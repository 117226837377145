import { colors } from '@loggi/mar';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  deleteIcon: {
    color: colors.smoke[600]
  }
}));

const NfeFilesList = ({ files, onDeleteFile }) => {
  const styles = useStyles();

  return (
    <Box pb={2}>
      {files.map(({ name }) => (
        <Box display="flex" justifyContent="center" key={name}>
          <Typography gutterBottom>
            <Box fontWeight="fontWeightBold" px={1} variant="subtitle2">
              {name}
            </Box>
          </Typography>
          <ButtonBase
            data-testid="remove-file"
            onClick={() => {
              const updatedFiles = files.filter(file => file.name !== name);
              onDeleteFile(updatedFiles);
            }}
          >
            <Delete className={styles.deleteIcon} fontSize="small" />
          </ButtonBase>
        </Box>
      ))}
    </Box>
  );
};

NfeFilesList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ).isRequired,
  onDeleteFile: PropTypes.func.isRequired
};

export default NfeFilesList;
