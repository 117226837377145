import {
  AddressField as AddressComponentField,
  ComplementField,
  NoComplementField
} from '@loggi/components/src/one/formik-fields';
import { validateDestinationCoverage } from '@loggi/components/src/one/address';
import { useFeature } from '@loggi/components/src/one/remote-config';
import { Box, Divider, Grid, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionTitle from '../../create-section-title';
import AddressField from '../address/address-field';

const AddressRecipientSection = () => {
  const { t } = useTranslation('packages');
  const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const gridSpacing = smUp ? 5 : 2;
  const complementFieldName = 'recipientAddressComplement';

  const enableAddressComponent = useFeature(
    'enable_address_validation_in_packages_app'
  );
  return (
    <>
      <Box display="block" mb={4}>
        <SectionTitle>{t('create.address.recipientTitle')}</SectionTitle>
      </Box>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={6}>
          {enableAddressComponent ? (
            <AddressComponentField
              fieldName="recipient"
              validate={validateDestinationCoverage}
            />
          ) : (
            <AddressField fieldName="recipient" />
          )}
        </Grid>
        <Grid item xs={6} md={3}>
          <ComplementField fieldName={complementFieldName} />
        </Grid>
        <Grid item xs={6} md={3}>
          <Box mt={1}>
            <NoComplementField complementFieldName={complementFieldName} />
          </Box>
        </Grid>
      </Grid>
      <Box py={4}>
        <Divider />
      </Box>
    </>
  );
};

export default AddressRecipientSection;
