import {
  IconButton,
  Typography,
  Box,
  Tooltip,
  useTheme
} from '@material-ui/core';
import FilterNoneOutlined from '@material-ui/icons/FilterNoneOutlined';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { TRACKING_KEY_MAX_CHAR } from '../../constants';

const useStyles = makeStyles(({ typography }) => ({
  copyIcon: {
    color: '#0095FF',
    width: typography.pxToRem(20),
    height: typography.pxToRem(20)
  }
}));

export const truncateID = (trackingKey, maxChar) => {
  if (trackingKey.length <= maxChar) return trackingKey;

  return `${trackingKey.substring(0, maxChar / 2)}...${trackingKey.substring(
    trackingKey.length - maxChar / 2
  )}`;
};

/**
 * IdentifierField's accountability is to create the identifier field in the packages list component
 * @param {string} identifier the identifier to be displayed
 * @returns identifierfield
 */
function IdentifierField({ identifier }) {
  const { typography } = useTheme();
  const { t } = useTranslation('packages');
  const [tooltiptext, setTooltipText] = useState(t('list.copyIdentifier'));

  const copyToClipboard = () => {
    navigator.clipboard.writeText(identifier);
  };

  const handleToolTipClick = () => {
    copyToClipboard();
    setTooltipText(t('list.copiedIdentifier'));
  };

  const handleTooltipClose = () => {
    setTimeout(() => {
      setTooltipText(t('list.copyIdentifier'));
    }, 200);
  };

  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      zIndex={1}
      justifyContent="space-between"
    >
      <Box pr={1}>
        <Typography
          variant="body2"
          color="textSecondary"
          noWrap
          style={{ fontWeight: typography.fontWeightMedium }}
        >
          {truncateID(identifier, TRACKING_KEY_MAX_CHAR)}
        </Typography>
      </Box>
      <Tooltip
        PopperProps={{
          disablePortal: true
        }}
        onClose={handleTooltipClose}
        title={tooltiptext}
      >
        <IconButton
          data-testid="copyIconButton"
          onClick={handleToolTipClick}
          color="primary"
          className={`${
            classes.copyIconButton
          } packages-list-item-component-copyToClipBoard`}
        >
          <FilterNoneOutlined className={classes.copyIcon} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

IdentifierField.propTypes = {
  identifier: PropTypes.string.isRequired
};

export default IdentifierField;
