import React, { useState, useEffect } from 'react';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { useFeature } from '@loggi/components/src/one/remote-config';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { differenceInDays, parseISO } from 'date-fns';
import {
  chargeDetailPropTypes,
  packageChargePropTypes,
  alertRefundPropTypes,
  fieldBlockPropTypes,
  propTypes as packageProps
} from '@loggi/components/src/one/package-utils';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import InfoIcon from '@material-ui/icons/Info';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  Tooltip
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import {
  accessItems,
  getUserRole
} from '@loggi/components/src/one/permissions';
import { shouldDisplayActionRequired } from '@loggi/components/src/one/package-utils/status.helpers';
import billingApi from '../billing-api';
import { useRemoteConfig } from '../../firebase';
import packagesRoutes from '../packages-routes';
import PackageHistoryWrapper from './package-history-wrapper';
import PackageDetailsReturnDirectionCard from './package-details-return-direction-card';
import PackageDetailsActionRequiredAlert from './package-details-action-required-alert';
import PackageDetailsRecipientSection from './package-details-recipient-section';
import { formatCurrency } from './utils';
import PackageDetailsDeliveryProofSection from './package-details-delivery-proof-section';
import PackageDetailsRedispatchSection from './package-details-redispatch-section';
import PackageFiscalElementsSection from './package-fiscal-elements-section';

export const ChargeDetail = ({ chargeAmount, slo, t }) => {
  /*
  0 to express delivery, 1 to economic delivery
  // https://loggidev.atlassian.net/wiki/spaces/LO/pages/1237319880/API+for+package+price+estimation
  // https://loggidev.atlassian.net/wiki/spaces/TL/pages/1214317878/Loggi+One+Nacional+Sales+Pricing+Tool+WIP
  */
  const sloToLabel = {
    0: t('details.labels.freightType.sameDay'),
    1: t('details.labels.freightType.economic')
  };

  return chargeAmount !== null && chargeAmount !== undefined ? (
    <>
      <Divider />
      <Box py={3}>
        <Typography variant="h6">
          <strong>{t('details.labels.charge')}</strong>
        </Typography>
      </Box>
      <Box display="flex" pb={3}>
        {slo !== null && slo !== undefined && (
          <Box flex="2">
            <Typography variant="body2">
              <strong>{t('details.labels.shippingOption')}</strong>
            </Typography>
            <Typography variant="body1">{sloToLabel[slo]}</Typography>
          </Box>
        )}
        <Box flex="2">
          <Typography variant="body2">
            <strong>{t('details.labels.amount')}</strong>
          </Typography>
          <Typography variant="body1">
            {formatCurrency(chargeAmount)}
          </Typography>
        </Box>
      </Box>
    </>
  ) : null;
};
ChargeDetail.propTypes = chargeDetailPropTypes;

const FieldBlock = ({ labelValue, value }) => {
  const { t } = useTranslation('packages');
  return (
    <>
      <Typography variant="body2">
        <strong>{t(labelValue)}</strong>
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </>
  );
};
FieldBlock.propTypes = fieldBlockPropTypes;

export const RefundDetail = ({ charge }) => {
  const { t } = useTranslation('packages');
  if (!charge || Object.keys(charge).length === 0) {
    return null;
  }

  const {
    amount,
    billing_month: billingMonth,
    refund_reason: refundReason
  } = charge;

  if (!amount && !billingMonth && !refundReason) {
    return null;
  }

  return (
    <>
      <Divider />
      <Box pt={3} pb={1}>
        <Typography variant="h6">
          <strong>{t('details.labels.refund')}</strong>
        </Typography>
      </Box>
      <Box display={{ sm: 'flex' }} pb={3}>
        <Box flex="1" pt={2}>
          <FieldBlock
            labelValue="details.labels.valueToBeRefunded"
            value={formatCurrency(amount)}
          />
        </Box>
        <Box flex="1" pt={2}>
          <FieldBlock
            labelValue="details.labels.refundReason"
            value={refundReason}
          />
        </Box>
        <Box flex="2" pt={2}>
          <FieldBlock
            labelValue="details.labels.billingRefunded"
            value={billingMonth}
          />
        </Box>
      </Box>
    </>
  );
};
RefundDetail.propTypes = {
  charge: packageChargePropTypes
};
RefundDetail.defaultProps = {
  charge: undefined
};

export const AlertRefund = ({ show }) => {
  const { t } = useTranslation('packages');
  if (!show) {
    return null;
  }

  return (
    <Box pb={3}>
      <Alert data-testid="action-required-alert" severity="info" color="info">
        <AlertTitle>{t('details.labels.refundAlertTitle')}</AlertTitle>
        {t('details.labels.refundAlertDescription')}
      </Alert>
    </Box>
  );
};
AlertRefund.propTypes = alertRefundPropTypes;

const useStyles = makeStyles(
  ({ typography, breakpoints, spacing, palette }) => ({
    copyIcon: {
      width: typography.pxToRem(22),
      height: typography.pxToRem(22)
    },
    copyIconButton: {
      boxShadow:
        '0px 11px 15px -7px rgba(190, 199, 214, 0.02), 0px 24px 38px 3px rgba(159, 171, 187, 0.03), 0px 9px 46px 8px rgba(72, 79, 95, 0.08)',
      background: palette.common.white,
      width: typography.pxToRem(32),
      height: typography.pxToRem(32)
    },
    trackingKeyCard: {
      backgroundColor: palette.grey[50],
      borderColor: palette.grey[100]
    },
    trackingHistoryButton: {
      [breakpoints.down('xs')]: {
        paddingTop: spacing(3)
      }
    }
  })
);

const DetailsContent = ({ data }) => {
  const { t } = useTranslation('packages');
  const { companyId } = useParams();
  const [dataCharge, setDataCharge] = useState(undefined);
  const { push, goBack } = useHistory();
  const styles = useStyles();
  const {
    state: { authenticatedUser, isImpersonation }
  } = useAmplifyAuth();
  const userRole = getUserRole(authenticatedUser, companyId);
  const { value: allowedDaysWihoutMovement } = useRemoteConfig(
    'max_days_without_movement_per_company'
  );

  const maxDaysMap = JSON.parse(allowedDaysWihoutMovement);

  useEffect(() => {
    billingApi
      .getCharge(companyId, data.trackingKey)
      .then(response => {
        setDataCharge(response);
      })
      .catch(() => {
        setDataCharge(undefined);
      });
  }, [companyId, data.trackingKey]);

  const urlLocation = window.location.href;

  const isTrackingHistoryDrawerOpen = urlLocation.includes(
    packagesRoutes.trackingHistory.url(companyId, data.trackingKey)
  );

  const [open, setOpen] = useState(isTrackingHistoryDrawerOpen);

  const showRedispatchSection = data.barcode || data.redispatchPickupAddress;

  const showAlertRefund = dataCharge?.amount ?? false;

  const enableDeliveryProofSection = useFeature(
    'enable_delivery_proof_section'
  );

  const showDeliveryProofSection =
    enableDeliveryProofSection &&
    (data.isDelivered() || data.isReturned()) &&
    (data.receiverName !== '' ||
      data.receiverDocument !== '' ||
      data.deliveryLocationDescription !== '' ||
      data.deliveryLinks?.length > 0);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(data.trackingKey);
  };

  const handleClickOpen = () => {
    setOpen(true);
    push(packagesRoutes.trackingHistory.url(companyId, data.trackingKey));
  };

  const handleClose = () => {
    setOpen(false);
    if (isTrackingHistoryDrawerOpen) {
      goBack();
    }
  };

  const shouldRenderSection = section =>
    accessItems[section].includes(userRole) || isImpersonation;

  const { value: enableDelayedChipAndToast } = useRemoteConfig(
    'enable_delayed_chip_and_alert'
  );
  const daysWithoutMovement = differenceInDays(
    new Date(),
    parseISO(data.statusUpdatedTime)
  );

  const maxAllowedDays = maxDaysMap[companyId]
    ? maxDaysMap[companyId]
    : maxDaysMap.default;

  const showDelayedAlert =
    enableDelayedChipAndToast &&
    !data.isPackageDirectionReturn() &&
    !data.isFinalStatus() &&
    data.isPackageDelayed() &&
    daysWithoutMovement > maxAllowedDays;

  return (
    <Box>
      {showDelayedAlert && (
        <Box pb={2}>
          <Alert
            severity="error"
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            icon={<InfoIcon fontSize="inherit" />}
          >
            <Typography variant="body2">
              <strong>{t('details.sections.delayedToast.title')}</strong>
            </Typography>
            <Typography variant="body2">
              <Trans>
                {t('details.sections.delayedToast.body', {
                  count: daysWithoutMovement
                })}
              </Trans>
            </Typography>
          </Alert>
        </Box>
      )}
      <PackageDetailsReturnDirectionCard
        show={data.isPackageDirectionReturn()}
        type={data.direction?.type?.description || ''}
        reason={data.direction?.reason?.description || ''}
        returnAddress={data.direction?.returnAddress || {}}
        warehouseId={data.updateReturnAddressInfo?.warehouseId || null}
        canUpdateReturnAddress={data.canUpdateReturnAddress()}
      />
      {data.statusActionRequired && (
        <PackageDetailsActionRequiredAlert
          show={shouldDisplayActionRequired()}
          packageIssued={data.isPackageIssued()}
          reasonLabel={data.statusActionRequired?.reasonLabel || ''}
          reasonDescription={data.statusActionRequired?.reasonDescription || ''}
        />
      )}
      <AlertRefund show={showAlertRefund} />
      {showRedispatchSection && <PackageDetailsRedispatchSection data={data} />}
      {data.trackingKey && (
        <Box
          pb={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <Typography variant="h6">
              <strong>{t('details.labels.trackingKey')}</strong>
            </Typography>

            <Box
              display="flex"
              mt={1}
              className={styles.trackingKeyCard}
              borderRadius={100}
              border={1}
            >
              <Box
                pl={3}
                pr={1}
                py={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                minWidth={240}
              >
                <Box pr={1}>
                  <Typography variant="caption" color="textSecondary" noWrap>
                    <strong>{data.trackingKey}</strong>
                  </Typography>
                </Box>
                <Tooltip title={t('list.copyIdentifier')}>
                  <IconButton
                    data-testid="copyIconButton"
                    onClick={copyToClipboard}
                    border={1}
                    color="primary"
                    className={`${styles.copyIconButton} svg-copy-button`}
                  >
                    <FileCopyRoundedIcon
                      className={`${styles.copyIcon} svg-copy-button`}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box className={styles.trackingHistoryButton}>
            <Button
              size="medium"
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              data-testid="tracking-history-btn"
            >
              {t('details.actions.trackingHistory.title')}
            </Button>
          </Box>
        </Box>
      )}
      {showDeliveryProofSection && (
        <PackageDetailsDeliveryProofSection data={data} />
      )}
      <PackageDetailsRecipientSection data={data} />
      {shouldRenderSection('packageInvoiceDetail') && (
        <PackageFiscalElementsSection data={data} />
      )}
      {shouldRenderSection('packageChargeDetail') && (
        <ChargeDetail chargeAmount={data.chargeAmount} slo={data.slo} t={t} />
      )}
      <RefundDetail charge={dataCharge} />
      <PackageHistoryWrapper
        trackingKey={data?.trackingKey}
        onClose={handleClose}
        open={open}
      />
    </Box>
  );
};

DetailsContent.propTypes = {
  data: packageProps.isRequired
};

export default DetailsContent;
