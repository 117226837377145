import React from 'react';
import { PropTypes } from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

const useStyles = makeStyles(({ palette, spacing }) => ({
  linkBackButton: {
    borderRadius: '100%',
    height: spacing(5),
    minWidth: 0,
    width: spacing(5),
    color: palette.common.white
  }
}));

/**
 * BulkCancellationResultsSummary's only responsibility is to show the summary info of the
 * packages batch cancellation results screen, with the params it receives.
 * @param goBackToPackagesList
 * @returns {*}
 */
const BulkCancellationResultsSummary = ({ goBackToPackagesList }) => {
  const styles = useStyles();
  const { t } = useTranslation('packages');

  return (
    <Box display="flex">
      <Button
        className={styles.linkBackButton}
        variant="outlined"
        data-testid="back-btn"
        onClick={() => goBackToPackagesList()}
      >
        <ArrowBack style={{ fontSize: 22 }} />
      </Button>
      <Box pl={1.5}>
        <Typography variant="h4">
          <strong>{t('list.bulkCancellation.results.summary.title')}</strong>
        </Typography>
      </Box>
    </Box>
  );
};

BulkCancellationResultsSummary.propTypes = {
  goBackToPackagesList: PropTypes.func.isRequired
};

export default BulkCancellationResultsSummary;
