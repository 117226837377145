import React from 'react';
import PropTypes from 'prop-types';

import { List, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { propTypes as warehousesProps } from '../../proptypes';

import WarehousesListItem from './warehouses-list-item.component';

const useStyles = makeStyles(() => ({
  list: {
    width: '100%'
  }
}));

/**
 * WarehousesList renders a static list that only presents the data it receives
 * @componentType presentational
 * @param data
 * @returns {*}
 * @constructor
 */
const WarehousesList = ({ data }) => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {data.map((warehouse, index) => {
        const showDivider = index < data.length - 1;
        return (
          <React.Fragment key={warehouse.warehouseId}>
            <WarehousesListItem
              warehouseId={warehouse.warehouseId}
              tradingName={warehouse.tradingName}
              warehouseAddress={
                warehouse.warehouseAddress?.postalAddress?.addressLines[0]
              }
            />
            {showDivider && <Divider component="li" />}
          </React.Fragment>
        );
      })}
    </List>
  );
};

WarehousesList.propTypes = {
  data: PropTypes.arrayOf(warehousesProps)
};
WarehousesList.defaultProps = {
  data: []
};

export default WarehousesList;
