import {
  accessItems,
  getUserRole
} from '@loggi/components/src/one/permissions';
import {
  LocationIcon,
  IntegrationIcon,
  PeopleIcon,
  CompanyDataIcon
} from '@loggi/components/src/one/shared/images';
import UsersManagement from '../../../users-management/src/users-management.component';
import {
  ReturnAddress,
  AddressList,
  WarehousesList,
  IntegrationsList,
  routes,
  CompanyData
} from '../management';
import integratorsJson from '../management/integrator/data/integrators-translations-pt-br.json';

export const getManagementRoutes = (
  authenticatedUser,
  companyId,
  enableWarehousesList = false,
  enableIntegratorList = false,
  enableCompanyDataManagement = false
) => {
  const userRole = getUserRole(authenticatedUser, companyId);

  return [
    {
      id: 'addressDetail',
      path: routes.addressList.path,
      component: AddressList,
      visible: accessItems.addressesList.includes(userRole)
    },
    {
      id: 'addressesList',
      path: routes.warehousesAddresses.path,
      component: WarehousesList,
      menu: {
        Image: LocationIcon,
        label: 'menuConfig.cards.titles.warehousesList',
        subtitle: 'menuConfig.cards.subtitles.warehousesList',
        url: routes.warehousesAddresses.url(companyId)
      },
      visible:
        enableWarehousesList && accessItems.addressesList.includes(userRole)
    },
    {
      id: 'editReturnAddress',
      path: routes.returnAddress.path,
      component: ReturnAddress,
      visible: accessItems.addressesList.includes(userRole)
    },
    {
      id: 'integrationsList',
      path: routes.integrationSystems.path,
      component: IntegrationsList,
      menu: {
        Image: IntegrationIcon,
        label: 'menuConfig.cards.titles.integratorList',
        subtitle: 'menuConfig.cards.subtitles.integratorList',
        url: routes.integrationSystems.url(companyId)
      },
      visible:
        enableIntegratorList && accessItems.integratorList.includes(userRole)
    },
    {
      id: 'usersManagement',
      path: routes.usersManagement.path,
      component: UsersManagement,
      menu: {
        Image: PeopleIcon,
        label: 'menuConfig.cards.titles.usersManagement',
        subtitle: 'menuConfig.cards.subtitles.usersManagement',
        url: routes.usersManagement.url(companyId)
      },
      visible: accessItems.usersManagement.includes(userRole)
    },
    {
      id: 'companyData',
      path: routes.companyData.path,
      component: CompanyData,
      menu: {
        Image: CompanyDataIcon,
        label: 'menuConfig.cards.titles.companyData',
        subtitle: 'menuConfig.cards.subtitles.companyData',
        url: routes.companyData.url(companyId)
      },
      visible:
        enableCompanyDataManagement &&
        accessItems.companyData.includes(userRole)
    }
  ];
};

export const getIntegratorRoutes = (authenticatedUser, companyId) => {
  const userRole = getUserRole(authenticatedUser, companyId);
  const integratorRoutes = [];

  Object.entries(integratorsJson).forEach(value => {
    const integrator = value[0];
    const integratorName = value[1].name;
    integratorRoutes.push({
      id: `integration${integratorName}`,
      path: routes[`integration${integratorName}`].path,
      integrator: `${integrator}`,
      visible: accessItems.integratorList.includes(userRole)
    });
  });

  return integratorRoutes;
};

export default getManagementRoutes;
