import React, { createContext } from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import ReceiptGenericError from '../screens/receipt-generic-error/receipt-generic-error.component';

const ErrorBoundaryContext = createContext({
  error: null,
  componentStack: null
});

const AppErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={scope => {
        scope.setTag('app.region', 'pickup-receipt');
      }}
      fallback={props => (
        <ErrorBoundaryContext.Provider value={props}>
          <ReceiptGenericError />
        </ErrorBoundaryContext.Provider>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

AppErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired
};

export default AppErrorBoundary;
