import DrawerBase from '@loggi/components/src/one/drawer-base/drawer-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PICKUP_RECEIPT_ROUTES } from '../../constants';
import PickupReceiptFeedbackDrawerContent from './pickup-receipt-feedback-drawer-content';

const PickupReceiptFeedbackDrawer = props => {
  const history = useHistory();
  const { companyId, id: pickupOrderScheduleId, pickupReceiptId } = useParams();
  const { isReceiptAlreadyAnswered, feedbackMessage } = props;

  const shouldDrawerBeOpened =
    history.location.pathname ===
    PICKUP_RECEIPT_ROUTES.report.url(
      companyId,
      pickupOrderScheduleId,
      pickupReceiptId
    );
  const [openDrawer, setOpenDrawer] = useState(shouldDrawerBeOpened);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    if (shouldDrawerBeOpened) {
      history.goBack();
    }
  };

  useEffect(() => {
    if (shouldDrawerBeOpened) {
      setOpenDrawer(true);
    }
  }, [shouldDrawerBeOpened]);

  return (
    <DrawerBase open={openDrawer} onClose={handleCloseDrawer}>
      <PickupReceiptFeedbackDrawerContent
        isReceiptAlreadyAnswered={isReceiptAlreadyAnswered}
        feedbackMessage={feedbackMessage}
        handleCloseDrawer={handleCloseDrawer}
      />
    </DrawerBase>
  );
};

PickupReceiptFeedbackDrawer.propTypes = {
  isReceiptAlreadyAnswered: PropTypes.bool,
  feedbackMessage: PropTypes.string
};

PickupReceiptFeedbackDrawer.defaultProps = {
  feedbackMessage: '',
  isReceiptAlreadyAnswered: false
};

export default PickupReceiptFeedbackDrawer;
