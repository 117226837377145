import {
  AccessDenied,
  ImpersonationForm
} from '@loggi/authentication/src/screens';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AllowedRoutes from './allowed-routes';
import {
  ACCESS_DENIED,
  COMPANIES_ROUTE,
  IMPERSONATION_ROUTE,
  LIST_ALLOWED_URL_REDIRECT,
  OUT_OF_COVERAGE
} from './constants';

const checkAllowedUrlRedirection = urlRedirect =>
  LIST_ALLOWED_URL_REDIRECT.some(allowedUrlRedirect =>
    urlRedirect.includes(allowedUrlRedirect)
  );

const AppsRoutes = ({
  company,
  isImpersonation,
  urlRedirect,
  setUrlRedirect,
  isOutOfCoverageCompanyStatus
}) => {
  let url = '';
  if (urlRedirect && checkAllowedUrlRedirection(urlRedirect)) {
    url = urlRedirect;
    setUrlRedirect('');
  }
  if (isOutOfCoverageCompanyStatus) {
    url = OUT_OF_COVERAGE.url(company.id);
  }
  return (
    <Switch>
      <Route path={`${COMPANIES_ROUTE}/:companyId`} component={AllowedRoutes} />
      <Route path={ACCESS_DENIED} component={AccessDenied} />
      {!isImpersonation && (
        <Route path={IMPERSONATION_ROUTE} component={ImpersonationForm} />
      )}
      {url && <Redirect to={url} />}
      <Redirect from="*" to={`${COMPANIES_ROUTE}/${company.id}`} />
    </Switch>
  );
};

AppsRoutes.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.string
  }).isRequired,
  isImpersonation: PropTypes.bool.isRequired,
  urlRedirect: PropTypes.string,
  setUrlRedirect: PropTypes.func.isRequired,
  isOutOfCoverageCompanyStatus: PropTypes.bool
};

AppsRoutes.defaultProps = {
  urlRedirect: null,
  isOutOfCoverageCompanyStatus: false
};

export default AppsRoutes;
