import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const codeIntegratorStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    alertMessage: {
      backgroundColor: colors.smoke[50],
      border: `1px solid ${colors.smoke[300]}`,
      color: palette.info.dark,
      fontSize: typography.body2.fontSize,
      margin: spacing(3, 0, -4, 0),
      [breakpoints.down('sm')]: {
        alignItems: 'start',
        '& .MuiAlert-icon': {
          paddingTop: spacing(1.15)
        }
      }
    },
    subtitleIcon: {
      color: palette.info.dark
    },
    codeIntegrator: {
      width: 'auto',
      height: 80,
      border: `solid 2px ${palette.primary.main}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: spacing(2),
      margin: spacing(3, 0, 3, 0),
      padding: spacing(0, 1, 0, 1)
    },
    fileCopyIcon: {
      color: palette.primary.main
    },
    integratorLink: {
      fontWeight: 700,
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  })
);

export const integratorDescriptionStyles = makeStyles(
  ({ typography, spacing }) => ({
    titlesMenuLink: {
      fontWeight: 'bold'
    },
    stickyMenu: {
      position: 'sticky',
      top: 0
    },
    titleMenu: {
      color: 'inherit',
      margin: spacing(1, 2, 1, -1),
      '&:hover': {
        textDecoration: 'none',
        fontWeight: 'bold'
      }
    },
    listDisc: {
      listStyle: 'disc',
      color: colors.blue[500]
    },
    boxGifs: {
      backgroundColor: colors.blue[50],
      padding: spacing(3),
      borderRadius: typography.pxToRem(8)
    },
    link: {
      textDecoration: 'none',
      fontWeight: 'bold',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline'
      }
    }
  })
);

export const MENU_PROPS = {
  PaperProps: {
    style: {
      marginTop: '8px',
      maxWidth: '80px'
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
};
