import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import schedulingApi from '../details/scheduling-api';

const initialState = {
  isLoading: true,
  data: undefined,
  totalItems: null // Mock value
};

/**
 * This hook adds an abstraction layer between the component and the fetch for
 * the PickupOrderScheduling resource.
 * It gets the pagination params directly from the useQueryParams hook, and fetches
 * the data using the fetchSchedulings method, also adding a loading interface
 * @returns {[undefined, null, boolean]}
 */
const useSchedulings = () => {
  const { companyId } = useParams();
  const {
    created,
    orderBy,
    page,
    pageSize,
    status,
    document
  } = useQueryParams();
  const [{ data, isLoading, totalItems }, setData] = useState(initialState);

  useEffect(() => {
    setData(state => ({ ...state, isLoading: true }));
    schedulingApi
      .fetchSchedulings(
        companyId,
        created,
        orderBy,
        status,
        document,
        page,
        pageSize
      )
      .then(response => {
        const results = response?.results;
        const count = response?.count;

        setData(state => ({
          ...state,
          data: results,
          isLoading: false,
          totalItems: count
        }));
      });
  }, [companyId, created, orderBy, page, pageSize, status, document]);

  return [data, totalItems, isLoading];
};

export default useSchedulings;
