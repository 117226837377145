import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { Box, SvgIcon, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as LogoLoggi } from '../assets/logo-loggi.svg';
import { PICKUP_RECEIPT_ROUTE } from '../routes/constants';

const useHeaderStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    [breakpoints.up('md')]: {
      right: 'auto',
      width: '100vw'
    }
  },
  logoLink: {
    color: palette.primary.contrastText
  }
}));

const HeaderPickupReceipt = () => {
  const styles = useHeaderStyles();
  const { push } = useHistory();
  const location = useLocation();
  const { pickupOrderScheduleHash } = matchPath(location.pathname, {
    path: PICKUP_RECEIPT_ROUTE.path
  }).params;

  const goBack = () => {
    push(PICKUP_RECEIPT_ROUTE.url(pickupOrderScheduleHash));
  };

  return (
    <Box
      className={styles.root}
      display="flex"
      left={0}
      position="absolute"
      right={0}
      mt={5}
    >
      <Container component={Box} height="inherit">
        <Box display="flex" mr={3}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            role="button"
            tabIndex={0}
            className={styles.logoLink}
            data-testid="logo-loggi-id"
            onClick={goBack}
            onKeyDown={goBack}
          >
            <SvgIcon component={LogoLoggi} />
          </a>
        </Box>
      </Container>
    </Box>
  );
};

export default HeaderPickupReceipt;
