import { ArrowForwardIos } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatHour } from '@loggi/components/src/one/date-utils';
import { TRANSPORT_TYPE_MAPPER } from '../../../constants';

const PickupReceiptInfo = props => {
  const { t } = useTranslation('pickupReceiptsList');
  const {
    driverName,
    licensePlate,
    packages,
    pickupEndTime,
    transportType
  } = props;

  const getVehicleInfo = () => {
    if (!transportType && !licensePlate) {
      return null;
    }

    let text = null;
    if (transportType) {
      text = t(TRANSPORT_TYPE_MAPPER(transportType));
    }

    if (licensePlate) {
      text = text ? `${text} - ${licensePlate}` : licensePlate;
    }

    return (
      <Typography noWrap variant="body2" color="textSecondary">
        {text}
      </Typography>
    );
  };

  return (
    <Box width="100%" textAlign="left">
      <Typography variant="subtitle1" color="textPrimary">
        <strong>{driverName}</strong>
      </Typography>
      <Box pt={2}>
        {getVehicleInfo()}
        <Typography noWrap variant="body2" color="textSecondary">
          {t('pickupEndTime.label', {
            pickupEndTime: formatHour(pickupEndTime)
          })}
        </Typography>
      </Box>
      <Box
        py={3}
        display="flex"
        justifyContent="space-between"
        sx={{ width: 1 }}
      >
        <Typography variant="body1" color="textPrimary">
          <strong>
            {t('beepedPackages.amount', {
              count: packages.length
            })}
          </strong>
        </Typography>
        <Box display="flex" mr={1}>
          <Typography variant="body2" color="primary">
            <strong>{t('beepedPackages.label')}</strong>
          </Typography>
          <ArrowForwardIos style={{ fontSize: 20 }} color="primary" />
        </Box>
      </Box>
    </Box>
  );
};

PickupReceiptInfo.defaultProps = {
  transportType: '',
  licensePlate: ''
};

PickupReceiptInfo.propTypes = {
  driverName: PropTypes.string.isRequired,
  transportType: PropTypes.string,
  licensePlate: PropTypes.string,
  pickupEndTime: PropTypes.string.isRequired,
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      trackingKey: PropTypes.string,
      recipientName: PropTypes.string
    })
  ).isRequired
};

export default PickupReceiptInfo;
