import { Box, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Toast from '@loggi/components/src/one/toast';
import { useCheckCompanyStatus } from '@loggi/components/src/one/hooks';
import { dispatchEvent } from '@loggi/components/src/one/useSubscription';

import exceptionHandler from '../../exception-handler';
import packagesApi from '../../packages-api';
import packagesRoutes from '../../packages-routes';
import InvoiceSection from './invoice/invoice-section';
import AddressRecipientSection from './recipient/address-recipient-section';
import RecipientSection from './recipient/recipient-section';
import VolumeSection from './volumetry/volume-section';

const useStyles = makeStyles(({ palette, spacing }) => ({
  buttonWrapper: {
    margin: spacing(1),
    position: 'relative'
  },
  buttonWrapperIsLoading: {
    '& button:disabled': {
      color: 'rgba(0, 0, 0, 0)'
    }
  },
  buttonProgress: {
    color: palette.common.white,
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
    position: 'absolute',
    top: '50%'
  }
}));

const CreatePackageForm = ({ initialValues }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('packages');
  const { enqueueSnackbar } = useSnackbar();
  const { companyId } = useParams();
  const { isDefaulter } = useCheckCompanyStatus();

  const failuresSnackbars = useCallback(
    failures =>
      failures.map(({ details }) =>
        enqueueSnackbar(details, {
          content: (key, message) => (
            <Toast id={key} message={message} type="error" />
          )
        })
      ),
    [enqueueSnackbar]
  );

  const onSubmit = useCallback(
    async formValues => {
      setLoading(true);
      packagesApi
        .create(formValues, companyId)
        .badRequest(({ text }) => {
          try {
            const errors = JSON.parse(text);
            errors.map(({ packageResult: { failures } }) =>
              failuresSnackbars(failures)
            );
          } catch {
            enqueueSnackbar(t('create.errorMessages.invalidFormStatus'), {
              content: (key, message) => (
                <Toast id={key} message={message} type="error" />
              )
            });
            exceptionHandler.warning(text);
          }

          setLoading(false);
        })
        .error(422, () => {
          enqueueSnackbar(t('create.errorMessages.unprocessableEntity'), {
            content: (key, message) => (
              <Toast id={key} message={message} type="error" />
            )
          });
          setLoading(false);
        })
        .json(({ trackingKey }) => {
          enqueueSnackbar(t('create.successMessages.packageCreated'), {
            content: (key, message) => (
              <Toast id={key} message={message} type="success" />
            )
          });
          dispatchEvent('package:created', trackingKey);
          const url = packagesRoutes.createSuccess.url(companyId);
          push(url, { trackingKeys: [trackingKey] });
        })
        .catch(() => {
          enqueueSnackbar(t('create.errorMessages.invalidFormStatus'), {
            content: (key, message) => (
              <Toast id={key} message={message} type="error" />
            )
          });
          setLoading(false);
        });
    },
    [companyId, enqueueSnackbar, failuresSnackbars, push, t]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      // 'validateOnMount' is needed because we want the form
      // to be invalid on initial render, so the submit button
      // is disabled. The date fields also need this to work
      // properly.
      validateOnMount
    >
      {({ isValid }) => {
        return (
          <Form data-testid="create-package-form">
            <Box>
              <Box>
                <AddressRecipientSection />
              </Box>
              <Box>
                <VolumeSection />
              </Box>
              <Box>
                <InvoiceSection />
              </Box>
              <Box>
                <RecipientSection />
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" pt={{ xs: 3, sm: 3 }}>
              <Box
                className={`${classes.buttonWrapper} ${
                  loading ? classes.buttonWrapperIsLoading : ''
                }`}
              >
                <Button
                  color="primary"
                  disabled={!isValid || loading || isDefaulter}
                  data-testid="submitButton"
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {t('create.addPackageButton')}
                </Button>
                {loading && (
                  <CircularProgress
                    className={classes.buttonProgress}
                    data-testid="loading"
                    size={24}
                  />
                )}
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

const packageFormShape = {
  recipientAddressComplement: PropTypes.string,
  cnpj: PropTypes.string,
  invoiceKey: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  volumetry: PropTypes.array
};

CreatePackageForm.propTypes = {
  initialValues: PropTypes.shape(packageFormShape)
};

CreatePackageForm.defaultProps = {
  initialValues: {}
};

CreatePackageForm.displayName = 'CreatePackageForm';

export default CreatePackageForm;
