import { Box, CircularProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import receiptOrderScheduleApi from '../../../operations/order-schedule/receipt-order-schedule-api';
import { PICKUP_RECEIPT_AUTH_ROUTE } from '../../../routes/constants';
import ReceiptOrderScheduleDetails from './receipt-order-schedule-details.component';
import useAsyncError from '../../use-async-error';

export default function ReceiptOrderSchedule({ pickupOrderScheduleHash }) {
  const { t } = useTranslation('receiptOrderSchedule');
  const [receiptOrderScheduleData, setReceiptOrderScheduleData] = useState();
  const [requestStatus, setRequestStatus] = useState({
    isLoading: true,
    isExpired: false
  });
  const { push } = useHistory();
  const throwError = useAsyncError();

  useEffect(() => {
    receiptOrderScheduleApi
      .get(pickupOrderScheduleHash)
      .forbidden(() =>
        push(PICKUP_RECEIPT_AUTH_ROUTE.url(pickupOrderScheduleHash))
      )
      .error(410, () =>
        setRequestStatus(current => ({ ...current, isExpired: true }))
      )
      .json(response => {
        setReceiptOrderScheduleData(response);
      })
      .catch(error => {
        throwError(new Error(error));
      })
      .finally(() =>
        setRequestStatus(current => ({ ...current, isLoading: false }))
      );
  }, [pickupOrderScheduleHash, t, push, throwError]);

  return (
    <Box data-testid="receipt-header">
      {requestStatus.isLoading && (
        <Box display="flex" justifyContent="center" padding={4}>
          <CircularProgress data-testid="loading" />
        </Box>
      )}

      {receiptOrderScheduleData && (
        <ReceiptOrderScheduleDetails
          receiptOrderSchedule={receiptOrderScheduleData}
        />
      )}

      {requestStatus.isExpired && (
        <Typography variant="h4">
          <strong>{t('receiptExpired.headerMessage')}</strong>
        </Typography>
      )}
    </Box>
  );
}

ReceiptOrderSchedule.propTypes = {
  pickupOrderScheduleHash: PropTypes.string.isRequired
};
