import { loggiWebApi } from '@loggi/authentication-lib';
import LIST_USERS_ENDPOINT, {
  DEFAULT_PAGE_SIZE,
  FIRST_PAGE,
  ACCESS_DENIED,
  LOGIN
} from './constants';

const listUsers = (
  companyId,
  email = '',
  pageSize = DEFAULT_PAGE_SIZE,
  pageToken = FIRST_PAGE,
  enableFilterPaginationListUsers = false
) => {
  const endpointUrl = enableFilterPaginationListUsers
    ? `/${LIST_USERS_ENDPOINT}/${companyId}/users/?page_size=${pageSize}&page_token=${pageToken}&email=${email}`
    : `/${LIST_USERS_ENDPOINT}/${companyId}/users/`;

  return loggiWebApi
    .url(endpointUrl)
    .get()
    .forbidden(() => {
      window.location.assign(ACCESS_DENIED);
    })
    .unauthorized(() => {
      window.location.assign(LOGIN);
    })
    .json();
};

const resendUserInvite = (userId, accountId, email, accountRole) => {
  return loggiWebApi
    .url(`/${LIST_USERS_ENDPOINT}/${accountId}/users/${userId}/resend-invite/`)
    .post({ email, role: accountRole })
    .json();
};

export { listUsers, resendUserInvite };
