/* eslint-disable no-param-reassign */
import { HTTP_CODE_SUCCESS, MESSAGE_ORIGIN } from '../../../constants';

const openCommunicationChannelWithSW = messageChannel => {
  navigator.serviceWorker.ready.then(registration => {
    registration.active.postMessage(
      { type: 'PORT_INITIALIZATION', origin: MESSAGE_ORIGIN },
      [messageChannel.port2]
    );
  });
};

const downloadFile = async (
  awsLink,
  handleReportDownloadRequest,
  sendToastMessage
) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = awsLink;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(awsLink);
  document.body.removeChild(a);
  sendToastMessage();
  handleReportDownloadRequest({ loading: false, error: false });
};

const serviceWorkerListener = async (
  messageChannel,
  handleReportDownloadRequest,
  sendToastMessage
) => {
  messageChannel.port1.onmessage = event => {
    if (event.data.data.http_code === HTTP_CODE_SUCCESS) {
      downloadFile(
        event.data.data.body,
        handleReportDownloadRequest,
        sendToastMessage
      );
    } else {
      handleReportDownloadRequest({ loading: false, error: true });
    }
  };
};

const handleFirebaseReturn = (
  payload,
  handleReportDownloadRequest,
  sendToastMessage
) => {
  if (payload.data.http_code === HTTP_CODE_SUCCESS) {
    downloadFile(
      payload.data.body,
      handleReportDownloadRequest,
      sendToastMessage
    );
  } else {
    handleReportDownloadRequest({ loading: false, error: true });
  }
};

const firebaseListener = (
  messaging,
  handleReportDownloadRequest,
  sendToastMessage
) => {
  messaging.onMessage(payload => {
    handleFirebaseReturn(
      payload,
      handleReportDownloadRequest,
      sendToastMessage
    );
  });
};

const backgroundListener = (handleReportDownloadRequest, sendToastMessage) => {
  navigator.serviceWorker.addEventListener('message', event => {
    if (event.data.type === 'downloadReport') {
      if (event.data.payload.data.http_code === HTTP_CODE_SUCCESS) {
        downloadFile(
          event.data.payload.data.body,
          handleReportDownloadRequest,
          sendToastMessage
        );
      } else {
        handleReportDownloadRequest({ loading: false, error: true });
      }
    }
  });
};

export {
  openCommunicationChannelWithSW,
  serviceWorkerListener,
  firebaseListener,
  handleFirebaseReturn,
  backgroundListener
};
