import PICKUP_RECEIPT_API_ENDPOINTS from '../constants';
import pickupReceiptApi from '../pickup-receipt-api';

const pickupReceiptAuthApi = {
  authenticate: ({ pickupOrderScheduleHash, document }) => {
    return pickupReceiptApi
      .url(PICKUP_RECEIPT_API_ENDPOINTS.auth(pickupOrderScheduleHash))
      .accept('application/json')
      .content('application/json')
      .post({ document });
  }
};

export default pickupReceiptAuthApi;
