import wretch from 'wretch';
/**
 * pickupOrderSchedulePackagesApi is a wretch instance for requests to Pickups RestAPI.
 *
 * @return {Object}
 */
const pickupsApi = wretch()
  .url(process.env.REACT_APP_LOGGI_WEB_URL)
  .options({ credentials: 'include' });

export default pickupsApi;
