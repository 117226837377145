import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LIST_TYPE } from '../../constants';
import SearchBar from '../search-bar/document-search-bar';

const RecurringPickupSearchBar = () => {
  const { t } = useTranslation('scheduling');

  return (
    <Box py={1}>
      <SearchBar
        nameField="recurring_document"
        listType={LIST_TYPE.RECURRING_PICKUPS}
        id="recurring-filter-by-document"
        placeholder={t('searchBar.placeholder')}
      />
    </Box>
  );
};

export default RecurringPickupSearchBar;
