/* eslint-disable import/prefer-default-export */
import { oneConstants } from '@loggi/components/src/one';

export const DATE_CHRISTMAS = 25;
export const DATE_CHRISTMAS_EVE = 24;
export const DATE_NEW_YEARS = 1;
export const DATE_NEW_YEARS_EVE = 31;
export const MONTH_DECEMBER = 11;
export const MONTH_JANUARY = 0;
export const SCHEDULING_DAYS_FROM_NOW = 15;
export const MINIMUM_DATE_HOURS_DIFF = 1;
export const NOT_RECURRING = 'notRecurring';
export const IS_RECURRING = 'isRecurring';
export const BACK_BUTTON_ID = 'back-btn';
export const IS_MORNING = 'isMorning';
export const IS_AFTERNOON = 'isAfternoon';
export const NonWorkingDayType = {
  INVALID: 0,
  DELIVERY: 1,
  PROCESSING: 2,
  PICKUP: 3
};

export const HIDE_RECURRING_PICKUP_DIALOG_LOCALSTORAGE_KEY = `${
  oneConstants.ONE_LOCALSTORAGE_KEY
}_hide_recurring_pickup_dialog`;

export const DEFAULT_LIST_PAGE_SIZE = 5;
export const DEFAULT_LIST_PAGE = 1;
export const PACKAGE_LIST_PAGE_SIZE = 20;

export const routes = {
  addPackage: {
    path: '/empresas/:companyId/coletas/adicionar/pacotes',
    url: companyId => `/empresas/${companyId}/coletas/adicionar/pacotes`
  },
  unifiedPickup: {
    path: '/empresas/:companyId/coletas/adicionar',
    url: companyId => `/empresas/${companyId}/coletas/adicionar`
  },
  editRecurringPickup: {
    path: '/empresas/:companyId/coletas/rotina/:recurringPickupId/editar'
  }
};
