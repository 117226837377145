import { Grid, Typography, Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * WarehousesSummary's only responsibility is to show the summary info of the
 * warehouses screen.
 * @componentType: Presentational
 * @returns {*}
 */
const WarehousesSummary = () => {
  const { t } = useTranslation('management');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <Box pb={1}>
          <Typography variant="h4" component="h1">
            <strong>{t('warehouses.summary.title')}</strong>
          </Typography>
        </Box>
        <Typography variant="subtitle2" component="h2">
          {t('warehouses.summary.subtitle')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default WarehousesSummary;
