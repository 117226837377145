import React from 'react';
import PropTypes from 'prop-types';

import { ListItem, ListItemText, Box, Typography } from '@material-ui/core';
import { useParams, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { StatusIndicatorPackages } from '@loggi/components/src/one/status-indicator';

import { PACKAGES_ROUTES } from '../../constants';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    padding: spacing(3),
    [breakpoints.up('sm')]: {
      paddingLeft: spacing(5),
      paddingRight: spacing(5)
    },
    '&:hover': {
      backgroundColor: palette.secondary.light,
      cursor: 'pointer'
    },
    color: palette.primary.light
  }
}));

const SchedulingDetailsPackagesListItem = props => {
  const { trackingKey, status, recipientName, destinationAddress } = props;

  const classes = useStyles();

  const { companyId } = useParams();

  const statusAndRecipient = (
    <>
      <Box
        pb={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <StatusIndicatorPackages status={status} />
        <ArrowForwardIos style={{ fontSize: 20 }} color="primary" />
      </Box>
      <Box pb={1}>
        <Typography variant="subtitle1" color="textPrimary" mb={1}>
          <strong>{recipientName}</strong>
        </Typography>
      </Box>
    </>
  );

  const recipientAddress = (
    <>
      <Typography noWrap variant="body1" color="textSecondary">
        {destinationAddress}
      </Typography>
    </>
  );

  return (
    <ListItem
      disableGutters
      className={classes.root}
      button
      component={Link}
      to={`${PACKAGES_ROUTES.show.url(companyId, trackingKey)}`}
    >
      <ListItemText
        disableTypography
        primary={statusAndRecipient}
        secondary={recipientAddress}
      />
    </ListItem>
  );
};

SchedulingDetailsPackagesListItem.propTypes = {
  trackingKey: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  recipientName: PropTypes.string.isRequired,
  destinationAddress: PropTypes.string.isRequired
};

export default SchedulingDetailsPackagesListItem;
