const getUserRole = (authenticatedUser, companyId) => {
  const customAccess =
    authenticatedUser['custom:accessLoggiEnvios'] ||
    authenticatedUser['custom:access'];
  const staffCxGroup = authenticatedUser['cognito:groups']?.includes('cx');
  const staffCompanyId = 3;

  return customAccess
    ? JSON.parse(customAccess)?.companies?.find(({ id }) =>
        staffCxGroup
          ? Number(id) === staffCompanyId
          : Number(id) === Number(companyId)
      )?.role
    : undefined;
};

export default getUserRole;
