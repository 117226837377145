import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import errorIllustration from '@loggi/components/src/one/shared/images/no-invoices.svg';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  title: {
    fontWeight: typography.fontWeightBold
  },
  image: {
    width: '100%',
    maxWidth: typography.pxToRem(160)
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      maxWidth: 300
    },
    width: '100%'
  }
}));

const NoInvoices = () => {
  const classes = useStyles();
  const { t } = useTranslation('payments');

  return (
    <Box
      data-testid="empty-invoices-wrapper"
      className={classes.container}
      maxWidth={400}
      py={15}
    >
      <img className={classes.image} src={errorIllustration} alt="invoice" />
      <Box mt={(5, 3.5)} mb={(3, 2)}>
        <Box mb={2}>
          <Typography className={classes.title} variant="subtitle1">
            {t('invoices.noClosedInvoices')}
          </Typography>
        </Box>
        <Typography variant="body1" component="p">
          {t('invoices.closeInvoicesWarn')}
        </Typography>
      </Box>
    </Box>
  );
};

export default NoInvoices;
