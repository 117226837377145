import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  OneTemplate,
  OneTemplateSummary,
  OneTemplateContent
} from '@loggi/components';
import { useParams } from 'react-router-dom';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import PackagesSelectionTable from './unified-pickup-packages-table.component';
import PackagesApi from '../../operations/unified-pickup/packages-api';
import PackagesTablePagination from './unified-pickup-packages-table-pagination.component';
import PackageSelectionFilters from './filters/package-selection-filters.component';
import {
  FILTERS_NAMES,
  SELECTED_PACKAGES_KEY,
  SELECTED_PACKAGES_EXPIRES_IN_MINUTES
} from './constants';
import PackagesSelectedCounter from './unified-pickup-selected-packages-counter.component';
import usePackageSelectionManager from './package-selection-manager';
import PackagesNotFound from './packages-not-found.component';
import getSelectedPackages from './get-selected-packages.component';
import { PACKAGE_LIST_PAGE_SIZE } from '../constants';

const PackagesSelection = () => {
  const { t } = useTranslation('unifiedPickup');
  const { companyId } = useParams();
  const queryParams = useQueryParams();
  const page = queryParams[FILTERS_NAMES.page] || 1;
  const filterSearch = queryParams[FILTERS_NAMES.search];
  const filterDate = queryParams[FILTERS_NAMES.date];

  const [packagesResponse, setPackagesResponse] = useState();

  const [packagesSelectionInfo, setPackagesSelectionInfo] = useState({
    selectedPackages: getSelectedPackages(),
    allMatchingSearchSelected: false,
    hasChanged: false
  });
  const [isLoading, setIsLoading] = useState(true);
  const initialState = useMemo(
    () => ({
      isChecked: false,
      trackingKey: '',
      allFromPage: false,
      allFromMatchingSearch: false
    }),
    []
  );
  const packages = useMemo(() => packagesResponse?.packages || [], [
    packagesResponse
  ]);
  const [checkControlInfo, setCheckControlInfo] = useState({
    initialState
  });

  const manager = usePackageSelectionManager({
    currentSelectedPackages: packagesSelectionInfo.selectedPackages,
    currentPagePackages: packages
  });

  const handleSelection = ({
    isChecked,
    trackingKey = '',
    allFromPage = false,
    allFromMatchingSearch = false
  }) => {
    setCheckControlInfo({
      isChecked,
      trackingKey,
      allFromPage,
      allFromMatchingSearch
    });
  };

  const handleSingleOrAllFromPagePackagesSelected = useCallback(
    (isChecked, selectedPackages) => {
      const updatedSelectedPackages = manager.handleSingleOrAllFromPagePackagesSelected(
        selectedPackages,
        isChecked
      );

      setPackagesSelectionInfo({
        allMatchingSearchSelected: false,
        selectedPackages: updatedSelectedPackages,
        hasChanged: true
      });
    },
    [manager]
  );

  const handleAllFromMatchingSearchSelected = useCallback(
    isChecked => {
      manager
        .handleAllMachingSearchPackagesSelected({
          isChecked,
          totalPackages: packagesResponse?.total,
          query: filterSearch,
          packagesCreatedAtDays: filterDate,
          companyId
        })
        .then(response => {
          setPackagesSelectionInfo({
            allMatchingSearchSelected: isChecked,
            selectedPackages: response,
            hasChanged: true
          });
        });
    },
    [packagesResponse, companyId, filterDate, filterSearch, manager]
  );

  const saveSelectedPackagesInLocalStorage = useCallback(() => {
    if (packagesSelectionInfo.hasChanged) {
      const expirationHour = new Date();
      expirationHour.setMinutes(
        expirationHour.getMinutes() + SELECTED_PACKAGES_EXPIRES_IN_MINUTES
      );

      const selectedPackagesObj = Object.fromEntries(
        packagesSelectionInfo.selectedPackages
      );

      localStorage.setItem(
        SELECTED_PACKAGES_KEY,
        JSON.stringify({
          packages: selectedPackagesObj,
          expirationHour
        })
      );

      setPackagesSelectionInfo(prevState => ({
        ...prevState,
        hasChanged: false
      }));
    }
  }, [
    packagesSelectionInfo.hasChanged,
    packagesSelectionInfo.selectedPackages
  ]);

  useEffect(() => {
    saveSelectedPackagesInLocalStorage();
  }, [
    packagesSelectionInfo.selectedPackages,
    saveSelectedPackagesInLocalStorage
  ]);

  useEffect(() => {
    if (checkControlInfo.trackingKey) {
      const selectedPackage = packages.find(
        pkg => pkg.trackingKey === checkControlInfo.trackingKey
      );
      handleSingleOrAllFromPagePackagesSelected(checkControlInfo.isChecked, [
        selectedPackage
      ]);
      setCheckControlInfo(initialState);
    }

    if (checkControlInfo.allFromPage) {
      handleSingleOrAllFromPagePackagesSelected(
        checkControlInfo.isChecked,
        packages
      );
      setCheckControlInfo(initialState);
    }

    if (checkControlInfo.allFromMatchingSearch) {
      handleAllFromMatchingSearchSelected(checkControlInfo.isChecked);
      setCheckControlInfo(initialState);
    }
  }, [
    checkControlInfo,
    packages,
    initialState,
    handleAllFromMatchingSearchSelected,
    handleSingleOrAllFromPagePackagesSelected
  ]);

  useEffect(() => {
    const calculatePageToken = () => {
      const calculatedPageToken = (page - 1) * PACKAGE_LIST_PAGE_SIZE - 1;
      return calculatedPageToken > 0 ? calculatedPageToken : 0;
    };

    PackagesApi.list({
      companyId,
      pageToken: calculatePageToken(),
      query: filterSearch,
      packagesCreatedAtDays: filterDate
    })
      .then(response => {
        setPackagesResponse(response);
      })
      .finally(() => {
        setIsLoading(false);
        setPackagesSelectionInfo(prevState => ({
          ...prevState,
          allMatchingSearchSelected: false
        }));
      });
  }, [companyId, page, filterSearch, filterDate]);

  const totalPackages = packagesResponse?.total;
  const isFiltered = filterDate || filterSearch;
  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Typography variant="h4">
          <strong>{t('packageSelection.header.title')}</strong>
        </Typography>
      </OneTemplateSummary>
      <OneTemplateContent disablePaper>
        {isLoading && (
          <OneTemplateContent disablePadding>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="560px"
            >
              <CircularProgress
                justify="center"
                data-testid="circular-progress"
              />
            </Box>
          </OneTemplateContent>
        )}
        {!isLoading && (
          <>
            <OneTemplateContent disablePadding>
              {(totalPackages || isFiltered) && <PackageSelectionFilters />}
              {packages.length > 0 && (
                <>
                  <PackagesSelectionTable
                    packages={packages}
                    handleSelection={handleSelection}
                    selectedPackages={packagesSelectionInfo.selectedPackages}
                    currentPageSelected={manager.isAllCurrentPagePackagesSelected()}
                    allFromMatchingSearchSelected={
                      packagesSelectionInfo.allMatchingSearchSelected
                    }
                    totalPackages={totalPackages}
                  />
                  <Box pt={2} px={5.5} pb={4}>
                    <PackagesTablePagination
                      isLoading={isLoading}
                      count={totalPackages}
                    />
                  </Box>
                </>
              )}
              {packages.length === 0 && (
                <PackagesNotFound isFiltered={isFiltered} />
              )}
            </OneTemplateContent>
            <OneTemplateContent disablePadding>
              <PackagesSelectedCounter
                totalSelectedPackages={
                  packagesSelectionInfo.selectedPackages.size
                }
                totalPackages={packagesResponse?.total}
                handleSelection={handleSelection}
                allFromMatchingSearchSelected={
                  packagesSelectionInfo.allMatchingSearchSelected
                }
              />
            </OneTemplateContent>
          </>
        )}
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default PackagesSelection;
