/* eslint-disable camelcase */
import { useQueryParams } from '@loggi/components/src/one/hooks';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DEFAULT_RECURRING_PICKUPS_LIST_PAGE_SIZE } from '../../constants';

/**
 * This component's responsibilities are to show the pagination info, and allow
 * the user to navigate to others pages with the HistoryApi provided by react-router-dom
 * @componentType container
 * @param count
 * @param isLoading
 * @returns {null | *}
 * @constructor
 */
const RecurringPickupListPagination = ({ count, isLoading }) => {
  const { t } = useTranslation('scheduling');
  const queryParams = useQueryParams();
  const { recurring_page: page = 1 } = queryParams;

  const history = useHistory();
  const onChangePage = useCallback(
    (_, nextPage) => {
      const params = { ...queryParams, recurring_page: nextPage + 1 };
      history.push(
        // Encoding should be off because we want a "comma" separator in the URL.
        // We will encode later, when sending the request.
        qs.stringify(params, { addQueryPrefix: true, encode: false })
      );
    },
    [history, queryParams]
  );

  return (
    <TablePagination
      component="div"
      onChangePage={onChangePage}
      page={Number(page) - 1} // Prop starts at 0
      count={count}
      data-testid="recurring-pickup-table-pagination"
      labelDisplayedRows={({ from, to, count: total }) =>
        t('pagination.row', { from, to, count: total })
      }
      rowsPerPageOptions={[DEFAULT_RECURRING_PICKUPS_LIST_PAGE_SIZE]}
      rowsPerPage={DEFAULT_RECURRING_PICKUPS_LIST_PAGE_SIZE}
      backIconButtonProps={{
        disabled: isLoading || Number(page) === 1
      }}
      nextIconButtonProps={{
        disabled:
          isLoading || page * DEFAULT_RECURRING_PICKUPS_LIST_PAGE_SIZE >= count
      }}
    />
  );
};

RecurringPickupListPagination.propTypes = {
  count: PropTypes.number.isRequired,
  isLoading: PropTypes.bool
};

RecurringPickupListPagination.defaultProps = {
  isLoading: false
};

export default RecurringPickupListPagination;
