import { Box } from '@material-ui/core';
import React from 'react';
import RecurringPickupHelp from './recurring-pickup-help.component';
import RecurringPickupList from './recurring-pickup-list.component';

const RecurringPickupListScreen = () => {
  return (
    <>
      <Box pt={{ xs: 3, md: 4 }} px={4}>
        <RecurringPickupHelp />
      </Box>
      <Box mt={2.5} pb={{ xs: 2, md: 1.75 }}>
        <RecurringPickupList />
      </Box>
    </>
  );
};

export default RecurringPickupListScreen;
