import PackagesApi from '../../operations/unified-pickup/packages-api';

const usePackageSelectionManager = ({
  currentSelectedPackages = new Map(),
  currentPagePackages = []
}) => {
  const isAllCurrentPagePackagesSelected = () => {
    return currentPagePackages.every(pkg =>
      currentSelectedPackages.has(pkg.trackingKey)
    );
  };

  const addToSelectedPackages = selectedPackages => {
    selectedPackages.forEach(pkg => {
      currentSelectedPackages.set(pkg.trackingKey, pkg);
    });

    return currentSelectedPackages;
  };

  const removeFromSelectedPackages = selectedPackages => {
    selectedPackages.forEach(pkgToRemove => {
      currentSelectedPackages.delete(pkgToRemove.trackingKey);
    });

    return currentSelectedPackages;
  };

  const handleSingleOrAllFromPagePackagesSelected = (
    selectedPackages,
    isChecked
  ) => {
    if (isChecked) {
      return addToSelectedPackages(selectedPackages);
    }

    return removeFromSelectedPackages(selectedPackages);
  };

  const handleAllMachingSearchPackagesSelected = ({
    isChecked,
    totalPackages,
    query,
    packagesCreatedAtDays,
    companyId
  }) => {
    if (isChecked) {
      return PackagesApi.list({
        companyId,
        query,
        packagesCreatedAtDays,
        pageSize: totalPackages
      }).then(response => {
        let selectedPackages = new Map();

        if (response.packages) {
          selectedPackages = addToSelectedPackages(response.packages);
        }
        return selectedPackages;
      });
    }

    return Promise.resolve().then(() => new Map());
  };

  return {
    handleSingleOrAllFromPagePackagesSelected,
    handleAllMachingSearchPackagesSelected,
    isAllCurrentPagePackagesSelected
  };
};

export default usePackageSelectionManager;
