import {
  Box,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import formattedDocument from '@loggi/components/src/one/document-format/format-document';
import RecurringPickupItemPropTypes from './recurring-pickup-item.propTypes';
import RecurringSettingsOptions from './recurring-settings-options.component';

const useWeekdays = ({
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday
}) =>
  useMemo(() => {
    const days = [];

    if (monday) days.push('Segunda');
    if (tuesday) days.push('Terça');
    if (wednesday) days.push('Quarta');
    if (thursday) days.push('Quinta');
    if (friday) days.push('Sexta');
    if (saturday) days.push('Sábado');
    if (sunday) days.push('Domingo');

    if (monday && tuesday && wednesday && thursday && friday) {
      const allDays = [];
      allDays.push('Todos os dias');
      return { daysList: allDays };
    }

    return { daysList: days.join(', ').replace(/,([^,]*)$/, ' e$1') };
  }, [monday, tuesday, wednesday, thursday, friday, saturday, sunday]);

const parseTimeRange = (startTime, endTime) =>
  `${format(new Date(startTime), 'HH:mm')} às ${format(
    new Date(endTime),
    'HH:mm'
  )}`;

const RecurringPickupItem = ({ recurring, onDelete, isDesktop }) => {
  const { daysList } = useWeekdays(recurring);
  const timeRange = parseTimeRange(recurring.start_time, recurring.end_time);

  return isDesktop ? (
    <RecurringPickupTableItem
      recurring={recurring}
      onDelete={onDelete}
      daysList={daysList}
      timeRange={timeRange}
    />
  ) : (
    <RecurringPickupListItem
      recurring={recurring}
      onDelete={onDelete}
      daysList={daysList}
      timeRange={timeRange}
    />
  );
};

RecurringPickupItem.propTypes = {
  onDelete: PropTypes.func.isRequired,
  recurring: PropTypes.shape(RecurringPickupItemPropTypes).isRequired,
  isDesktop: PropTypes.bool
};

RecurringPickupItem.defaultProps = {
  isDesktop: true
};

export default RecurringPickupItem;

/**
 * RecurringPickupTableItem renders a static table that only presents the recurring it receives
 * @componentType presentational
 * @param recurring
 * @returns {*}
 * @constructor
 */

const RecurringPickupTableItem = ({
  recurring,
  onDelete,
  daysList,
  timeRange
}) => {
  const { spacing } = useTheme();

  return (
    <TableRow
      key={recurring.id}
      data-testid={`recurring-pickup-list-item-${recurring.id}`}
    >
      <TableCell>
        <Box ml={2} width={spacing(31)}>
          <Typography variant="button">{daysList}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box width={spacing(18.5)}>
          <Typography variant="button">{timeRange}</Typography>
        </Box>
      </TableCell>
      <TableCell style={{ fontWeight: '600' }}>
        <Box width={spacing(21)}>
          <Typography variant="button">
            {formattedDocument(recurring.document)}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box width={spacing(36)} overflow="hidden" textOverflow="ellipsis">
          <Typography variant="caption" color="textSecondary" noWrap>
            {recurring.origin_address.full_address}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box mr={2} width={spacing(3)}>
          <RecurringSettingsOptions
            onDelete={onDelete}
            recurring={recurring}
            timeRange={timeRange}
            daysList={daysList}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

RecurringPickupTableItem.propTypes = {
  onDelete: PropTypes.func.isRequired,
  recurring: PropTypes.shape(RecurringPickupItemPropTypes).isRequired,
  daysList: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  timeRange: PropTypes.string.isRequired
};

/**
 * RecurringPickupListItem renders a list item that only presents the recurring it receives
 * @componentType presentational
 * @param recurring
 * @returns {*}
 * @constructor
 */
const RecurringPickupListItem = ({
  recurring,
  onDelete,
  daysList,
  timeRange
}) => {
  const { typography } = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      pl={3}
      py={2.37}
      pr={1.5}
    >
      <Box maxWidth="80%">
        <Box>
          <Typography variant="body1">
            <strong>{daysList}</strong>
          </Typography>
        </Box>
        <Box pt={1}>
          <Typography
            variant="body2"
            style={{ fontWeight: typography.fontWeightMedium }}
          >
            {timeRange}
          </Typography>
        </Box>
        <Box pt={1}>
          <Typography
            variant="body2"
            style={{ fontWeight: typography.fontWeightMedium }}
          >
            {formattedDocument(recurring.document)}
          </Typography>
        </Box>
        <Box overflow="hidden" textOverflow="ellipsis" pt={1}>
          <Typography variant="caption" color="textSecondary" noWrap>
            {recurring.origin_address.full_address}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" pr={1.5}>
        <RecurringSettingsOptions
          onDelete={onDelete}
          recurring={recurring}
          timeRange={timeRange}
          daysList={daysList}
        />
      </Box>
    </Box>
  );
};

RecurringPickupListItem.propTypes = {
  onDelete: PropTypes.func.isRequired,
  recurring: PropTypes.shape(RecurringPickupItemPropTypes).isRequired,
  daysList: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  timeRange: PropTypes.string.isRequired
};
