import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PICKUP_ORDER_SCHEDULE_PACKAGES_ROUTE from './constants';
import PickupOrderShedulePackages from '../screens/pickup-order-schedule-packages/pickup-order-shedule-packages.component';
import AppErrorBoundary from './error-boundary.component';

const Routes = () => {
  return (
    <AppErrorBoundary>
      <Switch>
        <Route
          exact
          path={PICKUP_ORDER_SCHEDULE_PACKAGES_ROUTE.path}
          component={PickupOrderShedulePackages}
        />
      </Switch>
    </AppErrorBoundary>
  );
};

export default Routes;
