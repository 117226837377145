import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Chip, useTheme } from '@material-ui/core';

import SummaryDateItem from './summary-date-item.component';

/**
 * PackagePromisedDate only responsibility is to show the promised date info
 * and show a Delayed tag if package is delayed
 * @componentType: Presentational
 * @param date
 * @param isPackageDelayed
 * @returns {*}
 */
const PackageDeliveryPromisedDate = ({ date, isPackageDelayed }) => {
  const { t } = useTranslation('packages');
  const theme = useTheme();

  const style = {
    chip: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.text.primary
    }
  };

  return (
    <>
      <SummaryDateItem
        date={date}
        label={t('details.labels.promisedDate')}
        dataTestId="promised-date-info"
      />
      {isPackageDelayed && (
        <Box alignSelf="flex-start" mt={5} ml={1}>
          <Chip
            size="small"
            label={t('details.labels.withProblem')}
            style={style.chip}
          />
        </Box>
      )}
    </>
  );
};

PackageDeliveryPromisedDate.propTypes = {
  date: PropTypes.string.isRequired,
  isPackageDelayed: PropTypes.bool.isRequired
};

export default PackageDeliveryPromisedDate;
