import { useQueryParams } from '@loggi/components/src/one/hooks';
import { useFeature } from '@loggi/components/src/one/remote-config';
import recurringPickupApi from '@loggi/pickups/src/unified-pickup/recurring-pickup-api';
import {
  Box,
  CircularProgress,
  Divider,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Replay } from '@material-ui/icons';
import React, { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TableCellHead from '../table-cell-head';
import { ReactComponent as ErrorImage } from './images/error.svg';
import RecurringPickupListBlankState from './recurring-pickup-list-blank-state.component';
import RecurringPickupItem from './recurring-pickup-list-item.component';
import RecurringPickupListPagination from './recurring-pickup-list-pagination.component';
import RecurringPickupSearchBar from './recurring-pickup-search-bar.component';
import { DEFAULT_RECURRING_PICKUPS_LIST_PAGE_SIZE } from '../../constants';

// eslint-disable-next-line consistent-return
const reducer = (state, { type, payload }) => {
  // eslint-disable-next-line default-case
  switch (type) {
    case 'fetch':
      return {
        data: undefined,
        loading: true,
        errorMessage: ''
      };
    case 'fetchOk':
      return {
        data: payload.data,
        loading: false,
        errorMessage: ''
      };
    case 'fetchError':
      return {
        data: undefined,
        loading: false,
        errorMessage: payload.errorMessage
      };
  }
};

const INITIAL_STATE = {
  loading: true,
  data: undefined,
  errorMessage: ''
};

const RecurringPickupList = () => {
  const { companyId } = useParams();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { data, loading, errorMessage } = state;
  const { t } = useTranslation('scheduling');
  const theme = useTheme();
  const { spacing } = theme;
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const {
    recurring_document: recurringDocument,
    recurring_page: page
  } = useQueryParams();
  const shouldUsePickupsApi = useFeature('should_use_pickups_api');

  const fetchRecurrences = useCallback(() => {
    dispatch({ type: 'fetch' });

    recurringPickupApi
      .index(companyId, recurringDocument, page, shouldUsePickupsApi)
      .then(response => {
        dispatch({ type: 'fetchOk', payload: { data: response } });
      })
      .catch(({ message }) => {
        dispatch({ type: 'fetchError', payload: { errorMessage: message } });
      });
  }, [companyId, recurringDocument, page, shouldUsePickupsApi]);

  useEffect(() => {
    fetchRecurrences();
  }, [fetchRecurrences]);

  const fetchDataResults = data?.results || [];

  const dataIsEmpty = fetchDataResults.length === 0;

  const hasPagination = data?.count > DEFAULT_RECURRING_PICKUPS_LIST_PAGE_SIZE;

  const shouldSearchBarBeVisible = !!recurringDocument || hasPagination;

  if (loading || errorMessage !== '')
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        minHeight={170}
        textAlign="center"
      >
        {loading ? (
          <CircularProgress data-testid="loading-recurring" />
        ) : (
          <Fade in>
            <Box>
              <Box mb={2}>
                <ErrorImage />
              </Box>
              <Box maxWidth={320}>
                <Typography>{errorMessage}</Typography>
              </Box>
              <Box mt={1} mb={-1}>
                <IconButton
                  data-testid="retry"
                  color="primary"
                  onClick={fetchRecurrences}
                >
                  <Replay />
                </IconButton>
              </Box>
            </Box>
          </Fade>
        )}
      </Box>
    );

  const recurringPickupTable = () => {
    return (
      <TableContainer component={Paper} data-testid="recurring-pickup-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHead ml={2}>
                {t('recurringPickupSettingsList.tableDataHeaders.weekdays')}
              </TableCellHead>
              <TableCellHead>
                {t('recurringPickupSettingsList.tableDataHeaders.hours')}
              </TableCellHead>
              <TableCellHead>
                {t('recurringPickupSettingsList.tableDataHeaders.document')}
              </TableCellHead>
              <TableCellHead>
                {t('recurringPickupSettingsList.tableDataHeaders.address')}
              </TableCellHead>
              <TableCellHead mr={2} />
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchDataResults.map(recurring => (
              <RecurringPickupItem
                onDelete={fetchRecurrences}
                recurring={recurring}
                isDesktop={isDesktop}
                key={recurring.id}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const recurringPickupList = () => {
    return (
      <List style={{ width: '100%' }} data-testid="recurring-pickup-list">
        {fetchDataResults.map(recurring => (
          <>
            <Divider />
            <React.Fragment key={recurring.id}>
              <ListItem
                disableGutters
                component="div"
                style={{ width: '100%' }}
              >
                <ListItemText
                  key={recurring.id}
                  primary={
                    <RecurringPickupItem
                      recurring={recurring}
                      onDelete={fetchRecurrences}
                      isDesktop={isDesktop}
                    />
                  }
                />
              </ListItem>
            </React.Fragment>
          </>
        ))}
      </List>
    );
  };

  const recurringPickupItemComponent = () => {
    return isDesktop ? recurringPickupTable() : recurringPickupList();
  };

  return (
    <Box>
      {shouldSearchBarBeVisible && (
        <Box
          pb={2.5}
          maxWidth={{ xs: '100%', md: spacing(55) }}
          px={{ xs: 3, md: 4 }}
        >
          <RecurringPickupSearchBar />
        </Box>
      )}
      {dataIsEmpty ? (
        <RecurringPickupListBlankState />
      ) : (
        <>
          {recurringPickupItemComponent()}
          {hasPagination && (
            <RecurringPickupListPagination
              count={data?.count || 0}
              isLoading={loading}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default RecurringPickupList;
