export const ROOT_PAYMENTS_PATH = `/one/api/payments/v1/companies/`;

export const GET_CLOSED_INVOICES_ENDPOINT = companyId =>
  `${ROOT_PAYMENTS_PATH}${companyId}/closed-invoices/`;

export const GET_PRICE_AGREEMENTS_ENDPOINT = companyId =>
  `${ROOT_PAYMENTS_PATH}${companyId}/price-agreements/`;

export const GENERATE_SPREADSHEET_ENDPOINT = (companyId, priceAgreementId) =>
  `${ROOT_PAYMENTS_PATH}${companyId}/price-agreements/${priceAgreementId}/price-spreadsheet/`;

export const SPREADSHEET_GENERATION_STATUS_ENDPOINT = (
  companyId,
  priceAgreementId,
  generationId
) =>
  `${ROOT_PAYMENTS_PATH}${companyId}/price-agreements/${priceAgreementId}/price-spreadsheet/${generationId}`;
