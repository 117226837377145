import React from 'react';
import PropTypes from 'prop-types';
import HeaderPickupOrderSchedulePackages from '@loggi/pickup-order-schedule/src/components/header';
import HeaderPickupReceipt from '@loggi/pickup-receipt/src/components/header';
import Header from '../header';

const EnviosHeader = ({
  isPickupReceiptPublic,
  isPickupOrderSchedulePackages,
  isDefaulter,
  isOutOfCoverageCompanyStatus
}) => {
  return (
    (isPickupReceiptPublic && <HeaderPickupReceipt />) ||
    (isPickupOrderSchedulePackages && (
      <HeaderPickupOrderSchedulePackages />
    )) || (
      <Header
        isDefaulter={isDefaulter}
        isOutOfCoverage={isOutOfCoverageCompanyStatus}
      />
    )
  );
};

EnviosHeader.propTypes = {
  isPickupReceiptPublic: PropTypes.bool.isRequired,
  isPickupOrderSchedulePackages: PropTypes.bool.isRequired,
  isDefaulter: PropTypes.bool.isRequired,
  isOutOfCoverageCompanyStatus: PropTypes.bool.isRequired
};

export default EnviosHeader;
