import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox
} from '@material-ui/core';
import TableCellHead from './table-cell-head';
import TableRowItem from './table-row';

const useStyles = makeStyles(({ spacing }) => ({
  checkBoxCell: {
    paddingLeft: spacing(4),
    paddingRight: 0,
    margin: 0
  }
}));

/**
 * PackagesListTable renders a static table that only presents the data it receives
 * @componentType presentational
 * @param data
 * @param handleToggle
 * @param checked
 * @returns {*}
 * @constructor
 */
const PackagesListTable = ({
  data,
  handleToggle,
  checked,
  useHierarchySpecifcs
}) => {
  const classes = useStyles();
  const { t } = useTranslation('packages');

  const allSelected = data.length === checked.length;

  const tableBody = () => {
    return data.map(pkg => (
      <TableRowItem
        key={pkg.trackingKey}
        pkg={pkg}
        handleToggle={handleToggle}
        checked={checked}
        useHierarchySpecifcs={useHierarchySpecifcs}
      />
    ));
  };
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.checkBoxCell}>
              <Checkbox
                data-testid="selectAllCheckbox"
                disableRipple
                checked={allSelected}
                onClick={() => handleToggle()}
              />
            </TableCell>
            <TableCellHead>{t('list.listHeader.status')}</TableCellHead>
            <TableCellHead ml={2}>
              {t('list.listHeader.recipient')}
            </TableCellHead>
            {useHierarchySpecifcs && (
              <TableCellHead ml={2}>
                {t('list.listHeader.companyCnpj')}
              </TableCellHead>
            )}
            <TableCellHead>{t('list.listHeader.address')}</TableCellHead>
            <TableCellHead>{t('list.listHeader.trackingKey')}</TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>{tableBody()}</TableBody>
      </Table>
    </TableContainer>
  );
};

PackagesListTable.propTypes = {
  data: PropTypes.arrayOf(packageProps),
  handleToggle: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  useHierarchySpecifcs: PropTypes.bool
};
PackagesListTable.defaultProps = {
  data: [],
  useHierarchySpecifcs: false
};

export default PackagesListTable;
