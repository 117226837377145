import {
  AddressField,
  ComplementField,
  NoComplementField
} from '@loggi/components/src/one/formik-fields';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

const AddressSection = () => {
  const { t } = useTranslation('management');
  const addressField = 'returnAddress';
  const complementFieldName = 'returnAddressComplement';

  return (
    <>
      <Box pb={4}>
        <Typography variant="h6">
          <Box component="strong" fontWeight="fontWeightBold">
            {t('returnAddress.addressSection.title')}
          </Box>
        </Typography>
      </Box>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
        <Box flex="1">
          <Box pr={{ sm: 5 }}>
            <AddressField fieldName={addressField} />
          </Box>
        </Box>
        <Box flex="1">
          <Box display="flex" pt={{ xs: 4, sm: 0 }}>
            <Box flex="1" pr={{ xs: 2, sm: 5 }}>
              <ComplementField fieldName={complementFieldName} />
            </Box>
            <Box flex="1" mt={1}>
              <NoComplementField complementFieldName={complementFieldName} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddressSection;
