import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ palette }) => ({
  cancelCancellationButton: {
    size: 'large',
    variant: 'contained',
    color: palette.common.white,
    backgroundColor: palette.error.dark,
    '&:hover': {
      backgroundColor: palette.error.main
    }
  },
  bulkCancellationButton: {
    borderColor: palette.error.dark,
    color: palette.error.dark,
    backgroundColor: palette.common.white,
    '&:hover': {
      backgroundColor: palette.error.main,
      color: palette.common.white
    }
  }
}));

const PackagesBulkCancellationButton = ({
  trackingKeysSelected,
  callback,
  hasMixedCompanyIds,
  handleBulkActionAlert
}) => {
  const [dialogState, setDialogState] = React.useState(false);
  const classes = useStyles();

  const { t } = useTranslation('packages');

  const handleButtonClick = () =>
    hasMixedCompanyIds ? handleBulkActionAlert(true) : setDialogState(true);

  return (
    <>
      <Box mb={{ xs: 2, sm: 0 }} pr={{ xs: 1, md: 2 }}>
        <Button
          variant="outlined"
          size="small"
          fullWidth
          className={classes.bulkCancellationButton}
          data-testid="bulkCancellationButton"
          onClick={handleButtonClick}
        >
          {t('list.bulkCancellation.activeButton', {
            count: trackingKeysSelected.length
          })}
        </Button>
      </Box>
      <Dialog
        open={dialogState}
        data-testid="bulkCancellationConfirmationDialog"
      >
        <Box paddingX={3} pt={2} pb={2}>
          <Box display="flex">
            <Box>
              <Box pb={2} fontSize={20} fontWeight="bold">
                {t('list.bulkCancellation.confirmationDialog.title')}
              </Box>
              <Box fontSize={16}>
                {t('list.bulkCancellation.confirmationDialog.body')}
              </Box>
            </Box>
          </Box>
          <Box pt={3} justifyContent="flex-end" display="flex">
            <Button
              size="large"
              color="primary"
              data-testid="cancelCancellationButton"
              onClick={() => {
                setDialogState(false);
              }}
            >
              {t('list.bulkCancellation.confirmationDialog.cancelCancellation')}
            </Button>
            <Button
              className={classes.cancelCancellationButton}
              onClick={() => {
                callback(true);
                setDialogState(false);
              }}
            >
              {t(
                'list.bulkCancellation.confirmationDialog.confirmCancellation'
              )}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

PackagesBulkCancellationButton.propTypes = {
  trackingKeysSelected: PropTypes.arrayOf(PropTypes.string),
  callback: PropTypes.func,
  hasMixedCompanyIds: PropTypes.bool.isRequired,
  handleBulkActionAlert: PropTypes.func.isRequired
};

PackagesBulkCancellationButton.defaultProps = {
  trackingKeysSelected: [],
  callback: undefined
};

export default PackagesBulkCancellationButton;
