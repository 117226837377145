import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  useTheme,
  TableContainer,
  Box,
  useMediaQuery,
  ButtonBase,
  Link
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getAddress } from '@loggi/components/src/one/package-utils/address.helpers';
import formattedDocument from '@loggi/components/src/one/document-format/format-document';
import formatPackageCreationDate from './package-info-format';
import { PACKAGE_LIST_PAGE_SIZE } from '../constants';

const PackagesSelectionTable = ({
  packages,
  handleSelection,
  selectedPackages,
  currentPageSelected,
  allFromMatchingSearchSelected,
  totalPackages
}) => {
  const { t } = useTranslation('unifiedPickup');
  const { typography, palette, spacing } = useTheme();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  const packagesList = () => {
    return packages.map(pkg => {
      const isChecked = selectedPackages?.has(pkg.trackingKey);
      return (
        <TableRow key={`${pkg.trackingKey}-row`} tabIndex={-1} hover>
          <TableCell key={`${pkg.trackingKey}-cb`}>
            <Checkbox
              data-testid={`${pkg.trackingKey}-cb`}
              checked={isChecked}
              onClick={() =>
                handleSelection({
                  isChecked: !isChecked,
                  trackingKey: pkg.trackingKey
                })
              }
            />
          </TableCell>
          <TableCell key={`${pkg.trackingKey}-name`}>
            <Typography
              variant="body2"
              color="textPrimary"
              style={{ fontWeight: typography.fontWeightBold }}
            >
              {pkg.recipient.fullName}
            </Typography>
          </TableCell>
          <TableCell key={`${pkg.trackingKey}-address`}>
            <Box
              overflow="hidden"
              width={{ xs: spacing(12), sm: spacing(60) }}
              textOverflow="ellipsis"
              position="relative"
            >
              <Typography variant="body2" color="textSecondary" noWrap>
                {getAddress(pkg.recipient.address)}
              </Typography>
            </Box>
          </TableCell>
          {isDesktop && (
            <>
              <TableCell key={`${pkg.trackingKey}-document`}>
                <Typography variant="body2" color="textSecondary">
                  {formattedDocument(pkg.senderCpfCnpj)}
                </Typography>
              </TableCell>
              <TableCell key={`${pkg.trackingKey}-creation-date`}>
                <Typography variant="body2" color="textSecondary">
                  {formatPackageCreationDate(pkg.creationTime)}
                </Typography>
              </TableCell>
            </>
          )}
        </TableRow>
      );
    });
  };

  const selectionControlInfo = (
    textFirst,
    textStrong,
    textLast,
    textLink,
    onClick
  ) => {
    return (
      <TableRow
        style={{
          backgroundColor: palette.background.default,
          position: 'sticky',
          top: 66,
          zIndex: 1
        }}
      >
        <TableCell colSpan={isDesktop ? 5 : 3} style={{ textAlign: 'center' }}>
          <Box display="flex" textAlign="center" justifyContent="center">
            <Typography variant="body2" color="textPrimary">
              {textFirst}
              <strong>{textStrong}</strong>
              <span style={{ whiteSpace: 'pre' }}>{textLast}</span>
              <ButtonBase
                component={Link}
                onClick={onClick}
                data-testid="select-all-link"
                style={{
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  verticalAlign: 'baseline',
                  color: palette.primary.main
                }}
              >
                {textLink}
              </ButtonBase>
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <TableContainer style={{ maxHeight: spacing(43.25) }}>
        <Table data-testid="package-selection-table">
          <TableHead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <TableRow>
              <TableCell>
                <Checkbox
                  data-testid="select-all-pkgs-cb"
                  checked={currentPageSelected}
                  onClick={() => {
                    handleSelection({
                      isChecked: !currentPageSelected,
                      allFromPage: true
                    });
                  }}
                />
              </TableCell>
              <TableCell key="header-name">
                <Typography
                  variant="body2"
                  color="textPrimary"
                  style={{ fontWeight: typography.fontWeightBold }}
                >
                  {t('packageSelection.tableHeader.recipientName')}
                </Typography>
              </TableCell>
              <TableCell key="header-address">
                <Typography
                  variant="body2"
                  color="textPrimary"
                  style={{ fontWeight: typography.fontWeightBold }}
                >
                  {t('packageSelection.tableHeader.recipientAddress')}
                </Typography>
              </TableCell>
              {isDesktop && (
                <>
                  <TableCell key="header-document">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontWeight: typography.fontWeightBold }}
                    >
                      {t('packageSelection.tableHeader.senderDocument')}
                    </Typography>
                  </TableCell>
                  <TableCell key="header-creation-date">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontWeight: typography.fontWeightBold }}
                    >
                      {t('packageSelection.tableHeader.packageCreationDate')}
                    </Typography>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {allFromMatchingSearchSelected &&
              totalPackages > PACKAGE_LIST_PAGE_SIZE &&
              selectionControlInfo(
                t(
                  'packageSelection.selectionInfo.allFromMatchingSearch.textFirst'
                ),
                t(
                  'packageSelection.selectionInfo.allFromMatchingSearch.textStrong'
                ),
                t(
                  'packageSelection.selectionInfo.allFromMatchingSearch.textLast'
                ),
                t(
                  'packageSelection.selectionInfo.allFromMatchingSearch.textLink'
                ),
                () => {
                  handleSelection({
                    isChecked: false,
                    allFromMatchingSearch: true
                  });
                }
              )}
            {!allFromMatchingSearchSelected &&
              currentPageSelected &&
              totalPackages > PACKAGE_LIST_PAGE_SIZE &&
              selectionControlInfo(
                t('packageSelection.selectionInfo.allFromPage.textFirst'),
                t('packageSelection.selectionInfo.allFromPage.textStrong', {
                  count: PACKAGE_LIST_PAGE_SIZE
                }),
                t('packageSelection.selectionInfo.allFromPage.textLast'),
                t('packageSelection.selectionInfo.allFromPage.textLink'),
                () => {
                  handleSelection({
                    isChecked: true,
                    allFromMatchingSearch: true
                  });
                }
              )}
            {packagesList()}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const packageShape = PropTypes.shape({
  recipient: PropTypes.objectOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      cpfCnpj: PropTypes.string,
      address: PropTypes.objectOf(
        PropTypes.shape({
          addressNumber: PropTypes.string,
          vicinity: PropTypes.string,
          city: PropTypes.string,
          state: PropTypes.string,
          addressComplement: PropTypes.string
        })
      )
    })
  ),
  creationTime: PropTypes.string
});

PackagesSelectionTable.propTypes = {
  packages: PropTypes.arrayOf(packageShape).isRequired,
  handleSelection: PropTypes.func.isRequired,
  selectedPackages: PropTypes.arrayOf(PropTypes.string),
  currentPageSelected: PropTypes.bool,
  allFromMatchingSearchSelected: PropTypes.bool,
  totalPackages: PropTypes.number.isRequired
};

PackagesSelectionTable.defaultProps = {
  selectedPackages: new Map(),
  currentPageSelected: false,
  allFromMatchingSearchSelected: false
};

export default PackagesSelectionTable;
