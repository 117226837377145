import { Drawer, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const CustomDrawer = withStyles(({ breakpoints, spacing }) => ({
  paper: {
    [breakpoints.up('sm')]: {
      maxWidth: spacing(58),
      minWidth: spacing(58)
    },
    width: '100%'
  }
}))(Drawer);

const InvoiceDetailsDrawer = ({ children, onClose }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setDrawerOpen(true);
  }, []);

  return (
    <CustomDrawer anchor="right" onClose={onClose} open={isDrawerOpen}>
      <Box px={{ xs: 3, sm: 5 }} py={5}>
        <IconButton
          onClick={onClose}
          edge="start"
          data-testid="close-drawer-invoice"
        >
          <ArrowBack color="primary" />
        </IconButton>
      </Box>
      <Box px={{ xs: 3, sm: 5 }}>{children}</Box>
    </CustomDrawer>
  );
};

InvoiceDetailsDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired
};

export default InvoiceDetailsDrawer;
