import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import SummaryDateItem from './summary-date-item.component';

/**
 * PackageWaitingRedispatchPickupDate only responsibility is to show
 * the deadline date to pickup a redispatch package.
 * @componentType: Presentational
 * @param date
 * @returns {*}
 */
const PackageWaitingRedispatchPickupDate = ({ date }) => {
  const { t } = useTranslation('packages');

  return (
    <SummaryDateItem
      date={date}
      label={t('details.labels.redispatchPickupDate')}
      dataTestId="redispatch-pickup-date-info"
    />
  );
};

PackageWaitingRedispatchPickupDate.propTypes = {
  date: PropTypes.string.isRequired
};

export default PackageWaitingRedispatchPickupDate;
