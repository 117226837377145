import PropTypes from 'prop-types';

const RecurringPickupItemPropTypes = {
  company_id: PropTypes.string.isRequired,
  document: PropTypes.string.isRequired,
  end_time: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  instructions: PropTypes.string.isRequired,
  monday: PropTypes.bool.isRequired,
  origin_address: PropTypes.shape({
    address_components: PropTypes.shape({
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      neighborhood: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      postal_code: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired
    }),
    /* eslint-disable-next-line react/forbid-prop-types */
    coordinates: PropTypes.object.isRequired,
    full_address: PropTypes.string.isRequired
  }),
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      height_cm: PropTypes.string.isRequired,
      length_cm: PropTypes.string.isRequired,
      tracking_key: PropTypes.string.isRequired,
      weight_g: PropTypes.string.isRequired,
      width_cm: PropTypes.string.isRequired
    }).isRequired
  ),
  shipper_name: PropTypes.string.isRequired,
  shipper_phone: PropTypes.string.isRequired,
  start_time: PropTypes.string.isRequired,
  starting_from_date: PropTypes.string.isRequired,
  wednesday: PropTypes.bool.isRequired
};

export default RecurringPickupItemPropTypes;
