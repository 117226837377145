import marTheme from '@loggi/mar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import { i18nInit } from '../config';
import App from './app.component';
import { routes } from '../management';

i18nInit();

const RootApp = () => (
  <ThemeProvider theme={marTheme}>
    <SnackbarProvider
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      autoHideDuration={7000}
      maxSnack={3}
    >
      <CssBaseline />
      <Router>
        <Switch>
          <Route path={routes.index.path} component={App} />
          <Redirect to={routes.index.url(1)} />
        </Switch>
      </Router>
    </SnackbarProvider>
  </ThemeProvider>
);

export default RootApp;
