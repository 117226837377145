import { loggiWebApi } from '@loggi/authentication-lib';
import { ACCOUNTS_COMPANIES_ENDPOINT } from '../../../../constants';

const getCompaniesList = () =>
  loggiWebApi
    .url(`/${ACCOUNTS_COMPANIES_ENDPOINT}`)
    .get()
    .json();

export default getCompaniesList;
