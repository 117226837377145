import { useEffect } from 'react';

const subscribers = {};

const subscribe = (event, callback) => {
  if (typeof event !== 'string')
    throw new Error('[useSubscription] event name should be a string');

  if (!subscribers[event]) {
    subscribers[event] = [callback];
  } else {
    subscribers[event] = [...subscribers[event], callback];
  }

  return () => {
    subscribers[event] = subscribers[event].filter(
      eventCallback => eventCallback !== callback
    );
  };
};

export const dispatchEvent = (event, data) => {
  if (!subscribers[event] || !subscribers[event].length) return;

  subscribers[event].forEach(callback => callback(data));
};

export const useSubscription = (event = '', callback = () => {}, deps = []) => {
  useEffect(() => subscribe(event, callback), [...deps, event, callback]); // eslint-disable-line react-hooks/exhaustive-deps
};
