import { useFeature } from '@loggi/components/src/one/remote-config';
import {
  Box,
  Button,
  Divider,
  fade,
  Hidden,
  Link,
  makeStyles,
  Popover,
  SwipeableDrawer,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useCurrentCompany } from '../../auth';
import { SIGN_UP_COMPANY_ROUTE } from '../../signup-company/routes/constants';
import CompaniesList from './companies-list';
import CompaniesSearchBar from './companies-search-bar';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    popover: {
      '& .MuiPopover-paper.MuiPaper-root': {
        marginTop: spacing(1),
        [breakpoints.only('xs')]: {
          width: 'calc(100% - 48px)'
        },
        [breakpoints.up('sm')]: {
          width: typography.pxToRem(320)
        }
      }
    },
    drawer: {
      '& .MuiDrawer-paper.MuiPaper-root': {
        borderRadius: spacing(2, 2, 0, 0)
      }
    },
    addNewCompany: {
      width: '100%',
      borderRadius: 0,
      padding: spacing(3),
      minHeight: typography.pxToRem(56),
      '&:hover': {
        backgroundColor: fade(palette.primary.light, 0.1),
        textDecoration: 'none'
      },
      '&.Mui-disabled': {
        color: palette.text.disabled
      },
      '&.Mui-disabled path': {
        fill: palette.text.disabled
      },
      [breakpoints.up('sm')]: {
        padding: spacing(2.5, 3)
      }
    }
  })
);

const CompaniesListMenuContent = ({
  hasCompany,
  companies,
  shouldRemoveCompanySelection
}) => {
  const { push } = useHistory();
  const { t } = useTranslation('components');
  const match = useRouteMatch('/empresas/:companyId');
  const companyIdFromRoute = match?.params?.companyId;
  const { pathname } = useLocation();
  const accessDeniedScreen = pathname.includes('acesso-negado');
  const currentCompany = useCurrentCompany();
  const currentCompanyId = currentCompany?.id;
  const companyId = accessDeniedScreen ? currentCompanyId : companyIdFromRoute;
  const styles = useStyles();

  const hasAccessToAnyCompany = Boolean(companies?.length);

  const redirectAddNewCompany = () =>
    push(SIGN_UP_COMPANY_ROUTE, { addNewCompany: true });

  const enableAddNewCompany = useFeature('enable_add_new_company', companyId);

  const [filteredCompanies, setFilteredCompanies] = useState([...companies]);

  return (
    <>
      {companies.length > 1 && (
        <CompaniesSearchBar
          companies={companies}
          setFilteredCompanies={setFilteredCompanies}
        />
      )}
      <Box mt={2} pl={3} mb={2}>
        <Typography
          style={{ lineHeight: '20px', fontSize: '16px', fontWeight: '600' }}
        >
          {t('header.companiesMenu.title')}
        </Typography>
      </Box>
      {hasAccessToAnyCompany && (
        <CompaniesList
          data-testid="companies-list-id"
          companies={companies}
          shouldRemoveCompanySelection={shouldRemoveCompanySelection}
          filteredCompanies={filteredCompanies}
        />
      )}
      <Divider />
      {hasCompany && enableAddNewCompany && (
        <Box
          fontWeight="fontWeightBold"
          component="strong"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            className={styles.addNewCompany}
            color="primary"
            component={Link}
            data-testid="add-new-company"
            onClick={redirectAddNewCompany}
          >
            {t('header.companiesMenu.addNewCompany')}
          </Button>
        </Box>
      )}
    </>
  );
};

CompaniesListMenuContent.propTypes = {
  hasCompany: PropTypes.bool.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape([
      PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          shared_name: PropTypes.string,
          cnpj: PropTypes.string
        })
      )
    ])
  ),
  shouldRemoveCompanySelection: PropTypes.bool.isRequired
};

CompaniesListMenuContent.defaultProps = {
  companies: []
};

const CompaniesListMenu = ({
  anchorElement,
  companies,
  handleClose,
  handleOpen,
  hasCompany,
  open,
  shouldRemoveCompanySelection
}) => {
  const styles = useStyles();

  return (
    <>
      <Hidden smUp>
        <SwipeableDrawer
          anchor="bottom"
          className={styles.drawer}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          <CompaniesListMenuContent
            hasCompany={hasCompany}
            companies={companies}
            shouldRemoveCompanySelection={shouldRemoveCompanySelection}
          />
        </SwipeableDrawer>
      </Hidden>
      <Hidden xsDown>
        <Popover
          PaperProps={{
            style: { width: '380px' }
          }}
          anchorEl={anchorElement}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          className={styles.popover}
          elevation={24}
          marginThreshold={24}
          onClose={handleClose}
          open={open}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <CompaniesListMenuContent
            hasCompany={hasCompany}
            companies={companies}
            shouldRemoveCompanySelection={shouldRemoveCompanySelection}
          />
        </Popover>
      </Hidden>
    </>
  );
};

CompaniesListMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  hasCompany: PropTypes.bool.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape([
      PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          shared_name: PropTypes.string,
          cnpj: PropTypes.string
        })
      )
    ])
  ),
  shouldRemoveCompanySelection: PropTypes.bool.isRequired,
  anchorElement: PropTypes.instanceOf(HTMLButtonElement)
};

CompaniesListMenu.defaultProps = {
  anchorElement: null,
  companies: []
};

export default CompaniesListMenu;
