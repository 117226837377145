import { loggiWebApi } from '@loggi/authentication-lib';
import {
  PICKUPS_COMPANIES_ENDPOINT,
  SUBSCRIBE_NOTIFICATIONS_ENDPOINT
} from '../../constants';

/**
 * This method is responsible for reaching the PickupOrderScheduling API
 * and registering the device's token to an FCM Topic..
 * @param {number} pickupOrderScheduleId - must be greater than zero
 * @param {string} token
 * @returns {Promise<PickupOrderScheduling[]>}
 */
const notificationSubscriptionService = (
  companyId,
  pickupOrderScheduleId,
  token
) => {
  const requestParams = {
    pickup_order_schedule_id: pickupOrderScheduleId,
    token
  };

  return loggiWebApi
    .url(
      `/${PICKUPS_COMPANIES_ENDPOINT}/${companyId}/${SUBSCRIBE_NOTIFICATIONS_ENDPOINT}/`
    )
    .accept('application/json')
    .content('application/json')
    .post(requestParams);
};

export default notificationSubscriptionService;
