const DEFAULT_SEARCH_DAYS = 30;

export const PACKAGES_DATE_FILTER_VALUES = [3, 7, 15, 30];
export const FILTERS_NAMES = {
  date: 'periodo',
  page: 'pagina',
  search: 'busca'
};

export const SELECTED_PACKAGES_KEY = 'selected_packages';
export const SELECTED_PACKAGES_EXPIRES_IN_MINUTES = 30;

export default DEFAULT_SEARCH_DAYS;
