const CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_CANCELLED = 2;
const CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_RECIPIENT_REFUSED = 6;
const CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_LOST = 9;
const CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_DAMAGED = 19;
const CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_INVALID_DESTINATION = 21;
const CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_AWAITING_RESOLUTION = 22;
const CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_STOLEN = 23;

export const CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_DELIVERED = 5;
export const CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_WITH_PROBLEM = 6;
export const CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_RETURNING_COMPLETED = 8;

export const CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_ADDED = '1';
export const CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_ON_DELIVERY_ROUTE = '11';
export const CUSTOM_SHIPPER_PACKAGE_STATUS_INTEGRATION_FAILURE_SHIPPER_CODE =
  '26';
export const CUSTOM_SHIPPER_PACKAGE_STATUS_INTEGRATION_FAILURE_LOGGI_CODE =
  '27';
export const CUSTOM_SHIPPER_PACKAGE_STATUS_PENDING_CUSTOMER_PICKUP = '28';

export const STATUSES_WITH_PROBLEM_GROUP_V1 = [
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_WITH_PROBLEM
];
export const STATUSES_WITH_PROBLEM_GROUP_V2 = [
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_RECIPIENT_REFUSED,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_INVALID_DESTINATION,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_AWAITING_RESOLUTION
];

export const FINAL_STATUSES_GROUP_V1 = [
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_CANCELLED,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_DELIVERED,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_LOST,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_RETURNING_COMPLETED
];

export const FINAL_STATUSES_GROUP_V2 = [
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_CANCELLED,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_DELIVERED,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_LOST,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_RETURNING_COMPLETED,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_DAMAGED,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_STOLEN
];
