import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AuthForm from './auth-form.component';

const Auth = () => {
  const { t } = useTranslation('auth');
  const { pickupOrderScheduleHash } = useParams();

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Typography variant="h4" component="h1">
          <Box fontWeight="bold">{t('header.message')}</Box>
        </Typography>
      </OneTemplateSummary>
      <OneTemplateContent>
        <AuthForm pickupOrderScheduleHash={pickupOrderScheduleHash} />
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default Auth;
