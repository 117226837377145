export default {
  INTELIPOST: 1,
  LOGGI: 2,
  VTEX: 3,
  FRENET: 4,
  EDITAVEL: 5,
  COMERCIAL: 6
};

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  AVAILABLE_FOR_ACTIVATION: 'AVAILABLE_FOR_ACTIVATION',
  PROCESSING_ACTIVATION: 'PROCESSING_ACTIVATION'
};
export const SPREADSHEET_LINK = 'https://link.to.download/';

export const PRICE_AGREEMENT_ID = '1774f179-38b8-47e4-8701-5e345866d010';

export const USER = {
  email: 'coelho@loggi.com'
};
