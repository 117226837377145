import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StatusIndicatorInvoicePayment from '@loggi/components/src/one/status-indicator/status-indicator-invoices-payment';
import { useParams } from 'react-router-dom';
import currency from '@loggi/js/formatters/currency';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import billingApi from '../billing-api';

const transformObject = (currentInvoice, t) => {
  const amount = currency(currentInvoice.amount).abs;
  const momentStartDate = moment.utc(currentInvoice.period_start);
  const startDate = momentStartDate.format('DD/MM/YY');
  const endDate = moment.utc(currentInvoice.period_end).format('DD/MM/YY');
  const interval = momentStartDate.format('MMMM / YY');
  const period = t('invoices.invoicePeriod', {
    startDate,
    endDate
  });

  return { amount, startDate, endDate, interval, period };
};

const CurrentInvoiceItem = () => {
  const [currentBilling, setCurrentBilling] = useState(null);
  const { companyId } = useParams();
  const { t } = useTranslation('payments');
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const mobile = isMobile ? 'column' : 'row';

  useEffect(() => {
    billingApi
      .getCurrentBilling(companyId)
      .then(currentInvoice =>
        setCurrentBilling(transformObject(currentInvoice, t))
      )
      .catch(() => setCurrentBilling(null));
  }, [companyId, t]);

  if (!currentBilling) {
    return null;
  }

  return (
    <>
      <ListItem disableGutters style={{ padding: 0 }}>
        <Box
          display="flex"
          flexDirection="column"
          width={1}
          px={{ xs: 3, sm: 5 }}
          py={{ xs: 1, sm: 3 }}
        >
          <Box
            display="flex"
            flexDirection={mobile}
            width={1}
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column">
              <Box
                mb={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                data-testid="content-status"
              >
                <StatusIndicatorInvoicePayment
                  endDate={currentBilling.endDate}
                  paymentStatus="CURRENT"
                />
              </Box>
              <Typography variant="subtitle1">
                <Box
                  mb={1}
                  component="strong"
                  fontWeight="fontWeightBold"
                  display="flex"
                >
                  {currentBilling.interval}
                </Box>
              </Typography>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">
                  {currentBilling.period}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">
                  {currentBilling.amount}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              mt={{ xs: 3, md: 0 }}
              mr={{ xs: 16, md: 0 }}
              mb={{ xs: 1, md: 0 }}
            >
              <Button
                color="primary"
                fullWidth
                type="submit"
                variant="outlined"
                data-testid="item-download-button"
                disabled
              >
                <Typography variant="button">
                  {t('invoices.downloadButton')}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </ListItem>
      <Divider />
    </>
  );
};

export default CurrentInvoiceItem;
