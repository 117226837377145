import { Box, Button } from '@material-ui/core';
import { FieldArray, useField } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';

import VolumeFields from '../fields/volume-fields';
import { ReactComponent as PackageIcon } from '../icons/package.svg';
import SectionTitle from '../section-title/section-title';

export const createVolume = () => ({ hash: shortid.generate() });

const VolumeSection = () => {
  const { t } = useTranslation('orderScheduling');

  // eslint-disable-next-line no-unused-vars
  const [field, _, helpers] = useField('volumes');
  const { value } = field;
  const { setValue } = helpers;

  const setInitialValue = useCallback(() => {
    if (!value) setValue([createVolume()]);
  }, [setValue, value]);
  // Sets volume's initialValue once
  // FIXME: React Hook useEffect has a missing dependency: 'setInitialValue'.
  // Either include it or remove the dependency array  react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setInitialValue, []);

  return (
    <>
      <SectionTitle icon={<PackageIcon title="Package Icon" />}>
        {t('create.volumeSection.title')}
      </SectionTitle>
      <Box>
        <FieldArray name="volumes">
          {({ remove, push }) => (
            <>
              {value?.map((volume, index) => (
                <VolumeFields
                  handleRemove={() => remove(index)}
                  index={index}
                  key={`volume-${volume.hash}`}
                />
              ))}
              <Box display="flex" justifyContent="center" mt={4}>
                <Button
                  color="primary"
                  onClick={() => push(createVolume())}
                  size="small"
                  variant="outlined"
                >
                  {t('create.volumeSection.addVolume')}
                </Button>
              </Box>
            </>
          )}
        </FieldArray>
      </Box>
    </>
  );
};

export default VolumeSection;
