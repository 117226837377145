import marTheme from '@loggi/mar';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import React from 'react';

const useRequirementsStyles = makeStyles(({ spacing, typography }) => ({
  listIcon: {
    alignItems: 'center',
    height: typography.pxToRem(32),
    minWidth: spacing(3)
  },
  listItem: {
    alignItems: 'center',
    paddingLeft: '0',
    paddingRight: '0'
  }
}));

const RequirementsModal = ({ onConfirm }) => {
  const styles = useRequirementsStyles();
  const { t } = useTranslation('orderScheduling');
  const bulletPointsForStandardScheduleTime = t(
    'create.requirements.bulletPointsForStandardScheduleTime',
    {
      returnObjects: true
    }
  );

  const bulletPointsListItemText = description => {
    return (
      <Box>
        {description?.first} <strong>{description?.strongFirst}</strong>
        {description?.second} <strong>{description?.strongSecond}</strong>
        {description?.last}
      </Box>
    );
  };
  return (
    <Box p={{ xs: 3, sm: 5 }}>
      <Typography variant="h6">{t('create.requirements.title')}</Typography>
      <List data-testid="bullet-points-standard-schedule-time">
        {bulletPointsForStandardScheduleTime.map((bulletPoint, index) => (
          <ListItem
            className={styles.listItem}
            key={`bullet-point-requirement-${index}`} // eslint-disable-line react/no-array-index-key
          >
            <ListItemIcon className={styles.listIcon}>
              <Box
                width={8}
                height={8}
                borderRadius={50}
                bgcolor={marTheme.palette.primary.main}
              />
            </ListItemIcon>
            <ListItemText primary={bulletPointsListItemText(bulletPoint)} />
          </ListItem>
        ))}
      </List>
      <Box mt={3}>
        <Button
          color="primary"
          fullWidth
          onClick={() => onConfirm()}
          size="large"
          variant="contained"
          data-testid="modal-action-button"
        >
          {t('create.requirements.action')}
        </Button>
      </Box>
    </Box>
  );
};

RequirementsModal.propTypes = {
  onConfirm: PropTypes.func.isRequired
};

export default RequirementsModal;
