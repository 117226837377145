import { ReactComponent as ShaftlessArrowDown } from '@loggi/components/src/one/shared/images/shaftless-arrow-down.svg';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { SCHEDULING_STATUSES as STATUSES, LIST_TYPE } from '../../constants';
import { filterMenuProps } from './menu-styles-shapes';
import useFilter from '../../useFilter';

const parseFilter = filter => (filter?.length ? filter.split(',') : []);

const FilterStatus = ({ menuStyles }) => {
  const [filter, setFilter] = useFilter('status', LIST_TYPE.SCHEDULINGS);
  const [status, setStatus] = useState(parseFilter(filter));
  const { t } = useTranslation('scheduling');
  const { typography } = useTheme();
  useEffect(() => setStatus(parseFilter(filter)), [filter]);

  const renderSelectedLabel = statuses => {
    const fixedT = getI18n().getFixedT(null, 'scheduling');
    const mapper = {
      cancelled: fixedT('filters.statusesLabels.cancelled'),
      dispatched: fixedT('filters.statusesLabels.dispatched'),
      partially_dispatched: fixedT(
        'filters.statusesLabels.partiallyDispatched'
      ),
      waiting_pickup: fixedT('filters.statusesLabels.waitingPickup'),
      waiting_volume: fixedT('filters.statusesLabels.waitingPickup'),
      waiting_confirmation: fixedT('filters.statusesLabels.waitingPickup'),
      failed_to_schedule: fixedT('filters.statusesLabels.cancelled')
    };
    return [...new Set(statuses.map(s => mapper[s]))].join(', ');
  };

  const FILTER_STATUS = [
    {
      name: t('filters.statusesLabels.waitingPickup'),
      value: STATUSES.WAITING_PICKUP
    },
    {
      name: t('filters.statusesLabels.cancelled'),
      value: STATUSES.CANCELLED
    },
    {
      name: t('filters.statusesLabels.dispatched'),
      value: STATUSES.DISPATCHED
    },
    {
      name: t('filters.statusesLabels.partiallyDispatched'),
      value: STATUSES.PARTIALLY_DISPATCHED
    }
  ];

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{t('filters.statusesLabels.text')}</InputLabel>
      <Select
        id="filter-status-select"
        input={<OutlinedInput label={t('filters.statusesLabels.text')} />}
        inputProps={{ 'data-testid': 'filter-status-select' }}
        labelId="filter-status-select-label"
        multiple
        onChange={event => setStatus(event.target.value)}
        onClose={() => setFilter(status)}
        renderValue={renderSelectedLabel}
        value={status}
        MenuProps={menuStyles}
        IconComponent={ShaftlessArrowDown}
      >
        {FILTER_STATUS.map(({ value, name }) => {
          const isChecked = Boolean(
            status.find(statusValue => statusValue === value)
          );
          return (
            <MenuItem key={name} value={value}>
              <Checkbox checked={isChecked} color="primary" />
              <ListItemText
                primary={name}
                primaryTypographyProps={{
                  style: { fontSize: typography.body2.fontSize }
                }}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

FilterStatus.propTypes = PropTypes.shape(filterMenuProps).isRequired;

FilterStatus.defaultProps = {
  menuStyles: null
};

export default FilterStatus;
