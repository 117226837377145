import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { ReactComponent as ReceiptErrorIcon } from '../../assets/receipt-error-icon.svg';
import PickupReceiptFooter from '../pickup-receipt-footer/pickup-receipt-footer.component';

const ReceiptGenericError = () => {
  const { t } = useTranslation('receiptGenericError');

  return (
    <>
      <Box
        alignItems="flex-end"
        display="flex"
        flexShrink={0}
        padding={3}
        style={{
          backgroundImage: colors.gradients.sanches,
          transition: 'background-color 0.5s ease-in-out',
          height: '100px'
        }}
      />
      <Box width="100%" top="166px" position="absolute">
        <Box
          margin="0 auto"
          textAlign="center"
          px={{ xs: 3, sm: 5.5 }}
          maxWidth="1280px"
        >
          <ReceiptErrorIcon />
          <Box pt={3}>
            <Typography variant="subtitle1">
              <strong>{t('error.title')}</strong>
            </Typography>
          </Box>
          <Box pt={2}>
            <Typography variant="body1" align="center">
              {t('error.message')}
            </Typography>
          </Box>
          <Box pt={5}>
            <Button
              color="primary"
              data-testid="reset-button"
              fullWidth
              size="small"
              variant="outlined"
              onClick={() => {
                window.location.reload();
              }}
            >
              {t('button.text')}
            </Button>
          </Box>
        </Box>
      </Box>
      <PickupReceiptFooter position="absolute" pb={3} />
    </>
  );
};

export default ReceiptGenericError;
