import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Button, Dialog } from '@material-ui/core';
import { useFeature } from '@loggi/components/src/one/remote-config';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { redirectToHelpCenter } from '@loggi/components/src/one';
import { useParams } from 'react-router-dom';
import DialogHeaderBase from '@loggi/components/src/one/dialog-header-base';

const UpdateReturnAddressAlert = ({ dialogState, setDialogState }) => {
  const { t } = useTranslation('packages');

  const [disableHelpDeskButton, setDisableHelpDeskButton] = useState(false);
  const useZendeskUrl = useFeature('enable_redirect_to_zendesk_help_center');
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const { companyId } = useParams();

  const handleHelpDeskClick = async () => {
    setDisableHelpDeskButton(true);
    await redirectToHelpCenter(companyId, authenticatedUser, useZendeskUrl);
  };

  return (
    <Dialog open={dialogState} data-testid="update-return-address-alert">
      <DialogHeaderBase
        title={t('details.labels.direction.alert.title')}
        subtitle={t('details.labels.direction.alert.subtitle')}
      >
        <Box>
          <Button
            disabled={disableHelpDeskButton}
            variant="outlined"
            size="small"
            bordercolor="primary"
            color="primary"
            backgroundcolor="common"
            data-testid="update-return-address-alert-helpdesk"
            onClick={() => {
              handleHelpDeskClick();
            }}
          >
            {t('details.labels.direction.alert.helpdesk')}
          </Button>
        </Box>
        <Box pl={4}>
          <Button
            size="small"
            bordercolor="primary"
            color="primary"
            backgroundcolor="common"
            data-testid="update-return-address-alert-close"
            onClick={() => {
              setDialogState(false);
            }}
          >
            {t('details.labels.direction.alert.close')}
          </Button>
        </Box>
      </DialogHeaderBase>
    </Dialog>
  );
};

export default UpdateReturnAddressAlert;

UpdateReturnAddressAlert.propTypes = {
  dialogState: PropTypes.bool.isRequired,
  setDialogState: PropTypes.func.isRequired
};
