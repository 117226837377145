/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  FormControl,
  Hidden,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Select,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import {
  LoggiRecurringPickups,
  TinyAddLoggiIntegration,
  TinyConfigureLoggiIntegration,
  TinyConfigurePricing,
  TinyDownloadLabel,
  TinyPackageDimensions,
  TinySalesTable
} from '../assets';
import { integratorDescriptionStyles, MENU_PROPS } from '../styles';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
  list: {
    display: 'list-item',
    listStyle: 'disc',
    fontWeight: 'bold',
    '&::marker': {
      fontSize: typography.subtitle2.fontSize
    },
    padding: 0
  },
  images: {
    [breakpoints.down('lg')]: {
      width: '100%'
    },
    [breakpoints.up('lg')]: {
      width: 'auto'
    },
    height: 'auto'
  }
}));

const IntegrationTinyTutorial = () => {
  const tinyStyles = useStyles();
  const descriptionStyles = integratorDescriptionStyles();
  const { t } = useTranslation('management');
  const smallerDevices = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const questionsLinks = t('integrations.questions.links', {
    returnObjects: true
  });
  const menuOptions = t('tinyTutorial.menu.options', {
    returnObjects: true
  });
  const firstSectionParagraphs = t(
    'tinyTutorial.description.loggiIntegration.paragraphs',
    {
      returnObjects: true
    }
  );
  const secondSectionParagraphs = t(
    'tinyTutorial.description.pricing.paragraphs',
    {
      returnObjects: true
    }
  );
  const thirdSectionParagraphs = t(
    'tinyTutorial.description.labels.paragraphs',
    {
      returnObjects: true
    }
  );
  const fourthSectionParagraphs = t(
    'tinyTutorial.description.recurringPickups.paragraphs',
    {
      returnObjects: true
    }
  );
  const bulletPoints = t('tinyTutorial.description.bulletPoints', {
    returnObjects: true
  });

  return (
    <Box display="flex" flexDirection={smallerDevices ? 'column' : 'row'}>
      <Hidden smDown>
        <Box padding={1} flexGrow={1} flexBasis={0}>
          <MenuList className={descriptionStyles.stickyMenu}>
            <Typography
              variant="h6"
              className={descriptionStyles.titlesMenuLink}
            >
              {t('tinyTutorial.menu.title')}
            </Typography>
            {menuOptions.map(({ id, title }, index) => {
              const lastOption = index === menuOptions.length - 1;
              const titleDivisionPosition = lastOption ? 35 : 30;
              return (
                <MenuItem key={id}>
                  <Link href={`#${id}`} className={descriptionStyles.titleMenu}>
                    <Box>{title.slice(0, titleDivisionPosition)}</Box>
                    <Box>{title.slice(titleDivisionPosition)}</Box>
                  </Link>
                </MenuItem>
              );
            })}
          </MenuList>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box padding={1} flexGrow={1} flexBasis={0} marginBottom={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{t('tinyTutorial.menu.title')}</InputLabel>
            <Select label={t('tinyTutorial.menu.title')} MenuProps={MENU_PROPS}>
              {menuOptions.map(({ id, title }) => (
                <MenuItem key={id} value={id}>
                  <Link href={`#${id}`} className={descriptionStyles.titleMenu}>
                    {title}
                  </Link>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Hidden>
      <Box padding={1} flexGrow={2} flexBasis={0}>
        <Box>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id={menuOptions[0].id}
          >
            {menuOptions[0].title}
          </Typography>
          <Typography variant="subtitle2">
            <Box my={2}>{firstSectionParagraphs[0]}</Box>
            <List>
              <ListItem className={`${tinyStyles.list}`}>
                <ListItemText
                  primary={<Typography>{bulletPoints[0]}</Typography>}
                />
              </ListItem>
              <ListItem className={`${tinyStyles.list}`}>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>{bulletPoints[1]}</strong>
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem className={`${tinyStyles.list}`}>
                <ListItemText
                  primary={
                    <Typography>
                      {bulletPoints[2].slice(0, 10)}
                      <strong>{bulletPoints[2].slice(10)}</strong>
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Box my={2}>
              <strong>{firstSectionParagraphs[1].slice(0, 83)}</strong>
              {firstSectionParagraphs[1].slice(83)}
            </Box>
            <TinyAddLoggiIntegration className={tinyStyles.images} />
            <Box mt={4}>{firstSectionParagraphs[2]}</Box>
            <List>
              <ListItem className={`${tinyStyles.list}`}>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>{bulletPoints[3]}</strong>
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem className={`${tinyStyles.list}`}>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>{bulletPoints[4].slice(0, 34)}</strong>
                      {bulletPoints[4].slice(34)}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Box my={2}>{firstSectionParagraphs[3]}</Box>
            <img
              className={tinyStyles.images}
              src={TinyConfigureLoggiIntegration}
            />
          </Typography>
        </Box>
        <Box mt={8}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id={menuOptions[1].id}
          >
            {menuOptions[1].title}
          </Typography>
          <Typography variant="subtitle2">
            <Box my={2}>
              {secondSectionParagraphs[0].slice(0, 29)}
              <strong>{secondSectionParagraphs[0].slice(29, 55)}</strong>
              {secondSectionParagraphs[0].slice(55)}
            </Box>
            <Box mb={3}>
              <strong>{secondSectionParagraphs[1].slice(0, 56)}</strong>
              {secondSectionParagraphs[1].slice(56)}
            </Box>
            <img className={tinyStyles.images} src={TinySalesTable} />
            <Box my={2}>{secondSectionParagraphs[2]}</Box>
            <Box my={3}>
              <strong>{secondSectionParagraphs[3]}</strong>
            </Box>
            <img className={tinyStyles.images} src={TinyPackageDimensions} />
            <Box pt={2}>
              <img className={tinyStyles.images} src={TinyConfigurePricing} />
            </Box>
          </Typography>
        </Box>
        <Box mt={8}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id={menuOptions[2].id}
          >
            {menuOptions[2].title}
          </Typography>
          <Typography variant="subtitle2">
            <Box my={2}>{thirdSectionParagraphs[0]}</Box>
            <List>
              <ListItem className={`${tinyStyles.list}`}>
                <ListItemText
                  primary={
                    <Typography>
                      {bulletPoints[5].slice(0, 6)}
                      <strong>{bulletPoints[5].slice(6)}</strong>
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem className={`${tinyStyles.list}`}>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>{bulletPoints[6].slice(0, 17)}</strong>
                      {bulletPoints[6].slice(17)}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem className={`${tinyStyles.list}`}>
                <ListItemText
                  primary={
                    <Typography>
                      {bulletPoints[7].slice(0, 23)}
                      <strong>{bulletPoints[7].slice(23)}</strong>
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem className={`${tinyStyles.list}`}>
                <ListItemText
                  primary={
                    <Typography>
                      {bulletPoints[8].slice(0, 10)}
                      <strong>{bulletPoints[8].slice(10)}</strong>
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Box mt={2} mb={3}>
              <strong>{thirdSectionParagraphs[1].slice(0, 12)}</strong>
              {thirdSectionParagraphs[1].slice(12)}
            </Box>
            <img className={tinyStyles.images} src={TinyDownloadLabel} />
          </Typography>
        </Box>
        <Box mt={8}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id={menuOptions[3].id}
          >
            {menuOptions[3].title}
          </Typography>
          <Typography variant="subtitle2">
            <Box my={2}>
              {fourthSectionParagraphs[0].slice(0, 85)}
              <strong>{fourthSectionParagraphs[0].slice(85, 113)}</strong>
              {fourthSectionParagraphs[0].slice(113)}
            </Box>
            <List>
              <ListItem className={`${tinyStyles.list}`}>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>{bulletPoints[9].slice(0, 19)}</strong>
                      {bulletPoints[9].slice(19)}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem className={`${tinyStyles.list}`}>
                <ListItemText
                  primary={
                    <Typography>
                      {bulletPoints[10].slice(0, 16)}
                      <strong>{bulletPoints[10].slice(16, 40)}</strong>
                      {bulletPoints[10].slice(40, 42)}
                      <strong>{bulletPoints[10].slice(42, 71)}</strong>
                      {bulletPoints[10].slice(71)}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem className={`${tinyStyles.list}`}>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>{bulletPoints[11].slice(0, 17)}</strong>
                      {bulletPoints[11].slice(17, 70)}
                      <strong>{bulletPoints[11].slice(70)}</strong>
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Box mt={2} mb={3}>
              {fourthSectionParagraphs[1]}
            </Box>
            <img className={tinyStyles.images} src={LoggiRecurringPickups} />
          </Typography>
        </Box>
        <Box mt={8}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="duvidas-sobre-integracao"
          >
            {t('integrations.questions.title')}
          </Typography>
          <Box mt={2}>
            <List>
              {questionsLinks.map((el, index) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={`${tinyStyles.list} ${descriptionStyles.listDisc}`}
                >
                  <ListItemText
                    primary={
                      <Typography>
                        <Link href={el.link} target="_blank">
                          <styles className={descriptionStyles.link}>
                            {el.text}
                          </styles>
                        </Link>
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography variant="h6" className={descriptionStyles.titlesMenuLink}>
            {t('integrations.helpLoggi.title')}
          </Typography>
          <Box my={3}>
            <Link href="https://ajuda.loggi.com/hc/pt-br/requests/new">
              <styles className={descriptionStyles.link}>
                {t('integrations.helpLoggi.link')}
              </styles>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default IntegrationTinyTutorial;
