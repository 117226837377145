import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { colors } from '@loggi/mar';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  useLocation,
  useParams,
  Link as RouterLink
} from 'react-router-dom';

import packagesRoutes from '../../../packages-routes';
import ErrorsList from './errors-list.component';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    alertMessage: {
      color: palette.info.dark,
      fontSize: typography.body2.fontSize,
      [breakpoints.down('sm')]: {
        alignItems: 'start',
        '& .MuiAlert-icon': {
          paddingTop: spacing(1.15)
        }
      }
    },
    listIcon: {
      color: palette.error.main,
      fontSize: typography.pxToRem(10)
    },
    subtitleIcon: {
      color: palette.error.main
    }
  })
);

const SpreadsheetErrors = () => {
  const { t } = useTranslation('packages');
  const styles = useStyles();
  const { push } = useHistory();
  const { companyId } = useParams();
  const { state } = useLocation();

  if (!state?.errors?.length > 0)
    push(packagesRoutes.create.spreadsheet.upload.url(companyId));

  const { errors } = state;

  return (
    <OneTemplate>
      <OneTemplateSummary color={colors.red[700]}>
        <Box display="flex" flexDirection="column">
          <Typography variant="h4" component="h1">
            <Box component="strong" fontWeight="fontWeightBold">
              {t('create.spreadsheetFlow.errors.title')}
            </Box>
          </Typography>
          <Box mt={2}>
            <Alert
              className={styles.alertMessage}
              icon={<Error className={styles.subtitleIcon} fontSize="small" />}
              severity="error"
            >
              {t('create.spreadsheetFlow.errors.subtitle')}
            </Alert>
          </Box>
        </Box>
      </OneTemplateSummary>
      <OneTemplateContent>
        <Box px={{ sm: 5 }} py={{ sm: 4 }}>
          {errors.map(pkg => (
            <ErrorsList
              failures={pkg.packageResult.failures}
              key={pkg.rowNumber}
              rowNumber={pkg.rowNumber}
            />
          ))}
          <Box display="flex" justifyContent="center" pt={3}>
            <Button
              color="primary"
              component={RouterLink}
              data-testid="add-new-spreadsheet-btn"
              to={packagesRoutes.create.spreadsheet.upload.url(companyId)}
              variant="outlined"
            >
              {t('create.spreadsheetFlow.errors.list.buttonText')}
            </Button>
          </Box>
        </Box>
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default SpreadsheetErrors;
