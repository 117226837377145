import { loggiWebApi } from '@loggi/authentication-lib';
import * as Sentry from '@sentry/react';
import { getI18n } from 'react-i18next';
import camelcaseKeys from 'camelcase-keys';
import { format, parseISO } from 'date-fns';

import {
  ORDER_SCHEDULES_ENDPOINT,
  PICKUPS_COMPANIES_ENDPOINT
} from './constants';

export const requestErrors = {
  errors: {
    badRequest: {
      '9': {
        CompanyClientExceptions: 'create.errorMessages.invalidCnpj',
        CoverageClientRequestError: 'create.errorMessages.invalidWarehouse'
      },
      '10102': 'create.errorMessages.invalidModalCombination',
      null: 'create.errorMessages.invalidFormStatus',
      pickupAlreadyScheduled: 'create.errorMessages.pickupAlreadyScheduled',
      nonWorkingDay: 'create.errorMessages.nonWorkingDay'
    },
    generic: 'create.errorMessages.internalServerError',
    internalServerError: 'create.errorMessages.internalServerError',
    unprocessableEntity: 'create.errorMessages.unprocessableEntity'
  }
};

const formatTime = (date, time) => {
  const day = format(date, 'yyyy-MM-dd');
  const hour = format(time, 'HH:mm');
  return parseISO(`${day} ${hour}`).toISOString();
};

export class NewPickupOrderScheduleError extends Error {
  constructor(...args) {
    super(...args);
    this.name = `NewPickupOrderScheduleError`;
  }
}

export const createOrderSchedules = async (values, companyId) => {
  const {
    address,
    complement,
    date,
    endTime,
    modalRestrictions,
    shipperName,
    shipperPhone,
    startTime,
    volumes
  } = values;

  const t = getI18n().getFixedT(null, 'orderScheduling');

  const requestParams = {
    end_time: formatTime(date, endTime),
    modal_restrictions: modalRestrictions,
    origin_address: {
      address_complement: complement,
      full_address: address.description,
      place_id: address.place_id
    },
    shipper_name: shipperName,
    shipper_phone: shipperPhone,
    start_time: formatTime(date, startTime),
    document: values?.document,
    instructions: values?.instructions,
    volumes
  };

  const url = `/${PICKUPS_COMPANIES_ENDPOINT}/${companyId}/${ORDER_SCHEDULES_ENDPOINT}`;

  return loggiWebApi
    .url(url)
    .accept('application/json')
    .content('application/json')
    .post(requestParams)
    .badRequest(error => {
      const { badRequest } = requestErrors.errors;
      let snackbarMessage;

      try {
        const parsedError = JSON.parse(error.message);
        const code = parsedError?.code || parsedError?.message?.code;
        const details = parsedError?.details || parsedError?.message?.details;
        snackbarMessage = badRequest[code][details] || badRequest[code];
        if (typeof snackbarMessage !== 'string') {
          snackbarMessage = badRequest.null;
        }
      } catch {
        snackbarMessage = badRequest.null;
      }
      throw new NewPickupOrderScheduleError(t(snackbarMessage));
    })
    .json(response => camelcaseKeys(response, { deep: true }))
    .catch(error => {
      let thrownError;
      switch (error.status) {
        case 422: {
          thrownError = new NewPickupOrderScheduleError(
            requestErrors.errors.unprocessableEntity
          );
          break;
        }
        case 500: {
          thrownError = new NewPickupOrderScheduleError(
            requestErrors.errors.internalServerError
          );
          break;
        }
        default: {
          thrownError =
            error instanceof NewPickupOrderScheduleError
              ? error
              : new NewPickupOrderScheduleError(requestErrors.errors.generic);
          break;
        }
      }

      Sentry.captureException(thrownError, {
        contexts: {
          'original error': error,
          payload: requestParams
        }
      });

      throw thrownError;
    });
};

export default { createOrderSchedules };
