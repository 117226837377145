export const CNPJ_ALREADY_REGISTERED = 'empresa com este cnpj já existe.';
export const INVALID_CNPJ = 'This value does not match the required pattern.';
export const INACTIVE_CNPJ = 'This field must be a valid CNPJ.';
export const INVALID_SHARED_NAME =
  'The name informed has not similar with the name registered.';

export const CNPJ_ERRORS = {
  [CNPJ_ALREADY_REGISTERED]: 'companyAlreadyExists',
  [INACTIVE_CNPJ]: 'invalidCnpj',
  [INVALID_CNPJ]: 'invalidCnpj',
  [INVALID_SHARED_NAME]: 'invalidSharedName'
};

export const CNPJ_REGEX = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

export const FORM = {
  INPUTS: {
    SHARED_NAME: {
      NAME: 'sharedName'
    },
    CNPJ: {
      NAME: 'cnpj',
      MASK: '99.999.999/9999-99'
    },
    CONSENT: {
      NAME: 'consent'
    },
    SEGMENT: {
      NAME: 'segment'
    }
  }
};

export const SIGN_UP_PROGRESS_BUTTON_TEST_ID = 'circular-progress-button';
export const CONSENT_CHECKBOX_TEST_ID = 'consent-checkbox';

export const TERMS_URL =
  'https://www.loggi.com/termos-condicoes-clientes-loggi-envios/';

export const COMPANY_CONTACT_PROTO_VALUES = {
  FAVORED_CONTACT_TIME: {
    MANHÃ: 2,
    TARDE: 3
  },
  FAVORED_CONTACT_METHOD: {
    PHONE: 2,
    EMAIL: 3,
    WHATSAPP: 4
  }
};

export const COMPANY_INTEGRATORS = {
  BLING: 'Bling',
  ECCOSYS: 'Eccosys',
  OMIE: 'Omie',
  CONTA_AZUL: 'Conta Azul',
  TOTVS: 'Totvs',
  MILLENIUM: 'Millenium',
  LINX: 'Linx',
  TINY: 'Tiny'
};
