import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import palette from '@loggi/mar/src/palette';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Typography, Divider, Link, ButtonBase } from '@material-ui/core';
import { useFeature } from '@loggi/components/src/one/remote-config';
import { redirectToHelpCenter } from '@loggi/components/src/one';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import Toast from '@loggi/components/src/one/toast';
import StatusIndicator from '@loggi/components/src/one/status-indicator/status-indicator';
import paymentsApi from '../payments-api';
import PriceAgreementStatus from './price-agreement-status';
import SelectSpreadSheetFormat from './select-spreadsheet-format';
import SPREADSHEET_TEMPLATE_FORMAT, { STATUS } from './constants';

const PriceAgreementsDetails = props => {
  const {
    priceAgreementId,
    status,
    startEffectiveOn,
    startHourEffectiveOn,
    priceTables
  } = props;
  const { companyId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isFetchFinished, setFetchFinished] = useState(true);
  const [numberTry, setNumberTry] = useState(0);
  const [spreadSheetFormat, setSpreadSheetFormat] = useState(
    SPREADSHEET_TEMPLATE_FORMAT.COMERCIAL
  );
  const { t } = useTranslation('payments');
  const useZendeskUrl = useFeature('enable_redirect_to_zendesk_help_center');
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  const NUMBER_MAX_TRY = 10;
  const RECURSIVE_TIMEOUT_IN_MS = 20000;
  const count = priceTables.length;

  const getSpreadsheetGenerationStatus = async generationId => {
    let spreadsheetStatus;

    try {
      spreadsheetStatus = await paymentsApi.spreadsheetGenerationStatus(
        companyId,
        priceAgreementId,
        generationId
      );
    } catch (error) {
      enqueueSnackbar(t('priceAgreements.error.download'), {
        content: (key, message) => (
          <Toast id={key} message={message} type="error" />
        )
      });
    }

    return spreadsheetStatus;
  };

  const getGenerateSpreadsheet = async priceTable => {
    setFetchFinished(false);
    let generationId;

    const data = {
      priceTableName: priceTable.priceTableName,
      spreadsheetTemplate: spreadSheetFormat
    };

    try {
      generationId = await paymentsApi.generateSpreadsheet(
        companyId,
        priceAgreementId,
        data
      );
    } catch (error) {
      enqueueSnackbar(t('priceAgreements.error.download'), {
        content: (key, message) => (
          <Toast id={key} message={message} type="error" />
        )
      });
    }
    return generationId;
  };

  const recursiveGenerationStatus = async generationId => {
    if (numberTry <= NUMBER_MAX_TRY) {
      setNumberTry(prev => prev + 1);
      const spreadsheetStatus = await getSpreadsheetGenerationStatus(
        generationId
      );

      switch (spreadsheetStatus?.status) {
        case 'SPREADSHEET_STATUS_DONE':
          setFetchFinished(true);
          window.open(spreadsheetStatus.download_link);
          break;

        case 'SPREADSHEET_STATUS_PROCESSING':
          setTimeout(() => {
            recursiveGenerationStatus(generationId);
          }, RECURSIVE_TIMEOUT_IN_MS);
          break;

        default:
          setFetchFinished(true);
          enqueueSnackbar(t('priceAgreements.error.download'), {
            content: (key, message) => (
              <Toast id={key} message={message} type="error" />
            )
          });
          break;
      }
    } else {
      setFetchFinished(true);
      enqueueSnackbar(t('priceAgreements.error.download'), {
        content: (key, message) => (
          <Toast id={key} message={message} type="error" />
        )
      });
    }
  };

  const handleSubmit = async priceTable => {
    const generationId = await getGenerateSpreadsheet(priceTable);
    recursiveGenerationStatus(generationId);
  };

  const statusInfo = () => {
    let activationInfoText;
    if (status === STATUS.PROCESSING_ACTIVATION) {
      activationInfoText = t('priceAgreements.processingActivation.text', {
        count
      });
    }

    if (status === STATUS.ACTIVE) {
      activationInfoText = t('priceAgreements.active.text');
    }

    return (
      <>
        {activationInfoText && (
          <Box pt={2} pr={5}>
            <Typography color="textSecondary">
              {activationInfoText}
              <strong>
                {t('priceAgreements.processingActivation.dateHourText', {
                  startEffectiveOn,
                  startHourEffectiveOn
                })}
              </strong>
            </Typography>
          </Box>
        )}
      </>
    );
  };

  const downloadButton = () => {
    const downloadButtonMap = () =>
      priceTables.map((priceTable, index) => (
        <Box key={`${priceTable.originRegion}`}>
          <Button
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
            onClick={() => handleSubmit(priceTable)}
          >
            <Typography variant="button">{`${t(
              'priceAgreements.priceTableButton'
            )} ${index + 1}`}</Typography>
          </Button>
        </Box>
      ));

    return (
      <>
        {priceTables.length === 1 && (
          <Box key={`${priceTables[0].originRegion}`}>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
              onClick={() => handleSubmit(priceTables[0])}
            >
              <Typography variant="button">
                {t('priceAgreements.priceTableButton')}
              </Typography>
            </Button>
          </Box>
        )}
        {priceTables.length > 1 && downloadButtonMap()}
      </>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box>
        <Box pl={5} pt={3} pb={6}>
          <Typography variant="h4" color="textPrimary">
            <strong>{t('priceAgreements.formatSpreadsheetTitle')}</strong>
          </Typography>
          <Box pt={6}>
            <StatusIndicator
              statusMap={PriceAgreementStatus()}
              status={status}
              noLabel={false}
              color={palette.text.primary}
            />
          </Box>
          {statusInfo()}
        </Box>
        <Divider />
        <Box pl={5} pt={5.5} pr={5.75}>
          {!isFetchFinished && (
            <Box pt={4} display="flex" flexDirection="column">
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
              <Box pt={6} width="300px" display="flex" alignSelf="center">
                <Typography
                  align="center"
                  color="textSecondary"
                  variant="body2"
                >
                  {t('priceAgreements.priceTableDownloadWarning')}
                </Typography>
              </Box>
            </Box>
          )}
          {isFetchFinished && (
            <>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                {t('priceAgreements.SpreadsheetChooseFormatTitle', { count })}
              </Typography>
              <Box pt={4}>
                <SelectSpreadSheetFormat
                  spreadSheetFormat={spreadSheetFormat}
                  setSpreadSheetFormat={setSpreadSheetFormat}
                  count={count}
                />
              </Box>
              <Box pt={3.5}>{downloadButton()}</Box>
            </>
          )}
        </Box>
      </Box>
      <Box pl={5.25} pr={5} pb={5}>
        <Typography variant="body2" align="center" color="textSecondary">
          {t('priceAgreements.help.text')}
          <ButtonBase
            component={Link}
            underline="always"
            variant="body2"
            color="primary"
            style={{
              cursor: 'pointer',
              verticalAlign: 'baseline'
            }}
            onClick={async () => {
              redirectToHelpCenter(companyId, authenticatedUser, useZendeskUrl);
            }}
            data-testid="help-center-btn"
          >
            <strong>{t('priceAgreements.help.link')}</strong>
          </ButtonBase>
        </Typography>
      </Box>
    </Box>
  );
};

PriceAgreementsDetails.propTypes = {
  priceAgreementId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  startEffectiveOn: PropTypes.string.isRequired,
  startHourEffectiveOn: PropTypes.string.isRequired,
  priceTables: PropTypes.arrayOf(
    PropTypes.shape({
      originRegion: PropTypes.string.isRequired,
      priceTableName: PropTypes.string.isRequired
    })
  ).isRequired
};

export default PriceAgreementsDetails;
