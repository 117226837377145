import { Box, Typography, useTheme } from '@material-ui/core';
import isToday from 'date-fns/isToday';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatDayMonth,
  formatHour
} from '@loggi/components/src/one/date-utils';

/**
 * DateTimeBox renders a cell with the datetime and a tag if startTime is today
 * @componentType presentational
 * @param startTime
 * @param endTime
 * @returns {*}
 * @constructor
 */
const DateTimeBox = ({ startTime, endTime }) => {
  const { t } = useTranslation('scheduling');
  const schedulingIsToday = isToday(new Date(startTime));
  const { palette, typography } = useTheme();

  const TodayTag = () => (
    <Box
      display="inline-flex"
      borderRadius={5}
      px={1}
      py={0.5}
      bgcolor={palette.background.default}
      width="auto"
      mr={1}
      data-testid="today-tag"
    >
      <Typography
        variant="caption"
        color="primary"
        noWrap
        style={{ fontWeight: typography.fontWeightMedium }}
      >
        {t('todayTag')}
      </Typography>
    </Box>
  );

  return (
    <Box display="inline-flex" alignItems="center">
      {schedulingIsToday && <TodayTag />}
      <Typography variant="button" color="textPrimary" noWrap>
        {t('listItem.date', {
          day: formatDayMonth(startTime, true),
          start: formatHour(startTime),
          end: formatHour(endTime)
        })}
      </Typography>
    </Box>
  );
};

DateTimeBox.propTypes = {
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired
};

export default DateTimeBox;
