import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import { ToggleButton } from '@material-ui/lab';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Box, ButtonBase, makeStyles, Popover } from '@material-ui/core';

import paymentsRoutes from '../payments-routes';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    item: {
      '&:hover': {
        backgroundColor: palette.secondary.light,
        color: palette.primary.main,
        transition: 'all 0.2s ease-in-out'
      },
      [breakpoints.up('sm')]: {
        padding: spacing(1.5, 2)
      },
      color: palette.info.dark,
      display: 'flex',
      fontSize: typography.body1,
      fontWeight: typography.fontWeightBold,
      justifyContent: 'space-between',
      padding: spacing(2),
      width: '100%'
    },
    popover: {
      '& .MuiPopover-paper.MuiPaper-root': {
        [breakpoints.only('xs')]: {
          width: 'calc(100% - 48px)'
        },
        [breakpoints.up('sm')]: {
          width: typography.pxToRem(312)
        },
        marginTop: spacing(1)
      }
    },
    toggleButton: {
      borderColor: palette.primary.contrastText,
      borderRadius: '50px',
      color: palette.primary.contrastText,
      justifyContent: 'space-between',
      minWidth: typography.pxToRem(183),
      paddingLeft: typography.pxToRem(16),
      paddingRight: typography.pxToRem(16),
      '&.Mui-selected, &.Mui-selected:hover': {
        color: palette.primary.contrastText,
        mixBlendMode: 'screen',
        transition: 'all 0.2s ease-in-out'
      }
    }
  })
);

const PaymentsNavigation = ({ toggleButtonLabel }) => {
  const styles = useStyles();
  const menuRef = useRef(null);
  const { companyId } = useParams();
  const { t } = useTranslation('payments');
  const { index, priceAgreements } = paymentsRoutes;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleClick = () => {
    setIsPopoverOpen(false);
  };

  const navigationItems = [
    {
      key: 'index',
      label: t('invoices.titleNavigation'),
      url: index.url(companyId)
    },
    {
      key: 'price-agreements',
      label: t('priceAgreements.titleNavigation'),
      url: priceAgreements.url(companyId)
    }
  ];

  return (
    <>
      <ToggleButton
        className={styles.toggleButton}
        data-testid="toggle-btn"
        onChange={() => setIsPopoverOpen(!isPopoverOpen)}
        ref={menuRef}
        selected={isPopoverOpen}
        size="small"
        value={toggleButtonLabel}
        variant="outlined"
      >
        <Box mr={1}>{toggleButtonLabel}</Box>
        {isPopoverOpen ? (
          <KeyboardArrowUp fontSize="small" />
        ) : (
          <KeyboardArrowDown fontSize="small" />
        )}
      </ToggleButton>
      <Popover
        anchorEl={menuRef.current}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        className={styles.popover}
        elevation={24}
        marginThreshold={24}
        onClose={() => setIsPopoverOpen(false)}
        open={isPopoverOpen}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {navigationItems.map(item => (
          <ButtonBase
            className={styles.item}
            component={Link}
            data-testid={item.key}
            key={item.key}
            onClick={() => handleClick()}
            to={item.url}
            variant="button"
          >
            {item.label}
          </ButtonBase>
        ))}
      </Popover>
    </>
  );
};

PaymentsNavigation.propTypes = {
  toggleButtonLabel: PropTypes.string.isRequired
};

export default PaymentsNavigation;
