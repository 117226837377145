// Platforms
import { isLoggiOneIOSApp } from '..';

// Platform handlers
export const handlers = {
  noop: () => {},
  iOS: (handler, message) =>
    window?.webkit?.messageHandlers[handler]?.postMessage(message)
};

// Actual handler
const MessageHandler = (isLoggiOneIOSApp() && handlers.iOS) || handlers.noop;

export default MessageHandler;
