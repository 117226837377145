import React from 'react';
import PropTypes from 'prop-types';
import theme from '@loggi/mar';
import { getI18n } from 'react-i18next';

import StatusIndicator from '@loggi/components/src/one/status-indicator/status-indicator';

const StatusIndicatorPickup = props => {
  const i18n = getI18n();
  const t = i18n.getFixedT(null, 'details');
  const { status, pickupEndTime } = props;
  const pickupStatusCollected = pickupEndTime
    ? t('pickupStatusCollectedWithPickupTime.label', {
        pickupEndTime
      })
    : t('pickupStatus.collected');

  const statusMap = {
    2: {
      color: theme.palette.primary.main,
      label: t('pickupStatus.scheduled')
    }, // PICKUP_STATUS_ALLOCATED
    3: {
      color: theme.palette.success.main,
      label: pickupStatusCollected
    }, // PICKUP_STATUS_COLLECTED
    5: {
      color: theme.palette.primary.main,
      label: t('pickupStatus.scheduled')
    }, // PICKUP_STATUS_SCHEDULED
    6: {
      color: theme.palette.primary.main,
      label: t('pickupStatus.scheduled')
    } // PICKUP_STATUS_CHECKED_IN
  };

  return <StatusIndicator statusMap={statusMap} status={status} />;
};

StatusIndicatorPickup.propTypes = {
  status: PropTypes.number.isRequired,
  pickupEndTime: PropTypes.string
};

StatusIndicatorPickup.defaultProps = {
  pickupEndTime: ''
};

export default StatusIndicatorPickup;
