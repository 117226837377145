import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  timelineStyles: {
    margin: 0,
    padding: spacing(0)
  },
  timelineItem: {
    padding: 0,
    '&:before': {
      display: 'none'
    }
  },
  timelineConnector: {
    backgroundColor: palette.grey[200],
    width: typography.pxToRem(2)
  },
  timelineContent: {
    paddingLeft: spacing(3.5)
  },
  skeleton: {
    borderRadius: typography.pxToRem(4)
  }
}));

const PackageHistoryDrawerSkeleton = () => {
  const style = useStyles();

  const repeatedRange = Array.from(Array(4).keys());

  return (
    <Box data-testid="package-history-drawer-skeleton">
      <Box px={3} py={6}>
        <Typography variant="h4">
          <Skeleton className={style.skeleton} />
        </Typography>
      </Box>
      <Box px={3}>
        <Timeline className={style.timelineStyles}>
          <TimelineItem className={style.timelineItem}>
            <TimelineSeparator>
              <Box my={1}>
                <Skeleton variant="circle" width={32} height={32} />
              </Box>
              <TimelineConnector className={style.timelineConnector} />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="h6">
                <Skeleton className={style.skeleton} />
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <Box pl={1.2}>
            <TimelineItem className={style.timelineItem}>
              <TimelineSeparator>
                <Box my={1.5}>
                  <Skeleton variant="circle" width={12} height={12} />
                </Box>
                <TimelineConnector className={style.timelineConnector} />
              </TimelineSeparator>
              <TimelineContent className={style.timelineContent}>
                <Box alignItems="center">
                  <Box>
                    <Typography variant="body1">
                      <Skeleton className={style.skeleton} />
                    </Typography>
                  </Box>
                  <Box pt={1} pb={4}>
                    <Typography variant="body1">
                      <Skeleton className={style.skeleton} width="47%" />
                    </Typography>
                  </Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
          </Box>

          {repeatedRange.map(key => {
            return (
              <Box pl={1.2} key={key}>
                <TimelineItem className={style.timelineItem}>
                  <TimelineSeparator>
                    <Box my={2}>
                      <Skeleton variant="circle" width={12} height={12} />
                    </Box>
                    {repeatedRange.length !== key + 1 && (
                      <TimelineConnector className={style.timelineConnector} />
                    )}
                  </TimelineSeparator>
                  <TimelineContent className={style.timelineContent}>
                    <Box mb={4}>
                      <Typography variant="h6">
                        <Skeleton className={style.skeleton} />
                      </Typography>
                      <Typography variant="body1">
                        <Skeleton className={style.skeleton} />
                      </Typography>
                      <Typography variant="body1">
                        <Skeleton className={style.skeleton} width="47%" />
                      </Typography>
                      <Typography variant="body1">
                        <Skeleton className={style.skeleton} width="67%" />
                      </Typography>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              </Box>
            );
          })}
        </Timeline>
      </Box>
    </Box>
  );
};

export default PackageHistoryDrawerSkeleton;
