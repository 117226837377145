const AWS_WEB_CLIENT = process.env.REACT_APP_AWS_WEB_CLIENT;

/**
 * getAndSetCognitoCookiesOnLocalStorage function
 * This function gets all the Cognito cookies and sets them in the local storage.
 */
const getAndSetCognitoCookiesOnLocalStorage = () => {
  document.cookie.split('; ').forEach(cookie => {
    const [key, ...value] = cookie.split('=');
    if (key.includes(AWS_WEB_CLIENT)) {
      const newValue = value.join('=');
      localStorage.setItem(key, newValue);
    }
  });
};

/**
 * useBeyondUser function
 * This function returns an object with a signIn method.
 * The signIn method is the getAndSetCognitoCookiesOnLocalStorage function.
 * @returns {Object} - An object with a signIn method.
 */
const useBeyondUser = () => ({
  signIn: getAndSetCognitoCookiesOnLocalStorage
});

export default useBeyondUser;
