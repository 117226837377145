import { Button, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  formatDayMonth,
  formatHour
} from '@loggi/components/src/one/date-utils';
import DetailsContext from '../../details-context';
import CancelScheduleDialog from '../cancel-schedule-dialog';

const useStyles = makeStyles(({ breakpoints }) => ({
  button: {
    width: '100%',
    [breakpoints.up('sm')]: {
      width: 'auto'
    }
  }
}));

/**
 * CancelScheduleButton is a wrapper component that shows a confirmation
 * dialog when the cancel schedule button is pressed.
 * @componentType: Presentational
 * @returns {*}
 */
const CancelScheduleButton = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('details');
  const { companyId } = useParams();
  const { orderScheduling } = useContext(DetailsContext);

  if (!orderScheduling) return null;

  const { endTime, id, startTime } = orderScheduling;

  return (
    <>
      <Button
        className={classes.button}
        size="medium"
        variant="outlined"
        onClick={() => setOpen(true)}
        color="primary"
      >
        {t('summary.cancelButton')}
      </Button>
      <CancelScheduleDialog
        companyId={companyId}
        schedulingId={id}
        open={open}
        handleClose={() => setOpen(false)}
        dayMonth={formatDayMonth(startTime)}
        startHour={formatHour(startTime)}
        endHour={formatHour(endTime)}
      />
    </>
  );
};

export default CancelScheduleButton;
