import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useTheme
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import {
  getAllShipperPackageStatusCodes,
  getShipperPackageStatusLabel
} from '@loggi/components/src/one/package-utils/status.helpers';

import { FILTERS_NAMES } from '../../constants';

const parseFilter = filter => (filter?.length ? filter.split(',') : []);

const FilterStatus = ({ setChecked, onChange }) => {
  const { spacing } = useTheme();

  const filter = useQueryParams()[FILTERS_NAMES.STATUS];
  const [status, setStatus] = useState(parseFilter(filter));
  const { t } = useTranslation('packages');

  useEffect(() => {
    setStatus(parseFilter(filter));
    // cleans checked packages list
    setChecked([]);
  }, [filter, setChecked]);

  const renderSelectedLabel = packageStatusCodes => {
    return packageStatusCodes
      .map(packageStatusCode => getShipperPackageStatusLabel(packageStatusCode))
      .join(', ');
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: spacing(42),
        marginTop: spacing(1),
        maxWidth: spacing(10)
      }
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  };

  const packageStatusCodes = getAllShipperPackageStatusCodes();

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{t('filters.status.text')}</InputLabel>
      <Select
        id="filter-status-select"
        input={<OutlinedInput label={t('filters.status.text')} />}
        inputProps={{ 'data-testid': 'filter-status-select' }}
        MenuProps={MenuProps}
        labelId="filter-status-select-label"
        multiple
        onChange={event => setStatus(event.target.value)}
        onClose={() => {
          const newFilter = Object();
          newFilter[FILTERS_NAMES.STATUS] = status;
          onChange(newFilter);
        }}
        renderValue={renderSelectedLabel}
        value={status}
      >
        {packageStatusCodes.map(statusCode => (
          <MenuItem key={statusCode} value={statusCode}>
            <Checkbox
              checked={status.some(statusValue => statusValue === statusCode)}
              color="primary"
            />
            <ListItemText
              primary={
                <Typography noWrap>
                  {getShipperPackageStatusLabel(statusCode)}
                </Typography>
              }
            />
          </MenuItem>
        ))}
        ;
      </Select>
    </FormControl>
  );
};

FilterStatus.propTypes = {
  setChecked: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default FilterStatus;
