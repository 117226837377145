import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog } from '@material-ui/core';

const NotificationDialog = ({
  title,
  subtitle,
  closeButtonLabel,
  dialogState,
  setDialogState
}) => {
  return (
    <Dialog open={dialogState} data-testid="notificationBaseDialog">
      <Box paddingX={4} pt={4} pb={2}>
        <Box display="flex">
          <Box>
            <Box pb={2} fontSize={24} fontWeight="bold">
              {title}
            </Box>
            <Box fontSize={16}>{subtitle}</Box>
          </Box>
        </Box>
        <Box pt={6} pb={2} justifyContent="center" display="flex">
          <Button
            variant="outlined"
            size="small"
            bordercolor="primary"
            color="primary"
            backgroundcolor="common"
            data-testid="notificationDialogCloseButton"
            onClick={() => {
              setDialogState(false);
            }}
          >
            {closeButtonLabel}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default NotificationDialog;

NotificationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  closeButtonLabel: PropTypes.string.isRequired,
  dialogState: PropTypes.bool.isRequired,
  setDialogState: PropTypes.func.isRequired
};
