import { loggiWebApi } from '@loggi/authentication-lib';
import {
  ORDER_SCHEDULES_ENDPOINT,
  PICKUP_RECEIPTS_COMPANIES_ENDPOINT
} from '../../constants';

const updatePickupReceiptFeedback = (
  pickupReceiptId,
  shipperConfirmedTotalPackages,
  shipperFeedbackMessage,
  pickupOrderScheduleId,
  companyId
) => {
  return loggiWebApi
    .url(
      `/${PICKUP_RECEIPTS_COMPANIES_ENDPOINT}/${companyId}/${ORDER_SCHEDULES_ENDPOINT}/${pickupOrderScheduleId}/pickup-receipts/${pickupReceiptId}/update-pickup-receipt-feedback/`
    )
    .accept('application/json')
    .content('application/json')
    .post({
      pickupReceiptId,
      shipperConfirmedTotalPackages,
      shipperFeedbackMessage
    });
};

export default updatePickupReceiptFeedback;
