import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { oneConstants } from '@loggi/components/src/one';
import { useCurrentUser } from '../auth';

/**
 * Appcues needs this configuration to identify the users (User ID)
 * that have been targeted by its events or flows.
 * Other main Appcues configurations, such as SKD installation, are inside
 * of the Google Tag Manager interface.
 * */
const useAppcues = () => {
  const user = useCurrentUser();
  const match = useRouteMatch('/empresas/:companyId');
  const {
    INTEGRATION_BLING_STORAGE_KEY,
    ENABLE_UNIFIED_PICKUPS
  } = oneConstants;
  const companyId = match?.params?.companyId;
  /**
   * Retrieving the role based on the current selected Company,
   * as the value may differ.
   * */
  const currentUserAccess = user?.companyAccess?.find(
    company => company?.id.toString() === companyId
  );
  const currentUserRole = currentUserAccess?.role;
  const hasReachedIntegrationBling = Boolean(
    localStorage.getItem(INTEGRATION_BLING_STORAGE_KEY) ?? false
  );
  const isPickupsUnifiedWithDynamic = Boolean(
    localStorage.getItem(ENABLE_UNIFIED_PICKUPS) ?? false
  );

  useEffect(() => {
    if (window.Appcues) {
      window.Appcues.identify(user?.sub, {
        displayName: user?.name,
        email: user?.email,
        role: currentUserRole,
        reachedIntegrationBling: hasReachedIntegrationBling,
        isUnifiedEnabled: isPickupsUnifiedWithDynamic,
        companyId
      });
    }
  }, [
    user,
    currentUserRole,
    hasReachedIntegrationBling,
    isPickupsUnifiedWithDynamic,
    companyId
  ]);
};

const appcuesPage = () => {
  /**
   * For our SPA, this is needed so that Appcues is notified when
   * a navigation change occurs.
   * */
  if (window.Appcues) {
    window.Appcues.page();
  }
};

export { appcuesPage };
export default useAppcues;
