import React, { useState, useEffect } from 'react';
import camelCaseKeys from 'camelcase-keys';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { Box, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useRemoteConfig } from '@loggi/components/src/one/remote-config';

import paymentsApi from '../payments-api';
import InvoiceList from './invoice-list.component';
import PaymentsNavigation from '../navigation/navigation.component';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: spacing(40),
    [breakpoints.up('sm')]: {
      height: spacing(50)
    }
  }
}));

const LoadingComponent = () => {
  const classes = useStyles();
  return (
    <Box data-testid="loading-component" className={classes.loadingContainer}>
      <CircularProgress />
    </Box>
  );
};

const Invoices = () => {
  const { t } = useTranslation('payments');
  const [invoices, setInvoices] = useState([]);
  const [isFetchFinished, setFetchFinished] = useState(false);
  const { companyId } = useParams();

  const { value: enablePriceAgreements } = useRemoteConfig(
    'enable_price_agreements'
  );

  const showPriceAgreements = enablePriceAgreements === 'true';

  useEffect(() => {
    async function getInvoices() {
      try {
        const invoiceList = await paymentsApi.getClosedInvoices(companyId);
        setInvoices(camelCaseKeys(invoiceList));
      } catch (_) {
        setInvoices([]);
      } finally {
        setFetchFinished(true);
      }
    }
    getInvoices();
  }, [companyId]);

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" component="h1">
            <Box component="strong">{t('invoices.title')}</Box>
          </Typography>
          {showPriceAgreements && (
            <Box>
              <PaymentsNavigation
                toggleButtonLabel={t('invoices.titleNavigation')}
              />
            </Box>
          )}
        </Box>
      </OneTemplateSummary>
      <OneTemplateContent disablePadding>
        {isFetchFinished ? (
          <InvoiceList invoices={invoices} />
        ) : (
          <LoadingComponent />
        )}
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default Invoices;
