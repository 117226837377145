import { addDays } from 'date-fns';
import PackageModel from './model';
import {
  isPackageDelivered,
  isPackageReturned,
  isPackageWaitingRedispatchPickup
} from './status.helpers';
import {
  getAddress,
  getPostalAddress,
  getRedispatchAddress
} from './address.helpers';
import { REDISPATCH_PICKUP_DEADLINE_DAYS } from '../constants';

/**
 * Receive a package object and extract important information from it to create
 * an instance of PackageModel.
 * @param {object} data - raw package data comming from package details tracking API
 * @returns {PackageModel}
 * status should be derived, history array or status node
 */
const trackingAPINormalizePackageStructure = data => {
  const trackingKey = data.trackingCode;
  const name = data.destination?.recipient?.name;
  const phone = data.destination?.recipient?.phone;
  const nif = data.destination?.recipient?.nif;
  const address = getPostalAddress(data.destination?.postalAddress);
  const originPostalCode = data.origin?.['postalCode']?.replace('-', '');
  const { companyCnpj } = data;
  const { companyId } = data;
  const { promisedDate } = data;
  const {
    heightCm: height,
    widthCm: width,
    lengthCm: length,
    weightG: weight
  } = data.dimension || {};
  const { direction } = data;
  const invoiceKey = data.invoice?.key;
  const invoiceSeries = data.invoice?.series;
  const invoiceNumber = data.invoice?.number;
  const invoiceValue = data.invoice?.['totalValue'];
  const { invoices } = data;
  const receiverName =
    data.deliveryInformation?.receiverName || data.receiver?.name;
  const receiverDocument = data.deliveryInformation?.receiverDocument;
  const deliveryLocationDescription =
    data.deliveryInformation?.locationDescription;
  const deliveryLinks = data.deliveryInformation?.links;

  // Status
  const { code, description, highLevelStatus: label, updatedTime } =
    data.status || {};

  const statusInfo = {
    code,
    description,
    label,
    updatedTime,
    actionRequired: data.status?.actionRequired
  };

  const isDelivered = isPackageDelivered(label);

  const isReturned = isPackageReturned(label);

  const deliveredDate =
    isDelivered || isReturned ? new Date(data.status?.updatedTime) : undefined;

  const deliveryInformation =
    isDelivered || isReturned
      ? {
          receiverName,
          receiverDocument,
          deliveryLocationDescription,
          deliveryLinks
        }
      : undefined;

  const redispatch = isPackageWaitingRedispatchPickup(label)
    ? data.redispatch
    : undefined;

  const pickupRedispatchDate = redispatch
    ? addDays(
        new Date(data.status?.updatedTime),
        REDISPATCH_PICKUP_DEADLINE_DAYS
      )
    : undefined;

  const pickupRedispatchAddress = redispatch?.pickupAddress
    ? getRedispatchAddress(redispatch?.pickupAddress)
    : undefined;

  const slo = data?.slo;
  const moneyAmount = data?.pricing?.amount;
  const chargeAmount = moneyAmount
    ? `${moneyAmount.units}.${moneyAmount.nanos.toString().substring(0, 2)}`
    : null;

  const volumetricInfo = data?.volumetricInfo;

  const updateReturnAddressInfo = data?.internalInfo?.updateReturnAddressInfo;

  return new PackageModel({
    trackingKey,
    recipient: { name, phone, nif },
    dimensions: { height, width, length },
    direction,
    weight,
    deliveryInformation,
    destination: { address },
    origin: { postalCode: originPostalCode },
    deliveredDate,
    status: label,
    statusInfo,
    promisedDate,
    contentInvoice: { invoiceNumber, invoiceKey, invoiceValue, invoiceSeries },
    invoices,
    redispatch: {
      ...redispatch,
      pickupAddress: pickupRedispatchAddress,
      pickupDate: pickupRedispatchDate
    },
    slo,
    chargeAmount,
    volumetricInfo,
    companyCnpj,
    companyId,
    updateReturnAddressInfo
  });
};

/**
 * Build a single instance of PackageModel
 * @param {object} data - raw package data from database
 * @returns {Promise}
 */
const trackingAPICreate = async (data = {}) => {
  return trackingAPINormalizePackageStructure(data);
};

/**
 * Build multiples instances of PackageModel for a given list of packages
 * @param {Array} data - list of raw packages data from database
 * @returns {Promise} Promise that resolves to an array of PackageModel
 * instances
 */
const trackingAPIBulkCreate = async (data = []) => {
  return data.map(pkg => trackingAPINormalizePackageStructure(pkg));
};

/**
 * Receive a package object from Search and extract important information from
 * it to create an instance of PackageModel. This structure use the Shipper Status from the response.
 * @param {object} data - raw package data from search
 * @returns {PackageModel}
 */
const normalizeSearchPackageStructure = data => {
  const trackingKey = data?.trackingKey;
  const { fullName: name, phone } = data?.recipient || {};
  const address = getAddress(data?.recipient?.address);

  // Status
  const { code, description, highLevelStatus: label } =
    data.shipperStatus || {};

  const statusInfo = {
    code,
    description,
    label
  };

  const slo = data?.slo;
  const chargeAmount = data?.pricing?.amount;
  const directionDescription = data?.packageDirection;

  const dimensions = {
    height: data?.heightCm,
    length: data?.lengthCm,
    width: data?.widthCm
  };
  const weight = data?.weightG;

  const direction = directionDescription
    ? {
        type: {
          description: directionDescription
        }
      }
    : directionDescription;
  const companyCnpj = data?.companyCnpj;

  const companyId = data?.companyId;

  return new PackageModel({
    trackingKey,
    recipient: { name, phone },
    destination: { address },
    status: label,
    statusInfo,
    slo,
    chargeAmount,
    direction,
    dimensions,
    companyCnpj,
    companyId,
    weight
  });
};

/**
 * Build a single instance of PackageModel
 * @param {object} data - raw package data from search
 * @returns {PackageModel}
 */
const createFromSearch = async (data = {}) => {
  return normalizeSearchPackageStructure(data);
};

/**
 * Build multiples instances of PackageModel for a given list of packages
 * @param {Array} data - list of raw packages data from search
 * @returns {Promise} Array of PackageModel instances
 */
const bulkCreateFromSearch = async (data = []) => {
  return data.map(pkg => normalizeSearchPackageStructure(pkg));
};

export default {
  search: {
    create: createFromSearch,
    bulkCreate: bulkCreateFromSearch
  },
  trackingAPI: { create: trackingAPICreate, bulkCreate: trackingAPIBulkCreate }
};
