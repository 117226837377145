import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core/';

import theme from '@loggi/mar';

const StatusIndicator = props => {
  const { statusMap, status, noLabel, color, endDate, fontWeight } = props;

  if (!statusMap[status]) return null;

  return (
    <Box display="flex">
      <Box
        display="flex"
        alignItems="center"
        alignSelf="center"
        mr={1}
        width={8}
        height={8}
        borderRadius={100}
        data-testid="status-indicator"
        bgcolor={statusMap[status].color}
        border={status === 'CURRENT' ? 1 : null}
        borderColor={statusMap[status].borderColor}
      />
      {!noLabel && (
        <Typography variant="body1">
          <Box color={color} fontWeight={fontWeight} component="span">
            {statusMap[status].label} {status === 'CURRENT' ? endDate : null}
          </Box>
        </Typography>
      )}
    </Box>
  );
};

StatusIndicator.propTypes = {
  statusMap: PropTypes.objectOf(
    PropTypes.shape({
      color: PropTypes.string,
      borderColor: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  noLabel: PropTypes.bool,
  color: PropTypes.string,
  endDate: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

StatusIndicator.defaultProps = {
  noLabel: false,
  color: theme.palette.grey[700],
  endDate: '',
  fontWeight: theme.typography.fontWeightBold
};

export default StatusIndicator;
