export const ORDER_SCHEDULES_FETCH_ERRORS = {
  MISSING_SCHEDULING_ID: 'fetchSchedulingDetails: id must be given',
  INVALID_PAGE: 'fetchSchedulings: page must be greater than 0',
  INVALID_PAGE_SIZE: 'fetchSchedulings: pageSize must be greater than 0'
};

export const ORDER_PICKUPS_FETCH_ERRORS = {
  MISSING_SCHEDULING_ID: 'fetchSchedulingPickups: id must be given'
};

export const DEFAULT_PAGE_SIZE = 20;

export const FIRST_PAGE_TOKEN = 0;

export const FIRST_PAGE = 1;

export const MAX_ENTRIES_IN_HISTORY_STACK = 2;

export const PACKAGES_ROUTES = {
  show: {
    url: (companyId, trackingKey) =>
      `/empresas/${companyId}/pacotes/${trackingKey}`
  }
};

export const PICKUP_STATUS_ALLOCATED = 2;
export const PICKUP_STATUS_COLLECTED = 3;
export const PICKUP_STATUS_CHECKED_IN = 6;

export const PICKUP_RECEIPT_ROUTES = {
  beepedPackages: {
    url: (companyId, pickupOrderScheduleId, pickupReceiptId) =>
      `/empresas/${companyId}/coletas/${pickupOrderScheduleId}/comprovantes/${pickupReceiptId}`
  },
  report: {
    url: (companyId, pickupOrderScheduleId, pickupReceiptId) =>
      `/empresas/${companyId}/coletas/${pickupOrderScheduleId}/comprovantes/${pickupReceiptId}/reportar`
  }
};
