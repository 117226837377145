import { oneConstants } from '@loggi/components/src/one';

const { ONE_LOCALSTORAGE_KEY } = oneConstants;

export const PICKUP_RECEIPT_STORAGE_KEY = `${ONE_LOCALSTORAGE_KEY}_pickup_receipt`;

// Transport types translations
export const TRANSPORT_TYPES = {
  MOTORCYCLE: 'transportTypes.motorcycle',
  BICYCLE: 'transportTypes.bicycle',
  VAN: 'transportTypes.van',
  CAR: 'transportTypes.car'
};

export const TRANSPORT_TYPE_MAPPER = type => {
  const mapper = {
    TRANSPORT_TYPE_MOTORCYCLE: TRANSPORT_TYPES.MOTORCYCLE,
    TRANSPORT_TYPE_BICYCLE: TRANSPORT_TYPES.BICYCLE,
    TRANSPORT_TYPE_VAN: TRANSPORT_TYPES.VAN,
    TRANSPORT_TYPE_CAR: TRANSPORT_TYPES.CAR
  };
  return mapper[type];
};
