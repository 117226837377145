import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import TablePagination from '@material-ui/core/TablePagination';
import qs from 'qs';
import PropTypes from 'prop-types';
import { PACKAGE_LIST_PAGE_SIZE } from '../constants';
import { FILTERS_NAMES } from './constants';

const PackagesTablePagination = ({ count, isLoading }) => {
  const { t } = useTranslation('unifiedPickup');
  const queryParams = useQueryParams();
  const page = queryParams[FILTERS_NAMES.page] || 1;
  const history = useHistory();

  const onChange = useCallback(
    (_, nextPage) => {
      const params = { ...queryParams, pagina: nextPage + 1 };
      history.push(
        qs.stringify(params, { addQueryPrefix: true, encode: false })
      );
    },
    [history, queryParams]
  );

  return (
    <TablePagination
      data-testid="package-selection-pagination"
      component="div"
      onChangePage={onChange}
      page={Number(page) - 1}
      count={count}
      labelDisplayedRows={({ from, to, count: total }) =>
        t('packageSelection.pagination', { from, to, count: total })
      }
      rowsPerPageOptions={[PACKAGE_LIST_PAGE_SIZE]}
      rowsPerPage={PACKAGE_LIST_PAGE_SIZE}
      backIconButtonProps={{
        disabled: isLoading || Number(page) === 1
      }}
      nextIconButtonProps={{
        disabled: isLoading || page * PACKAGE_LIST_PAGE_SIZE >= count
      }}
    />
  );
};

PackagesTablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  isLoading: PropTypes.bool
};

PackagesTablePagination.defaultProps = {
  isLoading: false
};

export default PackagesTablePagination;
