import { createContext } from 'react';

export const defaultValue = {
  companyId: '',
  signUpCompanyStepValues: {
    sharedName: '',
    cnpj: '',
    consent: false,
    segment: '',
    isNoL4BStatus: false
  },
  updateCompanyPickupStepValues: {
    postalCode: '',
    estimatedMonthlyPackages: '',
    averageTicket: '',
    isOutOfCoverage: false,
    integrators: ''
  },
  updateCompanyContactStepValues: {
    phone: '',
    favoredContactTime: '',
    favoredContactMethod: ''
  },
  setCompanyId: () => {
    // Set the ID of the Company created
  },
  setSignUpCompanyStepValues: () => {
    // Set the form values of the first step
  },
  setUpdateCompanyPickupStepValues: () => {
    // Set the form values of the second step
  },
  setUpdateCompanyContactStepValues: () => {
    // Set the form values of the third step
  }
};

export const hasContextData = data => Boolean(Object.keys(data).length);

const SignupCompanyContext = createContext(defaultValue);

export default SignupCompanyContext;
