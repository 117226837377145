import { AppErrorBoundary, oneConstants } from '@loggi/components/src/one';
import { useFeature } from '@loggi/components/src/one/remote-config';
import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useHierarchyChildren } from '@loggi/components/src/one/hooks';
import SchedulingDetails from './screens/details';
import SchedulingDetailsDrivers from './screens/details/components/scheduling-details-drivers';
import SchedulingDetailsPackages from './screens/details/components/scheduling-details-packages';
import SchedulingDetailsPickupReceipt from './screens/details/components/scheduling-details-pickup-receipt';
import Scheduling from './screens/scheduling';

const App = () => {
  const { path } = useRouteMatch();
  const isPickupsUnifiedWithDynamic = useFeature('enable_unified_pickups');

  useEffect(() => {
    const { ENABLE_UNIFIED_PICKUPS } = oneConstants;
    localStorage.setItem(ENABLE_UNIFIED_PICKUPS, isPickupsUnifiedWithDynamic);
  }, [isPickupsUnifiedWithDynamic]);

  useHierarchyChildren();

  return (
    <AppErrorBoundary
      beforeCapture={scope => {
        scope.setTag('app.region', 'tracking');
      }}
    >
      <Switch>
        <Route exact path={path} component={Scheduling} />
        <Route exact path={`${path}/:id`} component={SchedulingDetails} />
        <Route
          path={`${path}/:id/pacotes`}
          component={SchedulingDetailsPackages}
        />
        <Route
          path={`${path}/:id/veiculos`}
          component={SchedulingDetailsDrivers}
        />
        <Route
          path={`${path}/:id/comprovantes/:pickupReceiptId`}
          component={SchedulingDetailsPickupReceipt}
        />
      </Switch>
    </AppErrorBoundary>
  );
};

export default App;
