import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { useCurrentCompany, useCurrentUser } from '../auth';

const useSentry = () => {
  // Set user data
  const user = useCurrentUser();
  useEffect(() => {
    if (user) {
      const { email, companyAccess, name } = user;
      Sentry.setUser({ username: name, email, companyAccess });
    }
  }, [user]);

  // Set company data
  const company = useCurrentCompany();
  useEffect(() => {
    if (company) {
      Sentry.setTag('company.id', company.id);
      Sentry.setContext('company', company);
    }
  }, [company]);
};

export default useSentry;
