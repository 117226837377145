import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  useTheme
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import { useTranslation } from 'react-i18next';

import { FILTERS_NAMES } from '../../constants';

const useStyles = makeStyles(({ typography, palette }) => ({
  inputText: {
    fontWeight: typography.fontWeightBold,
    color: palette.grey[500],
    textOverflow: 'ellipsis'
  }
}));

const PackagesSearchBar = ({ onChange }) => {
  const classes = useStyles();
  const theme = useTheme();
  const initialFilter = useQueryParams()[FILTERS_NAMES.SEARCH];
  const filter = initialFilter || '';
  const [value, setValue] = useState(filter);
  const { t } = useTranslation('packages');
  const debounceDelayInMs = 600;

  useEffect(() => {
    const debounceId = setTimeout(() => {
      const newFilter = Object();
      newFilter[FILTERS_NAMES.SEARCH] = value;
      onChange(newFilter);
    }, debounceDelayInMs);
    return () => clearTimeout(debounceId);

    /*
     * Passing setFilter function to dependency array, have an undeserided
     * effect of runnin effect twice, for every state change. That's why
     * linter rule was disabled in this effect callback.
     */
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      display="flex"
      borderRadius={8}
      alignItems="center"
      pr={3}
      pt={{ md: 1, xs: 2 }}
      bgcolor={theme.palette.common.white}
      width="100%"
    >
      <Box display="flex" align-items="stretch" flexGrow={1}>
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon color="primary" />
            </InputAdornment>
          }
          fullWidth
          id="filter-receiver-name-input"
          inputProps={{
            'data-testid': 'filter-receiver-name-input',
            variant: 'body2',
            className: classes.inputText
          }}
          name="filter-receiver-name-input"
          onChange={event => {
            setValue(event.target.value);
          }}
          value={value}
          variant="body2"
          color="primary"
          placeholder={t('list.search.placeholder')}
          type="search"
        />
      </Box>
    </Box>
  );
};

PackagesSearchBar.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default PackagesSearchBar;
