import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import FilterDate from './filter-date';
import PickupSearchBar from './filter-document';
import FilterOrder from './filter-order';
import FilterStatus from './filter-status';

/**
 * SchedulingFilters renders filters that modify the URL with parameters.
 * @componentType presentational
 * @returns {*}
 * @constructor
 */
const SchedulingFilters = () => {
  const isUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));

  const { spacing } = useTheme();
  const menuStyles = {
    PaperProps: {
      style: {
        maxHeight: spacing(42),
        marginTop: spacing(1),
        maxWidth: spacing(10)
      }
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  };

  return (
    <Box py={1} my={2}>
      <Grid
        container
        spacing={3}
        justify="space-between"
        wrap={isUpMd ? 'nowrap' : 'wrap'}
      >
        <Grid item xs={12} md={7}>
          <PickupSearchBar />
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={12} md={4}>
            <FilterStatus menuStyles={menuStyles} />
          </Grid>
          <Grid item xs={12} md={4}>
            <FilterDate menuStyles={menuStyles} />
          </Grid>
          <Grid item xs={12} md={4}>
            <FilterOrder menuStyles={menuStyles} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SchedulingFilters;
