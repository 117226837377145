import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { deleteUser } from '../service';
import sendNotificationMessage from '../components/send-notification-message';
import errorHandler from '../service/error-handler';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  actionCol: {
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse'
    }
  },
  closeButton: {
    marginTop: spacing(1),
    [breakpoints.up('sm')]: {
      marginTop: spacing(0),
      marginRight: spacing(1.5)
    }
  }
}));

/**
 * RevokeUserDialog's responsibility is to show a dialog to confirm
 * a revoke user intention, with the params it receives.
 * @componentType: Presentational
 * @param companyId
 * @param handleClose
 * @param open
 * @param userId
 * @returns {*}
 */
const RevokeUserDialog = ({
  accountId,
  handleClose,
  name,
  open,
  updateUsersListCallback,
  userId
}) => {
  const [loading, setLoading] = useState(false);
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('revokeUser');

  const handleRevokeUserRequest = async () => {
    setLoading(true);
    deleteUser(accountId, userId)
      .then(() => {
        sendNotificationMessage(
          t('notificationMessages.success'),
          'success',
          enqueueSnackbar
        );
        updateUsersListCallback();
        handleClose();
      })
      .catch(error => {
        sendNotificationMessage(
          t('notificationMessages.error'),
          'error',
          enqueueSnackbar
        );
        errorHandler(error);
        handleClose();
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box p={{ xs: 3, sm: 5 }}>
        <Box pb={3}>
          <Typography variant="h5">
            <strong>{t('dialog.header')}</strong>
          </Typography>
        </Box>
        <Box pb={3}>
          <Typography variant="body1">
            {t('dialog.description', { name })}
          </Typography>
        </Box>

        <Box
          className={styles.actionCol}
          display="flex"
          justifyContent="center"
        >
          <Button
            data-testid="close-btn"
            className={styles.closeButton}
            onClick={handleClose}
            color="primary"
            size="medium"
          >
            {t('dialog.actions.keep')}
          </Button>
          <Button
            data-testid="revoke-btn"
            onClick={handleRevokeUserRequest}
            disabled={loading}
            size="medium"
            color="secondary"
            variant="outlined"
            autoFocus
          >
            {t('dialog.actions.revoke')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

RevokeUserDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool,
  updateUsersListCallback: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
};

RevokeUserDialog.defaultProps = {
  open: false
};

export default RevokeUserDialog;
