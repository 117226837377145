import React from 'react';
import PropTypes from 'prop-types';

import { Box, Divider, Hidden } from '@material-ui/core';

import {
  PACKAGE_DELIVERED_DATE,
  PACKAGE_DELIVERY_PROMISED_DATE,
  PACKAGE_RETURNED_DATE,
  PACKAGE_RETURN_PROMISED_DATE,
  PACKAGE_WAITING_REDISPATCH_PICKUP_DATE
} from '../constants';
import { getFormattedDate } from '../utils';
import PackageReturnedDate from './package-date-components/package-returned-date.component';
import PackageDeliveredDate from './package-date-components/package-delivered-date.component';
import PackageDeliveryPromisedDate from './package-date-components/package-delivery-promise-date.component';
import PackageReturnPromisedDate from './package-date-components/package-return-promise-date.component';
import PackageWaitingRedispatchPickupDate from './package-date-components/package-waiting-redispatch-pickup-date.component';

/**
 * DetailsSummaryDateSection's only responsibility is to show the package date info.
 * According to the params it receives, the date can be a
 * delivered date; a promised date; or a new promised date - for packages in a return direction.
 * @componentType: Presentational
 * @param date
 * @param returnDate
 * @param displayedDateComponent
 * @param isPackageDelayed
 * @returns {*}
 */
const DetailsSummaryDateSection = ({
  date,
  returnDate,
  displayedDateComponent,
  isPackageDelayed
}) => {
  const getDateComponent = () => {
    switch (displayedDateComponent) {
      case PACKAGE_RETURNED_DATE:
        return <PackageReturnedDate date={date} />;
      case PACKAGE_DELIVERED_DATE:
        return <PackageDeliveredDate date={date} />;
      case PACKAGE_RETURN_PROMISED_DATE:
        return (
          <PackageReturnPromisedDate
            returnDate={getFormattedDate(returnDate)}
            date={date}
          />
        );
      case PACKAGE_DELIVERY_PROMISED_DATE:
        return (
          <PackageDeliveryPromisedDate
            date={date}
            isPackageDelayed={isPackageDelayed}
          />
        );
      case PACKAGE_WAITING_REDISPATCH_PICKUP_DATE:
        return <PackageWaitingRedispatchPickupDate date={date} />;
      default:
        return <></>;
    }
  };

  return (
    <Box display="flex">
      <Hidden smDown>
        <Box pr={3} pl={4} alignSelf="center">
          <Divider
            style={{ height: '40px', backgroundColor: 'white', opacity: 0.4 }}
            orientation="vertical"
          />
        </Box>
      </Hidden>
      {getDateComponent()}
    </Box>
  );
};

DetailsSummaryDateSection.propTypes = {
  date: PropTypes.string.isRequired,
  returnDate: PropTypes.string,
  displayedDateComponent: PropTypes.string.isRequired,
  isPackageDelayed: PropTypes.bool.isRequired
};

DetailsSummaryDateSection.defaultProps = {
  returnDate: undefined
};

export default DetailsSummaryDateSection;
