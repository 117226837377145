import { loggiWebApi } from '@loggi/authentication-lib';
import * as Sentry from '@sentry/react';

export const currentBillingUrl = companyId =>
  `/one/api/billing/v1/companies/${companyId}/current/`;

export class PricingTableCreateError extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'PricingTableCreateError';
  }
}

const billingApi = {
  getCurrentBilling: companyId => {
    const url = currentBillingUrl(companyId);

    return loggiWebApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .get()
      .json();
  },
  postPricingTable: ({ companyId, postalCode, segment }) => {
    const url = `${currentBillingUrl(companyId)}pricing/`;
    const payload = {
      postal_code: postalCode.replace('-', ''),
      segment
    };

    return loggiWebApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .post(payload)
      .json()
      .catch(error => {
        const thrownError = new PricingTableCreateError(error?.message);
        Sentry.captureException(thrownError, {
          contexts: { 'original error': error }
        });
      });
  }
};

export default billingApi;
