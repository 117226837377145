import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Button, Dialog, Typography } from '@material-ui/core';

/**
 * ErrorDownloadReportModal's responsibility is to show a modal (dialog)
 * that indicates that the report download request failed.
 * @componentType: Presentational
 * @param open
 * @param onClose
 * @returns {*}
 */
const ErrorDownloadReportModal = ({ open, onClose }) => {
  const { t } = useTranslation('packages');

  return (
    <Dialog data-testid="error-download-report-dialog" open={open}>
      <Box p={3} display="flex" flexDirection="column">
        <Box pb={2}>
          <Typography variant="h6">
            <strong> {t('customReport.requestModal.error.title')}</strong>
          </Typography>
        </Box>
        <Box pb={3}>
          <Typography variant="body1">
            {t('customReport.requestModal.error.subtitle')}
          </Typography>
        </Box>
        <Box alignSelf="flex-end">
          <Button
            onClick={onClose}
            size="large"
            variant="contained"
            color="primary"
            autoFocus
          >
            {t('customReport.requestModal.error.closeButton')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

ErrorDownloadReportModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

ErrorDownloadReportModal.defaultProps = {
  open: false
};

export default ErrorDownloadReportModal;
