import { AppErrorBoundary } from '@loggi/components/src/one';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { routes } from '../payments';
import Invoices from '../payments/invoices';
import PriceAgreements from '../payments/price-agreements';

const App = () => (
  <AppErrorBoundary
    beforeCapture={scope => {
      scope.setTag('app.region', 'payments');
    }}
  >
    <Switch>
      <Route path={routes.invoiceDocuments.path} component={Invoices} />
      <Route path={routes.priceAgreements.path} component={PriceAgreements} />
      <Route path={routes.index.path} component={Invoices} />
    </Switch>
  </AppErrorBoundary>
);

export default App;
