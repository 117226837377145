import {
  AddressField,
  ComplementField,
  NoComplementField,
  NameField,
  PhoneField
} from '@loggi/components/src/one/formik-fields';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

const NewRecipientSection = ({ enablePackageRecipientEdition }) => {
  const { t } = useTranslation('packages');

  const recipientNameField = 'recipientName';
  const recipientPhoneField = 'recipientPhone';
  const addressField = 'packageAddress';
  const complementFieldName = 'packageAddressComplement';

  return (
    <>
      <Box pt={{ xs: 3, sm: 6 }} pb={3}>
        <Typography variant="h6">
          <strong>{t('edition.labels.newRecipientInfo')}</strong>
        </Typography>
      </Box>
      {enablePackageRecipientEdition && (
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          pb={{ xs: 4, sm: 6 }}
        >
          <Box flex="1" pr={{ sm: 5 }}>
            <NameField fieldName={recipientNameField} />
          </Box>
          <Box flex="1" pt={{ xs: 4, sm: 0 }}>
            <Box width={{ xs: '100%', sm: '60%' }}>
              <PhoneField
                isRequired={false}
                fieldName={recipientPhoneField}
                label={t('create.recipient.phone')}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
        <Box flex="1" pr={{ sm: 5 }}>
          <AddressField fieldName={addressField} />
        </Box>
        <Box flex="1" display="flex" pt={{ xs: 4, sm: 0 }}>
          <Box flex="1" pr={{ xs: 2, sm: 5 }}>
            <ComplementField fieldName={complementFieldName} />
          </Box>
          <Box flex="1">
            <NoComplementField complementFieldName={complementFieldName} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

NewRecipientSection.propTypes = {
  enablePackageRecipientEdition: PropTypes.bool.isRequired
};

export default NewRecipientSection;
