import { colors } from '@loggi/mar';
import { Box, useTheme } from '@material-ui/core';

import PropTypes from 'prop-types';
import React from 'react';

const IconWrapper = ({ children }) => {
  const { spacing } = useTheme();

  return (
    <Box
      alignItems="center"
      bgcolor={colors.blue[50]}
      borderRadius={spacing(1)}
      display="flex"
      height={spacing(6)}
      justifyContent="center"
      width={spacing(6)}
    >
      {children}
    </Box>
  );
};

IconWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

IconWrapper.displayName = 'IconWrapper';

export default IconWrapper;
