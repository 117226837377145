import { Box, TableCell, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const TableCellHead = ({ children, mr, ml }) => {
  const { typography } = useTheme();

  return (
    <TableCell>
      <Box mr={mr} ml={ml}>
        <Typography
          variant="body2"
          color="textPrimary"
          style={{ fontWeight: typography.fontWeightBold }}
        >
          {children}
        </Typography>
      </Box>
    </TableCell>
  );
};

TableCellHead.propTypes = {
  children: PropTypes.node.isRequired,
  mr: PropTypes.number,
  ml: PropTypes.number
};
TableCellHead.defaultProps = {
  mr: 0,
  ml: 0
};

export default TableCellHead;
