import { Auth } from 'aws-amplify';

const refreshUserSession = async callback => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();
  cognitoUser.refreshSession(currentSession.refreshToken, (_err, session) => {
    callback(session);
  });
};

export default refreshUserSession;
