import { getI18n } from 'react-i18next';
import { STATUS } from './constants';

const PriceAgreementText = (status, startEffectiveOn, startHourEffectiveOn) => {
  const i18n = getI18n();
  const t = i18n.getFixedT(null, 'payments');

  switch (status) {
    case STATUS.ACTIVE:
      return t('priceAgreements.priceAgreement.text.active', {
        startEffectiveOn,
        startHourEffectiveOn
      });

    case STATUS.INACTIVE:
      return t('priceAgreements.priceAgreement.text.inactive', {
        startEffectiveOn,
        startHourEffectiveOn
      });
    default:
      return t('priceAgreements.priceAgreement.text.default');
  }
};

export default PriceAgreementText;
