import { ReactComponent as Buildings } from '@loggi/components/src/one/shared/images/buildings.svg';
import { colors } from '@loggi/mar';
import {
  Box,
  ButtonBase,
  Container,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';

import packagesRoutes from '../packages-routes';
import DownloadPackagesLabelsButton from './download-packages-labels-button.component';

const useAccessDeniedStyle = makeStyles(({ palette }) => ({
  image: {
    fill: palette.primary.contrastText
  },
  root: {
    backgroundImage: colors.gradients.sanches
  }
}));

const PackageCreateSuccess = () => {
  const styles = useAccessDeniedStyle();
  const { t } = useTranslation('packages');
  const { companyId } = useParams();
  const { state } = useLocation();
  const trackingKeys = state?.trackingKeys ?? [];
  const count = trackingKeys.length;

  return (
    <Box
      className={styles.root}
      color="primary.contrastText"
      display="flex"
      height="100vh"
    >
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          height={1}
          justifyContent="center"
          pt={9.5}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Buildings className={styles.image} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="inherit"
                component="h1"
                gutterBottom
                variant="h4"
              >
                <Box fontWeight="fontWeightMedium" component="strong">
                  {t('create.successTitle', { count })}
                </Box>
              </Typography>
              <Typography variant="h6" component="h2" color="inherit">
                {t('create.successDescription', { count })}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5} md={3}>
              <DownloadPackagesLabelsButton trackingKeys={trackingKeys} />
              <Box
                fontSize={16}
                fontWeight="fontWeightBold"
                py={2}
                textAlign="center"
              >
                <ButtonBase
                  color={colors.root[0]}
                  component={Link}
                  to={packagesRoutes.index.url(companyId)}
                >
                  {t('create.spreadsheetFlow.success.linkText')}
                </ButtonBase>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default PackageCreateSuccess;
