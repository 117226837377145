const formattedCNPJ = cnpj => {
  const regexCnpj = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g;
  const formatOfCnpj = '$1.$2.$3/$4-$5';
  return cnpj.replace(regexCnpj, formatOfCnpj);
};

const extractFirstInitials = name => {
  if (!name) return undefined;

  const arr = name.split(' ');
  if (arr.length > 1) {
    return (arr[0][0] + arr[1][0]).toUpperCase();
  }

  return name[0];
};

export { formattedCNPJ, extractFirstInitials };
