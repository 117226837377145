import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(({ breakpoints, spacing, typography }) => ({
  paper: {
    [breakpoints.up('sm')]: {
      maxWidth: typography.pxToRem(320)
    }
  },
  dialogContent: {
    '&, &:first-child': {
      padding: spacing(4)
    }
  }
}));

const LoadingDialog = ({ message }) => {
  const { dialogContent, paper } = useStyles();

  return (
    <Dialog classes={{ paper }} data-testid="loading-dialog" open>
      <DialogContent className={dialogContent}>
        <Box
          alignItems="center"
          textAlign={{ xs: 'center', sm: 'initial' }}
          color="text.primary"
          flexDirection={{ xs: 'column', sm: 'row' }}
          display="flex"
        >
          <Box height={40} mb={{ xs: 2, sm: 0 }} mr={{ sm: 2 }}>
            <CircularProgress size={40} />
          </Box>
          {message}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

LoadingDialog.propTypes = {
  message: PropTypes.element.isRequired
};

export default LoadingDialog;
