import { AppErrorBoundary } from '@loggi/components/src/one';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { setEnableShipperV2 } from '@loggi/components/src/one/package-utils/status.helpers';
import {
  useFeature,
  useRemoteConfig
} from '@loggi/components/src/one/remote-config';

import {
  CreatePackage,
  CreatePackageForm,
  ImportInvoice,
  ImportInvoiceDrawer,
  InstructionsDrawer,
  InvoiceErrors,
  PackageCreateSuccess,
  PackageDetails,
  PackageRecipientEdition,
  PackageRecipientEditionSuccess,
  PackagesList,
  routes,
  SpreadsheetErrors,
  UploadSpreadsheet
} from '../packages';

const { singlePackage, spreadsheet } = routes.create;

const App = () => {
  const { value: enableShipperStatusV2Rollout } = useRemoteConfig(
    'enable_shipper_status_v2_rollout'
  );

  const disableShipperStatusV2 = useFeature('disable_shipper_status_v2');
  const enableShipperStatusV2 = useFeature('enable_shipper_status_v2');

  let useShipperStatusV2;

  if (enableShipperStatusV2Rollout === 'true') {
    useShipperStatusV2 = !disableShipperStatusV2;
  } else {
    useShipperStatusV2 = enableShipperStatusV2;
  }

  setEnableShipperV2(useShipperStatusV2);

  return (
    <AppErrorBoundary
      beforeCapture={scope => {
        scope.setTag('app.region', 'packages');
      }}
    >
      <Switch>
        <Route exact path={singlePackage.path}>
          <CreatePackage>
            <CreatePackageForm />
          </CreatePackage>
        </Route>
        <Route exact path={spreadsheet.upload.path}>
          <CreatePackage>
            <UploadSpreadsheet />
          </CreatePackage>
        </Route>
        <Route exact path={spreadsheet.instructions.path}>
          <CreatePackage>
            <UploadSpreadsheet />
            <InstructionsDrawer />
          </CreatePackage>
        </Route>
        <Route
          exact
          path={spreadsheet.errors.path}
          component={SpreadsheetErrors}
        />
        <Route
          exact
          path={routes.createSuccess.path}
          component={PackageCreateSuccess}
        />
        <Route exact path={routes.index.path} component={PackagesList} />
        <Route exact path={routes.show.path} component={PackageDetails} />
        <Route exact path={routes.options.path} component={PackageDetails} />
        <Route
          exact
          path={routes.trackingHistory.path}
          component={PackageDetails}
        />
        <Route
          exact
          path={routes.edit.path}
          component={PackageRecipientEdition}
        />
        <Route
          exact
          path={routes.editSuccess.path}
          component={PackageRecipientEditionSuccess}
        />
        <Route
          exact
          path={routes.importInvoiceErrors.path}
          component={InvoiceErrors}
        />
        <Route path={routes.importInvoice.path}>
          <CreatePackage>
            <ImportInvoice />
            <Route exact path={routes.importInvoiceInstructions.path}>
              <ImportInvoiceDrawer />
            </Route>
          </CreatePackage>
        </Route>
      </Switch>
    </AppErrorBoundary>
  );
};

export default App;
