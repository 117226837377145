import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import {
  useSubscription,
  dispatchEvent
} from '@loggi/components/src/one/useSubscription';
import NotificationDialogUI from './notification-dialog-ui';
import { requestPermission } from '../../firebase/notifications';
import { createNotificationBridge } from '../mobile/bridge';
import { constants } from '../use-notification-status';

export const HIDE_COOKIE = 'hide-notification-dialog';
export const ASK_FOR_PUSH_NOTIFICATION_BRIGDE =
  'askForPushNotificationPermission';
const ASK_FOR_PUSH_NOTIFICATION = 'ask-for-push-notification';
const { NOTIFICATION_SCOPE } = constants;
const TEN_YEARS = 3650;

const NotificationDialog = ({ scope }) => {
  const hide = Boolean(Cookies.get(HIDE_COOKIE));
  const [display, setDisplay] = useState(!hide);

  useSubscription('order-schedule:created', () => setDisplay(true), []);

  const onFail = () => {
    Cookies.set(HIDE_COOKIE, true, { expires: TEN_YEARS });
    setDisplay(false);
  };

  const onSuccess = async () => {
    setDisplay(false);
    if (scope === NOTIFICATION_SCOPE.IOS_SUPPORTED) {
      const notificationBridge = createNotificationBridge(
        ASK_FOR_PUSH_NOTIFICATION_BRIGDE
      );
      notificationBridge[ASK_FOR_PUSH_NOTIFICATION_BRIGDE]({
        message: ASK_FOR_PUSH_NOTIFICATION
      });
    } else {
      const data = await requestPermission();
      if (data?.token) {
        // This event will trigger NotificationGateway
        dispatchEvent('notification:status', data);
      }
    }
  };

  const onClose = () => {
    setDisplay(false);
  };

  return display ? (
    <NotificationDialogUI
      onFail={onFail}
      onSuccess={onSuccess}
      onClose={onClose}
    />
  ) : null;
};

NotificationDialog.propTypes = {
  scope: PropTypes.string.isRequired
};

export default NotificationDialog;
