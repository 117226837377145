import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_SEARCH_DAYS,
  FIRST_PAGE_TOKEN
} from './list/constants';

export const baseUrl = '/one/api/packages/v1/companies/';

const removeEmptyParams = urlSearchParams => {
  const params = urlSearchParams;
  const keysForDelete = [];

  params.forEach((value, key) => {
    if (value === '') {
      keysForDelete.push(key);
    }
  });

  keysForDelete.forEach(key => {
    params.delete(key);
  });

  return params;
};

const endpoints = {
  search: backendUrl => {
    const {
      companyId = undefined,
      query = undefined,
      pageToken = FIRST_PAGE_TOKEN,
      shipperStatus = undefined,
      created = DEFAULT_SEARCH_DAYS,
      from = undefined,
      to = undefined,
      pageSize = DEFAULT_PAGE_SIZE,
      packageDirection = undefined,
      integratorId = undefined,
      searchCompanies = companyId
    } = backendUrl;

    const params = removeEmptyParams(
      new URLSearchParams({
        page_size: pageSize,
        page_token: pageToken,
        query: query || '',
        shipper_status: shipperStatus || '',
        from,
        to,
        created,
        package_direction: packageDirection || '',
        integrator_id: integratorId || '',
        search_companies: searchCompanies || ''
      })
    );

    if (from && to) {
      params.delete('created');
    } else {
      params.delete('to');
      params.delete('from');
    }

    return `${baseUrl}${companyId}/packages:search/?${params.toString()}`;
  },
  create: companyId => `${baseUrl}${companyId}/packages/`,
  getLabel: (companyId, trackingKey) =>
    `${baseUrl}${companyId}/packages/${trackingKey}/label/`,
  cancel: (companyId, trackingKey) =>
    `${baseUrl}${companyId}/packages/${trackingKey}/cancel/`,
  bulkCancel: companyId => `${baseUrl}${companyId}/packages/bulkcancel/`,
  report: companyId => `${baseUrl}${companyId}/packages/report/`,
  update: (companyId, trackingKey) =>
    `${baseUrl}${companyId}/packages/${trackingKey}/`,
  upload: companyId => `${baseUrl}${companyId}/packages/upload/`,
  xmlUpload: companyId => `${baseUrl}${companyId}/packages/upload-nfes/`
};

export default endpoints;
