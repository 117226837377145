import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export const SectionTitle = ({ children }) => (
  <Box mb={{ xs: 2, sm: 3 }}>
    <Typography variant="h4">
      <Box component="strong" fontWeight="fontWeightBold">
        {children}
      </Box>
    </Typography>
  </Box>
);

export const SubSectionTitle = ({ children }) => (
  <Box mb={{ xs: 2, sm: 3 }}>
    <Typography variant="h5">
      <Box component="strong" fontWeight="fontWeightBold">
        {children}
      </Box>
    </Typography>
  </Box>
);

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired
};

SubSectionTitle.propTypes = {
  children: PropTypes.node.isRequired
};
