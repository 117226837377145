import Toast from '@loggi/components/src/one/toast';
import { useFeature } from '@loggi/components/src/one/remote-config';
import recurringPickupApi from '@loggi/pickups/src/unified-pickup/recurring-pickup-api';
import { Box, Button, Dialog, Typography, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import RecurringPickupItemPropTypes from './recurring-pickup-item.propTypes';

/**
 * DeleteConfirmationModal's responsibility is to show a dialog to confirm
 * a delete recurrence, with the params it receives.
 * @componentType: Presentational
 * @param companyId
 * @param handleClose
 * @param open
 * @param timeRange
 * @param daysList
 * @returns {*}
 */
const DeleteConfirmationModal = ({
  handleClose,
  recurring,
  onDelete,
  open,
  timeRange,
  daysList
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { companyId } = useParams();
  const { spacing } = useTheme();
  const shouldUsePickupsApi = useFeature('should_use_pickups_api');

  const { t } = useTranslation('scheduling');

  const buildToast = (key, message) => {
    return <Toast id={key} message={message} type="error" />;
  };

  const deleteRecurring = () => {
    setLoading(true);
    recurringPickupApi
      .delete(companyId, recurring.id, shouldUsePickupsApi)
      .then(() => onDelete())
      .catch(error => {
        enqueueSnackbar(error.message, {
          content: (key, message) => buildToast(key, message)
        });
      })
      .finally(() => handleClose(), setLoading(false));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box p={{ xs: 3, sm: 6 }}>
        <Box pb={2}>
          <Typography variant="h5">
            <strong>{t('dialog.header')}</strong>
          </Typography>
        </Box>
        <Box pb={5}>
          <Typography variant="body1">
            {t('dialog.description', { daysList, timeRange })}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            data-testid="close-btn"
            onClick={handleClose}
            color="primary"
            variant="outlined"
            style={{ marginRight: spacing(3) }}
          >
            {t('dialog.actions.keep')}
          </Button>
          <Button
            data-testid="delete-btn"
            onClick={deleteRecurring}
            disabled={loading}
            color="secondary"
            autoFocus
          >
            {t('dialog.actions.delete')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

DeleteConfirmationModal.propTypes = {
  recurring: PropTypes.shape(RecurringPickupItemPropTypes).isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  timeRange: PropTypes.string.isRequired,
  daysList: PropTypes.string.isRequired
};

DeleteConfirmationModal.defaultProps = {
  open: false
};

export default DeleteConfirmationModal;
