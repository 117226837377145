import React, { useEffect, useState, useCallback } from 'react';
import { Box, InputBase, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  inputText: {
    color: palette.grey[900],
    textOverflow: 'ellipsis',
    fontSize: '15px'
  },
  inputContainer: {
    border: `1px solid ${palette.grey[500]}`,
    borderRadius: spacing(1),
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0.5, 2),
    margin: spacing(3),
    bgcolor: palette.common.white,
    [breakpoints.down('xs')]: {
      marginBottom: spacing(1)
    }
  }
}));

const CompaniesSearchBar = ({ companies, setFilteredCompanies }) => {
  const { t } = useTranslation('components');
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const DEBOUNCE_DELAY_MS = 400;

  const filterForSearchedCompanies = useCallback(() => {
    const filteredCompanies = companies.filter(company => {
      const companyName = company.name.toLowerCase();
      const companySharedName = company.shared_name.toLowerCase();
      return (
        companyName.includes(searchValue) ||
        companySharedName.includes(searchValue) ||
        company.cnpj.includes(searchValue)
      );
    });
    setFilteredCompanies(filteredCompanies);
  }, [companies, setFilteredCompanies, searchValue]);

  useEffect(() => {
    if (searchValue) {
      const debounceId = setTimeout(() => {
        filterForSearchedCompanies();
      }, DEBOUNCE_DELAY_MS);
      return () => clearTimeout(debounceId);
    }
    return setFilteredCompanies([...companies]);
  }, [
    searchValue,
    companies,
    filterForSearchedCompanies,
    setFilteredCompanies
  ]);

  return (
    <Box className={classes.inputContainer}>
      <SearchIcon color="primary" />
      <Box display="flex" align-items="stretch" pl={1} flexGrow={1}>
        <InputBase
          fullWidth
          id="companies-search-input"
          inputProps={{
            'data-testid': 'companies-search-input',
            variant: 'body2',
            className: classes.inputText
          }}
          name="companies-search-input"
          onChange={event => setSearchValue(event.target.value.toLowerCase())}
          value={searchValue}
          variant="body2"
          color="primary"
          placeholder={t('header.companiesMenu.searchBar')}
          type="search"
        />
      </Box>
    </Box>
  );
};

CompaniesSearchBar.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape([
      PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          shared_name: PropTypes.string,
          cnpj: PropTypes.string
        })
      )
    ])
  ).isRequired,
  setFilteredCompanies: PropTypes.func.isRequired
};

export default CompaniesSearchBar;
