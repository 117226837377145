import pickupsApi from './pickups-api';
import PICKUP_ORDER_SCHEDULE_PACKAGES_API_ENDPOINTS from './constants';

const pickupOrderSchedulePackagesApi = {
  get: pickupOrderScheduleUUID => {
    return pickupsApi
      .url(
        PICKUP_ORDER_SCHEDULE_PACKAGES_API_ENDPOINTS.get(
          pickupOrderScheduleUUID
        )
      )
      .accept('application/json')
      .content('application/json')
      .get();
  }
};

export default pickupOrderSchedulePackagesApi;
