import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import InvoiceDetailsContent from './invoice-details/invoice-details-content';
import InvoiceDetailsDrawer from './invoice-details/invoice-details.drawer';
import useInvoiceDetails from './invoice-details/use-invoice-details.hook';
import InvoiceItem from './invoice-item.component';
import NoInvoices from './no-invoices.component';
import CurrentInvoiceItem from './current-invoice-item.component';

const InvoiceList = ({ invoices }) => {
  const { companyId, invoiceLabel } = useParams();

  const {
    dataInvoiceDetails,
    isDrawerOpen,
    resetInvoiceDetailsData,
    setInvoiceDetailsData
  } = useInvoiceDetails(invoices, companyId, invoiceLabel);

  return (
    <>
      <Box width={1} py={2} data-testid="invoice-list">
        {invoices.length === 0 ? (
          <NoInvoices />
        ) : (
          <>
            <CurrentInvoiceItem />
            {invoices.map(invoice => (
              <Fragment key={invoice.invoiceId}>
                <InvoiceItem
                  invoice={invoice}
                  onClickDownload={setInvoiceDetailsData}
                />
                <Divider />
              </Fragment>
            ))}
          </>
        )}
      </Box>
      {isDrawerOpen && (
        <InvoiceDetailsDrawer onClose={resetInvoiceDetailsData}>
          <InvoiceDetailsContent invoice={dataInvoiceDetails} />
        </InvoiceDetailsDrawer>
      )}
    </>
  );
};

InvoiceList.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      invoiceId: PropTypes.number.isRequired,
      amount: PropTypes.string,
      interval: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      bankSlipUrl: PropTypes.string,
      invoiceReportUrl: PropTypes.string,
      nfsesZipUrl: PropTypes.string,
      ctesZipUrl: PropTypes.string,
      debitNoteUrl: PropTypes.string
    })
  ).isRequired
};

export default InvoiceList;
