import React, { createContext } from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import GenericError from '../screens/generic-error/generic-error.component';

const ErrorBoundaryContext = createContext({
  error: null,
  componentStack: null
});

const GenericAppErrorBoundary = props => {
  return (
    <ErrorBoundaryContext.Provider value={props}>
      <GenericError />
    </ErrorBoundaryContext.Provider>
  );
};

const AppErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={scope => {
        scope.setTag('app.region', 'pickup-order-schedule');
      }}
      fallback={GenericAppErrorBoundary}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

AppErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired
};

export default AppErrorBoundary;
