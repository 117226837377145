import * as Sentry from '@sentry/react';
import {
  refreshUserSession,
  showNotificationMessage
} from '@loggi/authentication/src/service';
import billingApi from '@loggi/payments/src/payments/billing-api';
import { MARKETING_UTMS_LOCAL_STORAGE_KEY } from '@loggi/authentication/src/screens/signup/constants';
import { sortedInputOptions } from '@loggi/components/src/one/segments';
import { HOME_ROUTE } from '../../routes/constants';
import { signupCompany } from '../service/sign-up-company';
import { CNPJ_ERRORS, COMPANY_INTEGRATORS } from './constants';

export const getCorrespondentIntegrator = integrator => {
  const integratorValue = COMPANY_INTEGRATORS[integrator];
  if (integratorValue) return integratorValue;
  const integratorKey = Object.keys(COMPANY_INTEGRATORS).find(
    key => COMPANY_INTEGRATORS[key] === integrator
  );
  return integratorKey || null;
};

export const getCorrespondentIntegratorsList = integratorsList => {
  return [...integratorsList].map(integrator => {
    return getCorrespondentIntegrator(integrator);
  });
};

export const getIntegratorsList = () => {
  return Object.values(COMPANY_INTEGRATORS);
};

export const hasCnpjError = errorPayload => {
  return errorPayload.cnpj && CNPJ_ERRORS[errorPayload.cnpj[0]];
};

export const handleErrorMessage = (t, errorMessage) => {
  const defaultMessage = t('errorsMessages.default');
  try {
    const parsedErrorMessage = JSON.parse(errorMessage);
    if (
      parsedErrorMessage &&
      !hasCnpjError(parsedErrorMessage) &&
      parsedErrorMessage.code
    ) {
      return t(`errorsMessages.${parsedErrorMessage.code}`);
    }
    if (parsedErrorMessage && hasCnpjError(parsedErrorMessage))
      return t(`errorsMessages.${CNPJ_ERRORS[parsedErrorMessage.cnpj[0]]}`);
    return defaultMessage;
  } catch (e) {
    return defaultMessage;
  }
};

/* eslint-disable camelcase */
export const createCompany = (
  t,
  request,
  setCompanyId,
  enqueueSnackbar,
  push,
  stopLoading = () => {},
  showFinishedScreen = () => {}
) =>
  signupCompany(request.payload)
    .then(async company => {
      stopLoading();
      await refreshUserSession(async () => {
        showNotificationMessage(
          t('successMessage'),
          'success',
          enqueueSnackbar
        );

        const outOfCoverage =
          request.payload.company_configuration_json?.is_no_l4b_status ||
          request.payload.company_configuration_json?.is_out_of_coverage_status;

        if (outOfCoverage) {
          showFinishedScreen();
        } else {
          setCompanyId(company.id);
          push(HOME_ROUTE, { isFromSignUpCompany: true });
        }

        if (request.generatePricingTable) {
          await billingApi.postPricingTable({
            postalCode: request.payload.company_configuration_json?.postal_code,
            segment: request.payload.company_configuration_json.segment,
            companyId: company.id
          });
        }

        localStorage.removeItem(MARKETING_UTMS_LOCAL_STORAGE_KEY);
      });
    })
    .catch(error => {
      Sentry.captureException(error);
      const errorMessage = handleErrorMessage(t, error.message);
      showNotificationMessage(errorMessage, 'error', enqueueSnackbar);
      stopLoading();
    });

export const getSegmentSelectOptions = t => [
  {
    id: 'segment',
    inputLabel: t('companySignupForm.segment.inputLabel'),
    options: sortedInputOptions
  }
];

export const getEstimatedMonthlyPackagesOptions = (
  estimatedPackagesRange,
  t
) => {
  const upToLabel = initialRange =>
    t('companyPickupForm.estimatedMonthlyPackages.options.upTo', {
      initialRange
    });
  const fromToLabel = (initialRange, finalRange) =>
    t('companyPickupForm.estimatedMonthlyPackages.options.fromTo', {
      initialRange,
      finalRange
    });
  const moreThanLabel = finalRange =>
    t('companyPickupForm.estimatedMonthlyPackages.options.moreThan', {
      finalRange
    });

  const { micro, small, medium, large } = estimatedPackagesRange;

  return [
    {
      label: upToLabel(micro[0] - 1),
      value: micro[0] - 1
    },
    {
      label: fromToLabel(micro[0], micro[1]),
      value: micro[1]
    },
    {
      label: fromToLabel(small[0], small[1]),
      value: small[1]
    },
    {
      label: fromToLabel(medium[0], medium[1]),
      value: medium[1]
    },
    {
      label: moreThanLabel(large[0] - 1),
      value: large[0]
    }
  ];
};
