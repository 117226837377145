import React from 'react';
import WakeCodeIntegrator from '../components/wake/wake-code-integrator.component';
import TrayCodeIntegrator from '../components/tray/tray-code-integrator.component';

const DefaultValue = () => {
  return <></>;
};

const customPageList = {
  wake: WakeCodeIntegrator,
  tray: TrayCodeIntegrator,
  default: DefaultValue
};

export default customPageList;
