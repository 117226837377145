// eslint-disable-next-line max-classes-per-file
import { loggiWebApi, pickupsApi } from '@loggi/authentication-lib';
import * as Sentry from '@sentry/react';
import qs from 'qs';
import { getI18n } from 'react-i18next';
import { DEFAULT_LIST_PAGE_SIZE } from './constants';

export const recurringRequestErrors = {
  badRequest: {
    '4': 'errorMessages.warehouseTimeout',
    '5': 'errorMessages.warehouseModelValidation',
    generic: 'errorMessages.badRequestGeneric',
    duplicatedRecurringPickup: 'errorMessages.multipleRecurringPickup'
  },
  generic: 'errorMessages.generic',
  notFound: 'errorMessages.notFound'
};

export const endpoints = {
  create: {
    url: (companyId, appName) =>
      `/${appName}/api/recurring_pickups/v1/companies/${companyId}/recurring_pickups/`
  },
  delete: (companyId, id, appName) =>
    `/${appName}/api/recurring_pickups/v1/companies/${companyId}/recurring_pickups/${id}/`,
  index: {
    url: (companyId, appName) =>
      `/${appName}/api/recurring_pickups/v1/companies/${companyId}/recurring_pickups/`
  },
  getOrUpdate: {
    url: (companyId, recurringPickupId, appName) =>
      `/${appName}/api/recurring_pickups/v1/companies/${companyId}/recurring_pickup/${recurringPickupId}/`
  }
};

const formatPickupPayload = ({ companyId, data }) => {
  const {
    pickupAddress,
    pickupAddressComplement,
    pickupOriginInstructions,
    pickupOriginName,
    pickupOriginPhone,
    pickupWeekdays,
    pickupDate,
    pickupStartTime,
    pickupEndTime
  } = data;

  return {
    company_id: companyId,
    end_time: pickupEndTime,
    friday: pickupWeekdays.includes('5'),
    instructions: pickupOriginInstructions,
    monday: pickupWeekdays.includes('1'),
    origin_address: {
      address_complement: pickupAddressComplement,
      full_address: pickupAddress.description,
      place_id: pickupAddress.place_id
    },
    saturday: pickupWeekdays.includes('6'),
    shipper_name: pickupOriginName,
    shipper_phone: pickupOriginPhone,
    start_time: pickupStartTime,
    starting_from_date: pickupDate,
    sunday: pickupWeekdays.includes('0'),
    thursday: pickupWeekdays.includes('4'),
    tuesday: pickupWeekdays.includes('2'),
    wednesday: pickupWeekdays.includes('3'),
    document: data?.document
  };
};

export class RecurringPickupCreateError extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'RecurringPickupCreateError';
  }
}

export class RecurringPickupDeleteError extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'RecurringPickupDeleteError';
  }
}

export class RecurringPickupListError extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'RecurringPickupListError';
  }
}

export class RecurringPickupUpdateError extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'RecurringPickupUpdateError';
  }
}

export class RecurringPickupNotFoundError extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'RecurringPickupNotFoundError';
  }
}

export class RecurringPickupGetError extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'RecurringPickupGetError';
  }
}

const getApiAndAppName = shouldUsePickupsApi => {
  if (shouldUsePickupsApi) {
    return {
      apiInstance: pickupsApi,
      appName: 'envios'
    };
  }
  return {
    apiInstance: loggiWebApi,
    appName: 'one'
  };
};

const recurringPickupApi = {
  create: ({ companyId, data, shouldUsePickupsApi = false }) => {
    const t = getI18n().getFixedT(null, 'unifiedPickup');
    const formattedPayload = formatPickupPayload({ companyId, data });
    const { apiInstance, appName } = getApiAndAppName(shouldUsePickupsApi);

    const url = endpoints.create.url(companyId, appName);

    return apiInstance
      .url(url)
      .accept('application/json')
      .content('application/json')
      .post(formattedPayload)
      .badRequest(error => {
        const { badRequest } = recurringRequestErrors;
        const scope = new Sentry.Scope();
        scope.setContext('payload', formattedPayload);
        Sentry.captureException(error);

        let snackbarMessage;

        try {
          const parsedError = JSON.parse(error.message);
          const code = parsedError?.code || parsedError?.message?.code;
          snackbarMessage = badRequest[code] || badRequest.generic;
        } catch {
          snackbarMessage = badRequest.generic;
        }

        throw new RecurringPickupCreateError(t(snackbarMessage));
      })
      .json()
      .catch(error => {
        let thrownError;

        if (!(error instanceof RecurringPickupCreateError)) {
          thrownError = new RecurringPickupCreateError(
            t(recurringRequestErrors.generic)
          );
          const scope = new Sentry.Scope();
          scope.setContext('payload', formattedPayload);
          Sentry.captureException(error);
        } else {
          thrownError = error;
        }

        throw thrownError;
      });
  },
  delete(companyId, id, shouldUsePickupsApi = false) {
    const t = getI18n().getFixedT(null, 'unifiedPickup');
    const { apiInstance, appName } = getApiAndAppName(shouldUsePickupsApi);

    return apiInstance
      .url(endpoints.delete(companyId, id, appName))
      .delete()
      .json()
      .catch(error => {
        Sentry.captureException(new RecurringPickupDeleteError(error.message), {
          contexts: { recurring: { id } }
        });
        throw new Error(t('unifiedPickup:errorMessages.delete'));
      });
  },
  index: (companyId, document, page, shouldUsePickupsApi = false) => {
    const t = getI18n().getFixedT(null, 'unifiedPickup');
    const params = { document, page, page_size: DEFAULT_LIST_PAGE_SIZE };
    const { apiInstance, appName } = getApiAndAppName(shouldUsePickupsApi);

    return apiInstance
      .url(`${endpoints.index.url(companyId, appName)}?${qs.stringify(params)}`)
      .accept('application/json')
      .get()
      .json()
      .catch(error => {
        const thrownError = new RecurringPickupListError(
          t(recurringRequestErrors.generic)
        );
        Sentry.captureException(thrownError, {
          contexts: { 'original error': error }
        });

        throw thrownError;
      });
  },
  put: ({
    data,
    companyId,
    recurringPickupId,
    shouldUsePickupsApi = false
  }) => {
    const formattedPayload = formatPickupPayload({ companyId, data });
    const { apiInstance, appName } = getApiAndAppName(shouldUsePickupsApi);
    const url = endpoints.getOrUpdate.url(
      companyId,
      recurringPickupId,
      appName
    );
    const t = getI18n().getFixedT(null, 'unifiedPickup');

    return apiInstance
      .url(url)
      .accept('application/json')
      .content('application/json')
      .put(formattedPayload)
      .badRequest(() => {
        const { badRequest } = recurringRequestErrors;
        throw new RecurringPickupUpdateError(t(badRequest.generic));
      })
      .json()
      .catch(error => {
        const scope = new Sentry.Scope();
        scope.setContext('payload', formattedPayload);
        Sentry.captureException(error);

        if (error instanceof RecurringPickupUpdateError) {
          throw error;
        }

        throw new RecurringPickupUpdateError(t(recurringRequestErrors.generic));
      });
  },
  get: ({ companyId, recurringPickupId, shouldUsePickupsApi = false }) => {
    const { apiInstance, appName } = getApiAndAppName(shouldUsePickupsApi);
    const url = endpoints.getOrUpdate.url(
      companyId,
      recurringPickupId,
      appName
    );
    const t = getI18n().getFixedT(null, 'unifiedPickup');

    return apiInstance
      .url(url)
      .accept('application/json')
      .get()
      .notFound(() => {
        const { notFound } = recurringRequestErrors;
        throw new RecurringPickupNotFoundError(t(notFound));
      })
      .json()
      .catch(error => {
        if (error instanceof RecurringPickupNotFoundError) {
          throw error;
        }

        const thrownError = new RecurringPickupGetError(error);
        Sentry.captureException(thrownError, {
          contexts: { 'original error': error }
        });

        throw thrownError;
      });
  }
};

export default recurringPickupApi;
