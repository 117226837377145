import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, makeStyles } from '@material-ui/core';
import RevokeUserDialog from '../revoke-user-dialog';
import REVOKE_BTN_ID from '../constants';

const useStyles = makeStyles(() => ({
  button: {
    height: '55px',
    width: '75%'
  }
}));

/**
 * RevokeUserButton is a wrapper component that shows a confirmation
 * dialog when the revoke user button is pressed.
 * @componentType: Presentational
 * @param companyId
 * @param name
 * @param userId
 * @returns {*}
 */
const RevokeUserButton = ({
  accountId,
  name,
  updateUsersListCallback,
  handleClose,
  userId
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    handleClose();
  };

  return (
    <>
      <Box my={4} display="flex" justifyContent="center">
        <Button
          className={classes.button}
          color="primary"
          data-testid={REVOKE_BTN_ID}
          type="submit"
          onClick={handleClickOpen}
          size="large"
          fullWidth
        >
          Excluir membro
        </Button>
      </Box>
      <RevokeUserDialog
        accountId={accountId}
        handleClose={onClose}
        name={name}
        open={open}
        updateUsersListCallback={updateUsersListCallback}
        userId={userId}
      />
    </>
  );
};

RevokeUserButton.propTypes = {
  accountId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  updateUsersListCallback: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
};

export default RevokeUserButton;
