import React, { useEffect, useState } from 'react';
import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { useTranslation } from 'react-i18next';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import { getCompanyData } from '@loggi/components/src/one/hooks';
import { useParams } from 'react-router';
import { estimatedPackagesOptions } from '@loggi/components/src/one/constants';
import { segmentOptions } from '@loggi/components/src/one/segments';
import AboutCompanyTemplate from './about-company-template';
import CompanyDataError from './company-data-error';

const initialState = {
  isLoading: true,
  showGenericError: false,
  companyData: {}
};

const CompanyData = () => {
  const { t } = useTranslation('management');
  const { companyId } = useParams();
  const [{ companyData, isLoading, showGenericError }, setState] = useState(
    initialState
  );
  const {
    companyName,
    cnpj,
    sharedName,
    segment,
    averageTicket,
    estimatedMonthlyPackages,
    telephone
  } = companyData;

  const formattedCNPJ = cnpjReceived => {
    const regexCnpj = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g;
    const formatOfCnpj = '$1.$2.$3/$4-$5';
    return cnpjReceived.replace(regexCnpj, formatOfCnpj);
  };

  const segmentText = type => {
    const typeSegment = segmentOptions;
    return typeSegment[type];
  };

  const estimatedPackagesText = type => {
    const packages = estimatedPackagesOptions;
    return packages[type];
  };

  const aboutCompany = [
    {
      id: 'companyName',
      subtitle: t('companyData.aboutCompany.companyName'),
      value: companyName
    },
    {
      id: 'sharedName',
      subtitle: t('companyData.aboutCompany.sharedName'),
      value: sharedName
    },
    {
      id: 'cnpj',
      subtitle: t('companyData.aboutCompany.cnpj'),
      value: cnpj
    },
    {
      id: 'segment',
      subtitle: t('companyData.aboutCompany.segment'),
      value: segment
    },
    {
      id: 'telephone',
      subtitle: t('companyData.aboutCompany.telephone'),
      value: telephone
    }
  ];

  const aboutBilling = [
    {
      id: 'estimatedMonthlyPackages',
      subtitle: t('companyData.aboutBilling.estimatedMonthlyPackages'),
      value: estimatedMonthlyPackages
    },
    {
      id: 'averageTicket',
      subtitle: t('companyData.aboutBilling.averageTicket'),
      value: t('companyData.aboutBilling.monetarySymbol', { averageTicket })
    }
  ];

  const renderAboutBilling = averageTicket || estimatedMonthlyPackages;

  useEffect(() => {
    getCompanyData(companyId)
      .then(response => {
        const companyDataResponse = {
          companyName: response.name,
          sharedName: response.shared_name,
          cnpj: formattedCNPJ(response.cnpj),
          segment: segmentText(response.company_configuration?.segment),
          averageTicket: response.company_configuration?.['average_ticket'],
          estimatedMonthlyPackages: estimatedPackagesText(
            response.company_configuration?.['estimated_monthly_packages']
          ),
          telephone: response.landline_1
        };
        setState({
          isLoading: false,
          companyData: companyDataResponse
        });
      })
      .catch(() => {
        setState({
          isLoading: false,
          companyData: {},
          showGenericError: true
        });
      });
  }, [companyId]);

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Typography variant="h4" component="h1">
          <strong>{t('companyData.summary.title')}</strong>
        </Typography>
      </OneTemplateSummary>
      <OneTemplateContent>
        {showGenericError && <CompanyDataError />}
        {isLoading && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress justify="center" />
          </Box>
        )}
        {!showGenericError && !isLoading && (
          <>
            <AboutCompanyTemplate
              title={t('companyData.aboutCompany.title')}
              sections={aboutCompany}
            />
            {renderAboutBilling && (
              <AboutCompanyTemplate
                title={t('companyData.aboutBilling.title')}
                sections={aboutBilling}
              />
            )}
          </>
        )}
      </OneTemplateContent>
    </OneTemplate>
  );
};
export default CompanyData;
