import React, { useState } from 'react';
import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Box, Typography, Divider, Button } from '@material-ui/core';
import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';
import Toast from '@loggi/components/src/one/toast';
import { getDeliveryLocationDescriptionMap } from '../utils';
import DeliveryProofField from './delivery-proof-field';
import PackageDrawerBase from '../../package-drawer-base';
import DeliveryProofDrawerContent from './delivery-proof-drawer-content';

export class ProofOfDeliveryDownloadError extends Error {
  constructor(message) {
    super(message);
    this.name = `ProofOfDeliveryDownloadError`;
  }
}

const getPdfLink = data => {
  const pdfLink = data.deliveryLinks?.find(
    links => links.rel === 'delivery_receipt'
  );
  return pdfLink ? pdfLink.href : null;
};

const downloadFile = async (response, filename) => {
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

const PackageDetailsDeliveryProofSection = ({ data }) => {
  const { t } = useTranslation('packages');
  const pdfLink = getPdfLink(data);
  const [loading, setLoading] = useState(!pdfLink);
  const { enqueueSnackbar } = useSnackbar();
  const renderToast = () => {
    enqueueSnackbar(t('details.sections.deliveryDetails.toastMessage'), {
      content: (key, message) => (
        <Toast id={key} message={message} type="error" />
      )
    });
  };

  const deliveryLocationDescription = data.deliveryLocationDescription
    ? getDeliveryLocationDescriptionMap()[data.deliveryLocationDescription]
    : undefined;

  const handlePdfDowload = async () => {
    setLoading(true);
    const { trackingKey } = data;
    const response = await fetch(pdfLink, { method: 'GET' });
    if (response.ok) {
      const filename = t('details.sections.deliveryDetails.filename', {
        trackingKey
      });
      downloadFile(response, filename);
      setLoading(false);
    } else {
      renderToast();
      captureException(new ProofOfDeliveryDownloadError(response.statusText));
      setLoading(false);
    }
  };
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  return (
    <>
      <Divider />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pt={2}
        pb={1}
      >
        <Typography variant="h6">
          <strong>{t('details.sections.deliveryDetails.title')}</strong>
        </Typography>
      </Box>
      <Box display={{ sm: 'flex' }} flexWrap="wrap" flexDirection="row" pb={3}>
        <DeliveryProofField
          label={t('details.sections.deliveryDetails.receiverName')}
          field={data.receiverName}
        />
        <DeliveryProofField
          label={t('details.sections.deliveryDetails.receiverDocument')}
          field={data.receiverDocument}
        />
        <DeliveryProofField
          label={t('details.sections.deliveryDetails.locationDescription')}
          field={t(deliveryLocationDescription)}
        />
        <Box>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={handleOpenDrawer}
          >
            {t('details.sections.deliveryDetails.deliveryDetailsButton')}
          </Button>
          {openDrawer && (
            <PackageDrawerBase
              open={openDrawer}
              onClose={handleCloseDrawer}
              secondaryAction={handlePdfDowload}
              isSecondaryActionDisabled={loading}
            >
              <DeliveryProofDrawerContent
                trackingKey={data.trackingKey}
                pdfLink={pdfLink}
              />
            </PackageDrawerBase>
          )}
        </Box>
      </Box>
    </>
  );
};

PackageDetailsDeliveryProofSection.propTypes = {
  data: packageProps.isRequired
};

export default PackageDetailsDeliveryProofSection;
