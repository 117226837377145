import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ErrorIcon } from '@loggi/components/src/one/shared/images/error-icon.svg';
import CreatePackageNavigation from './package-selection-create-package-toogle-button.component';

const PackagesNotFound = ({ isFiltered }) => {
  const { t } = useTranslation('unifiedPickup');
  const textFirst = isFiltered
    ? t('packageSelection.packagesNotFound.filtered.first')
    : t('packageSelection.packagesNotFound.notFiltered.first');
  const textSecond = isFiltered
    ? t('packageSelection.packagesNotFound.filtered.second')
    : t('packageSelection.packagesNotFound.notFiltered.second');

  const { spacing } = useTheme();

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      textAlign="center"
      justifyContent="center"
      height={spacing(60.25)}
      px={5}
    >
      <ErrorIcon />
      <Box pt={3}>
        <Typography variant="subtitle2">{textFirst}</Typography>
        <Typography variant="subtitle2">{textSecond}</Typography>
      </Box>
      {!isFiltered && (
        <Box pt={3} width={{ xs: '100%', md: spacing(30) }}>
          <CreatePackageNavigation
            buttonText={t('packageSelection.packagesNotFound.addPackageButton')}
          />
        </Box>
      )}
    </Box>
  );
};

PackagesNotFound.propTypes = {
  isFiltered: PropTypes.bool
};

PackagesNotFound.defaultProps = {
  isFiltered: false
};

export default PackagesNotFound;
