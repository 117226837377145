import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  ButtonBase,
  TableCell,
  TableRow,
  Typography,
  useTheme,
  Checkbox
} from '@material-ui/core';

import { StatusIndicatorPackages } from '@loggi/components/src/one/status-indicator';
import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';
import { substituteCompanyIdOnEnviosUrl } from '@loggi/components/src/one/utils';
import IdentifierField from '../identifier-field';

const useStyles = makeStyles(({ spacing }) => ({
  checkBoxCell: {
    paddingLeft: spacing(4),
    paddingRight: 0,
    margin: 0
  }
}));

const TableRowItem = ({ pkg, handleToggle, checked, useHierarchySpecifcs }) => {
  const classes = useStyles();

  const history = useHistory();
  const { url } = useRouteMatch();
  const { spacing, typography } = useTheme();

  const handleCheckbox = (value, trackingKey) => {
    if (value === 'checkbox') {
      handleToggle(trackingKey);
    }
  };

  const hasClickedOnCheckbox = event => {
    return event.target.type === 'checkbox';
  };

  const hasClickedOnCopyButton = event => {
    return (
      event.target?.className instanceof SVGAnimatedString ||
      (typeof event.target?.className === 'string' &&
        event.target?.className?.includes('copyToClipBoard'))
    );
  };

  return (
    <ButtonBase
      component={TableRow}
      onClick={event => {
        if (hasClickedOnCheckbox(event) || hasClickedOnCopyButton(event)) {
          event.preventDefault();
        } else {
          history.push(
            `${substituteCompanyIdOnEnviosUrl(url, pkg.companyId)}/${
              pkg.trackingKey
            }`
          );
        }
      }}
      hover
      data-testid="package-row-item"
    >
      <TableCell className={classes.checkBoxCell}>
        <Checkbox
          data-testid="checkBox-item"
          checked={checked.indexOf(pkg.trackingKey) !== -1}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': 'labelId' }}
          onClick={event => handleCheckbox(event.target.type, pkg.trackingKey)}
        />
      </TableCell>
      <TableCell>
        <Box width={spacing(18)}>
          <StatusIndicatorPackages status={pkg.status} isTag />
        </Box>
      </TableCell>
      <TableCell>
        <Box width={spacing(20)} paddingLeft={2}>
          <Typography
            variant="body2"
            color="textPrimary"
            style={{ fontWeight: typography.fontWeightMedium }}
          >
            {pkg.recipientName}
          </Typography>
        </Box>
      </TableCell>
      {useHierarchySpecifcs && (
        <TableCell>
          <Box width={spacing(20)} paddingLeft={2}>
            <Typography
              variant="body2"
              color="textPrimary"
              style={{ fontWeight: typography.fontWeightMedium }}
            >
              {pkg.companyCnpj}
            </Typography>
          </Box>
        </TableCell>
      )}
      <TableCell>
        <Box overflow="hidden" textOverflow="ellipsis" width={spacing(20)}>
          <Typography variant="body2" color="textSecondary" noWrap>
            {pkg.destinationAddress}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box width={spacing(20)}>
          <Typography variant="button" color="textSecondary">
            <IdentifierField identifier={pkg.trackingKey} />
          </Typography>
        </Box>
      </TableCell>
    </ButtonBase>
  );
};

TableRowItem.propTypes = {
  pkg: packageProps.isRequired,
  handleToggle: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  useHierarchySpecifcs: PropTypes.bool
};

TableRowItem.defaultProps = {
  useHierarchySpecifcs: false
};

export default TableRowItem;
