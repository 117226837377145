import { Box, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ReturnAddressSection from '../return-address-section.component';
import GenericError from '../../components/generic-error/index';

/**
 * AddressListWrapper is a wrapper component that adds a loading capability
 * before the address list
 * @param {object} data
 * @param {boolean} isLoading
 * @returns {*}
 * @constructor
 */
const AddressListWrapper = ({ data, isLoading, error }) => {
  const wrap = component => <Box>{component}</Box>;

  if (isLoading)
    return wrap(
      <Box display="flex" justifyContent="center" padding={4}>
        <CircularProgress data-testid="loading" />
      </Box>
    );
  if (error)
    return wrap(
      <Box display="flex" justifyContent="center" padding={4}>
        <GenericError />
      </Box>
    );
  return wrap(<ReturnAddressSection data={data} />);
};

AddressListWrapper.propTypes = {
  data: PropTypes.shape({
    companyId: PropTypes.string,
    warehouseId: PropTypes.string,
    aliasName: PropTypes.string,
    returnAddress: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.bool
};

AddressListWrapper.defaultProps = {
  data: {},
  isLoading: true,
  error: false
};

export default AddressListWrapper;
