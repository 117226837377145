import { Box, Button, Divider } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AddressSectionSkeleton from './address-section-skeleton.component';
import ResponsibleSectionSkeleton from './responsible-section-skeleton.component';
import AddressAliasSectionSkeleton from './address-alias-section-skeleton.component';

const ReturnAddressFormSkeleton = () => {
  const { t } = useTranslation('management');

  return (
    <Box data-testid="return-address-form-skeleton">
      <Box pb={5}>
        <AddressSectionSkeleton />
      </Box>
      <Divider />
      <Box py={5}>
        <ResponsibleSectionSkeleton />
      </Box>
      <Divider />
      <Box py={5}>
        <AddressAliasSectionSkeleton />
      </Box>
      <Divider />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        pt={5}
        flexDirection={{ xs: 'column-reverse', sm: 'row' }}
      >
        <Box marginTop={{ xs: 1, sm: 0 }} marginRight={{ sm: 4 }}>
          <Button
            disabled
            data-testid="cancel-btn"
            color="primary"
            size="large"
          >
            {t('returnAddress.actions.cancel')}
          </Button>
        </Box>
        <Button
          disabled
          data-testid="returnAddressForm-actionButton"
          size="large"
          type="submit"
          variant="contained"
        >
          {t('returnAddress.actions.create.button')}
        </Button>
      </Box>
    </Box>
  );
};

export default ReturnAddressFormSkeleton;
