const PICKUP_RECEIPT_ROUTE = {
  path: '/comprovante-coleta/:pickupOrderScheduleHash',
  url: pickupOrderScheduleHash =>
    `/comprovante-coleta/${pickupOrderScheduleHash}`
};

const PICKUP_RECEIPT_AUTH_ROUTE = {
  path: '/comprovante-coleta/:pickupOrderScheduleHash/auth',
  url: pickupOrderScheduleHash =>
    `/comprovante-coleta/${pickupOrderScheduleHash}/auth`
};

const PICKUP_RECEIPT_FEEDBACK = {
  path:
    '/comprovante-coleta/:pickupOrderScheduleHash/comprovante/:pickupReceiptId/feedback',
  url: (pickupOrderScheduleHash, pickupReceiptId) =>
    `/comprovante-coleta/${pickupOrderScheduleHash}/comprovante/${pickupReceiptId}/feedback`
};

const PICKUP_RECEIPT_PACKAGES_ROUTE = {
  path:
    '/comprovante-coleta/:pickupOrderScheduleHash/comprovante/:pickupReceiptId/pacotes',
  url: (pickupOrderScheduleHash, pickupReceiptId) =>
    `/comprovante-coleta/${pickupOrderScheduleHash}/comprovante/${pickupReceiptId}/pacotes`
};

export {
  PICKUP_RECEIPT_ROUTE,
  PICKUP_RECEIPT_AUTH_ROUTE,
  PICKUP_RECEIPT_FEEDBACK,
  PICKUP_RECEIPT_PACKAGES_ROUTE
};
