import { colors } from '@loggi/mar';
import { Box, ButtonBase, Typography } from '@material-ui/core';
import { CheckCircle, Error } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as BarcodeReaderIcon } from '@loggi/components/src/one/shared/images/barcode-reader.svg';
import { PICKUP_RECEIPT_ROUTES } from '../../constants';

const ConfirmationInfo = ({ confirmationProperties }) => (
  <Box display="inline-flex">
    {confirmationProperties.icon}
    <Box pl={1} display="flex" alignItems="center">
      <Typography
        color={confirmationProperties.color}
        variant={confirmationProperties.variant}
      >
        {confirmationProperties.text}
      </Typography>
    </Box>
  </Box>
);

ConfirmationInfo.propTypes = {
  confirmationProperties: PropTypes.shape({
    icon: PropTypes.element,
    color: PropTypes.string,
    variant: PropTypes.string,
    text: PropTypes.string
  }).isRequired
};

const SchedulingDetailsReportBeepedPackages = props => {
  const { confirmedTotalPackages, numberOfBeepedPackages, receiptId } = props;

  const { t } = useTranslation('details');
  const { push } = useHistory();
  const { companyId, id } = useParams();

  const onReportClick = () => {
    push({
      pathname: PICKUP_RECEIPT_ROUTES.beepedPackages.url(
        companyId,
        id,
        receiptId
      ),
      state: { redirectToReport: true }
    });
  };

  const defaultConfirmationProperties = {
    icon: <BarcodeReaderIcon style={{ width: '24px', height: '24px' }} />,
    text: t('receiptConfirmation.notAnswered.button.text'),
    color: 'primary',
    variant: 'button',
    action: () => onReportClick()
  };

  const confirmationPropertiesMap = {
    true: {
      icon: (
        <CheckCircle
          style={{ color: colors.green[500], width: '24px', height: '24px' }}
        />
      ),
      text: t('receiptConfirmation.confirmed.message'),
      color: 'textSecondary',
      variant: 'caption',
      action: null
    },
    false: {
      icon: <Error color="error" style={{ width: '24px', height: '24px' }} />,
      text: t('receiptConfirmation.notConfirmed.message'),
      color: 'textSecondary',
      variant: 'caption',
      action: null
    }
  };

  const confirmationProperties =
    confirmationPropertiesMap[confirmedTotalPackages] ||
    defaultConfirmationProperties;

  return (
    <Box display="flex" pt={3} flexDirection="row">
      <Box>
        <Typography variant="body1" color="textPrimary">
          {t('numberOfBeepedPackages.label', {
            count: numberOfBeepedPackages
          })}
        </Typography>
      </Box>
      <Box pl={4} alignItems="center">
        {(confirmationProperties.action && (
          <ButtonBase
            onClick={() => {
              confirmationProperties.action();
            }}
            data-testid="receipt-report-button"
          >
            <ConfirmationInfo confirmationProperties={confirmationProperties} />
          </ButtonBase>
        )) || (
          <ConfirmationInfo confirmationProperties={confirmationProperties} />
        )}
      </Box>
    </Box>
  );
};

SchedulingDetailsReportBeepedPackages.propTypes = {
  confirmedTotalPackages: PropTypes.bool,
  numberOfBeepedPackages: PropTypes.number,
  receiptId: PropTypes.number.isRequired
};

SchedulingDetailsReportBeepedPackages.defaultProps = {
  confirmedTotalPackages: undefined,
  numberOfBeepedPackages: 0
};

export default SchedulingDetailsReportBeepedPackages;
