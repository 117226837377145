import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

const DeliveryProofField = ({ label, field }) => {
  return (
    <>
      {field && (
        <Box flex="1" pt={2}>
          <Typography variant="body2">
            <strong>{label}</strong>
          </Typography>
          <Typography variant="body1">{field}</Typography>
        </Box>
      )}
    </>
  );
};

DeliveryProofField.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.string
};

DeliveryProofField.defaultProps = {
  field: undefined
};

export default DeliveryProofField;
