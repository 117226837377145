import AddressAutocomplete from '@loggi/components/src/one/address-autocomplete';
import { FormHelperText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { ErrorMessage, useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AddressField = props => {
  const { fieldName } = props;
  const { t } = useTranslation('packages');

  const [field, meta, helpers] = useField({
    name: fieldName,
    validate: newValue => {
      return !newValue ? t('create.errorMessages.requiredAddress') : null;
    }
  });

  const { name, onBlur, value } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;
  const hasError = Boolean(error) && touched;

  return (
    <FormControl error={hasError} fullWidth variant="outlined">
      <AddressAutocomplete
        error={hasError}
        required
        label={t('create.address.addressLabel')}
        name={name}
        onBlur={onBlur}
        setValue={setValue}
        value={value}
      />
      <FormHelperText>
        <ErrorMessage name={name} />
      </FormHelperText>
    </FormControl>
  );
};

AddressField.propTypes = {
  fieldName: PropTypes.string.isRequired
};

export default AddressField;
