import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Button } from '@material-ui/core';

import priceAgreementsEmpty from '@loggi/components/src/one/shared/images/price-agreements-empty.svg';

const PriceAgreementsEmpty = () => {
  const { t } = useTranslation('payments');

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      minHeight={200}
      p={{ xs: 3, sm: 5.5 }}
    >
      <img src={priceAgreementsEmpty} alt="Price Agreements Empty" />
      <Box mt={3} mb={3}>
        <Box mb={1}>
          <Typography variant="body1" align="center">
            <Box component="strong">{t('priceAgreements.empty.title')}</Box>
          </Typography>
        </Box>
        <Typography variant="body1" align="center">
          {t('priceAgreements.empty.tryUpdateScreenText')}
        </Typography>
        <Typography variant="body1" align="center">
          {t('priceAgreements.empty.toSeeAvailableTablesText')}
        </Typography>
      </Box>
      <Button
        onClick={() => {
          window.location.reload();
        }}
        color="primary"
        role="button"
      >
        {t('priceAgreements.empty.linkLabelUpdate')}
      </Button>
    </Box>
  );
};

export default PriceAgreementsEmpty;
