const splitResultData = resultData => {
  const errorResults = resultData?.failure;
  const successResults = resultData?.success?.filter(
    result => result.warning_message === undefined
  );
  const warningResults = resultData?.success?.filter(
    result => result.warning_message !== undefined
  );

  return {
    errorResults: errorResults || [],
    successResults: successResults || [],
    warningResults: warningResults || []
  };
};

export default { splitResultData };
export { splitResultData };
