import {
  Box,
  Drawer,
  IconButton,
  makeStyles,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  useTheme
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ palette, typography }) => ({
  ul: {
    display: 'list-item',
    '&::marker': {
      fontSize: typography.subtitle1.fontSize,
      color: palette.primary.main
    },
    fontSize: typography.subtitle1.fontSize,
    padding: '0 2rem 0 .5rem',
    listStyle: 'disc',
    marginLeft: '20px'
  }
}));

const PeriodSectionDrawerExplain = () => {
  const { t } = useTranslation('orderScheduling');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const styles = useStyles();
  const { spacing } = useTheme();
  const pathTextsSectionPeriod =
    'create.dateSection.periodSection.sectionExplainPeriodSelect';
  const listItemsTimeLimit = t(
    `${pathTextsSectionPeriod}.drawer.listItemsLimitTime.bulletsPoints`,
    {
      returnObjects: true
    }
  );
  const listItemsExamples = t(
    `${pathTextsSectionPeriod}.drawer.listItemsExamples.bulletsPoints`,
    {
      returnObjects: true
    }
  );

  const renderItemsTimeLimit = () => {
    let i = 0;
    return listItemsTimeLimit.map(item => {
      i += 1;
      return (
        <ListItem className={styles.ul} key={i}>
          <ListItemText
            style={{ marginBottom: spacing(3) }}
            primary={
              <Typography variant="body1" style={{ lineHeight: '22px' }}>
                {item}
              </Typography>
            }
          />
        </ListItem>
      );
    });
  };

  const renderItemsExamples = () => {
    let i = 0;
    return listItemsExamples.map(item => {
      i += 1;
      return (
        <ListItem className={styles.ul} key={i}>
          <ListItemText
            style={{ marginBottom: spacing(3) }}
            primary={
              <Typography variant="body1" style={{ lineHeight: '22px' }}>
                {item}
              </Typography>
            }
          />
        </ListItem>
      );
    });
  };

  return (
    <>
      {t(`${pathTextsSectionPeriod}.drawer.textToDrawer`)}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        variant="body2"
        style={{ fontWeight: 'bold', cursor: 'pointer' }}
        onClick={() => setDrawerOpen(true)}
      >
        {t(`${pathTextsSectionPeriod}.drawer.linkDrawer`)}
      </Link>
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchor="right"
      >
        <Box
          display="flex"
          flexDirection="column"
          height={{ xs: 'auto', sm: '100%' }}
          width={{ xs: '100%', sm: 450 }}
          pt={3}
          px={5}
        >
          <Box mb={3}>
            <IconButton
              data-testid="close-drawer"
              edge="start"
              onClick={() => setDrawerOpen(false)}
            >
              <ArrowBack color="primary" />
            </IconButton>
          </Box>
          <Box mb={4} pr={4}>
            <Typography variant="h6">
              <Box component="strong" fontWeight="fontWeightBold">
                {t(`${pathTextsSectionPeriod}.drawer.title`)}
              </Box>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              {t(`${pathTextsSectionPeriod}.drawer.textAddPackages`)}
            </Typography>
            <Box mt={4} mb={2}>
              <Typography variant="body1">
                {t(`${pathTextsSectionPeriod}.drawer.subTitleLimitTime`)}
              </Typography>
            </Box>
            <List>
              <Box display="flex" flexDirection="column">
                {renderItemsTimeLimit()}
              </Box>
            </List>
          </Box>
          <Box mt={4}>
            <Typography variant="h6">
              <Box component="strong" fontWeight="fontWeightBold">
                {t(`${pathTextsSectionPeriod}.drawer.titleExamples`)}
              </Box>
            </Typography>
          </Box>
          <Box>
            <Box my={4}>
              <Typography variant="body1">
                {t(`${pathTextsSectionPeriod}.drawer.description.first`)}
                <strong>
                  {t(`${pathTextsSectionPeriod}.drawer.description.bold`)}
                </strong>
                {t(`${pathTextsSectionPeriod}.drawer.description.second`)}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                <Box component="strong" fontWeight="fontWeightBold">
                  {t(`${pathTextsSectionPeriod}.drawer.checkExamples`)}
                </Box>
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1">
                {t(
                  `${pathTextsSectionPeriod}.drawer.descriptionExamples.first`
                )}
                <strong>
                  {t(
                    `${pathTextsSectionPeriod}.drawer.descriptionExamples.boldFirst`
                  )}
                </strong>
                {t(
                  `${pathTextsSectionPeriod}.drawer.descriptionExamples.second`
                )}
                <strong>
                  {t(
                    `${pathTextsSectionPeriod}.drawer.descriptionExamples.boldSecond`
                  )}
                </strong>
                {t(
                  `${pathTextsSectionPeriod}.drawer.descriptionExamples.third`
                )}
                <strong>
                  {t(
                    `${pathTextsSectionPeriod}.drawer.descriptionExamples.boldThird`
                  )}
                </strong>
                {t(
                  `${pathTextsSectionPeriod}.drawer.descriptionExamples.fourth`
                )}
              </Typography>
            </Box>
            <List>
              <Box display="flex" flexDirection="column">
                {renderItemsExamples()}
              </Box>
            </List>
            <Box mt={1} mb={3.25}>
              <Typography variant="body1">
                {t(`${pathTextsSectionPeriod}.drawer.footer.first`)}
                <strong>
                  {t(`${pathTextsSectionPeriod}.drawer.footer.bold`)}
                </strong>
                {t(`${pathTextsSectionPeriod}.drawer.footer.second`)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default PeriodSectionDrawerExplain;
