import React from 'react';
import PropTypes from 'prop-types';
import { List, Divider, makeStyles } from '@material-ui/core';
import SchedulingDetailsDriversListItem from './scheduling-details-drivers-list-item.component';
import { SCHEDULING_STATUSES } from '../../../scheduling/constants';
import SchedulingDetailsEmptyDriversList from '../scheduling-details-empty-drivers-list';

const useStyles = makeStyles(({ breakpoints }) => ({
  divider: {
    [breakpoints.up('sm')]: {
      '&:last-child': {
        display: 'none'
      }
    }
  }
}));

/**
 * SchedulingDetailsDriversList renders a static list that only presents the data it receives
 * @componentType presentational
 * @param pickups
 * @param orderStatus
 * @returns {*}
 * @constructor
 */
const SchedulingDetailsDriversList = ({ pickups, orderStatus }) => {
  const classes = useStyles();

  const pickupsList = () => {
    return pickups.map(pickup => {
      return (
        <React.Fragment key={pickup.id}>
          <SchedulingDetailsDriversListItem
            driver={pickup.driver}
            pickupReceipt={pickup.pickupReceipt}
            status={pickup.status}
          />
          <Divider className={classes.divider} component="li" />
        </React.Fragment>
      );
    });
  };

  const PickupsDriversContent = pickups.length ? (
    <List>{pickupsList()}</List>
  ) : (
    <SchedulingDetailsEmptyDriversList />
  );

  return (
    <>
      {orderStatus === SCHEDULING_STATUSES.CANCELLED ? (
        <SchedulingDetailsEmptyDriversList cancelled />
      ) : (
        PickupsDriversContent
      )}
    </>
  );
};

SchedulingDetailsDriversList.propTypes = {
  pickups: PropTypes.oneOfType([PropTypes.any, PropTypes.arrayOf({})]),
  orderStatus: PropTypes.string
};
SchedulingDetailsDriversList.defaultProps = {
  pickups: [],
  orderStatus: null
};

export default SchedulingDetailsDriversList;
