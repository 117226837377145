import {
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { getUserRole } from '@loggi/components/src/one/permissions';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFeature } from '@loggi/components/src/one/remote-config';
import {
  COMPANIES_ROUTE,
  USERS_MANAGEMENT_PATH
} from '../users-management.constants';
import ScreenHeader from './components/screen-header';
import sendNotificationMessage from './components/send-notification-message';
import {
  EMAIL_INPUT_ID,
  ERROR_CODES,
  INVITE_BTN_ID,
  INVITE_USER_PROGRESS_BUTTON_TEST_ID,
  NAME_INPUT_ID,
  ROLES
} from './constants';
import inviteUser from './service';
import errorHandler from './service/error-handler';

const useButtonLoadingStyle = makeStyles(({ palette }) => ({
  progress: {
    color: palette.common.white
  }
}));

const InviteUserForm = () => {
  const { t } = useTranslation('inviteUserForm');
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const buttonLoadingStyle = useButtonLoadingStyle();
  const { companyId } = useParams();
  const { push } = useHistory();
  const isCreateSupportUserEnabled = useFeature('enable_support_user_invite');
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  const userRole = getUserRole(authenticatedUser, companyId);
  const userSbm = userRole === 'OWNER' || userRole === 'ADMIN';
  const userLarge = userRole === 'OWNER_LARGE' || userRole === 'ADMIN_LARGE';

  const handleInviteErrorMessage = error => {
    let parsedError;
    let errorMessage = t('notificationMessages.errors.default');
    try {
      parsedError = JSON.parse(error.message);
    } catch (e) {
      errorHandler(error);
      return errorMessage;
    }
    if (parsedError?.code === ERROR_CODES.ALREADY_INVITED)
      errorMessage = t('notificationMessages.errors.alreadyExists');
    else {
      errorHandler(error);
    }
    return errorMessage;
  };

  const inviteUserSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validateEmail.required'))
      .email(t('validateEmail.invalid')),
    name: Yup.string().required(t('validateName.required'))
  });

  const onSubmitUserInvite = values => {
    setLoading(true);
    inviteUser(values, companyId)
      .then(() => {
        sendNotificationMessage(
          t('notificationMessages.success'),
          'success',
          enqueueSnackbar
        );
        setLoading(false);
        push(`${COMPANIES_ROUTE}/${companyId}${USERS_MANAGEMENT_PATH}`);
      })
      .catch(error => {
        sendNotificationMessage(
          handleInviteErrorMessage(error),
          'error',
          enqueueSnackbar
        );
        setLoading(false);
      });
  };

  return (
    <Container>
      <Box>
        <ScreenHeader
          title={t('header.message')}
          subTitle={t('subHeader.message')}
        />
      </Box>
      <Formik
        initialValues={{
          email: '',
          name: '',
          role: ROLES.ADMIN.NAME
        }}
        validationSchema={inviteUserSchema}
        onSubmit={onSubmitUserInvite}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          touched
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              direction="column"
              justify="space-around"
              alignItems="center"
            >
              <Box width="100%" mt={2}>
                <TextField
                  inputProps={{ 'data-testid': NAME_INPUT_ID }}
                  margin="normal"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  label={t('nameInput.label')}
                  value={values.name}
                  variant="outlined"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Box>
              <Box width="100%" mt={2}>
                <TextField
                  inputProps={{ 'data-testid': EMAIL_INPUT_ID }}
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="email"
                  fullWidth
                  label={t('emailInput.label')}
                  value={values.email}
                  variant="outlined"
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Box>
              <Box width="100%" mt={4}>
                <FormControl component="fieldset">
                  <Typography variant="h5">{t('rolesOptions')}</Typography>
                  <RadioGroup
                    aria-label="role"
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                  >
                    {userSbm && (
                      <>
                        <Box my={3}>
                          <FormControlLabel
                            value={ROLES.ADMIN.NAME}
                            control={<Radio />}
                            label={t('roleInputSmb.options.admin.label')}
                          />
                          <Box mx={4}>
                            <Typography variant="caption">
                              {t('roleInputSmb.options.admin.text')}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                        <Box my={2}>
                          <FormControlLabel
                            value={ROLES.REGULAR.NAME}
                            control={<Radio />}
                            label={t('roleInputSmb.options.regular.label')}
                          />
                          <Box mx={4}>
                            <Typography variant="caption">
                              {t('roleInputSmb.options.regular.text')}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                        <Box my={2}>
                          <FormControlLabel
                            value={ROLES.OPERATOR.NAME}
                            control={<Radio />}
                            label={t('roleInputSmb.options.operator.label')}
                          />
                          <Box mx={4}>
                            <Typography variant="caption">
                              {t('roleInputSmb.options.operator.text')}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                      </>
                    )}
                    {userLarge && (
                      <>
                        <Box my={3}>
                          <FormControlLabel
                            value={ROLES.ADMIN_LARGE.NAME}
                            control={<Radio />}
                            label={t('roleInputLarge.options.adminLarge.label')}
                          />
                          <Box mx={4}>
                            <Typography variant="caption">
                              {t('roleInputLarge.options.adminLarge.text')}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                        <Box my={2}>
                          <FormControlLabel
                            value={ROLES.MANAGER_LARGE.NAME}
                            control={<Radio />}
                            label={t(
                              'roleInputLarge.options.managerLarge.label'
                            )}
                          />
                          <Box mx={4}>
                            <Typography variant="caption">
                              {t('roleInputLarge.options.managerLarge.text')}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                        <Box my={2}>
                          <FormControlLabel
                            value={ROLES.OPERATOR_LARGE.NAME}
                            control={<Radio />}
                            label={t(
                              'roleInputLarge.options.operatorLarge.label'
                            )}
                          />
                          <Box mx={4}>
                            <Typography variant="caption">
                              {t('roleInputLarge.options.operatorLarge.text')}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                      </>
                    )}
                    <Box my={2}>
                      <FormControlLabel
                        value={ROLES.FINANCE.NAME}
                        control={<Radio />}
                        label={t('roleInput.options.finance.label')}
                      />
                      <Box mx={4}>
                        <Typography variant="caption">
                          {t('roleInput.options.finance.text')}
                        </Typography>
                      </Box>
                    </Box>
                    {isCreateSupportUserEnabled && (
                      <>
                        <Divider />
                        <Box my={2}>
                          <FormControlLabel
                            value={ROLES.SUPPORT.NAME}
                            control={<Radio />}
                            label={t('roleInput.options.support.label')}
                          />
                          <Box mx={4}>
                            <Typography variant="caption">
                              {t('roleInput.options.support.text')}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box my={3} width="90%">
                <Button
                  size="large"
                  color="primary"
                  data-testid={INVITE_BTN_ID}
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={loading}
                >
                  {!loading && t('inviteButton.text')}
                  {loading && (
                    <CircularProgress
                      data-testid={INVITE_USER_PROGRESS_BUTTON_TEST_ID}
                      size={26}
                      className={buttonLoadingStyle.progress}
                    />
                  )}
                </Button>
              </Box>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default InviteUserForm;
