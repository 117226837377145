import marTheme from '@loggi/mar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import { routes } from '../payments';
import App from './app.component';

import { i18nInit } from '../config';

i18nInit();

const RootApp = () => (
  <ThemeProvider theme={marTheme}>
    <CssBaseline />
    <Router>
      <Switch>
        <Route path={routes.index.path} component={App} />
        <Redirect to={routes.index.url(1)} />
      </Switch>
    </Router>
  </ThemeProvider>
);

export default RootApp;
