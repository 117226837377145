import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import theme from '@loggi/mar/src/theme';
import StatusIndicator from '@loggi/components/src/one/status-indicator/status-indicator';
import { useTranslation } from 'react-i18next';
import { routes } from '../index';
import PriceAgreementText from './price-agreement-text';
import PriceAgreementTitle from './price-agreement-title';
import PriceAgreementStatus from './price-agreement-status';
import PriceAgreementsDrawer from './price-agreements-drawer.component';
import PriceAgreementsDetails from './price-agreements-details.component';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: palette.secondary.light
    }
  }
}));

const PriceAgreementsListItem = ({ priceAgreement }) => {
  const {
    priceAgreementId,
    status,
    startEffectiveOn,
    startHourEffectiveOn,
    endEffectiveOn,
    priceTables
  } = priceAgreement;
  const classes = useStyles();
  const { companyId } = useParams();
  const { push, goBack } = useHistory();
  const { pathname } = useLocation();

  const isPriceAgreementsDrawerOpen = pathname.includes(
    routes.priceAgreementsDetails.url(companyId, priceAgreementId)
  );

  const [open, setOpen] = useState(isPriceAgreementsDrawerOpen);

  const handleOpenDrawer = () => {
    setOpen(true);
    push(routes.priceAgreementsDetails.url(companyId, priceAgreementId));
  };

  const handleCloseDrawer = () => {
    setOpen(false);
    if (open) {
      goBack();
    }
  };
  const { t } = useTranslation('payments');

  const Details = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection={{ xs: 'column', sm: 'row' }}
      p={2}
    >
      <Box display="flex" flexDirection="column">
        <Box mb={1}>
          <StatusIndicator
            statusMap={PriceAgreementStatus()}
            status={status}
            noLabel={false}
            fontWeight={theme.typography.fontWeightMedium}
          />
        </Box>
        <Box mb={1}>
          <Typography variant="subtitle2">
            <Box component="strong">
              {PriceAgreementTitle(
                status,
                startEffectiveOn,
                endEffectiveOn,
                startHourEffectiveOn
              )}
            </Box>
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography color="textSecondary">
            {PriceAgreementText(status, startEffectiveOn, startHourEffectiveOn)}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Button color="primary" fullWidth variant="outlined">
          {t('priceAgreements.priceAgreement.downloadButton')}
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <ListItem className={classes.root} onClick={handleOpenDrawer} divider>
        <ListItemText disableTypography primary={<Details />} />
      </ListItem>
      <PriceAgreementsDrawer onClose={handleCloseDrawer} open={open}>
        <PriceAgreementsDetails
          priceAgreementId={priceAgreementId}
          status={status}
          startEffectiveOn={startEffectiveOn}
          startHourEffectiveOn={startHourEffectiveOn}
          priceTables={priceTables}
          onClose={handleCloseDrawer}
        />
      </PriceAgreementsDrawer>
    </>
  );
};

PriceAgreementsListItem.propTypes = {
  priceAgreement: PropTypes.shape({
    priceAgreementId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    startEffectiveOn: PropTypes.string.isRequired,
    startHourEffectiveOn: PropTypes.string.isRequired,
    endEffectiveOn: PropTypes.string,
    priceTables: PropTypes.arrayOf(
      PropTypes.shape({
        originRegion: PropTypes.string.isRequired,
        priceTableName: PropTypes.string.isRequired
      })
    ).isRequired
  })
};
PriceAgreementsListItem.defaultProps = {
  priceAgreement: PropTypes.arrayOf(
    PropTypes.shape({
      endEffectiveOn: null
    })
  )
};

export default PriceAgreementsListItem;
