import { useDownloadPackageLabelsCallback } from '@loggi/components/src/one/hooks';
import { Button, CircularProgress } from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const DownloadPackagesLabelsButton = ({ trackingKeys }) => {
  const { t } = useTranslation('packages');
  const { companyId } = useParams();

  const count = trackingKeys.length;
  const timestamp = useMemo(
    () => format(new Date(), 'yyyy-MM-dd-hh[h]mm[m]'),
    []
  );
  const fileName = t('labelsFileName', {
    trackingKey: trackingKeys[0],
    count,
    timestamp
  });

  const { triggerDownloadLabels, loading } = useDownloadPackageLabelsCallback(
    companyId,
    trackingKeys,
    fileName
  );
  const disabled = count === 0 || loading;

  return (
    <Button
      data-testid="button"
      disabled={disabled}
      onClick={triggerDownloadLabels}
      size="large"
      variant="outlined"
      fullWidth
    >
      {loading ? (
        <CircularProgress color="inherit" data-testid="loading" size={20} />
      ) : (
        t('create.printLabels', { count })
      )}
    </Button>
  );
};

DownloadPackagesLabelsButton.propTypes = {
  trackingKeys: PropTypes.arrayOf(PropTypes.string)
};

DownloadPackagesLabelsButton.defaultProps = {
  trackingKeys: []
};

export default DownloadPackagesLabelsButton;
