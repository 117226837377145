import React from 'react';
import { useTranslation } from 'react-i18next';

import { PropTypes } from 'prop-types';

import { Box, Typography, Divider, Button, Hidden } from '@material-ui/core';
import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';

import BulkCancellationListItem from './bulk-cancellation-list-item.component';
import BulkCancellationListHeader from './bulk-cancellation-list-header.component';
import { splitResultData } from './bulk-cancellation-results.helpers';
import {
  EN_ROUTE_CANCELLATION_WARNING_MSG,
  REDISPATCH_ALREADY_CANCELLED_WARNING_MSG,
  REDISPATCH_CANCELLATION_WARNING_MSG
} from '../../constants';

/**
 * BulkCancellationResults renders the bulk cancellation result screen describing
 * the result from bulk cancellation it only presents the data it receives.
 * @componentType presentational
 * @param packagesData
 * @param resultData
 * @returns {*}
 */
const BulkCancellationResults = ({
  goBackToPackagesList,
  packagesData,
  resultData
}) => {
  const { t } = useTranslation('packages');

  const { successResults, warningResults, errorResults } = splitResultData(
    resultData
  );

  const getFullPackage = key => {
    return packagesData.filter(
      packageModel => packageModel.trackingKey === key
    )?.[0];
  };

  const getReason = (result, type) => {
    let reason = '';
    switch (type) {
      case 'error':
        reason = t('list.bulkCancellation.results.error.reasons.default');

        if (result.status.message === 'CancelPackageFinalizerStatusException') {
          reason = t(
            'list.bulkCancellation.results.error.reasons.finalizerStatusException'
          );
        }
        if (result.status.message === 'CancelPackageAlreadyReturnedException') {
          reason = t(
            'list.bulkCancellation.results.error.reasons.alreadyReturnedException'
          );
        }
        break;
      case 'warning':
        /* eslint-disable camelcase */
        if (result?.warning_message === EN_ROUTE_CANCELLATION_WARNING_MSG) {
          reason = t('list.bulkCancellation.results.warning.reasons.enRoute');
        }
        if (
          result?.warning_message === REDISPATCH_ALREADY_CANCELLED_WARNING_MSG
        ) {
          reason = t(
            'list.bulkCancellation.results.warning.reasons.redipatchAlreadyCancelled'
          );
        }
        if (result?.warning_message === REDISPATCH_CANCELLATION_WARNING_MSG) {
          reason = t(
            'list.bulkCancellation.results.warning.reasons.redispatchCancellation'
          );
        }
        break;
      default:
        reason = t('list.bulkCancellation.results.success.reasons.default');
        break;
    }

    return reason;
  };

  const ResultSection = (packagesResults, type) => {
    return (
      <>
        {packagesResults.length > 0 && (
          <Box pb={5.5} data-testid={`bulk-cancellation-section-${type}`}>
            <BulkCancellationListHeader
              type={type}
              badgeContent={packagesResults.length}
            />
            <Hidden only="xs">
              <Box display="flex" pt={4} pb={1.5}>
                <Box flex={2}>
                  <Typography variant="caption">
                    <strong>
                      {type === 'success'
                        ? t('list.bulkCancellation.results.labels.result')
                        : t('list.bulkCancellation.results.labels.reason')}
                    </strong>
                  </Typography>
                </Box>
                <Box flex={2} px={2}>
                  <Typography variant="caption">
                    <strong>
                      {t('list.bulkCancellation.results.labels.name')}
                    </strong>
                  </Typography>
                </Box>
                <Box flex={2}>
                  <Typography variant="caption">
                    <strong>
                      {t('list.bulkCancellation.results.labels.address')}
                    </strong>
                  </Typography>
                </Box>
                <Box flex={1.5} pl={4}>
                  <Typography variant="caption">
                    <strong>
                      {t('list.bulkCancellation.results.labels.trackingKey')}
                    </strong>
                  </Typography>
                </Box>
              </Box>
              <Divider />
            </Hidden>

            {packagesResults.map(result => {
              const fullPackage = getFullPackage(result.key);
              return (
                <BulkCancellationListItem
                  key={result.key}
                  trackingKey={result.key}
                  recipientName={fullPackage?.recipientName}
                  recipientAddress={fullPackage?.destinationAddress}
                  reason={getReason(result, type)}
                />
              );
            })}
          </Box>
        )}
      </>
    );
  };

  return (
    <Box
      px={{ xs: 3, sm: 5 }}
      py={{ xs: 3, sm: 5 }}
      display="flex"
      flexDirection="column"
    >
      <Box>{ResultSection(errorResults, 'error')}</Box>
      <Box>{ResultSection(warningResults, 'warning')}</Box>
      <Box>{ResultSection(successResults, 'success')}</Box>
      <Box alignSelf="flex-end" pt={5}>
        <Button
          onClick={() => goBackToPackagesList()}
          variant="contained"
          color="primary"
          size="large"
          data-testid="bulk-cancellation-back-btn"
        >
          {t('list.bulkCancellation.results.button')}
        </Button>
      </Box>
    </Box>
  );
};

BulkCancellationResults.propTypes = {
  goBackToPackagesList: PropTypes.func.isRequired,
  packagesData: PropTypes.arrayOf(packageProps),
  resultData: PropTypes.shape({
    success: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        warning_message: PropTypes.string
      })
    ),
    failure: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        status: PropTypes.shape({
          code: PropTypes.number.isRequired,
          message: PropTypes.string.isRequired,
          details: PropTypes.arrayOf(PropTypes.string)
        })
      })
    )
  })
};

BulkCancellationResults.defaultProps = {
  packagesData: [],
  resultData: []
};

export default BulkCancellationResults;
