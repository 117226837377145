import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CreatePackage = ({ children }) => {
  const { t } = useTranslation('packages');
  const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Box
          alignItems={smUp ? 'center' : ''}
          display="flex"
          flexDirection={smUp ? 'row' : 'column'}
          justifyContent="space-between"
        >
          <Typography variant="h4" component="h1">
            <Box
              alignItems="center"
              component="strong"
              display="flex"
              fontWeight="fontWeightBold"
            >
              {t('create.title')}
            </Box>
          </Typography>
        </Box>
      </OneTemplateSummary>
      <OneTemplateContent>{children}</OneTemplateContent>
    </OneTemplate>
  );
};

CreatePackage.propTypes = {
  children: PropTypes.node.isRequired
};

export default CreatePackage;
