import { Box, Hidden, makeStyles, Avatar } from '@material-ui/core';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { KeyboardArrowDown, KeyboardArrowUp, Person } from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import UserProfileMenu from './user-profile-menu';
import { extractFirstInitials } from '../utils';

const useStyles = makeStyles(({ breakpoints, palette, typography }) => ({
  picture: {
    color: palette.grey[900],
    width: typography.pxToRem(40),
    height: typography.pxToRem(40),
    backgroundColor: palette.common.white,
    borderColor: palette.grey[500],
    borderStyle: 'solid',
    borderWidth: 'thin',
    fontSize: '14px'
  },
  profileButton: ({ isOutOfCoverage }) => ({
    color: palette.primary.contrastText,
    [breakpoints.down('xs')]: {
      borderColor: isOutOfCoverage
        ? palette.primary.main
        : palette.primary.contrastText,
      borderRadius: typography.pxToRem(50),
      paddingLeft: typography.pxToRem(16),
      paddingRight: typography.pxToRem(16),
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: palette.primary.contrastText,
        color: '#000',
        mixBlendMode: 'screen',
        transition: 'all 0.2s ease-in-out'
      }
    },
    [breakpoints.up('sm')]: {
      border: 'none',
      '&:hover, &.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: 'inherit',
        color: palette.primary.contrastText
      },
      '& .MuiTouchRipple-root': {
        display: 'none'
      }
    }
  })
}));

const UserProfileButton = ({ signUpFlow, hidePersonIcon, isOutOfCoverage }) => {
  const styles = useStyles({ isOutOfCoverage });
  const [isProfilePopoverOpen, setIsProfilePopoverOpen] = useState(false);
  const menuRef = useRef(null);
  const userProfileButtonColor =
    signUpFlow || isOutOfCoverage ? 'primary' : 'inherit';
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  const userName = authenticatedUser?.name;
  const avatarLetter = userName ? extractFirstInitials(userName) : undefined;

  return (
    <Box>
      <ToggleButton
        className={styles.profileButton}
        data-testid="profile-menu-btn-id"
        onChange={() => setIsProfilePopoverOpen(!isProfilePopoverOpen)}
        ref={menuRef}
        selected={isProfilePopoverOpen}
        size="small"
        value
        variant="outlined"
      >
        {avatarLetter && hidePersonIcon && (
          <Hidden xsDown>
            <Box>
              <Avatar
                className={styles.picture}
                data-testid="avatar-menu-btn-id"
              >
                <Box fontWeight="fontWeightBold" component="strong">
                  {avatarLetter}
                </Box>
              </Avatar>
            </Box>
          </Hidden>
        )}
        <Hidden smUp={hidePersonIcon}>
          <Person color={userProfileButtonColor} />
        </Hidden>
        <Hidden smUp={hidePersonIcon}>
          {isProfilePopoverOpen ? (
            <KeyboardArrowUp fontSize="small" color={userProfileButtonColor} />
          ) : (
            <KeyboardArrowDown
              fontSize="small"
              color={userProfileButtonColor}
            />
          )}
        </Hidden>
      </ToggleButton>
      <UserProfileMenu
        anchorElement={menuRef.current}
        handleClose={() => setIsProfilePopoverOpen(false)}
        handleOpen={() => setIsProfilePopoverOpen(true)}
        open={isProfilePopoverOpen}
      />
    </Box>
  );
};

UserProfileButton.propTypes = {
  signUpFlow: PropTypes.bool.isRequired,
  hidePersonIcon: PropTypes.bool.isRequired,
  isOutOfCoverage: PropTypes.bool
};

UserProfileButton.defaultProps = {
  isOutOfCoverage: false
};

export default UserProfileButton;
