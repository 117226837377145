import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme, Button, Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { routes } from '../constants';

const PackagesSelectedCounter = ({
  totalSelectedPackages,
  totalPackages,
  handleSelection,
  allFromMatchingSearchSelected
}) => {
  const { t } = useTranslation('unifiedPickup');
  const { push } = useHistory();
  const { companyId } = useParams();
  const { typography, spacing } = useTheme();
  const continueToPickupOrderSchedule = () => {
    push(routes.unifiedPickup.url(companyId));
  };

  const allPackagesSelected =
    allFromMatchingSearchSelected ||
    (totalSelectedPackages === totalPackages && totalPackages > 0);

  return (
    <Box data-testid="package-selection-counter" py={3.75} px={5}>
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" color="textPrimary">
            {t('packageSelection.packagesSelectedCounter.addedToTheSystem')}
          </Typography>
          <Typography variant="h5" color="textPrimary">
            {!totalPackages
              ? t('packageSelection.packagesSelectedCounter.counterZero')
              : t('packageSelection.packagesSelectedCounter.counter', {
                  count: totalPackages
                })}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          md={5}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: spacing(4.25)
          }}
        >
          <Box>
            <Typography variant="subtitle2" color="textPrimary">
              {t('packageSelection.packagesSelectedCounter.totalSelected')}
            </Typography>
            <Typography
              variant="h5"
              color="textPrimary"
              style={{ fontWeight: typography.fontWeightBold }}
            >
              {!totalSelectedPackages
                ? t(
                    'packageSelection.packagesSelectedCounter.totalSelectedCounterZero'
                  )
                : t('packageSelection.packagesSelectedCounter.counter', {
                    count: totalSelectedPackages
                  })}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            variant="outlined"
            color="primary"
            data-testid="clean-or-select-all-button"
            disabled={!totalPackages}
            onClick={() => {
              handleSelection({
                isChecked: !allPackagesSelected,
                allFromMatchingSearch: true
              });
            }}
            fullWidth
          >
            <Typography
              variant="body1"
              style={{ fontWeight: typography.fontWeightMedium }}
            >
              {allPackagesSelected
                ? t('packageSelection.cleanSelection.button')
                : t('packageSelection.selectAll.button')}
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            data-testid="continue-btn"
            fullWidth
            color="primary"
            onClick={continueToPickupOrderSchedule}
            disabled={!totalSelectedPackages}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: typography.fontWeightMedium }}
            >
              {t('packageSelection.continue.button')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

PackagesSelectedCounter.propTypes = {
  totalSelectedPackages: PropTypes.number,
  totalPackages: PropTypes.number,
  handleSelection: PropTypes.func.isRequired,
  allFromMatchingSearchSelected: PropTypes.bool
};

PackagesSelectedCounter.defaultProps = {
  totalSelectedPackages: 0,
  totalPackages: 0,
  allFromMatchingSearchSelected: false
};

export default PackagesSelectedCounter;
