import React, { useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box, Divider, Button } from '@material-ui/core';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import Toast from '@loggi/components/src/one/toast';
import firebase from 'firebase/app';
import 'firebase/messaging';

import REPORT_FIELDS, {
  DATES,
  PACKAGE_DIRECTION,
  RECIPIENT,
  STATUS,
  PACKAGE_INFORMATION,
  COMPANY_INFORMATION,
  SUB_FIELDS
} from '../constants';
import ReportDownloadSection from './report-download-section';
import {
  getReportPreferencesData,
  saveReportPreferencesDataOnLocalStorage
} from './report-download-storage';
import ReportDownloadHeader from './report-download-header';

import {
  DEFAULT_SEARCH_DAYS,
  FILTERS_NAMES,
  PACKAGE_DIRECTION_RETURN,
  PARAM_URL_RETURN
} from '../../../constants';
import { getToken } from '../../../../../firebase/notifications';
import packagesApi from '../../../../packages-api';
import getReportRangeDate from './utils';
import {
  openCommunicationChannelWithSW,
  firebaseListener,
  serviceWorkerListener,
  backgroundListener
} from './communicationChannelInit';
import { useRemoteConfig } from '../../../../../firebase';

const ReportDownloadDrawerContent = ({ handleReportDownloadRequest }) => {
  const messaging = firebase.messaging();
  const messageChannel = new MessageChannel();
  const { t } = useTranslation('packages');
  const { enqueueSnackbar } = useSnackbar();

  const { value: fsHierarchyFilter } = useRemoteConfig(
    'enable_company_cnpj_in_package_list'
  );
  const enableHierarchyFilter = fsHierarchyFilter === 'true';

  const { value: fsEnableBackgroundListener } = useRemoteConfig(
    'enable_package_report_background_listener'
  );
  const enableBackgroundListener = fsEnableBackgroundListener === 'true';

  const sendToastMessage = () => {
    const toastMessage = t(
      'details.actions.downloadReport.toastMessageSuccess'
    );

    enqueueSnackbar(toastMessage, {
      content: (key, message) => (
        <Toast id={key} message={message} type="success" />
      )
    });
  };

  firebaseListener(messaging, handleReportDownloadRequest, sendToastMessage);

  if (enableBackgroundListener) {
    backgroundListener(handleReportDownloadRequest, sendToastMessage);
  } else {
    serviceWorkerListener(
      messageChannel,
      handleReportDownloadRequest,
      sendToastMessage
    );
  }

  const queryParams = useQueryParams();
  const { companyId } = useParams();
  const reportPreferencesSaved = useMemo(() => getReportPreferencesData(), []);

  const getCategoryInitialData = category => {
    const categoryInitialData = [];

    for (let index = 0; index < REPORT_FIELDS[category].length; index += 1) {
      const field = REPORT_FIELDS[category][index];
      const savedField = reportPreferencesSaved?.[category]?.[index];

      categoryInitialData.push({
        label: t(field.label),
        value: field.value,
        selected:
          savedField?.value === field.value ? savedField.selected : false
      });
    }

    return categoryInitialData;
  };

  const recipientInitialData = getCategoryInitialData(RECIPIENT);

  const periodInitialData = getCategoryInitialData(DATES);

  const statusInitialData = getCategoryInitialData(STATUS);

  const directionInitialData = getCategoryInitialData(PACKAGE_DIRECTION);

  const informationInitialData = getCategoryInitialData(PACKAGE_INFORMATION);

  const companyInitialData = getCategoryInitialData(COMPANY_INFORMATION);

  const isThereAnyFieldSelected = section => {
    return section.some(field => field.selected);
  };

  const isDisabledAtInitialState = () => {
    return !(
      isThereAnyFieldSelected(recipientInitialData) ||
      isThereAnyFieldSelected(periodInitialData) ||
      isThereAnyFieldSelected(statusInitialData) ||
      isThereAnyFieldSelected(directionInitialData) ||
      isThereAnyFieldSelected(informationInitialData) ||
      isThereAnyFieldSelected(companyInitialData)
    );
  };

  const isDisableAtReduceState = reducedReportState => {
    const auxReducedReportState = reducedReportState;

    delete auxReducedReportState.changed;
    delete auxReducedReportState.disabledButton;

    return !Object.values(auxReducedReportState).some(section =>
      isThereAnyFieldSelected(section)
    );
  };

  const [reportState, setReportState] = useReducer(
    (currentReportState, newReportState) => {
      const reducedReportState = {
        ...currentReportState,
        ...newReportState
      };

      return {
        ...reducedReportState,
        disabledButton: isDisableAtReduceState(reducedReportState)
      };
    },
    {
      disabledButton: isDisabledAtInitialState(),
      changed: false,
      recipientSectionData: recipientInitialData,
      periodSectionData: periodInitialData,
      statusSectionData: statusInitialData,
      directionSectionData: directionInitialData,
      informationSectionData: informationInitialData,
      companySectionData: companyInitialData
    }
  );

  const onChangeRecipientSection = recipientSectionData => {
    setReportState({
      recipientSectionData,
      changed: !reportState.changed
    });
  };

  const onChangePeriodSection = periodSectionData => {
    setReportState({ periodSectionData, changed: !reportState.changed });
  };

  const onChangeStatusSection = statusSectionData => {
    setReportState({ statusSectionData, changed: !reportState.changed });
  };

  const onChangeDirectionSection = directionSectionData => {
    setReportState({
      directionSectionData,
      changed: !reportState.changed
    });
  };

  const onChangeInformationSection = informationSectionData => {
    setReportState({
      informationSectionData,
      changed: !reportState.changed
    });
  };

  const onChangeCompanySection = companySectionData => {
    setReportState({
      companySectionData,
      changed: !reportState.changed
    });
  };

  const getSelectedFieldsValue = section => {
    const selectedFields = section.filter(field => field.selected === true);
    return selectedFields.map(field => field.value);
  };

  const getSubfields = reportFieldMask => {
    let newReportFieldMask = reportFieldMask;
    Object.keys(SUB_FIELDS).forEach(key => {
      if (newReportFieldMask.includes(key)) {
        newReportFieldMask.splice(newReportFieldMask.indexOf(key), 1);
        newReportFieldMask = newReportFieldMask.concat(SUB_FIELDS[key]);
      }
    });
    return newReportFieldMask;
  };

  const getReportFieldMask = reportPreferences => {
    let reportFieldMask = [];

    Object.keys(reportPreferences).forEach(reportSection => {
      reportFieldMask = reportFieldMask.concat(
        getSelectedFieldsValue(reportPreferences[reportSection])
      );
      return reportFieldMask;
    });

    reportFieldMask = getSubfields(reportFieldMask);
    return reportFieldMask;
  };

  const sendDownloadReportRequest = reportPreferences => {
    handleReportDownloadRequest({ loading: true, error: false });

    const search = queryParams[FILTERS_NAMES.SEARCH];
    const shipperStatuses = queryParams[FILTERS_NAMES.STATUS]
      ?.split(',')
      .map(status => {
        return Number(status);
      });
    const directionParam = queryParams[FILTERS_NAMES.DIRECTION];

    const dateParam = queryParams[FILTERS_NAMES.DATE] || DEFAULT_SEARCH_DAYS;
    const startDateParam = queryParams[FILTERS_NAMES.START_DATE];
    const finalDateParam = queryParams[FILTERS_NAMES.FINAL_DATE];
    const integrator = queryParams[FILTERS_NAMES.INTEGRATOR]?.split(',');
    const searchCompanies = queryParams[FILTERS_NAMES.SEARCH_COMPANIES]
      ?.split(',')
      .map(company => {
        return Number(company);
      });

    const { startDateTime, endDateTime } = getReportRangeDate(
      dateParam,
      startDateParam,
      finalDateParam
    );
    const direction =
      directionParam === PARAM_URL_RETURN
        ? PACKAGE_DIRECTION_RETURN
        : undefined;
    const reportFieldMask = getReportFieldMask(reportPreferences);

    getToken().then(firebaseToken => {
      const requestParams = {
        report_filter_options: {
          report_start_date_time: startDateTime,
          report_end_date_time: endDateTime,
          shipper_status: shipperStatuses,
          query: search,
          package_direction: direction,
          integrator_id: integrator,
          search_companies: searchCompanies,
          field_mask: reportFieldMask
        },
        report_delivery_method: {
          firebase_token: firebaseToken
        }
      };

      packagesApi
        .report(companyId, requestParams)
        .then(() => {
          handleReportDownloadRequest({ loading: true, error: false });
        })
        .catch(() =>
          handleReportDownloadRequest({ loading: false, error: true })
        );
    });
  };

  const handleSaveReport = () => {
    const reportPreferences = {
      RECIPIENT: reportState.recipientSectionData,
      DATES: reportState.periodSectionData,
      STATUS: reportState.statusSectionData,
      PACKAGE_DIRECTION: reportState.directionSectionData,
      PACKAGE_INFORMATION: reportState.informationSectionData,
      COMPANY_INFORMATION: reportState.companySectionData
    };
    saveReportPreferencesDataOnLocalStorage(reportPreferences);

    openCommunicationChannelWithSW(messageChannel);

    sendDownloadReportRequest(reportPreferences);
  };

  return (
    <>
      <Box px={3}>
        <ReportDownloadHeader />
        <ReportDownloadSection
          header={t('customReport.categories.recipient.title')}
          data={reportState.recipientSectionData}
          onChange={onChangeRecipientSection}
        />
        <Divider />
        <ReportDownloadSection
          header={t('customReport.categories.dates.title')}
          data={reportState.periodSectionData}
          onChange={onChangePeriodSection}
        />
        <Divider />
        <ReportDownloadSection
          header={t('customReport.categories.status.title')}
          data={reportState.statusSectionData}
          onChange={onChangeStatusSection}
        />
        <Divider />
        <ReportDownloadSection
          header={t('customReport.categories.packageDirection.title')}
          data={reportState.directionSectionData}
          onChange={onChangeDirectionSection}
        />
        <Divider />
        <ReportDownloadSection
          header={t('customReport.categories.packageInformation.title')}
          data={reportState.informationSectionData}
          onChange={onChangeInformationSection}
        />
        {enableHierarchyFilter && (
          <>
            <Divider />
            <ReportDownloadSection
              header={t('customReport.categories.companyInformation.title')}
              data={reportState.companySectionData}
              onChange={onChangeCompanySection}
            />
          </>
        )}
      </Box>
      <Divider />
      <Box p={3}>
        <Button
          data-testid="download-button"
          color="primary"
          fullWidth
          disabled={reportState.disabledButton}
          variant="contained"
          onClick={() => handleSaveReport()}
        >
          {t('customReport.downloadButton')}
        </Button>
      </Box>
    </>
  );
};

ReportDownloadDrawerContent.propTypes = {
  handleReportDownloadRequest: PropTypes.func.isRequired
};

export default ReportDownloadDrawerContent;
