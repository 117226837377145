import { ReactComponent as ShaftlessArrowDown } from '@loggi/components/src/one/shared/images/shaftless-arrow-down.svg';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PICKUP_ORDER_DATE_FILTER_VALUES as DATE_VALUES,
  LIST_TYPE
} from '../../constants';
import { filterMenuProps } from './menu-styles-shapes';
import useFilter from '../../useFilter';

const FilterDate = ({ menuStyles }) => {
  const [filter = 0, setFilter] = useFilter('created', LIST_TYPE.SCHEDULINGS);
  const [created, setCreated] = useState(Number(filter));
  const { t } = useTranslation('scheduling');

  useEffect(() => setCreated(Number(filter)), [filter]);
  const renderDateFilterLabel = value => {
    if (value === 0) {
      return t('filters.dateLabels.allDays');
    }

    let valueAsString = String(value);

    if (valueAsString.length === 1) {
      valueAsString = `0${value}`;
    }

    return t('filters.dateLabels.lastDays', { days: valueAsString });
  };

  const FILTER_DATES = [
    {
      name: t('filters.dateLabels.lastDays', { days: '03' }),
      value: DATE_VALUES.LAST_3_DAYS
    },
    {
      name: t('filters.dateLabels.lastDays', { days: '07' }),
      value: DATE_VALUES.LAST_7_DAYS
    },
    {
      name: t('filters.dateLabels.lastDays', { days: '15' }),
      value: DATE_VALUES.LAST_15_DAYS
    },
    {
      name: t('filters.dateLabels.lastDays', { days: '30' }),
      value: DATE_VALUES.LAST_30_DAYS
    },
    {
      name: t('filters.dateLabels.allDays'),
      value: DATE_VALUES.ALL_DAYS
    }
  ];

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{t('filters.dateLabels.text')}</InputLabel>
      <Select
        id="filter-date-select"
        input={
          <OutlinedInput
            data-testid="filter-date-select"
            label={t('filters.dateLabels.text')}
          />
        }
        labelId="filter-date-select-label"
        onChange={event => {
          const value = Number(event.target.value);
          setFilter(value === DATE_VALUES.ALL_DAYS ? null : value);
        }}
        renderValue={renderDateFilterLabel}
        value={created}
        MenuProps={menuStyles}
        IconComponent={ShaftlessArrowDown}
      >
        {FILTER_DATES.map(({ value, name }) => (
          <MenuItem key={name} value={value}>
            <FormControlLabel
              label={name}
              checked={created === value}
              control={<Radio color="primary" />}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FilterDate.propTypes = PropTypes.shape(filterMenuProps).isRequired;

FilterDate.defaultProps = {
  menuStyles: null
};

export default FilterDate;
