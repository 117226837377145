import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

const RecipientSection = ({
  recipientName,
  recipientPhone,
  destinationAddress
}) => {
  const { t } = useTranslation('packages');

  return (
    <>
      <Box pb={3}>
        <Typography variant="h6">
          <strong>{t('edition.labels.recipientInfo')}</strong>
        </Typography>
      </Box>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} pb={4}>
        {recipientName && (
          <Box pr={7} pb={{ xs: 4, sm: 0 }}>
            <Typography variant="body2">
              <strong>{t('edition.labels.name')}</strong>
            </Typography>
            <Box pt={1}>
              <Typography variant="body1">{recipientName}</Typography>
            </Box>
          </Box>
        )}
        {recipientPhone && (
          <Box>
            <Typography variant="body2">
              <strong>{t('edition.labels.phone')}</strong>
            </Typography>
            <Box pt={1}>
              <Typography variant="body1">{recipientPhone}</Typography>
            </Box>
          </Box>
        )}
      </Box>
      {destinationAddress && (
        <Box>
          <Typography variant="body2">
            <strong>{t('edition.labels.address')}</strong>
          </Typography>
          <Box pt={1}>
            <Typography variant="body1">{destinationAddress}</Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

RecipientSection.propTypes = {
  recipientName: PropTypes.string.isRequired,
  recipientPhone: PropTypes.string.isRequired,
  destinationAddress: PropTypes.string.isRequired
};

export default RecipientSection;
