import { COMPANY_STATUS_PROTO_VALUES } from '../constants';
import useCompany from './use-company';

export const useCompanyStatus = () => {
  const companyData = useCompany();

  return companyData?.status ?? null;
};

/**
 * This hook uses the route location provided by react-router-dom
 * to get the Company presented on the URL. In addition, it retrieves
 * the status within the Company's data and returns a dict
 * to represent whether the status the Company current is.

 * @example
 *
 *    Company Status: { "status": 3 }
 *    useCheckCompanyStatus() returns => {"isDefaulter": true, "isOutOfCoverage": false}
 *
 * @returns {{"isDefaulter": boolean, "isOutOfCoverage": boolean }}
 */
export const useCheckCompanyStatus = () => {
  const companyStatus = useCompanyStatus();
  const statusCode = companyStatus?.status;
  return {
    isDefaulter: statusCode === COMPANY_STATUS_PROTO_VALUES.DEFAULTER,
    isOutOfCoverage: statusCode === COMPANY_STATUS_PROTO_VALUES.OUT_OF_COVERAGE
  };
};
