import Toast from '@loggi/components/src/one/toast';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import React, { useEffect, useCallback } from 'react';

import { useRemoteConfig } from '../../firebase';
import { ONE_DAY, REMOTE_CONFIG_TOAST_COOKIE } from './constants';

/**
 * Renders a <Toast /> if `app_general_notification` firebase remote config is set
 * @returns {null}
 */
const RemoteConfigToast = () => {
  let toast;
  const { value: appMessageJSON } = useRemoteConfig('app_general_notification');
  const { enqueueSnackbar } = useSnackbar();

  if (appMessageJSON === 'false') {
    Cookies.remove(REMOTE_CONFIG_TOAST_COOKIE);
  }

  try {
    toast = JSON.parse(appMessageJSON) ?? {};
  } catch {
    toast = {};
  }

  const hide = Boolean(Cookies.get(REMOTE_CONFIG_TOAST_COOKIE));
  const handleClose = useCallback((_e, reason) => {
    // notistack calls onClose method if the user clicks away.
    // we only want to set a cookie if the user
    // really clicked the "close" button
    if (reason === 'instructed') {
      Cookies.set(REMOTE_CONFIG_TOAST_COOKIE, true, { expires: ONE_DAY });
    }
  }, []);

  const renderSnackbarToast = useCallback(
    (key, message, type) => <Toast id={key} message={message} type={type} />,
    []
  );

  useEffect(() => {
    if (!hide && toast.message && toast.type) {
      enqueueSnackbar(toast.message, {
        content: (key, message) =>
          renderSnackbarToast(key, message, toast.type),
        onClose: handleClose,
        persist: true,
        preventDuplicate: true
      });
    }
  }, [toast, hide, handleClose, enqueueSnackbar, renderSnackbarToast]);

  return null;
};

export default RemoteConfigToast;
