import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Typography,
  Divider,
  ButtonBase,
  makeStyles
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';
import StatusIndicatorPackages from '@loggi/components/src/one/status-indicator';
import { redirectToHelpCenter } from '@loggi/components/src/one';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import usePackageDownloadLabel from '../../use-package-download-label.hook';
import { useFeature } from '../../../firebase';
import packagesRoutes from '../../packages-routes';
import { CORREIOS_TRACKING_URL } from '../constants';

const useStyles = makeStyles(({ spacing, palette }) => ({
  buttonBase: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: palette.primary.light,
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    paddingTop: spacing(2.5),
    paddingBottom: spacing(2.5),
    '&:hover': {
      backgroundColor: palette.secondary.light,
      cursor: 'pointer'
    }
  }
}));

const PackageOptionsDrawerContent = ({
  companyId,
  data,
  openCancelPackageDialog,
  openPackageEditionModalError,
  handleOpenHelpOption
}) => {
  const classes = useStyles();
  const { push } = useHistory();
  const { t } = useTranslation('packages');
  const { status, statusDescription } = data;
  const barcode = data.trackingKey;
  const useZendeskUrl = useFeature('enable_redirect_to_zendesk_help_center');
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const [loading, setLoading] = useState(false);

  /**
   * For the moment this code was written, Loggi One packages were integrated
   * sending barcode data similar to the tracking key. This can't be truthy in
   * the future, so it's important to keep an eye on future decisions at
   * Package Label API.
   */
  const downloadLabel = usePackageDownloadLabel(
    companyId,
    data.trackingKey,
    barcode
  );

  const useHelpDrawer = useFeature('enable_package_help_drawer');

  const openZendeskHelpCenter = async () => {
    setLoading(true);
    await redirectToHelpCenter(companyId, authenticatedUser, useZendeskUrl);
    setLoading(false);
  };

  const shouldDisplayHelpDrawer = useHelpDrawer
    ? handleOpenHelpOption
    : openZendeskHelpCenter;

  const shouldDisplayRedispatchOption = data.barcode;
  const shouldDisplayLabelDownloadOption =
    !data.isFinalStatus() && !shouldDisplayRedispatchOption;
  const shouldDisplayCancelOption = !data.isFinalStatus();

  const handleEditRecipientButton = () => {
    if (data.isFinalStatus() || data.isEnRouteStatus()) {
      openPackageEditionModalError();
    } else {
      push(packagesRoutes.edit.url(companyId, data.trackingKey));
    }
  };

  /* eslint no-void: ["error", { "allowAsStatement": true }] */

  return (
    <Box data-testid="package-options-drawer-content">
      <Box px={3} py={4}>
        <Box display="flex" alignItems="center" pb={2}>
          <Box pr={1}>
            <StatusIndicatorPackages status={status} noLabel />
          </Box>
          <Typography variant="h4">
            <strong>{status}</strong>
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" color="textSecondary">
            {statusDescription}
          </Typography>
        </Box>
      </Box>
      <>
        <Divider />
        <ButtonBase
          className={classes.buttonBase}
          onClick={handleEditRecipientButton}
          data-testid="edit-btn"
        >
          <Typography variant="subtitle2" color="textPrimary">
            {t('details.actions.edit.fullTitle')}
          </Typography>
          <ArrowForwardIosIcon color="primary" />
        </ButtonBase>
      </>
      {shouldDisplayCancelOption && (
        <>
          <Divider />
          <ButtonBase
            className={`stop-sending-option, ${classes.buttonBase}`}
            onClick={openCancelPackageDialog}
          >
            <Typography
              className="stop-sending-option"
              variant="subtitle2"
              color="textPrimary"
            >
              {t('details.actions.cancel.title')}
            </Typography>
            <ArrowForwardIosIcon color="primary" />
          </ButtonBase>
        </>
      )}
      {shouldDisplayLabelDownloadOption && (
        <>
          <Divider />
          <ButtonBase
            className={classes.buttonBase}
            onClick={downloadLabel}
            data-testid="print-btn"
          >
            <Typography variant="subtitle2" color="textPrimary">
              {t('details.actions.print')}
            </Typography>
            <ArrowForwardIosIcon color="primary" />
          </ButtonBase>
        </>
      )}
      {shouldDisplayRedispatchOption && (
        <>
          <Divider />
          <Box>
            <form action={CORREIOS_TRACKING_URL} method="post" target="_blank">
              <input type="hidden" name="objetos" value={data.barcode} />
              <ButtonBase
                className={classes.buttonBase}
                type="submit"
                data-testid="redispatch-btn"
              >
                <Typography variant="subtitle2" color="textPrimary">
                  {t('details.actions.redispatch')}
                </Typography>
                <ArrowForwardIosIcon color="primary" />
              </ButtonBase>
            </form>
          </Box>
        </>
      )}
      <Divider />
      <ButtonBase
        disabled={loading}
        className={classes.buttonBase}
        data-testid="help-center-btn"
        onClick={shouldDisplayHelpDrawer}
      >
        <Typography variant="subtitle2" color="textPrimary">
          {t('details.actions.help')}
        </Typography>
        <ArrowForwardIosIcon color="primary" />
      </ButtonBase>
      <Divider />
    </Box>
  );
};

PackageOptionsDrawerContent.propTypes = {
  companyId: PropTypes.string.isRequired,
  data: packageProps.isRequired,
  openCancelPackageDialog: PropTypes.func.isRequired,
  openPackageEditionModalError: PropTypes.func.isRequired,
  handleOpenHelpOption: PropTypes.func
};

PackageOptionsDrawerContent.defaultProps = {
  handleOpenHelpOption: null
};

export default PackageOptionsDrawerContent;
