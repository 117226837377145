import { colors } from '@loggi/mar';
import { getI18n } from 'react-i18next';

const PriceAgreementStatus = () => {
  const i18n = getI18n();
  const t = i18n.getFixedT(null, 'payments');

  return {
    AVAILABLE_FOR_ACTIVATION: {
      color: colors.blue[500],
      label: t('priceAgreements.status.availableForActivation')
    },
    ACTIVE: {
      color: colors.green[500],
      label: t('priceAgreements.status.active')
    },
    INACTIVE: {
      color: colors.smoke[500],
      label: t('priceAgreements.status.inactive')
    },
    PROCESSING_ACTIVATION: {
      color: colors.yellow[500],
      label: t('priceAgreements.status.processingActivation')
    }
  };
};

export default PriceAgreementStatus;
