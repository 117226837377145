import { TRANSPORT_TYPE_MAPPER } from '@loggi/components/src/one/constants';
import msk from 'msk';
import { CPF_MASK } from '../screens/details/utils/masks';

const generateReceiptReportFile = async (pickupReceipt, t) => {
  const XLSX = await import('xlsx');

  const transportTypeText = t(
    TRANSPORT_TYPE_MAPPER(pickupReceipt.transportType)
  );
  const documentMasked = msk(pickupReceipt.driverDocument, CPF_MASK);
  const vehicleInfoText = [transportTypeText, pickupReceipt.licensePlate]
    .filter(value => !!value)
    .join(' - ');

  const filteredPackagesData = pickupReceipt.packages.map(pkg => {
    const localEndTime = pickupReceipt.pickupEndTime
      ? new Date(pickupReceipt.pickupEndTime).toLocaleString()
      : '-';

    const jsonPkg = {};
    jsonPkg[t('receiptDownload.reportAttributes.collectedByField')] =
      pickupReceipt.driverName || '-';
    jsonPkg[t('receiptDownload.reportAttributes.CPFField')] =
      documentMasked || '-';
    jsonPkg[t('receiptDownload.reportAttributes.vehicleField')] =
      vehicleInfoText || '-';
    jsonPkg[
      t('receiptDownload.reportAttributes.collectedAtField')
    ] = localEndTime;
    jsonPkg[t('receiptDownload.reportAttributes.trackingKeyField')] =
      pkg.trackingKey || '-';
    return jsonPkg;
  });

  const packagesJson = XLSX.utils.json_to_sheet(filteredPackagesData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    wb,
    packagesJson,
    t('receiptDownload.sheetName')
  );

  const today = new Date();
  const formatedDate = today.toISOString().slice(0, 10);
  const miliseconds = String(today.getMilliseconds()).padStart(3, '0');
  const fileName = t('receiptDownload.fileName');

  XLSX.writeFile(wb, `${fileName}-${formatedDate}-${miliseconds}.xlsx`);
};

export default generateReceiptReportFile;
