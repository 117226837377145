import firebase from 'firebase/app';
import 'firebase/messaging';

/**
 * Notification permission
 * Using Firebase to request notification permission
 *
 *
 * Usage example:
 * const { requestPermission } = firebase
 * requestPermission()
 *
 * Notification permission types
 * https://developer.mozilla.org/en-US/docs/Web/API/Notification/permission
 */
export const requestPermission = async () => {
  const payload = {
    token: null,
    permission: Notification.permission,
    errors: []
  };

  try {
    // Init firebase messagging
    const messaging = firebase.messaging();
    // Request permission
    await messaging.requestPermission();
    payload.token = await messaging.getToken();
  } catch (error) {
    if (error.code && error.code === 'messaging/permission-blocked') {
      // Denied requests will be treated
      payload.errors.push(error);
    } else {
      // Other errors will be raise so we don't create a "bocao"
      throw error;
    }
  }

  // Current notification status
  payload.permission = Notification.permission;

  return payload;
};

export const getToken = async () => {
  let token = null;

  if (firebase.messaging.isSupported() && Notification) {
    if (Notification.permission === 'granted') {
      const messaging = firebase.messaging();
      token = await messaging.getToken();
    }
  }

  return token;
};

/**
 * Foreground Notification
 * Enable notifications when user is viewing loggi-one
 */
export const createNotification = payload => {
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: payload.notification.icon
  };

  if ('Notification' in window && Notification.permission === 'granted') {
    // If it's okay we create a notification
    const notification = new Notification(
      notificationTitle,
      notificationOptions
    );
    notification.onclick = event => {
      event.preventDefault(); // prevent the browser from focusing the Notification's tab
      if (payload.fcmOptions?.link) {
        window.open(payload.fcmOptions.link, '_blank');
      }
      notification.close();
    };
    return notification;
  }
  return null;
};

export const notificationListenner = () => {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    messaging.onMessage(createNotification);
  }
};

export default {};
