import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import camelCaseKeys from 'camelcase-keys';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import Toast from '@loggi/components/src/one/toast';

import paymentsApi from '../payments-api';
import LoadingComponent from '../loading';
import PriceAgreementsList from './price-agreements-list.component';
import PaymentsNavigation from '../navigation/navigation.component';
import PriceAgreementsEmpty from './price-agreements-empty.component';

const PriceAgreements = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('payments');
  const [priceAgreements, setPriceAgreements] = useState();
  const [isFetchFinished, setFetchFinished] = useState(false);
  const { companyId } = useParams();

  useEffect(() => {
    paymentsApi
      .getPriceAgreements(companyId)
      .then(priceAgreementsList => {
        setPriceAgreements(camelCaseKeys(priceAgreementsList, { deep: true }));
      })
      .catch(() => {
        enqueueSnackbar(t('priceAgreements.error.list'), {
          content: (key, message) => (
            <Toast id={key} message={message} type="error" />
          )
        });
      })
      .finally(() => setFetchFinished(true));
  }, [companyId, enqueueSnackbar, t]);

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" component="h1">
            <Box component="strong">{t('priceAgreements.title')}</Box>
          </Typography>
          <Box>
            <PaymentsNavigation
              toggleButtonLabel={t('priceAgreements.titleNavigation')}
            />
          </Box>
        </Box>
      </OneTemplateSummary>
      <OneTemplateContent disablePadding>
        {!isFetchFinished && <LoadingComponent />}
        {!priceAgreements || priceAgreements.length === 0 ? (
          <PriceAgreementsEmpty />
        ) : (
          <PriceAgreementsList
            priceAgreements={priceAgreements}
            isLoading={!isFetchFinished}
          />
        )}
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default PriceAgreements;
