import theme from '@loggi/mar';

const colorMap = {
  // Adicionado || Adicionado no sistema
  '1': {
    primary: theme.palette.primary.main,
    secondary: theme.palette.primary.light
  },
  // Cancelado
  '2': {
    primary: theme.palette.error.main,
    secondary: theme.palette.error.light
  },
  // Em processamento || Preparando para transferência
  '3': {
    primary: theme.palette.primary.main,
    secondary: theme.palette.primary.light
  },
  // Em trânsito || Preparando para entrega
  '4': {
    primary: theme.palette.primary.main,
    secondary: theme.palette.primary.light
  },
  // Entregue
  '5': {
    primary: theme.palette.success.main,
    secondary: theme.palette.success.light
  },
  // Com problema || Recusado pelo destinatário
  '6': {
    primary: theme.palette.error.main,
    secondary: theme.palette.error.light
  },
  // Em devolução || Devolução iniciada
  '7': {
    primary: theme.palette.warning.dark,
    secondary: theme.palette.warning.light
  },
  // Devolvido
  '8': {
    primary: theme.palette.success.main,
    secondary: theme.palette.success.light
  },
  // Extraviado
  '9': {
    primary: theme.palette.error.main,
    secondary: theme.palette.error.light
  },
  // Pronto para retirada || Retirar nos correios
  '10': {
    primary: theme.palette.success.main,
    secondary: theme.palette.success.light
  },
  // Saiu para entrega || Em rota
  '11': {
    primary: theme.palette.primary.main,
    secondary: theme.palette.primary.light
  },
  // Entrega não realizada || Imprevisto na entrega
  '12': {
    primary: theme.palette.warning.dark,
    secondary: theme.palette.warning.light
  },
  // Conferido
  '13': {
    primary: theme.palette.primary.main,
    secondary: theme.palette.primary.light
  },
  // Coletado
  '14': {
    primary: theme.palette.primary.main,
    secondary: theme.palette.primary.light
  },
  // Medido e pesado
  '15': {
    primary: theme.palette.primary.main,
    secondary: theme.palette.primary.light
  },
  // Saiu de uma base
  '16': {
    primary: theme.palette.primary.main,
    secondary: theme.palette.primary.light
  },
  // Chegou em uma base
  '17': {
    primary: theme.palette.primary.main,
    secondary: theme.palette.primary.light
  },
  // Destinatário ausente"
  '18': {
    primary: theme.palette.warning.dark,
    secondary: theme.palette.warning.light
  },
  // Avariado
  '19': {
    primary: theme.palette.error.main,
    secondary: theme.palette.error.light
  },
  // Pendência interna
  '20': {
    primary: theme.palette.warning.dark,
    secondary: theme.palette.warning.light
  },
  // Endereço errado
  '21': {
    primary: theme.palette.error.main,
    secondary: theme.palette.error.light
  },
  // Aguardando ação do remetente
  '22': {
    primary: theme.palette.error.main,
    secondary: theme.palette.error.light
  },
  // Roubado ou furtado
  '23': {
    primary: theme.palette.error.main,
    secondary: theme.palette.error.light
  },
  // Aguardando liberação fiscal
  '24': {
    primary: theme.palette.warning.dark,
    secondary: theme.palette.warning.light
  },
  // Liberado pela fiscalização
  '25': {
    primary: theme.palette.primary.main,
    secondary: theme.palette.primary.light
  },
  // Coleta pendente
  '26': {
    primary: theme.palette.primary.main,
    secondary: theme.palette.primary.light
  },
  // Retido pela fiscalização
  '29': {
    primary: theme.palette.warning.dark,
    secondary: theme.palette.warning.light
  }
};

export default colorMap;
