import { loggiWebApi } from '@loggi/authentication-lib';
import {
  ORDER_SCHEDULES_ENDPOINT,
  PICKUP_RECEIPTS_COMPANIES_ENDPOINT
} from '../../constants';

/**
 * Implements a Promise interface that fetches GetPickupReceipt API
 * and retrieves a pickup receipt details of a given scheduling order id.
 * @param {number} schedulingId
 * @param {number} companyId
 * @param {number} pickupReceiptId
 * @returns {Promise<>}
 */
const fetchPickupReceipt = (schedulingId, companyId, pickupReceiptId) => {
  return loggiWebApi
    .url(
      `/${PICKUP_RECEIPTS_COMPANIES_ENDPOINT}/${companyId}/${ORDER_SCHEDULES_ENDPOINT}/${schedulingId}/pickup-receipts/${pickupReceiptId}`
    )
    .get()
    .json();
};

export default fetchPickupReceipt;
