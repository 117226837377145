import {
  CnpjOrCpfField,
  NameField,
  PhoneField
} from '@loggi/components/src/one/formik-fields';
import { Box, Divider, Grid, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionTitle from '../../create-section-title';

const RecipientSection = () => {
  const { t } = useTranslation('packages');
  const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const gridSpacing = smUp ? 5 : 2;

  return (
    <>
      <Box display="block" mb={4}>
        <SectionTitle>{t('create.recipient.title')}</SectionTitle>
      </Box>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={6} md={6}>
          <NameField fieldName="name" />
        </Grid>
        <Grid item xs={6} md={3}>
          <PhoneField fieldName="phone" label={t('create.recipient.phone')} />
        </Grid>
        <Grid item xs={12} md={3}>
          <CnpjOrCpfField
            fieldName="cnpjOrCpf"
            label={t('create.recipient.cnpj')}
            shouldRenderRequiredAsterisk
          />
        </Grid>
      </Grid>
      <Box py={4}>
        <Divider />
      </Box>
    </>
  );
};

export default RecipientSection;
