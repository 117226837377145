import React, { useEffect } from 'react';
import {
  OneTemplateSummary,
  OneTemplate,
  OneTemplateContent
} from '@loggi/components';
import { useTheme, List, useMediaQuery, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import PackagesListSummary from './packages-list-summary.component';
import PackagesListItem from './packages-list-item.component';
import PickupReceiptFooter from '../../pickup-receipt-footer/pickup-receipt-footer.component';

const useStyles = makeStyles(({ breakpoints }) => ({
  borderNone: {
    [breakpoints.up('sm')]: {
      '& > :last-child ': {
        borderBottomWidth: 0
      }
    }
  }
}));

function PackagesList({ pickupReceipt }) {
  const { palette } = useTheme();
  const styles = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <OneTemplate>
      <OneTemplateSummary color={palette.success.dark}>
        <PackagesListSummary
          transportType={pickupReceipt.transportType}
          beepedPackagesQty={pickupReceipt.packages.length}
          driverName={pickupReceipt.driverName}
          licensePlate={pickupReceipt.licensePlate}
        />
      </OneTemplateSummary>
      <OneTemplateContent disablePadding disablePaper>
        <OneTemplateContent disablePadding>
          <List className={styles.borderNone} disablePadding>
            {pickupReceipt.packages.map(pck => (
              <PackagesListItem
                recipientName={pck.recipientName}
                trackingKey={pck.trackingKey}
                key={pck.trackingKey}
              />
            ))}
          </List>
          {!isDesktop && <PickupReceiptFooter pt={5} pb={5} />}
        </OneTemplateContent>
        {isDesktop && <PickupReceiptFooter pt={5} pb={5} />}
      </OneTemplateContent>
    </OneTemplate>
  );
}

const packagePropTypes = PropTypes.shape({
  trackingKey: PropTypes.string,
  recipientName: PropTypes.string
}).isRequired;

PackagesList.propTypes = {
  pickupReceipt: PropTypes.shape({
    driverName: PropTypes.string,
    transportType: PropTypes.string,
    licensePlate: PropTypes.string,
    packages: PropTypes.arrayOf(packagePropTypes)
  }).isRequired
};

export default PackagesList;
