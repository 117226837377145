import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
  styled
} from '@material-ui/core';
import { Form, useFormikContext } from 'formik';
import { colors } from '@loggi/mar';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircle, Edit } from '@material-ui/icons';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { useTranslation } from 'react-i18next';
import {
  useCheckCompanyStatus,
  usePickupsUserPermission
} from '@loggi/components/src/one/hooks';
import palette from '@loggi/mar/src/palette';
import { CnpjOrCpfField } from '@loggi/components/src/one/formik-fields';
import { useFeature } from '@loggi/components/src/one/remote-config';
import FormikStorageWatcher from './formik-storage-watcher.component';
import AddressSection from './address-section.component';
import OriginSection from './origin-section.component';
import DateSection from './date-section.component';
import { routes, NOT_RECURRING } from './constants';
import getSelectedPackages from './package-selection/get-selected-packages.component';

const useStyles = makeStyles(() => ({
  buttonWrapperIsDisabled: {
    '& button:disabled': {
      color: palette.common.white,
      backgroundColor: colors.smoke[500]
    }
  }
}));

const PackageSelectionButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  border: '1px solid',
  borderColor: theme.palette.grey[400],
  color: theme.palette.primary.main
}));

const UnifiedPickupFormFields = ({
  isValid,
  isLoading,
  pageTitle,
  submitLabelButton
}) => {
  const styles = useStyles();
  const { typography } = useTheme();
  const {
    state: { isImpersonation }
  } = useAmplifyAuth();
  const { t } = useTranslation('unifiedPickup');
  const { isDefaulter } = useCheckCompanyStatus();
  const isPickupsPermission = usePickupsUserPermission();
  const { push } = useHistory();
  const { companyId } = useParams();
  const { values } = useFormikContext();
  const selectedPackagesCount = getSelectedPackages().size;
  const buttonAddPackageEnabledForCompanyId = useFeature(
    'enable_add_package_button_by_company_id'
  );

  const redirectToAddPackagePage = () => {
    push(routes.addPackage.url(companyId));
  };

  const packageSelectionButtonText = (text, icon) => (
    <>
      {text}
      <Box pl={1.25} display="flex">
        {icon}
      </Box>
    </>
  );

  return (
    <Form data-testid="pickup-form">
      <FormikStorageWatcher />
      <Box mb={4}>
        <Typography
          variant="h4"
          component="h4"
          style={{ fontWeight: typography.fontWeightMedium }}
        >
          {pageTitle}
        </Typography>
      </Box>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
        <Box flex="1" mr={{ xs: 0, sm: 4 }}>
          <Box pb={{ xs: 2.5 }} data-testid="address-section">
            <AddressSection />
          </Box>
          {isImpersonation && (
            <Box pb={{ xs: 2.5 }} mt={3}>
              <CnpjOrCpfField fieldName="cnpjOrCpf" />
            </Box>
          )}
          <Box mt={3} data-testid="origin-section">
            <OriginSection />
          </Box>
        </Box>
        <Box flex="1" ml={{ xs: 0, sm: 4 }}>
          <Box data-testid="date-section">
            <DateSection />
          </Box>
          {values.pickupIsRecurring === NOT_RECURRING &&
            buttonAddPackageEnabledForCompanyId && (
              <Box py={4}>
                <PackageSelectionButton
                  fullWidth
                  onClick={redirectToAddPackagePage}
                  data-testid="add-package-button"
                  style={{
                    backgroundColor:
                      selectedPackagesCount > 0
                        ? palette.secondary.light
                        : palette.background.default
                  }}
                >
                  {selectedPackagesCount > 0 &&
                    packageSelectionButtonText(
                      t('addPackageButton.packagesSelected', {
                        count: selectedPackagesCount
                      }),
                      <Edit fontSize="small" />
                    )}
                  {selectedPackagesCount === 0 &&
                    packageSelectionButtonText(
                      t('addPackageButton.default'),
                      <AddCircle />
                    )}
                </PackageSelectionButton>
              </Box>
            )}
          <Box
            position="relative"
            className={`${
              !isValid || isLoading ? styles.buttonWrapperIsDisabled : ''
            }`}
          >
            <Button
              color="primary"
              disabled={
                !isValid || isLoading || isDefaulter || !isPickupsPermission
              }
              data-testid="pickupForm-actionButton"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {submitLabelButton}
            </Button>
            {isLoading && (
              <CircularProgress
                style={{
                  color: palette.common.white,
                  left: '50%',
                  marginLeft: -12,
                  marginTop: -12,
                  position: 'absolute',
                  top: '50%'
                }}
                data-testid="loading"
                size={24}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Form>
  );
};

UnifiedPickupFormFields.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  pageTitle: PropTypes.string.isRequired,
  submitLabelButton: PropTypes.string.isRequired
};
UnifiedPickupFormFields.defaultProps = {
  isLoading: false
};

export default UnifiedPickupFormFields;
