import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, withStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import PropTypes from 'prop-types';

const CustomIconButton = withStyles(({ spacing }) => ({
  root: {
    border: '1px solid',
    height: spacing(5),
    padding: spacing(1),
    width: spacing(5)
  }
}))(IconButton);

const BackButton = ({ backToUrl }) => {
  const history = useHistory();
  return (
    <CustomIconButton color="inherit" onClick={() => history.push(backToUrl)}>
      <ArrowBack data-testid="onBackIconButton" />
    </CustomIconButton>
  );
};

BackButton.propTypes = {
  backToUrl: PropTypes.string.isRequired
};

export default BackButton;
