import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Button, Link, makeStyles } from '@material-ui/core';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { useFeature } from '@loggi/components/src/one/remote-config';
import { redirectToHelpCenter } from '@loggi/components/src/one';
import PropTypes from 'prop-types';

const useStyles = makeStyles(({ typography, spacing, palette }) => ({
  mobileHelpLink: {
    ...typography.h6,
    padding: spacing(2, 4),
    width: '100%',
    borderRadius: '0',
    display: 'flex',
    justifyContent: 'start',
    '&:hover': {
      backgroundColor: palette.secondary.light
    }
  }
}));

const HelpCenterLink = ({ isMobile, isOutOfCoverage, handleClickOpen }) => {
  const styles = useStyles();
  const { t } = useTranslation('components');
  const useZendeskUrl = useFeature('enable_redirect_to_zendesk_help_center');
  const useHelpDrawer = useFeature('enable_header_help_drawer');
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const match = useRouteMatch('/empresas/:companyId');
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);

  const companyId = match?.params?.companyId;
  const companyIdFromRoute = companyId ? parseInt(companyId, 10) : null;
  const signInScreens =
    pathname.includes('cadastro') || pathname.includes('login');

  const openZendeskHelpCenter = () => {
    setLoading(true);
    redirectToHelpCenter(
      companyIdFromRoute,
      authenticatedUser,
      useZendeskUrl
    ).finally(() => {
      setLoading(false);
    });
  };

  const shouldDisplayHelpDrawer = useHelpDrawer
    ? handleClickOpen
    : openZendeskHelpCenter;

  return (
    <Button
      color={signInScreens || isOutOfCoverage ? 'primary' : 'inherit'}
      component={Link}
      disabled={loading}
      data-testid="help-center-btn"
      onClick={shouldDisplayHelpDrawer}
      target="_blank"
      underline="none"
      size="small"
      className={isMobile ? styles.mobileHelpLink : ''}
    >
      {t('header.help')}
    </Button>
  );
};

HelpCenterLink.propTypes = {
  isMobile: PropTypes.bool,
  isOutOfCoverage: PropTypes.bool,
  handleClickOpen: PropTypes.func
};

HelpCenterLink.defaultProps = {
  isMobile: false,
  isOutOfCoverage: false,
  handleClickOpen: () => {}
};

export default HelpCenterLink;
