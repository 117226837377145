import {
  Box,
  Drawer,
  IconButton,
  Typography,
  withStyles
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import packagesRoutes from '../../packages-routes';

const CustomDrawer = withStyles(({ breakpoints, spacing }) => ({
  root: {
    left: ['auto', '!important']
  },
  paper: {
    [breakpoints.up('sm')]: {
      maxWidth: spacing(58),
      minWidth: spacing(58)
    },
    width: '100%'
  }
}))(Drawer);

const ImportInvoiceDrawer = () => {
  const { push } = useHistory();
  const { companyId } = useParams();
  const { t } = useTranslation('packages');
  const [open, setOpen] = useState(false);

  const closeDrawer = useCallback(
    () => push(packagesRoutes.importInvoice.url(companyId)),
    [companyId, push]
  );

  useEffect(() => setOpen(true), []);

  return (
    <CustomDrawer
      anchor="right"
      elevation={24}
      ModalProps={{
        hideBackdrop: true
      }}
      onClose={() => setOpen(false)}
      open={open}
      SlideProps={{ onExited: closeDrawer }}
    >
      <Box py={{ xs: 3, sm: 4 }}>
        <Box px={{ xs: 3 }}>
          <IconButton
            data-testid="close-drawer-btn"
            edge="start"
            onClick={() => setOpen(false)}
          >
            <ArrowBack color="primary" />
          </IconButton>
        </Box>
        <Box px={{ xs: 3 }}>
          <Typography variant="h6">
            <Box pt={2} pb={1} fontWeight="fontWeightBold">
              {t('create.invoiceImport.instructions.title')}
            </Box>
          </Typography>
          <Typography>
            <Box pt={2} pb={1}>
              {t('create.invoiceImport.instructions.textFirstLine')}
            </Box>
          </Typography>
          <Typography>
            <Box pt={2} pb={1}>
              {t('create.invoiceImport.instructions.textSecondLine')}
            </Box>
          </Typography>
          <Typography>
            <Box pt={2} pb={1}>
              {t('create.invoiceImport.instructions.textThirdyLine')}
            </Box>
          </Typography>
          <Typography>
            <Box pt={2} pb={1}>
              {t('create.invoiceImport.instructions.textFourthLine')}
            </Box>
          </Typography>
        </Box>
      </Box>
    </CustomDrawer>
  );
};

export default ImportInvoiceDrawer;
