import * as Yup from 'yup';
import React, { useContext, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Select,
  useTheme
} from '@material-ui/core';
import SignupCompanyTemplate from '@loggi/components/src/one/sign-up-company';
import { PhoneField } from '@loggi/components/src/one/formik-fields';
import { MARKETING_UTMS_LOCAL_STORAGE_KEY } from '@loggi/authentication/src/screens/signup/constants';
import { UPDATE_COMPANY_PICKUP_ROUTE } from '../routes/constants';
import SignupCompanyContext from '../sign-up-company-context';
import GoBackButton from '../shared/go-back-button.component';
import { createCompany } from './utils';
import { useButtonLoadingStyle } from './styles';
import { COMPANY_CONTACT_PROTO_VALUES } from './constants';

const CompanyContactForm = () => {
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const buttonLoadingStyle = useButtonLoadingStyle();
  const { spacing } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const { push } = useHistory();
  const location = useLocation();
  const { t } = useTranslation('signupCompanyForm');
  const {
    setCompanyId,
    signUpCompanyStepValues,
    updateCompanyContactStepValues,
    updateCompanyPickupStepValues
  } = useContext(SignupCompanyContext);
  const initialValues = useMemo(
    () => updateCompanyContactStepValues,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const {
    FAVORED_CONTACT_METHOD,
    FAVORED_CONTACT_TIME
  } = COMPANY_CONTACT_PROTO_VALUES;

  const MENU_PROPS = {
    PaperProps: {
      style: {
        marginTop: spacing(1),
        maxWidth: spacing(10)
      }
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  };

  const CONTACT_SELECTS = [
    {
      id: 'favoredContactTime',
      inputLabel: t('companyContactForm.favoredContactTime.inputLabel'),
      options: [
        {
          label: t('companyContactForm.favoredContactTime.options.morning'),
          value: FAVORED_CONTACT_TIME.MANHÃ
        },
        {
          label: t('companyContactForm.favoredContactTime.options.afternoon'),
          value: FAVORED_CONTACT_TIME.TARDE
        }
      ]
    },
    {
      id: 'favoredContactMethod',
      inputLabel: t('companyContactForm.favoredContactMethod.inputLabel'),
      options: [
        {
          label: t('companyContactForm.favoredContactMethod.options.email'),
          value: FAVORED_CONTACT_METHOD.EMAIL
        },
        {
          label: t('companyContactForm.favoredContactMethod.options.phone'),
          value: FAVORED_CONTACT_METHOD.PHONE
        },
        {
          label: t('companyContactForm.favoredContactMethod.options.whatsapp'),
          value: FAVORED_CONTACT_METHOD.WHATSAPP
        }
      ]
    }
  ];

  const companyContactFormSchema = Yup.object().shape({
    favoredContactMethod: Yup.number().required(
      t('companyContactForm.validation.favoredContactMethodRequired')
    ),
    favoredContactTime: Yup.number().required(
      t('companyContactForm.validation.favoredContactTimeRequired')
    )
  });

  const stopLoading = () => {
    setLoading(false);
  };

  const showFinishedScreen = () => setFinished(true);

  const _onSubmit = async values => {
    setLoading(true);
    const { phone, favoredContactTime, favoredContactMethod } = values;
    const {
      cnpj,
      segment,
      sharedName,
      isNoL4BStatus
    } = signUpCompanyStepValues;

    const {
      averageTicket,
      estimatedMonthlyPackages,
      postalCode,
      isOutOfCoverage: isOutOfCoverageStatus,
      integrators
    } = updateCompanyPickupStepValues;

    const utms =
      JSON.parse(localStorage.getItem(MARKETING_UTMS_LOCAL_STORAGE_KEY)) || {};

    const formattedPhone = phone.replace(/\W/g, '');
    const signupCompanyPayload = {
      cnpj,
      landline_1: formattedPhone,
      landline_2: formattedPhone,
      shared_name: sharedName,
      company_configuration_json: {
        segment,
        average_ticket: averageTicket,
        estimated_monthly_packages: estimatedMonthlyPackages,
        postal_code: postalCode,
        favored_contact_time: favoredContactTime,
        favored_contact_method: favoredContactMethod,
        owner_email: authenticatedUser.email,
        is_out_of_coverage_status: isOutOfCoverageStatus,
        is_no_l4b_status: isNoL4BStatus,
        ...utms
      }
    };
    if (integrators.length) {
      signupCompanyPayload.company_configuration_json.integrators = integrators;
    }
    await createCompany(
      t,
      { payload: signupCompanyPayload, generatePricingTable: false },
      setCompanyId,
      enqueueSnackbar,
      push,
      stopLoading,
      showFinishedScreen
    );
  };

  const redirectToPreviousStep = () => {
    push(UPDATE_COMPANY_PICKUP_ROUTE, location?.state);
  };

  const {
    isOutOfCoverage,
    estimatedMonthlyPackages: estimatedMonthlyPackagesNumber
  } = updateCompanyPickupStepValues;

  const showOutOfCoverageMessage =
    isOutOfCoverage && Number(estimatedMonthlyPackagesNumber) !== 2000;

  const formTitles = t(
    `companyContactForm.titles.${
      showOutOfCoverageMessage ? 'outOfCoverage' : 'contact'
    }`,
    { returnObjects: true }
  );
  const formSubtitles = t(
    `companyContactForm.subtitles.${
      showOutOfCoverageMessage ? 'outOfCoverage' : 'contact'
    }`,
    { returnObjects: true }
  );

  return (
    <SignupCompanyTemplate
      formTitles={
        finished
          ? t('finishedForm.titles', { returnObjects: true })
          : formTitles
      }
      formSubtitles={
        finished
          ? t('finishedForm.subtitles', { returnObjects: true })
          : formSubtitles
      }
      outOfCoverage={showOutOfCoverageMessage}
      finishedSignUp={finished}
    >
      {finished ? (
        <></>
      ) : (
        <>
          <Formik
            validateOnMount
            initialValues={initialValues}
            validationSchema={companyContactFormSchema}
            onSubmit={_onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <PhoneField
                  fieldName="phone"
                  label={t('companyContactForm.phone.inputLabel')}
                />
                {CONTACT_SELECTS.map(({ id, inputLabel, options }) => (
                  <FormControl
                    fullWidth
                    key={id}
                    style={{ marginTop: spacing(4) }}
                    variant="outlined"
                  >
                    <InputLabel>{inputLabel}</InputLabel>
                    <Select
                      error={Boolean(touched[id] && errors[id])}
                      id={id}
                      name={id}
                      input={<OutlinedInput label={inputLabel} />}
                      MenuProps={MENU_PROPS}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values[id] || ''}
                    >
                      {options.map(({ label, value }) => (
                        <MenuItem key={label} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>
                      {touched[id] && errors[id]}
                    </FormHelperText>
                  </FormControl>
                ))}
                <Box pt={4} width="100%">
                  <Button
                    data-testid="submitButton"
                    disabled={loading || !isValid}
                    color="primary"
                    fullWidth
                    variant="contained"
                    type="submit"
                  >
                    {!loading && t('button.text')}
                    {loading && (
                      <CircularProgress
                        data-testid="company-contact-form-loading"
                        size={26}
                        className={buttonLoadingStyle.progress}
                      />
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <GoBackButton onClick={redirectToPreviousStep} />
        </>
      )}
    </SignupCompanyTemplate>
  );
};

export default CompanyContactForm;
