import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';

const AboutCompanyTemplate = ({ title, sections }) => {
  return (
    <Box mt={3}>
      <Box mb={3}>
        <Typography variant="h6">
          <strong>{title}</strong>
        </Typography>
      </Box>
      <Box display="flex">
        {sections.map(section => (
          <Box key={section.id}>
            {section.value && (
              <>
                <Box mr={8} mb={1}>
                  <Typography>
                    <strong>{section.subtitle}</strong>
                  </Typography>
                </Box>
                <Box mr={8}>
                  <Typography>{section.value}</Typography>
                </Box>
              </>
            )}
          </Box>
        ))}
      </Box>
      <Box mt={4}>
        <Divider />
      </Box>
    </Box>
  );
};

AboutCompanyTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      value: PropTypes.isRequired
    })
  ).isRequired
};

export default AboutCompanyTemplate;
