import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const PackageDetailsActionRequiredAlert = ({
  show,
  packageIssued,
  reasonLabel,
  reasonDescription
}) => {
  return (
    <>
      {show && (
        <Box pb={5.5}>
          <Alert
            data-testid="action-required-alert"
            severity={packageIssued ? 'error' : 'success'}
            color={packageIssued ? 'error' : 'success'}
          >
            <AlertTitle>{reasonLabel}</AlertTitle>
            {reasonDescription}
          </Alert>
        </Box>
      )}
    </>
  );
};

PackageDetailsActionRequiredAlert.propTypes = {
  show: PropTypes.bool,
  packageIssued: PropTypes.bool.isRequired,
  reasonLabel: PropTypes.string.isRequired,
  reasonDescription: PropTypes.string.isRequired
};

PackageDetailsActionRequiredAlert.defaultProps = {
  show: false
};

export default PackageDetailsActionRequiredAlert;
