import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import { useFeature } from '@loggi/components/src/one/remote-config';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import { FILTERS_NAMES } from '../../constants';

const RETURN_DIRECTION_VALUE = 'devolucao';

const useStyles = makeStyles(({ typography, palette }) => ({
  appBar: {
    backgroundColor: 'white',
    color: 'inherit',
    boxShadow: 'none'
  },
  tab: {
    color: palette.grey[900],
    fontSize: typography.pxToRem(16),
    fontWeight: typography.fontWeightRegular,
    '&.Mui-selected': {
      fontWeight: typography.fontWeightMedium
    }
  }
}));

const StyledTabs = withStyles(theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginBottom: theme.typography.pxToRem(2),
    '& > span': {
      maxWidth: theme.typography.pxToRem(56),
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.typography.pxToRem(40),
      height: theme.typography.pxToRem(4)
    }
  }
}))(props => (
  <Tabs
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
));

/**
 * PackagesListTabs
 * @componentType presentational
 * @param children
 * @returns {*}
 * @constructor
 */
const PackagesListTabs = ({ children, setChecked, onChange }) => {
  const classes = useStyles();
  const initialState = useQueryParams().direcao ? RETURN_DIRECTION_VALUE : 0;
  const [value, setValue] = React.useState(initialState);
  const { t } = useTranslation('packages');
  const enablePackageListTabs = useFeature('enable_package_list_tabs');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const newFilter = Object();
    newFilter[FILTERS_NAMES.DIRECTION] = newValue;
    onChange(newFilter);
    setChecked([]);
  };

  return (
    <>
      {enablePackageListTabs ? (
        <AppBar
          data-testid="packageListTabs"
          position="static"
          className={classes.appBar}
        >
          <StyledTabs
            className={classes.root}
            value={value}
            onChange={handleChange}
          >
            <Tab
              disableRipple
              className={classes.tab}
              label={t('list.direction.all')}
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={t('list.direction.return')}
              value={RETURN_DIRECTION_VALUE}
            />
          </StyledTabs>
          <Box>{children}</Box>
        </AppBar>
      ) : (
        <Box>{children}</Box>
      )}
    </>
  );
};

PackagesListTabs.propTypes = {
  children: PropTypes.node.isRequired,
  setChecked: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PackagesListTabs;
