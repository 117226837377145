import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { Box, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SCHEDULING_STATUSES } from '../../../scheduling/constants';
import schedulingApi from '../../scheduling-api';
import usePackages from '../../usePackages';
import SchedulingDetailsPackagesListWrapper from '../scheduling-details-packages-list-wrapper';
import SchedulingDetailsPackagesSummary from '../scheduling-details-packages-summary';

/**
 * This is the packages scheduling details screen, it's responsible for getting the data
 * from API and then passing to it's children components.
 * It receives no params, it renders itself without external dependencies.
 * @componentType container
 * @returns {*}
 */
const SchedulingDetailsPackages = () => {
  const { palette } = useTheme();
  const { id, companyId } = useParams();
  const [pickupOrderData, setPickupOrderData] = useState();

  useEffect(() => {
    schedulingApi.get(id, companyId).then(result => setPickupOrderData(result));
  }, [id, companyId]);

  const orderStatus = pickupOrderData?.status;

  const [packagesData, itemsCount, isLoading, error] = usePackages(
    id,
    companyId
  );

  const backgroundMap = {
    [SCHEDULING_STATUSES.CANCELLED]: palette.error.dark,
    [SCHEDULING_STATUSES.DISPATCHED]: palette.success.dark,
    [SCHEDULING_STATUSES.PARTIALLY_DISPATCHED]: palette.success.dark
  };

  return (
    <OneTemplate>
      <OneTemplateSummary color={backgroundMap[orderStatus]}>
        <SchedulingDetailsPackagesSummary id={id} packagesAmount={itemsCount} />
      </OneTemplateSummary>
      <OneTemplateContent disablePadding>
        <Box pt={1}>
          <SchedulingDetailsPackagesListWrapper
            count={itemsCount}
            data={packagesData}
            isLoading={isLoading}
            error={error}
          />
        </Box>
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default SchedulingDetailsPackages;
