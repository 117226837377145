import inviteUserForm from './invite-user.json';
import listUsers from './list-users.json';
import revokeUser from './revoke-user.json';
import updateUser from './update-user.json';

export default {
  listUsers,
  inviteUserForm,
  revokeUser,
  updateUser
};
