import { loggiWebApi } from '@loggi/authentication-lib';
import * as Sentry from '@sentry/react';
import { getI18n } from 'react-i18next';

export class RecurringPickupShowWarningNoPackagesToPickup extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'RecurringPickupShowWarningNoPackagesToPickup';
  }
}

const showWarningNoPackagesToPickupToday = companyId => {
  const t = getI18n().getFixedT(null, 'details');
  return loggiWebApi
    .url(
      `/one/api/pickups/v1/companies/${companyId}/there-are-no-packages-to-pickup/`
    )
    .accept('application/json')
    .get()
    .json()
    .catch(error => {
      const thrownError = new RecurringPickupShowWarningNoPackagesToPickup(
        t('requests.getSchedulingPickups.generic')
      );
      Sentry.captureException(thrownError, {
        contexts: { 'original error': error }
      });
      throw thrownError;
    });
};

export default showWarningNoPackagesToPickupToday;
