import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CachedIconOutlined from '@material-ui/icons/CachedOutlined';

const RecurringPickupHelp = () => {
  const { t } = useTranslation('scheduling');

  return (
    <>
      <Box display="flex">
        <Box display="flex" alignItems="center">
          <CachedIconOutlined fontSize="large" />
        </Box>
        <Box pl={2}>
          <Typography variant="subtitle1" gutterBottom>
            <Box style={{ fontWeight: '600' }}>{t('index.helpTitle')}</Box>
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {t('index.helpDescription')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default RecurringPickupHelp;
