import { useCallback } from 'react';

/**
 * useDownloadCallback: This hook is responsible for requesting and downloading
 * a Package PDF label, though our authenticate API, and then mimicking the download
 * action with the mimicDownloadClick method, as if the user had just clicked on
 * an anchor with a direct file URL.
 * @returns {(...args: any[]) => any} - it returns a function that trigger the API request
 * downloading the blob file, and then calls the mimicDownloadClick. On sub-sequential
 * calls, it uses the blobUrl already saved on the local state instead of calling
 * again the API.
 */
const useDownloadCallback = () => {
  const mimicDownloadClick = useCallback((url, fileName) => {
    const a = document.createElement('a');
    a.style = 'display: none';
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, []);

  return useCallback(
    (blobUrl = '', fileName) => {
      if (blobUrl.length > 0) mimicDownloadClick(blobUrl, fileName);
    },
    [mimicDownloadClick]
  );
};

export default useDownloadCallback;
