import { Box, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import UserListItem from './user-list-item';

const UsersListContainer = ({
  loggedUserEmail,
  updateUsersListCallback,
  users
}) => {
  return (
    <Box mt={2} pt={{ sm: 3 }}>
      {users.map(user => {
        return (
          <div key={user.id}>
            <UserListItem
              accountId={user.accountId}
              accountRole={user.accountRole}
              email={user.email}
              loggedUserEmail={loggedUserEmail}
              name={user.fullName}
              updateUsersListCallback={updateUsersListCallback}
              userId={user.id}
              userStatus={user.userStatus}
            />
            <Divider />
          </div>
        );
      })}
    </Box>
  );
};

UsersListContainer.propTypes = {
  loggedUserEmail: PropTypes.string.isRequired,
  updateUsersListCallback: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string.isRequired,
      accountRole: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      userStatus: PropTypes.number.isRequired
    })
  ).isRequired
};

export default UsersListContainer;
