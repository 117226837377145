import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {
  FINALIZER_STATUS_ALERT,
  DELAYED_WITHOUT_MOVEMENT_ALERT,
  DELAYED_WITH_MOVEMENT_RETURN_ALERT,
  DELAYED_WITH_MOVEMENT_DELIVERY_ALERT,
  WITH_MOVEMENT_DELIVERY_ALERT,
  WITH_MOVEMENT_RETURN_ALERT
} from './alerts-names';

const MovementDrawerAlert = ({ alertCase, daysCount }) => {
  const { t } = useTranslation('packages');

  let alertTitle = '';
  let alertBody = '';
  let alertSeverity = 'info';

  switch (alertCase) {
    case FINALIZER_STATUS_ALERT:
      alertTitle = t('movementDrawer.actionAlerts.finalStatus.title');
      alertBody = t('movementDrawer.actionAlerts.finalStatus.text');
      break;
    case DELAYED_WITH_MOVEMENT_RETURN_ALERT:
      alertTitle = t(
        'movementDrawer.actionAlerts.delayedWithMovementReturnAlert.title'
      );
      alertBody = t(
        'movementDrawer.actionAlerts.delayedWithMovementReturnAlert.text'
      );
      break;
    case DELAYED_WITH_MOVEMENT_DELIVERY_ALERT:
      alertTitle = t(
        'movementDrawer.actionAlerts.delayedWithMovementDeliveryAlert.title'
      );
      alertBody = t(
        'movementDrawer.actionAlerts.delayedWithMovementDeliveryAlert.text'
      );
      break;
    case WITH_MOVEMENT_DELIVERY_ALERT:
      alertTitle = t(
        'movementDrawer.actionAlerts.withMovementDeliveryAlert.title'
      );
      alertBody = t(
        'movementDrawer.actionAlerts.withMovementDeliveryAlert.text'
      );
      break;
    case WITH_MOVEMENT_RETURN_ALERT:
      alertTitle = t(
        'movementDrawer.actionAlerts.withMovementReturnAlert.title'
      );
      alertBody = t('movementDrawer.actionAlerts.withMovementReturnAlert.text');
      break;
    case DELAYED_WITHOUT_MOVEMENT_ALERT:
      alertTitle = t(
        'movementDrawer.actionAlerts.delayedWithoutMovementAlert.title'
      );
      alertBody =
        daysCount === 1
          ? t(
              'movementDrawer.actionAlerts.delayedWithoutMovementAlert.textSingular'
            )
          : t('movementDrawer.actionAlerts.delayedWithoutMovementAlert.text', {
              count: daysCount
            });
      alertSeverity = 'error';
      break;
    default:
  }

  return (
    <Alert severity={alertSeverity} icon={<InfoIcon fontSize="inherit" />}>
      <Typography variant="body2">
        <strong>{alertTitle}</strong>
      </Typography>
      <Typography variant="body2">
        <Trans>{alertBody}</Trans>
      </Typography>
    </Alert>
  );
};

MovementDrawerAlert.propTypes = {
  alertCase: PropTypes.string.isRequired,
  daysCount: PropTypes.number
};

MovementDrawerAlert.defaultProps = {
  daysCount: 0
};

export default MovementDrawerAlert;
