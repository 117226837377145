import { Box, Button, Divider } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Toast from '@loggi/components/src/one/toast';
import { useFeature } from '@loggi/components/src/one/remote-config';

import AddressSection from './address-section.component';
import ResponsibleSection from './responsible-section.component';
import AddressAliasSection from './address-alias-section.component';
import ReturnAddressFormShape from './return-address-form.shape';
import { routes } from '../..';
import addressesApi from '../../addresses-api';
import UpdateReturnAddressConfirmationDialog from './return-address-confirmation-modal';

const ReturnAddressForm = ({ initialValues }) => {
  const { t } = useTranslation('management');
  const [loading, setLoading] = useState(false);
  const { companyId, warehouseId } = useParams();
  const { goBack, push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [confirmationDialogState, setConfirmationDialogState] = useState(false);

  const enableReturnAddressChangeLogic = useFeature(
    'enable_return_address_alert'
  );

  const backToAddressesList = useCallback(() => {
    const url = routes.addressList.url(companyId, warehouseId);
    push(url);
  }, [companyId, warehouseId, push]);

  const handleCreateOrUpdateReturnAddressRequest = useCallback(
    formValues => {
      setLoading(true);
      addressesApi
        .createOrUpdate(companyId, warehouseId, formValues)
        .then(() => {
          setLoading(false);
          if (enableReturnAddressChangeLogic) {
            goBack();
          } else {
            backToAddressesList();
          }
          enqueueSnackbar(t('returnAddress.actions.create.successToast'), {
            content: (key, message) => (
              <Toast id={key} message={message} type="success" />
            )
          });
        })
        .catch(() => {
          setLoading(false);
          enqueueSnackbar(t('returnAddress.actions.create.errorToast'), {
            content: (key, message) => (
              <Toast id={key} message={message} type="error" />
            )
          });
        });
    },
    [
      companyId,
      warehouseId,
      enqueueSnackbar,
      setLoading,
      t,
      goBack,
      backToAddressesList,
      enableReturnAddressChangeLogic
    ]
  );

  const handleConfirmation = () => {
    document.getElementById('submitWarehouseUpdateForm').click();
    setConfirmationDialogState(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        // 'validateOnMount' is needed because we want the form
        // to be invalid on initial render, so the submit button
        // is disabled.
        validateOnMount
        onSubmit={handleCreateOrUpdateReturnAddressRequest}
      >
        {({ isValid }) => {
          return (
            <Form data-testid="return-address-form">
              <Box pb={5}>
                <AddressSection />
              </Box>
              <Divider />
              <Box py={5}>
                <ResponsibleSection />
              </Box>
              <Divider />
              <Box py={5}>
                <AddressAliasSection />
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                pt={5}
                flexDirection={{ xs: 'column-reverse', sm: 'row' }}
              >
                <Box marginTop={{ xs: 1, sm: 0 }} marginRight={{ sm: 4 }}>
                  <Button
                    data-testid="cancel-btn"
                    onClick={() =>
                      enableReturnAddressChangeLogic
                        ? goBack()
                        : backToAddressesList()
                    }
                    color="primary"
                    size="large"
                  >
                    {t('returnAddress.actions.cancel')}
                  </Button>
                </Box>
                {enableReturnAddressChangeLogic ? (
                  <Button
                    color="primary"
                    disabled={!isValid || loading}
                    data-testid="returnAddressForm-actionButton"
                    size="large"
                    onClick={() => setConfirmationDialogState(true)}
                    variant="contained"
                  >
                    {t('returnAddress.actions.create.button')}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    disabled={!isValid || loading}
                    data-testid="returnAddressForm-actionButton"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('returnAddress.actions.create.button')}
                  </Button>
                )}
                <Button
                  id="submitWarehouseUpdateForm"
                  type="submit"
                  style={{ display: 'None' }}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>

      <UpdateReturnAddressConfirmationDialog
        dialogState={confirmationDialogState}
        setDialogState={setConfirmationDialogState}
        handleConfirmation={handleConfirmation}
      />
    </>
  );
};

ReturnAddressForm.propTypes = {
  initialValues: PropTypes.shape(ReturnAddressFormShape)
};

ReturnAddressForm.defaultProps = {
  initialValues: {}
};

export default ReturnAddressForm;
