import React from 'react';
import PropTypes from 'prop-types';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import { useTranslation } from 'react-i18next';

import { Typography, Box } from '@material-ui/core';

const DateFilterOption = ({ onClickDateFilter }) => {
  const { t } = useTranslation('packages');
  return (
    <Box
      data-testid="datePickerOption"
      onClick={onClickDateFilter}
      display="flex"
      alignItems="flex-start"
      paddingY={1}
    >
      <Box>
        <CalendarTodayIcon
          style={{ fontSize: 18, marginTop: 2 }}
          color="primary"
        />
      </Box>
      <Box pl={1}>
        <Typography color="primary">
          {t('filters.date.datePickerOption')}
        </Typography>
      </Box>
    </Box>
  );
};

DateFilterOption.propTypes = {
  onClickDateFilter: PropTypes.func.isRequired
};

export default DateFilterOption;
