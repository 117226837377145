// Validations
import { validateMessageSchema } from './validators';

// Platform handlers
import messageHandler from './handlers';

// Schema
import bridgeNames from './schema';

export default function registerBridge(schema) {
  // Transform schema keys into functions
  const schemaKeys = Object.keys(schema);
  const Bridge = {};
  schemaKeys.forEach(listener => {
    Bridge[listener] = message => {
      if (validateMessageSchema(schema[listener], message)) {
        messageHandler(listener, JSON.stringify(message));
      }
    };
  });

  return Bridge;
}

export const createNotificationBridge = bridgeName =>
  registerBridge({ [bridgeName]: bridgeNames[bridgeName] });
