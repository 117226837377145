import {
  OneTemplate,
  OneTemplateSummary,
  OneTemplateContent
} from '@loggi/components';
import Toast from '@loggi/components/src/one/toast';
import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import routes from '../../management-routes';
import PageTitle from '../../page-title.component';
import CardIntegrator from '../components/card-integrator.component';
import integratorListApi from '../services/integrator-api';
import integratorsJson from '../data/integrators-translations-pt-br.json';
import { IconsList } from '../assets';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  cardPosition: {
    [breakpoints.only('xs')]: {
      marginTop: spacing(3),
      justifyContent: 'center'
    }
  }
}));

const IntegrationsList = () => {
  const classes = useStyles();
  const { companyId } = useParams();
  const { t } = useTranslation('management');
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [activeIntegrations, setActiveIntegrations] = useState([]);

  useEffect(() => {
    setLoading(true);
    integratorListApi
      .integratorsList(companyId)
      .then(response => {
        const activeIntegrators = response
          .filter(integration => integration.active)
          .map(({ integrator }) => integrator.name);
        setActiveIntegrations(activeIntegrators);
        setLoading(false);
      })
      .catch(error => {
        enqueueSnackbar(error.message, {
          content: (key, message) => (
            <Toast id={key} message={message} type="error" />
          )
        });
        setLoading(false);
      });
  }, [companyId, enqueueSnackbar, t]);

  const integratorList = [];
  Object.entries(integratorsJson).forEach(value => {
    const integrator = value[0];
    const integratorName = value[1].name;
    integratorList.push({
      id: `integration${integratorName}`,
      active: activeIntegrations.includes(`${integratorName}`),
      Icon:
        integrator in IconsList
          ? IconsList[`${integrator}`]
          : IconsList.default,
      subtitle: t('integrations.cards.subtitle.textConfig', {
        integratorName
      }),
      url: routes[`integration${integratorName}`].url(companyId),
      visible: t(`${integrator.toString()}.visible`, { returnObjects: true })
    });
  });

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Box
          alignItems="center"
          display="flex"
          flexWrap={{ xs: 'wrap', sm: 'initial' }}
        >
          <Box pr={3} flex={1}>
            <PageTitle title="menuConfig.cards.titles.integratorList" />
          </Box>
        </Box>
      </OneTemplateSummary>
      <OneTemplateContent disablePaper>
        <Box
          display="flex"
          flexWrap="wrap"
          width="100%"
          className={classes.cardPosition}
        >
          {integratorList.map(
            ({ id, active, Icon, subtitle, url, visible }) =>
              visible && (
                <CardIntegrator
                  key={id}
                  icon={<Icon />}
                  subtitle={subtitle}
                  url={url}
                  isActive={active}
                  loading={loading}
                />
              )
          )}
        </Box>
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default IntegrationsList;
