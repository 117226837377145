import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import emptyPickupsIllustration from './empty-pickups.svg';

const useStyles = makeStyles(({ typography }) => ({
  container: {
    maxWidth: typography.pxToRem(496),
    width: '100%'
  },
  title: {
    fontWeight: typography.fontWeightBold
  },
  image: {
    width: '100%',
    maxWidth: typography.pxToRem(155)
  }
}));

const SchedulingEmptyFilterResult = () => {
  const history = useHistory();
  const classes = useStyles();
  const { url } = useRouteMatch();
  const { t } = useTranslation('scheduling');
  const goToScheduling = () => history.push(url);

  return (
    <Box
      alignItems="center"
      className={classes.container}
      data-testid="empty-state-wrapper"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      m="auto"
      textAlign="center"
    >
      <img
        className={classes.image}
        src={emptyPickupsIllustration}
        alt="Truck"
      />
      <Box mt={3.5} mb={2}>
        <Box mb={2}>
          <Typography className={classes.title} variant="subtitle1">
            {t('emptyFilterResult.title')}
          </Typography>
        </Box>
        <Typography variant="subtitle1" component="p">
          {t('emptyFilterResult.subtitle')}
        </Typography>
      </Box>
      <Button onClick={goToScheduling} color="primary">
        {t('emptyFilterResult.linkText')}
      </Button>
    </Box>
  );
};

export default SchedulingEmptyFilterResult;
