import React from 'react';
import PropTypes from 'prop-types';
import { getI18n } from 'react-i18next';

// @MATERIAL-UI
import ErrorIcon from '@material-ui/icons/Error';
import { Alert } from '@material-ui/lab';

import {
  SCHEDULING_STATUSES,
  CANCELLED_BY_VALUES
} from '../../../scheduling/constants';

export const getCancelationDisclaimer = (
  orderStatus,
  cancelledBy,
  wasCancelledByShipper
) => {
  const i18n = getI18n();
  const t = i18n.getFixedT(null, 'details');
  const disclaimer = {};

  if (orderStatus === SCHEDULING_STATUSES.CANCELLED) {
    disclaimer.severity = 'error';

    if (
      cancelledBy === CANCELLED_BY_VALUES.SCHEDULE_CANCELLED_BY_SHIPPER ||
      (wasCancelledByShipper && !cancelledBy)
    ) {
      disclaimer.message = t('cancelDisclaimer.userCancellationMessage');
      return disclaimer;
    }

    if (cancelledBy === CANCELLED_BY_VALUES.SCHEDULE_CANCELLED_BY_TRACKING) {
      disclaimer.message = t('cancelDisclaimer.noPackagesCancellationMessage');
      return disclaimer;
    }

    disclaimer.message = t('cancelDisclaimer.failCancellationMessage');
    return disclaimer;
  }

  if (orderStatus === SCHEDULING_STATUSES.FAILED_TO_SCHEDULE) {
    disclaimer.severity = 'error';
    disclaimer.message = t('cancelDisclaimer.failCancellationMessage');

    return disclaimer;
  }

  if (orderStatus === SCHEDULING_STATUSES.PARTIALLY_DISPATCHED) {
    disclaimer.severity = 'success';
    disclaimer.message = t('cancelDisclaimer.partialCancellationMessage');
    return disclaimer;
  }

  return disclaimer;
};

const CancelScheduleDisclaimer = ({
  orderStatus,
  cancelledBy,
  wasCancelledByShipper
}) => {
  const { message, severity } = getCancelationDisclaimer(
    orderStatus,
    cancelledBy,
    wasCancelledByShipper
  );

  return (
    <Alert icon={<ErrorIcon style={{ fontSize: 20 }} />} severity={severity}>
      {message}
    </Alert>
  );
};

CancelScheduleDisclaimer.propTypes = {
  orderStatus: PropTypes.string,
  wasCancelledByShipper: PropTypes.bool,
  cancelledBy: PropTypes.string
};

CancelScheduleDisclaimer.defaultProps = {
  orderStatus: SCHEDULING_STATUSES.WAITING_PICKUP,
  wasCancelledByShipper: false,
  cancelledBy: ''
};

export default CancelScheduleDisclaimer;
