import React from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(({ typography }) => ({
  skeleton: {
    borderRadius: typography.pxToRem(4)
  },
  title: {
    width: typography.pxToRem(205)
  }
}));

const RecipientSectionSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Box
        className={classes.title}
        pb={3}
        data-testid="recipient-section-skeleton"
      >
        <Typography variant="h6">
          <Skeleton className={classes.skeleton} />
        </Typography>
      </Box>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} pb={4}>
        <Box flex="1" pb={{ xs: 4, sm: 0 }}>
          <Box>
            <Typography variant="body2">
              <Skeleton className={classes.skeleton} width="28%" />
            </Typography>
          </Box>
          <Box pt={1}>
            <Typography variant="body2">
              <Skeleton className={classes.skeleton} width="65%" />
            </Typography>
          </Box>
        </Box>
        <Box flex="1">
          <Typography variant="body2">
            <Skeleton className={classes.skeleton} width="28%" />
          </Typography>
          <Box pt={1}>
            <Typography variant="body2">
              <Skeleton className={classes.skeleton} width="65%" />
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography variant="body2">
          <Skeleton className={classes.skeleton} width="28%" />
        </Typography>
        <Box pt={1}>
          <Typography variant="body2">
            <Skeleton className={classes.skeleton} width="65%" />
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default RecipientSectionSkeleton;
