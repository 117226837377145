import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import React from 'react';
import SchedulingSummary from './components/scheduling-summary';
import SchedulingsList from './components/schedulings-list';
import RecurringPickupListScreen from './components/settings-recurring-pickup/recurring-pickup-list.screen';

/**
 * This is the schedulings screen root, it's responsible for getting the data
 * from API using the useSchedulings hook, and then passing to its children components
 * It receives no params, it renders itself without external dependencies
 * @componentType container
 * @returns {*}
 */
const Scheduling = () => {
  return (
    <OneTemplate>
      <OneTemplateSummary>
        <SchedulingSummary />
      </OneTemplateSummary>
      <OneTemplateContent
        disablePadding
        mx={{ xs: 1.5, sm: 0 }}
        style={{ borderRadius: 16 }}
      >
        <RecurringPickupListScreen />
      </OneTemplateContent>
      <OneTemplateContent
        disablePadding
        mx={{ xs: 1.5, sm: 0 }}
        mt={3}
        style={{ borderRadius: 16 }}
      >
        <SchedulingsList />
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default Scheduling;
