import { translations as paymentsTranslations } from '../payments';

const translations = {
  'pt-BR': {
    ...paymentsTranslations['pt-BR']
  },
  en: {
    ...paymentsTranslations.en
  }
};

export default translations;
