import headerEnTranslations from './header-en.translations.json';
import headerPtBrTranslations from './header-pt-br.translations.json';

const translations = {
  'pt-BR': {
    components: {
      ...headerPtBrTranslations
    }
  },
  en: {
    components: {
      ...headerEnTranslations
    }
  }
};

export default translations;
