import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { Box, useTheme, CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Toast from '@loggi/components/src/one/toast';
import { SCHEDULING_STATUSES } from '../../../scheduling/constants';
import schedulingApi from '../../scheduling-api';
import SchedulingDetailsDriversList from '../scheduling-details-drivers-list/scheduling-details-drivers-list.component';
import SchedulingDetailsDriversSummary from '../scheduling-details-drivers-summary';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    minHeight: spacing(14),
    [breakpoints.up('sm')]: {
      minHeight: spacing(60)
    }
  }
}));

/**
 * This is the packages scheduling details screen, it's responsible for getting the data
 * from API and then passing to it's children components.
 * It receives no params, it renders itself without external dependencies.
 * @componentType container
 * @returns {*}
 */
const SchedulingDetailsDrivers = () => {
  const styles = useStyles();
  const { palette } = useTheme();
  const { id, companyId } = useParams();
  const [pickupOrderData, setPickupOrderData] = useState();
  const [trackingData, setTrackingData] = useState([]);
  const [isPickupLoading, setIsPickupLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    schedulingApi
      .get(id, companyId)
      .then(result => setPickupOrderData(result))
      .catch(error => {
        enqueueSnackbar(error?.message, {
          content: (key, message) => (
            <Toast id={key} message={message} type="error" />
          )
        });
      });

    schedulingApi
      .getPickups(id, companyId)
      .then(trackingResult => setTrackingData(trackingResult))
      .catch(error => {
        enqueueSnackbar(error?.message, {
          content: (key, message) => (
            <Toast id={key} message={message} type="error" />
          )
        });
      })
      .finally(() => setIsPickupLoading(false));
  }, [id, companyId, enqueueSnackbar]);

  const orderStatus = pickupOrderData?.status;
  const { pickups } = trackingData;
  const shouldJustifyContent = !(
    pickups?.length && orderStatus !== SCHEDULING_STATUSES.CANCELLED
  );

  const backgroundMap = {
    [SCHEDULING_STATUSES.CANCELLED]: palette.error.dark,
    [SCHEDULING_STATUSES.DISPATCHED]: palette.success.dark,
    [SCHEDULING_STATUSES.PARTIALLY_DISPATCHED]: palette.success.dark
  };

  return (
    <OneTemplate>
      <OneTemplateSummary color={backgroundMap[orderStatus]}>
        <SchedulingDetailsDriversSummary
          id={id}
          vehiclesAmount={pickups?.length}
          orderStatus={orderStatus}
        />
      </OneTemplateSummary>
      <OneTemplateContent disablePadding>
        <Box
          className={styles.root}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent={shouldJustifyContent ? 'center' : {}}
        >
          {isPickupLoading ? (
            <CircularProgress data-testid="loading" />
          ) : (
            <Box pt={1} width={1}>
              <SchedulingDetailsDriversList
                pickups={pickups}
                orderStatus={orderStatus}
              />
            </Box>
          )}
        </Box>
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default SchedulingDetailsDrivers;
