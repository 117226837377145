import { USER_ACCESS_COMPANIES_LOCAL_STORAGE_KEY } from '@loggi/authentication-lib/src/constants';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  Box,
  fade,
  makeStyles
} from '@material-ui/core';
import {
  CheckCircle,
  BusinessRounded,
  RadioButtonUnchecked
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { redirectToHelpCenter } from '@loggi/components/src/one/utils';
import { useFeature } from '@loggi/components/src/one/remote-config';
import {
  SELECTED_COMPANY_LOCAL_STORAGE_KEY,
  SUPPORT_ROLE
} from '../../constants';
import { COMPANIES_ROUTE } from '../../routes/constants';
import { formattedCNPJ } from '../utils';
import useCurrentUser from '../../auth/use-current-user';

export const COMPANY_ITEM_TEST_ID = 'company-item';
export const SELECTED_ICON_TEST_ID = 'selected-icon-item';

const useStyles = makeStyles(() => ({
  dynamicScroll: {
    maxHeight: ({ companyItemHeight }) => `calc(${companyItemHeight}px * 4)`,
    overflow: 'auto'
  }
}));

const CustomList = withStyles(() => ({
  root: {
    padding: 0
  }
}))(List);

const CustomListItem = withStyles(({ typography, palette, spacing }) => ({
  root: {
    padding: spacing(1.25, 3),
    '& span': {
      fontWeight: typography.fontWeightBold
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0
    },
    '& .MuiSvgIcon-root': {
      color: palette.primary.main,
      fontSize: '30px'
    },
    '& .MuiListItemText-primary': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginRight: '20px'
    },
    '&:hover': {
      backgroundColor: fade(palette.primary.light, 0.1)
    },
    '&.Mui-selected': {
      backgroundColor: palette.secondary.light,
      borderLeft: '5px solid',
      borderLeftColor: palette.primary.main,
      paddingLeft: '19px'
    }
  }
}))(ListItem);

const CompaniesList = ({
  companies,
  shouldRemoveCompanySelection,
  filteredCompanies
}) => {
  const ref = useRef(null);
  const { t } = useTranslation('components');
  const match = useRouteMatch('/empresas/:companyId');
  const companyId = match?.params?.companyId;
  const [companiesToBeRendered, setCompaniesToBeRendered] = useState([
    ...filteredCompanies
  ]);
  const [shouldRemoveIcon, setShouldRemoveIcon] = useState(
    shouldRemoveCompanySelection
  );
  const [companyItemHeight, setCompanyItemHeight] = useState(0);
  const styles = useStyles({ companyItemHeight });
  const user = useCurrentUser();
  const useZendeskUrl = useFeature('enable_redirect_to_zendesk_help_center');

  useEffect(() => {
    const height = ref.current ? ref.current.offsetHeight : 0;
    setCompanyItemHeight(height);
  }, [ref]);

  const handleListItemClick = company => {
    localStorage.setItem(
      SELECTED_COMPANY_LOCAL_STORAGE_KEY,
      JSON.stringify(company)
    );
    setShouldRemoveIcon(false);

    const userCompany = user.companyAccess.find(x => x.id === company.id);
    if (userCompany?.role === SUPPORT_ROLE) {
      // this finally here is because all promises must be treated
      redirectToHelpCenter(userCompany.id, user, useZendeskUrl).finally(() => {
        // eslint-disable-next-line no-useless-return
        return;
      });
    }
  };

  useEffect(() => {
    localStorage.setItem(
      USER_ACCESS_COMPANIES_LOCAL_STORAGE_KEY,
      JSON.stringify(companies)
    );
  }, [companies]);

  const sortArrayByAlphabeticalOrder = useCallback(() => {
    const newArr = [...filteredCompanies];
    return newArr.sort((a, b) => {
      const nameToCompareA = a.shared_name || a.name;
      const nameToCompareB = b.shared_name || b.name;
      return nameToCompareA
        .toLowerCase()
        .localeCompare(nameToCompareB.toLowerCase());
    });
  }, [filteredCompanies]);

  const moveSelectedCompanyToFirstPosition = useCallback(
    array => {
      const index = array.findIndex(a => a.id === Number(companyId));
      if (index !== -1) {
        const item = array.splice(index, 1)[0];
        array.unshift(item);
      }
    },
    [companyId]
  );

  useEffect(() => {
    const sortedArrayByName = sortArrayByAlphabeticalOrder();
    moveSelectedCompanyToFirstPosition(sortedArrayByName);
    setCompaniesToBeRendered(sortedArrayByName);
  }, [
    sortArrayByAlphabeticalOrder,
    moveSelectedCompanyToFirstPosition,
    setCompaniesToBeRendered
  ]);

  return (
    <CustomList component="nav" className={styles.dynamicScroll}>
      {companiesToBeRendered.map(company => (
        <Fragment key={`company-item-${company.id}`}>
          <CustomListItem
            ref={ref}
            button
            component={RouterLink}
            to={`${COMPANIES_ROUTE}/${company.id}`}
            selected={!shouldRemoveIcon && company.id === Number(companyId)}
            onClick={() => handleListItemClick(company)}
            data-testid={COMPANY_ITEM_TEST_ID}
          >
            <Box mr={2}>
              <BusinessRounded style={{ color: 'black', fontSize: '25px' }} />
            </Box>
            <ListItemText
              primary={company.shared_name || company.name}
              secondary={`${t('header.companiesMenu.cnpj')} ${formattedCNPJ(
                company.cnpj
              )}`}
            />
            {!shouldRemoveIcon && company.id === Number(companyId) ? (
              <ListItemIcon data-testid={SELECTED_ICON_TEST_ID}>
                <CheckCircle fontSize="small" />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <RadioButtonUnchecked
                  fontSize="small"
                  style={{ color: '#AEBAC9' }}
                />
              </ListItemIcon>
            )}
          </CustomListItem>
        </Fragment>
      ))}
    </CustomList>
  );
};

CompaniesList.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape([
      PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          shared_name: PropTypes.string,
          cnpj: PropTypes.string
        })
      )
    ])
  ).isRequired,
  filteredCompanies: PropTypes.arrayOf(
    PropTypes.shape([
      PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          shared_name: PropTypes.string,
          cnpj: PropTypes.string
        })
      )
    ])
  ).isRequired,
  shouldRemoveCompanySelection: PropTypes.bool.isRequired
};

export default CompaniesList;
