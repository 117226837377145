import {
  addHours,
  addWeeks,
  isValid,
  getISODay,
  setISODay,
  parseISO,
  subHours,
  addDays
} from 'date-fns';
import * as defaults from './constants';
import nonWorkingDayApi from '../operations/unified-pickup/non-working-day-api';

export function basicHolidays(date) {
  if (date.getMonth() === defaults.MONTH_JANUARY) {
    return date.getDate() === defaults.DATE_NEW_YEARS;
  }
  if (date.getMonth() === defaults.MONTH_DECEMBER) {
    const december = [
      defaults.DATE_CHRISTMAS,
      defaults.DATE_CHRISTMAS_EVE,
      defaults.DATE_NEW_YEARS_EVE
    ];

    return december.indexOf(date.getDate()) >= 0;
  }

  return false;
}

const parseValues = formValue => {
  try {
    const aux = JSON.parse(formValue) ?? {};
    if (aux.pickupDate) {
      aux.pickupDate = parseISO(aux.pickupDate);
    }
    if (aux.pickupEndTime) {
      aux.pickupEndTime = parseISO(aux.pickupEndTime);
    }
    if (aux.pickupStartTime) {
      aux.pickupStartTime = parseISO(aux.pickupStartTime);
    }
    return aux;
  } catch {
    return {};
  }
};

const setDateTime = (hours = 0, minutes = 0, seconds = 0, milliseconds = 0) => {
  const dt = new Date();
  dt.setHours(hours);
  dt.setMinutes(minutes);
  dt.setSeconds(seconds);
  dt.setMilliseconds(milliseconds);
  return dt;
};

const fitsRulesForSameDay = ({ pickupWeekdays, pickupStartTime }) => {
  const todayWeekdayStr = getISODay(new Date()).toString();
  const isTodayWeekdaySelected = pickupWeekdays.includes(todayWeekdayStr);
  const dateNowPlusOneHour = addHours(new Date(), 1);

  return isTodayWeekdaySelected && pickupStartTime >= dateNowPlusOneHour;
};

/**
 * Based on date, address and complement will check if is a working day
 * @param {Date} date
 * @param {String} address
 * @param {String} addressComplement
 * @param {Number} companyId
 * @returns {Promise<Boolean>}
 */
const isWorkingDay = async ({
  date,
  address,
  addressComplement,
  companyId
}) => {
  return (
    nonWorkingDayApi
      .get({
        date,
        address,
        addressComplement,
        companyId
      })
      .then(response => !response.is_non_working_day)
      // In case of failure in the call to the API, we will consider the date as
      // a working day because of the probability that the date is actually a working day.
      .catch(() => true)
  );
};

/**
 * Based on pickup weekdays array and start time, calculates next pickup date
 * @param {Array} pickupWeekdays
 * @param {Date} pickupStartTime
 * @param {String} address
 * @param {String} addressComplement
 * @param {Number} companyId
 * @param {Boolean} validateWorkingDay
 * @returns {Promise<Date|undefined>}
 */
const createSameDay = async ({
  pickupWeekdays,
  pickupStartTime,
  address,
  addressComplement,
  validateWorkingDay,
  companyId
}) => {
  if (fitsRulesForSameDay({ pickupWeekdays, pickupStartTime })) {
    const today = new Date();

    if (!validateWorkingDay) return today;

    if (
      await isWorkingDay({ date: today, address, addressComplement, companyId })
    ) {
      return today;
    }
  }

  return undefined;
};

const pickupDateInSameWeek = ({ pickupWeekday }) => {
  const todayWeekday = getISODay(new Date());

  if (pickupWeekday > todayWeekday) {
    const daysDifference = pickupWeekday - todayWeekday;
    const dayWeek = todayWeekday + daysDifference;
    return setISODay(new Date(), dayWeek);
  }

  return undefined;
};

const createSameWeek = async ({
  pickupWeekdays,
  address,
  addressComplement,
  validateWorkingDay,
  companyId
}) => {
  for (let i = 0; i < pickupWeekdays?.length; i += 1) {
    const pickupDate = pickupDateInSameWeek({
      pickupWeekday: pickupWeekdays[i]
    });
    if (pickupDate) {
      /* eslint-disable no-await-in-loop */
      if (
        !validateWorkingDay ||
        (await isWorkingDay({
          date: pickupDate,
          address,
          addressComplement,
          companyId
        }))
      ) {
        return pickupDate;
      }
    }
  }

  return undefined;
};

const pickupDateNextWeek = ({ pickupWeekday, numberOfWeeksAhead = 1 }) => {
  return setISODay(addWeeks(new Date(), numberOfWeeksAhead), pickupWeekday);
};

const createNextWeek = async ({
  pickupWeekdays,
  address,
  addressComplement,
  numberOfWeeksAhead = 1,
  validateWorkingDay,
  companyId
}) => {
  for (let i = 0; i < pickupWeekdays?.length; i += 1) {
    const pickupWeekday = pickupWeekdays[i];
    const pickupDate = pickupDateNextWeek({
      pickupWeekday,
      numberOfWeeksAhead
    });

    /* eslint-disable no-await-in-loop */
    if (
      !validateWorkingDay ||
      (await isWorkingDay({
        date: pickupDate,
        address,
        addressComplement,
        companyId
      }))
    ) {
      return pickupDate;
    }
  }

  const weeksAhead = numberOfWeeksAhead + 1;
  return createNextWeek({
    pickupWeekdays,
    address,
    addressComplement,
    numberOfWeeksAhead: weeksAhead,
    validateWorkingDay,
    companyId
  });
};

export const settingTheHoursToTheStartTime = (
  startingFromDate,
  pickupStartTime
) => {
  startingFromDate.setHours(pickupStartTime.getHours());
  startingFromDate.setMinutes(pickupStartTime.getMinutes());
  startingFromDate.setSeconds(pickupStartTime.getSeconds());
  startingFromDate.setMilliseconds(pickupStartTime.getMilliseconds());
};

const pickupDateForRecurringPickup = async ({
  pickupWeekdays,
  pickupStartTime,
  address,
  addressComplement,
  validateWorkingDay,
  companyId
}) => {
  const params = {
    pickupWeekdays,
    pickupStartTime,
    address,
    addressComplement,
    validateWorkingDay,
    companyId
  };

  let startingFromDate = await createSameDay(params);

  if (!startingFromDate) {
    startingFromDate = await createSameWeek(params);
  }

  if (!startingFromDate) {
    startingFromDate = await createNextWeek(params);
  }

  return startingFromDate;
};

/**
 * Based on pickup weekday and start time, calculates next pickup date
 * @param {string} pickupWeekday
 * @param {String} address
 * @param {String} addressComplement
 * @param {Date} pickupStartTime
 * @param {Boolean} validateWorkingDay
 * @param {Number} companyId
 * @returns {Promise<Date|undefined>}
 */
const pickupDateForIndividualPickup = async ({
  pickupWeekday,
  address,
  addressComplement,
  pickupStartTime,
  validateWorkingDay,
  companyId
}) => {
  let pickupDate;
  if (
    fitsRulesForSameDay({ pickupWeekdays: [pickupWeekday], pickupStartTime })
  ) {
    pickupDate = new Date();
  }

  if (!pickupDate) {
    pickupDate = pickupDateInSameWeek({ pickupWeekday });
  }

  if (!pickupDate) {
    pickupDate = pickupDateNextWeek({ pickupWeekday });
  }

  if (
    !validateWorkingDay ||
    (await isWorkingDay({
      date: pickupDate,
      address,
      addressComplement,
      companyId
    }))
  ) {
    return pickupDate;
  }

  return undefined;
};

/**
 * Based on pickup weekdays array and start time, calculates next pickup date
 * @param {Array} pickupWeekdays
 * @param {Date} pickupStartTime
 * @param {String} address
 * @param {String} addressComplement
 * @param {Number} companyId
 * @param {Boolean} isIndividualPickup
 * @param {Boolean} validateWorkingDay
 * @returns {Promise<Date|undefined>}
 */
const getDateFromChosenWeekday = async ({
  pickupWeekdays,
  pickupStartTime,
  address,
  addressComplement,
  companyId,
  isIndividualPickup,
  validateWorkingDay
}) => {
  // Unordered arrays can cause incorrect date calculations
  pickupWeekdays.sort();

  let startingFromDate;

  if (isIndividualPickup) {
    startingFromDate = await pickupDateForIndividualPickup({
      pickupWeekday: pickupWeekdays[0],
      address,
      addressComplement,
      pickupStartTime,
      validateWorkingDay,
      companyId
    });
  } else {
    startingFromDate = await pickupDateForRecurringPickup({
      pickupWeekdays,
      pickupStartTime,
      address,
      addressComplement,
      validateWorkingDay,
      companyId
    });
  }

  if (startingFromDate) {
    settingTheHoursToTheStartTime(startingFromDate, pickupStartTime);
  }
  return startingFromDate;
};

/**
 * Removes all ponctuation from a CNPJ
 * @param {string} document
 * @returns {string}
 */
const removeCnpjMask = document => {
  if (typeof document === 'string') {
    return document.replace(/\D/g, '');
  }
  return document;
};

/**
 * Returns a pickup start time and end time of a given pickup period
 * @param {string} period
 * @returns {Object}
 */
const pickupWindowStandartizationTime = period => {
  let pickupStartTime;
  let pickupEndTime;

  if (period === defaults.IS_MORNING) {
    pickupStartTime = setDateTime(8);
    pickupEndTime = setDateTime(14);
  }

  if (period === defaults.IS_AFTERNOON) {
    pickupStartTime = setDateTime(13);
    pickupEndTime = setDateTime(18);
  }

  return { pickupStartTime, pickupEndTime };
};

/**
 * Set default values for order scheduling creation form
 * @param {Object} formValues
 * @returns {Object}
 */
const setDefaultsForMissingValues = formValues => {
  const aux = { ...formValues };
  if (!aux.pickupDate) {
    aux.pickupDate = null;
  }
  if (!aux.pickupIsRecurring) {
    aux.pickupIsRecurring = defaults.IS_RECURRING;
  }
  if (!aux.pickupStartTime || !aux.pickupEndTime) {
    const { pickupStartTime, pickupEndTime } = pickupWindowStandartizationTime(
      defaults.IS_MORNING
    );
    aux.pickupStartTime = pickupStartTime;
    aux.pickupEndTime = pickupEndTime;
  }
  return aux;
};

/**
 * Returns the pickup period of a given Date
 * @param {Object} startTime
 * @returns {string}
 */
const getPickupPeriodFromStartTime = startTime => {
  // default case
  if (!startTime) {
    return defaults.IS_MORNING;
  }
  if (isValid(startTime) && startTime.getHours() < 13) {
    return defaults.IS_MORNING;
  }
  return defaults.IS_AFTERNOON;
};

/**
 * Adapt selected packages map into a list of objects expected in order schedule creation request
 * @param {Map} selectedPackages
 * @returns {Array}
 */
const formatSelectedVolumesForOrderSchedule = selectedPackages => {
  const formattedSelectedPackages = [];

  if (selectedPackages) {
    selectedPackages.forEach(pkg => {
      formattedSelectedPackages.push({
        alias: 'Package',
        height_cm: pkg.heightCm,
        length_cm: pkg.lengthCm,
        weight_g: pkg.weightG,
        width_cm: pkg.widthCm,
        tracking_key: pkg.trackingKey,
        loggi_key: pkg.loggiKey,
        recipient_name: pkg.recipient?.fullName,
        recipient_address: {
          correios_address: {
            logradouro: pkg.recipient?.address?.addressSt,
            numero: pkg.recipient?.address?.addressNumber,
            complemento: pkg.recipient?.address?.addressComplement,
            bairro: pkg.recipient?.address?.vicinity,
            cep: pkg.recipient?.address?.zipCode,
            cidade: pkg.recipient?.address?.city,
            uf: pkg.recipient?.address?.state
          }
        }
      });
    });
  }

  return formattedSelectedPackages;
};

/**
 * Converts request params into OrderSchedule Objects
 * @param {Object} requestParams
 * @param {Array} selectedPackages (Optional)
 * @returns {Object}
 */
const formatFormValuesForOrderSchedules = (requestParams, selectedPackages) => {
  const {
    pickupAddress,
    pickupAddressComplement,
    pickupOriginName,
    pickupOriginPhone,
    pickupStartTime,
    pickupEndTime,
    modalRestrictions,
    pickupOriginInstructions,
    pickupDate
  } = requestParams;

  return {
    address: pickupAddress,
    complement: pickupAddressComplement,
    date: pickupDate,
    endTime: pickupEndTime,
    modalRestrictions,
    shipperName: pickupOriginName,
    shipperPhone: pickupOriginPhone,
    startTime: pickupStartTime,
    instructions: pickupOriginInstructions,
    volumes: formatSelectedVolumesForOrderSchedule(selectedPackages),
    document: removeCnpjMask(requestParams?.cnpjOrCpf)
  };
};

/**
 * Converts string representations of dates into Date instances
 * and returns a new object
 * @param {Object} initialValues
 * @returns {Object}
 */
export const setDateToInitialValues = initialValues => {
  const { pickupDate, pickupEndTime, pickupStartTime } = initialValues;
  const nextInitialValues = { ...initialValues };

  if (pickupDate) nextInitialValues.pickupDate = parseISO(pickupDate);
  if (pickupEndTime) {
    const pickupEndTimeDate = new Date(pickupEndTime);
    nextInitialValues.pickupEndTime = setDateTime(
      pickupEndTimeDate.getHours(),
      pickupEndTimeDate.getMinutes()
    );
  }
  if (pickupStartTime) {
    const pickupStartTimeDate = new Date(pickupStartTime);
    nextInitialValues.pickupStartTime = setDateTime(
      pickupStartTimeDate.getHours(),
      pickupStartTimeDate.getMinutes()
    );
  }
  return nextInitialValues;
};

export const calculateMinDate = dt => {
  const date = setDateTime(dt?.getHours());
  const date2 = subHours(date, defaults.MINIMUM_DATE_HOURS_DIFF);
  if (date2.getTime() < new Date().getTime()) {
    return addDays(date, 1);
  }
  return date2;
};

export {
  getDateFromChosenWeekday,
  formatFormValuesForOrderSchedules,
  removeCnpjMask,
  pickupWindowStandartizationTime,
  getPickupPeriodFromStartTime,
  setDateTime,
  parseValues,
  setDefaultsForMissingValues
};
