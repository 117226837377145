import { useEffect, useMemo, useState } from 'react';
import { useAmplifyAuth } from '@loggi/authentication-lib';

export default function usePickupsUserPermission() {
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const [canUsePickup, setCanUsePickup] = useState(true);
  const staffCompanyId = 3;
  const groupName = 'pickups_permission_group';

  const isUserInGroup = useMemo(() => {
    const groups = authenticatedUser['cognito:groups'] || [];
    return groups.includes(groupName);
  }, [authenticatedUser]);

  const hasLoggiCompanyAccess = useMemo(() => {
    const customAccess =
      authenticatedUser['custom:access'] ||
      authenticatedUser['custom:accessLoggiEnvios'];
    const customAccessLoggiEnvios = JSON.parse(customAccess);
    const companyAccess = customAccessLoggiEnvios?.companies || [];
    return companyAccess.some(company => company.id === staffCompanyId);
  }, [authenticatedUser, staffCompanyId]);

  useEffect(() => {
    if (hasLoggiCompanyAccess && !isUserInGroup) {
      setCanUsePickup(false);
    }
  }, [staffCompanyId, authenticatedUser, isUserInGroup, hasLoggiCompanyAccess]);

  return canUsePickup;
}
