import React, { useCallback, useState } from 'react';
import Toast from '@loggi/components/src/one/toast';
import { useFeature } from '@loggi/components/src/one/remote-config';
import { CoverageParamsContext } from '@loggi/components/src/one/coverage-params';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import recurringPickupFormShape from './recurring-pickup-form.shape';
import recurringPickupApi from './recurring-pickup-api';
import UnfiedPickupFormFields from './unified-pickup-form-fields';
import {
  removeCnpjMask,
  setDateToInitialValues
} from './unified-pickup-helpers';

const UnifiedPickupUpdateForm = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation(['unifiedPickup', 'orderScheduling']);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const initialValuesWithDate = setDateToInitialValues(initialValues);
  const { companyId, recurringPickupId } = useParams();
  const shouldUsePickupsApi = useFeature('should_use_pickups_api');

  const [context, setContext] = useState({
    coverageParams: {},
    setCoverageParams: coverageParams => {
      setContext(state => ({ ...state, coverageParams }));
    }
  });

  const buildToast = (key, type, message) => {
    return <Toast id={key} message={message} type={type} />;
  };

  const snackBarMessage = useCallback(
    (barMessage, type) =>
      enqueueSnackbar(barMessage, {
        content: (key, message) => buildToast(key, type, message)
      }),
    [enqueueSnackbar]
  );

  const handleSubmit = formValues => {
    setLoading(true);
    const requestParams = formValues;

    requestParams.document = removeCnpjMask(requestParams?.cnpjOrCpf);

    recurringPickupApi
      .put({
        companyId,
        data: requestParams,
        recurringPickupId,
        shouldUsePickupsApi
      })
      .then(() => {
        onSubmit(true);
        snackBarMessage(t('unifiedPickup:success.title'), 'success');
      })
      .catch(error => {
        snackBarMessage(error.message, 'error');
      })
      .finally(() => setLoading(false));
  };

  return (
    <CoverageParamsContext.Provider value={context}>
      <Formik
        initialValues={initialValuesWithDate}
        validateOnMount
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <UnfiedPickupFormFields
            isValid={isValid}
            isLoading={loading}
            pageTitle={t('orderScheduling:edit.title')}
            submitLabelButton={t(
              'orderScheduling:create.editRecurringPickupButton'
            )}
          />
        )}
      </Formik>
    </CoverageParamsContext.Provider>
  );
};

UnifiedPickupUpdateForm.propTypes = {
  initialValues: PropTypes.shape(recurringPickupFormShape),
  onSubmit: PropTypes.func.isRequired
};

UnifiedPickupUpdateForm.defaultProps = {
  initialValues: {}
};

export default UnifiedPickupUpdateForm;
