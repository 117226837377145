import { Box, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useSchedulingDetailsPackagesSummaryStyles = makeStyles(
  ({ palette, spacing }) => ({
    linkBackButton: {
      borderRadius: '100%',
      height: spacing(5),
      minWidth: 0,
      width: spacing(5),
      color: palette.common.white
    },
    fontColor: {
      mixBlendMode: 'screen',
      position: 'relative',
      color: 'initial',
      '&:before': {
        mixBlendMode: 'color-burn',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        content: '""'
      }
    }
  })
);

/**
 * SchedulingDetailsPackagesSummary's only responsibility is to show the summary info of the
 * packages scheduling details screen, with the params it receives.
 * @componentType: Presentational
 * @param id
 * @param packagesAmount
 * @returns {*}
 */
const SchedulingDetailsPackagesSummary = ({ id, packagesAmount }) => {
  const styles = useSchedulingDetailsPackagesSummaryStyles();
  const theme = useTheme();
  const { t } = useTranslation(['details']);

  const packagesAmountInfo = packagesAmount
    ? t('summary.package', { count: packagesAmount })
    : t('summary.emptyPackages');

  const showPackagesAmountInfo = packagesAmount !== null;

  return (
    <Box display="flex" flexDirection="column">
      <Box
        alignSelf="flex-start"
        mb={1.5}
        bgcolor={theme.palette.common.white}
        borderRadius={50}
        px={1}
        py={0.5}
        className={styles.fontColor}
      >
        <Typography variant="caption">
          <strong>{t('summary.scheduling', { id })}</strong>
        </Typography>
      </Box>
      {showPackagesAmountInfo && (
        <Box>
          <Box pb={1.5}>
            <Typography variant="h4">
              <strong>{packagesAmountInfo}</strong>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

SchedulingDetailsPackagesSummary.propTypes = {
  id: PropTypes.string.isRequired,
  packagesAmount: PropTypes.number
};

SchedulingDetailsPackagesSummary.defaultProps = {
  packagesAmount: null
};

export default SchedulingDetailsPackagesSummary;
