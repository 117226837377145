import { colors } from '@loggi/mar';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
  withStyles
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  SM_FIELD_SPACING,
  XS_FIELD_SPACING
} from '../sections/sections-constants';
import HeightField from './height-field';
import LengthField from './length-field';
import QuantityField from './quantity-field';
import WeightField from './weight-field';
import WidthField from './width-field';

const useStyles = makeStyles(({ breakpoints, spacing, typography }) => ({
  actions: {
    justifyContent: 'center',
    [breakpoints.up('md')]: {
      position: 'absolute',
      right: spacing(1),
      top: spacing(1)
    }
  },
  cardContent: {
    padding: spacing(0),
    '&:last-child': {
      paddingBottom: spacing(0)
    }
  },
  root: {
    position: 'relative'
  },
  volumeLabel: {
    color: colors.smoke[900],
    fontWeight: typography.fontWeightMedium,
    marginBottom: spacing(2),
    [breakpoints.up('sm')]: {
      marginBottom: spacing(3)
    }
  }
}));

const TimesIcon = withStyles(({ typography }) => ({
  root: {
    color: colors.smoke[300],
    // These horizontal margins makes the icon width not affect the layout
    marginLeft: '-0.5em',
    marginRight: '-0.5em',
    // This value keeps the x aligned with the input
    marginTop: typography.pxToRem(40)
  }
}))(CloseRoundedIcon);

const VolumeFields = ({ handleRemove, index }) => {
  const { t } = useTranslation('orderScheduling');
  const { values } = useFormikContext();
  const styles = useStyles();
  const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const gridSpacing = smUp ? SM_FIELD_SPACING : XS_FIELD_SPACING;
  const isRemoveEnabled = values.volumes.length > 1;

  return (
    <Card
      className={styles.root}
      component={Box}
      data-testid={`volumes.${index}`}
      mb={4}
      p={{ xs: 3, sm: 5 }}
      variant="outlined"
    >
      <CardContent className={styles.cardContent}>
        <Box width={{ xs: 1, md: 11 / 12 }}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={6} md>
              <HeightField index={index} />
            </Grid>
            <Hidden smDown>
              <TimesIcon fontSize="small" />
            </Hidden>
            <Grid item xs={12} sm={6} md>
              <WidthField index={index} />
            </Grid>
            <Hidden smDown>
              <TimesIcon fontSize="small" />
            </Hidden>
            <Grid item xs={12} sm={6} md>
              <LengthField index={index} />
            </Grid>
            <Grid item xs={12} sm={6} md>
              <WeightField index={index} />
            </Grid>
          </Grid>
          <Box mt={2}>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12} md={9}>
                <Typography className={styles.volumeLabel} variant="body2">
                  {t('create.volumeSection.titleQuantity')}
                </Typography>
                <QuantityField index={index} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardContent>
      {isRemoveEnabled && (
        <CardActions className={styles.actions}>
          <Button color="primary" onClick={handleRemove}>
            {t('create.volumeSection.removeVolume')}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

VolumeFields.propTypes = {
  handleRemove: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

export default VolumeFields;
