import {
  Box,
  Radio,
  FormControlLabel,
  RadioGroup,
  Tooltip,
  Grid,
  Typography,
  useTheme
} from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  getPickupPeriodFromStartTime,
  pickupWindowStandartizationTime
} from './unified-pickup-helpers';
import { IS_MORNING, IS_AFTERNOON } from './constants';

import { ReactComponent as MorningIcon } from './icons/morning.svg';
import { ReactComponent as EveningIcon } from './icons/evening.svg';

const useStyledCheckboxStyles = makeStyles(({ palette, typography, shape }) => {
  return {
    icon: {
      borderRadius: shape.borderRadius,
      fontSize: typography.body2.fontSize,
      fontWeight: typography.fontWeightBold,
      minHeight: typography.pxToRem(42),
      lineHeight: '150%',
      width: '100%',
      margin: typography.pxToRem(10),
      display: 'flex',
      verticalAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center'
    },
    checkedIcon: {
      color: palette.text.primary,
      background: palette.background.paper,
      boxShadow:
        '0px 8px 10px -5px rgba(190, 199, 214, 0.02), 0px 16px 24px 2px rgba(159, 171, 187, 0.03), 0px 6px 30px 5px rgba(72, 79, 95, 0.08)'
    },
    checkbox: {
      padding: 0,
      width: '100%',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  };
});

const periodSectionFieldStyles = makeStyles(
  ({ palette, typography, shape }) => ({
    formLabel: {
      margin: 0,
      width: '100%'
    },
    formGroup: {
      borderRadius: shape.borderRadius,
      justifyContent: 'space-between',
      width: '100%',
      background: palette.grey[50],
      minHeight: typography.pxToRem(62)
    }
  })
);

const StyledRadio = props => {
  const classes = useStyledCheckboxStyles();
  const {
    description,
    label,
    dataTestId,
    icon,
    value,
    disabled,
    subtitle
  } = props;
  const { typography } = useTheme();

  const radioItem = checked => {
    const fontProperties = checked
      ? {
          weight: typography.fontWeightMedium,
          color: 'textPrimary'
        }
      : {
          color: 'textSecondary'
        };

    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          style={{
            fontSize: typography.pxToRem(16),
            lineHeight: typography.pxToRem(24)
          }}
        >
          <Box display={{ lg: 'flex' }} pt={1}>
            {icon}
            <Typography
              variant="body1"
              color={fontProperties.color}
              style={{ fontWeight: fontProperties.weight, marginLeft: '.5em' }}
            >
              {label}
            </Typography>
          </Box>
          <Box py={1}>
            <Typography variant="body1" color={fontProperties.color}>
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Tooltip title={description}>
      <Radio
        className={classes.checkbox}
        checkedIcon={
          <>
            <span className={`${classes.icon} ${classes.checkedIcon}`}>
              {radioItem(true)}
            </span>
          </>
        }
        icon={<span className={`${classes.icon} `}>{radioItem(false)}</span>}
        inputProps={{
          'data-testid': dataTestId,
          'aria-label': description
        }}
        value={value}
        disabled={disabled}
      />
    </Tooltip>
  );
};

StyledRadio.propTypes = {
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

const PeriodSection = () => {
  const { t } = useTranslation('orderScheduling');

  const pickupFrequencyOptions = [
    {
      icon: <MorningIcon />,
      description: 'Manhã',
      label: t('create.dateSection.periodSection.buttonMorning'),
      subtitle: t('create.dateSection.periodSection.subtitleMorning'),
      fieldValue: IS_MORNING
    },
    {
      icon: <EveningIcon />,
      description: 'Tarde',
      label: t('create.dateSection.periodSection.buttonAfternoon'),
      subtitle: t('create.dateSection.periodSection.subtitleAfternoon'),
      fieldValue: IS_AFTERNOON
    }
  ];

  const classes = periodSectionFieldStyles();
  const endTimeFieldName = 'pickupEndTime';
  const startTimeFieldName = 'pickupStartTime';
  const pickupDateFieldName = 'pickupDate';

  const { values, setFieldValue } = useFormikContext();

  const initialValue = getPickupPeriodFromStartTime(values.pickupStartTime);

  useField({
    name: startTimeFieldName,
    initialValue: values.pickupStartTime,
    validate: () => null
  });

  useField({
    name: endTimeFieldName,
    initialValue: values.pickupEndTime,
    validate: () => null
  });

  const updatePickupWindowTime = useCallback(
    period => {
      const {
        pickupStartTime,
        pickupEndTime
      } = pickupWindowStandartizationTime(period);

      setFieldValue(startTimeFieldName, pickupStartTime, true);
      setFieldValue(endTimeFieldName, pickupEndTime, true);

      setFieldValue(pickupDateFieldName, null, true);
    },
    [endTimeFieldName, startTimeFieldName, pickupDateFieldName, setFieldValue]
  );

  const handleChange = useCallback(
    e => {
      const {
        target: { value }
      } = e;
      updatePickupWindowTime(value);
    },
    [updatePickupWindowTime]
  );

  const renderItems = () => {
    let i = 0;
    return pickupFrequencyOptions.map(
      ({ description, fieldValue, label, icon, subtitle }) => {
        i += 1;
        return (
          <Grid item xs={6} key={i}>
            <FormControlLabel
              className={classes.formLabel}
              control={
                <StyledRadio
                  description={description}
                  label={label}
                  subtitle={subtitle}
                  icon={icon}
                  dataTestId={fieldValue}
                  value={fieldValue}
                  disabled={false}
                />
              }
              key={description}
              value={fieldValue}
            />
          </Grid>
        );
      }
    );
  };

  return (
    <Box display="flex">
      <RadioGroup
        name="pickupPeriodTime"
        className={classes.formGroup}
        defaultValue={initialValue}
        onChange={handleChange}
        row
      >
        <Grid container spacing={2}>
          {renderItems()}
        </Grid>
      </RadioGroup>
    </Box>
  );
};

export default PeriodSection;
