const CORREIOS_TRACKING_URL =
  'https://www2.correios.com.br/sistemas/rastreamento/ctrl/ctrlRastreamento.cfm?';

const USER_ACCESS_COMPANIES_LOCAL_STORAGE_KEY =
  'loggione_user_access_companies';

const PACKAGE_RETURNED_DATE = 'packageReturnedDate';

const PACKAGE_DELIVERED_DATE = 'packageDeliveredDate';

const PACKAGE_RETURN_PROMISED_DATE = 'packageReturnPromisedDate';

const PACKAGE_DELIVERY_PROMISED_DATE = 'packageDeliveryPromisedDate';

const PACKAGE_WAITING_REDISPATCH_PICKUP_DATE =
  'packageWaitingRedispatchPickupDate';

export const endpoints = {
  updateWarehouse: (companyId, warehouseId) =>
    `/empresas/${companyId}/configurar/enderecos/${warehouseId}/devolucao/editar`
};

export {
  CORREIOS_TRACKING_URL,
  USER_ACCESS_COMPANIES_LOCAL_STORAGE_KEY,
  PACKAGE_RETURNED_DATE,
  PACKAGE_DELIVERED_DATE,
  PACKAGE_RETURN_PROMISED_DATE,
  PACKAGE_DELIVERY_PROMISED_DATE,
  PACKAGE_WAITING_REDISPATCH_PICKUP_DATE
};
