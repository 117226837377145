import { useState, useCallback } from 'react';

/* Error boundary does not work for async failures,
 being necessary to return errors in a useCallBack hook
*/
const useAsyncError = () => {
  const [, setError] = useState();
  return useCallback(e => {
    setError(() => {
      throw e;
    });
  }, []);
};

export default useAsyncError;
