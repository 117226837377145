import React from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import useFilter from '@loggi/components/src/one/use-filter/useFilter';
import { useTranslation } from 'react-i18next';
import PackageSelectionDateFilter from './packages-selection-date-filter.component';
import PackageSearchBar from './packages-selection-text-search-filter.component';
import CreatePackageNavigation from '../package-selection-create-package-toogle-button.component';

const PackageSelectionFilters = () => {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const setFilter = useFilter();
  const { t } = useTranslation('unifiedPickup');
  const { spacing } = useTheme();

  const onChangeFilter = filters => {
    setFilter(filters, true);
  };

  return (
    <Box py={4} px={5} display="flex" data-testid="package-selection-filters">
      <Grid container spacing={3} wrap={isDesktop ? 'nowrap' : 'wrap'}>
        <Grid item xs={12} md={5}>
          <PackageSearchBar onChange={onChangeFilter} />
        </Grid>
        <Grid item xs={12} md={3}>
          <PackageSelectionDateFilter onChange={onChangeFilter} />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <Box width={{ xs: '100%', md: spacing(32) }}>
            <CreatePackageNavigation
              buttonText={t('packageSelection.addPackageButton.text')}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PackageSelectionFilters;
