import {
  Box,
  Drawer,
  IconButton,
  makeStyles,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  useTheme
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { ArrowBack } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ palette, typography }) => ({
  ul: {
    display: 'list-item',
    '&::marker': {
      fontSize: typography.subtitle1.fontSize,
      color: palette.primary.main
    },
    padding: '0 0 0 .5rem',
    listStyle: 'disc',
    marginLeft: '20px'
  }
}));

const RecurringPickupDrawerExplain = () => {
  const { t } = useTranslation('orderScheduling');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const styles = useStyles();
  const { spacing } = useTheme();

  const listItems = t('create.sectionExplainScheduling.bulletsPoints', {
    returnObjects: true
  });

  const renderItems = () => {
    let i = 0;
    return listItems.map(item => {
      i += 1;
      return (
        <ListItem className={styles.ul} key={i}>
          <ListItemText
            style={{ marginBottom: spacing(3) }}
            primary={
              <Typography variant="body1" style={{ lineHeight: '22px' }}>
                {item}
              </Typography>
            }
          />
        </ListItem>
      );
    });
  };

  return (
    <>
      <Typography variant="body2" color="textSecondary">
        {t('create.sectionExplainScheduling.text')}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          variant="body2"
          style={{ cursor: 'pointer' }}
          onClick={() => setDrawerOpen(true)}
        >
          {t('create.sectionExplainScheduling.linkDrawerHelpAction')}
        </Link>
      </Typography>

      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchor="right"
      >
        <Box
          display="flex"
          flexDirection="column"
          height={{ xs: 'auto', sm: '100%' }}
          width={{ xs: '100%', sm: 450 }}
          pt={3}
          px={5}
        >
          <Box mb={3}>
            <IconButton
              data-testid="close-drawer"
              edge="start"
              onClick={() => setDrawerOpen(false)}
            >
              <ArrowBack color="primary" />
            </IconButton>
          </Box>
          <Box mb={3.5}>
            <Typography variant="h6">
              <Box component="strong" fontWeight="fontWeightBold">
                {t('create.sectionExplainScheduling.title')}
              </Box>
            </Typography>
          </Box>
          <List>
            <Box display="flex" flexDirection="column">
              {renderItems()}
            </Box>
          </List>
          <Box mt={1}>
            <Alert
              variant="outlined"
              severity="info"
              style={{
                padding: '.5rem 0 .5rem 1rem',
                marginBottom: spacing(2)
              }}
              icon={
                <InfoIcon style={{ color: useTheme().palette.info.dark }} />
              }
            >
              <AlertTitle
                style={{
                  color: useTheme().palette.info.dark,
                  fontWeight: '700'
                }}
              >
                {t('create.sectionExplainScheduling.alertTitle')}
              </AlertTitle>
              <Typography variant="body2">
                {t('create.sectionExplainScheduling.alertContent')}
              </Typography>
            </Alert>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default RecurringPickupDrawerExplain;
