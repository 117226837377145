import { ReactComponent as ShaftlessArrowDown } from '@loggi/components/src/one/shared/images/shaftless-arrow-down.svg';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ORDER_BY_VALUES, LIST_TYPE } from '../../constants';
import { filterMenuProps } from './menu-styles-shapes';
import useFilter from '../../useFilter';

const parseFilter = filter => (filter?.length ? filter.split(',') : []);

const FilterOrder = ({ menuStyles }) => {
  const [filter, setFilter] = useFilter('orderBy', LIST_TYPE.SCHEDULINGS);
  const [orderBy, setOrderBy] = useState(parseFilter(filter));
  const { t } = useTranslation('scheduling');

  useEffect(() => setOrderBy(parseFilter(filter)), [filter]);

  const ORDER_BY_LABELS = {
    [ORDER_BY_VALUES.CREATED]: t('filters.orderLabels.created'),
    [ORDER_BY_VALUES.STATUS]: t('filters.orderLabels.status')
  };

  const renderSelectedLabel = statuses =>
    statuses.map(s => ORDER_BY_LABELS[s]).join(', ');

  const ORDER_BY = [
    {
      name: ORDER_BY_LABELS[ORDER_BY_VALUES.CREATED],
      value: ORDER_BY_VALUES.CREATED
    },
    {
      name: ORDER_BY_LABELS[ORDER_BY_VALUES.STATUS],
      value: ORDER_BY_VALUES.STATUS
    }
  ];

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{t('filters.orderLabels.text')}</InputLabel>
      <Select
        input={<OutlinedInput label={t('filters.orderLabels.text')} />}
        inputProps={{
          'data-testid': 'order-by-select'
        }}
        multiple
        onChange={event => setOrderBy(event.target.value)}
        onClose={() => setFilter(orderBy)}
        renderValue={renderSelectedLabel}
        value={orderBy}
        MenuProps={menuStyles}
        IconComponent={ShaftlessArrowDown}
      >
        {ORDER_BY.map(({ name, value }) => {
          const isChecked = Boolean(
            orderBy.find(filterValue => filterValue === value)
          );
          return (
            <MenuItem key={name} value={value}>
              <Checkbox checked={isChecked} color="primary" />
              <ListItemText primary={name} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

FilterOrder.propTypes = PropTypes.shape(filterMenuProps).isRequired;

FilterOrder.defaultProps = {
  menuStyles: null
};

export default FilterOrder;
