import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { useCheckCompanyStatus } from '@loggi/components/src/one/hooks';

const RecurringPickupListBlankState = () => {
  const { t } = useTranslation('scheduling');
  const { url } = useRouteMatch();
  const { isDefaulter } = useCheckCompanyStatus();

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      pt={5}
      pb={6.25}
      textAlign="center"
    >
      <Box maxWidth={351} mb={3}>
        <Typography variant="body2" color="textSecondary">
          {t('index.blankState.description')}
        </Typography>
      </Box>
      <Button
        color="primary"
        component={Link}
        to={`${url}/adicionar`}
        variant="text"
        disabled={isDefaulter}
      >
        {t('index.blankState.cta')}
      </Button>
    </Box>
  );
};

export default RecurringPickupListBlankState;
