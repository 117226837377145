import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from '@loggi/components/src/one/hooks';

// MATERIAL-UI
import TablePagination from '@material-ui/core/TablePagination';

import { DEFAULT_PAGE_SIZE } from '../../../details/constants';

/**
 * This component's responsibilities are to show the pagination info, and allow
 * the user to navigate to others pages with the HistoryApi provided by react-router-dom
 * @componentType container
 * @param count
 * @param isLoading
 * @returns {null | *}
 * @constructor
 */
const SchedulingListPagination = ({ count, isLoading }) => {
  const { t } = useTranslation('details');
  const queryParams = useQueryParams();
  const { page = 1, pageSize = DEFAULT_PAGE_SIZE } = queryParams;

  const history = useHistory();
  const onChangePage = useCallback(
    (_, nextPage) => {
      const params = { ...queryParams, page: nextPage + 1 };
      history.push(
        // Encoding should be off because we want a "comma" separator in the URL.
        // We will encode later, when sending the request.
        qs.stringify(params, { addQueryPrefix: true, encode: false })
      );
    },
    [history, queryParams]
  );

  // There's no pagination if there are no items.
  if (typeof count !== 'number' || count < 1) {
    return null;
  }

  return (
    <TablePagination
      component="div"
      onChangePage={onChangePage}
      page={Number(page) - 1} // Prop starts at 0
      count={count}
      labelDisplayedRows={({ from, to, count: total }) =>
        t('pagination.row', { from, to, count: total })
      }
      rowsPerPage={Number(pageSize)}
      rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
      nextIconButtonProps={{ disabled: isLoading || page * pageSize >= count }}
      backIconButtonProps={{ disabled: isLoading || Number(page) === 1 }}
    />
  );
};

SchedulingListPagination.propTypes = {
  count: PropTypes.number.isRequired,
  isLoading: PropTypes.bool
};

SchedulingListPagination.defaultProps = {
  isLoading: false
};

export default SchedulingListPagination;
