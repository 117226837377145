import Toast from '@loggi/components/src/one/toast';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import packagesApi from './packages-api';

/**
 * usePackageDownloadLabel: This hook is responsible for requesting and downloading
 * a Package PDF label, though our authenticate API, and then mimicking the download
 * action with the mimicDownloadClick method, as if the user had just clicked on
 * an anchor with a direct file URL.
 * @param companyId
 * @param trackingKey - used to append the key on the downloaded file name
 * @param barcode - used to request to our API the Package PDF label
 * @returns {(...args: any[]) => any} - it returns a function that trigger the API request
 * downloading the blob file, and then calls the mimicDownloadClick. On sub-sequential
 * calls, it uses the blobUrl already saved on the local state instead of calling
 * again the API.
 */
const usePackageDownloadLabel = (companyId, trackingKey, barcode) => {
  const { t } = useTranslation('packages');
  const { enqueueSnackbar } = useSnackbar();
  const [blobUrl, setBlobUrl] = useState('');

  const mimicDownloadClick = useCallback(
    url => {
      const a = document.createElement('a');
      a.style = 'display: none';
      a.href = url;
      a.download = t('create.packageSuccess.label', { trackingKey });

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    [t, trackingKey]
  );

  const revokeURL = useCallback(() => {
    if (blobUrl.length) URL.revokeObjectURL(blobUrl);
  }, [blobUrl]);

  // with this whenever this hook is unmount or a new objectURL is created
  // the current one is revoked
  useEffect(() => revokeURL, [revokeURL]);

  return useCallback(() => {
    if (blobUrl) {
      mimicDownloadClick(blobUrl);
    } else {
      packagesApi
        .getLabel(companyId, barcode)
        .blob(blob => {
          const url = URL.createObjectURL(blob);

          setBlobUrl(url);
          mimicDownloadClick(url);
        })
        .catch(() => {
          enqueueSnackbar(t('create.packageSuccess.errorDownloadingLabel'), {
            content: (key, message) => (
              <Toast id={key} message={message} type="error" />
            )
          });
        });
    }
  }, [blobUrl, companyId, enqueueSnackbar, mimicDownloadClick, barcode, t]);
};

export default usePackageDownloadLabel;
