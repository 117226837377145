import React from 'react';
import {
  AddressField,
  ComplementField,
  NoComplementField
} from '@loggi/components/src/one/formik-fields';
import { getOriginCoverageParams } from '@loggi/components/src/one/address';
import { useFeature } from '@loggi/components/src/one/remote-config';
import { Box, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SectionTitle from './section-title.component';

const AddressSection = () => {
  const { t } = useTranslation('unifiedPickup');
  const complementFieldName = 'pickupAddressComplement';
  const enableOriginValidationRecurringPickup = useFeature(
    'enable_address_validation_in_recurring_pickup_app'
  );

  return (
    <>
      <SectionTitle>{t('addressSection.title')}</SectionTitle>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AddressField
            fieldName="pickupAddress"
            label={t('addressSection.label')}
            validate={
              enableOriginValidationRecurringPickup
                ? getOriginCoverageParams
                : null
            }
            cleanFieldsOnError={[]}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <ComplementField fieldName={complementFieldName} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box mt={1}>
            <NoComplementField complementFieldName={complementFieldName} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AddressSection;
