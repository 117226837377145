import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  OneTemplateContent,
  OneTemplate,
  OneTemplateSummary
} from '@loggi/components';
import { Box, Typography, Button } from '@material-ui/core';
import { ReactComponent as GenericErrorIcon } from '../../assets/generic-error-icon.svg';

const GenericError = () => {
  const { t } = useTranslation('pickupOrderSchedulePackages');

  return (
    <>
      <OneTemplate>
        <OneTemplateSummary />
        <OneTemplateContent>
          <Box pt={8} textAlign="center" px={{ xs: 3, sm: 8 }}>
            <GenericErrorIcon />
            <Box pt={2}>
              <Typography variant="subtitle2">
                <strong>{t('error.title')}</strong>
              </Typography>
            </Box>
            <Box pt={1}>
              <Typography variant="subtitle2" align="center">
                {t('error.message')}
              </Typography>
            </Box>
          </Box>
          <Box pt={5} px={1}>
            <Button
              color="primary"
              data-testid="reset-button"
              fullWidth
              size="small"
              variant="outlined"
              onClick={() => {
                window.location.reload();
              }}
            >
              {t('error.button.text')}
            </Button>
          </Box>
        </OneTemplateContent>
      </OneTemplate>
    </>
  );
};

export default GenericError;
