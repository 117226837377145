import { loggiWebApi } from '@loggi/authentication-lib';
import {
  BASE_ENDPOINT,
  REVOKE_ENDPOINT,
  DELETE_ENDPOINT,
  USERS_ENDPOINT
} from './constants';

const revokeUser = (accountId, userId) => {
  return loggiWebApi
    .url(
      `/${BASE_ENDPOINT}/${accountId}/${USERS_ENDPOINT}/${userId}/${REVOKE_ENDPOINT}/`
    )
    .headers({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    })
    .patch()
    .json();
};

const deleteUser = (accountId, userId) => {
  return loggiWebApi
    .url(
      `/${BASE_ENDPOINT}/${accountId}/${USERS_ENDPOINT}/${userId}/${DELETE_ENDPOINT}/`
    )
    .headers({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    })
    .delete()
    .json();
};

export { deleteUser, revokeUser };
