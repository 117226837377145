import { useAmplifyAuth } from '@loggi/authentication-lib';
import { useRemoteConfig } from '@loggi/components/src/one/remote-config';

const useCurrentUser = () => {
  const { value: enableCustomAccessLoggiEnvios } = useRemoteConfig(
    'enable_custom_access_loggi_envios'
  );
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  if (!authenticatedUser) return null;

  let userAccess = JSON.parse(
    authenticatedUser['custom:access'] ||
      authenticatedUser?.challengeParam?.userAttributes['custom:access'] ||
      null
  );

  if (enableCustomAccessLoggiEnvios === 'true') {
    const userAccessEnvios =
      JSON.parse(
        authenticatedUser['custom:accessLoggiEnvios'] ||
          authenticatedUser?.challengeParam?.userAttributes[
            'custom:accessLoggiEnvios'
          ] ||
          null
      ) || userAccess;
    userAccess = userAccessEnvios;
  }
  const companyAccess = userAccess?.companies || [];

  return { ...authenticatedUser, companyAccess };
};

export default useCurrentUser;
