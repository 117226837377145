import { FormControl, InputAdornment, OutlinedInput } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useFilter from '../../useFilter';

const parseFilter = filter => (filter?.length ? filter.split(',') : []);

const normalizeNumericValue = value => value.replace(/[\D]*/g, '');

const DocumentSearchBar = ({ nameField, id, placeholder, listType }) => {
  const [filter = '', setFilter] = useFilter(nameField, listType);
  const [value, setValue] = useState(parseFilter(filter));
  const [clearPagination, setClearPagination] = useState(false);
  const debounceDelayInMs = 600;

  useEffect(() => setValue(parseFilter(filter)), [filter]);

  useEffect(() => {
    const debounceId = setTimeout(() => {
      setFilter(value, clearPagination);
    }, debounceDelayInMs);
    return () => clearTimeout(debounceId);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          id={id}
          value={value}
          onChange={event => {
            setClearPagination(true);
            setValue(normalizeNumericValue(event.target.value));
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon color="primary" />
            </InputAdornment>
          }
          placeholder={placeholder}
          fullWidth
        />
      </FormControl>
    </>
  );
};

DocumentSearchBar.propTypes = {
  nameField: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  listType: PropTypes.string.isRequired
};

export default DocumentSearchBar;
