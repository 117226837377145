import React from 'react';
import PropTypes from 'prop-types';
import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';
import PackageMovementSummary from './package-movement-summary';
import PackageMovementContent from './package-movement-content';

/**
 * PackageMovementBaseDrawer is a base page to show moviment information of the package.
 * @componentType: Presentational
 * @returns {*}
 */
const PackageMovementBaseDrawer = ({ data, handleClose }) => {
  return (
    <>
      <PackageMovementSummary data={data} />
      <PackageMovementContent data={data} handleClose={handleClose} />
    </>
  );
};

PackageMovementBaseDrawer.propTypes = {
  data: packageProps.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default PackageMovementBaseDrawer;
