import Toast from '@loggi/components/src/one/toast';
import {
  useFeature,
  useRemoteConfig
} from '@loggi/components/src/one/remote-config';
import { dispatchEvent } from '@loggi/components/src/one/useSubscription';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { withStyles, Drawer, useMediaQuery, Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { CoverageParamsContext } from '@loggi/components/src/one/coverage-params';
import recurringPickupApi from './recurring-pickup-api';
import recurringPickupFormShape from './recurring-pickup-form.shape';
import { createOrderSchedules } from '../order-scheduling/create/order-scheduling-form.service';
import {
  formatFormValuesForOrderSchedules,
  removeCnpjMask
} from './unified-pickup-helpers';
import UnifiedPickupFormFields from './unified-pickup-form-fields';
import RequirementsModal from './requirements-modal.component';
import { TRANSPORT_TYPES } from '../order-scheduling/create/constants';
import { NOT_RECURRING, IS_RECURRING } from './constants';
import { SELECTED_PACKAGES_KEY } from './package-selection/constants';
import getSelectedPackages from './package-selection/get-selected-packages.component';
import { UNIFIED_PICKUP_FORM_STORAGE_KEY } from './formik-storage-watcher.component';

export const pickupUrl = (companyId, id) =>
  `/empresas/${companyId}/coletas/${id}`;

const StyledDrawer = withStyles(({ spacing }) => ({
  paperAnchorBottom: {
    borderRadius: spacing(2, 2, 0, 0)
  }
}))(Drawer);

const UnifiedPickupCreateForm = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation(['unifiedPickup', 'orderScheduling']);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { companyId } = useParams();
  const { push } = useHistory();
  const shouldUsePickupsApi = useFeature('should_use_pickups_api');
  const [openDialog, setOpenDialog] = useState(false);
  const [savedFormValues, setSavedFormValues] = useState({});
  const isMobile = useMediaQuery(theme => theme.breakpoints.only('xs'));
  const selectedPackages = getSelectedPackages();

  const [context, setContext] = useState({
    coverageParams: {},
    setCoverageParams: coverageParams => {
      setContext(state => ({ ...state, coverageParams }));
    }
  });

  const buildToast = (key, type, message) => {
    return <Toast id={key} message={message} type={type} />;
  };

  const snackBarMessage = useCallback(
    (barMessage, type) =>
      enqueueSnackbar(barMessage, {
        content: (key, message) => buildToast(key, type, message)
      }),
    [enqueueSnackbar]
  );

  const { value: isVanRestrictionEnabled } = useRemoteConfig(
    'enable_van_restriction'
  );

  const cleanPickupDateInLocalStorage = () => {
    const storedFormData = JSON.parse(
      localStorage.getItem(UNIFIED_PICKUP_FORM_STORAGE_KEY)
    );

    if (storedFormData?.pickupDate) {
      delete storedFormData.pickupDate;
      localStorage.setItem(
        UNIFIED_PICKUP_FORM_STORAGE_KEY,
        JSON.stringify(storedFormData)
      );
    }
  };

  const handleRecurringPickup = useCallback(
    formValues => {
      const requestParams = formValues;
      requestParams.document = removeCnpjMask(requestParams?.cnpjOrCpf);
      recurringPickupApi
        .create({ companyId, data: requestParams, shouldUsePickupsApi })
        .then(() => {
          onSubmit(true);
          cleanPickupDateInLocalStorage();
          snackBarMessage(t('unifiedPickup:success.title'), 'success');
        })
        .catch(error => {
          snackBarMessage(t(`unifiedPickup:${error.message}`), 'error');
        })
        .finally(() => setLoading(false));
    },
    [companyId, onSubmit, shouldUsePickupsApi, t, snackBarMessage]
  );

  const handleOrderScheduling = useCallback(
    formValues => {
      let requestParams = formValues;
      if (isVanRestrictionEnabled === 'true') {
        requestParams.modalRestrictions = [TRANSPORT_TYPES.VAN];
      }

      requestParams = formatFormValuesForOrderSchedules(
        requestParams,
        selectedPackages
      );
      createOrderSchedules(requestParams, companyId)
        .then(response => {
          snackBarMessage(
            t('orderScheduling:create.successMessages.pickupCreated'),
            'success'
          );
          dispatchEvent('order-schedule:created', response.id);
          cleanPickupDateInLocalStorage();
          localStorage.removeItem(SELECTED_PACKAGES_KEY);
          push(pickupUrl(companyId, response.id), {
            from: 'new-order-scheduling',
            pickup: response
          });
        })
        .catch(error =>
          snackBarMessage(t(`orderScheduling:${error.message}`), 'error')
        )
        .finally(() => setLoading(false));
    },
    [
      isVanRestrictionEnabled,
      companyId,
      push,
      snackBarMessage,
      t,
      selectedPackages
    ]
  );

  const handleSubmit = useCallback(() => {
    setOpenDialog(false);
    setLoading(true);
    if (savedFormValues.pickupIsRecurring === IS_RECURRING) {
      handleRecurringPickup(savedFormValues);
    } else if (savedFormValues.pickupIsRecurring === NOT_RECURRING) {
      handleOrderScheduling(savedFormValues);
    }
  }, [handleRecurringPickup, handleOrderScheduling, savedFormValues]);

  const openModalAndSaveValues = formValues => {
    setSavedFormValues(formValues);
    setOpenDialog(true);
  };

  return (
    <CoverageParamsContext.Provider value={context}>
      <Formik
        initialValues={initialValues}
        // 'validateOnMount' is needed because we want the form
        // to be invalid on initial render, so the submit button
        // is disabled. The date fields also need this to work
        // properly.
        validateOnMount
        onSubmit={openModalAndSaveValues}
      >
        {({ isValid }) => (
          <>
            <UnifiedPickupFormFields
              isValid={isValid}
              isLoading={loading}
              pageTitle={t('unifiedPickup:newSubtitle')}
              submitLabelButton={t(
                'orderScheduling:create.schedulePickupButton'
              )}
            />
            {isMobile ? (
              <StyledDrawer
                anchor="bottom"
                data-testid="drawer"
                onClose={() => setOpenDialog(false)}
                open={openDialog}
              >
                <RequirementsModal
                  data-testid="requirements-modal"
                  onConfirm={handleSubmit}
                />
              </StyledDrawer>
            ) : (
              <Dialog
                data-testid="dialog"
                onClose={() => setOpenDialog(false)}
                open={openDialog}
              >
                <RequirementsModal
                  data-testid="requirements-modal"
                  onConfirm={handleSubmit}
                />
              </Dialog>
            )}
          </>
        )}
      </Formik>
    </CoverageParamsContext.Provider>
  );
};

UnifiedPickupCreateForm.propTypes = {
  initialValues: PropTypes.shape(recurringPickupFormShape),
  onSubmit: PropTypes.func.isRequired
};

UnifiedPickupCreateForm.defaultProps = {
  initialValues: {}
};

export default UnifiedPickupCreateForm;
