import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';

import OneTemplate, {
  OneTemplateSummary,
  OneTemplateContent
} from '@loggi/components/src/one/template';
import { PackageFactory } from '@loggi/components/src/one/package-utils';
import fetchTrackingApi from '../tracking-api';

import PackageRecipientEditionContent from './package-recipient-edition-content.component';
import PackageRecipientEditionError from './package-recipient-edition-error.component';

const initialState = {
  isLoading: true,
  data: null,
  error: false
};

const PackageRecipientEdition = () => {
  const { t } = useTranslation('packages');
  const [{ data, isLoading, error }, setState] = useState(initialState);
  const { companyId, trackingKey } = useParams();

  useEffect(() => {
    fetchTrackingApi
      .details(companyId, [trackingKey])
      .then(response => {
        return PackageFactory.trackingAPI.create(response.packages[0]);
      })
      .then(pkg => setState({ isLoading: false, data: pkg, error: false }))
      .catch(() => setState({ isLoading: false, data: null, error: true }));
  }, [companyId, trackingKey]);

  const showContent = !error;
  const showError = error;

  return (
    <>
      <OneTemplate>
        <OneTemplateSummary>
          <Box display="flex">
            <Box pl={1.5}>
              <Typography variant="h4">
                <strong>{t('edition.title')}</strong>
              </Typography>
            </Box>
          </Box>
        </OneTemplateSummary>
        <OneTemplateContent>
          {showContent && (
            <PackageRecipientEditionContent isLoading={isLoading} data={data} />
          )}
          {showError && <PackageRecipientEditionError />}
        </OneTemplateContent>
      </OneTemplate>
    </>
  );
};

export default PackageRecipientEdition;
