import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Typography,
  FormControl,
  OutlinedInput
} from '@material-ui/core';
import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import Toast from '@loggi/components/src/one/toast';
import {
  PICKUP_RECEIPT_ROUTE,
  PICKUP_RECEIPT_AUTH_ROUTE
} from '../routes/constants';
import sendShipperFeedbackApi from '../operations/pickup-receipts/send-shipper-feedback-api';
import BackButton from './back-button.component';

const PickupReceiptFeedback = () => {
  const { t } = useTranslation('pickupReceiptFeedback');
  const history = useHistory();
  const { pickupOrderScheduleHash, pickupReceiptId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [feedback, setFeedback] = useState('');

  const handleChange = event => {
    setFeedback(event.target.value);
  };

  const sendFeedback = event => {
    event.preventDefault();
    sendShipperFeedbackApi
      .send(pickupOrderScheduleHash, pickupReceiptId, false, feedback)
      .forbidden(() => {
        history.push(PICKUP_RECEIPT_AUTH_ROUTE.url(pickupOrderScheduleHash));
      })
      .res(() => {
        history.push({
          pathname: PICKUP_RECEIPT_ROUTE.url(pickupOrderScheduleHash),
          state: {
            notConfirmedPickupReceiptId: pickupReceiptId
          }
        });
      })
      .catch(() => {
        enqueueSnackbar(t('errorsMessages.unexpectedError'), {
          content: (key, message) => (
            <Toast id={key} message={message} type="error" />
          )
        });
      });
  };

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Box display="flex">
          <Box mr={2} display="flex">
            <BackButton
              backToUrl={PICKUP_RECEIPT_ROUTE.url(pickupOrderScheduleHash)}
            />
          </Box>
          <Box display="flex">
            <Typography variant="h6" component="h1">
              {t('header.message')}
            </Typography>
          </Box>
        </Box>
      </OneTemplateSummary>
      <OneTemplateContent>
        <Box display="flex" justifyContent="center">
          <FormControl variant="outlined" fullWidth margin="normal">
            <OutlinedInput
              multiline
              rows={5}
              onChange={handleChange}
              value={feedback}
              placeholder={t('feedbackField.label')}
            />
          </FormControl>
        </Box>
        <Box pt={2}>
          <Button
            color="primary"
            fullWidth
            size="small"
            type="submit"
            variant="contained"
            onClick={sendFeedback}
          >
            {t('sendFeedback.text')}
          </Button>
        </Box>
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default PickupReceiptFeedback;
