import { Box, Typography, IconButton, Link } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Toast from '@loggi/components/src/one/toast';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Error } from '@material-ui/icons';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import { useParams } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';
import { codeIntegratorStyles } from '../styles';
import integratorListApi from '../services/integrator-api';

const CodeIntegrator = ({ integrator, integratorEnabled }) => {
  const { t } = useTranslation('management');
  const { enqueueSnackbar } = useSnackbar();
  const { companyId } = useParams();
  const styles = codeIntegratorStyles();
  const [code, setCode] = useState();
  const integratorName = t(`${integrator}.name`);

  useEffect(() => {
    integratorListApi
      .integratorCode(companyId, { integrator_name: integratorName })
      .then(response => {
        setCode(response?.uuid);
      })
      .catch(error => {
        enqueueSnackbar(error.message, {
          content: (key, message) => (
            <Toast id={key} message={message} type="error" />
          )
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, enqueueSnackbar, t]);

  const copyToClipboard = () => navigator.clipboard.writeText(code);
  const instructions = t(`${integrator}.codeIntegrator.instructions`, {
    returnObjects: true
  });

  const reminderInstructions = t(
    `${integrator}.codeIntegrator.reminderInstructions`,
    {
      returnObjects: true
    }
  );
  const registration = t(`${integrator}.codeIntegrator.registration`, {
    returnObjects: true
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={{ sm: 3 }}
      sx={{ width: 900 }}
    >
      <Box>
        {integratorEnabled && (
          <Box mb={2} ml={-2}>
            <Alert severity="success" data-testid="enabled-status-alert">
              <AlertTitle>{t('integrations.cards.status.enabled')}</AlertTitle>
              {t('integrations.cards.status.enabledMessage', {
                integratorName
              })}
            </Alert>
          </Box>
        )}
        <Box>
          <Typography variant="h5">
            <strong>{t(`${integrator}.codeIntegrator.title`)}</strong>
          </Typography>
        </Box>
        <Box my={2}>
          <Typography>
            {instructions[0]}
            <strong>{instructions[1]}</strong>
            <Link
              href={t(`${integrator}.link`)}
              target="_blank"
              className={styles.integratorLink}
            >
              {instructions[2]}
            </Link>
            {instructions[3]}
            <strong>{instructions[4]}</strong>
          </Typography>
        </Box>
        {code && (
          <Box className={styles.codeIntegrator}>
            <Typography>{code}</Typography>
            <IconButton
              className={styles.fileCopyIcon}
              data-testid="copyIconButton"
              onClick={copyToClipboard}
            >
              <FileCopyRoundedIcon className={styles.copyIcon} />
            </IconButton>
          </Box>
        )}
        <Box>
          <Typography>
            {registration[0]}
            <strong>{registration[1]}</strong>
            {registration[2]}
          </Typography>
        </Box>
        <Box mt={2}>
          <Alert
            className={styles.alertMessage}
            icon={<Error className={styles.subtitleIcon} fontSize="small" />}
            severity="error"
          >
            <AlertTitle>
              {t(`${integrator}.codeIntegrator.reminder`)}
            </AlertTitle>
            {reminderInstructions[0]}
            <Link
              href={t(`${integrator}.codeIntegrator.reminderHelpLink`)}
              target="_blank"
              className={styles.integratorLink}
            >
              {reminderInstructions[1]}
            </Link>
            {reminderInstructions[2]}
            <Link
              href={t(`${integrator}.codeIntegrator.reminderFormHelpLink`)}
              target="_blank"
              className={styles.integratorLink}
            >
              {reminderInstructions[3]}
            </Link>
            {reminderInstructions[4]}
          </Alert>
        </Box>
      </Box>
    </Box>
  );
};

CodeIntegrator.propTypes = {
  integrator: PropTypes.string,
  integratorEnabled: PropTypes.bool
};

CodeIntegrator.defaultProps = {
  integrator: 'Default Integrator',
  integratorEnabled: false
};

export default CodeIntegrator;
