import {
  Box,
  ButtonBase,
  Divider,
  ListItem,
  ListItemText,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { StatusIndicatorScheduling } from '../../../details/components/status-indicator';
import SchedulingModel from '../schedulings-list/scheduling-model';
import DateTimeBox from './date-time-box.component';

export const SchedulingsTableItem = ({ pickup, index }) => {
  const history = useHistory();
  const { spacing } = useTheme();
  const { url } = useRouteMatch();

  return (
    <ButtonBase
      component={TableRow}
      key={pickup.id}
      onClick={() => history.push(`${url}/${pickup.id}`)}
      hover
      data-testid={`row-scheduling-${index}`}
    >
      <TableCell>
        <Box ml={2} width={spacing(25)}>
          <StatusIndicatorScheduling status={pickup.status} />
        </Box>
      </TableCell>
      <TableCell>
        <Box width={spacing(37)}>
          <DateTimeBox
            startTime={pickup.start_time}
            endTime={pickup.end_time}
          />
        </Box>
      </TableCell>
      <TableCell>
        <Box overflow="hidden" textOverflow="ellipsis" width={spacing(44)}>
          <Typography variant="caption" color="textSecondary" noWrap>
            {pickup.origin_address.full_address}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box mr={2} width={spacing(11)}>
          <Typography variant="button" color="textSecondary">
            {pickup.id}
          </Typography>
        </Box>
      </TableCell>
    </ButtonBase>
  );
};

SchedulingsTableItem.propTypes = {
  pickup: PropTypes.objectOf(SchedulingModel).isRequired,
  index: PropTypes.number.isRequired
};

export const SchedulingsListItem = ({ pickup, index }) => {
  const { url } = useRouteMatch();

  return (
    <>
      <Divider />
      <>
        <ListItem
          disableGutters
          style={{ width: '100%' }}
          button
          component="a"
          href={`${url}/${pickup.id}`}
          data-testid={`list-item-scheduling-${index}`}
          key={index}
        >
          <ListItemText
            primary={
              <Box px={3} py={1.75} width="100%" textAlign="left">
                <StatusIndicatorScheduling status={pickup.status} />
                <Box pt={1}>
                  <DateTimeBox
                    startTime={pickup.start_time}
                    endTime={pickup.end_time}
                  />
                </Box>
                <Box overflow="hidden" textOverflow="ellipsis" pt={1}>
                  <Typography variant="caption" color="textSecondary">
                    {pickup.origin_address.full_address}
                  </Typography>
                </Box>
                <Box pt={1}>
                  <Typography variant="button" color="textSecondary">
                    {pickup.id}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </ListItem>
      </>
    </>
  );
};

SchedulingsListItem.propTypes = {
  pickup: PropTypes.objectOf(SchedulingModel).isRequired,
  index: PropTypes.number.isRequired
};
