import {
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Chip
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFeature } from '@loggi/components/src/one/remote-config';

import ScreenHeader from './components/screen-header';
import {
  UPDATE_BTN_ID,
  UPDATE_USER_PROGRESS_BUTTON_TEST_ID,
  ROLES
} from './constants';
import UpdateUserDialog from './update-user-dialog';

const useButtonLoadingStyle = makeStyles(({ palette }) => ({
  progress: {
    color: palette.common.white
  }
}));

const UpdateUserForm = ({
  updateUsersListCallback,
  handleClose,
  name,
  email,
  currentRole,
  userStatus
}) => {
  const { t } = useTranslation('inviteUserForm');
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [optionValue, setOptionValue] = useState({});

  const buttonLoadingStyle = useButtonLoadingStyle();

  const userSmb = !currentRole.includes('large');

  const userLarge = !!currentRole.includes('large');

  const updateUserSchema = Yup.object().shape({
    role: Yup.string().required(t('validateRole.required'))
  });

  const onClose = () => {
    setOpenDialog(false);
    setUpdateLoading(false);
    handleClose();
  };

  const onSubmitUserUpdate = values => {
    setOptionValue(values);
    setUpdateLoading(true);
    setOpenDialog(true);
  };
  const handleOnChange = () => {
    setIsValid(true);
  };
  const isCreateSupportUserEnabled = useFeature('enable_support_user_invite');

  return (
    <>
      <Container>
        <Box>
          <Box color="#757D8C" my={1} ml={-1}>
            <Chip
              data-testid="chip-id"
              label={t(`allRolesOptions.options.${currentRole}.label`)}
            />
          </Box>
          <ScreenHeader title={name} subTitle={email} />
        </Box>
        <Formik
          initialValues={{
            role: ROLES[currentRole.toUpperCase()].NAME
          }}
          validationSchema={updateUserSchema}
          onSubmit={onSubmitUserUpdate}
        >
          {({ handleChange, handleSubmit, values }) => (
            <form onSubmit={handleSubmit} onChange={handleOnChange}>
              <Grid
                container
                spacing={2}
                direction="column"
                justify="space-around"
                alignItems="center"
              >
                <Box width="100%" mt={4}>
                  <FormControl component="fieldset">
                    <Typography variant="h5">
                      {t('updateUserOptions')}
                    </Typography>
                    <RadioGroup
                      aria-label="role"
                      name="role"
                      value={values.role}
                      onChange={handleChange}
                    >
                      {userSmb && (
                        <>
                          <Box my={3}>
                            <FormControlLabel
                              value={ROLES.ADMIN.NAME}
                              data-testid={`radio-role-${ROLES.ADMIN.NAME}`}
                              control={<Radio />}
                              label={t('roleInputSmb.options.admin.label')}
                              disabled={currentRole.toUpperCase() === 'ADMIN'}
                            />
                            <Box mx={4}>
                              <Typography variant="caption">
                                {t('roleInputSmb.options.admin.text')}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box my={2}>
                            <FormControlLabel
                              value={ROLES.REGULAR.NAME}
                              data-testid={`radio-role-${ROLES.REGULAR.NAME}`}
                              control={<Radio />}
                              label={t('roleInputSmb.options.regular.label')}
                              disabled={currentRole.toUpperCase() === 'REGULAR'}
                            />
                            <Box mx={4}>
                              <Typography variant="caption">
                                {t('roleInputSmb.options.regular.text')}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box my={2}>
                            <FormControlLabel
                              value={ROLES.OPERATOR.NAME}
                              data-testid={`radio-role-${ROLES.OPERATOR.NAME}`}
                              control={<Radio />}
                              label={t('roleInputSmb.options.operator.label')}
                              disabled={
                                currentRole.toUpperCase() === 'OPERATOR'
                              }
                            />
                            <Box mx={4}>
                              <Typography variant="caption">
                                {t('roleInputSmb.options.operator.text')}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                        </>
                      )}
                      {userLarge && (
                        <>
                          <Box my={3}>
                            <FormControlLabel
                              value={ROLES.ADMIN_LARGE.NAME}
                              data-testid={`radio-role-${
                                ROLES.ADMIN_LARGE.NAME
                              }`}
                              control={<Radio />}
                              label={t(
                                'roleInputLarge.options.adminLarge.label'
                              )}
                              disabled={
                                currentRole.toUpperCase() === 'ADMIN_LARGE'
                              }
                            />
                            <Box mx={4}>
                              <Typography variant="caption">
                                {t('roleInputLarge.options.adminLarge.text')}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box my={2}>
                            <FormControlLabel
                              value={ROLES.MANAGER_LARGE.NAME}
                              data-testid={`radio-role-${
                                ROLES.MANAGER_LARGE.NAME
                              }`}
                              control={<Radio />}
                              label={t(
                                'roleInputLarge.options.managerLarge.label'
                              )}
                              disabled={
                                currentRole.toUpperCase() === 'MANAGER_LARGE'
                              }
                            />
                            <Box mx={4}>
                              <Typography variant="caption">
                                {t('roleInputLarge.options.managerLarge.text')}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box my={2}>
                            <FormControlLabel
                              value={ROLES.OPERATOR_LARGE.NAME}
                              data-testid={`radio-role-${
                                ROLES.OPERATOR_LARGE.NAME
                              }`}
                              control={<Radio />}
                              label={t(
                                'roleInputLarge.options.operatorLarge.label'
                              )}
                              disabled={
                                currentRole.toUpperCase() === 'OPERATOR_LARGE'
                              }
                            />
                            <Box mx={4}>
                              <Typography variant="caption">
                                {t('roleInputLarge.options.operatorLarge.text')}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                        </>
                      )}
                      <Box my={2}>
                        <FormControlLabel
                          value={ROLES.FINANCE.NAME}
                          data-testid={`radio-role-${ROLES.FINANCE.NAME}`}
                          control={<Radio />}
                          label={t('roleInput.options.finance.label')}
                          disabled={currentRole.toUpperCase() === 'FINANCE'}
                        />
                        <Box mx={4}>
                          <Typography variant="caption">
                            {t('roleInput.options.finance.text')}
                          </Typography>
                        </Box>
                      </Box>
                      {isCreateSupportUserEnabled && (
                        <>
                          <Divider />
                          <Box my={2}>
                            <FormControlLabel
                              value={ROLES.SUPPORT.NAME}
                              data-testid={`radio-role-${ROLES.SUPPORT.NAME}`}
                              control={<Radio />}
                              label={t('roleInput.options.support.label')}
                              disabled={currentRole.toUpperCase() === 'SUPPORT'}
                            />
                            <Box mx={4}>
                              <Typography variant="caption">
                                {t('roleInput.options.support.text')}
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box mt={2} width="90%">
                  <Button
                    size="large"
                    color="primary"
                    data-testid={UPDATE_BTN_ID}
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={!isValid || updateLoading}
                  >
                    {!updateLoading && t('updateButton.text')}
                    {updateLoading && (
                      <CircularProgress
                        data-testid={UPDATE_USER_PROGRESS_BUTTON_TEST_ID}
                        size={26}
                        className={buttonLoadingStyle.progress}
                      />
                    )}
                  </Button>
                </Box>
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
      <UpdateUserDialog
        handleClose={onClose}
        name={name}
        open={openDialog}
        email={email}
        values={optionValue}
        currentRole={currentRole}
        newRole={optionValue?.role}
        updateUsersListCallback={updateUsersListCallback}
        userStatus={userStatus}
      />
    </>
  );
};

UpdateUserForm.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  currentRole: PropTypes.string.isRequired,
  updateUsersListCallback: PropTypes.func,
  handleClose: PropTypes.func,
  userStatus: PropTypes.number.isRequired
};

UpdateUserForm.defaultProps = {
  updateUsersListCallback: () => {},
  handleClose: () => {}
};

export default UpdateUserForm;
