function getCurrencyValue(value) {
  const isNegative = value < 0;
  const onlyNumbers = value
    .toFixed(2)
    .trim()
    .replace(/[^0-9]/g, '');
  let newValue;
  let formatted = '';

  if (onlyNumbers) {
    let integerPart = onlyNumbers.slice(0, onlyNumbers.length - 2);
    let decimalPart;
    let leadingZeros;

    // remove initial zeros
    integerPart = integerPart.replace(/^0*/g, '');
    // put settings.thousands every 3 chars
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (integerPart === '') {
      integerPart = '0';
    }
    newValue = integerPart;

    decimalPart = onlyNumbers.slice(onlyNumbers.length - 2);
    leadingZeros = new Array(2 + 1 - decimalPart.length).join('0');
    newValue += `,${leadingZeros}${decimalPart}`;
    formatted = `R$ ${newValue}`;
  }

  /* eslint-disable no-new-wrappers */
  const output = new String((isNegative ? '-' : '') + formatted);
  output.raw = newValue;
  output.withCurrency = formatted;
  output.abs = formatted;
  /* eslint-enable no-new-wrappers */

  return output;
}

export default function currency(value = '') {
  if (value === null || typeof value === 'undefined') {
    value = '';
  }

  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  return getCurrencyValue(value);
}
