import Toast from '@loggi/components/src/one/toast';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import {
  CheckCircle,
  Error,
  ThumbDownAltRounded,
  ThumbUpAltRounded
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import sendShipperFeedbackApi from '../../../operations/pickup-receipts/send-shipper-feedback-api';
import {
  PICKUP_RECEIPT_AUTH_ROUTE,
  PICKUP_RECEIPT_FEEDBACK
} from '../../../routes/constants';
import showToast from '../../toast/toast.container';

const PickupReceiptConfirmationButton = (
  buttonText,
  onClickFunction,
  startIcon,
  disabled
) => {
  return (
    <Button
      style={{ justifyContent: 'space-between', marginRight: 20 }}
      onClick={() => {
        onClickFunction();
      }}
      color="primary"
      variant="outlined"
      startIcon={startIcon}
      disabled={disabled}
    >
      {buttonText}
    </Button>
  );
};

const PickupReceiptConfirmation = props => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('pickupReceiptsList');
  const location = useLocation();
  const {
    pickupOrderScheduleHash,
    pickupReceipt,
    pickupReceipts,
    setPickupReceipts
  } = props;
  const [disabled, setDisabled] = useState(false);

  const { push } = useHistory();
  const {
    pickupReceiptId,
    alreadyAnswered,
    shipperConfirmedTotalPackages
  } = pickupReceipt;
  const notConfirmedTotalPackages =
    location.state?.notConfirmedPickupReceiptId === pickupReceiptId;
  const [confirmationToast, setConfirmationToast] = useState({
    display: notConfirmedTotalPackages,
    undoConfirmation: false
  });

  const doNotConfirmTotalPackages = () => {
    push(PICKUP_RECEIPT_FEEDBACK.url(pickupOrderScheduleHash, pickupReceiptId));
  };

  const updateShipperConfirmation = useCallback(
    (undoShipperConfirmation = false) => {
      sendShipperFeedbackApi
        .send(
          pickupOrderScheduleHash,
          pickupReceiptId,
          true,
          '',
          undoShipperConfirmation
        )
        .forbidden(() =>
          push(PICKUP_RECEIPT_AUTH_ROUTE.url(pickupOrderScheduleHash))
        )
        .res(() => {
          setConfirmationToast({
            display: true,
            undoConfirmation: undoShipperConfirmation
          });
        })
        .catch(() => {
          enqueueSnackbar(t('errorsMessages.unexpectedError'), {
            content: (key, message) => (
              <Toast id={key} message={message} type="error" />
            )
          });
        });
    },
    [pickupOrderScheduleHash, pickupReceiptId, enqueueSnackbar, push, t]
  );

  useEffect(() => {
    const updatePickupAnswer = (
      currentAlreadyAnswered = false,
      currentConfirmedTotalPackages = null
    ) => {
      setDisabled(true);
      setPickupReceipts(
        pickupReceipts.map(p => {
          if (p.pickupReceiptId !== pickupReceiptId) {
            return p;
          }

          pickupReceipt.alreadyAnswered = currentAlreadyAnswered;
          pickupReceipt.shipperConfirmedTotalPackages = currentConfirmedTotalPackages;
          return pickupReceipt;
        })
      );
      setDisabled(false);
    };

    if (confirmationToast.display) {
      setConfirmationToast(toast => ({ ...toast, display: false }));

      if (confirmationToast.undoConfirmation) {
        showToast({
          message: t('undoConfirmation.message'),
          enqueueSnackbar
        });
        updatePickupAnswer();
        return;
      }

      showToast({
        message: notConfirmedTotalPackages
          ? t('confirmation.messageNotConfirmed')
          : t('confirmation.messageConfirmed'),
        buttonText: t('confirmation.button.text'),
        onButtonClick: () => updateShipperConfirmation(true),
        durationMilliseconds: 30000,
        enqueueSnackbar
      });
      updatePickupAnswer(true, !notConfirmedTotalPackages);
      window.history.replaceState({}, document.title);
      location.state = {};
    }
  }, [
    confirmationToast,
    pickupReceiptId,
    pickupReceipts,
    setPickupReceipts,
    enqueueSnackbar,
    t,
    location.state,
    updateShipperConfirmation,
    notConfirmedTotalPackages,
    pickupReceipt
  ]);

  const getAlreadyConfirmedComponent = () => {
    let ConfirmationIcon = () => <Error color="disabled" fontSize="small" />;
    let confirmationLabel = t('reportedBeep.label');

    if (shipperConfirmedTotalPackages) {
      ConfirmationIcon = () => (
        <CheckCircle color="disabled" fontSize="small" />
      );
      confirmationLabel = t('confirmedBeep.label');
    }

    return (
      <>
        <Divider />
        <Box display="flex" mt={3} alignItems="center">
          <ConfirmationIcon />
          <Typography
            noWrap
            variant="subtitle2"
            color="textSecondary"
            style={{ marginLeft: '10px' }}
          >
            {confirmationLabel}
          </Typography>
        </Box>
      </>
    );
  };

  const getConfirmationButtonsComponent = () => {
    return (
      <>
        <Divider />
        <Box pt={3}>
          <Typography noWrap variant="subtitle2" color="textPrimary">
            {t('confirmationQuestion.label')}
          </Typography>

          <Box pt={2}>
            {PickupReceiptConfirmationButton(
              t('reportButton.text'),
              doNotConfirmTotalPackages,
              <ThumbDownAltRounded />,
              disabled
            )}
            {PickupReceiptConfirmationButton(
              t('confirmationButton.text'),
              updateShipperConfirmation,
              <ThumbUpAltRounded />,
              disabled
            )}
          </Box>
        </Box>
      </>
    );
  };

  if (alreadyAnswered) {
    return getAlreadyConfirmedComponent();
  }

  return getConfirmationButtonsComponent();
};

export default PickupReceiptConfirmation;
