import { generateZendeskJwtToken } from '@loggi/authentication-lib';
import {
  LOGIN_STORAGE_KEYS,
  HELP_CENTER_TYPEFORM,
  ZENDESK_HELP_CENTER_URL
} from './constants';

export const buildHelpCenterUrl = async (companyId, user, useZendeskUrl) => {
  if (companyId && user && useZendeskUrl) {
    const { token, url } = await generateZendeskJwtToken();
    if (token) return url;
  }

  return useZendeskUrl ? ZENDESK_HELP_CENTER_URL : HELP_CENTER_TYPEFORM;
};

export const redirectToHelpCenter = (companyId, user, useZendeskUrl) =>
  buildHelpCenterUrl(companyId, user, useZendeskUrl).then(resolvedUrl => {
    localStorage.removeItem(LOGIN_STORAGE_KEYS.REDIRECT_SERVICE);
    window.open(resolvedUrl, '_self');
  });

export const formattedCNPJ = cnpj => {
  const regexCnpj = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g;
  const formatOfCnpj = '$1.$2.$3/$4-$5';
  return cnpj.replace(regexCnpj, formatOfCnpj);
};

const replaceCompanyId = (url, companyId, urlRegex) => {
  const isValidCompanyId = companyId && companyId !== '0';
  return isValidCompanyId ? url.replace(urlRegex, '$1'.concat(companyId)) : url;
};

export const substituteCompanyIdOnEnviosUrl = (url, companyId) => {
  const urlRegex = /(\/empresas\/)\d+/g;
  return replaceCompanyId(url, companyId, urlRegex);
};

export const substituteCompanyIdOnAPIUrl = (url, companyId) => {
  const urlRegex = /(\/companies\/)\d+/g;
  return replaceCompanyId(url, companyId, urlRegex);
};
