import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { useFeature } from '@loggi/components/src/one/remote-config';
import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { getManagementRoutes } from '../../app/get-routes';
import MenuItem from './menu-item.component';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  cardPosition: {
    [breakpoints.down('xs')]: {
      marginTop: spacing(3),
      justifyContent: 'center'
    }
  }
}));

const Menu = () => {
  const classes = useStyles();
  const { companyId } = useParams();
  const { t } = useTranslation('management');
  const {
    state: { authenticatedUser, isImpersonation }
  } = useAmplifyAuth();
  const enableWarehousesList = !useFeature('disable_warehouses_list');
  const enableIntegratorList = useFeature('enable_integrator_list');
  const enableCompanyDataManagement = useFeature(
    'enable_company_data_management'
  );
  const managementRoutes = getManagementRoutes(
    authenticatedUser,
    companyId,
    enableWarehousesList,
    enableIntegratorList,
    enableCompanyDataManagement
  );
  const allowedRoutes = managementRoutes.filter(
    ({ visible }) => visible || isImpersonation
  );
  const menuItems = allowedRoutes.filter(routes => routes.menu);

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" component="h1">
            <Box
              alignItems="center"
              component="strong"
              display="flex"
              fontWeight="fontWeightBold"
            >
              {t('menuConfig.title')}
            </Box>
          </Typography>
        </Box>
      </OneTemplateSummary>
      <OneTemplateContent disablePaper>
        <Box
          display="flex"
          flexWrap="wrap"
          width="100%"
          className={classes.cardPosition}
        >
          {menuItems.map(({ id, menu: { Image, label, subtitle, url } }) => (
            <MenuItem
              key={id}
              image={<Image />}
              label={t(label)}
              subtitle={t(subtitle)}
              url={url}
            />
          ))}
        </Box>
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default Menu;
