import React from 'react';
import PropTypes from 'prop-types';

import { List, Divider } from '@material-ui/core';
import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';
import PackagesListItem from './packages-list-item';

/**
 * PackagesList renders a static list that only presents the data it receives
 * @componentType presentational
 * @param data
 * @param handleToggle
 * @param checked
 * @returns {*}
 * @constructor
 */
const PackagesListResponsive = ({
  data,
  handleToggle,
  checked,
  useHierarchySpecifcs
}) => {
  const listItems = () => {
    return data.map((pkg, index) => {
      return (
        <React.Fragment key={pkg.trackingKey}>
          <PackagesListItem
            index={index}
            status={pkg.status}
            recipientName={pkg.recipientName}
            recipientAddress={pkg.destinationAddress}
            trackingKey={pkg.trackingKey}
            companyCnpj={pkg.companyCnpj}
            handleToggle={handleToggle}
            checked={checked}
            useHierarchySpecifcs={useHierarchySpecifcs}
          />
          <Divider component="li" />
        </React.Fragment>
      );
    });
  };

  return <List style={{ width: '100%' }}>{listItems()}</List>;
};

PackagesListResponsive.propTypes = {
  data: PropTypes.arrayOf(packageProps),
  handleToggle: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  useHierarchySpecifcs: PropTypes.bool
};
PackagesListResponsive.defaultProps = {
  data: [],
  useHierarchySpecifcs: false
};

export default PackagesListResponsive;
