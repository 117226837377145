import { object, shape, number, string } from 'prop-types';

export const filterMenuProps = {
  menuStyles: shape({
    PaperProps: shape({
      stytle: shape({
        maxHeight: number,
        marginTop: number,
        maxWidtth: number
      })
    }),
    anchorOrigin: shape({
      vertical: string,
      horizontal: string
    }),
    transformOrigin: shape({
      vertical: string,
      horizontal: string
    }),
    // FIXME: Prop type `object` is forbidden  react/forbid-prop-types
    // eslint-disable-next-line react/forbid-prop-types
    getContentAnchorEl: object
  })
};

const MenuStyles = shape({});

export default MenuStyles;
