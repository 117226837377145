import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../utils';

const PackageDetailsInvoiceSection = ({
  invoiceKey,
  invoiceNumber,
  invoiceValue
}) => {
  const { t } = useTranslation('packages');

  return (
    <>
      <Box
        display={{ sm: 'flex' }}
        style={{ flexWrap: 'wrap', flexDirection: 'row' }}
        pb={1}
      >
        <Box flex="1" pr={{ sm: 2 }} pt={2}>
          <Typography variant="body2">
            <strong>{t('details.labels.invoiceNumber')}</strong>
          </Typography>
          <Typography
            variant="body1"
            style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            {invoiceNumber}
          </Typography>
        </Box>
        <Box flex="2" pr={{ sm: 4 }} pt={2}>
          <Typography variant="body2">
            <strong>{t('details.labels.invoiceKey')}</strong>
          </Typography>
          <Typography
            variant="body1"
            style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            {invoiceKey}
          </Typography>
        </Box>
        <Box flex="1" pr={{ sm: 4 }} pt={2}>
          <Typography variant="body2">
            <strong>{t('details.labels.totalValue')}</strong>
          </Typography>
          <Typography variant="body1">
            {formatCurrency(invoiceValue)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

PackageDetailsInvoiceSection.propTypes = {
  invoiceKey: PropTypes.number.isRequired,
  invoiceNumber: PropTypes.number.isRequired,
  invoiceValue: PropTypes.number.isRequired
};

export default PackageDetailsInvoiceSection;
