const rootPath = '/empresas/:companyId/financeiro';

const paymentsRoutes = {
  index: {
    path: rootPath,
    url: companyId => `/empresas/${companyId}/financeiro`
  },
  invoiceDocuments: {
    path: `${rootPath}/:invoiceLabel/documentos`,
    url: (companyId, invoiceLabel) =>
      `/empresas/${companyId}/financeiro/${invoiceLabel}/documentos`
  },
  priceAgreements: {
    path: `${rootPath}/contratos-de-precos`,
    url: companyId => `/empresas/${companyId}/financeiro/contratos-de-precos`
  },
  priceAgreementsDetails: {
    path: `${rootPath}/contratos-de-precos/detalhes`,
    url: (companyId, agreementId) =>
      `/empresas/${companyId}/financeiro/contratos-de-precos/detalhes/${agreementId}`
  }
};

export default paymentsRoutes;
