import { getI18n } from 'react-i18next';
import { STATUS } from './constants';

const PriceAgreementTitle = (
  status,
  startEffectiveOn,
  endEffectiveOn,
  startHourEffectiveOn
) => {
  const i18n = getI18n();
  const t = i18n.getFixedT(null, 'payments');

  switch (status) {
    case STATUS.ACTIVE:
      if (endEffectiveOn) {
        return t('priceAgreements.priceAgreement.title.activeWithEndDate', {
          endEffectiveOn
        });
      }

      return t('priceAgreements.priceAgreement.title.active');

    case STATUS.INACTIVE:
      return t('priceAgreements.priceAgreement.title.inactive', {
        endEffectiveOn
      });

    default:
      return t('priceAgreements.priceAgreement.title.default', {
        startEffectiveOn,
        startHourEffectiveOn
      });
  }
};

export default PriceAgreementTitle;
