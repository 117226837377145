import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import React, { useCallback } from 'react';

import { invoiceLength } from '../constants';

const invoiceMask = String(9).repeat(invoiceLength);

const InvoiceKeyField = () => {
  const { t } = useTranslation('packages');

  const validate = useCallback(
    newValue => {
      const regex = new RegExp('^[0-9]+$');
      if (!newValue) return t('create.errorMessages.requiredInvoiceKey');
      if (!regex.test(newValue))
        return t('create.errorMessages.invalidInvoiceKey');
      if (newValue.length !== invoiceLength)
        return t('create.errorMessages.invalidInvoiceKeyLength');
      return null;
    },
    [t]
  );

  const [field, meta] = useField({
    name: 'invoiceKey',
    validate
  });

  const { name, onBlur, onChange, value = '' } = field;
  const { error, touched } = meta;
  const hasError = Boolean(error) && touched;

  const LABEL = t('create.invoice.label');

  return (
    <InputMask
      mask={invoiceMask}
      maskChar={null}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
    >
      {(
        // we need the defaultProps to be able to test the Textfield inside the Input Mask
        defaultProps = {
          onChange: null,
          onBlur: null,
          value: null
        }
      ) => (
        <TextField
          error={hasError}
          fullWidth
          helperText={hasError && error}
          id={name}
          inputProps={{ 'data-testid': `${name}-field` }}
          label={LABEL}
          name={name}
          onBlur={defaultProps.onBlur}
          onChange={defaultProps.onChange}
          required
          value={defaultProps.value}
          variant="outlined"
        />
      )}
    </InputMask>
  );
};

export default InvoiceKeyField;
