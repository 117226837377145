import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useMediaQuery,
  Hidden,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  WakePage,
  WakeEditToken,
  WakePermission,
  WakeConfigGeneral,
  WakeIntegrators,
  WakeOrder,
  WakeLogistic,
  WakePrintLabel
} from '../../assets';
import { integratorDescriptionStyles, MENU_PROPS } from '../../styles';

const useStyles = makeStyles(({ typography }) => ({
  list: {
    display: 'list-item',
    '&::marker': {
      fontSize: typography.subtitle2.fontSize
    },
    padding: 0
  },
  images: {
    height: '100%',
    maxHeight: '600px',
    width: 'auto'
  },
  listDecimal: {
    listStyle: 'decimal'
  }
}));

const WakeTutorial = () => {
  const wakeStyles = useStyles();
  const descriptionStyles = integratorDescriptionStyles();
  const { t } = useTranslation('management');
  const smallerDevices = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const description = 'wakeTutorial.description.';

  const wakePageSteps = t(`${description}wakePage.paragraphs`, {
    returnObjects: true
  });
  const loggiPageSteps = t(`${description}loggiPage.paragraphs`, {
    returnObjects: true
  });
  const scheduleSteps = t(`${description}schedule.paragraphs`, {
    returnObjects: true
  });
  const labelSteps = t(`${description}label.paragraphs`, {
    returnObjects: true
  });
  const listQuestionsLinks = t('integrations.questions.links', {
    returnObjects: true
  });
  const menuOptions = t('wakeTutorial.menu.options', {
    returnObjects: true
  });

  return (
    <Box display="flex" flexDirection={smallerDevices ? 'column' : 'row'}>
      <Hidden smDown>
        <Box padding={1} flexGrow={1} flexBasis={0}>
          <MenuList className={descriptionStyles.stickyMenu}>
            <Typography
              variant="h6"
              className={descriptionStyles.titlesMenuLink}
            >
              {t('wakeTutorial.menu.title')}
            </Typography>
            {menuOptions.map(({ id, title }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={index} value={id} mx={2}>
                <Link href={`#${id}`} className={descriptionStyles.titleMenu}>
                  {title}
                </Link>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box padding={1} flexGrow={1} flexBasis={0} mb={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{t('wakeTutorial.menu.title')}</InputLabel>
            <Select label={t('wakeTutorial.menu.title')} MenuProps={MENU_PROPS}>
              {menuOptions.map(({ id, title }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={index} value={id} mx={2}>
                  <Link href={`#${id}`} className={descriptionStyles.titleMenu}>
                    {title}
                  </Link>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Hidden>

      <Box width="inherit" padding={1} flexGrow={2} flexBasis={0}>
        <Box mt={4} mb={2}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="acesso-wake"
          >
            {t(`${description}wakePage.title`)}
          </Typography>
          <Typography variant="subtitle2">
            <Box mt={2}>{t(wakePageSteps[0])}</Box>
            <Box mt={2}>{t(wakePageSteps[1])}</Box>
            <Box mt={2}>{t(wakePageSteps[2])}</Box>
            <Box mt={2}>
              <img
                src={WakePage}
                alt={t(`${description}deliveryServices.deliveryServicePng`)}
                className={wakeStyles.images}
              />
            </Box>
            <Box mt={2}>{t(wakePageSteps[3])}</Box>
            <Box mx={2}>
              <Box mt={2}>{t(wakePageSteps[4])}</Box>
              <Box mt={2}>{t(wakePageSteps[5])}</Box>
              <Box mt={2}>{t(wakePageSteps[6])}</Box>
            </Box>
            <Box mt={2}>
              <img
                src={WakeEditToken}
                alt={t(`${description}deliveryServices.deliveryServicePng`)}
                className={wakeStyles.images}
              />
            </Box>
            <Box mx={2} mt={2}>
              {t(wakePageSteps[7])}
            </Box>
            <Box mt={2}>
              <img
                src={WakePermission}
                alt={t(`${description}deliveryServices.deliveryServicePng`)}
                className={wakeStyles.images}
              />
            </Box>
            <Box mx={2} mt={2}>
              {t(wakePageSteps[8])}
            </Box>
            <Box mt={2}>{t(wakePageSteps[9])}</Box>
          </Typography>
        </Box>

        <Box mt={4} mb={2}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="acesso-loggi"
          >
            {t(`${description}loggiPage.title`)}
          </Typography>
          <Typography variant="subtitle2">
            <Box mt={2}>{t(loggiPageSteps[0])}</Box>
            <Box mt={2}>{t(loggiPageSteps[1])}</Box>
            <Box mt={2}>
              <img
                src={WakeConfigGeneral}
                alt={t(`${description}deliveryServices.deliveryServicePng`)}
                className={wakeStyles.images}
              />
            </Box>
            <Box mt={2}>{t(loggiPageSteps[2])}</Box>
            <Box mt={2}>
              <img
                src={WakeIntegrators}
                alt={t(`${description}deliveryServices.deliveryServicePng`)}
                className={wakeStyles.images}
              />
            </Box>
            <Box mt={2}>{t(loggiPageSteps[3])}</Box>
            <Box mt={2}>{t(loggiPageSteps[4])}</Box>
            <Box mt={2}>{t(loggiPageSteps[5])}</Box>
          </Typography>
        </Box>

        <Box mt={4} mb={2}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="agendamento"
          >
            {t(`${description}schedule.title`)}
          </Typography>
          <Typography variant="subtitle2">
            <Box mt={2}>{t(scheduleSteps[0])}</Box>
            <Box mt={2}>
              <img
                src={WakeOrder}
                alt={t(`${description}deliveryServices.deliveryServicePng`)}
                className={wakeStyles.images}
              />
            </Box>
            <Box mt={2}>{t(scheduleSteps[1])}</Box>
            <Box mt={2}>
              <img
                src={WakeLogistic}
                alt={t(`${description}deliveryServices.deliveryServicePng`)}
                className={wakeStyles.images}
              />
            </Box>
          </Typography>
        </Box>

        <Box mt={4} mb={2}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="imprima-etiquetas"
          >
            {t(`${description}schedule.title`)}
          </Typography>
          <Typography variant="subtitle2">
            <Box mt={2}>{t(labelSteps[0])}</Box>
            <Box mt={2}>{t(labelSteps[1])}</Box>
            <Box>{t(labelSteps[2])}</Box>
            <Box mt={2}>{t(labelSteps[3])}</Box>
            <Box mt={2}>
              <img
                src={WakePrintLabel}
                alt={t(`${description}deliveryServices.deliveryServicePng`)}
                className={wakeStyles.images}
              />
            </Box>
            <Box mt={2}>{t(labelSteps[4])}</Box>
            <Box mt={2}>{t(labelSteps[5])}</Box>
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="duvidas-sobre-integracao"
          >
            {t('integrations.questions.title')}
          </Typography>
          <Box mt={2}>
            <List>
              <Box display="flex" flexDirection="column">
                {listQuestionsLinks.map((el, index) => (
                  <ListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={`${wakeStyles.list} ${
                      descriptionStyles.listDisc
                    }`}
                  >
                    <ListItemText
                      primary={
                        <Typography>
                          <Link
                            href={el.link}
                            target="_blank"
                            className={descriptionStyles.link}
                          >
                            {el.text}
                          </Link>
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </Box>
            </List>
          </Box>
        </Box>
        <Box mt={2}>
          <Typography variant="h6" className={descriptionStyles.titlesMenuLink}>
            {t('integrations.helpLoggi.title')}
          </Typography>
          <Box>
            <Link
              href="https://ajuda.loggi.com/hc/pt-br/requests/new"
              className={descriptionStyles.link}
            >
              {t('integrations.helpLoggi.link')}
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WakeTutorial;
