import { loggiWebApi } from '@loggi/authentication-lib';

export const endpoints = {
  createOrUpdate: (companyId, warehouseId) =>
    `/one/api/addresses/v1/companies/${companyId}/warehouse/${warehouseId}/return-address`,
  warehouses: companyId =>
    `/one/api/addresses/v1/companies/${companyId}/warehouses`
};

const addressesApi = {
  createOrUpdate: (companyId, warehouseId, values) => {
    const url = endpoints.createOrUpdate(companyId, warehouseId);
    const {
      returnAddress,
      returnAddressComplement,
      responsibleName,
      responsiblePhone,
      returnAddressInstructions,
      returnAddressAlias
    } = values;

    const payload = {
      widget_address: {
        address: returnAddress.description,
        complement: returnAddressComplement,
        places_api_info: {
          place_id: returnAddress.place_id
        }
      },
      responsible: {
        full_name: responsibleName,
        phone: responsiblePhone,
        instructions: returnAddressInstructions
      },
      alias_name: returnAddressAlias
    };

    return loggiWebApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .put(payload)
      .res();
  },
  warehouses: companyId => {
    const url = endpoints.warehouses(companyId);

    return loggiWebApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .get()
      .json();
  }
};

export default addressesApi;
