import { colors } from '@loggi/mar';
import { Box, ButtonBase, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import packagesRoutes from '../../../packages-routes';
import SectionTitle from '../../create-section-title';

const InstructionsSection = () => {
  const { t } = useTranslation('packages');
  const { companyId } = useParams();

  return (
    <Box data-testid="spreadsheet-instructions-section">
      <SectionTitle>
        {t('create.spreadsheetFlow.instructionsSection.title')}
      </SectionTitle>
      <Typography variant="body1" gutterBottom>
        {t('create.spreadsheetFlow.instructionsSection.description')}
      </Typography>
      <Box color={colors.blue[500]} fontSize={16} fontWeight="fontWeightBold">
        <ButtonBase
          component={Link}
          to={packagesRoutes.create.spreadsheet.instructions.url(companyId)}
        >
          {t('create.spreadsheetFlow.instructionsSection.linkText')}
        </ButtonBase>
      </Box>
    </Box>
  );
};

export default InstructionsSection;
