import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { differenceInDays, isBefore, subMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import {
  LocalizationProvider,
  StaticDateRangePicker
} from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { Divider, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FILTERS_NAMES, FILTER_DATE_ERROR_NAME } from '../../../constants';
import DatePickerErrorDialog from './DatePickerErrorDialog';
import { formatDate, parseStringToDate, validDate } from './helpers';

const useStyles = makeStyles(theme => ({
  dateRangePicker: {
    '& .MuiPickersDesktopDateRangeCalendar-calendar': {
      minWidth: '332px',
      minHeight: '234px'
    },
    '& .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer:not(:last-child)': {
      borderRight: 'None'
    },
    '& .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer:last-child': {
      [theme.breakpoints.down('sm')]: {
        display: 'None'
      }
    },
    '& .MuiPickersArrowSwitcher-hidden': {
      [theme.breakpoints.down('sm')]: {
        visibility: 'visible'
      }
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '14px',
      fontWeight: '600'
    }
  }
}));

function DateRangePickerComponent({
  initialStartDate,
  initialEndDate,
  onClose,
  onChangeDate
}) {
  const classes = useStyles();
  const INITIAL_DATE_RANGE = [null, null];
  const { t } = useTranslation('packages');
  const initialOpenDialog = false;
  const initialErrorName = FILTER_DATE_ERROR_NAME.DEFAULT;

  const [selectedDate, setSelectedDate] = useState(
    initialStartDate && initialEndDate
      ? [formatDate(initialStartDate), formatDate(initialEndDate)]
      : INITIAL_DATE_RANGE
  );

  const [errorDialogState, setErrorDialogState] = useReducer(
    (currentState, newState) => ({ ...currentState, ...newState }),
    {
      openDialog: initialOpenDialog,
      errorName: initialErrorName
    }
  );

  const handleCloseErrorDialog = () => {
    setErrorDialogState({ openDialog: false });
  };

  const clearCalendar = () => {
    setSelectedDate(INITIAL_DATE_RANGE);
  };

  const validatePeriod = () => {
    const startSelectedDate = parseStringToDate(selectedDate[0]);
    const endSelectedDate = validDate(selectedDate[1])
      ? parseStringToDate(selectedDate[1])
      : startSelectedDate;

    const periodAsDays = differenceInDays(endSelectedDate, startSelectedDate);
    const lastAllowedDate = subMonths(new Date(), 6);

    if (
      isBefore(startSelectedDate, lastAllowedDate) ||
      isBefore(endSelectedDate, lastAllowedDate)
    ) {
      setErrorDialogState({
        errorName: FILTER_DATE_ERROR_NAME.INVALID_PERIOD
      });
      return false;
    }

    if (periodAsDays > 60) {
      setErrorDialogState({
        errorName: FILTER_DATE_ERROR_NAME.OUT_OF_RANGE_PERIOD
      });
      return false;
    }

    return true;
  };

  const saveSelectedDate = () => {
    if (validatePeriod()) {
      const newFilter = Object();
      newFilter[FILTERS_NAMES.START_DATE] = formatDate(selectedDate[0], true);
      newFilter[FILTERS_NAMES.FINAL_DATE] = formatDate(
        selectedDate[1] ? selectedDate[1] : selectedDate[0],
        true
      );
      newFilter[FILTERS_NAMES.DATE] = null;
      onChangeDate(newFilter);
      onClose();
    } else {
      setErrorDialogState({ openDialog: true });
    }
  };

  return (
    <>
      <LocalizationProvider adapterLocale={ptBR} dateAdapter={DateFnsUtils}>
        <Box p={3.5} m={1}>
          <StaticDateRangePicker
            className={classes.dateRangePicker}
            displayStaticWrapperAs="desktop"
            disableFuture
            value={selectedDate}
            onChange={date => setSelectedDate(date)}
          />
        </Box>
        <Divider />
        <Box
          m={2}
          display="flex"
          justifyContent="space-between"
          px={3.5}
          py={2.5}
        >
          <Button onClick={clearCalendar} color="primary">
            {t('filters.date.datePickerDialog.clean')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            data-testid="btn-apply-filters"
            disabled={!validDate(selectedDate[0])}
            onClick={() => saveSelectedDate()}
          >
            {t('filters.date.datePickerDialog.save')}
          </Button>
        </Box>
      </LocalizationProvider>
      <DatePickerErrorDialog
        errorName={errorDialogState.errorName}
        open={errorDialogState.openDialog}
        handleCloseErrorDialog={handleCloseErrorDialog}
      />
    </>
  );
}

DateRangePickerComponent.propTypes = {
  initialEndDate: PropTypes.string,
  initialStartDate: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired
};

DateRangePickerComponent.defaultProps = {
  initialStartDate: null,
  initialEndDate: null
};

export default DateRangePickerComponent;
