import { loggiGenericApi } from '@loggi/authentication-lib';
import { NonWorkingDayType } from '../../unified-pickup/constants';

const listNonWorkingDaysApi = {
  list: ({ startDate, endDate, pickupAddress, pickupAddressComplement }) => {
    const url = '/promiseland/api/v1/list_non_working_days/';

    return loggiGenericApi
      .url(url)
      .accept('application/json')
      .post({
        start_date_time: startDate,
        end_date_time: endDate,
        address: pickupAddress,
        address_complement: pickupAddressComplement || '',
        non_working_day_type: [NonWorkingDayType.PICKUP]
      })
      .json();
  }
};

export default listNonWorkingDaysApi;
