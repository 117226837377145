import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/pt-br';

import translations from './translations';

const i18nInit = () => {
  i18n.use(initReactI18next).init({
    resources: translations,
    lng: 'pt-BR',
    fallbackLng: 'pt-BR'
  });
};
moment.locale('pt-BR');

export default i18nInit;
