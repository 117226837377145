import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFeature } from '@loggi/components/src/one/remote-config';
import { isSameDay } from 'date-fns';
import CreatePickupNavigation from '../create-pickup-navigation';
import NoPackagesToPickupAlert from '../no-packages-to-pickup-alert/no-packages-to-pickup-alert.component';
import showWarningNoPackagesToPickupToday from './fetch-there-are-no-packages-to-pickup-today.request';

const useSchedulingSummaryStyles = makeStyles(
  ({ typography, breakpoints }) => ({
    h1: { fontWeight: typography.fontWeightBold },
    actionCol: {
      [breakpoints.up('sm')]: {
        alignItems: 'center',
        justifyContent: 'flex-end'
      }
    }
  })
);

/**
 * SchedulingSummary's only responsibility is to show the summary info of the
 * schedulings screen, with the params it receives.
 * @componentType: Presentational
 * @returns {*}
 */
const SchedulingSummary = () => {
  const { companyId } = useParams();
  const styles = useSchedulingSummaryStyles();
  const { t } = useTranslation('scheduling');
  const [
    showNoPackagesToPickupAlert,
    setShowNoPackagesToPickupAlert
  ] = useState({});
  const enableWarningNoPackagesToday = useFeature(
    'enable_warning_no_packages_today'
  );
  const checkShowWarningNoPackages = useCallback(() => {
    showWarningNoPackagesToPickupToday(companyId).then(response => {
      if (response.there_are_no_packages_to_pickup !== true) {
        return;
      }

      const now = new Date();
      const cuttingTime = response.cutting_time
        ? new Date(response.cutting_time)
        : null;
      const startTime = response.start_time
        ? new Date(response.start_time)
        : null;
      setShowNoPackagesToPickupAlert({
        // If start_time is in the next day, it means that we're handling with
        // the pickup window time at tomorrow morning
        isNoPackagesToPickupTomorrowMorning: !isSameDay(now, startTime),
        cuttingTime,
        startTime,
        shouldShowAlert: cuttingTime !== null && startTime !== null
      });
    });
  }, [companyId]);

  useEffect(() => {
    if (enableWarningNoPackagesToday) {
      checkShowWarningNoPackages();
    }
  }, [enableWarningNoPackagesToday, checkShowWarningNoPackages]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h4" component="h1" className={styles.h1}>
            {t('summary.title')}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={4} className={styles.actionCol}>
          <CreatePickupNavigation />
        </Grid>
      </Grid>
      {showNoPackagesToPickupAlert.shouldShowAlert && (
        <NoPackagesToPickupAlert
          isNoPackagesToPickupTomorrowMorning={
            showNoPackagesToPickupAlert.isNoPackagesToPickupTomorrowMorning
          }
          cuttingTime={showNoPackagesToPickupAlert.cuttingTime}
          startTime={showNoPackagesToPickupAlert.startTime}
        />
      )}
    </>
  );
};

export default SchedulingSummary;
