import { useRemoteConfig } from '@loggi/components/src/one/remote-config';
import { Box, List, ListItem, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { SCHEDULING_STATUSES } from '../scheduling/constants';
import CancelScheduleButton from './components/cancel-schedule-button';
import SchedulingDetailsVehiclesAmountOverview from './components/scheduling-details-vehicles-amount--overview';
import SchedulingDetailsVolumePackages from './components/scheduling-details-volume-packages';
import DetailsContext from './details-context';
import DownloadPackagesLabelsButton from './download-packages-labels-button.component';

const useStyles = makeStyles({
  listItem: {
    display: 'unset'
  }
});

const SchedulingContent = () => {
  const classes = useStyles();
  const [volume, setVolume] = useState(0);

  const { url } = useRouteMatch();
  const { orderScheduling, pickups } = useContext(DetailsContext);
  const status = orderScheduling?.status;

  const { value: enableDownloadAllPackagesLabels } = useRemoteConfig(
    'enable_download_all_packages_labels'
  );
  const isDownloadAllEnabled = enableDownloadAllPackagesLabels === 'true';

  const hasVolumes = Boolean(volume);

  const showVehiclesAmount =
    status !== SCHEDULING_STATUSES.CANCELLED && pickups.length > 0;
  const showCancelScheduleButton =
    status === SCHEDULING_STATUSES.WAITING_PICKUP;

  const handlePackagesVolumes = count => {
    setVolume(count);
  };

  return (
    <Box key="content" display="flex" flexDirection="column" height="100%">
      <List disablePadding>
        {showVehiclesAmount && (
          <ListItem
            className={classes.listItem}
            button
            disableGutters
            component={Link}
            to={`${url}/veiculos`}
          >
            <SchedulingDetailsVehiclesAmountOverview
              vehiclesAmount={pickups.length}
              orderStatus={status}
            />
          </ListItem>
        )}
        {hasVolumes && (
          <ListItem
            className={classes.listItem}
            button
            disableGutters
            component={Link}
            to={`${url}/pacotes`}
          >
            <SchedulingDetailsVolumePackages count={volume} />
          </ListItem>
        )}
      </List>
      <Box
        display="flex"
        flexWrap="wrap"
        mt={{ xs: 'auto', sm: 0 }}
        justifyContent="flex-end"
        p={{ xs: 3, sm: 5 }}
      >
        {isDownloadAllEnabled && (
          <DownloadPackagesLabelsButton
            handlePackagesVolumes={handlePackagesVolumes}
          />
        )}
        {showCancelScheduleButton && (
          <Box
            ml={{ xs: 0, sm: 3 }}
            mt={{ xs: 3, sm: 0 }}
            width={{ xs: '100%', sm: 'auto' }}
          >
            <CancelScheduleButton />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SchedulingContent;
