import React from 'react';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';

import { Box, Typography } from '@material-ui/core';
import StatusIndicatorPackages from '@loggi/components/src/one/status-indicator';
import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';
import { getFormattedDate, getFormattedDateAndTime } from '../details/utils';

const PackageMovementSummary = ({ data }) => {
  const { t } = useTranslation('packages');
  const {
    promisedDate,
    statusUpdatedTime,
    trackingKey,
    recipientName,
    destinationAddress,
    status
  } = data;

  const isFinalStatus = data.isFinalStatus();

  return (
    <>
      <Box px={4}>
        <Box pt={4} pb={2}>
          <Box display="flex" alignItems="center">
            <Box pr={1}>
              <StatusIndicatorPackages status={status} noLabel />
            </Box>
            <Typography variant="h4">
              <strong>{status}</strong>
            </Typography>
          </Box>
          <Box pl={3}>
            <Typography>
              {t('movementDrawer.lastUpdatedAt')}{' '}
              {getFormattedDateAndTime(parseISO(statusUpdatedTime))}
            </Typography>
          </Box>
        </Box>
        {promisedDate && !isFinalStatus && (
          <Box py={2}>
            <Typography variant="h5">
              {t('movementDrawer.deliveryUntil')}{' '}
              <strong>{getFormattedDate(parseISO(promisedDate))}</strong>
            </Typography>
          </Box>
        )}
        <Box pt={2}>
          <Typography variant="caption" color="textSecondary">
            {t('movementDrawer.package')}
          </Typography>
          <Typography>
            {trackingKey} {t('movementDrawer.packageOf')} {recipientName}
          </Typography>
        </Box>
        <Box pt={2}>
          <Typography variant="caption" color="textSecondary">
            {t('movementDrawer.deliveryAddress')}
          </Typography>
          <Typography>{destinationAddress}</Typography>
        </Box>
      </Box>
    </>
  );
};

PackageMovementSummary.propTypes = {
  data: packageProps.isRequired
};

export default PackageMovementSummary;
