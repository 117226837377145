import React from 'react';
import { Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TRANSPORT_TYPE_MAPPER } from '../../../constants';
import BackButton from '../../back-button.component';
import { PICKUP_RECEIPT_ROUTE } from '../../../routes/constants';

function PackagesListSummary({
  beepedPackagesQty,
  driverName,
  transportType,
  licensePlate
}) {
  const { t } = useTranslation(['receiptPackagesList', 'pickupReceiptsList']);
  const { pickupOrderScheduleHash } = useParams();
  const transportTypeText = t(TRANSPORT_TYPE_MAPPER(transportType), {
    ns: 'pickupReceiptsList'
  });
  const vehicleText = [transportTypeText, licensePlate]
    .filter(s => !!s)
    .join(' - ');
  return (
    <Box display="flex">
      <BackButton
        backToUrl={PICKUP_RECEIPT_ROUTE.url(pickupOrderScheduleHash)}
      />
      <Box ml={2}>
        <Box mb={2}>
          <Typography variant="h4" component="h1">
            <strong>
              {t('summary.beepedPackagesQty', {
                count: beepedPackagesQty
              })}
            </strong>
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography variant="subtitle1">
            {t('summary.collectedBy')} <strong> {driverName} </strong>
          </Typography>
        </Box>
        <Typography variant="subtitle2">{vehicleText}</Typography>
      </Box>
    </Box>
  );
}

PackagesListSummary.defaultProps = {
  transportType: '',
  licensePlate: ''
};

PackagesListSummary.propTypes = {
  transportType: PropTypes.string,
  beepedPackagesQty: PropTypes.number.isRequired,
  driverName: PropTypes.string.isRequired,
  licensePlate: PropTypes.string
};

export default PackagesListSummary;
