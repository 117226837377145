import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { redirectToHelpCenter } from '@loggi/components/src/one';
import { useFeature } from '@loggi/components/src/one/remote-config';

import { MENU_ITEMS } from '../header.constants';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  actionSection: {
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse'
    }
  },
  closeButton: {
    color: palette.error.main,
    marginTop: spacing(1),
    [breakpoints.up('sm')]: {
      marginTop: spacing(0),
      marginRight: spacing(1.5)
    }
  },
  helpCenterRedirectLink: {
    color: palette.primary.main,
    fontWeight: 600
  }
}));

const CompanyStatusDialog = () => {
  const { actionSection, closeButton, helpCenterRedirectLink } = useStyles();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();
  const routeParams = useRouteMatch('/empresas/:companyId');
  const { t } = useTranslation('companyStatusTranslations');
  const useZendeskUrl = useFeature('enable_redirect_to_zendesk_help_center');
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const companyId = routeParams?.params?.companyId;
  const {
    PAYMENTS: { route }
  } = MENU_ITEMS;
  const dialogMessage = t('dialog.message.captions', { returnObjects: true });

  const onClose = () => setOpen(false);

  const helpCenterRedirect = () => {
    setLoading(true);
    redirectToHelpCenter(companyId, authenticatedUser, useZendeskUrl).finally(
      () => setLoading(false)
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="company-status-dialog"
    >
      <Box p={{ xs: 3, sm: 5 }}>
        <Box pb={3}>
          <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
            {t('dialog.title')}
          </Typography>
        </Box>
        <Box pb={2}>
          <Typography variant="caption">
            {dialogMessage.slice(0, 1)}
            <strong>{t('dialog.message.strong')}</strong>
            {dialogMessage.slice(1)}
          </Typography>
        </Box>
        <Box pb={3} display="flex" alignItems="center">
          <Typography variant="caption">
            {t('dialog.helpCenter.text')}
            <ButtonBase
              data-testid="help-center-link"
              component={Link}
              disabled={loading}
              className={helpCenterRedirectLink}
              onClick={() => helpCenterRedirect()}
            >
              {t('dialog.helpCenter.link')}
            </ButtonBase>
          </Typography>
        </Box>
        <Box className={actionSection} display="flex" justifyContent="flex-end">
          <Button
            data-testid="close-dialog-btn"
            className={closeButton}
            onClick={onClose}
            size="medium"
          >
            {t('dialog.finance.dismiss')}
          </Button>
          <Button
            data-testid="redirect-to-finance-link"
            component={Link}
            href={route(companyId)}
            onClick={() => push(route(companyId))}
            size="medium"
            variant="contained"
            color="primary"
            autoFocus
          >
            {t('dialog.finance.redirectToFinance')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CompanyStatusDialog;
