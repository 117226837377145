import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import { useFeature } from '@loggi/components/src/one/remote-config';
import { redirectToHelpCenter } from '@loggi/components/src/one';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, Button, Typography, Dialog } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';

const useStyles = makeStyles(({ breakpoints }) => ({
  actionCol: {
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse'
    }
  }
}));

/**
 * PackageEditionModalError's responsibility is to show a model content to
 * show that the package edition intention is not possible.
 * @componentType: Presentational
 * @param status
 * @param open
 * @param handleClose
 * @returns {*}
 */
const PackageEditionModalError = ({ status, open, handleClose }) => {
  const { t } = useTranslation('packages');
  const styles = useStyles();
  const useZendeskUrl = useFeature('enable_redirect_to_zendesk_help_center');
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const { companyId } = useParams();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      data-testid="dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        p={{ xs: 3, sm: 5 }}
        data-testid="package-edition-modal-error-content"
      >
        <Box pb={2}>
          <Typography variant="h6">
            <strong>{t('edition.error.finalizerStatusError.title')}</strong>
          </Typography>
        </Box>
        <Box pb={4}>
          <Typography variant="subtitle2">
            <Trans>
              {t('edition.error.finalizerStatusError.subtitle', {
                status: status.toLowerCase()
              })}
            </Trans>
          </Typography>
        </Box>
        <Box
          className={styles.actionCol}
          display="flex"
          justifyContent="flex-end"
        >
          <Box
            display="flex"
            justifyContent="center"
            mt={{ xs: 1, sm: 0 }}
            mr={{ xs: 0, sm: 1 }}
          >
            <Button
              data-testid="close-btn"
              component={Link}
              disabled={loading}
              target="_blank"
              onClick={async () => {
                handleClose();
                setLoading(true);
                redirectToHelpCenter(
                  companyId,
                  authenticatedUser,
                  useZendeskUrl
                );
                setLoading(false);
              }}
              color="primary"
              size="large"
            >
              {t('edition.error.finalizerStatusError.closeButton')}
            </Button>
          </Box>
          <Button
            data-testid="edit-error-confirmation-btn"
            onClick={handleClose}
            size="large"
            variant="contained"
            color="primary"
            autoFocus
          >
            {t('edition.error.finalizerStatusError.confirmationButton')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

PackageEditionModalError.propTypes = {
  status: PropTypes.string.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

PackageEditionModalError.defaultProps = {
  open: false
};

export default PackageEditionModalError;
