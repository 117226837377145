const spreadsheetRoutes = {
  upload: {
    path: '/empresas/:companyId/pacotes/adicionar/planilha',
    url: companyId => `/empresas/${companyId}/pacotes/adicionar/planilha`
  },
  instructions: {
    path: `/empresas/:companyId/pacotes/adicionar/planilha/instrucoes`,
    url: companyId =>
      `/empresas/${companyId}/pacotes/adicionar/planilha/instrucoes`
  },
  errors: {
    path: `/empresas/:companyId/pacotes/adicionar/planilha/erros`,
    url: companyId => `/empresas/${companyId}/pacotes/adicionar/planilha/erros`
  }
};

const packagesRoutes = {
  create: {
    singlePackage: {
      path: '/empresas/:companyId/pacotes/adicionar',
      url: companyId => `/empresas/${companyId}/pacotes/adicionar`
    },
    spreadsheet: spreadsheetRoutes
  },
  createSuccess: {
    path: `/empresas/:companyId/pacotes/sucesso`,
    url: companyId => `/empresas/${companyId}/pacotes/sucesso`
  },
  edit: {
    path: '/empresas/:companyId/pacotes/:trackingKey/destinatario/editar',
    url: (companyId, trackingKey) =>
      `/empresas/${companyId}/pacotes/${trackingKey}/destinatario/editar`
  },
  editSuccess: {
    path:
      '/empresas/:companyId/pacotes/:trackingKey/destinatario/editar/sucesso',
    url: (companyId, trackingKey) =>
      `/empresas/${companyId}/pacotes/${trackingKey}/destinatario/editar/sucesso`
  },
  importInvoice: {
    path: '/empresas/:companyId/pacotes/adicionar/nfe',
    url: companyId => `/empresas/${companyId}/pacotes/adicionar/nfe`
  },
  importInvoiceErrors: {
    path: '/empresas/:companyId/pacotes/adicionar/nfe/erros',
    url: companyId => `/empresas/${companyId}/pacotes/adicionar/nfe/erros`
  },
  importInvoiceInstructions: {
    path: '/empresas/:companyId/pacotes/adicionar/nfe/instrucoes',
    url: companyId => `/empresas/${companyId}/pacotes/adicionar/nfe/instrucoes`
  },
  index: {
    path: '/empresas/:companyId/pacotes',
    url: companyId => `/empresas/${companyId}/pacotes`
  },
  options: {
    path: '/empresas/:companyId/pacotes/:trackingKey/opcoes',
    url: (companyId, trackingKey) =>
      `/empresas/${companyId}/pacotes/${trackingKey}/opcoes`
  },
  show: {
    path: '/empresas/:companyId/pacotes/:trackingKey',
    url: (companyId, trackingKey) =>
      `/empresas/${companyId}/pacotes/${trackingKey}`
  },
  trackingHistory: {
    path: '/empresas/:companyId/pacotes/:trackingKey/historico',
    url: (companyId, trackingKey) =>
      `/empresas/${companyId}/pacotes/${trackingKey}/historico`
  }
};

export default packagesRoutes;
