import { ReactComponent as EmptyStateSearchIllustration } from '@loggi/components/src/one/shared/images/empty-state-search.svg';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PickupReceiptExpired = ({ daysExpired }) => {
  const { t } = useTranslation('receiptOrderSchedule');

  return (
    <Box
      textAlign="center"
      flexDirection="column"
      alignItems="center"
      mt={14}
      mx={7}
    >
      <EmptyStateSearchIllustration />
      <Box mx={3.5} mt={2} mb={1}>
        <Typography>
          <strong>{t('receiptExpired.title')}</strong>
        </Typography>
      </Box>
      <Typography>
        {t('receiptExpired.subtitle', { count: daysExpired })}
      </Typography>
    </Box>
  );
};

PickupReceiptExpired.propTypes = {
  daysExpired: PropTypes.number.isRequired
};

export default PickupReceiptExpired;
