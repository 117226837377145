import { Button, Grid, Typography } from '@material-ui/core';
import Toast from '@loggi/components/src/one/toast';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { CnpjOrCpfField } from '@loggi/components/src/one/formik-fields';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import pickupReceiptAuthApi from '../../operations/auth/pickup-receipt-auth-api';
import { PICKUP_RECEIPT_ROUTE } from '../../routes/constants';
import PickupReceiptFooter from '../pickup-receipt-footer/pickup-receipt-footer.component';

const removeDocumentMask = document => {
  return document.replace(/\D/g, '');
};

const AuthForm = ({ pickupOrderScheduleHash }) => {
  const { t } = useTranslation('auth');
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (values, { setErrors }) => {
    const cnpjOrCpf = removeDocumentMask(values.document);

    pickupReceiptAuthApi
      .authenticate({ pickupOrderScheduleHash, document: cnpjOrCpf })
      .forbidden(() => {
        setErrors({ document: t('errorsMessages.documentMismatch') });
      })
      .res(() => {
        push(PICKUP_RECEIPT_ROUTE.url(pickupOrderScheduleHash));
      })
      .catch(error => {
        Sentry.captureException(error, {
          contexts: {
            pickupOrderScheduleHash,
            document: cnpjOrCpf
          }
        });

        enqueueSnackbar(t('errorsAuthAPIMessages.genericMessage'), {
          content: (key, message) => (
            <Toast id={key} message={message} type="error" />
          )
        });
      });
  };

  return (
    <Formik
      initialValues={{
        document: ''
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Typography>{t('insertDocument.instructions')}</Typography>
            </Grid>
            <Grid item>
              <CnpjOrCpfField
                fieldName="document"
                label={t('insertDocument.label')}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                {t('authenticationButton.text')}
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="center">
                <strong>{t('footerInstructionsForm.textBold')}</strong>
                {t('footerInstructionsForm.text')}
              </Typography>
            </Grid>
            <Grid item>
              <PickupReceiptFooter pb={2} pt={1} />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

AuthForm.propTypes = {
  pickupOrderScheduleHash: PropTypes.string.isRequired
};

export { removeDocumentMask };

export default AuthForm;
