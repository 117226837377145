import { DateSection } from '@loggi/components/src/one/formik-sections/';
import { useRemoteConfig } from '@loggi/components/src/one/remote-config';
import { dispatchEvent } from '@loggi/components/src/one/useSubscription';
import { CoverageParamsContext } from '@loggi/components/src/one/coverage-params';
import Toast from '@loggi/components/src/one/toast';
import { Box, Button, CircularProgress, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  useCheckCompanyStatus,
  usePickupsUserPermission
} from '@loggi/components/src/one/hooks';

import { TRANSPORT_TYPES } from './constants';
import FormikStorageWatcher from './formik-storage-watcher.component';
import { createOrderSchedules } from './order-scheduling-form.service';
import AddressSection from './sections/address-section';
import VolumeSection from './sections/volume-section';
import SectionTitle from './section-title/section-title';
import { ReactComponent as CalendarIcon } from './icons/calendar.svg';

const useStyles = makeStyles(({ palette, spacing }) => ({
  buttonWrapper: {
    margin: spacing(1),
    position: 'relative'
  },
  buttonWrapperIsLoading: {
    '& button:disabled': {
      color: 'rgba(0, 0, 0, 0)'
    }
  },
  buttonProgress: {
    color: palette.common.white,
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
    position: 'absolute',
    top: '50%'
  }
}));

export const pickupUrl = (companyId, id) =>
  `/empresas/${companyId}/coletas/${id}`;

const OrderSchedulingForm = ({ initialValues }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('orderScheduling');
  const { enqueueSnackbar } = useSnackbar();
  const { companyId } = useParams();
  const { isDefaulter } = useCheckCompanyStatus();
  const isPickupsPermission = usePickupsUserPermission();

  const snackBarMessage = useCallback(
    (barMessage, type) =>
      enqueueSnackbar(barMessage, {
        content: (key, message) => (
          <Toast id={key} message={message} type={type} />
        )
      }),
    [enqueueSnackbar]
  );

  const { value: isVanRestrictionEnabled } = useRemoteConfig(
    'enable_van_restriction'
  );

  const formatVolumes = volumes => {
    const groupedVolumes = volumes.map(
      ({ alias = 'GENERIC_VOLUME', height, length, quantity, weight, width }) =>
        [...Array(quantity)].map(() => ({
          alias,
          height_cm: height,
          length_cm: length,
          weight_g: weight,
          width_cm: width
        }))
    );
    return [].concat(...groupedVolumes);
  };

  const onSubmit = useCallback(
    async formValues => {
      setLoading(true);

      const requestParams = formValues;
      if (isVanRestrictionEnabled === 'true') {
        requestParams.modalRestrictions = [TRANSPORT_TYPES.VAN];
      }
      if (requestParams.volumes) {
        requestParams.volumes = formatVolumes(formValues.volumes);
      }

      createOrderSchedules(requestParams, companyId)
        .then(response => {
          snackBarMessage(t('create.successMessages.pickupCreated'), 'success');
          dispatchEvent('order-schedule:created', response.id);
          push(pickupUrl(companyId, response.id), {
            from: 'new-order-scheduling',
            pickup: response
          });
        })
        .catch(error => snackBarMessage(t(error.message), 'error'))
        .finally(() => setLoading(false));
    },
    [companyId, isVanRestrictionEnabled, push, snackBarMessage, t]
  );

  const [context, setContext] = useState({
    coverageParams: {},
    setCoverageParams: coverageParams => {
      setContext(state => ({ ...state, coverageParams }));
    }
  });

  return (
    <CoverageParamsContext.Provider value={context}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        // 'validateOnMount' is needed because we want the form
        // to be invalid on initial render, so the submit button
        // is disabled. The date fields also need this to work
        // properly.
        validateOnMount
      >
        {({ isValid }) => (
          <Form data-testid="order-schedulling-form">
            <FormikStorageWatcher />
            <Box>
              <Box pb={{ xs: 3, sm: 5.5 }}>
                <AddressSection />
              </Box>
              <Divider />
              <Box py={{ xs: 3, sm: 5.5 }}>
                <VolumeSection />
              </Box>
              <Divider />
              <Box pt={{ xs: 3, sm: 5.5 }}>
                <SectionTitle icon={<CalendarIcon title="Calendar Icon" />}>
                  {t('create.dateSection.title')}
                </SectionTitle>
                <DateSection />
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" pt={{ xs: 3, sm: 5.5 }}>
              <Box
                className={`${classes.buttonWrapper} ${
                  loading ? classes.buttonWrapperIsLoading : ''
                }`}
              >
                <Button
                  color="primary"
                  disabled={
                    !isValid || loading || isDefaulter || !isPickupsPermission
                  }
                  size="large"
                  type="submit"
                  variant="contained"
                  data-testid="submit-button"
                >
                  {t('create.schedulePickupButton')}
                </Button>
                {loading && (
                  <CircularProgress
                    className={classes.buttonProgress}
                    data-testid="loading"
                    size={24}
                  />
                )}
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </CoverageParamsContext.Provider>
  );
};

const schedulingFormShape = {
  address: PropTypes.shape({
    description: PropTypes.string,
    place_id: PropTypes.string
  }),
  complement: PropTypes.string,
  date: PropTypes.string,
  endTime: PropTypes.string,
  startTime: PropTypes.string,
  volumes: PropTypes.arrayOf(
    PropTypes.shape({
      alias: PropTypes.string,
      height: PropTypes.number,
      length: PropTypes.number,
      quantity: PropTypes.number,
      weight: PropTypes.number,
      width: PropTypes.number
    })
  )
};

OrderSchedulingForm.propTypes = {
  initialValues: PropTypes.shape(schedulingFormShape)
};
OrderSchedulingForm.defaultProps = {
  initialValues: {}
};

export default OrderSchedulingForm;
