import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import { Error, LensRounded } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    alertMessage: {
      color: palette.info.dark,
      fontSize: typography.body2.fontSize,
      [breakpoints.down('sm')]: {
        alignItems: 'start',
        '& .MuiAlert-icon': {
          paddingTop: spacing(1.15)
        }
      }
    },
    listIcon: {
      color: palette.error.main,
      fontSize: typography.pxToRem(10)
    }
  })
);

export const getColumn = details => {
  // TODO: remove this as soon as the back end
  // starts returning these for us
  if (details.match('endereço')) return 'A';
  if (details.match('CEP')) return 'D';
  if (details.match('nome')) return 'E';
  if (details.match('telefone')) return 'F';
  if (details.match('CPF') || details.match('CNPJ')) return 'G';
  if (details.match('altura')) return 'H';
  if (details.match('largura')) return 'I';
  if (details.match('comprimento')) return 'J';
  if (details.match('peso')) return 'K';
  if (details.match('chave')) return 'L';
  if (details.match('valor')) return 'M';

  return '';
};

const ErrorsList = ({ failures, rowNumber }) => {
  const { t } = useTranslation('packages');
  const styles = useStyles();

  return (
    <>
      <Box py={1}>
        <Box alignItems="center" display="flex">
          <Box mr={1}>
            <LensRounded className={styles.listIcon} />
          </Box>
          <Typography variant="body1">
            <Box
              color="text.secondary"
              component="strong"
              fontWeight="fontWeightBold"
            >
              {t('create.spreadsheetFlow.errors.list.lineTitle', {
                line: rowNumber
              })}
            </Box>
          </Typography>
        </Box>
        {failures.map(({ details }) => (
          <Box key={details} py={1}>
            <Typography variant="subtitle1">
              <Box component="strong" fontWeight="fontWeightBold" pb={1}>
                {t('create.spreadsheetFlow.errors.list.columnTitle', {
                  column: getColumn(details)
                })}
              </Box>
            </Typography>
            <Alert
              className={styles.alertMessage}
              icon={<Error fontSize="small" />}
              severity="warning"
            >
              {details}
            </Alert>
          </Box>
        ))}
      </Box>
      <Box py={3}>
        <Divider />
      </Box>
    </>
  );
};

ErrorsList.propTypes = {
  failures: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.string.isRequired,
      failureType: PropTypes.number.isRequired
    })
  ).isRequired,
  rowNumber: PropTypes.number.isRequired
};

export default ErrorsList;
