import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';
import HelpDrawerContent from '@loggi/components/src/one/help-drawer-content/help-drawer-content';

import PackageOptionsDrawerContent from '../package-options-drawer-content';
import PackageMovementBaseDrawer from '../../../movement_drawer/package-movement-base';

const PackageOptionsDrawerHandler = ({
  companyId,
  data,
  openCancelPackageDialog,
  openPackageEditionModalError,
  handleClose
}) => {
  const [openMovementDetails, setOpenMovementDetails] = useState(false);
  const [openHelpOption, setOpenHelpOption] = useState(false);

  const handleOpenHelpOption = () => {
    setOpenHelpOption(true);
  };
  const handleOpenMovementDetails = () => {
    setOpenMovementDetails(true);
  };

  if (openMovementDetails) {
    return <PackageMovementBaseDrawer data={data} handleClose={handleClose} />;
  }

  if (openHelpOption) {
    return (
      <HelpDrawerContent
        companyId={companyId}
        handleOpenMovementDetails={handleOpenMovementDetails}
      />
    );
  }

  return (
    <PackageOptionsDrawerContent
      companyId={companyId}
      data={data}
      openCancelPackageDialog={openCancelPackageDialog}
      openPackageEditionModalError={openPackageEditionModalError}
      handleOpenHelpOption={handleOpenHelpOption}
      open
    />
  );
};

PackageOptionsDrawerHandler.propTypes = {
  companyId: PropTypes.string.isRequired,
  data: packageProps.isRequired,
  openCancelPackageDialog: PropTypes.func.isRequired,
  openPackageEditionModalError: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default PackageOptionsDrawerHandler;
