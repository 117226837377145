import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const DialogHeaderBase = props => {
  return (
    <Box paddingX={4} pt={4} pb={2}>
      <Box display="flex">
        <Box>
          <Box pb={2} fontSize={24} fontWeight="bold">
            {props.title}
          </Box>
          <Box fontSize={16}>{props.subtitle}</Box>
        </Box>
      </Box>
      <Box pt={6} pb={2} justifyContent="center" display="flex">
        {props.children}
      </Box>
    </Box>
  );
};

export default DialogHeaderBase;

DialogHeaderBase.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};
