import { useFormikContext } from 'formik';

export const PICKUP_FORM_STORAGE_KEY = 'loggione_pickup_form';

const FormikStorageWatcher = () => {
  const {
    values: { address, complement, nocomplement, shipperName, shipperPhone }
  } = useFormikContext();

  localStorage.setItem(
    PICKUP_FORM_STORAGE_KEY,
    JSON.stringify({
      address,
      complement,
      nocomplement,
      shipperName,
      shipperPhone
    })
  );

  return null;
};

export default FormikStorageWatcher;
