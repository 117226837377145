import React from 'react';
import PropTypes from 'prop-types';

import { List, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';
import SchedulingDetailsPackagesListItem from './scheduling-details-packages-list-item.component';

const useStyles = makeStyles(() => ({
  listLoading: {
    width: '100%',
    opacity: ({ isLoading }) => (isLoading ? 0.5 : 1)
  }
}));

/**
 * SchedulingDetailsPackagesList renders a static list that only presents the data it receives
 * @componentType presentational
 * @param data
 * @param isLoading
 * @returns {*}
 * @constructor
 */
const SchedulingDetailsPackagesList = ({ data, isLoading }) => {
  const classes = useStyles({ isLoading });

  return (
    <>
      <List className={classes.listLoading}>
        {data.map(pkg => {
          return (
            <React.Fragment key={pkg.trackingKey}>
              <SchedulingDetailsPackagesListItem
                trackingKey={pkg.trackingKey}
                status={pkg.status}
                recipientName={pkg.recipientName}
                destinationAddress={pkg.destinationAddress}
              />
              <Divider component="li" />
            </React.Fragment>
          );
        })}
      </List>
    </>
  );
};

SchedulingDetailsPackagesList.propTypes = {
  data: PropTypes.arrayOf(packageProps),
  isLoading: PropTypes.bool.isRequired
};
SchedulingDetailsPackagesList.defaultProps = {
  data: []
};

export default SchedulingDetailsPackagesList;
