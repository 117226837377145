import { makeStyles, Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { useCheckCompanyStatus } from '@loggi/components/src/one/hooks';
import { colors } from '@loggi/mar';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    item: {
      '&:hover': {
        backgroundColor: palette.secondary.light,
        color: palette.primary.main,
        transition: 'all 0.2s ease-in-out'
      },
      [breakpoints.up('sm')]: {
        padding: spacing(1.5, 2)
      },
      color: palette.info.dark,
      display: 'flex',
      fontSize: typography.body1,
      fontWeight: typography.fontWeightBold,
      justifyContent: 'space-between',
      padding: spacing(2),
      width: '100%'
    },
    popover: {
      '& .MuiPopover-paper.MuiPaper-root': {
        [breakpoints.only('xs')]: {
          width: 'calc(100% - 48px)'
        },
        [breakpoints.up('sm')]: {
          width: typography.pxToRem(312)
        },
        marginTop: spacing(1)
      }
    },
    toggleButton: {
      borderColor: ({ isDefaulter }) =>
        isDefaulter ? colors.smoke[500] : palette.primary.contrastText,
      borderRadius: '50px',
      color: palette.primary.contrastText,
      justifyContent: 'space-between',
      minWidth: typography.pxToRem(183),
      paddingLeft: typography.pxToRem(16),
      paddingRight: typography.pxToRem(16),
      '&.Mui-selected, &.Mui-selected:hover': {
        color: palette.primary.contrastText,
        mixBlendMode: 'screen',
        transition: 'all 0.2s ease-in-out'
      },
      '&:disabled': {
        color: colors.smoke[500]
      }
    },
    unifiedButton: {
      borderColor: ({ isDefaulter }) =>
        isDefaulter ? colors.smoke[500] : palette.primary.contrastText,
      borderRadius: '50px',
      color: palette.primary.contrastText,
      justifyContent: 'center',
      minWidth: typography.pxToRem(183),
      paddingLeft: typography.pxToRem(16),
      paddingRight: typography.pxToRem(16),
      '&.Mui-selected, &.Mui-selected:hover': {
        color: palette.primary.contrastText,
        mixBlendMode: 'screen',
        transition: 'all 0.2s ease-in-out'
      },
      '&:disabled': {
        color: colors.smoke[500]
      }
    }
  })
);

const CreatePickupNavigation = () => {
  const { t } = useTranslation('scheduling');
  const { url } = useRouteMatch();
  const { isDefaulter } = useCheckCompanyStatus() || {};
  const styles = useStyles({ isDefaulter });

  const toggleButtonLabel = t('scheduleOrder');

  const navigationItems = [
    {
      enabled: true,
      key: 'single-pickup',
      label: t('navigation.singlePickup'),
      url: `${url}/adicionar`
    }
  ];

  return (
    <Button
      to={navigationItems[0].url}
      className={styles.unifiedButton}
      data-testid="unified-btn"
      size="small"
      variant="outlined"
      component={Link}
      disabled={isDefaulter}
    >
      {toggleButtonLabel}
    </Button>
  );
};

export default CreatePickupNavigation;
