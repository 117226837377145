import { Box, Divider, Grid, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionTitle from '../../create-section-title';
import InvoiceKeyField from './invoice-key-field';
import TotalValueField from './total-value-field';

const InvoiceSection = () => {
  const { t } = useTranslation('packages');
  const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const gridSpacing = smUp ? 5 : 2;

  return (
    <>
      <Box display="block" mb={4}>
        <SectionTitle>{t('create.invoice.title')}</SectionTitle>
        {t('create.invoice.description')}
      </Box>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={6}>
          <InvoiceKeyField />
        </Grid>
        <Grid item xs={12} md={6}>
          <TotalValueField />
        </Grid>
      </Grid>
      <Box py={4}>
        <Divider />
      </Box>
    </>
  );
};

export default InvoiceSection;
