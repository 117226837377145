import React from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, makeStyles } from '@material-ui/core';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import DateRangePickerComponent from './DateRangePicker';
import { FILTERS_NAMES } from '../../../constants';

const useStyles = makeStyles({
  dialogContainer: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        '& .MuiBox-root': {
          padding: '0px'
        },
        width: 'auto'
      },
      '& .MuiDialog-paper': {
        padding: '0px'
      }
    }
  }
});

const DatePickerDialog = ({ handleCloseDatePickerDialog, onChangeDate }) => {
  const styles = useStyles();

  const queryParams = useQueryParams();
  const startDateParam = queryParams[FILTERS_NAMES.START_DATE];
  const finalDateParam = queryParams[FILTERS_NAMES.FINAL_DATE];

  return (
    <Dialog
      open
      className={styles.dialogContainer}
      maxWidth={false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="datepicker-dialog"
      onBackdropClick={handleCloseDatePickerDialog}
    >
      <Box p={{ xs: 3, sm: 5 }}>
        <DateRangePickerComponent
          initialStartDate={startDateParam}
          initialEndDate={finalDateParam}
          onClose={handleCloseDatePickerDialog}
          onChangeDate={onChangeDate}
        />
      </Box>
    </Dialog>
  );
};

DatePickerDialog.propTypes = {
  handleCloseDatePickerDialog: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired
};

export default DatePickerDialog;
