import { Box, Divider, Grid, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionTitle from '../../create-section-title';
import HeightField from './height-field';
import LengthField from './length-field';
import WeightField from './weight-field';
import WidthField from './width-field';

const VolumeSection = () => {
  const { t } = useTranslation('packages');

  const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const gridSpacing = smUp ? 5 : 2;

  return (
    <>
      <Box display="block" mb={4}>
        <SectionTitle>{t('create.volumetry.title')}</SectionTitle>
        {t('create.volumetry.description')}
      </Box>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={3}>
          <HeightField />
        </Grid>
        <Grid item xs={12} md={3}>
          <WidthField />
        </Grid>
        <Grid item xs={12} md={3}>
          <LengthField />
        </Grid>
        <Grid item xs={12} md={3}>
          <WeightField />
        </Grid>
      </Grid>
      <Box py={4}>
        <Divider />
      </Box>
    </>
  );
};

export default VolumeSection;
