import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Box } from '@material-ui/core';

import { ReactComponent as EmptyPickupsIllustration } from '@loggi/components/src/one/shared/images/packages-error.svg';

const useStyles = makeStyles(({ typography }) => ({
  emptyIllustration: {
    width: '100%',
    maxWidth: typography.pxToRem(163)
  }
}));

const PackageRecipientEditionError = () => {
  const classes = useStyles();
  const { t } = useTranslation('packages');

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      data-testid="package-recipient-edition-error-wrapper"
      mt={{ xs: 7, sm: 0 }}
    >
      <EmptyPickupsIllustration className={classes.emptyIllustration} />
      <Box pt={2} pb={1}>
        <Box mb={1}>
          <Typography variant="subtitle1">
            <strong>{t('edition.error.title')}</strong>
          </Typography>
        </Box>
        <Typography variant="subtitle2" component="p" color="textSecondary">
          {t('edition.error.subtitle')}
        </Typography>
      </Box>
      <Button
        onClick={() => {
          window.location.reload();
        }}
        color="primary"
        data-testid="reload"
      >
        {t('edition.error.linkText')}
      </Button>
    </Box>
  );
};

export default PackageRecipientEditionError;
