import { PackageFactory } from '@loggi/components/src/one/package-utils';
import exceptionHandler from '../exception-handler';
import PackagesSearchUpdateException from '../exceptions/packages-search-update.exception';

export const normalizeSearchData = async response => ({
  ...response,
  packages: await PackageFactory.search.bulkCreate(response?.packages)
});

export const buildPackageHash = response => {
  const byId = response.packages.reduce(
    (result, pkg) => ({ ...result, [pkg.trackingKey]: pkg }),
    {}
  );
  const allIds = response.packages.map(pkg => pkg.trackingKey);
  return { ...response, packagesHash: { byId, allIds } };
};

/**
 * This function removes any package with error from tracking list API
 * response and notifies Sentry about them.
 */
export const filterPackagesWithError = response => {
  let trackingKeysWithError = [];
  let packagesFound = [];

  const splitCheckingTrackingAPI = (failedPackages, packages) => {
    trackingKeysWithError = failedPackages;
    packagesFound = packages;
  };

  /**
   * To identify which kind of payload is received, this code applies some
   * duck typing strategies to check if the payload contains a package list or
   * a package hash. Split functions handle each option and factory function
   * call the correct method on Factory to instantiate packages.
   */
  splitCheckingTrackingAPI(response?.failedPackages, response?.packages);
  if (trackingKeysWithError?.length) {
    const exception = new PackagesSearchUpdateException(
      'Failed to fetch packages'
    );
    exceptionHandler.error(exception, { trackingKeys: trackingKeysWithError });
  }
  return PackageFactory.trackingAPI.bulkCreate(packagesFound);
};
