const LOGGI_WEB_URL = process.env.REACT_APP_LOGGI_WEB_URL;

const ACCESS_DENIED = '/acesso-negado';
const PICKUPS_COMPANIES_ENDPOINT = 'one/api/pickups/v1/companies';
const PACKAGES_COMPANIES_ENDPOINT = 'one/api/packages/v1/companies';
const PICKUP_RECEIPTS_COMPANIES_ENDPOINT =
  'one/api/pickup_receipt/v1/companies';
const ORDER_SCHEDULES_ENDPOINT = 'order-schedules';
const PACKAGES_BATCH_GET_ENDPOINT = 'packages:batchGet';

export {
  ACCESS_DENIED,
  PICKUPS_COMPANIES_ENDPOINT,
  PACKAGES_COMPANIES_ENDPOINT,
  PICKUP_RECEIPTS_COMPANIES_ENDPOINT,
  ORDER_SCHEDULES_ENDPOINT,
  PACKAGES_BATCH_GET_ENDPOINT,
  LOGGI_WEB_URL
};
