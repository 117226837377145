import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BaseVolumeField from './base-volume-field';

const QuantityField = ({ index }) => {
  const { t } = useTranslation('orderScheduling');

  const validate = value => {
    if (value !== 0 && !value)
      return t('create.errorMessages.requiredQuantity');
    if (value < 1) return t('create.errorMessages.invalidQuantity');
    return null;
  };

  return (
    <BaseVolumeField
      fieldName={`volumes.${index}.quantity`}
      label={t('create.volumeSection.labelQuantity')}
      validate={validate}
    />
  );
};

QuantityField.propTypes = {
  index: PropTypes.number.isRequired
};

export default QuantityField;
