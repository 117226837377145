import { InputAdornment, TextField } from '@material-ui/core';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

/**
 * <BaseVolumeField />
 * @param adornmentText
 * @param fieldName - used as field name for formik useField
 * @param label
 * @param validate
 * @returns {*}
 * @constructor
 */
const BaseVolumeField = ({ adornmentText, fieldName, label, validate }) => {
  const [field, meta] = useField({
    name: fieldName,
    validate
  });

  const { name, onChange, onBlur, value = '' } = field;
  const { error, touched } = meta;
  const hasError = Boolean(error) && touched;

  const Adornment = useMemo(
    () => () => {
      if (adornmentText) {
        return <InputAdornment position="end">{adornmentText}</InputAdornment>;
      }
      return null;
    },
    [adornmentText]
  );

  return (
    <TextField
      error={hasError}
      fullWidth
      helperText={hasError && error}
      id={name}
      inputProps={{
        'data-testid': `${name}-field`,
        min: 1,
        inputMode: 'numeric'
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{ onBlur, endAdornment: <Adornment /> }}
      label={label}
      name={name}
      onChange={onChange}
      required
      type="number"
      value={value}
      variant="outlined"
    />
  );
};

BaseVolumeField.propTypes = {
  adornmentText: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validate: PropTypes.func.isRequired
};

BaseVolumeField.defaultProps = {
  adornmentText: null
};

export default BaseVolumeField;
