import { Checkbox, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const NoComplementField = ({ complementFieldName }) => {
  const fieldName = `no${complementFieldName}`;
  const { t } = useTranslation('one');

  const { setFieldTouched, setFieldValue, validateField } = useFormikContext();
  const [{ name, value = false }, , { setValue }] = useField({
    name: fieldName,
    initialValue: ''
  });

  const onChange = useCallback(
    e => {
      const newValue = e.target.checked;

      setValue(newValue);

      /** These 2 lines intentionally make changes to Formik without triggering
       * a new validation, to keep the performance good */
      setFieldValue(complementFieldName, '', false);
      setFieldTouched(complementFieldName, false, false);

      /** This setTimeout is important because it defer its callback execution to
       * the next JS event loop, and only with this Formik will have time to
       * finish its work with the previous lines, and then the next validation
       * will have the correct field values. */
      setTimeout(() => validateField(complementFieldName), 0);
    },
    [
      complementFieldName,
      setFieldTouched,
      setFieldValue,
      setValue,
      validateField
    ]
  );

  return (
    <FormControlLabel
      style={{ marginRight: 0 }}
      control={
        <Checkbox
          checked={value}
          color="primary"
          inputProps={{ 'data-testid': `${name}-checkbox` }}
          name={name}
          onChange={onChange}
        />
      }
      label={
        <Typography variant="body2">{t('noComplementField.label')}</Typography>
      }
    />
  );
};

NoComplementField.propTypes = {
  complementFieldName: PropTypes.string.isRequired
};

export default NoComplementField;
