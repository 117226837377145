import Toast from '@loggi/components/src/one/toast';
import { colors } from '@loggi/mar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import cancelSchedule from '../../cancel-schedule';

const useCancelScheduleDialogStyles = makeStyles(
  ({ spacing, breakpoints }) => ({
    data_info: { fontWeight: 600 },
    actionCol: {
      [breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column-reverse'
      },
      [breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'center'
      }
    },
    denyButton: {
      [breakpoints.up('sm')]: {
        display: 'flex',
        marginRight: spacing(2.5)
      }
    },
    dialogTitle: { color: colors.smoke[900] },
    dialogContent: { color: colors.smoke[900] }
  })
);

/**
 * CancelScheduleDialog's responsibility is to show a dialog to confirm
 * a schedule cancelation intention, with the params it receives.
 * @componentType: Presentational
 * @param schedulingId
 * @param open
 * @param handleClose
 * @param dayMonth
 * @param startHour
 * @param endHour
 * @returns {*}
 */
const CancelScheduleDialog = ({
  companyId,
  schedulingId,
  open,
  handleClose,
  dayMonth,
  startHour,
  endHour
}) => {
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const redirectToSchedulesList = () => push('/coletas');
  const styles = useCancelScheduleDialogStyles();
  const { t } = useTranslation('details');

  const getToastMessage = paidValue => {
    if (parseFloat(paidValue) > 0) {
      return t('cancelSchedule.paidPickupCancellation', { value: paidValue });
    }
    return t('cancelSchedule.freePickupCancellation');
  };

  const handleCancelScheduleRequest = async () => {
    const i18n = getI18n();
    const fixedT = i18n.getFixedT(null, 'details');

    cancelSchedule(companyId, schedulingId)
      .then(response => {
        const paidValue = response.message;
        const toastMessage = getToastMessage(paidValue);
        enqueueSnackbar(toastMessage, {
          content: (key, message) => (
            <Toast id={key} message={message} type="success" />
          )
        });
        handleClose();
        redirectToSchedulesList();
      })
      .catch(error => {
        let toastMessage = fixedT('cancelSchedule.fail');

        if (error.response && error.response.status === 400) {
          toastMessage = fixedT('cancelSchedule.pickupIsNotCancellable');
        }

        enqueueSnackbar(toastMessage, {
          content: (key, message) => (
            <Toast id={key} message={message} type="error" />
          )
        });
        handleClose();
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box p={5.5}>
        <Box pb={3}>
          <Typography className={styles.dialogTitle} variant="subtitle1">
            {t('cancelSchedule.labelInfo')}
            <br />
            <strong className={styles.data_info}>
              {t('cancelSchedule.dateInfo', { dayMonth, startHour, endHour })}
            </strong>
          </Typography>
        </Box>
        <Box pb={5}>
          <Typography className={styles.dialogContent} variant="body1">
            {t('cancelSchedule.description')}
          </Typography>
        </Box>
        <Box className={styles.actionCol}>
          <Button
            aria-label={t('cancelSchedule.denyButton')}
            className={styles.denyButton}
            onClick={handleClose}
            color="primary"
            size="small"
          >
            {t('cancelSchedule.denyButton')}
          </Button>
          <Button
            aria-label={t('cancelSchedule.confirmButton')}
            className={styles.confirmButton}
            onClick={handleCancelScheduleRequest}
            color="secondary"
            size="small"
            variant="outlined"
            autoFocus
          >
            {t('cancelSchedule.confirmButton')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

CancelScheduleDialog.propTypes = {
  companyId: PropTypes.string.isRequired,
  schedulingId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  dayMonth: PropTypes.string,
  startHour: PropTypes.string,
  endHour: PropTypes.string
};

CancelScheduleDialog.defaultProps = {
  open: false,
  dayMonth: '',
  startHour: '',
  endHour: ''
};

export default CancelScheduleDialog;
