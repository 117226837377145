import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Tooltip,
  useMediaQuery
} from '@material-ui/core';
import { ErrorMessage, FieldArray, useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { format, setDay, setISODay } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useCoverageParamsContext } from '@loggi/components/src/one/coverage-params';
import { useFeature } from '@loggi/components/src/one/remote-config';
import { weekDays } from '@loggi/components/src/one/formik-fields/constants';
import { IS_RECURRING } from './constants';

const useStyledCheckboxStyles = makeStyles(
  ({ palette, typography, breakpoints }) => ({
    icon: {
      borderRadius: '9999em',
      fontSize: typography.body2.fontSize,
      fontWeight: typography.fontWeightMedium,
      height: typography.pxToRem(40),
      lineHeight: typography.pxToRem(40),
      width: typography.pxToRem(95),
      marginBottom: typography.pxToRem(10),
      [breakpoints.down('md')]: {
        width: '100%'
      }
    },
    enableIcon: {
      border: `1px solid ${palette.grey[200]}`
    },
    checkedIcon: {
      color: palette.primary.contrastText,
      background: `linear-gradient(to right, ${palette.primary.main}, ${
        palette.primary.dark
      })`
    },
    checkbox: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent'
      },
      [breakpoints.down('md')]: {
        width: '100%'
      }
    }
  })
);

const useWeekdaysFieldStyles = makeStyles(({ breakpoints, spacing }) => ({
  formLabel: {
    margin: spacing(0.5),
    [breakpoints.up('sm')]: {
      margin: '0 .3em 0 .3em'
    }
  },
  formControl: {
    justifyContent: 'space-between',
    width: '100%'
  },
  formGroup: {
    display: 'flex'
  }
}));

const businessDays = workingWeekDays =>
  [1, 2, 3, 4, 5].map(businessDay => {
    const day = setDay(new Date(), businessDay);
    const enabledWorkingWeekDays = [];

    workingWeekDays.forEach(item => {
      const dow = weekDays.indexOf(item);
      enabledWorkingWeekDays.push(setISODay(new Date(), dow).getDay());
    });

    return {
      description: format(day, 'EEEE'),
      label: format(day, 'EEEE').replace('-feira', ''),
      value: businessDay.toString(),
      disabled: !enabledWorkingWeekDays.includes(day.getDay())
    };
  });

const StyledCheckbox = props => {
  const classes = useStyledCheckboxStyles();
  const { description, label, disabled } = props;

  return (
    <Tooltip title={description}>
      <Checkbox
        disabled={disabled}
        checkedIcon={
          <span className={`${classes.icon} ${classes.checkedIcon}`}>
            {label}
          </span>
        }
        className={classes.checkbox}
        icon={
          <span
            className={`${classes.icon} ${
              disabled ? classes.disabledIcon : classes.enableIcon
            }`}
          >
            {label}
          </span>
        }
        inputProps={{
          'data-testid': 'weekdayCheckbox',
          'aria-label': description
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Tooltip>
  );
};

StyledCheckbox.propTypes = {
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

const WeekdaysField = ({ fieldName }) => {
  const { t } = useTranslation(['one', 'unifiedPickup']);
  const classes = useWeekdaysFieldStyles();
  const { values } = useFormikContext();
  const [errorMessage, setErrorMessage] = useState(undefined);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const [field, { error, touched }, { setTouched }] = useField({
    name: fieldName,
    initialValue: [],
    validate: newValue => {
      let message;
      if (!newValue?.length) {
        message = t('one:weekdaysField.errorMessages.requiredField');
      }
      if (values.pickupIsRecurring !== IS_RECURRING && newValue?.length > 1) {
        message = t('unifiedPickup:dateSection.weekdays.error');
      }
      setErrorMessage(message);
      return message;
    }
  });

  const { value: fieldValue } = field;
  const hasError = Boolean(error) && touched;

  const { pickupWorkingWeekdays } = useCoverageParamsContext();

  const shouldDisableWeekDaysWithoutPickup = useFeature(
    'should_disable_week_days_without_pickup'
  );

  const workingWeekDays = shouldDisableWeekDaysWithoutPickup
    ? businessDays(pickupWorkingWeekdays)
    : businessDays(weekDays.slice(1, 6));

  const handleChange = useCallback(
    (e, { push, remove }) => {
      if (e.target.checked) {
        push(e.target.value);
      } else {
        const idx = fieldValue.indexOf(e.target.value);
        remove(idx);
      }
    },
    [fieldValue]
  );

  const renderFields = arrayHelpers => {
    return workingWeekDays.map(({ description, value, label, disabled }) => (
      <FormControlLabel
        className={classes.formLabel}
        control={
          <StyledCheckbox
            disabled={disabled || false}
            checked={fieldValue?.includes(value) || false}
            description={description}
            label={label}
            onChange={e => handleChange(e, arrayHelpers)}
          />
        }
        key={description}
        label={null}
        name={fieldName}
        value={value}
        disabled={disabled}
      />
    ));
  };

  useEffect(() => {
    if (fieldValue !== undefined && !touched) setTouched(true);
  }, [touched, setTouched, fieldValue]);

  return (
    <Box display="flex">
      <FormControl className={classes.formControl} error={hasError}>
        <FormGroup className={classes.formGroup} row={!isMobile}>
          <FieldArray
            name={fieldName}
            render={arrayHelpers => renderFields(arrayHelpers)}
          />
        </FormGroup>
        <FormHelperText>
          <Box mx={1}>
            <ErrorMessage
              name={fieldName}
              render={() => {
                return errorMessage || '';
              }}
            />
          </Box>
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

WeekdaysField.propTypes = {
  fieldName: PropTypes.string.isRequired
};

export default WeekdaysField;
