import { Drawer, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import UpdateUserForm from './update-user.container';
import RevokeUserButton from '../revoke-user/revoke-user-button';
import {
  COMPANIES_ROUTE,
  USERS_MANAGEMENT_PATH
} from '../users-management.constants';

const CustomDrawer = withStyles(({ breakpoints, spacing }) => ({
  paper: {
    [breakpoints.up('sm')]: {
      maxWidth: spacing(58),
      minWidth: spacing(58)
    },
    width: '100%'
  }
}))(Drawer);

const UpdateUserDrawer = ({
  accountId,
  name,
  role,
  handleClose,
  email,
  open,
  updateUsersListCallback,
  userId,
  userStatus
}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { push } = useHistory();
  const { companyId } = useParams();

  const closeDrawer = () => {
    setDrawerOpen(false);
    handleClose();
    push({
      pathname: `${COMPANIES_ROUTE}/${companyId}${USERS_MANAGEMENT_PATH}`
    });
  };

  useEffect(() => {
    setDrawerOpen(open);
  }, [open]);

  return (
    <CustomDrawer anchor="right" onClose={closeDrawer} open={isDrawerOpen}>
      <Box px={{ xs: 3, sm: 4 }} pt={{ xs: 1.5, sm: 4 }}>
        <IconButton
          data-testid="close-update-drawer-btn"
          edge="start"
          onClick={closeDrawer}
        >
          <ArrowBack color="primary" />
        </IconButton>
      </Box>
      <UpdateUserForm
        name={name}
        currentRole={role}
        email={email}
        handleClose={closeDrawer}
        updateUsersListCallback={updateUsersListCallback}
        userStatus={userStatus}
      />
      <RevokeUserButton
        accountId={accountId}
        name={name}
        userId={userId}
        handleClose={closeDrawer}
        updateUsersListCallback={updateUsersListCallback}
      />
    </CustomDrawer>
  );
};

UpdateUserDrawer.propTypes = {
  accountId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  updateUsersListCallback: PropTypes.func,
  handleClose: PropTypes.func,
  userId: PropTypes.string.isRequired,
  userStatus: PropTypes.number.isRequired
};

UpdateUserDrawer.defaultProps = {
  updateUsersListCallback: () => {},
  handleClose: () => {}
};

export default UpdateUserDrawer;
