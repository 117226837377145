import { addDays, isAfter, parseISO } from 'date-fns';
import {
  PACKAGE_DIRECTION_DELIVERY,
  PACKAGE_DIRECTION_DELIVERY_CODE,
  PACKAGE_DIRECTION_RETURN,
  PACKAGE_DIRECTION_RETURN_CODE,
  RETURN_DEADLINE_DAYS
} from '../constants';
import {
  isPackageDelivered,
  isPackageAtFinalStatus,
  isPackageEnRoute,
  isPackageIssued,
  isPackageStatusAdded,
  isPackageReturned
} from './status.helpers';
import { formattedCNPJ } from '../utils';

/**
 * Normalized representation of PostgreSQL database package. It makes easier
 * for presentational components to display fields info without having to know
 * deeply how package is structured, and how make some assumptions about the
 * package state.
 */
class PackageModel {
  /**
   * Create a Package
   * @param {object} data - normalized package data
   * @return {PackageModel} PackageModel instance
   */
  constructor(data) {
    if (!data.trackingKey) {
      throw Error(
        "[PackageModel] Can't instantiate package object without trackingKey."
      );
    }
    this.trackingKey = data.trackingKey;
    this.deliveredDate = data.deliveredDate;
    this.status = data.status;
    this._statusInfo = data.statusInfo;
    this.promisedDate = data.promisedDate;
    this.returnPromisedDate = addDays(
      parseISO(data.direction?.updatedTime),
      RETURN_DEADLINE_DAYS
    );
    this.chargeAmount = data.chargeAmount;
    this.slo = data.slo;
    this.direction = data.direction;
    this.volumetricInfo = data.volumetricInfo;
    this.companyCnpj = data.companyCnpj
      ? formattedCNPJ(data.companyCnpj)
      : undefined;
    this.companyId = data.companyId;
    this._deliveryInformation = data.deliveryInformation;
    this._recipient = data.recipient;
    this._origin = data.origin;
    this._destination = data.destination;
    this._dimensions = data.dimensions;
    this._weight = data.weight;
    this._contentInvoice = data.contentInvoice;
    this.invoices = data.invoices;
    this._redispatch = data.redispatch;
    this.updateReturnAddressInfo = data.updateReturnAddressInfo;
  }

  /**
   * Checks if package can be considered delivered
   * @returns {boolean}
   */
  isDelivered() {
    return isPackageDelivered(this?._statusInfo?.label);
  }

  /**
   * Checks if package can be considered returned
   * @returns {boolean}
   */
  isReturned() {
    return isPackageReturned(this?._statusInfo?.label);
  }

  /**
   * Checks if package direction type is return
   * @returns {boolean}
   */
  isPackageDirectionReturn() {
    return (
      this?.direction?.type?.code === PACKAGE_DIRECTION_RETURN_CODE ||
      this?.direction?.type?.description === PACKAGE_DIRECTION_RETURN
    );
  }

  /**
   * Checks if package is delayed
   * @returns {boolean}
   */
  isPackageDelayed() {
    return this.isPackageDirectionReturn()
      ? isAfter(new Date(), this.returnPromisedDate)
      : isAfter(new Date(), parseISO(this.promisedDate));
  }

  /**
   * Checks if package direction type is delivery
   * @returns {boolean}
   */
  isPackageDirectionDelivery() {
    return (
      this?.direction?.type?.code === PACKAGE_DIRECTION_DELIVERY_CODE ||
      this?.direction?.type?.description === PACKAGE_DIRECTION_DELIVERY
    );
  }

  /**
   * Checks if package current status is Added
   * @returns {boolean}
   */
  isStatusAdded() {
    return isPackageStatusAdded(this?._statusInfo?.code);
  }

  /**
   * Checks if package has final status
   * @returns {boolean}
   */
  isFinalStatus() {
    return isPackageAtFinalStatus(this?._statusInfo?.label);
  }

  /**
   * Checks if package status is en route
   * @returns {boolean}
   */
  isEnRouteStatus() {
    return isPackageEnRoute(this?._statusInfo?.code);
  }

  /**
   * Checks is package has issued status
   * @returns {boolean}
   */
  isPackageIssued() {
    return isPackageIssued(this._statusInfo?.label);
  }

  /**
   * Check if package can have the return address updated
   * @returns {boolean}
   */
  canUpdateReturnAddress() {
    return this?.updateReturnAddressInfo?.updateWarehouseEnable ?? false;
  }

  /**
   * Gets package status label
   * @returns {(string|undefined)}
   */
  get statusLabel() {
    return this?._statusInfo?.label;
  }

  /**
   * Gets package status description
   * @returns {(string|undefined)}
   */
  get statusDescription() {
    return this?._statusInfo?.description;
  }

  /**
   * Gets package status action required
   * @returns {({reasonDescription: string, reasonLabel: string}|undefined)}
   */
  get statusActionRequired() {
    return this?._statusInfo?.actionRequired;
  }

  /**
   * Gets package status last updated time
   * @returns {(string|undefined)}
   */
  get statusUpdatedTime() {
    return this?._statusInfo?.updatedTime;
  }

  /**
   * Gets package receiver name
   * @returns {(string|undefined)}
   */
  get receiverName() {
    return this?._deliveryInformation?.receiverName;
  }

  /**
   * Gets package receiver document
   * @returns {(string|undefined)}
   */
  get receiverDocument() {
    return this?._deliveryInformation?.receiverDocument;
  }

  /**
   * Gets package delivery location description
   * It's an enum that describes the receiver location
   * eg.CONCIERGE that is translated to "Na portaria"
   * @returns {(string|undefined)}
   */
  get deliveryLocationDescription() {
    return this?._deliveryInformation?.deliveryLocationDescription;
  }

  /**
   * Gets package delivery links related to proof of delivery
   * @returns {([{rel: string, href: string}]|undefined)}
   */
  get deliveryLinks() {
    return this?._deliveryInformation?.deliveryLinks;
  }

  /**
   * Gets package recipient fullname
   * @returns {(string|undefined)}
   */
  get recipientName() {
    return this?._recipient?.name;
  }

  /**
   * Gets package recipient phone
   * @returns {(string|undefined)}
   */
  get recipientPhone() {
    return this?._recipient?.phone;
  }

  /**
   * Gets package recipient email
   * @returns {(string|undefined)}
   */
  get recipientEmail() {
    return this?._recipient?.email;
  }

  /**
   * Gets package recipient nif
   * nif is a standardization for cpf or cnpj
   * @returns {(string|undefined)}
   */
  get recipientNif() {
    return this?._recipient?.nif;
  }

  /**
   * Gets origin address
   * @returns {(string|undefined)}
   */
  get originAddress() {
    return this?._origin?.address;
  }

  /**
   * Gets origin address postal code
   * @returns {(string|undefined)}
   */
  get originPostalCode() {
    return this?._origin?.postalCode;
  }

  /**
   * Gets destination address
   * @returns {(string|undefined)}
   */
  get destinationAddress() {
    return this?._destination?.address;
  }

  /**
   * Gets invoice number
   * @returns {(string|undefined)}
   */
  get invoiceNumber() {
    return this?._contentInvoice?.invoiceNumber;
  }

  /**
   * Gets invoice key
   * @returns {(string|undefined)}
   */
  get invoiceKey() {
    return this?._contentInvoice?.invoiceKey;
  }

  /**
   * Gets invoice value
   * @returns {(string|undefined)}
   */
  get invoiceValue() {
    return this?._contentInvoice?.invoiceValue;
  }

  /**
   * Gets invoice Series
   * @returns {(string|undefined)}
   */
  get invoiceSeries() {
    return this?._contentInvoice?.invoiceSeries;
  }

  /**
   * Gets package weight with unit (g|Kg)
   * @returns {string}
   */
  get weight() {
    const weight = parseInt(this._weight, 10) / 1000;

    if (Number.isNaN(weight)) return undefined;

    if (weight < 1) {
      return `${this._weight} g`;
    }

    return `${weight} Kg`;
  }

  /**
   * Gets package raw weight
   * @returns {string}
   */
  get weightG() {
    return this._weight;
  }

  /**
   * Gets package dimensions and weight
   * @returns {(string|undefined)}
   */
  get dimensions() {
    const { width, height, length } = this._dimensions || {};
    if (!height || !width || !length || !this._weight) {
      return undefined;
    }

    return `${height} x ${width} x ${length} - ${this.weight}`;
  }

  /**
   * Gets package raw dimensions
   * @returns {string}
   */
  get rawDimensions() {
    return this._dimensions || {};
  }

  /**
   * Gets package barcode
   * @returns {(string|undefined)}
   */
  get barcode() {
    return this?._redispatch?.barcode;
  }

  /**
   * Gets pickup redispatch address
   * @returns {(string|undefined)}
   */
  get redispatchPickupAddress() {
    return this?._redispatch?.pickupAddress;
  }

  /**
   * Gets redispatch pickup date
   * @returns {(string|undefined)}
   */
  get redispatchPickupDate() {
    return this?._redispatch?.pickupDate;
  }

  /**
   * Merge two packages
   * It's used on L1 packages search because the query results list is updated
   * with data from 2 different sources.
   * @returns {PackageModel}
   */
  merge(pkg) {
    const validData = Object.entries(pkg).filter(
      ([, value]) => value !== undefined
    );

    return Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
      validData.reduce(
        (result, [key, value]) => ({ ...result, [key]: value }),
        {}
      )
    );
  }
}

export default PackageModel;
