import wretch from 'wretch';
/**
 * pickupReceiptApi is a wretch instance for requests to PickupReceipt RestAPI.
 *
 * @return {Object}
 */
const pickupReceiptApi = wretch()
  .url(process.env.REACT_APP_LOGGI_WEB_URL)
  .options({ credentials: 'include' });

export default pickupReceiptApi;
