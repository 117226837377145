import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';

/**
 * SummaryDateItem's only responsibility is to show the date info and it's label.
 * @componentType: Presentational
 * @param label
 * @param date
 * @param dataTestId
 * @returns {*}
 */
const SummaryDateItem = ({ label, date, dataTestId }) => {
  return (
    <Box ml={{ xs: 2, sm: 1 }} pt={1} flex="1" data-testid={dataTestId}>
      <Typography variant="body1">{label}</Typography>
      <Typography variant="h5">
        <strong>{date}</strong>
      </Typography>
    </Box>
  );
};

SummaryDateItem.propTypes = {
  label: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired
};

export default SummaryDateItem;
