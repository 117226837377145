import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAmplifyAuth } from '@loggi/authentication-lib';

import { getCompanyData } from './service';
import Company from './Company';

const COMPANIES_ROUTE_REGEX = /(\/empresas\/)[0-9]*\//g;

const useCompany = () => {
  const [companyData, setCompanyData] = useState(undefined);
  const [shouldFetchCompanyData, setShouldFetchCompanyData] = useState(false);
  const { pathname } = useLocation();
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  useEffect(() => {
    setShouldFetchCompanyData(Boolean(authenticatedUser));
  }, [authenticatedUser]);

  useEffect(() => {
    const company = Company.getInstance();
    const companyRoute = pathname.match(COMPANIES_ROUTE_REGEX);
    const routeCompanyId = companyRoute?.length
      ? companyRoute[0]?.replace(/\D/g, '')
      : null;
    const hasCompanyChanged =
      company.getCompanyData().id !== Number(routeCompanyId);

    if (shouldFetchCompanyData && routeCompanyId) {
      if (company.isCompanyDataEmpty() || hasCompanyChanged) {
        getCompanyData(routeCompanyId)
          .then(currentCompany => {
            setCompanyData(currentCompany);
            return currentCompany;
          })
          .then(companyDataInfo =>
            company.setCompanyData(companyDataInfo ?? {})
          )
          .catch(() => setCompanyData({}));
      }
    } else {
      setCompanyData(company.getCompanyData());
    }
  }, [shouldFetchCompanyData, pathname]);

  return companyData ?? null;
};

export default useCompany;
