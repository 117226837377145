import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, CircularProgress, Dialog, Typography } from '@material-ui/core';

/**
 * LoadingDownloadReportModal's responsibility is to show a modal (dialog)
 * that indicates that the report will be downloaded soon.
 * @componentType: Presentational
 * @param open
 * @returns {*}
 */
const LoadingDownloadReportModal = ({ open }) => {
  const { t } = useTranslation('packages');

  return (
    <Dialog data-testid="loading-download-report-dialog" open={open}>
      <Box p={3} display="flex" flexDirection="row">
        <Box pr={3}>
          <CircularProgress />
        </Box>
        <Box>
          <Box>
            <Typography variant="body1">
              <strong>{t('customReport.requestModal.loading.title')}</strong>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">
              {t('customReport.requestModal.loading.subtitle')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

LoadingDownloadReportModal.propTypes = {
  open: PropTypes.bool
};

LoadingDownloadReportModal.defaultProps = {
  open: false
};

export default LoadingDownloadReportModal;
