import { useQueryParams } from '@loggi/components/src/one/hooks';
import qs from 'qs';
import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LIST_TYPE, SCHEDULING_STATUSES as STATUSES } from './constants';

const { isArray } = Array;

const removeFromFilter = (currentFilter, statusesToRemove) => {
  const updatedFilter = currentFilter.filter(
    status => !statusesToRemove.includes(status)
  );
  return updatedFilter;
};

const includeCancelledGroupOfStatuses = currentFilter => {
  const updatedFilter = new Set(currentFilter);
  updatedFilter.add(STATUSES.FAILED_TO_SCHEDULE);

  return Array.from(updatedFilter);
};

const includeWaitingPickupGroupOfStatuses = currentFilter => {
  const updatedFilter = new Set(currentFilter);

  updatedFilter.add(STATUSES.WAITING_VOLUME);
  updatedFilter.add(STATUSES.WAITING_CONFIRMATION);

  return Array.from(updatedFilter);
};

const updateFilter = filter => {
  let updatedFilter = filter;
  const waitingPickup = updatedFilter.some(
    status => status === STATUSES.WAITING_PICKUP
  );
  const cancelled = updatedFilter.some(status => status === STATUSES.CANCELLED);

  const cancelledStatuses = [STATUSES.FAILED_TO_SCHEDULE];

  const waitingPickupStatuses = [
    STATUSES.WAITING_VOLUME,
    STATUSES.WAITING_CONFIRMATION
  ];

  if (waitingPickup) {
    updatedFilter = includeWaitingPickupGroupOfStatuses(updatedFilter);
  }

  if (cancelled) {
    updatedFilter = includeCancelledGroupOfStatuses(updatedFilter);
  }

  if (!cancelled) {
    updatedFilter = removeFromFilter(updatedFilter, cancelledStatuses);
  }

  if (!waitingPickup) {
    updatedFilter = removeFromFilter(updatedFilter, waitingPickupStatuses);
  }

  return updatedFilter;
};

const useFilter = (filterName, listType) => {
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const queryParams = useQueryParams();

  const stringifiedQueryParams = JSON.stringify(queryParams);

  const setFilter = useCallback(
    (filter, clearPagination = false) => {
      const params = JSON.parse(stringifiedQueryParams);

      // To reset pagination when a filter is applied
      switch (listType) {
        case LIST_TYPE.SCHEDULINGS:
          delete params.page;
          break;
        case LIST_TYPE.RECURRING_PICKUPS:
          if (clearPagination) {
            delete params.recurring_page;
          }
          break;
        default:
          break;
      }

      // This condition exists because of a bug that qs has, in which it doesn't
      // remove the param from the generated string when it's an empty array
      // with the 'arrayFormat' option as 'comma'
      // Issue: https://github.com/ljharb/qs/issues/310
      if (isArray(filter)) {
        delete params[filterName];
        if (filter.length) {
          params[filterName] = updateFilter(filter);
        }
      } else {
        params[filterName] = filter;
      }

      const stringifiedParams = qs.stringify(params, {
        addQueryPrefix: true,
        arrayFormat: 'comma',
        encode: false,
        skipNulls: true
      });

      push(`${url}${stringifiedParams}`);
    },
    [filterName, url, push, stringifiedQueryParams, listType]
  );

  return [queryParams[filterName], setFilter];
};

export default useFilter;
