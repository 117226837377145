import { Box, ButtonBase, makeStyles, Popover } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useCheckCompanyStatus } from '@loggi/components/src/one/hooks';
import { colors } from '@loggi/mar';

import { useRemoteConfig } from '../../../../firebase';
import packagesRoutes from '../../../packages-routes';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    item: {
      '&:hover': {
        backgroundColor: palette.secondary.light,
        color: palette.primary.main,
        transition: 'all 0.2s ease-in-out'
      },
      [breakpoints.up('sm')]: {
        padding: spacing(1.5, 2)
      },
      color: palette.info.dark,
      display: 'flex',
      fontSize: typography.body1,
      fontWeight: typography.fontWeightBold,
      justifyContent: 'space-between',
      padding: spacing(2),
      width: '100%'
    },
    popover: {
      '& .MuiPopover-paper.MuiPaper-root': {
        [breakpoints.only('xs')]: {
          width: 'calc(100% - 48px)'
        },
        [breakpoints.up('sm')]: {
          width: typography.pxToRem(312)
        },
        marginTop: spacing(1)
      }
    },
    toggleButton: {
      borderColor: ({ isDefaulter }) =>
        isDefaulter ? colors.smoke[500] : palette.primary.contrastText,
      borderRadius: '50px',
      color: palette.primary.contrastText,
      justifyContent: 'space-between',
      minWidth: typography.pxToRem(183),
      paddingLeft: typography.pxToRem(16),
      paddingRight: typography.pxToRem(16),
      '&.Mui-selected, &.Mui-selected:hover': {
        color: palette.primary.contrastText,
        mixBlendMode: 'screen',
        transition: 'all 0.2s ease-in-out'
      },
      '&:disabled': {
        color: colors.smoke[500]
      }
    }
  })
);

const CreatePackageNavigation = () => {
  const { t } = useTranslation('packages');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const menuRef = useRef(null);
  const { companyId } = useParams();
  const {
    create: { singlePackage, spreadsheet }
  } = packagesRoutes;
  const toggleButtonLabel = t('list.createPackages');
  const { isDefaulter } = useCheckCompanyStatus();
  const styles = useStyles({ isDefaulter });

  const { value: enableInvoiceImport } = useRemoteConfig(
    'enable_invoice_import'
  );

  let navigationItems = [];

  if (enableInvoiceImport === 'true') {
    navigationItems.push({
      key: 'invoice',
      label: t('list.navigation.invoice'),
      url: packagesRoutes.importInvoice.url(companyId)
    });
  }

  navigationItems = [
    ...navigationItems,
    {
      key: 'spreadsheet',
      label: t('list.navigation.spreadsheet'),
      url: spreadsheet.upload.url(companyId)
    },
    {
      key: 'single-package',
      label: t('list.navigation.singlePackage'),
      url: singlePackage.url(companyId)
    }
  ];

  return (
    <>
      <ToggleButton
        className={styles.toggleButton}
        data-testid="toggle-btn"
        onChange={() => setIsPopoverOpen(!isPopoverOpen)}
        ref={menuRef}
        selected={isPopoverOpen}
        size="small"
        value={toggleButtonLabel}
        variant="outlined"
        disabled={isDefaulter}
      >
        <Box mr={1}>{toggleButtonLabel}</Box>
        {isPopoverOpen ? (
          <KeyboardArrowUp fontSize="small" />
        ) : (
          <KeyboardArrowDown fontSize="small" />
        )}
      </ToggleButton>
      <Popover
        anchorEl={menuRef.current}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        className={styles.popover}
        elevation={24}
        marginThreshold={24}
        onClose={() => setIsPopoverOpen(false)}
        open={isPopoverOpen}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {navigationItems.map(item => (
          <ButtonBase
            className={styles.item}
            component={Link}
            data-testid={item.key}
            key={item.key}
            onClick={() => setIsPopoverOpen(false)}
            to={item.url}
            variant="button"
          >
            {item.label}
          </ButtonBase>
        ))}
      </Popover>
    </>
  );
};

export default CreatePackageNavigation;
