import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { getShipperStatusCodeByLabel } from '../package-utils/status.helpers';
import colorMap from './color-mapper';
import StatusIndicator from './status-indicator';
import StatusIndicatorTag from './status-indicator-tag';

const StatusIndicatorPackages = props => {
  const theme = useTheme();

  const { status, noLabel, isTag } = props;

  const getStatusColor = () => {
    const shipperPackageStatusCode = getShipperStatusCodeByLabel(status);
    const color = colorMap[shipperPackageStatusCode]?.primary;

    return color || theme.palette.primary.main;
  };

  const statusMap = {
    [status]: {
      color: getStatusColor(),
      label: status
    }
  };

  return isTag ? (
    <StatusIndicatorTag statusMap={statusMap} status={status} />
  ) : (
    <StatusIndicator statusMap={statusMap} status={status} noLabel={noLabel} />
  );
};

StatusIndicatorPackages.propTypes = {
  status: PropTypes.string.isRequired,
  noLabel: PropTypes.bool,
  isTag: PropTypes.bool
};

StatusIndicatorPackages.defaultProps = {
  noLabel: false,
  isTag: false
};

export default StatusIndicatorPackages;
