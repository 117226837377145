import { authenticationLocales } from '@loggi/authentication';
import { componentsLocales } from '@loggi/components';
import { translations as managementTranslations } from '@loggi/management';
import { translations as packagesTranslations } from '@loggi/packages';
import { translations as paymentsTranslations } from '@loggi/payments';
import { pickupReceiptLocales } from '@loggi/pickup-receipt';
import { pickupOrderScheduleLocales } from '@loggi/pickup-order-schedule';
import { pickupsLocales } from '@loggi/pickups';
import { trackingLocales } from '@loggi/tracking';
import { usersManagementLocales } from '@loggi/users-management';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translations as componentsTranslations } from '../components';
import oneLocales from '../locales';

/**
 * i18next configuration
 */
i18n.use(initReactI18next).init({
  resources: {
    'pt-BR': {
      ...componentsLocales['pt-BR'],
      ...componentsTranslations['pt-BR'],
      ...managementTranslations['pt-BR'],
      ...oneLocales.ptBr,
      ...packagesTranslations['pt-BR'],
      ...paymentsTranslations['pt-BR'],
      ...pickupReceiptLocales.ptBr,
      ...pickupsLocales.ptBr,
      ...trackingLocales.ptBr,
      ...usersManagementLocales.ptBr,
      ...authenticationLocales.ptBr,
      ...pickupOrderScheduleLocales.ptBr
    },
    en: {
      ...componentsTranslations.en,
      ...packagesTranslations.en
    }
  },
  lng: 'pt-BR',
  fallbackLng: 'pt-BR'
});

export default i18n;
