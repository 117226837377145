import React, { useEffect, useReducer } from 'react';
import { Box, InputBase, makeStyles, useTheme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import userFilter from './user-list-filter/userFilter';

const useStyles = makeStyles(({ typography, palette }) => ({
  inputText: {
    fontWeight: typography.fontWeightBold,
    color: palette.grey[500],
    textOverflow: 'ellipsis'
  }
}));

// eslint-disable-next-line consistent-return
const reducer = (state, { type, payload }) => {
  if (type === 'updateSearchValue') {
    return {
      clearPagination: true,
      value: payload.value
    };
  }
};

const INITIAL_STATE = {
  clearPagination: false,
  value: ''
};

const UserListSearchBar = () => {
  const { t } = useTranslation('listUsers');
  const classes = useStyles();
  const theme = useTheme();
  const [, setFilter] = userFilter('email');
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { clearPagination, value } = state;
  const debounceDelayInMs = 600;

  useEffect(() => {
    const debounceId = setTimeout(() => {
      setFilter(value, clearPagination);
    }, debounceDelayInMs);
    return () => clearTimeout(debounceId);

    //     /*
    //      * Passing setFilter function to dependency array, have an undeserided
    //      * effect of runnin effect twice, for every state change. That's why
    //      * linter rule was disabled in this effect callback.
    //      */
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      display="flex"
      borderRadius={100}
      alignItems="center"
      pl={3}
      pr={3}
      pt={1.5}
      pb={1.5}
      mt={3}
      bgcolor={theme.palette.common.white}
    >
      <SearchIcon color="primary" />
      <Box display="flex" pl={1} align-items="stretch" flexGrow={1}>
        <InputBase
          fullWidth
          id="filter-receiver-name-input"
          inputProps={{
            'data-testid': 'filter-receiver-name-input',
            variant: 'body2',
            className: classes.inputText
          }}
          name="filter-receiver-name-input"
          onChange={event => {
            dispatch({
              type: 'updateSearchValue',
              payload: {
                clearPagination: true,
                value: event.target.value
              }
            });
          }}
          value={value}
          variant="body2"
          color="primary"
          placeholder={t('filter.searchPlaceholder')}
          type="search"
        />
      </Box>
    </Box>
  );
};

export default UserListSearchBar;
