import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import emptyPickupsIllustration from '@loggi/components/src/one/shared/images/packages-error.svg';

const useStyles = makeStyles(({ typography }) => ({
  title: {
    fontWeight: typography.fontWeightBold
  },
  image: {
    width: '100%',
    maxWidth: typography.pxToRem(155)
  }
}));

const DetailsPackagesError = () => {
  const classes = useStyles();
  const { t } = useTranslation('packages');

  return (
    <Box
      alignItems="center"
      className={classes.container}
      data-testid="error-wrapper"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      m="auto"
      textAlign="center"
      width={0.75}
      maxWidth={486}
    >
      <img
        className={classes.image}
        src={emptyPickupsIllustration}
        alt="Truck"
      />
      <Box mt={3.5} mb={2}>
        <Box mb={2}>
          <Typography className={classes.title} variant="subtitle1">
            {t('errorDetailsPackages.title')}
          </Typography>
        </Box>
        <Typography variant="subtitle1" component="p">
          {t('errorDetailsPackages.subtitle')}
        </Typography>
      </Box>
    </Box>
  );
};

export default DetailsPackagesError;
