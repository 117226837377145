const getToastParameters = warningValue => {
  let toastMessage = warningValue;
  let toastType = 'success';

  switch (toastMessage) {
    case 'REDISPATCH_CANCELLATION_WARNING_MESSAGE':
      toastMessage = 'details.actions.cancel.warningWithCustodyCorreios';
      toastType = 'warning';
      break;
    case 'EN_ROUTE_CANCELLATION_WARNING_MESSAGE':
      toastMessage = 'details.actions.cancel.warningPackageEnRoute';
      toastType = 'warning';
      break;
    case 'REDISPATCH_ALREADY_CANCELLED_WARNING_MESSAGE':
      toastMessage = 'details.actions.cancel.warningRedispatchAlreadyCancelled';
      toastType = 'warning';
      break;
    default:
      toastMessage = 'details.actions.cancel.successfulAction';
  }

  return { message: toastMessage, type: toastType };
};

const getErrorToastMessage = error => {
  let toastMessage;

  if (error?.response) {
    const errorMessage = JSON.parse(error.message);
    const errorType = errorMessage ? errorMessage.type : '';

    switch (errorType) {
      case 'CancelPackageException':
        toastMessage = 'details.actions.cancel.failedRequest';
        break;
      case 'CancelPackageNotFoundException':
        toastMessage = 'details.actions.cancel.failedRequest';
        break;
      case 'CancelPackageNotAllowedException':
        toastMessage = 'details.actions.cancel.failedUserPermission';
        break;
      case 'CancelPackageFinalizerStatusException':
        toastMessage = 'details.actions.cancel.failedAction';
        break;
      case 'CancelPackageCorreiosCustodyException':
        toastMessage = 'details.actions.cancel.failedWithCustodyCorreios';
        break;
      case 'CancelPackageEnRouteException':
        toastMessage = 'details.actions.cancel.failedPackageEnRoute';
        break;
      case 'CancelPackageAlreadyReturnedException':
        toastMessage = 'details.actions.cancel.failedAlreadyReturned';
        break;
      case 'CancelPackageRedispatchCustodyException':
        toastMessage = 'details.actions.cancel.failedRequest';
        break;
      default:
        toastMessage = 'details.actions.cancel.failedRequest';
    }
  } else {
    toastMessage = 'details.actions.cancel.failedRequest';
  }

  return toastMessage;
};

export default { getToastParameters };
export { getToastParameters, getErrorToastMessage };
