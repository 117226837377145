import { useAmplifyAuth } from '@loggi/authentication-lib';
import { AppErrorBoundary } from '@loggi/components/src/one';
import { useFeature } from '@loggi/components/src/one/remote-config';
import React from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { Menu, routes, IntegratorPage } from '../management';
import { getManagementRoutes, getIntegratorRoutes } from './get-routes';

const App = () => {
  const {
    state: { authenticatedUser, isImpersonation }
  } = useAmplifyAuth();
  const { companyId } = useParams();
  const enableWarehousesList = !useFeature('disable_warehouses_list');
  const enableIntegratorList = useFeature('enable_integrator_list');
  const enableCompanyDataManagement = useFeature(
    'enable_company_data_management'
  );
  const managementRoutes = getManagementRoutes(
    authenticatedUser,
    companyId,
    enableWarehousesList,
    enableIntegratorList,
    enableCompanyDataManagement
  );
  const allowedRoutes = managementRoutes.filter(
    ({ visible }) => visible || isImpersonation
  );

  const integratorRoutes = getIntegratorRoutes(authenticatedUser, companyId);
  const allowedIntegratorRoutes = integratorRoutes.filter(
    ({ visible }) => visible || isImpersonation
  );

  return (
    <AppErrorBoundary
      beforeCapture={scope => {
        scope.setTag('app.region', 'management');
      }}
    >
      <Switch>
        <Route exact path={routes.index.path} component={Menu} />
        {allowedRoutes.map(({ id, path, component }) => (
          <Route exact key={id} path={path} component={component} />
        ))}
        {allowedIntegratorRoutes.map(({ id, path, integrator }) => (
          <Route
            exact
            key={id}
            path={path}
            render={() => <IntegratorPage integrator={integrator} />}
          />
        ))}
        <Redirect to={routes.index.url(companyId)} />
      </Switch>
    </AppErrorBoundary>
  );
};

export default App;
