import { useAmplifyAuth } from '@loggi/authentication-lib';
import {
  accessItems,
  getUserRole
} from '@loggi/components/src/one/permissions';
import React from 'react';
import CompanyHierarchy from '@loggi/components/src/one/entity/CompanyHierarchy';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCurrentCompany } from '../auth/index';
import { useRemoteConfig } from '../firebase';
import { MENU_ITEMS } from './header.constants';
import MenuLink from './menu-link.component';

const Menu = () => {
  const { t } = useTranslation('components');

  const { pathname } = useLocation();
  const accessDeniedScreen = pathname.includes('acesso-negado');
  const currentCompany = useCurrentCompany();
  const currentCompanyId = currentCompany?.id;

  const match = useRouteMatch('/empresas/:companyId');
  const companyIdFromRoute = match?.params?.companyId;
  const { value: enableHierarchyRoute } = useRemoteConfig(
    'enable_hierarchy_company_in_menu_route'
  );

  let routeCompanyId = companyIdFromRoute;
  if (enableHierarchyRoute === 'true') {
    routeCompanyId =
      CompanyHierarchy.getInstance().getLoggedCompanyId() ?? companyIdFromRoute;
  }

  const companyId = accessDeniedScreen ? currentCompanyId : routeCompanyId;

  const {
    state: { authenticatedUser, isImpersonation }
  } = useAmplifyAuth();
  const { value: isPaymentsEnabled } = useRemoteConfig('enable_payments_app');

  const userRole = getUserRole(authenticatedUser, companyId);
  const menuItems = [
    {
      id: 'tracking',
      label: t(MENU_ITEMS.TRACKING.label),
      route: MENU_ITEMS.TRACKING.route(companyId),
      visible: accessItems.tracking.includes(userRole)
    },
    {
      id: 'packages',
      label: t(MENU_ITEMS.PACKAGES.label),
      route: MENU_ITEMS.PACKAGES.route(companyId),
      visible: accessItems.packages.includes(userRole)
    },
    {
      id: 'payments',
      label: t(MENU_ITEMS.PAYMENTS.label),
      route: MENU_ITEMS.PAYMENTS.route(companyId),
      visible:
        isPaymentsEnabled === 'true' && accessItems.finance.includes(userRole)
    }
  ];

  return (
    <>
      {menuItems.map(({ id, label, route, visible }) => {
        return (
          (visible || isImpersonation) && (
            <MenuLink key={id} to={route}>
              {label}
            </MenuLink>
          )
        );
      })}
    </>
  );
};
export default Menu;
