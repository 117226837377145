import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StatusIndicatorInvoicePayment from '@loggi/components/src/one/status-indicator/status-indicator-invoices-payment';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      marginTop: spacing(3),
      marginRight: spacing(16),
      marginBottom: spacing(1)
    }
  }
}));

const InvoiceItem = ({ invoice, onClickDownload }) => {
  const { interval, startDate, endDate, amount, paymentStatus } = invoice;
  const classes = useStyles();
  const { t } = useTranslation('payments');
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const period = t('invoices.invoicePeriod', {
    startDate,
    endDate
  });

  return (
    <ListItem disableGutters style={{ padding: 0 }}>
      <Box
        display="flex"
        flexDirection="column"
        width={1}
        px={{ xs: 3, sm: 5 }}
        py={{ xs: 1, sm: 3 }}
      >
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          width={1}
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column">
            <Box
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              data-testid="content-status"
            >
              <StatusIndicatorInvoicePayment
                endDate={endDate}
                paymentStatus={paymentStatus}
              />
            </Box>
            <Typography variant="subtitle1">
              <Box
                mb={1}
                component="strong"
                fontWeight="fontWeightBold"
                display="flex"
              >
                {interval}
              </Box>
            </Typography>
            <Box>
              <Typography variant="subtitle2" color="textSecondary">
                {period}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" color="textSecondary">
                {amount}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.buttonContainer}>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="outlined"
              onClick={() => onClickDownload(invoice)}
              data-testid="item-download-button"
            >
              <Typography variant="button">
                {t('invoices.downloadButton')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};

InvoiceItem.propTypes = {
  invoice: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    paymentStatus: PropTypes.string.isRequired
  }).isRequired,
  onClickDownload: PropTypes.func.isRequired
};

export default InvoiceItem;
