import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import PeriodSection from './period-section.component';
import PeriodSectionDrawerExplain from './period-section-drawer.component';
import { IS_MORNING } from './constants';
import { getPickupPeriodFromStartTime } from './unified-pickup-helpers';
import SectionTitle from './section-title.component';

const PeriodTimeSection = () => {
  const { t } = useTranslation('orderScheduling');
  const { values } = useFormikContext();
  const periodSelectedIsMorning =
    getPickupPeriodFromStartTime(values.pickupStartTime) === IS_MORNING;
  const textMorning = 'descriptionBoldPeriodMorning';
  const textAfternoon = 'descriptionBoldPeriodAfternoon';

  return (
    <>
      <Box alignItems="center" display="flex" data-testid="pickup-time-section">
        <SectionTitle>
          {t('create.dateSection.periodTimeSection.title')}
        </SectionTitle>
      </Box>
      <Box mb={4}>
        <Box>
          <PeriodSection />
        </Box>
        <Box mt={3}>
          <Typography variant="body2" color="textSecondary">
            {t(
              'create.dateSection.periodSection.sectionExplainPeriodSelect.descriptionPeriod'
            )}
            <strong>
              {t(
                `create.dateSection.periodSection.sectionExplainPeriodSelect.${
                  periodSelectedIsMorning ? textMorning : textAfternoon
                }`
              )}
            </strong>
            <Box>
              <PeriodSectionDrawerExplain />
            </Box>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PeriodTimeSection;
