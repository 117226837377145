import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PageTitle = ({ title }) => {
  const { t } = useTranslation('management');

  return (
    <Typography variant="h4" component="h1">
      <Box component="strong" fontWeight="fontWeightBold">
        {t(title)}
      </Box>
    </Typography>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default PageTitle;
