import PropTypes from 'prop-types';

const ReturnAddressFormShape = {
  noreturnAddressComplement: PropTypes.bool,
  returnAddress: PropTypes.shape({
    description: PropTypes.string,
    place_id: PropTypes.string
  }),
  returnAddressComplement: PropTypes.string,
  responsibleName: PropTypes.string,
  responsiblePhone: PropTypes.string,
  returnAddressInstructions: PropTypes.string,
  returnAddressAlias: PropTypes.string
};

export default ReturnAddressFormShape;
