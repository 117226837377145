import { Box, Hidden, makeStyles, Typography } from '@material-ui/core';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  BusinessRounded
} from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CompaniesListMenu from './companies-list-menu';
import { formattedCNPJ } from '../utils';

const useStyles = makeStyles(({ breakpoints, palette, typography }) => ({
  companyListButton: {
    color: ({ isOutOfCoverageStatus }) =>
      isOutOfCoverageStatus
        ? palette.primary.main
        : palette.primary.contrastText,
    [breakpoints.down('xs')]: {
      borderColor: ({ isOutOfCoverageStatus }) =>
        isOutOfCoverageStatus
          ? palette.primary.main
          : palette.primary.contrastText,
      borderRadius: typography.pxToRem(50),
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: ({ isOutOfCoverageStatus }) =>
          isOutOfCoverageStatus
            ? palette.primary.main
            : palette.primary.contrastText,
        color: '#000',
        mixBlendMode: 'screen',
        transition: 'all 0.2s ease-in-out'
      }
    },
    [breakpoints.up('sm')]: {
      border: 'none',
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: 'inherit',
        color: ({ isOutOfCoverageStatus }) =>
          isOutOfCoverageStatus
            ? palette.primary.main
            : palette.primary.contrastText
      },
      '& .MuiTouchRipple-root': {
        display: 'none'
      }
    }
  },
  companyInfo: {
    maxWidth: typography.pxToRem(186),
    paddingRight: typography.pxToRem(6),
    textAlign: 'right',
    '& p': {
      display: 'block',
      maxWidth: typography.pxToRem(146),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    '& p:first-child': {
      fontWeight: 'bold',
      fontSize: typography.pxToRem(14)
    },
    '& p:last-child': {
      fontWeight: 'normal',
      fontSize: typography.pxToRem(12)
    }
  }
}));

const CompaniesListButton = ({
  hasCompanyDetails,
  shouldHideCompanyDetails,
  selectedCompany,
  hasCompanyFromRoute,
  companies,
  isOutOfCoverage
}) => {
  const { t } = useTranslation('components');
  const styles = useStyles({ isOutOfCoverageStatus: isOutOfCoverage });
  const [isCompaniesPopoverOpen, setIsCompaniesPopoverOpen] = useState(false);
  const menuRef = useRef(null);
  const hideSelectedCompanyDetails =
    !hasCompanyDetails || shouldHideCompanyDetails;

  return (
    <Box height="fit-content">
      <ToggleButton
        className={styles.companyListButton}
        onChange={() => setIsCompaniesPopoverOpen(!isCompaniesPopoverOpen)}
        ref={menuRef}
        selected={isCompaniesPopoverOpen}
        size="small"
        value
        variant="outlined"
        data-testid="companies-list-menu-btn-id"
      >
        <Hidden smUp={hideSelectedCompanyDetails} xsDown>
          <Box className={styles.companyInfo}>
            <Typography>
              {hasCompanyDetails &&
                (selectedCompany.shared_name || selectedCompany.name)}
            </Typography>
            <Typography>
              {t('header.companiesMenu.cnpj')}:{' '}
              {hasCompanyDetails && formattedCNPJ(selectedCompany.cnpj)}
            </Typography>
          </Box>
        </Hidden>
        <Hidden xsDown>
          {isCompaniesPopoverOpen ? (
            <KeyboardArrowUp fontSize="small" />
          ) : (
            <KeyboardArrowDown fontSize="small" />
          )}
        </Hidden>
        <Hidden smUp={!hideSelectedCompanyDetails}>
          <BusinessRounded />
        </Hidden>
      </ToggleButton>
      <CompaniesListMenu
        anchorElement={menuRef.current}
        companies={companies}
        handleClose={() => setIsCompaniesPopoverOpen(false)}
        handleOpen={() => setIsCompaniesPopoverOpen(true)}
        hasCompany={hasCompanyFromRoute}
        open={isCompaniesPopoverOpen}
        shouldRemoveCompanySelection={shouldHideCompanyDetails}
      />
    </Box>
  );
};

CompaniesListButton.propTypes = {
  hasCompanyDetails: PropTypes.bool.isRequired,
  shouldHideCompanyDetails: PropTypes.bool.isRequired,
  selectedCompany: PropTypes.shape([
    PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        shared_name: PropTypes.string,
        cnpj: PropTypes.string
      })
    )
  ]),
  hasCompanyFromRoute: PropTypes.bool.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape([
      PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          shared_name: PropTypes.string,
          cnpj: PropTypes.string
        })
      )
    ])
  ),
  isOutOfCoverage: PropTypes.bool
};

CompaniesListButton.defaultProps = {
  companies: [],
  selectedCompany: undefined,
  isOutOfCoverage: false
};

export default CompaniesListButton;
