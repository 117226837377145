import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

const DownloadButton = ({ id, title, url }) => {
  const {
    location: { pathname },
    push
  } = useHistory();

  /*
  As we dont trigger click events on GA,
  we added download page views (on query string) to collect
  downloading document user action.
  */
  return (
    <Box pt={3}>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        disabled={!url}
        href={url}
        onClick={() => push(`${pathname}?download=${id}`)}
        data-testid="download-button"
      >
        {title}
      </Button>
    </Box>
  );
};

DownloadButton.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string
};

DownloadButton.defaultProps = {
  url: ''
};

export default DownloadButton;
