import { Button, fade, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    backgroundColor: 'transparent',
    color: palette.primary.contrastText,
    mixBlendMode: 'screen',
    '& + &': {
      marginLeft: spacing(1),
      [breakpoints.up('sm')]: {
        marginLeft: spacing(2)
      }
    },
    '&:hover': {
      backgroundColor: fade(palette.grey[900], 0.04),
      mixBlendMode: 'initial'
    }
  },
  active: {
    '&.active': {
      backgroundColor: '#FFF',
      color: '#000',
      mixBlendMode: 'screen',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        backgroundColor: '#FFF'
      }
    }
  }
}));

const MenuLink = ({ children, to }) => {
  const styles = useStyles();

  return (
    <Button
      className={`${styles.root} ${styles.active}`}
      component={RouterLink}
      to={to}
    >
      {children}
    </Button>
  );
};

MenuLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
};

export default MenuLink;
