import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Error } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Link, Typography } from '@material-ui/core';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { getUserRole, ROLES } from '@loggi/components/src/one/permissions';

import { MENU_ITEMS } from '../header.constants';

const useCompanyStatusAlertStyle = makeStyles(
  ({ breakpoints, palette, spacing }) => ({
    alert: {
      height: spacing(7),
      [breakpoints.down('sm')]: {
        height: spacing(15)
      },
      [breakpoints.down('xs')]: {
        height: spacing(20)
      }
    },
    alertIcon: {
      alignSelf: 'flex-start',
      marginTop: spacing(0.3)
    },
    financeLink: {
      color: palette.info.dark,
      textDecoration: 'underline'
    }
  })
);

const CompanyStatusAlert = () => {
  const { t } = useTranslation('companyStatusTranslations');
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const routeParams = useRouteMatch('/empresas/:companyId');
  const styles = useCompanyStatusAlertStyle();
  const companyId = routeParams?.params?.companyId;
  const userRole = getUserRole(authenticatedUser, companyId);
  const isOperator =
    userRole === ROLES.OPERATOR || userRole === ROLES.OPERATOR_LARGE;
  const {
    PAYMENTS: { route }
  } = MENU_ITEMS;
  const alertMessage = isOperator
    ? t('alert.messages.operator')
    : t('alert.messages.default');

  return (
    <Box
      data-testid="company-status-alert"
      className={styles.alert}
      bgcolor="warning.main"
      display="flex"
      position="sticky"
      width="100%"
      zIndex="appBar"
      top={0}
      left={0}
      py={5}
    >
      <Container component={Box} style={{ display: 'flex' }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <Error className={styles.alertIcon} fontSize="small" />
            <Box pl={{ xs: 2 }} clone>
              <Typography variant="body1">
                <strong>{t('alert.strong')}</strong>
                {alertMessage}
                {!isOperator && (
                  <Link
                    data-testid="redirect-to-finance-link"
                    className={styles.financeLink}
                    href={route(companyId)}
                  >
                    {t('alert.financeLink')}
                  </Link>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CompanyStatusAlert;
