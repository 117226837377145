import React from 'react';
import PropTypes from 'prop-types';

import StatusIndicator from './status-indicator';
import { PAYMENT_STATUS } from '../constants';

const StatusIndicatorInvoicePayment = ({ paymentStatus, noLabel, endDate }) => {
  return (
    <StatusIndicator
      statusMap={PAYMENT_STATUS}
      status={paymentStatus}
      noLabel={noLabel}
      endDate={endDate}
    />
  );
};

StatusIndicatorInvoicePayment.propTypes = {
  paymentStatus: PropTypes.string.isRequired,
  noLabel: PropTypes.bool,
  endDate: PropTypes.string
};

StatusIndicatorInvoicePayment.defaultProps = {
  noLabel: false,
  endDate: ''
};

export default StatusIndicatorInvoicePayment;
