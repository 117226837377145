import { subDays } from 'date-fns';

const getReportRangeDate = (date, startDateParam, endDateParam) => {
  // Date from days filter
  let endDate = new Date();
  let startDate = subDays(endDate, date);

  // Date from Date Picker filter
  if (startDateParam && endDateParam) {
    const [startDateDay, startDateMonth, startDateYear] = startDateParam.split(
      '-'
    );

    const [endDateDay, endDateMonth, endDateYear] = endDateParam.split('-');

    startDate = new Date(startDateYear, startDateMonth - 1, startDateDay);
    endDate = new Date(endDateYear, endDateMonth - 1, endDateDay);
  }

  return {
    startDateTime: startDate.toISOString(),
    endDateTime: endDate.toISOString()
  };
};

export default getReportRangeDate;
