import receiptOrderSchedule from './receipt-order-schedule.json';
import auth from './auth.json';
import pickupReceiptsList from './pickup-receipts-list.json';
import pickupReceiptFeedback from './pickup-receipts-feedback.json';
import receiptPackagesList from './receipt-packages-list.json';
import pickupReceiptFooter from './pickup-receipt-footer.json';
import receiptGenericError from './receipt-generic-error.json';

export default {
  auth,
  receiptOrderSchedule,
  pickupReceiptFeedback,
  pickupReceiptsList,
  pickupReceiptFooter,
  receiptPackagesList,
  receiptGenericError
};
