import shipperPackageStatusJSON from '@loggi/external-proto/xproto/json/status/custom_shipper_status_codes_v2.json';
import shipperPackageStatusV1JSON from '@loggi/external-proto/xproto/json/status/custom_shipper_package_status_codes.json';
import {
  CUSTOM_SHIPPER_PACKAGE_STATUS_INTEGRATION_FAILURE_LOGGI_CODE,
  CUSTOM_SHIPPER_PACKAGE_STATUS_INTEGRATION_FAILURE_SHIPPER_CODE,
  CUSTOM_SHIPPER_PACKAGE_STATUS_PENDING_CUSTOMER_PICKUP,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_ADDED,
  STATUSES_WITH_PROBLEM_GROUP_V2,
  STATUSES_WITH_PROBLEM_GROUP_V1,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_DELIVERED,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_RETURNING_COMPLETED,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_WITH_PROBLEM,
  FINAL_STATUSES_GROUP_V2,
  FINAL_STATUSES_GROUP_V1,
  CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_ON_DELIVERY_ROUTE
} from './status-consts';

let enableShipperV2 = false;
export const setEnableShipperV2 = featureSwitchValue => {
  enableShipperV2 = featureSwitchValue;
};

export const shipperPackageStatusToRemove = [
  CUSTOM_SHIPPER_PACKAGE_STATUS_INTEGRATION_FAILURE_SHIPPER_CODE,
  CUSTOM_SHIPPER_PACKAGE_STATUS_INTEGRATION_FAILURE_LOGGI_CODE,
  CUSTOM_SHIPPER_PACKAGE_STATUS_PENDING_CUSTOMER_PICKUP
];

/**
 * Return the shipper status JSON file without some undesirable statuses
 */
export const getShipperPackageStatusFromJson = () => {
  shipperPackageStatusToRemove.forEach(key => {
    delete shipperPackageStatusV1JSON[key];
    delete shipperPackageStatusJSON[key];
  });

  return {
    shipperPackageStatus: shipperPackageStatusJSON,
    shipperPackageStatusV1: shipperPackageStatusV1JSON
  };
};

const {
  shipperPackageStatus,
  shipperPackageStatusV1
} = getShipperPackageStatusFromJson();

/**
 *
 * @param {string} shipperPackageStatusName Shipper Status NAME | Const
 * @returns shipperStatusCode
 */
export const getShipperStatusCodeByName = shipperPackageStatusName => {
  if (!enableShipperV2) {
    return Number(
      Object.keys(shipperPackageStatusV1).find(
        shipperPackageStatusCode =>
          shipperPackageStatusV1[shipperPackageStatusCode]?.name ===
          shipperPackageStatusName
      )
    );
  }

  return Number(
    Object.keys(shipperPackageStatus).find(
      shipperPackageStatusCode =>
        shipperPackageStatus[shipperPackageStatusCode]?.name ===
        shipperPackageStatusName
    )
  );
};

/**
 * Receive a shipper package status label and returns it's code.
 * @param number - shipperPackageStatusLabel
 */
export const getShipperStatusCodeByLabel = (
  shipperPackageStatusLabel,
  lang = 'pt_br'
) => {
  if (!enableShipperV2) {
    return Number(
      Object.keys(shipperPackageStatusV1).find(
        shipperPackageStatusCode =>
          shipperPackageStatusV1[shipperPackageStatusCode]?.label[lang] ===
          shipperPackageStatusLabel
      )
    );
  }

  return Number(
    Object.keys(shipperPackageStatus).find(
      shipperPackageStatusCode =>
        shipperPackageStatus[shipperPackageStatusCode]?.label[lang] ===
        shipperPackageStatusLabel
    )
  );
};

/**
 * Receive a shipper package status code and returns it's loggi package status code array group.
 * @param number - shipperPackageStatusCode
 */
export const getShipperPackageStatusRefs = shipperPackageStatusCode => {
  if (!enableShipperV2) {
    return shipperPackageStatusV1[shipperPackageStatusCode]?.ref;
  }

  return shipperPackageStatus[shipperPackageStatusCode]?.ref;
};

/**
 * Returns an array of all the shipper package status codes.
 */
export const getAllShipperPackageStatusCodes = () => {
  if (!enableShipperV2) {
    return Object.keys(shipperPackageStatusV1);
  }

  return Object.keys(shipperPackageStatus);
};

export const shouldDisplayActionRequired = () => {
  return !enableShipperV2;
};

/**
 * Receive a shipper package status code and loggi package status Code then returns it's action required.
 * @param number - shipperPackageStatusCode
 * @param number - loggiPackageStatusCode
 */
export const getShipperPackageStatusActionRequired = (
  shipperPackageStatusCode,
  loggiPackageStatusCode,
  lang = 'pt_br'
) => {
  let actionRequired;
  if (!enableShipperV2) {
    actionRequired =
      shipperPackageStatusV1[shipperPackageStatusCode]?.['action_required']?.[
        loggiPackageStatusCode
      ];
  } else {
    actionRequired =
      shipperPackageStatus[shipperPackageStatusCode]?.['action_required']?.[
        loggiPackageStatusCode
      ];
  }

  const { reason_label: reasonLabel, reason_description: reasonDescription } =
    actionRequired || {};

  return actionRequired
    ? {
        reasonDescription: reasonDescription?.[lang],
        reasonLabel: reasonLabel?.[lang]
      }
    : actionRequired;
};

/**
 * Receive a shipper package status code and returns it's label text.
 * @param number - shipperPackageStatusCode
 */
export const getShipperPackageStatusLabel = (
  shipperPackageStatusCode,
  lang = 'pt_br'
) => {
  if (!enableShipperV2) {
    return shipperPackageStatusV1[shipperPackageStatusCode]?.label[lang];
  }

  return shipperPackageStatus[shipperPackageStatusCode]?.label[lang];
};

const finalStatusLabels = () =>
  !enableShipperV2
    ? FINAL_STATUSES_GROUP_V1.map(code => getShipperPackageStatusLabel(code))
    : FINAL_STATUSES_GROUP_V2.map(code => getShipperPackageStatusLabel(code));

const deliveredStatusLabel = () =>
  getShipperPackageStatusLabel(CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_DELIVERED);

const returnedStatusLabel = () =>
  getShipperPackageStatusLabel(
    CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_RETURNING_COMPLETED
  );

const issuedShipperStatusLabel = () =>
  getShipperPackageStatusLabel(CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_WITH_PROBLEM);

const isPackageDelivered = status => {
  return status === deliveredStatusLabel();
};

const isPackageReturned = status => {
  return status === returnedStatusLabel();
};

const isPackageStatusAdded = statusCode => {
  return statusCode === CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_ADDED;
};

const isPackageAtFinalStatus = status => {
  return finalStatusLabels().includes(status);
};

const isPackageEnRoute = status => {
  return status === CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_ON_DELIVERY_ROUTE;
};

const waitingPickupStatusLabel = () => getShipperPackageStatusLabel(10);

const isPackageWaitingRedispatchPickup = status => {
  return status === waitingPickupStatusLabel();
};

const isPackageIssued = status => {
  return issuedShipperStatusLabel().includes(status);
};

const SHIPPER_CODES_WITH_PROBLEM = () =>
  enableShipperV2
    ? STATUSES_WITH_PROBLEM_GROUP_V2
    : STATUSES_WITH_PROBLEM_GROUP_V1;

export {
  isPackageDelivered,
  isPackageReturned,
  isPackageAtFinalStatus,
  isPackageEnRoute,
  finalStatusLabels,
  isPackageIssued,
  isPackageWaitingRedispatchPickup,
  isPackageStatusAdded,
  SHIPPER_CODES_WITH_PROBLEM
};
