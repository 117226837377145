import firebase from 'firebase/app';
import 'firebase/messaging';

/**
 * Notification permission
 * Using Firebase to request notification permission
 *
 *
 * Usage example:
 * const { requestPermission } = firebase
 * requestPermission()
 *
 * Notification permission types
 * https://developer.mozilla.org/en-US/docs/Web/API/Notification/permission
 */
export const requestPermission = async () => {
  const payload = {
    token: null,
    permission: Notification.permission,
    errors: []
  };

  if (firebase.messaging.isSupported()) {
    try {
      // Init firebase messagging
      const messaging = firebase.messaging();
      // Request permission
      await Notification.requestPermission();

      payload.token = await messaging.getToken();
    } catch (error) {
      if (error.code === 'messaging/permission-blocked') {
        // Denied requests will be treated
        payload.errors.push(error);
      } else {
        // Other errors will be raise so we don't create a "bocao"
        throw error;
      }
    }
  }

  payload.permission = Notification.permission;

  return payload;
};

export const isNotificationDenied = () => {
  if (firebase.messaging.isSupported() && Notification) {
    return Notification.permission === 'denied';
  }
  return false;
};

export const isNotificationGranted = () => {
  if (firebase.messaging.isSupported() && Notification) {
    return Notification.permission === 'granted';
  }
  return false;
};

export const getToken = async () => {
  let token = null;

  if (isNotificationGranted()) {
    const messaging = firebase.messaging();
    token = await messaging.getToken();
  }

  return token;
};

export default {};
