import { loggiWebApi } from '@loggi/authentication-lib';

const fetchPickupPackages = (companyId, pickupId) =>
  loggiWebApi
    .accept('application/json')
    .content('application/json')
    .url(
      `/one/api/pickups/v1/companies/${companyId}/order-schedules/${pickupId}/packages/`
    )
    .get();

export default fetchPickupPackages;
