const FINALIZER_STATUS_ALERT = 'FinalizerStatusAlert';

const DELAYED_WITHOUT_MOVEMENT_ALERT = 'DelayedWithoutMovementAlert';

const DELAYED_WITH_MOVEMENT_RETURN_ALERT = 'DelayedWithMovementReturnAlert';

const DELAYED_WITH_MOVEMENT_DELIVERY_ALERT = 'DelayedWithMovementDeliveryAlert';

const WITH_MOVEMENT_DELIVERY_ALERT = 'WithMovementDeliveryAlert';

const WITH_MOVEMENT_RETURN_ALERT = 'WithMovementReturnAlert';

export {
  FINALIZER_STATUS_ALERT,
  DELAYED_WITHOUT_MOVEMENT_ALERT,
  DELAYED_WITH_MOVEMENT_RETURN_ALERT,
  DELAYED_WITH_MOVEMENT_DELIVERY_ALERT,
  WITH_MOVEMENT_DELIVERY_ALERT,
  WITH_MOVEMENT_RETURN_ALERT
};
