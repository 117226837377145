import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, Divider } from '@material-ui/core';

import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';

import PackageEditionModalError from '../package-edition-modal-error';
import packagesRoutes from '../../packages-routes';

const PackageDetailsRecipientSection = ({ data }) => {
  const { t } = useTranslation('packages');
  const { companyId } = useParams();
  const { push } = useHistory();

  const [
    openPackageEditionModalError,
    setOpenPackageEditionModalError
  ] = useState(false);

  const handleClosePackageEditionModalError = () => {
    setOpenPackageEditionModalError(false);
  };

  const handleEditRecipientButton = () => {
    if (data.isFinalStatus() || data.isEnRouteStatus()) {
      setOpenPackageEditionModalError(true);
    } else {
      push(packagesRoutes.edit.url(companyId, data.trackingKey));
    }
  };

  return (
    <>
      <Divider />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pt={2}
        pb={1}
      >
        <Box>
          <Typography variant="h6">
            <strong>{t('details.labels.recipient')}</strong>
          </Typography>
          <Typography variant="h6" />
        </Box>
        <Button
          size="small"
          color="primary"
          onClick={handleEditRecipientButton}
        >
          {t('details.actions.edit.title')}
        </Button>
      </Box>
      <Box display={{ sm: 'flex' }} flexWrap="wrap" flexDirection="row" pb={3}>
        {data.recipientName && (
          <Box flex="2" pt={2}>
            <Typography variant="body2">
              <strong>{t('details.labels.name')}</strong>
            </Typography>
            <Typography variant="body1">{data.recipientName}</Typography>
          </Box>
        )}
        {data.recipientPhone && (
          <Box pt={2} flex="1" px={{ sm: 2 }}>
            <Typography variant="body2">
              <strong>{t('details.labels.phone')}</strong>
            </Typography>
            <Typography variant="body1">{data.recipientPhone}</Typography>
          </Box>
        )}
        {data.recipientNif && (
          <Box pt={2} flex="1" px={{ sm: 2 }}>
            <Typography variant="body2">
              <strong>{t('details.labels.nif')}</strong>
            </Typography>
            <Typography variant="body1">{data.recipientNif}</Typography>
          </Box>
        )}
        {data.recipientEmail && (
          <Box pt={2} flex="1" pr={{ sm: 4 }}>
            <Typography variant="body2">
              <strong>{t('details.labels.email')}</strong>
            </Typography>
            <Typography variant="body1">{data.recipientEmail}</Typography>
          </Box>
        )}
        {data.destinationAddress && (
          <Box pt={2} flex="3">
            <Typography variant="body2">
              <strong>{t('details.labels.address')}</strong>
            </Typography>
            <Typography variant="body1">{data.destinationAddress}</Typography>
          </Box>
        )}
      </Box>
      <PackageEditionModalError
        open={openPackageEditionModalError}
        handleClose={handleClosePackageEditionModalError}
        status={data.status}
      />
    </>
  );
};

PackageDetailsRecipientSection.propTypes = {
  data: packageProps.isRequired
};

export default PackageDetailsRecipientSection;
