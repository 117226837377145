import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import GenericError from '../components/generic-error/index';
import ReturnAddressForm from './return-address-form';
import ReturnAddressFormSkeleton from './return-address-form-skeleton';

/**
 * ReturnAddressWrapper is a wrapper component that adds a loading capability
 * and erro handling before return address
 * @param {object} data
 * @param {boolean} isLoading
 * @returns {*}
 * @constructor
 */
const ReturnAddresstWrapper = ({ data, isLoading, error }) => {
  const wrap = component => <Box>{component}</Box>;

  if (isLoading) return <ReturnAddressFormSkeleton />;
  if (error) return wrap(<GenericError />);
  return <ReturnAddressForm initialValues={data} />;
};

ReturnAddresstWrapper.propTypes = {
  data: PropTypes.shape({
    responsibleName: PropTypes.string,
    responsiblePhone: PropTypes.string,
    returnAddressInstructions: PropTypes.string,
    returnAddressAlias: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.bool
};

ReturnAddresstWrapper.defaultProps = {
  isLoading: true,
  error: false
};

export default ReturnAddresstWrapper;
