import notificationDialog from './notification-dialog.json';
import signupCompanyForm from './sign-up-company-form.json';
import warehousesTranslations from './warehouses.json';
import companyStatusTranslations from './company-status.json';

export default {
  notificationDialog,
  signupCompanyForm,
  warehousesTranslations,
  companyStatusTranslations
};
