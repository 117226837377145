import { oneConstants } from '@loggi/components/src/one';
import * as Sentry from '@sentry/react';
import { DATES, PACKAGE_DIRECTION, RECIPIENT, STATUS } from '../constants';

const { ONE_LOCALSTORAGE_KEY } = oneConstants;

export class InvalidReportPreferencesError extends Error {
  constructor(message) {
    super(message);
    this.name = `InvalidReportPreferencesError`;
  }
}

export const REPORT_PREFERENCES_STORE_KEY = `${ONE_LOCALSTORAGE_KEY}_report_preferences`;
export const SEMANTIC_REPORT_PREFERENCES_STORED_ERROR_MESSAGE =
  'Semantically invalid report preferences on Local Storage';
export const SEMANTIC_REPORT_PREFERENCES_CREATE_ERROR_MESSAGE =
  'Semantically invalid report preferences trying to be saved';

const isCategoryValid = categoryData =>
  Array.isArray(categoryData) &&
  categoryData?.every(
    field =>
      typeof field.label === 'string' &&
      typeof field.value === 'string' &&
      typeof field.selected === 'boolean'
  );

export const isReportPreferencesDataValid = reportPreferences =>
  typeof reportPreferences === 'object' &&
  isCategoryValid(reportPreferences[RECIPIENT]) &&
  isCategoryValid(reportPreferences[DATES]) &&
  isCategoryValid(reportPreferences[STATUS]) &&
  isCategoryValid(reportPreferences[PACKAGE_DIRECTION]);

export const saveReportPreferencesDataOnLocalStorage = data => {
  if (!isReportPreferencesDataValid(data)) {
    throw new InvalidReportPreferencesError(
      SEMANTIC_REPORT_PREFERENCES_CREATE_ERROR_MESSAGE
    );
  }
  localStorage.setItem(REPORT_PREFERENCES_STORE_KEY, JSON.stringify(data));
};

export const clearLocalStorage = key => localStorage.removeItem(key);

export const getReportPreferencesData = () => {
  const storeData = localStorage.getItem(REPORT_PREFERENCES_STORE_KEY);
  let parsedData;

  if (typeof storeData === 'string') {
    try {
      parsedData = JSON.parse(storeData);
    } catch (e) {
      Sentry.captureException(new InvalidReportPreferencesError(e.message));
      clearLocalStorage(REPORT_PREFERENCES_STORE_KEY);
      return {};
    }

    if (!isReportPreferencesDataValid(parsedData)) {
      Sentry.captureException(
        new InvalidReportPreferencesError(
          SEMANTIC_REPORT_PREFERENCES_STORED_ERROR_MESSAGE
        )
      );
      clearLocalStorage(REPORT_PREFERENCES_STORE_KEY);
      return {};
    }
  }

  return parsedData || {};
};
