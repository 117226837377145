import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, Button, Typography } from '@material-ui/core';
import CancelPackageDialog from '../../details/cancel-package-dialog';

const CancelPackageAction = ({ data, handleClose }) => {
  const [openCancelPackageDialog, setOpenCancelPackageDialog] = useState(false);
  const { companyId } = useParams();
  const { t } = useTranslation('packages');

  const handleClickOpenCancelPackageDialog = () => {
    setOpenCancelPackageDialog(true);
  };

  const handleCloseCancelPackageDialog = () => {
    setOpenCancelPackageDialog(false);
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleClickOpenCancelPackageDialog}
      >
        <Typography variant="body1" color="initial">
          {t('movementDrawer.actionsButtons.cancelPackage')}
        </Typography>
      </Button>
      <Box pt={2}>
        <Button variant="text" color="primary" fullWidth onClick={handleClose}>
          <Typography variant="body1" color="initial">
            {t('movementDrawer.actionsButtons.wait')}
          </Typography>
        </Button>
      </Box>
      <CancelPackageDialog
        companyId={String(companyId)}
        trackingKey={data.trackingKey}
        open={openCancelPackageDialog}
        handleClose={handleCloseCancelPackageDialog}
      />
    </Box>
  );
};

CancelPackageAction.propTypes = {
  data: packageProps.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default CancelPackageAction;
