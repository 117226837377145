import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Hidden, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { packageDimensionsPropTypes } from '@loggi/components/src/one/package-utils/proptypes';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(({ palette }) => ({
  helpIcon: {
    width: 20,
    height: 20,
    marginLeft: 10,
    position: 'absolute'
  },
  conferredDimensions: {
    display: 'flex'
  },
  disabledDimensions: {
    color: palette.grey[700]
  },
  enabledDimensions: {
    position: 'relative'
  },
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 7
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  }
}));

const PackageDetailsDimensionsSection = ({ volumetricInfo, isMeasured }) => {
  const { t } = useTranslation('packages');
  const classes = useStyles();
  const disabledDimensions = !isMeasured && volumetricInfo.measuredValues;
  let { dimensions } = volumetricInfo.integrationValues;
  let tooltipTitle = t('details.labels.declaredDimensionsTooltip');
  let dimensionsLabel = t('details.labels.declaredDimensions');
  let cubicWeight = volumetricInfo.integrationValues.cubicWeightG;

  if (isMeasured) {
    dimensions = volumetricInfo.measuredValues.dimensions;
    tooltipTitle = t('details.labels.conferredDimensionsTooltip');
    dimensionsLabel = t('details.labels.conferredDimensions');
    cubicWeight = volumetricInfo.measuredValues.cubicWeightG;
  }

  return (
    <Box className={classes.mainBox}>
      <Typography
        variant="body2"
        className={
          disabledDimensions
            ? classes.disabledDimensions
            : classes.enabledDimensions
        }
      >
        <strong>{dimensionsLabel}</strong>
        <Hidden mdDown>
          <Tooltip
            arrow
            placement="top-start"
            title={
              <Typography>
                {tooltipTitle}
                <Trans>
                  {t('details.labels.dimensionsDescriptionTooltip')}
                </Trans>
              </Typography>
            }
          >
            <HelpOutlineIcon color="primary" className={classes.helpIcon} />
          </Tooltip>
        </Hidden>
      </Typography>
      <Box
        className={
          disabledDimensions
            ? classes.disabledDimensions
            : classes.enabledDimensions
        }
      >
        <Typography variant="body1">
          <Trans>
            {t('details.labels.length', { length: dimensions.lengthCm })}
            {t('details.labels.width', { width: dimensions.widthCm })}
            {t('details.labels.height', { height: dimensions.heightCm })}
          </Trans>
        </Typography>

        <Typography variant="body1">
          <Trans>
            {t('details.labels.grossWeight', {
              grossWeight: dimensions.weightG
            })}
          </Trans>
        </Typography>

        <Typography variant="body1">
          <Trans>
            {t('details.labels.cubicWeight', {
              cubicWeight
            })}
          </Trans>
        </Typography>
      </Box>
      <Hidden mdUp>
        <Box pt={3}>
          <Alert severity="info" color="info">
            <Typography>{tooltipTitle}</Typography>
          </Alert>
        </Box>
      </Hidden>
    </Box>
  );
};

PackageDetailsDimensionsSection.propTypes =
  packageDimensionsPropTypes.isRequired;

export default PackageDetailsDimensionsSection;
