import { Box, ListItem, Typography, List, Paper } from '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import React from 'react';

const useStyles = makeStyles(
  ({ spacing, breakpoints, palette, typography }) => ({
    listItem: {
      display: 'unset'
    },
    menuContent: {
      '& > :last-child': {
        '& .MuiDivider-root': {
          display: 'none'
        }
      },
      margin: spacing(3, 3, 3, 3),
      [breakpoints.down('xs')]: {
        margin: spacing(2, 2, 2, 2)
      }
    },
    horizontalMenu: {
      maxWidth: '360px',
      margin: spacing(1, 2, 2, 2)
    },
    descriptionCard: {
      color: palette.grey[600],
      fontSize: typography.body2.fontSize
    }
  })
);

const MenuItem = props => {
  const classes = useStyles();
  const { label, subtitle, url, image } = props;
  const { push } = useHistory();

  return (
    <Paper className={classes.horizontalMenu}>
      <List className={classes.menuContent} disablePadding>
        <ListItem
          button
          className={classes.listItem}
          data-testid="management-menu-item"
          disableGutters
          onClick={() => push(url)}
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              <Box>{image}</Box>
              <Box width="100%" mt={1} ml={1}>
                <Typography variant="h6">{label}</Typography>
              </Box>
              <Box mt={2} ml={4}>
                <ArrowForwardIos style={{ fontSize: 20 }} color="primary" />
              </Box>
            </Box>
            <Box display="flex" mt={4} mr={6} ml={1}>
              <Typography className={classes.descriptionCard} variant="body1">
                {subtitle}
              </Typography>
            </Box>
          </Box>
        </ListItem>
      </List>
    </Paper>
  );
};

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.element.isRequired
};

export default MenuItem;
