import { trackingApi } from '@loggi/authentication-lib';

export const endpoints = {
  tracking: (companyId, trackingKey) =>
    `/v1/companies/${companyId}/packages/${trackingKey}/tracking/`,
  details: (companyId, trackingKey) =>
    `/v1/companies/${companyId}/packages/${trackingKey}/`,
  list: (companyId, queryParams) =>
    `/v1/companies/${companyId}/packages?${queryParams}`
};

const fetchTrackingApi = {
  tracking: (companyId, trackingKey) => {
    const url = endpoints.tracking(companyId, trackingKey);

    return trackingApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .get()
      .json();
  },
  details: (companyId, trackingKey) => {
    const fieldList = [
      'internal_info',
      'direction',
      'status',
      'destination',
      'promised_date',
      'dimension',
      'invoice',
      'invoices',
      'receiver',
      'delivery_information',
      'pricing',
      'slo',
      'redispatch',
      'pickup_receipt',
      'volumetric_info'
    ];

    const params = `?field_mask=${fieldList.join(',')}`;
    const url = endpoints.details(companyId, trackingKey);

    const api = trackingApi
      .url(url + params)
      .accept('application/json')
      .content('application/json');

    return api.get().json();
  },
  list: (companyId, trackingKeys) => {
    const queryParams = trackingKeys
      ?.map(trackingKey => `&tracking_codes=${trackingKey}`)
      .join('');
    const url = endpoints.list(companyId, queryParams);

    const api = trackingApi
      .url(url)
      .accept('application/json')
      .content('application/json');

    return api.get().json();
  }
};

export default fetchTrackingApi;
