import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import PackageMovementBaseDrawer from '../../../../../apps/packages/src/packages/movement_drawer/package-movement-base';
import { PackageFactory } from '../../package-utils';
import fetchTrackingApi from '../../../../../apps/packages/src/packages/tracking-api';

const initialState = {
  isLoading: false,
  data: null,
  value: '',
  error: false
};

const HelpDrawerPackageSearch = ({ handleClose, companyId }) => {
  const { t } = useTranslation('packages');
  const [{ data, isLoading, value, error }, setState] = useReducer(
    (curState, newState) => ({ ...curState, ...newState }),
    initialState
  );

  const APICall = trackingKey => {
    fetchTrackingApi
      .details(companyId, trackingKey)
      .then(response => {
        return PackageFactory.trackingAPI.create(response.packages[0]);
      })
      .then(pkg => setState({ isLoading: false, data: pkg, error: false }))
      .catch(() => setState({ isLoading: false, data: null, error: true }));
  };

  const handleClick = () => {
    setState({
      isLoading: true
    });
    APICall(value);
  };

  if (data) {
    return <PackageMovementBaseDrawer data={data} handleClose={handleClose} />;
  }

  return (
    <>
      <Box paddingTop={5} px={5}>
        <Typography variant="h4">
          <strong>{t('details.helpDrawer.searchDrawer.title')}</strong>
        </Typography>
      </Box>
      <Box display="flex" paddingY={3} paddingX={5}>
        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel htmlFor="input-tk" error={error}>
            {t('details.helpDrawer.searchDrawer.inputLabel')}
          </InputLabel>
          <OutlinedInput
            error={error}
            fullWidth
            id="input-tk"
            data-testid="input-tk"
            onChange={e => {
              setState({ value: e.target.value, error: false });
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleClick();
              }
            }}
            value={value || ''}
            type="search"
            label={t('details.helpDrawer.searchDrawer.inputLabel')}
          />
          {error && (
            <FormHelperText>
              <Typography variant="caption" color="error">
                {t('details.helpDrawer.searchDrawer.invalidTrackingCode')}
              </Typography>
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box px={5} position="relative" top="50%">
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => handleClick()}
          disabled={!value}
        >
          <Typography variant="body1" color="initial">
            {isLoading ? (
              <CircularProgress size={26} />
            ) : (
              t('details.helpDrawer.searchDrawer.button')
            )}
          </Typography>
        </Button>
      </Box>
    </>
  );
};
HelpDrawerPackageSearch.propTypes = {
  companyId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default HelpDrawerPackageSearch;
