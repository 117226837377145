import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { maxWeightInGrams, minWeightInGrams } from '../constants';
import BaseVolumeField from './base-volume-field';

const WeightField = ({ index }) => {
  const { t } = useTranslation('orderScheduling');

  const validate = value => {
    if (value !== 0 && !value) return t('create.errorMessages.requiredField');

    if (value < minWeightInGrams)
      return t('create.errorMessages.invalidMeasurement');

    if (value > maxWeightInGrams)
      return t('create.errorMessages.maxWeight', { maxWeightInGrams });

    return null;
  };

  return (
    <BaseVolumeField
      adornmentText={t('create.volumeSection.grams_unit')}
      fieldName={`volumes.${index}.weight`}
      label={t('create.volumeSection.labelWeight')}
      validate={validate}
    />
  );
};

WeightField.propTypes = {
  index: PropTypes.number.isRequired
};

export default WeightField;
