import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Divider } from '@material-ui/core';

import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';

const PackageDetailsRedispatchSection = ({ data }) => {
  const { t } = useTranslation('packages');

  return (
    <Box pb={3}>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pb={1}
      >
        <Box>
          <Typography variant="h6">
            <strong>{t('details.labels.redispatch')}</strong>
          </Typography>
          <Typography variant="h6" />
        </Box>
      </Box>
      <Box display={{ sm: 'flex' }} flexWrap="wrap" flexDirection="row" pb={3}>
        {data.barcode && (
          <Box flex="1" pt={2}>
            <Typography variant="body2">
              <strong>{t('details.labels.barcode')}</strong>
            </Typography>
            <Typography variant="body1">{data.barcode}</Typography>
          </Box>
        )}
        {data.redispatchPickupAddress && (
          <Box pt={2} flex="2" px={{ sm: 2 }}>
            <Typography variant="body2">
              <strong>{t('details.labels.address')}</strong>
            </Typography>
            <Typography variant="body1">
              {data.redispatchPickupAddress}
            </Typography>
          </Box>
        )}
      </Box>
      <Divider />
    </Box>
  );
};

PackageDetailsRedispatchSection.propTypes = {
  data: packageProps.isRequired
};

export default PackageDetailsRedispatchSection;
