import { Grid, useMediaQuery } from '@material-ui/core';
import React from 'react';
import {
  CoverageParamsAlert,
  useCoverageParamsContext
} from '../coverage-params';

import DateField from '../formik-fields/date-field.component';
import EndTimeField from '../formik-fields/end-time-field.component';
import StartTimeField from '../formik-fields/start-time-field.component';
import { useFeature } from '../remote-config';

const DateSection = () => {
  const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { hasPickup } = useCoverageParamsContext();
  const shouldUseCoverageParams = useFeature('should_use_coverage_params');
  const gridSpacing = smUp ? 5 : 3;

  const endTimeFieldName = 'endTime';
  const dateFieldName = 'date';
  const startTimeFieldName = 'startTime';

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12} md={6}>
        <DateField fieldName={dateFieldName} />
      </Grid>
      <Grid item xs={12} md={3} data-testid="start-time-wrapper">
        <StartTimeField
          endTimeFieldName={endTimeFieldName}
          dateFieldName={dateFieldName}
          fieldName={startTimeFieldName}
        />
      </Grid>
      <Grid item xs={12} md={3} data-testid="end-time-wrapper">
        <EndTimeField
          dateFieldName={dateFieldName}
          fieldName={endTimeFieldName}
          startTimeFieldName={startTimeFieldName}
        />
      </Grid>
      {shouldUseCoverageParams && hasPickup && (
        <Grid item xs={12} data-testid="coverage-parms-alert-wrapper">
          <CoverageParamsAlert />
        </Grid>
      )}
    </Grid>
  );
};

export default DateSection;
