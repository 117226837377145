import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  Select,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Radio
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ShaftlessArrowDown } from '@loggi/components/src/one/shared/images/shaftless-arrow-down.svg';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import DEFAULT_SEARCH_DAYS, {
  FILTERS_NAMES,
  PACKAGES_DATE_FILTER_VALUES
} from '../constants';

const PackageSelectionDateFilter = ({ onChange }) => {
  const { periodo: selectedDate } = useQueryParams();
  const filter = selectedDate || DEFAULT_SEARCH_DAYS;
  const { t } = useTranslation('unifiedPickup');
  const [created, setCreated] = useState(Number(filter));

  useEffect(() => {
    setCreated(Number(filter));
  }, [filter]);

  const onChangeDate = event => {
    const value = Number(event.target.value);
    setCreated(value);
    const newFilter = Object();
    newFilter[FILTERS_NAMES.date] =
      value === DEFAULT_SEARCH_DAYS ? null : value;
    onChange(newFilter);
  };

  const FilterDateOptions = () => {
    return PACKAGES_DATE_FILTER_VALUES.map(value => (
      <MenuItem key={value} value={value} data-testid={`${value}-option`}>
        <FormControlLabel
          label={t('packageSelection.filter.creationDate.option', {
            days: value
          })}
          checked={created === value}
          control={<Radio color="primary" />}
        />
      </MenuItem>
    ));
  };

  const renderDateFilterLabel = value => {
    return t('packageSelection.filter.creationDate.option', {
      days: `${value}`.padStart(2, '0')
    });
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{t('packageSelection.filter.creationDate.label')}</InputLabel>
      <Select
        id="filter-date-select"
        input={
          <OutlinedInput
            data-testid="filter-date-select"
            label={t('packageSelection.filter.creationDate.label')}
          />
        }
        renderValue={renderDateFilterLabel}
        value={created}
        onChange={onChangeDate}
        IconComponent={ShaftlessArrowDown}
      >
        {FilterDateOptions()}
      </Select>
    </FormControl>
  );
};

PackageSelectionDateFilter.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default PackageSelectionDateFilter;
