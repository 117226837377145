import { isValid, format, parse } from 'date-fns';

const ftIn = 'dd-MM-yyyy';
const ftOut = 'MM-dd-yyyy';

export function formatDate(dt, invert = false) {
  if (dt) {
    if (typeof dt === 'string') {
      return format(
        parse(dt, invert ? ftOut : ftIn, new Date()),
        invert ? ftIn : ftOut
      );
    }
    return format(dt, invert ? ftIn : ftOut);
  }
  return dt;
}

export function parseStringToDate(dt) {
  if (dt) {
    return typeof dt === 'string' ? parse(dt, ftOut, new Date()) : dt;
  }
  return new Date();
}

export function validDate(dt) {
  if (dt) {
    return isValid(typeof dt === 'string' ? parse(dt, ftOut, new Date()) : dt);
  }
  return false;
}
