import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme
} from '@material-ui/core';

import SPREADSHEET_TEMPLATE_FORMAT from '../constants';

const SelectSpreadSheetFormat = ({
  spreadSheetFormat,
  setSpreadSheetFormat,
  count
}) => {
  const { spacing } = useTheme();
  const { t } = useTranslation('payments');

  const SPREADSHEET_TEMPLATE = [
    {
      name: t('priceAgreements.spreadSheetFormat.comercial'),
      value: SPREADSHEET_TEMPLATE_FORMAT.COMERCIAL
    },
    {
      name: t('priceAgreements.spreadSheetFormat.intelipost'),
      value: SPREADSHEET_TEMPLATE_FORMAT.INTELIPOST
    },
    {
      name: t('priceAgreements.spreadSheetFormat.vtex'),
      value: SPREADSHEET_TEMPLATE_FORMAT.VTEX
    },
    {
      name: t('priceAgreements.spreadSheetFormat.frenet'),
      value: SPREADSHEET_TEMPLATE_FORMAT.FRENET
    },
    {
      name: t('priceAgreements.spreadSheetFormat.editavel'),
      value: SPREADSHEET_TEMPLATE_FORMAT.EDITAVEL
    }
  ];

  const MenuProps = {
    PaperProps: {
      style: {
        marginTop: spacing(1),
        maxWidth: spacing(10)
      }
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  };

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel>
        {t('priceAgreements.spreadSheetFormatLabel', { count })}
      </InputLabel>
      <Select
        id="select-spreadsheet-format"
        input={
          <OutlinedInput
            label={t('priceAgreements.spreadSheetFormatLabel', { count })}
          />
        }
        MenuProps={MenuProps}
        labelId="select-spreadsheet-format-label"
        onChange={event => setSpreadSheetFormat(event.target.value)}
        value={spreadSheetFormat}
      >
        {SPREADSHEET_TEMPLATE.map(({ value, name }) => (
          <MenuItem key={value} value={value} data-testid={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectSpreadSheetFormat.propTypes = {
  spreadSheetFormat: PropTypes.number.isRequired,
  setSpreadSheetFormat: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired
};

export default SelectSpreadSheetFormat;
