import { TRANSPORT_TYPE_MAPPER } from '@loggi/components/src/one/constants';
import {
  Box,
  Typography,
  makeStyles,
  ButtonBase,
  ListItem,
  ListItemText
} from '@material-ui/core';
import msk from 'msk';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ArrowForwardIos } from '@material-ui/icons';
import { CPF_MASK } from '../../utils/masks';
import StatusIndicatorPickup from '../status-indicator/status-indicator-pickup';
import SchedulingDetailsReportBeepedPackages from './scheduling-details-report-beeped-packages.component';
import { PICKUP_RECEIPT_ROUTES } from '../../constants';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    padding: spacing(5),
    [breakpoints.up('sm')]: {
      paddingLeft: spacing(5.5),
      paddingRight: spacing(5.5)
    },
    '&:hover': {
      backgroundColor: palette.secondary.light
    },
    color: palette.primary.light
  }
}));

const SchedulingDetailsDriversListItem = props => {
  const { t } = useTranslation(['details']);
  const { push } = useHistory();
  const { companyId, id } = useParams();
  const classes = useStyles();

  const { driver, pickupReceipt, status } = props;
  const { name, transportType, licensePlate, document } = driver;

  const documentMasked = msk(document, CPF_MASK);

  const textDivider = '/';

  const pickupEndTimeFormated = pickupReceipt?.created
    ? format(parseISO(pickupReceipt.created), 'HH:mm')
    : '';

  const hasRelatedPickupReceipt = !!pickupReceipt;

  const PickupStatusInfo = () => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: 1 }}
    >
      <StatusIndicatorPickup
        status={status}
        pickupEndTime={pickupEndTimeFormated}
      />
      {hasRelatedPickupReceipt && (
        <>
          <Box display="flex" alignItems="center">
            <Box pr={2}>
              <Typography
                variant="subtitle2"
                color="primary"
                style={{ fontWeight: 600 }}
              >
                {t('redirectToPackagesList.label')}
              </Typography>
            </Box>
            <Box display="flex">
              <ArrowForwardIos
                style={{
                  fontSize: 20
                }}
                color="primary"
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );

  const DriverInfo = () => (
    <>
      <Box py={1}>
        <Typography variant="subtitle1" color="textPrimary" mb={1}>
          <strong>{name}</strong>
        </Typography>
      </Box>
      <Box display={{ sm: 'flex' }}>
        <Box>
          <Typography noWrap variant="body1" color="textSecondary">
            {t('pickupDriver.document', { document: documentMasked })}
          </Typography>
        </Box>
        <Box display={{ xs: 'none', sm: 'flex' }} px={1}>
          <Typography variant="body1" color="textSecondary">
            {textDivider}
          </Typography>
        </Box>
        <Box pt={{ xs: 1, sm: 0 }}>
          <Typography noWrap variant="body1" color="textSecondary">
            {t(TRANSPORT_TYPE_MAPPER(transportType))}
            {licensePlate && ` - ${licensePlate}`}
          </Typography>
        </Box>
      </Box>
    </>
  );

  const pickupStatusAndDriverInfo = (hasRelatedPickupReceipt && (
    <ButtonBase
      data-testid="beeped-packages-list-button"
      style={{ width: '100%' }}
      onClick={() => {
        push(
          PICKUP_RECEIPT_ROUTES.beepedPackages.url(
            companyId,
            id,
            pickupReceipt.id
          )
        );
      }}
    >
      <Box width="100%" textAlign="left">
        <PickupStatusInfo />
        <DriverInfo />
      </Box>
    </ButtonBase>
  )) || (
    <>
      <PickupStatusInfo />
      <DriverInfo />
    </>
  );

  const reportBeepedPackagesInfo = hasRelatedPickupReceipt && (
    <SchedulingDetailsReportBeepedPackages
      numberOfBeepedPackages={pickupReceipt?.numberOfBeepedPackages}
      confirmedTotalPackages={pickupReceipt?.confirmedTotalPackages}
      receiptId={pickupReceipt?.id}
    />
  );

  return (
    <ListItem disableGutters className={classes.root} component="div">
      <ListItemText
        disableTypography
        primary={pickupStatusAndDriverInfo}
        secondary={reportBeepedPackagesInfo}
      />
    </ListItem>
  );
};

SchedulingDetailsDriversListItem.propTypes = {
  driver: PropTypes.shape({
    name: PropTypes.string,
    transportType: PropTypes.string,
    licensePlate: PropTypes.string,
    document: PropTypes.string
  }).isRequired,
  pickupReceipt: PropTypes.shape({
    id: PropTypes.number,
    created: PropTypes.string,
    numberOfBeepedPackages: PropTypes.number,
    confirmedTotalPackages: PropTypes.bool
  }),
  status: PropTypes.number.isRequired
};

SchedulingDetailsDriversListItem.defaultProps = {
  pickupReceipt: null
};

export default SchedulingDetailsDriversListItem;
