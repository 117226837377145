import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchBar from '../search-bar/document-search-bar';
import { LIST_TYPE } from '../../constants';

const PickupSearchBar = () => {
  const { t } = useTranslation('scheduling');

  return (
    <SearchBar
      nameField="document"
      listType={LIST_TYPE.SCHEDULINGS}
      id="filter-by-document"
      placeholder={t('search.placeholder')}
    />
  );
};

export default PickupSearchBar;
