import {
  Box,
  CircularProgress,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import PackagesEmptyFilterResult from '../packages-empty-filter-result';
import PackagesEmptyResult from '../packages-empty-result';
import PackagesListPagination from '../packages-list-pagination';
import PackagesSearchError from '../packages-search-error';
import PackagesListTable from '../packages-list-table';
import PackagesListResponsive from '../packages-list-responsive';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    minHeight: spacing(14),
    [breakpoints.up('sm')]: {
      minHeight: spacing(40)
    }
  },
  paginationBox: {
    marginTop: spacing(2),
    [breakpoints.up('sm')]: {
      marginTop: spacing(3)
    }
  }
}));

/**
 * PackagesListWrapper is a wrapper component that adds a loading capability
 * before the packages table and pagination
 * @param {object} data
 * @param {number} count
 * @param {boolean} isLoading
 * @param {boolean} error
 * @param {function} handleToggle
 * @param {function} setChecked
 * @param {boolean} useHierarchySpecifcs
 * @param {array} checked
 * @returns {*}
 * @constructor
 */
const PackagesListWrapper = ({
  data,
  count,
  isLoading,
  error,
  handleToggle,
  setChecked,
  useHierarchySpecifcs,
  checked
}) => {
  const queryParams = useQueryParams();
  const styles = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const hasFilterApplied = Object.keys(queryParams).length > 0;
  const isEmpty = data.length === 0;
  const isPackagesFound = isEmpty && hasFilterApplied && !error;
  const isThereAnyPackage = isEmpty && !hasFilterApplied && !error;

  const wrap = component => (
    <Box
      alignItems="center"
      className={styles.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {component}
    </Box>
  );

  if (error) return wrap(<PackagesSearchError />);
  if (isLoading) return wrap(<CircularProgress data-testid="loading" />);
  if (isPackagesFound) return wrap(<PackagesEmptyFilterResult />);
  if (isThereAnyPackage) return wrap(<PackagesEmptyResult />);

  return wrap(
    <>
      {isDesktop ? (
        <PackagesListTable
          data={data}
          handleToggle={handleToggle}
          checked={checked}
          useHierarchySpecifcs={useHierarchySpecifcs}
        />
      ) : (
        <PackagesListResponsive
          data={data}
          handleToggle={handleToggle}
          checked={checked}
          useHierarchySpecifcs={useHierarchySpecifcs}
        />
      )}
      <Box alignSelf="flex-end" className={styles.paginationBox}>
        <PackagesListPagination
          count={count}
          isLoading={isLoading}
          setChecked={setChecked}
        />
      </Box>
    </>
  );
};

PackagesListWrapper.propTypes = {
  count: PropTypes.number,
  data: PropTypes.arrayOf(packageProps),
  isLoading: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired,
  setChecked: PropTypes.func.isRequired,
  useHierarchySpecifcs: PropTypes.bool,
  error: PropTypes.bool,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired
};

PackagesListWrapper.defaultProps = {
  count: 0,
  data: [],
  isLoading: true,
  error: false,
  useHierarchySpecifcs: false
};

export default PackagesListWrapper;
