import integratorsJson from './integrator/data/integrators-translations-pt-br.json';

const rootPath = '/empresas/:companyId/configurar';
const rootUrl = companyId => `/empresas/${companyId}/configurar`;
const basePath = '/empresas/:companyId';
const baseRootUrl = companyId => `/empresas/${companyId}`;

const managementRoutes = {
  index: { path: rootPath, url: companyId => `${rootUrl(companyId)}` },
  listSchedules: {
    path: `/empresas/:companyId/coletas`,
    url: companyId => `/empresas/${companyId}/coletas`
  },
  returnAddress: {
    path: `${rootPath}/enderecos/:warehouseId/devolucao/editar`,
    url: (companyId, warehouseId) =>
      `${rootUrl(companyId)}/enderecos/${warehouseId}/devolucao/editar`
  },
  warehousesAddresses: {
    path: `${rootPath}/enderecos`,
    url: companyId => `${rootUrl(companyId)}/enderecos`
  },
  addressList: {
    path: `${rootPath}/enderecos/:warehouseId`,
    url: (companyId, warehouseId) =>
      `${rootUrl(companyId)}/enderecos/${warehouseId}`
  },
  integrationSystems: {
    path: `${rootPath}/integracoes`,
    url: companyId => `${rootUrl(companyId)}/integracoes`
  },
  usersManagement: {
    path: `${basePath}/gerenciar-usuarios`,
    url: companyId => `${baseRootUrl(companyId)}/gerenciar-usuarios`
  },
  companyData: {
    path: `${rootPath}/dados-cadastrais`,
    url: companyId => `${rootUrl(companyId)}/dados-cadastrais`
  }
};

Object.entries(integratorsJson).forEach(value => {
  const integrator = value[0];
  const integratorName = value[1].name;

  managementRoutes[`integration${integratorName}`] = {
    path: `${rootPath}/integracoes/${integrator}`,
    url: companyId => `${rootUrl(companyId)}/integracoes/${integrator}`
  };
});

export default managementRoutes;
