import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, OutlinedInput, useTheme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import { useTranslation } from 'react-i18next';
import { FILTERS_NAMES } from '../constants';

const PackageSearchBar = ({ onChange }) => {
  const { busca: searchText } = useQueryParams();
  const filter = searchText || '';
  const [value, setValue] = useState(filter);
  const { t } = useTranslation('unifiedPickup');
  const { typography } = useTheme();
  const debounceDelayInMs = 600;

  useEffect(() => {
    const debounceId = setTimeout(() => {
      const newFilter = Object();
      newFilter[FILTERS_NAMES.search] = value;
      onChange(newFilter);
    }, debounceDelayInMs);

    return () => clearTimeout(debounceId);
    /*
     * Passing setFilter function to dependency array, have an undeserided
     * effect of runnin effect twice, for every state change. That's why
     * linter rule was disabled in this effect callback.
     */
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OutlinedInput
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon color="primary" />
        </InputAdornment>
      }
      fullWidth
      inputProps={{
        'data-testid': 'filter-search-text-input'
      }}
      onChange={event => {
        setValue(event.target.value);
      }}
      style={{ fontWeight: typography.fontWeightMedium }}
      value={value}
      variant="button"
      color="primary"
      placeholder={t('packageSelection.filter.placeholder')}
      type="search"
    />
  );
};
PackageSearchBar.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default PackageSearchBar;
