import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DialogHeaderBase from '@loggi/components/src/one/dialog-header-base';
import { Box, Button, Dialog } from '@material-ui/core';

const UpdateReturnAddressConfirmationDialog = ({
  dialogState,
  setDialogState,
  handleConfirmation
}) => {
  const { t } = useTranslation('management');

  return (
    <Dialog
      open={dialogState}
      data-testid="update-return-address-confirmation-dialog"
    >
      <DialogHeaderBase
        title={t('returnAddress.confirmationDialog.title')}
        subtitle={t('returnAddress.confirmationDialog.subtitle')}
      >
        <Box>
          <Button
            variant="outlined"
            size="small"
            bordercolor="primary"
            color="primary"
            backgroundcolor="common"
            data-testid="update-return-address-confirmation-confirm"
            onClick={() => handleConfirmation()}
          >
            {t('returnAddress.confirmationDialog.confirm')}
          </Button>
        </Box>
        <Box pl={4}>
          <Button
            size="small"
            bordercolor="primary"
            color="primary"
            backgroundcolor="common"
            data-testid="update-return-address-confirmation-close"
            onClick={() => {
              setDialogState(false);
            }}
          >
            {t('returnAddress.confirmationDialog.close')}
          </Button>
        </Box>
      </DialogHeaderBase>
    </Dialog>
  );
};

export default UpdateReturnAddressConfirmationDialog;

UpdateReturnAddressConfirmationDialog.propTypes = {
  dialogState: PropTypes.bool.isRequired,
  setDialogState: PropTypes.func.isRequired,
  handleConfirmation: PropTypes.func.isRequired
};
