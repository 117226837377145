import { ReactComponent as ShaftlessArrowDown } from '@loggi/components/src/one/shared/images/shaftless-arrow-down.svg';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import React from 'react';

const ComboSingleSelect = ({
  comboBoxOptions,
  setCheckedCallback,
  checkedValue,
  filedName,
  title
}) => {
  const renderLabel = value => {
    return comboBoxOptions.find(e => e.value === value).labelText;
  };
  const onChange = event => {
    setCheckedCallback(event.target.value);
  };
  return (
    <FormControl fullWidth variant="outlined" data-testid="form-control-id">
      <InputLabel>{title}</InputLabel>
      <Select
        name={filedName}
        id="combobox-select"
        input={<OutlinedInput data-testid="combobox-select-id" label={title} />}
        labelId="combobox-select-label-id"
        onChange={onChange}
        renderValue={renderLabel}
        value={checkedValue}
        inputProps={{ 'data-testid': `combobox-select-${filedName}` }}
        IconComponent={ShaftlessArrowDown}
      >
        {comboBoxOptions.map(({ value, labelText }) => (
          <MenuItem key={labelText} value={value}>
            <FormControlLabel
              label={labelText}
              checked={checkedValue === value}
              control={<Radio color="primary" />}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ComboSingleSelect.propTypes = {
  title: PropTypes.string.isRequired,
  filedName: PropTypes.string.isRequired,
  checkedValue: PropTypes.string.isRequired,
  comboBoxOptions: PropTypes.arrayOf(
    PropTypes.shape({
      labelText: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,

  setCheckedCallback: PropTypes.func.isRequired
};

export default ComboSingleSelect;
