import * as Sentry from '@sentry/react';
import { oneConstants } from '@loggi/components/src/one';

const { ONE_LOCALSTORAGE_KEY } = oneConstants;

export const SIGN_UP_COMPANY_KEYS = {
  CREATED_COMPANY_ID: 'sign_up_company_id',
  SIGN_UP_COMPANY_STEP: 'sign_up_company_step',
  UPDATE_COMPANY_PICKUP_STEP: 'update_company_pickup_step',
  UPDATE_COMPANY_CONTACT_STEP: 'update_company_contact_step'
};

export const saveDataOnLocalStorage = (key, data) =>
  localStorage.setItem(`${ONE_LOCALSTORAGE_KEY}_${key}`, JSON.stringify(data));

export const clearDataOnLocalStorage = key =>
  localStorage.removeItem(`${ONE_LOCALSTORAGE_KEY}_${key}`);

export const clearMultipleDataOnLocalStorage = keys =>
  keys.forEach(key => clearDataOnLocalStorage(key));

export const getDataOnLocalStorage = key => {
  const storeData = localStorage.getItem(`${ONE_LOCALSTORAGE_KEY}_${key}`);
  let parsedData = {};

  if (typeof storeData === 'string') {
    try {
      parsedData = JSON.parse(storeData);
    } catch (error) {
      Sentry.captureException(error, {
        contexts: { storeData }
      });
      clearDataOnLocalStorage(key);
      parsedData = {};
    }
  }

  return parsedData;
};

export const hasDataOnLocalStorage = key => {
  const retrievedData = getDataOnLocalStorage(key);
  return typeof retrievedData !== 'object'
    ? Boolean(getDataOnLocalStorage(key))
    : Boolean(Object.keys(getDataOnLocalStorage(key)).length);
};

export const hasDataOnContext = dataObject =>
  typeof dataObject !== 'object'
    ? Boolean(dataObject)
    : Boolean(Object.values(dataObject).find(value => value));
