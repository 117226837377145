import React from 'react';
import PropTypes from 'prop-types';
import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';
import { differenceInDays, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import MovementDrawerAlert from './alerts/movement-drawer-alert';
import {
  FINALIZER_STATUS_ALERT,
  WITH_MOVEMENT_DELIVERY_ALERT,
  WITH_MOVEMENT_RETURN_ALERT,
  DELAYED_WITHOUT_MOVEMENT_ALERT,
  DELAYED_WITH_MOVEMENT_RETURN_ALERT,
  DELAYED_WITH_MOVEMENT_DELIVERY_ALERT
} from './alerts/alerts-names';
import { useRemoteConfig } from '../../firebase';
import MovementActionHelpCenter from './movement-actions/moviment-action-help-center';
import CancelPackageAction from './movement-actions/cancel-package-action';

const casesWithHelpCenterRedirect = [
  DELAYED_WITHOUT_MOVEMENT_ALERT,
  DELAYED_WITH_MOVEMENT_RETURN_ALERT,
  FINALIZER_STATUS_ALERT
];

const getAlertCase = ({ data, maxDaysMap, companyId }) => {
  if (data.isFinalStatus()) {
    return FINALIZER_STATUS_ALERT;
  }

  if (data.isPackageDelayed()) {
    const maxAllowedDays = maxDaysMap[companyId]
      ? maxDaysMap[companyId]
      : maxDaysMap.default;

    const lastUpdatedAt = parseISO(data.statusUpdatedTime);

    if (differenceInDays(new Date(), lastUpdatedAt) > maxAllowedDays) {
      return DELAYED_WITHOUT_MOVEMENT_ALERT;
    }

    if (data.isPackageDirectionReturn()) {
      return DELAYED_WITH_MOVEMENT_RETURN_ALERT;
    }

    return DELAYED_WITH_MOVEMENT_DELIVERY_ALERT;
  }

  if (data.isPackageDirectionReturn()) {
    return WITH_MOVEMENT_RETURN_ALERT;
  }

  return WITH_MOVEMENT_DELIVERY_ALERT;
};

const PackageMovementContent = ({ data, handleClose }) => {
  const { value: allowedDaysWihoutMovement } = useRemoteConfig(
    'max_days_without_movement_per_company'
  );

  const maxDaysMap = JSON.parse(allowedDaysWihoutMovement);

  const { companyId } = useParams();

  const alertCase = getAlertCase({ data, maxDaysMap, companyId });

  const isWithHelpCenterRedirect = casesWithHelpCenterRedirect.includes(
    alertCase
  );
  const isInDelivery = alertCase === DELAYED_WITH_MOVEMENT_DELIVERY_ALERT;
  const daysCount =
    alertCase === DELAYED_WITHOUT_MOVEMENT_ALERT
      ? differenceInDays(new Date(), parseISO(data.statusUpdatedTime))
      : undefined;

  return (
    <Box
      paddingX={3}
      paddingY={5}
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <MovementDrawerAlert alertCase={alertCase} daysCount={daysCount} />
      {isWithHelpCenterRedirect && <MovementActionHelpCenter />}
      {isInDelivery && (
        <CancelPackageAction data={data} handleClose={handleClose} />
      )}
    </Box>
  );
};

PackageMovementContent.propTypes = {
  data: packageProps.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default PackageMovementContent;
