import { useTheme } from '@material-ui/core';

import { SCHEDULING_STATUSES } from '../scheduling/constants';

const useSchedulingStatusColor = orderScheduling => {
  const { palette } = useTheme();

  if (!orderScheduling) return null;

  const status = orderScheduling?.status;

  const backgroundMap = {
    [SCHEDULING_STATUSES.CANCELLED]: palette.error.dark,
    [SCHEDULING_STATUSES.FAILED_TO_SCHEDULE]: palette.error.dark,
    [SCHEDULING_STATUSES.DISPATCHED]: palette.success.dark,
    [SCHEDULING_STATUSES.PARTIALLY_DISPATCHED]: palette.success.dark,
    [SCHEDULING_STATUSES.WAITING_CONFIRMATION]: null,
    [SCHEDULING_STATUSES.WAITING_PICKUP]: null
  };

  return backgroundMap[status];
};

export default useSchedulingStatusColor;
