import React, { useState, useReducer } from 'react';
import { Button, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PackageDrawerBase from '../../../../package-drawer-base';
import ReportDownloadDrawerContent from '../report-download-drawer/report-download-drawer-content';
import FirebaseNotificationModal from '../report-download-dialog/firebase-notification-modal';
import {
  isNotificationDenied,
  isNotificationGranted
} from '../../../../../firebase/notifications';
import LoadingDownloadReportModal from '../report-download-dialog/loading-download-report-modal';
import ErrorDownloadReportModal from '../report-download-dialog/error-download-report-modal';

const PackagesReportButton = () => {
  const { t } = useTranslation('packages');

  const [openDrawer, setOpenDrawer] = useState(false);
  const initialModalState = {
    openFirebaseNotificationModal: false,
    openLoadingDownloadReportModal: false,
    openErrorDownloadReportModal: false,
    showBlockedNotificationModal: false
  };

  const [modalState, setModalState] = useReducer(
    (curState, newState) => ({ ...curState, ...newState }),
    initialModalState
  );

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleCloseModal = () => {
    setModalState({ openFirebaseNotificationModal: false });
  };

  const handleCloseErrorModal = () => {
    setModalState({ openErrorDownloadReportModal: false });
  };

  const handleReportDownloadRequest = state => {
    handleCloseDrawer();

    setModalState({
      openLoadingDownloadReportModal: state.loading,
      openErrorDownloadReportModal: state.error
    });
  };

  const handleOpenDrawer = () => {
    const showReportDownloadDrawer = isNotificationGranted();
    const notificationDenied = isNotificationDenied();
    setModalState({ showBlockedNotificationModal: notificationDenied });

    if (showReportDownloadDrawer) {
      setOpenDrawer(true);
    } else {
      setModalState({ openFirebaseNotificationModal: true });
    }
  };

  return (
    <>
      <Box>
        <Button
          size="small"
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleOpenDrawer}
        >
          {t('customReport.reportSettingsButton')}
        </Button>
      </Box>
      <PackageDrawerBase open={openDrawer} onClose={handleCloseDrawer}>
        <ReportDownloadDrawerContent
          handleReportDownloadRequest={handleReportDownloadRequest}
        />
      </PackageDrawerBase>
      <FirebaseNotificationModal
        open={modalState.openFirebaseNotificationModal}
        onClose={handleCloseModal}
        openDrawer={handleOpenDrawer}
        isBlockedNotification={modalState.showBlockedNotificationModal}
      />
      <LoadingDownloadReportModal
        open={modalState.openLoadingDownloadReportModal}
      />
      <ErrorDownloadReportModal
        open={modalState.openErrorDownloadReportModal}
        onClose={handleCloseErrorModal}
      />
    </>
  );
};

export default PackagesReportButton;
