import React from 'react';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ReturnImage } from '../../../assets/return.svg';
import managementRoutes from '../../management-routes';

const ReturnAddressSection = ({ data }) => {
  const { t } = useTranslation('management');

  const { companyId, warehouseId, aliasName, returnAddress } = data;

  const { push } = useHistory();

  const handleReturnAddressButton = () => {
    push(managementRoutes.returnAddress.url(companyId, warehouseId));
  };

  const isEmptyResult = returnAddress === undefined;

  const spacing = { xs: 3, sm: 5.5 };

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        data-testid="return-address-section"
        pb={isEmptyResult ? spacing : 4}
        px={spacing}
        pt={spacing}
      >
        <Box display="flex" flexDirection="row">
          <Box pt={1} pr={3}>
            <ReturnImage />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h5">
              <strong>{t('addresses.return.title')}</strong>
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {t('addresses.return.subtitle')}
            </Typography>
          </Box>
        </Box>
        {isEmptyResult && (
          <Box display="flex" alignItems="center">
            <Button
              data-testid="return-add-button"
              variant="contained"
              color="primary"
              onClick={handleReturnAddressButton}
            >
              {t('addresses.return.addButton')}
            </Button>
          </Box>
        )}
      </Box>
      {!isEmptyResult && (
        <Box>
          <Divider />
          <Box
            display="flex"
            flexDirection="row"
            alignItems={{ xs: 'center' }}
            pt={4}
            px={spacing}
            pb={spacing}
          >
            <Box
              display="flex"
              flex="4"
              flexDirection={{ xs: 'column', sm: 'row' }}
            >
              <Box pr={4}>
                <Typography>
                  <strong>{aliasName}</strong>
                </Typography>
              </Box>
              <Box>
                <Typography>{returnAddress}</Typography>
              </Box>
            </Box>
            <Box alignItems={{ sm: 'flex-end' }}>
              <Button
                size="small"
                color="primary"
                data-testid="return-edit-button"
                onClick={handleReturnAddressButton}
              >
                {t('addresses.return.editButton')}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

ReturnAddressSection.propTypes = {
  data: PropTypes.shape({
    companyId: PropTypes.string,
    warehouseId: PropTypes.string,
    aliasName: PropTypes.string,
    returnAddress: PropTypes.string
  }).isRequired
};

export default ReturnAddressSection;
