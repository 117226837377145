import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Box,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { getIntegratorsList } from './utils';

const useStyles = makeStyles(({ spacing }) => ({
  radioFormControl: {
    marginTop: spacing(4),
    '& .MuiFormLabel-root': {
      color: 'initial',
      '&.Mui-focused': {
        color: 'initial'
      }
    }
  }
}));

const IntegratorsField = ({
  selectedIntegrators,
  setSelectedIntegrators,
  hasIntegration,
  setHasIntegration
}) => {
  const { t } = useTranslation('signupCompanyForm');
  const styles = useStyles();
  const { spacing, palette } = useTheme();
  const integratorsList = getIntegratorsList();

  const handleHasIntegrationChange = event => {
    const booleanRadioValue = event.target.value === 'true';
    setHasIntegration(booleanRadioValue);
    if (!booleanRadioValue) setSelectedIntegrators([]);
  };

  return (
    <>
      <FormControl component="fieldset" className={styles.radioFormControl}>
        <FormLabel component="legend">
          {t('companyPickupForm.hasIntegration.question')}
        </FormLabel>
        <RadioGroup
          data-testid="has-integration-radio"
          aria-label="has-integration"
          name="has-integration"
          value={hasIntegration.toString()}
          onChange={handleHasIntegrationChange}
          style={{ marginTop: spacing(1) }}
        >
          <Box>
            <FormControlLabel
              value="true"
              control={<Radio />}
              label={t('companyPickupForm.hasIntegration.yes')}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label={t('companyPickupForm.hasIntegration.no')}
            />
          </Box>
        </RadioGroup>
      </FormControl>
      {hasIntegration && (
        <FormControl
          fullWidth
          variant="outlined"
          style={{ marginTop: spacing(1) }}
        >
          <InputLabel>
            {t('companyPickupForm.inputLabels.integrators')}
          </InputLabel>
          <Select
            data-testid="integrators-list-select"
            id="integrators-list-select"
            input={
              <OutlinedInput
                label={t('companyPickupForm.inputLabels.integrators')}
              />
            }
            inputProps={{ 'data-testid': 'integrators-list-input' }}
            labelId="integrators-list-select-label"
            multiple
            onChange={event => setSelectedIntegrators(event.target.value)}
            renderValue={() => selectedIntegrators.join(', ')}
            value={selectedIntegrators}
            MenuProps={{ getContentAnchorEl: () => null }}
          >
            {integratorsList.map(integrator => (
              <MenuItem key={integrator} value={integrator}>
                <Checkbox
                  checked={selectedIntegrators.some(
                    selectedIntegrator => selectedIntegrator === integrator
                  )}
                  color="primary"
                />
                <ListItemText
                  primary={<Typography noWrap>{integrator}</Typography>}
                />
              </MenuItem>
            ))}
          </Select>
          {!selectedIntegrators.length && (
            <FormHelperText error style={{ color: palette.grey[700] }}>
              {t('companyPickupForm.validation.integratorRequired')}
            </FormHelperText>
          )}
        </FormControl>
      )}
    </>
  );
};

IntegratorsField.propTypes = {
  selectedIntegrators: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedIntegrators: PropTypes.func.isRequired,
  hasIntegration: PropTypes.bool.isRequired,
  setHasIntegration: PropTypes.func.isRequired
};

export default IntegratorsField;
