import { AppErrorBoundary } from '@loggi/components/src/one';
import React from 'react';

import UsersManagementRoutes from './users-management.routes';

const UsersManagement = () => (
  <AppErrorBoundary
    beforeCapture={scope => {
      scope.setTag('app.region', 'users-management');
    }}
  >
    <UsersManagementRoutes />
  </AppErrorBoundary>
);

export default UsersManagement;
