import React from 'react';
import { useTranslation } from 'react-i18next';

import SignupCompanyTemplate from '@loggi/components/src/one/sign-up-company';

const WaitingContact = () => {
  const { t } = useTranslation('signupCompanyForm');

  return (
    <SignupCompanyTemplate
      formTitles={t('waitingContact.titles', { returnObjects: true })}
      formSubtitles={t('waitingContact.subtitles', { returnObjects: true })}
      outOfCoverage
      finishedSignUp
    >
      <></>
    </SignupCompanyTemplate>
  );
};

export default WaitingContact;
