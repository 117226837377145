import React from 'react';
import PropTypes from 'prop-types';
import { Box, Drawer, IconButton, withStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

const CustomDrawer = withStyles(({ breakpoints, spacing }) => ({
  paper: {
    width: '100%',
    [breakpoints.up('sm')]: {
      maxWidth: spacing(58),
      minWidth: spacing(58)
    }
  }
}))(Drawer);

const PriceAgreementsDrawer = props => {
  const { children, open, onClose } = props;

  return (
    <CustomDrawer
      data-testid="price-agreement-drawer"
      anchor="right"
      elevation={24}
      ModalProps={{
        hideBackdrop: false,
        disableScrollLock: false,
        BackdropProps: {
          invisible: false
        }
      }}
      SlideProps={{ onExited: onClose }}
      onClose={onClose}
      open={open}
    >
      <Box pt={{ xs: 3, sm: 4 }} px={{ xs: 3, sm: 4 }}>
        <IconButton onClick={onClose} edge="start" data-testid="close-button">
          <ArrowBack color="primary" />
        </IconButton>
      </Box>
      {children}
    </CustomDrawer>
  );
};

PriceAgreementsDrawer.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool
};
PriceAgreementsDrawer.defaultProps = {
  children: null,
  open: false
};

export default PriceAgreementsDrawer;
