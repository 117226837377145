import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import SchedulingDetailsEmptyPackagesList from '../scheduling-details-empty-packages-list';
import SchedulingDetailsPackagesList from '../scheduling-details-packages-list';
import SchedulingDetailsPackagesListPagination from '../scheduling-details-packages-list-pagination';
import SchedulingDetailsPackagesListError from '../scheduling-details-packages-list-error';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    minHeight: spacing(14),
    [breakpoints.up('sm')]: {
      minHeight: spacing(60)
    }
  },
  paginationBox: {
    marginTop: spacing(2),
    [breakpoints.up('sm')]: {
      marginTop: spacing(3)
    }
  }
}));

/**
 * SchedulingDetailsPackagesListWrapper is a wrapper component that adds a loading capability
 * before the packages list and pagination
 * @param {object} data
 * @param {number} count
 * @param {boolean} isLoading
 * @param {boolean} error
 * @returns {*}
 * @constructor
 */
const SchedulingDetailsPackagesListWrapper = props => {
  const styles = useStyles();
  const { data, count, isLoading: loading, error } = props;
  const isLoading = loading && !error;
  const isEmpty = !loading && !data?.length;
  const wrap = component => (
    <Box
      className={styles.root}
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent={isLoading ? 'center' : {}}
    >
      {component}
    </Box>
  );
  if (isLoading) return wrap(<CircularProgress data-testid="loading" />);
  if (error) return wrap(<SchedulingDetailsPackagesListError />);
  if (isEmpty) return wrap(<SchedulingDetailsEmptyPackagesList />);

  return wrap(
    <>
      <SchedulingDetailsPackagesList data={data} isLoading={isLoading} />
      <Box alignSelf="flex-end" className={styles.paginationBox}>
        <SchedulingDetailsPackagesListPagination
          count={count}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
};

SchedulingDetailsPackagesListWrapper.propTypes = {
  count: PropTypes.number,
  data: PropTypes.oneOfType([PropTypes.any, PropTypes.arrayOf({})]),
  isLoading: PropTypes.bool,
  error: PropTypes.bool
};

SchedulingDetailsPackagesListWrapper.defaultProps = {
  count: 0,
  data: [],
  isLoading: true,
  error: false
};

export default SchedulingDetailsPackagesListWrapper;
