const ROLES = {
  ADMIN: 'ADMIN',
  ADMIN_LARGE: 'ADMIN_LARGE',
  FINANCE: 'FINANCE',
  MANAGER_LARGE: 'MANAGER_LARGE',
  OPERATOR: 'OPERATOR',
  OPERATOR_LARGE: 'OPERATOR_LARGE',
  REGULAR: 'REGULAR',
  OWNER: 'OWNER',
  OWNER_LARGE: 'OWNER_LARGE'
};

export default ROLES;
