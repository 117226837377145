import React, { useEffect, useState } from 'react';
import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { Box, Typography } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddressListWrapper from './address-list-wrapper/index';
import addressesApi from '../../addresses-api';
import managementRoutes from '../../management-routes';

const initialState = {
  isLoading: true,
  warehouses: [],
  showGenericError: false
};

const AddressList = () => {
  const { t } = useTranslation('management');
  const { push } = useHistory();

  const { companyId, warehouseId } = useParams();
  const [{ warehouses, isLoading, showGenericError }, setState] = useState(
    initialState
  );

  useEffect(() => {
    addressesApi
      .warehouses(companyId)
      .then(response => {
        setState({
          isLoading: false,
          warehouses: response.warehouses,
          showGenericError: false
        });
      })
      .catch(() => {
        setState({
          isLoading: false,
          warehouses: [],
          showGenericError: true
        });
      });
  }, [companyId]);

  const warehouseInfo = warehouses?.find(
    warehouse => warehouse.warehouseId === warehouseId
  );
  if (!isLoading && !showGenericError && warehouseInfo === undefined) {
    push(managementRoutes.warehousesAddresses.url(companyId));
  }

  const aliasName = warehouseInfo?.returnAddress?.aliasName;

  const returnAddress =
    warehouseInfo?.returnAddress?.address.postalAddress.addressLines?.[0];

  const data = { companyId, warehouseId, aliasName, returnAddress };

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Typography data-testid="cart-title" component="h1" variant="h4">
          <Box component="strong" fontWeight="fontWeightBold">
            {t('addresses.title')}
          </Box>
        </Typography>
        <Typography variant="body1">{t('addresses.subtitle')}</Typography>
      </OneTemplateSummary>
      <OneTemplateContent disablePadding>
        <AddressListWrapper
          data={data}
          isLoading={isLoading}
          error={showGenericError}
        />
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default AddressList;
