import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const SectionTitle = ({ children }) => (
  <Typography variant="subtitle2" gutterBottom>
    <Box component="strong" fontWeight="fontWeightBold">
      {children}
    </Box>
  </Typography>
);

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired
};

export default SectionTitle;
