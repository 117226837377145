import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Button, Typography, Box, makeStyles } from '@material-ui/core';

import EmptyPickupsIllustration from '../assets/empty-drivers.svg';

const useStyles = makeStyles(({ typography }) => ({
  image: {
    width: '100%',
    maxWidth: typography.pxToRem(155)
  }
}));

const SchedulingDetailsEmptyDriversList = ({ cancelled }) => {
  const classes = useStyles();
  const { t } = useTranslation('details');
  const { push } = useHistory();
  const redirectToSchedulesList = () => push('/coletas');

  const emptyLabel = cancelled
    ? 'emptyCancelledDriversList'
    : 'emptyDriversList';

  return (
    <Box
      alignItems="center"
      width={1}
      maxWidth={300}
      pt={{ xs: 7, sm: 0 }}
      data-testid="empty-state-wrapper"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      m="auto"
      textAlign="center"
    >
      <img
        className={classes.image}
        src={EmptyPickupsIllustration}
        alt="Lista de pacotes vazia"
      />
      <Box mt={3.5} mb={2}>
        <Box mb={2}>
          <Typography variant="body1">
            <strong>{t(`${emptyLabel}.title`)}</strong>
          </Typography>
        </Box>
        <Typography variant="body1">{t(`${emptyLabel}.subtitle`)}</Typography>
        <Box pt={2}>
          <Button onClick={redirectToSchedulesList} color="primary">
            {t('emptyDriversList.linkText')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SchedulingDetailsEmptyDriversList.propTypes = {
  cancelled: PropTypes.bool
};

SchedulingDetailsEmptyDriversList.defaultProps = {
  cancelled: false
};

export default SchedulingDetailsEmptyDriversList;
