import { translations as managementTranslations } from '../management';

const translations = {
  'pt-BR': {
    ...managementTranslations['pt-BR']
  },
  en: {
    ...managementTranslations.en
  }
};

export default translations;
