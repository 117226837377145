import { colors } from '@loggi/mar';
import {
  Box,
  ButtonBase,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  withStyles
} from '@material-ui/core';
import { ArrowBack, Error } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import packagesRoutes from '../../packages-routes';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  downloadModelLink: {
    fontWeight: typography.fontWeightBold,
    verticalAlign: 'baseline'
  },
  listItemBulletPoints: {
    display: 'list-item',
    listStyle: 'disc',
    '&::marker': {
      fontSize: typography.subtitle2.fontSize
    }
  },
  listItemColumns: {
    counterIncrement: 'column',
    '&::before': {
      alignItems: 'center',
      content: 'counter(column, upper-alpha)',
      backgroundColor: palette.primary.light,
      border: `2px solid ${palette.primary.main}`,
      borderRadius: '50%',
      boxShadow: `0px 0px 0px 2px ${palette.background.paper} inset`,
      color: colors.smoke[900],
      display: 'flex',
      fontSize: typography.subtitle2.fontSize,
      fontWeight: typography.fontWeightBold,
      minHeight: typography.pxToRem(40),
      justifyContent: 'center',
      marginRight: spacing(2),
      minWidth: typography.pxToRem(40)
    },
    listStyle: 'none'
  },
  warningBox: {
    border: `2px solid ${colors.smoke[200]}`,
    borderRadius: '8px'
  }
}));

const CustomDrawer = withStyles(({ breakpoints, spacing }) => ({
  root: {
    left: ['auto', '!important']
  },
  paper: {
    [breakpoints.up('sm')]: {
      maxWidth: spacing(58),
      minWidth: spacing(58)
    },
    width: '100%'
  }
}))(Drawer);

const InstructionsDrawer = () => {
  const { t } = useTranslation('packages');
  const styles = useStyles();
  const history = useHistory();
  const { companyId } = useParams();
  const modelFile = process.env.REACT_APP_SPREADSHEET_MODEL_FILE_S3;
  const bulletPoints = t('create.spreadsheetFlow.instructions.bulletPoints', {
    returnObjects: true
  });
  const spreadsheetColumns = t('create.spreadsheetFlow.instructions.columns', {
    returnObjects: true
  });

  const closeDrawer = () =>
    history.push(packagesRoutes.create.spreadsheet.upload.url(companyId));

  const DownloadSpreadsheetModel = () => (
    <ButtonBase
      color="primary"
      component={Link}
      className={styles.downloadModelLink}
      data-testid="download-spreadsheet-model-btn"
      onClick={() => window.open(modelFile)}
    >
      {t('create.spreadsheetFlow.instructions.linkText')}
    </ButtonBase>
  );

  const bulletPointsListItemText = item => {
    const renderDownloadLink = Boolean(item.secondDescription);
    return (
      <Box>
        {item.firstDescription}
        {renderDownloadLink && <DownloadSpreadsheetModel />}
        {item.secondDescription}
      </Box>
    );
  };

  return (
    <CustomDrawer
      anchor="right"
      elevation={24}
      ModalProps={{
        BackdropProps: {
          invisible: true
        },
        disableScrollLock: true,
        hideBackdrop: false
      }}
      onClose={closeDrawer}
      open
      SlideProps={{ onExited: closeDrawer }}
    >
      <Box py={{ xs: 3, sm: 4 }}>
        <Box px={{ xs: 3 }}>
          <IconButton
            data-testid="close-drawer-btn"
            edge="start"
            onClick={closeDrawer}
          >
            <ArrowBack color="primary" />
          </IconButton>
        </Box>
        <Box px={{ xs: 3 }}>
          <Typography variant="h6">
            <Box pt={2} pb={1} fontWeight="fontWeightBold">
              {t('create.spreadsheetFlow.instructions.title')}
            </Box>
          </Typography>
          <List disablePadding>
            <Box display="flex" flexDirection="column" px={2}>
              {bulletPoints.map(item => (
                <ListItem
                  className={styles.listItemBulletPoints}
                  disableGutters
                >
                  <Typography variant="subtitle2">
                    <ListItemText primary={bulletPointsListItemText(item)} />
                  </Typography>
                </ListItem>
              ))}
            </Box>
          </List>
          <List disablePadding>
            <Box display="flex" flexDirection="column">
              {spreadsheetColumns.map(item => (
                <ListItem className={styles.listItemColumns}>
                  <ListItemText
                    primary={item.title}
                    secondary={
                      <Typography variant="body2" color="textSecondary">
                        {item.description}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </Box>
          </List>
          <Box className={styles.warningBox} display="flex" mt={2} mx={1} p={2}>
            <Box pt={0.35}>
              <Error fontSize="small" />
            </Box>
            <Box ml={2}>
              <Typography variant="body2">
                <Box fontWeight="fontWeightMedium">
                  {t('create.spreadsheetFlow.instructions.warning.title')}
                </Box>
              </Typography>
              <Typography variant="body2">
                <Box>
                  {t('create.spreadsheetFlow.instructions.warning.description')}
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomDrawer>
  );
};

export default InstructionsDrawer;
