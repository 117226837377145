import React from 'react';
import PropTypes from 'prop-types';

// @MATERIAL-UI
import { Typography, Box, Divider } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { SCHEDULING_STATUSES } from '../../../scheduling/constants';

/**
 * SchedulingDetailsVehicleAmountOverview's only responsibility is to show a
 * overview about the drivers quantity allocated for the pickup order schedule.
 * @componentType: Presentational
 * @param vehiclesAmount
 * @returns {*}
 */
const SchedulingDetailsVehiclesAmountOverview = ({
  vehiclesAmount,
  orderStatus
}) => {
  const { t } = useTranslation('details');

  const vehicleAmountLabel =
    orderStatus === SCHEDULING_STATUSES.WAITING_PICKUP
      ? 'vehiclesAmountOverview.toCollect'
      : 'vehiclesAmountOverview.collected';

  return (
    <>
      <Box
        p={{ xs: 3, sm: 5 }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body1">
          <strong>{String(vehiclesAmount).padStart(2, '0')}</strong>
          {t(vehicleAmountLabel, { count: vehiclesAmount })}
        </Typography>
        <ArrowForwardIos style={{ fontSize: 20 }} color="primary" />
      </Box>
      <Divider />
    </>
  );
};

SchedulingDetailsVehiclesAmountOverview.propTypes = {
  vehiclesAmount: PropTypes.number.isRequired,
  orderStatus: PropTypes.string.isRequired
};

export default SchedulingDetailsVehiclesAmountOverview;
