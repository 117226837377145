import { loggiWebApi } from '@loggi/authentication-lib';
import { FIRST_PAGE_TOKEN, PACKAGES_FETCH_ERRORS } from './list/constants';
import endpoints from './packages-api-endpoints';

const packagesApi = {
  search: (
    companyId,
    {
      query,
      pageToken,
      shipperStatus,
      created,
      from,
      to,
      pageSize,
      packageDirection,
      integratorId,
      searchCompanies
    }
  ) => {
    // First request get all packages from the company
    if (pageToken < FIRST_PAGE_TOKEN)
      throw new Error(PACKAGES_FETCH_ERRORS.INVALID_PAGE);

    const backendUrl = {
      companyId,
      query,
      shipperStatus,
      created,
      pageToken,
      pageSize,
      packageDirection,
      from,
      to,
      integratorId,
      searchCompanies
    };

    const url = endpoints.search(backendUrl);
    return loggiWebApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .get()
      .json();
  },
  create: (values, companyId) => {
    const {
      cnpjOrCpf,
      height,
      invoiceKey,
      length,
      name,
      phone,
      recipient,
      recipientAddressComplement,
      totalValue,
      weight,
      width
    } = values;

    const normalizeNumericValue = value => value.replace(/[\D]*/g, '');

    const requestParams = {
      package: {
        height_cm: height,
        invoice_key: invoiceKey,
        length_cm: length,
        total_value: totalValue,
        weight_g: weight,
        width_cm: width
      },
      recipient: {
        address: {
          address_complement: recipientAddressComplement,
          full_address: recipient.description,
          place_id: recipient.place_id
        },
        cpf_cnpj: normalizeNumericValue(cnpjOrCpf),
        name,
        phone_number: normalizeNumericValue(phone)
      }
    };

    const url = endpoints.create(companyId);
    return loggiWebApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .post(requestParams);
  },
  getLabel: (companyId, trackingKey) => {
    const url = endpoints.getLabel(companyId, trackingKey);

    return loggiWebApi
      .url(url)
      .accept('application/pdf')
      .content('application/pdf')
      .get();
  },
  cancel: (companyId, trackingKey) => {
    const url = endpoints.cancel(companyId, trackingKey);
    return loggiWebApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .post()
      .json();
  },
  bulkCancel: (companyId, trackingKeys = []) => {
    const url = endpoints.bulkCancel(companyId);

    const payload = { tracking_keys: trackingKeys };

    const api = loggiWebApi
      .url(url)
      .accept('application/json')
      .content('application/json');

    return api.post(payload).json();
  },
  report: (companyId, payload) => {
    const url = endpoints.report(companyId);

    return loggiWebApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .post(payload)
      .res();
  },
  update: (companyId, trackingKey, values) => {
    const url = endpoints.update(companyId, trackingKey);

    const {
      address,
      complement,
      placeId,
      recipientName,
      recipientPhone
    } = values;

    const payload = {
      widget_address: {
        address,
        complement,
        places_api_info: {
          place_id: placeId
        }
      },
      recipient: {
        full_name: recipientName,
        phone: recipientPhone
      }
    };

    return loggiWebApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .put(payload)
      .res();
  },
  upload: (companyId, file) =>
    loggiWebApi
      .accept('application/json')
      .url(endpoints.upload(companyId))
      .formData({ loggiInboundCSV: file })
      .post(),
  uploadInvoices: (companyId, files) =>
    loggiWebApi
      .accept('application/json')
      .url(endpoints.xmlUpload(companyId))
      .formData({ files })
      .post()
};

export default packagesApi;
