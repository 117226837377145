import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Typography,
  FormControl,
  OutlinedInput
} from '@material-ui/core';
import Toast from '@loggi/components/src/one/toast';
import PropTypes from 'prop-types';
import updatePickupReceiptFeedback from '../../update-pickup-receipt-feedback-api';

const PickupReceiptFeedbackDrawerContent = props => {
  const { t } = useTranslation('details');
  const { companyId, id: pickupOrderScheduleId, pickupReceiptId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isReceiptAlreadyAnswered,
    feedbackMessage,
    handleCloseDrawer
  } = props;
  const [feedback, setFeedback] = useState(feedbackMessage);

  const handleChange = event => {
    setFeedback(event.target.value);
  };

  const sendFeedbackButtonProps = {
    true: {
      color: 'default',
      variant: 'text',
      text: t('feedbackDrawerContent.sendFeedbackButton.alreadyAnswered.text')
    },
    false: {
      color: 'primary',
      variant: 'contained',
      text: t('feedbackDrawerContent.sendFeedbackButton.sendFeedback.text')
    }
  };

  const buttonProps = sendFeedbackButtonProps[!!isReceiptAlreadyAnswered];

  const confirmFeedback = () => {
    updatePickupReceiptFeedback(
      pickupReceiptId,
      false,
      feedback,
      pickupOrderScheduleId,
      companyId
    )
      .forbidden(() => {
        enqueueSnackbar(
          t('feedbackDrawerContent.errorsMessages.answerLimitExpired'),
          {
            content: (key, message) => (
              <Toast id={key} message={message} type="error" />
            )
          }
        );
      })
      .res(() => {
        enqueueSnackbar(t('feedbackDrawerContent.confirmationFeedback.text'), {
          content: (key, message) => (
            <Toast id={key} message={message} type="success" />
          )
        });
      })
      .catch(() => {
        enqueueSnackbar(
          t('feedbackDrawerContent.errorsMessages.unexpectedError'),
          {
            content: (key, message) => (
              <Toast id={key} message={message} type="error" />
            )
          }
        );
      })
      .finally(() => {
        handleCloseDrawer();
      });
  };

  return (
    <Box p={3}>
      <Box display="flex">
        <Typography variant="h6" component="h1">
          {t('feedbackDrawerContent.title')}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" pb={4} pt={4}>
        {isReceiptAlreadyAnswered ? (
          <Typography variant="body1" data-testid="shipper-feedback-message">
            {feedback}
          </Typography>
        ) : (
          <FormControl variant="outlined" fullWidth margin="normal">
            <OutlinedInput
              data-testid="feedback-text-area"
              multiline
              rows={7}
              disabled={isReceiptAlreadyAnswered}
              value={feedback}
              onChange={handleChange}
            />
          </FormControl>
        )}
      </Box>
      <Button
        color={buttonProps.color}
        fullWidth
        size="small"
        type="submit"
        variant={buttonProps.variant}
        onClick={() => {
          if (!isReceiptAlreadyAnswered) {
            confirmFeedback();
          }
        }}
        data-testid="send-feedback-button"
      >
        {buttonProps.text}
      </Button>
    </Box>
  );
};

PickupReceiptFeedbackDrawerContent.propTypes = {
  isReceiptAlreadyAnswered: PropTypes.bool.isRequired,
  feedbackMessage: PropTypes.string,
  handleCloseDrawer: PropTypes.func.isRequired
};

PickupReceiptFeedbackDrawerContent.defaultProps = {
  feedbackMessage: null
};

export default PickupReceiptFeedbackDrawerContent;
