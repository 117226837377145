import React from 'react';
import { Box, ListItem, ListItemText, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const PackagesList = ({ packages }) => {
  const { t } = useTranslation('pickupOrderSchedulePackages');

  const packageListItems = () => {
    return packages.map(pkg => {
      return (
        <ListItem disableGutters component="div" divider key={pkg.tracking_key}>
          <ListItemText
            disableTypography
            primary={
              <Box px={3} pt={2}>
                <Typography variant="subtitle2" color="textPrimary">
                  {pkg.recipient_name}
                </Typography>
              </Box>
            }
            secondary={
              <Box pt={1} px={3} pb={2}>
                <Typography variant="subtitle2" color="textSecondary">
                  {pkg.tracking_key}
                </Typography>
              </Box>
            }
          />
        </ListItem>
      );
    });
  };

  return (
    <>
      <Box
        p={3}
        style={{
          backgroundColor: colors.smoke[50],
          borderRadius: '16px 16px 0px 0px'
        }}
      >
        <Typography variant="subtitle2" color="textPrimary">
          <strong>{t('list.title')}</strong>
        </Typography>
      </Box>
      {packageListItems()}
    </>
  );
};

PackagesList.propTypes = {
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      recipient_name: PropTypes.string,
      tracking_key: PropTypes.string
    })
  ).isRequired
};

export default PackagesList;
