const ACCESS_DENIED = '/acesso-negado';
const COMPANIES_ROUTE = '/empresas';
const CREATE_NEW_PASSWORD_ROUTE = '/criar-nova-senha';
const HOME_ROUTE = '/';
const IMPERSONATION_ROUTE = '/representar';
const MANAGEMENT = {
  path: '/empresas/:companyId/configurar',
  url: companyId => `/empresas/${companyId}/configurar`
};
const COMING_SOON = 'estamos-chegando';
const OUT_OF_COVERAGE = {
  path: `/empresas/:companyId/${COMING_SOON}`,
  url: companyId => `/empresas/${companyId}/${COMING_SOON}`
};
const PACKAGES_ROUTE = '/pacotes';
const TRACKING_ROUTE = '/coletas';
const USERS_MANAGEMENT = {
  path: '/empresas/:companyId/gerenciar-usuarios',
  url: companyId => `/empresas/${companyId}/gerenciar-usuarios`
};
const RECURRING_PICKUP_UPDATE = {
  path: '/empresas/:companyId/coletas/rotina/:recurringPickupId/editar'
};
const PICKUPS_ROUTE = {
  path: '/empresas/:companyId/coletas/adicionar'
};

const PRICE_AGREEMENTS = 'contratos-de-precos';

const LIST_ALLOWED_URL_REDIRECT = [PRICE_AGREEMENTS];

export {
  ACCESS_DENIED,
  COMING_SOON,
  COMPANIES_ROUTE,
  CREATE_NEW_PASSWORD_ROUTE,
  HOME_ROUTE,
  IMPERSONATION_ROUTE,
  MANAGEMENT,
  OUT_OF_COVERAGE,
  PACKAGES_ROUTE,
  PICKUPS_ROUTE,
  TRACKING_ROUTE,
  USERS_MANAGEMENT,
  LIST_ALLOWED_URL_REDIRECT,
  RECURRING_PICKUP_UPDATE
};
