/* eslint-disable max-classes-per-file */
import { loggiWebApi } from '@loggi/authentication-lib';
import * as Sentry from '@sentry/react';
import camelCaseKeys from 'camelcase-keys';
import { getI18n } from 'react-i18next';
import qs from 'qs';

import {
  DEFAULT_PAGE_SIZE,
  FIRST_PAGE,
  ORDER_SCHEDULES_FETCH_ERRORS,
  PICKUP_STATUS_ALLOCATED,
  PICKUP_STATUS_COLLECTED,
  PICKUP_STATUS_CHECKED_IN
} from './constants';
import {
  ORDER_SCHEDULES_ENDPOINT,
  PICKUPS_COMPANIES_ENDPOINT
} from '../../constants';

export class GetSchedulingDetailsError extends Error {
  constructor(...args) {
    super(...args);
    this.name = `GetSchedulingDetailsError`;
  }
}

export class GetSchedulingPickupsError extends Error {
  constructor(...args) {
    super(...args);
    this.name = `GetSchedulingPickupsError`;
  }
}

const baseEndpoint = companyId => {
  return `${PICKUPS_COMPANIES_ENDPOINT}/${companyId}/${ORDER_SCHEDULES_ENDPOINT}`;
};

const treatError = (error, t, pickup = true) => {
  let thrownError;
  switch (error.status) {
    case 500: {
      if (pickup) {
        thrownError = new GetSchedulingPickupsError(
          t('requests.getSchedulingPickups.internalServerError')
        );
      } else {
        thrownError = new GetSchedulingDetailsError(
          t('requests.getSchedulingDetails.generic')
        );
      }
      break;
    }
    case 403: {
      if (pickup) {
        thrownError = new GetSchedulingPickupsError(
          t('requests.getSchedulingPickups.forbidden')
        );
      } else {
        thrownError = new GetSchedulingDetailsError(
          t('requests.getSchedulingDetails.forbidden')
        );
      }
      break;
    }
    default: {
      if (pickup) {
        thrownError = new GetSchedulingPickupsError(
          t('requests.getSchedulingPickups.generic')
        );
      } else {
        thrownError = new GetSchedulingDetailsError(
          t('requests.getSchedulingDetails.generic')
        );
      }
      break;
    }
  }

  Sentry.captureException(thrownError, {
    contexts: { 'original error': error }
  });

  throw thrownError;
};

const schedulingApi = {
  /**
   * This method is just mock for now, but later it'll be responsible for reaching
   * the PickupOrderScheduling API and retrieving the needed data.
   * It implements a Promise interface to mock a fetch request
   * @param {number} schedulingId - must be given
   * @returns {Promise<PickupOrderScheduling>}
   */
  get: (schedulingId, companyId) => {
    const t = getI18n().getFixedT(null, 'details');

    return loggiWebApi
      .url(`/${baseEndpoint(companyId)}/${schedulingId}/`)
      .get()
      .forbidden(err => {
        throw err;
      })
      .json(response => camelCaseKeys(response, { deep: true }))
      .catch(error => treatError(error, t, false));
  },

  /**
   * Implements a Promise interface that fetches PickupOrderScheduling API
   * and retrieves all pickups of a given scheduling order id.
   * @param {number} schedulingId - must be given
   * @returns {Promise<SchedulingPickups>}
   */
  getPickups: (schedulingId, companyId) => {
    const t = getI18n().getFixedT(null, 'details');

    const statuses = `${PICKUP_STATUS_ALLOCATED},${PICKUP_STATUS_COLLECTED},${PICKUP_STATUS_CHECKED_IN}`;

    return loggiWebApi
      .url(
        `/${baseEndpoint(
          companyId
        )}/${schedulingId}/pickups/?status=${statuses}`
      )
      .get()
      .forbidden(err => {
        throw err;
      })
      .json(response => camelCaseKeys(response, { deep: true }))
      .catch(error => treatError(error, t));
  },
  /**
   * This method is responsible for reaching
   * the PickupOrderScheduling API and retrieving the needed data.
   * @param {number} page - must be greater than zero
   * @param {number} pageSize - must be greater than zero
   * @returns {Promise<PickupOrderScheduling[]>}
   */
  fetchSchedulings: (
    companyId,
    created,
    orderBy,
    status,
    document,
    page = FIRST_PAGE,
    pageSize = DEFAULT_PAGE_SIZE
  ) => {
    if (page < 1)
      throw new GetSchedulingPickupsError(
        ORDER_SCHEDULES_FETCH_ERRORS.INVALID_PAGE
      );

    if (pageSize < 1)
      throw new GetSchedulingPickupsError(
        ORDER_SCHEDULES_FETCH_ERRORS.INVALID_PAGE_SIZE
      );

    const t = getI18n().getFixedT(null, 'details');
    const params = {
      created,
      order_by: orderBy,
      page,
      page_size: pageSize,
      status,
      document
    };
    return loggiWebApi
      .url(`/${baseEndpoint(companyId)}/?${qs.stringify(params)}`)
      .get()
      .forbidden(err => {
        throw err;
      })
      .json()
      .catch(error => treatError(error, t));
  }
};

export default schedulingApi;
