import ROLES from './roles';

const {
  ADMIN_LARGE,
  FINANCE,
  MANAGER_LARGE,
  OPERATOR,
  OPERATOR_LARGE,
  OWNER_LARGE
} = ROLES;

const defaultRoutes = {
  finance: [FINANCE],
  packages: [ADMIN_LARGE, MANAGER_LARGE, OPERATOR, OPERATOR_LARGE, OWNER_LARGE]
};

export default defaultRoutes;
