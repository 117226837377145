import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ typography }) => ({
  container: {
    maxWidth: typography.pxToRem(496),
    width: '100%'
  },
  title: {
    fontWeight: typography.fontWeightBold
  }
}));

const UsersFilterEmptyFilterResult = () => {
  const classes = useStyles();
  const { t } = useTranslation('listUsers');

  return (
    <Box
      alignItems="center"
      className={classes.container}
      data-testid="empty-state-wrapper"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      m="auto"
      textAlign="center"
    >
      <Box mt={3.5} mb={2}>
        <Box mb={2}>
          <Typography className={classes.title} variant="subtitle1">
            {t('emptyFilterResult.title')}
          </Typography>
        </Box>
        <Typography variant="subtitle1" component="p">
          {t('emptyFilterResult.subtitle')}
        </Typography>
      </Box>
    </Box>
  );
};

export default UsersFilterEmptyFilterResult;
