import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { getUserRole } from '@loggi/components/src/one/permissions';
import { useCheckCompanyStatus } from '@loggi/components/src/one/hooks';
import { MARKETING_UTMS_LOCAL_STORAGE_KEY } from '@loggi/authentication/src/screens/signup/constants';
import { MENU_ITEMS } from '../components/header.constants';
import { ACCESS_DENIED, COMING_SOON, OUT_OF_COVERAGE } from './constants';
import getOneRoutes from './get-routes';

import { CompanyStatusDialog } from '../components/company-status-notification';

const AllowedRoutes = ({ match }) => {
  const {
    state: { authenticatedUser, isImpersonation }
  } = useAmplifyAuth();
  const {
    params: { companyId }
  } = match;
  const history = useHistory();
  const urlLocation = window.location.href;

  const userRole = getUserRole(authenticatedUser, companyId);
  const oneRoutes = getOneRoutes(userRole);
  const { isDefaulter, isOutOfCoverage } = useCheckCompanyStatus();
  const isFromOutOfCoverage = urlLocation.includes(COMING_SOON);

  const allowedRoutes = isOutOfCoverage
    ? oneRoutes.filter(({ id }) => id === 'out-of-coverage')
    : oneRoutes.filter(({ visible }) => visible || isImpersonation);

  const defaultRoute = isOutOfCoverage
    ? OUT_OF_COVERAGE.path
    : oneRoutes.find(({ defaultRouteRoles }) =>
        defaultRouteRoles.includes(userRole)
      )?.path || MENU_ITEMS.TRACKING_ROUTE_INITIAL_PATH.path;

  useEffect(() => {
    localStorage.removeItem(MARKETING_UTMS_LOCAL_STORAGE_KEY);
  }, []);

  useEffect(() => {
    if (!isOutOfCoverage && isFromOutOfCoverage) {
      history.push(MENU_ITEMS.TRACKING_ROUTE_INITIAL_PATH.route(companyId));
    }
  }, [isOutOfCoverage, isFromOutOfCoverage, companyId, history]);

  const renderAllowedRoutes = allowedRoute => {
    const { id, path, Component } = allowedRoute;
    const homeRoute = defaultRoute.includes(path);
    const renderCompanuStatusDialog = homeRoute && isDefaulter;
    return (
      <Route
        key={id}
        path={path}
        render={() => (
          <>
            {renderCompanuStatusDialog && <CompanyStatusDialog />}
            <Component />
          </>
        )}
      />
    );
  };

  return (
    <Switch>
      {allowedRoutes.map(allowedRoute => renderAllowedRoutes(allowedRoute))}
      {userRole ? (
        <Redirect from={match.path} to={defaultRoute} />
      ) : (
        <Redirect from="*" to={ACCESS_DENIED} />
      )}
    </Switch>
  );
};

AllowedRoutes.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      companyId: PropTypes.string
    }),
    path: PropTypes.string,
    url: PropTypes.string
  }).isRequired
};

export default AllowedRoutes;
