import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PICKUP_RECEIPT_ROUTES } from '../../constants';
import fetchPickupReceipt from '../../fetch-pickup-receipt';
import PickupReceiptFeedbackDrawer from '../pickup-receipt-feedback';
import SchedulingDetailsPickupReceiptPackagesList from '../scheduling-details-pickup-receipt-packages-list';
import SchedulingDetailsPickupReceiptSummary from '../scheduling-details-pickup-receipt-summary';

const initialState = {
  isLoading: true,
  pickupReceiptData: null
};

/**
 * This is the pickup receipt scheduling details screen.
 * It's responsible for getting the data from API and then passing to it's children components.
 * It receives no params, it renders itself without external dependencies.
 * @componentType container
 * @returns {*}
 */
const SchedulingDetailsPickupReceipt = () => {
  const { palette } = useTheme();
  const history = useHistory();
  const { id: schedulingId, companyId, pickupReceiptId } = useParams();
  const [{ isLoading, pickupReceiptData }, setState] = useState(initialState);

  useEffect(() => {
    fetchPickupReceipt(schedulingId, companyId, pickupReceiptId).then(
      result => {
        setState({ isLoading: false, pickupReceiptData: result.pickupReceipt });
      }
    );
  }, [schedulingId, companyId, pickupReceiptId]);

  useEffect(() => {
    if (history.location.state?.redirectToReport === true) {
      history.replace({
        state: { ...history.location.state, redirectToReport: false }
      });
      history.push(
        PICKUP_RECEIPT_ROUTES.report.url(
          companyId,
          schedulingId,
          pickupReceiptId
        )
      );
    }
  });

  const showContent = !isLoading;

  return (
    <OneTemplate>
      <OneTemplateSummary color={palette.success.dark}>
        {showContent && (
          <SchedulingDetailsPickupReceiptSummary
            pickupReceipt={pickupReceiptData}
          />
        )}
      </OneTemplateSummary>
      <OneTemplateContent disablePadding>
        {showContent && (
          <>
            <SchedulingDetailsPickupReceiptPackagesList
              pickupReceipt={pickupReceiptData}
            />
            <PickupReceiptFeedbackDrawer
              isReceiptAlreadyAnswered={pickupReceiptData.alreadyAnswered}
              feedbackMessage={pickupReceiptData.shipperFeedbackMessage}
            />
          </>
        )}
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default SchedulingDetailsPickupReceipt;
