import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';

import { useRemoteConfig } from '../../../../firebase';
import CreatePackageNavigation from '../create-package-navigation';
import PackagesIssueAlert from '../packages-issue-alert';

const usePackagesSummaryStyles = makeStyles(({ breakpoints }) => ({
  actionCol: {
    [breakpoints.up('sm')]: {
      alignItems: 'center',
      justifyContent: 'flex-end'
    }
  }
}));

/**
 * PackagesSummary's only responsibility is to show the summary info of the
 * packages screen, with the params it receives.
 * @componentType: Presentational
 * @param count
 * @param isLoading
 * @returns {*}
 */
const PackagesSummary = ({ onChange }) => {
  const styles = usePackagesSummaryStyles();
  const { url } = useRouteMatch();
  const { t } = useTranslation('packages');
  const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { value: renderCreatePackageNavigation } = useRemoteConfig(
    'should_render_packages_navigation_select'
  );

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h4" component="h1">
            <strong>{t('list.summary.title')}</strong>
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={4} className={styles.actionCol}>
          {renderCreatePackageNavigation === 'true' ? (
            <Box pt={smUp ? '' : 2}>
              <CreatePackageNavigation />
            </Box>
          ) : (
            <Button
              component={Link}
              size="small"
              to={`${url}/adicionar`}
              variant="outlined"
            >
              {t('list.createPackage')}
            </Button>
          )}
        </Grid>
      </Grid>
      <PackagesIssueAlert onChange={onChange} />
    </Box>
  );
};

PackagesSummary.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default PackagesSummary;
