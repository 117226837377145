import { Box, Typography, Link } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { format } from 'date-fns';
import managementRoutes from '@loggi/management/src/management/management-routes';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import packagesRoutes from '@loggi/packages/src/packages/packages-routes';

/**
 * NoPackagesToPickupAlert renders a warning no packages for the day's schedule
 * @componentType presentational
 * @returns {*}
 * @constructor
 */
const NoPackagesToPickupAlert = ({
  isNoPackagesToPickupTomorrowMorning,
  cuttingTime,
  startTime
}) => {
  const { t } = useTranslation('scheduling');
  const {
    params: { companyId }
  } = useRouteMatch();

  const day = format(startTime, 'dd');
  const month = format(startTime, 'MM');
  const cuttingTimeHour = format(cuttingTime, 'HH:mm');

  let textPickupDate = t('noPackagesToPickupAlert.pickupForToday', {
    day,
    month
  });

  if (isNoPackagesToPickupTomorrowMorning) {
    textPickupDate = t('noPackagesToPickupAlert.pickupForTomorrowMorning', {
      day,
      month
    });
  }

  return (
    <Box mt={4}>
      <Alert severity="warning" data-testid="alert-no-packages-id">
        <Typography variant="body1">
          <strong>{t('noPackagesToPickupAlert.strong')}</strong>
          {textPickupDate}
          {t('noPackagesToPickupAlert.warning')}
          <Link
            color="inherit"
            underline="always"
            data-testid="create-packages-btn-id"
            href={packagesRoutes.create.singlePackage.url(companyId)}
          >
            {t('noPackagesToPickupAlert.linkAddPackages')}
          </Link>
          <strong>
            {t('noPackagesToPickupAlert.untilCuttingTime', { cuttingTimeHour })}
          </strong>
          {isNoPackagesToPickupTomorrowMorning &&
            t('noPackagesToPickupAlert.cuttingTimeDay')}
          {t('noPackagesToPickupAlert.or')}
          <Link
            color="inherit"
            underline="always"
            data-testid="go-to-integrations-btn-id"
            href={managementRoutes.integrationSystems.url(companyId)}
          >
            {t('noPackagesToPickupAlert.linkCheckIntegration')}
          </Link>
          {t('noPackagesToPickupAlert.last')}
        </Typography>
      </Alert>
    </Box>
  );
};

NoPackagesToPickupAlert.propTypes = {
  isNoPackagesToPickupTomorrowMorning: PropTypes.bool.isRequired,
  cuttingTime: PropTypes.instanceOf(Date).isRequired,
  startTime: PropTypes.instanceOf(Date).isRequired
};

export default NoPackagesToPickupAlert;
