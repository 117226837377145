import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useTheme
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from '@loggi/components/src/one/hooks';

import { FILTERS_NAMES } from '../../constants';

const parseFilter = filter => (filter?.length ? filter.split(',') : []);

const FilterIntegrator = ({ setChecked, onChange, integratorsData }) => {
  const { spacing } = useTheme();

  const filter = useQueryParams()[FILTERS_NAMES.INTEGRATOR];
  const [integratorFilter, setIntegratorFilter] = useState(parseFilter(filter));
  const { t } = useTranslation('packages');

  useEffect(() => {
    setIntegratorFilter(parseFilter(filter));
    setChecked([]);
  }, [filter, setChecked]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: spacing(42),
        marginTop: spacing(1),
        maxWidth: spacing(10)
      }
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  };

  const arrayOfObjectToObject = arrayObject => {
    return arrayObject.reduce((acc, curr) => {
      return { ...acc, ...curr };
    });
  };

  const getKeyByValue = (object, value) =>
    Object.keys(object).find(key => object[key] === value);

  const renderSelectedLabel = integratorRenderValue => {
    return integratorRenderValue
      .map(integratorName =>
        getKeyByValue(arrayOfObjectToObject(integratorsData), integratorName)
      )
      .join(', ');
  };

  /**
   * Object.keys = Integrator Name | Object.values = Integrator ID
   * @returns MenuItem
   */
  const FilterOptions = () => {
    return integratorsData.map(integrator => (
      <MenuItem
        key={Object.values(integrator)[0]}
        value={Object.values(integrator)[0]}
      >
        <Checkbox
          checked={integratorFilter.some(
            integratorId => integratorId === Object.values(integrator)[0]
          )}
          color="primary"
        />
        <ListItemText
          primary={<Typography noWrap>{Object.keys(integrator)[0]}</Typography>}
        />
      </MenuItem>
    ));
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{t('filters.integrator.text')}</InputLabel>
      <Select
        id="filter-integrator-select-id"
        input={<OutlinedInput label={t('filters.integrator.text')} />}
        inputProps={{ 'data-testid': 'filter-integrator-select' }}
        MenuProps={MenuProps}
        labelId="filter-integrator-select-label"
        multiple
        onChange={event => setIntegratorFilter(event.target.value)}
        onClose={() => {
          const newFilter = Object();
          newFilter[FILTERS_NAMES.INTEGRATOR] = integratorFilter;
          onChange(newFilter);
        }}
        renderValue={renderSelectedLabel}
        value={integratorFilter}
      >
        {FilterOptions()}
      </Select>
    </FormControl>
  );
};

FilterIntegrator.propTypes = {
  setChecked: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  integratorsData: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string }))
    .isRequired
};

export default FilterIntegrator;
