import { getUser } from '@loggi/authentication-lib';
import TagManager from 'react-gtm-module';

if (process.env.REACT_APP_GTM_ID) {
  getUser().then(user => {
    const userId = user?.sub;
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
      dataLayer: {
        userId
      }
    };
    return TagManager.initialize(tagManagerArgs);
  });
}
