import { loggiWebApi } from '@loggi/authentication-lib';
import { ACCOUNTS_COMPANIES_ENDPOINT } from '../../../constants';

export const signupCompany = payload =>
  loggiWebApi
    .url(`/${ACCOUNTS_COMPANIES_ENDPOINT}`)
    .post(payload)
    .json();

export const updateCompany = (companyId, payload) =>
  loggiWebApi
    .url(`/${ACCOUNTS_COMPANIES_ENDPOINT}${companyId}/`)
    .patch(payload);

export const validateCompanyData = (sharedName, cnpj) =>
  loggiWebApi
    .url(`/${ACCOUNTS_COMPANIES_ENDPOINT}validate/`)
    .post({ shared_name: sharedName, cnpj })
    .json();
