import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, makeStyles, Chip } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Alert, AlertTitle } from '@material-ui/lab';
import colorMap from '@loggi/components/src/one/status-indicator/color-mapper';
import { CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_ON_DELIVERY_ROUTE } from '@loggi/components/src/one/package-utils/status-consts';
import {
  shouldDisplayActionRequired,
  SHIPPER_CODES_WITH_PROBLEM
} from '@loggi/components/src/one/package-utils/status.helpers';
import { format, toDate } from 'date-fns-tz';
import pt from 'date-fns/locale/pt';
import { useRemoteConfig } from '../../../firebase';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  timelineStyles: {
    margin: 0,
    padding: spacing(0)
  },
  timelineItem: {
    '&:before': {
      display: 'none'
    }
  },
  activeDotTimeline: {
    backgroundColor: ({ color }) => color,
    borderColor: ({ borderColor }) => borderColor,
    border: `${typography.pxToRem(16)} solid`
  },
  timelineDotWithoutBorder: {
    padding: 0,
    backgroundColor: ({ color }) => color,
    width: typography.pxToRem(8),
    height: typography.pxToRem(8)
  },
  inactiveDotTimeline: {
    padding: typography.pxToRem(2)
  },
  timelineContent: {
    padding: 0
  },
  timelineConnector: {
    backgroundColor: ({ color }) => color,
    width: typography.pxToRem(4)
  },
  inactiveAlertError: {
    color: palette.text.secondary
  }
}));

const validPrefix = (value, { prefix = '' }) => (value ? prefix + value : '');

const getLocation = (location = {}) =>
  `${validPrefix(location.city, { prefix: ' • ' })}${validPrefix(
    location.state,
    { prefix: ', ' }
  )}.`;

const PackageHistoryDrawerContent = ({ history }) => {
  const { t } = useTranslation('packages');
  let deliveryAttemptsCount = history.reduce((counter, record) => {
    return (
      counter +
      (record.status.code ===
        CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_ON_DELIVERY_ROUTE)
    );
  }, 0);
  const historyWithAttempts = [];
  history.forEach(record => {
    const newRecord = record;
    if (
      record.status.code ===
      CUSTOM_SHIPPER_PACKAGE_STATUS_CODE_ON_DELIVERY_ROUTE
    ) {
      newRecord.status.deliveryAttempt = deliveryAttemptsCount;
      deliveryAttemptsCount -= 1;
    }
    historyWithAttempts.push(newRecord);
  });
  const currentStatus = historyWithAttempts[0].status;
  const isCurrentStatusDeliveryAttempt = currentStatus.deliveryAttempt > 1;
  const currentStatusDeliveryAttemptLabel =
    currentStatus.deliveryAttempt + t('details.labels.deliveryAttempt');
  const historyStatus = historyWithAttempts.slice(1);
  const historyLength = historyStatus.length - 1;
  const reasonLabel = currentStatus?.actionRequired?.reasonLabel;
  const reasonDescription = currentStatus?.actionRequired?.reasonDescription;
  const location = historyWithAttempts[0]?.location;
  const { value: enablelocationPackage } = useRemoteConfig(
    'enable_location_package'
  );
  const showLocationPackage = enablelocationPackage === 'true';

  const withProblemStatus = SHIPPER_CODES_WITH_PROBLEM().includes(
    currentStatus.code
  );

  const style = useStyles({
    color: colorMap[currentStatus.code].primary,
    borderColor: colorMap[currentStatus.code].secondary
  });

  const dateFormatter = updatedTime => {
    return format(toDate(updatedTime), "d 'de' MMMM, 'às' HH:mm", {
      locale: pt
    }).toLowerCase();
  };

  return (
    <Box data-testid="package-history-drawer-content">
      <Box px={3} py={6}>
        <Typography variant="h4">
          <strong>{t('details.labels.trackShipment')}</strong>
        </Typography>
      </Box>
      <Box px={3}>
        <Timeline className={style.timelineStyles}>
          <TimelineItem className={style.timelineItem}>
            <TimelineSeparator>
              <TimelineDot className={style.activeDotTimeline} />
              <TimelineConnector className={style.timelineConnector} />
            </TimelineSeparator>
            <TimelineContent className={style.timelineContent}>
              <Box pl={1} pt={1} display="flex" alignItems="center">
                <Typography variant="h6">
                  <strong>{currentStatus.highLevelStatus}</strong>
                </Typography>
                {isCurrentStatusDeliveryAttempt && (
                  <Box pl={1}>
                    <Chip
                      size="small"
                      label={currentStatusDeliveryAttemptLabel}
                    />
                  </Box>
                )}
              </Box>
            </TimelineContent>
          </TimelineItem>
          <Box pl={2}>
            <TimelineItem className={style.timelineItem}>
              <TimelineSeparator>
                <TimelineDot className={style.timelineDotWithoutBorder} />
                {history.length > 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent className={style.timelineContent}>
                <Box pl={3} pb={4} alignItems="center">
                  <Box>
                    <Typography variant="body1">
                      {currentStatus.description}
                    </Typography>
                  </Box>
                  <Box pt={1}>
                    <Typography variant="body1">
                      {dateFormatter(currentStatus.updatedTime)}
                      {showLocationPackage && getLocation(location)}
                    </Typography>
                  </Box>
                  {shouldDisplayActionRequired() &&
                    currentStatus.actionRequired && (
                      <Box mt={4}>
                        <Alert
                          className={style.activeAlertError}
                          severity={withProblemStatus ? 'error' : 'success'}
                          color={withProblemStatus ? 'error' : 'success'}
                        >
                          <AlertTitle>{reasonLabel}</AlertTitle>
                          {reasonDescription}
                        </Alert>
                      </Box>
                    )}
                </Box>
              </TimelineContent>
            </TimelineItem>
          </Box>
          {historyStatus.map((historyRecord, i) => {
            const isLastRecord = historyLength === i;
            const problemStatus = SHIPPER_CODES_WITH_PROBLEM().includes(
              historyRecord.status.code
            );
            const isDeliveryAttempt = historyRecord.status.deliveryAttempt > 1;
            const labelAttempt =
              historyRecord.status.deliveryAttempt +
              t('details.labels.deliveryAttempt');
            const historyRecordLocation = historyRecord?.location;
            return (
              <Box pl={2}>
                <TimelineItem className={style.timelineItem}>
                  <TimelineSeparator>
                    <TimelineDot className={style.inactiveDotTimeline} />
                    {!isLastRecord && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent className={style.timelineContent}>
                    <Box pl={3} pb={4}>
                      <Box color="text.secondary" display="flex">
                        <Typography variant="body1">
                          <strong>
                            {historyRecord.status.highLevelStatus}
                          </strong>
                        </Typography>
                        {isDeliveryAttempt && (
                          <Box pl={1}>
                            <Chip size="small" label={labelAttempt} disabled />
                          </Box>
                        )}
                      </Box>
                      <Box mt={1} color="text.secondary">
                        <Typography variant="body1">
                          {historyRecord.status.description}
                        </Typography>
                      </Box>
                      <Box mt={1} color="text.secondary">
                        <Typography variant="body1">
                          {dateFormatter(historyRecord.status.updatedTime)}
                          {showLocationPackage &&
                            getLocation(historyRecordLocation)}
                        </Typography>
                      </Box>
                      {historyRecord.status.actionRequired &&
                        shouldDisplayActionRequired() && (
                          <Box mt={4} className={style.margin}>
                            <Alert
                              className={style.inactiveAlertError}
                              severity={problemStatus ? 'error' : 'success'}
                              color="info"
                            >
                              <AlertTitle>
                                {
                                  historyRecord.status.actionRequired
                                    .reasonLabel
                                }
                              </AlertTitle>
                              {
                                historyRecord.status.actionRequired
                                  .reasonDescription
                              }
                            </Alert>
                          </Box>
                        )}
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              </Box>
            );
          })}
        </Timeline>
      </Box>
    </Box>
  );
};

PackageHistoryDrawerContent.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.shape({
        code: PropTypes.string,
        highLevelStatus: PropTypes.string,
        description: PropTypes.string,
        updatedTime: PropTypes.string,
        actionRequired: PropTypes.shape({
          reasonLabel: PropTypes.string,
          reasonDescription: PropTypes.string
        })
      })
    })
  ).isRequired
};

export default PackageHistoryDrawerContent;
