import { Box, Typography, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import React from 'react';

const useStyles = makeStyles(({ typography }) => ({
  skeleton: {
    borderRadius: typography.pxToRem(4)
  },
  title: {
    width: typography.pxToRem(205)
  }
}));

const AddressAliasSectionSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Box
        className={classes.title}
        pb={4}
        data-testid="alias-section-skeleton"
      >
        <Typography variant="h6">
          <Skeleton className={classes.skeleton} />
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ sm: 'center' }}
      >
        <Box flex="1" pr={{ sm: 5 }}>
          <Skeleton className={classes.skeleton} variant="rect" height={52} />
        </Box>
        <Box flex="2" pt={{ xs: 4, sm: 0 }}>
          <Skeleton className={classes.skeleton} variant="rect" height={70} />
        </Box>
      </Box>
    </>
  );
};

export default AddressAliasSectionSkeleton;
