import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  OneTemplate,
  OneTemplateSummary,
  OneTemplateContent
} from '@loggi/components';
import { CircularProgress, Box } from '@material-ui/core';
import PickupOrderScheduleInfo from './header/pickup-order-schedule-info.component';
import pickupOrderSchedulePackagesApi from '../../operations/pickup-order-schedule-packages-api';
import PackagesList from './packages-list/packages-list.component';
import useAsyncError from '../use-async-error/use-async-error';

const PickupOrderShedulePackages = () => {
  const throwError = useAsyncError();

  const { pickupOrderScheduleUUID } = useParams();
  const [
    pickupOrderSchedulePackages,
    setPickupOrderSchedulePackages
  ] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    pickupOrderSchedulePackagesApi
      .get(pickupOrderScheduleUUID)
      .json(response => {
        setPickupOrderSchedulePackages(response);
      })
      .catch(error => {
        throwError(new Error(error));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pickupOrderScheduleUUID, throwError]);

  const loadingComponent = () => (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CircularProgress justify="center" />
    </Box>
  );

  return (
    <>
      <OneTemplate>
        <OneTemplateSummary>
          {loading && loadingComponent()}
          {pickupOrderSchedulePackages && (
            <PickupOrderScheduleInfo
              packagesCount={pickupOrderSchedulePackages.packages_count}
              pickupOrderScheduleStartTime={
                pickupOrderSchedulePackages.pickup_order_schedule_start_time
              }
              pickupOrderScheduleEndTime={
                pickupOrderSchedulePackages.pickup_order_schedule_end_time
              }
              pickupOrderScheduleAddress={
                pickupOrderSchedulePackages.pickup_order_schedule_address
              }
              pickupOrderScheduleId={
                pickupOrderSchedulePackages.pickup_order_schedule_id
              }
            />
          )}
        </OneTemplateSummary>
        <OneTemplateContent style={{ padding: 0 }}>
          {pickupOrderSchedulePackages && (
            <PackagesList packages={pickupOrderSchedulePackages.packages} />
          )}
        </OneTemplateContent>
      </OneTemplate>
    </>
  );
};

export default PickupOrderShedulePackages;
