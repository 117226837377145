import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Toast from '@loggi/components/src/one/toast';
import { useSnackbar } from 'notistack';

import packagesRoutes from '../../packages-routes';

import packagesApi from '../../packages-api';
import {
  getToastParameters,
  getErrorToastMessage
} from '../../details/cancel-package-dialog/helpers/cancel-package.helpers';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  actionCol: {
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse'
    }
  },
  closeButton: {
    marginTop: spacing(1),
    [breakpoints.up('sm')]: {
      marginTop: spacing(0),
      marginRight: spacing(1.5)
    }
  }
}));

/**
 * PackageRecipientEditionModalContent's responsibility is to show a model content to confirm
 * a package recipient edition intention, with the params it receives.
 * @componentType: Presentational
 * @param companyId
 * @param trackingKey
 * @param handleClose
 * @param oldPhone
 * @param address
 * @param complement
 * @param placeId
 * @param recipientName
 * @param recipientPhone
 * @param isPackageStatusAdded
 * @returns {*}
 */
const PackageRecipientEditionModalContent = ({
  companyId,
  trackingKey,
  handleClose,
  oldPhone,
  address,
  complement,
  placeId,
  recipientName,
  recipientPhone,
  isPackageStatusAdded,
  enablePackageRecipientEdition
}) => {
  const { t } = useTranslation('packages');
  const validPhone = recipientPhone || oldPhone;

  const primarySubtitle = t('edition.action.update.primarySubtitle', {
    recipientName,
    address
  });

  const primarySubtitleWithPhone = t(
    'edition.action.update.primarySubtitleWithPhone',
    {
      recipientName,
      validPhone,
      address
    }
  );

  let selectedSubtitle = validPhone
    ? primarySubtitleWithPhone
    : primarySubtitle;

  if (!enablePackageRecipientEdition) {
    selectedSubtitle = t('edition.action.update.defaultSubtitle');
  }

  const initialValues = {
    title: t('edition.action.update.title'),
    subtitle: selectedSubtitle,
    closeButtonText: t('edition.action.update.closeButton'),
    confirmationButtonText: t('edition.action.update.confirmationButton'),
    link: undefined
  };

  const [loading, setLoading] = useState(false);
  const [dialogContent, setDialogContent] = useState(initialValues);

  const styles = useStyles();

  const { push } = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const redirectToPackageDetails = () =>
    push(packagesRoutes.show.url(companyId, trackingKey));

  const handleCancelPackageRequest = async () => {
    if (!dialogContent.link) {
      handleClose();
    } else {
      setLoading(true);

      packagesApi
        .cancel(companyId, trackingKey)
        .then(response => {
          setLoading(false);

          const { message: toastMessage, type } = getToastParameters(
            response.warning_message
          );

          enqueueSnackbar(t(toastMessage), {
            content: (key, message) => (
              <Toast id={key} message={message} type={type} />
            )
          });

          handleClose();
          redirectToPackageDetails();
        })
        .catch(error => {
          setLoading(false);

          const errorToastMessage = getErrorToastMessage(error);

          enqueueSnackbar(t(errorToastMessage), {
            content: (key, message) => (
              <Toast id={key} message={message} type="error" />
            )
          });
          handleClose();
        });
    }
  };

  const handleEditPackageRequest = async () => {
    if (dialogContent.link) {
      push(dialogContent.link);
    } else {
      setLoading(true);

      const payload = {
        address,
        complement,
        placeId,
        recipientName,
        recipientPhone
      };

      packagesApi
        .update(companyId, trackingKey, payload)
        .then(() => {
          setLoading(false);
          handleClose();
          enqueueSnackbar(t('edition.success.toastMessage'), {
            content: (key, message) => (
              <Toast id={key} message={message} type="success" />
            )
          });
          let url = packagesRoutes.show.url(companyId, trackingKey);
          if (isPackageStatusAdded) {
            url = packagesRoutes.editSuccess.url(companyId, trackingKey);
          }
          push(url);
        })
        .catch(error => {
          setLoading(false);
          let toastMessage;
          if (error.response && error.response.status === 400) {
            const errorMessage = JSON.parse(error.message);
            const errorType = errorMessage ? errorMessage.type : '';
            switch (errorType) {
              case 'UpdatePackageCorreiosCustodyException':
                toastMessage = t('edition.error.toastMessage.correiosError');
                break;
              case 'UpdatePackageDisabledException':
                toastMessage = t('edition.error.toastMessage.requestError');
                break;
              case 'UpdatePackageEnRouteException':
                toastMessage = t('edition.error.toastMessage.enRouteError');
                break;
              case 'UpdatePackageInReturnDirectionException':
                toastMessage = t(
                  'edition.error.toastMessage.returnDirectionError'
                );
                break;
              case 'UpdatePackageFinalizerStatusException':
                toastMessage = t('edition.error.toastMessage.defaultError');
                break;
              case 'UpdatePackageFreightNotAvailableException':
                setDialogContent({
                  title: t('edition.error.freightError.title'),
                  subtitle: t('edition.error.freightError.subtitle'),
                  confirmationButtonText: t(
                    'edition.error.freightError.confirmationButton'
                  ),
                  closeButtonText: t('edition.error.freightError.closeButton'),
                  link: packagesRoutes.show.url(companyId, trackingKey)
                });

                break;
              case 'UpdatePackageIsNotFiscallyAllowedException':
                setDialogContent({
                  title: t('edition.error.fiscalErrorDialog.title'),
                  subtitle: t('edition.error.fiscalErrorDialog.subtitle'),
                  confirmationButtonText: t(
                    'edition.error.fiscalErrorDialog.confirmationButton'
                  ),
                  closeButtonText: t(
                    'edition.error.fiscalErrorDialog.closeButton'
                  ),
                  link: packagesRoutes.show.url(companyId, trackingKey)
                });

                break;
              default:
                toastMessage = t('edition.error.toastMessage.defaultError');
            }
          } else {
            toastMessage = t('edition.error.toastMessage.requestError');
          }
          if (toastMessage) {
            handleClose();

            enqueueSnackbar(toastMessage, {
              content: (key, message) => (
                <Toast id={key} message={message} type="error" />
              )
            });
          }
        });
    }
  };

  return (
    <Box p={{ xs: 3, sm: 5 }}>
      <Box pb={2}>
        <Typography variant="h6">
          <strong>{dialogContent.title}</strong>
        </Typography>
      </Box>
      <Box pb={4}>
        <Typography variant="subtitle2">{dialogContent.subtitle}</Typography>
      </Box>
      <Box
        className={styles.actionCol}
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          data-testid="close-btn"
          className={styles.closeButton}
          onClick={handleCancelPackageRequest}
          disabled={loading}
          color="primary"
          size="large"
        >
          {dialogContent.closeButtonText}
        </Button>
        <Button
          data-testid="edit-confirmation-btn"
          onClick={handleEditPackageRequest}
          disabled={loading}
          size="large"
          variant="contained"
          color="primary"
          autoFocus
        >
          {dialogContent.confirmationButtonText}
        </Button>
      </Box>
    </Box>
  );
};

PackageRecipientEditionModalContent.propTypes = {
  companyId: PropTypes.string.isRequired,
  trackingKey: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  oldPhone: PropTypes.string,
  address: PropTypes.string.isRequired,
  complement: PropTypes.string.isRequired,
  placeId: PropTypes.string.isRequired,
  recipientName: PropTypes.string.isRequired,
  recipientPhone: PropTypes.string.isRequired,
  isPackageStatusAdded: PropTypes.bool.isRequired,
  enablePackageRecipientEdition: PropTypes.bool.isRequired
};

PackageRecipientEditionModalContent.defaultProps = {
  oldPhone: ''
};

export default PackageRecipientEditionModalContent;
