import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Auth from '../screens/auth';
import PickupReceiptFeedback from '../screens/pickup-receipt-feedback';
import PickupReceipts from '../screens/pickup-receipts';
import AppErrorBoundary from './receipt-error-boundary.component';
import {
  PICKUP_RECEIPT_AUTH_ROUTE,
  PICKUP_RECEIPT_ROUTE,
  PICKUP_RECEIPT_FEEDBACK
} from './constants';

const Routes = () => {
  return (
    <AppErrorBoundary>
      <Switch>
        <Route
          exact
          path={PICKUP_RECEIPT_FEEDBACK.path}
          component={PickupReceiptFeedback}
        />
        <Route exact path={PICKUP_RECEIPT_AUTH_ROUTE.path} component={Auth} />
        <Route path={PICKUP_RECEIPT_ROUTE.path} component={PickupReceipts} />
      </Switch>
    </AppErrorBoundary>
  );
};

export default Routes;
