import { colors } from '@loggi/mar';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import packagesRoutes from '../../packages-routes';
import UploadDropzone from './upload-dropzone';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    alertMessage: {
      backgroundColor: colors.smoke[50],
      border: `1px solid ${colors.smoke[300]}`,
      color: palette.info.dark,
      fontSize: typography.body2.fontSize,
      [breakpoints.down('sm')]: {
        alignItems: 'start',
        '& .MuiAlert-icon': {
          paddingTop: spacing(1.15)
        }
      }
    },
    subtitleIcon: {
      color: palette.info.dark
    }
  })
);

const ImportInvoice = () => {
  const { t } = useTranslation('packages');
  const styles = useStyles();
  const { companyId } = useParams();

  return (
    <>
      <Box data-testid="upload-spreadsheet">
        <Box>
          <Typography>
            <Box component="strong" fontWeight="fontWeightBold">
              {t('create.invoiceImport.importTitle')}
            </Box>
          </Typography>
          <Typography color="textSecondary">
            {t('create.invoiceImport.importDescription')}
          </Typography>
        </Box>
        <Box py={3}>
          <Alert
            className={styles.alertMessage}
            icon={<Error className={styles.subtitleIcon} fontSize="small" />}
            severity="error"
          >
            <AlertTitle>{t('create.invoiceImport.alertTitle')}</AlertTitle>
            {t('create.invoiceImport.alertDescription')}
          </Alert>
        </Box>
        <Box mb={3}>
          <UploadDropzone />
        </Box>
        <Box>
          <Typography gutterBottom>
            <Box component="strong" fontWeight="fontWeightBold">
              {t('create.invoiceImport.howTitle')}
            </Box>
          </Typography>
          <Typography gutterBottom>
            {t('create.invoiceImport.howDescription')}
          </Typography>
          <Box
            color={colors.blue[500]}
            fontSize={16}
            fontWeight="fontWeightBold"
          >
            <ButtonBase
              component={Link}
              to={packagesRoutes.importInvoiceInstructions.url(companyId)}
            >
              {t('create.invoiceImport.howLink')}
            </ButtonBase>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ImportInvoice;
