import Management from '@loggi/management';
import Packages from '@loggi/packages';
import Payments from '@loggi/payments';
import Pickups from '@loggi/pickups';
import Tracking from '@loggi/tracking';
import UsersManagement from '@loggi/users-management';
import {
  accessItems,
  defaultRoutes
} from '@loggi/components/src/one/permissions';
import { MENU_ITEMS } from '../components/header.constants';
import {
  MANAGEMENT,
  PICKUPS_ROUTE,
  USERS_MANAGEMENT,
  RECURRING_PICKUP_UPDATE,
  OUT_OF_COVERAGE
} from './constants';
import WaitingContact from '../signup-company/steps/waiting-contact';

const getOneRoutes = userRole => [
  {
    id: 'management',
    path: MANAGEMENT.path,
    Component: Management,
    visible: accessItems.management.includes(userRole),
    defaultRouteRoles: []
  },
  {
    id: 'out-of-coverage',
    path: OUT_OF_COVERAGE.path,
    Component: WaitingContact,
    visible: accessItems.tracking.includes(userRole),
    defaultRouteRoles: []
  },
  {
    id: 'packages',
    path: MENU_ITEMS.PACKAGES.path,
    Component: Packages,
    visible: accessItems.packages.includes(userRole),
    defaultRouteRoles: defaultRoutes.packages
  },
  {
    id: 'payments',
    path: MENU_ITEMS.PAYMENTS.path,
    Component: Payments,
    visible: accessItems.finance.includes(userRole),
    defaultRouteRoles: defaultRoutes.finance
  },
  {
    id: 'pickups',
    path: PICKUPS_ROUTE.path,
    Component: Pickups,
    visible: accessItems.tracking.includes(userRole),
    defaultRouteRoles: []
  },
  {
    id: 'edit-recurring-pickup',
    path: RECURRING_PICKUP_UPDATE.path,
    Component: Pickups,
    visible: accessItems.tracking.includes(userRole),
    defaultRouteRoles: []
  },
  {
    id: 'tracking',
    path: MENU_ITEMS.TRACKING.path,
    Component: Tracking,
    visible: accessItems.tracking.includes(userRole),
    defaultRouteRoles: []
  },
  {
    id: 'usersManagement',
    path: USERS_MANAGEMENT.path,
    Component: UsersManagement,
    visible: accessItems.usersManagement.includes(userRole),
    defaultRouteRoles: []
  }
];

export default getOneRoutes;
