import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import SummaryDateItem from './summary-date-item.component';

/**
 * PackageReturnedDate's only responsibility is to show the returned date info.
 * @componentType: Presentational
 * @param date
 * @returns {*}
 */
const PackageReturnedDate = ({ date }) => {
  const { t } = useTranslation('packages');

  return (
    <SummaryDateItem
      date={date}
      label={t('details.labels.returnedAt')}
      dataTestId="returned-date-info"
    />
  );
};

PackageReturnedDate.propTypes = {
  date: PropTypes.string.isRequired
};

export default PackageReturnedDate;
