import { useDownloadPackageLabelsCallback } from '@loggi/components/src/one/hooks';
import React, { useMemo } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const PackagesBulkDownloadLabelsButton = ({
  companyId,
  trackingKeysSelected,
  hasMixedCompanyIds,
  handleBulkActionAlert
}) => {
  const { t } = useTranslation('packages');
  const timestamp = useMemo(() => {
    const currentDate = new Date();
    return format(currentDate, 'yyyy-MM-dd-hh[h]mm[m]');
  }, []);

  const fileName = !trackingKeysSelected.length
    ? null
    : t('labelsFileName', {
        trackingKey: trackingKeysSelected[0],
        count: trackingKeysSelected.length,
        timestamp
      });

  const {
    loading,
    triggerMultipleDownloadLabels
  } = useDownloadPackageLabelsCallback(
    companyId,
    trackingKeysSelected,
    fileName
  );

  const handleButtonClick = () =>
    hasMixedCompanyIds
      ? handleBulkActionAlert(true)
      : triggerMultipleDownloadLabels();

  return (
    <>
      <Box mb={{ xs: 2, sm: 0 }} pr={{ xs: 1, md: 2 }}>
        <Button
          disabled={loading}
          variant="outlined"
          size="small"
          bordercolor="primary"
          color="primary"
          backgroundcolor="common"
          fullWidth
          data-testid="bulkDownloadLabelButton"
          onClick={handleButtonClick}
        >
          {loading ? (
            <CircularProgress color="inherit" size={20} data-testid="loading" />
          ) : (
            t('list.bulkDownloadLabel.activeButton', {
              count: trackingKeysSelected.length
            })
          )}
        </Button>
      </Box>
    </>
  );
};

PackagesBulkDownloadLabelsButton.propTypes = {
  companyId: PropTypes.string,
  trackingKeysSelected: PropTypes.arrayOf(PropTypes.string),
  hasMixedCompanyIds: PropTypes.bool.isRequired,
  handleBulkActionAlert: PropTypes.func.isRequired
};

PackagesBulkDownloadLabelsButton.defaultProps = {
  companyId: '',
  trackingKeysSelected: []
};

export default PackagesBulkDownloadLabelsButton;
