import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Box,
  ButtonBase,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { useAmplifyAuth } from '@loggi/authentication-lib';

import { useFeature } from '../../../../apps/packages/src/firebase';
import HelpDrawerPackageSearch from './help-drawer-package-search/help-drawer-package-search';
import { redirectToHelpCenter } from '../utils';

const useStyles = makeStyles(({ spacing, palette }) => ({
  buttonBase: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: palette.primary.light,
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    paddingTop: spacing(2.5),
    paddingBottom: spacing(2.5),
    '&:hover': {
      backgroundColor: palette.secondary.light,
      cursor: 'pointer'
    }
  }
}));

const HelpDrawerContent = ({
  handleClose,
  handleOpenMovementDetails,
  companyId
}) => {
  const { t } = useTranslation('packages');
  const useZendeskUrl = useFeature('enable_redirect_to_zendesk_help_center');
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [openPackageSearch, setOpenPackageSearch] = useState(false);

  const handleOpenPackageSearch = () => {
    setOpenPackageSearch(true);
  };
  const handleClickDrawer =
    handleOpenMovementDetails ?? handleOpenPackageSearch;

  if (openPackageSearch) {
    return (
      <HelpDrawerPackageSearch
        handleClose={handleClose}
        companyId={companyId}
      />
    );
  }

  /* eslint no-void: ["error", { "allowAsStatement": true }] */

  return (
    <>
      <Box display="flex" alignItems="center" py={4} px={3}>
        <Typography variant="h4">
          <strong>{t('details.helpDrawer.title')}</strong>
        </Typography>
      </Box>
      <Divider />
      <ButtonBase className={classes.buttonBase} onClick={handleClickDrawer}>
        <Typography variant="subtitle2" color="textPrimary">
          {t('details.helpDrawer.delayedPackage')}
        </Typography>
        <ArrowForwardIosIcon color="primary" />
      </ButtonBase>
      <Divider />
      <ButtonBase className={classes.buttonBase} onClick={handleClickDrawer}>
        <Typography variant="subtitle2" color="textPrimary">
          {t('details.helpDrawer.motionlessPackage')}
        </Typography>
        <ArrowForwardIosIcon color="primary" />
      </ButtonBase>
      <Divider />
      <ButtonBase
        className={classes.buttonBase}
        disabled={loading}
        onClick={() => {
          void (async () => {
            setLoading(true);
            await redirectToHelpCenter(
              companyId,
              authenticatedUser,
              useZendeskUrl
            );
            setLoading(false);
          })();
        }}
      >
        <Typography variant="subtitle2" color="textPrimary">
          {t('details.helpDrawer.redirectToHelpCenter')}
        </Typography>
        <ArrowForwardIosIcon color="primary" />
      </ButtonBase>
      <Divider />
    </>
  );
};

HelpDrawerContent.propTypes = {
  handleOpenMovementDetails: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired
};

HelpDrawerContent.defaultProps = {
  handleOpenMovementDetails: null
};

export default HelpDrawerContent;
