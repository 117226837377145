import { TRACKING_ROUTE_INITIAL_STATE } from '@loggi/components/src/one/constants';

// eslint-disable-next-line import/prefer-default-export
export const MENU_ITEMS = {
  PACKAGES: {
    label: 'header.menu.packages',
    path: '/empresas/:companyId/pacotes',
    route: companyId => `/empresas/${companyId}/pacotes`
  },
  PAYMENTS: {
    label: 'header.menu.payments',
    path: '/empresas/:companyId/financeiro',
    route: companyId => `/empresas/${companyId}/financeiro`
  },
  TRACKING: {
    label: 'header.menu.scheduling',
    path: '/empresas/:companyId/coletas',

    // The value of this constant TRACKING_ROUTE_INITIAL_STATE is something
    // like this '/coletas?status=waiting_pickup'.
    route: companyId => `/empresas/${companyId}${TRACKING_ROUTE_INITIAL_STATE}`
  },
  TRACKING_ROUTE_INITIAL_PATH: {
    path:
      '/empresas/:companyId/coletas?status=waiting_pickup,waiting_volume,waiting_confirmation',
    route: companyId => `/empresas/${companyId}${TRACKING_ROUTE_INITIAL_STATE}`
  }
};
