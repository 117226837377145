export const NOTIFICATION_SCOPE = {
  SUPPORTED: 'supported',
  IOS_SUPPORTED: 'ios-supported',
  NOT_SUPPORTED: 'not-supported'
};

export const LOADING_STATUS = {
  DONE: 'done',
  LOADING: 'loading'
};

export default {
  LOADING_STATUS,
  NOTIFICATION_SCOPE
};
