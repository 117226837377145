/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import {
  MeasurementsField,
  TokenField
} from '@loggi/components/src/one/formik-fields';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import palette from '@loggi/mar/src/palette';
import { useSnackbar } from 'notistack';
import Toast from '@loggi/components/src/one/toast';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Company from '@loggi/components/src/one/hooks/Company';
import { codeIntegratorStyles } from '../../styles';
import integratorListApi from '../../services/integrator-api';
import { SectionTitle, SubSectionTitle } from '../titles.component';

const WakeCodeIntegrator = ({ integrator }) => {
  const { t } = useTranslation('management');
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const styles = codeIntegratorStyles();
  const { companyId } = useParams();
  const [savedFormValues, setSavedFormValues] = useState({});
  const [code, setCode] = useState();
  const [integratorId, setIntegratorId] = useState();
  const [configExists, setConfigExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const integratorName = t(`${integrator}.name`);
  const schedulingUrl = `/empresas/${companyId}/coletas/adicionar`;
  const companyData = Company.getInstance().getCompanyData();

  const tokenInstructions = t('wake.token.instructions', {
    returnObjects: true
  });

  const handleSnackbarError = useCallback(
    error => {
      enqueueSnackbar(error.message, {
        content: (key, message) => (
          <Toast id={key} message={message} type="error" />
        )
      });
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    setLoading(true);
    integratorListApi
      .integratorsList(companyId)
      .then(response => {
        const integratorCompany = response
          .filter(integration => integration.integrator.name === integratorName)
          .map(integration => {
            setCode(integration.id);
            setIntegratorId(integration.integrator.accessKey);
            return integration;
          });
        if (integratorCompany.length === 0) {
          integratorListApi
            .integratorCode(companyId, { integrator_name: integratorName })
            .then(response)
            .catch(error => handleSnackbarError(error));
        }
        setLoading(false);
      })
      .catch(error => handleSnackbarError(error));
    setLoading(false);
  }, [companyId, integratorName, handleSnackbarError]);

  useEffect(() => {
    if (code) {
      setLoading(true);
      integratorListApi
        .getScheduleConfig('WAKE', code)
        .then(response => {
          setSavedFormValues({
            tokenwake: response.authentication.apiKey,
            weight: response.metadata.weightGDefault,
            length: response.metadata.lengthCmDefault,
            width: response.metadata.widthCmDefault,
            height: response.metadata.heightCmDefault
          });
          setConfigExists(true);
        })
        .catch(() => {
          setSavedFormValues({});
        });
      setLoading(false);
    }
  }, [code]);

  const CreatePayload = values => ({
    companyId,
    integrator: integratorId,
    externalId: code,
    endpoint: 'https://api.fbits.net/',
    maxPackagesPerFetch: '50',
    timeZoneId: 'GMT-3',
    metadata: {
      weightGDefault: values.weight,
      lengthCmDefault: values.length,
      widthCmDefault: values.width,
      heightCmDefault: values.height,
      federalTaxId: companyData.cnpj,
      integrationCode: code
    },
    authentication: {
      apiKey: values.tokenwake
    }
  });

  const saveValues = formValues => {
    const handleResponse = () => {
      setSavedFormValues(formValues);
      enqueueSnackbar(t('integrations.successMessages.goToPickupConfig'), {
        content: (key, message) => (
          <Toast id={key} message={message} type="success" />
        )
      });
      push(schedulingUrl);
    };

    const handleError = error => {
      enqueueSnackbar(error.message, {
        content: (key, message) => (
          <Toast id={key} message={message} type="error" />
        )
      });
    };

    const handleFinally = () => {
      setLoading(false);
    };

    setLoading(true);
    if (configExists) {
      integratorListApi
        .updateScheduleConfig('WAKE', CreatePayload(formValues), code)
        .then(handleResponse)
        .catch(handleError)
        .finally(handleFinally);
    } else {
      integratorListApi
        .createScheduleConfig('WAKE', CreatePayload(formValues))
        .then(handleResponse)
        .catch(handleError)
        .finally(handleFinally);
    }
  };

  return (
    <Formik
      initialValues={savedFormValues}
      onSubmit={saveValues}
      enableReinitialize
      validateOnMount
    >
      {({ isValid }) => {
        return (
          <Form data-testid="wake-form">
            <Box>
              <Box mb={1}>
                <SectionTitle>{t('wake.configTitle')}</SectionTitle>
                <SubSectionTitle>{t('wake.token.title')}</SubSectionTitle>
                <Typography variant="subtitle2">
                  <Box mt={2}>
                    {tokenInstructions[0]}
                    <strong>{tokenInstructions[1]}</strong>
                  </Box>
                  <Box>
                    {tokenInstructions[2]}
                    <Link
                      href={t('wake.link')}
                      className={styles.integratorLink}
                    >
                      {tokenInstructions[3]}
                    </Link>
                  </Box>
                  <Box mt={3} mb={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TokenField
                          fieldName="tokenwake"
                          minValue={10}
                          maxValue={100}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Typography>
              </Box>
              <Divider />
              <Box mt={3}>
                <SubSectionTitle>
                  {t('wake.measurements.title')}
                </SubSectionTitle>
                <Typography variant="subtitle2">
                  {t('wake.measurements.instructions')}
                </Typography>
                <Box mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                      <MeasurementsField
                        fieldName="height"
                        minValue={1}
                        maxValue={100}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <MeasurementsField
                        fieldName="width"
                        minValue={1}
                        maxValue={100}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <MeasurementsField
                        fieldName="length"
                        minValue={1}
                        maxValue={100}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <MeasurementsField
                        fieldName="weight"
                        minValue={1}
                        maxValue={30000}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                mt={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} md={6}>
                  <Button
                    color="primary"
                    disabled={!isValid || loading}
                    data-testid="wakeForm-saveButton"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {!loading && t('wake.saveButtonText')}
                    {loading && (
                      <CircularProgress
                        style={{
                          color: palette.common.white,
                          left: '50%',
                          marginLeft: -12,
                          marginTop: -12,
                          position: 'absolute',
                          top: '50%'
                        }}
                        data-testid="loading"
                        size={24}
                      />
                    )}
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

WakeCodeIntegrator.propTypes = {
  integrator: PropTypes.string
};

WakeCodeIntegrator.defaultProps = {
  integrator: 'wake'
};

export default WakeCodeIntegrator;
