import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory, useParams } from 'react-router-dom';

import inviteUser from '../service';
import sendNotificationMessage from '../components/send-notification-message';
import errorHandler from '../service/error-handler';
import { ERROR_CODES } from '../constants';
import {
  COMPANIES_ROUTE,
  USERS_MANAGEMENT_PATH
} from '../../users-management.constants';
import { USER_STATUS } from '../../list-users/constants';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  actionCol: {
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse'
    }
  },
  closeButton: {
    marginTop: spacing(1),
    [breakpoints.up('sm')]: {
      marginTop: spacing(0),
      marginRight: spacing(1.5)
    }
  }
}));

/**
 * UpdateUserDialog's responsibility is to show a dialog to confirm
 * a update user intention, with the params it receives.
 * @componentType: Presentational
 * @param companyId
 * @param handleClose
 * @param open
 * @returns {*}
 */
const UpdateUserDialog = ({
  handleClose,
  email,
  name,
  open,
  updateUsersListCallback,
  currentRole,
  newRole,
  values,
  userStatus
}) => {
  const [dialogLoading, setDialogLoading] = useState(false);
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('updateUser');
  const { companyId } = useParams();
  const { push } = useHistory();

  let successTranslation = 'success';
  if (
    userStatus === USER_STATUS.expired ||
    userStatus === USER_STATUS.pending
  ) {
    successTranslation = 'successEmailSent';
  }

  const handleInviteErrorMessage = error => {
    let parsedError;
    let errorMessage = t('notificationMessages.errors.default');
    try {
      parsedError = JSON.parse(error.message);
    } catch (e) {
      errorHandler(error);
      return errorMessage;
    }
    if (parsedError?.code === ERROR_CODES.ALREADY_INVITED)
      errorMessage = t('notificationMessages.errors.alreadyExists');
    else {
      errorHandler(error);
    }
    return errorMessage;
  };

  const handleUpdateUserRequest = async () => {
    setDialogLoading(true);
    inviteUser({ email, full_name: name, ...values }, companyId)
      .then(() => {
        sendNotificationMessage(
          t(`notificationMessages.${successTranslation}`),
          'success',
          enqueueSnackbar
        );
        setDialogLoading(false);
        updateUsersListCallback();
        handleClose();
        push(`${COMPANIES_ROUTE}/${companyId}${USERS_MANAGEMENT_PATH}`);
      })
      .catch(error => {
        if (
          error.message.includes('UserAlreadyExists') ||
          error.message.includes(ERROR_CODES.ALREADY_INVITED)
        ) {
          sendNotificationMessage(
            t(`notificationMessages.${successTranslation}`),
            'success',
            enqueueSnackbar
          );
          setDialogLoading(false);
          updateUsersListCallback();
          handleClose();
          push(`${COMPANIES_ROUTE}/${companyId}${USERS_MANAGEMENT_PATH}`);
        } else {
          sendNotificationMessage(
            handleInviteErrorMessage(error),
            'error',
            enqueueSnackbar
          );
          setDialogLoading(false);
          handleClose();
        }
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box p={{ xs: 3, sm: 5 }}>
        <Box pb={3}>
          <Typography data-testid="dialog-header-id" variant="h5">
            <strong>{t('dialog.header')}</strong>
          </Typography>
        </Box>
        <Box pb={3}>
          <Typography variant="body1">
            {t(`changeMessages.${currentRole}.${newRole}`, {
              name,
              currentRole,
              newRole
            })}
          </Typography>
        </Box>
        <Box
          className={styles.actionCol}
          display="flex"
          justifyContent="center"
        >
          <Button
            data-testid="close-update-btn"
            className={styles.closeButton}
            onClick={handleClose}
            color="primary"
            size="medium"
          >
            {t('dialog.actions.keep')}
          </Button>
          <Button
            data-testid="update-btn"
            onClick={handleUpdateUserRequest}
            disabled={dialogLoading}
            size="medium"
            color="secondary"
            autoFocus
          >
            {t('dialog.actions.update')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

UpdateUserDialog.propTypes = {
  handleClose: PropTypes.func,
  name: PropTypes.string.isRequired,
  currentRole: PropTypes.string,
  newRole: PropTypes.string,
  open: PropTypes.bool,
  email: PropTypes.string.isRequired,
  updateUsersListCallback: PropTypes.func,
  values: PropTypes.shape({
    role: PropTypes.string
  }).isRequired,
  userStatus: PropTypes.number.isRequired
};

UpdateUserDialog.defaultProps = {
  updateUsersListCallback: () => {},
  handleClose: () => {},
  open: false,
  currentRole: '',
  newRole: ''
};

export default UpdateUserDialog;
