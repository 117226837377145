import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';
import { differenceInDays, parseISO } from 'date-fns';

import { Box, Button, Hidden, makeStyles, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import PackageDrawerBase from '../package-drawer-base';
import CancelPackageDialog from './cancel-package-dialog';
import packagesRoutes from '../packages-routes';
import PackageEditionModalError from './package-edition-modal-error';
import DetailsSummaryDateSection from './details-summary-date-section/details-summary-date-section.component';
import {
  PACKAGE_DELIVERED_DATE,
  PACKAGE_DELIVERY_PROMISED_DATE,
  PACKAGE_RETURNED_DATE,
  PACKAGE_RETURN_PROMISED_DATE,
  PACKAGE_WAITING_REDISPATCH_PICKUP_DATE
} from './constants';
import PackageOptionsDrawerHandler from './package-options-drawer/package-options-drawer-handler/package-options-drawer-handler';
import { getFormattedDate } from './utils';
import { useRemoteConfig } from '../../firebase';

const useDetailsSummaryStyles = makeStyles(
  ({ palette, spacing, breakpoints }) => ({
    linkBackButton: {
      borderRadius: '100%',
      height: spacing(5),
      minWidth: 0,
      width: spacing(5),
      color: palette.common.white
    },
    packageOptionsButton: {
      [breakpoints.down('xs')]: {
        paddingLeft: spacing(6.5),
        paddingTop: spacing(3),
        width: '100%'
      }
    }
  })
);

/**
 * DetailsSummary's only responsibility is to show the summary info of the
 * packages scheduling details screen, with the params it receives.
 * @componentType: Presentational
 * @param data
 * @returns {*}
 */
const DetailsSummary = ({ data }) => {
  const styles = useDetailsSummaryStyles();

  const { companyId } = useParams();
  const { goBack, push } = useHistory();
  const { t } = useTranslation('packages');

  const urlLocation = window.location.href;
  const isOptionDrawerOpen = urlLocation.includes(
    packagesRoutes.options.url(companyId, data.trackingKey)
  );

  const [open, setOpen] = useState(isOptionDrawerOpen);
  const [openCancelPackageDialog, setOpenCancelPackageDialog] = useState(false);
  const [
    openPackageEditionModalError,
    setOpenPackageEditionModalError
  ] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    push(packagesRoutes.options.url(companyId, data.trackingKey));
  };

  const handleClose = () => {
    setOpen(false);
    if (isOptionDrawerOpen) {
      goBack();
    }
  };

  const handleClickOpenCancelPackageDialog = () => {
    handleClose();
    setOpenCancelPackageDialog(true);
  };

  const handleClickOpenPackageEditionModalError = () => {
    handleClose();
    setOpenPackageEditionModalError(true);
  };

  const handleCloseCancelPackageDialog = () => {
    setOpenCancelPackageDialog(false);
  };

  const handleClosePackageEditionModalError = () => {
    setOpenPackageEditionModalError(false);
  };

  const shouldDisplayDeliveryInformation =
    data.isDelivered() || data.isReturned();

  let displayedDate = shouldDisplayDeliveryInformation
    ? data?.deliveredDate
    : data?.promisedDate;

  let displayedDateComponent;
  if (shouldDisplayDeliveryInformation) {
    displayedDateComponent = data.isPackageDirectionReturn()
      ? PACKAGE_RETURNED_DATE
      : PACKAGE_DELIVERED_DATE;
  } else {
    displayedDateComponent = data.isPackageDirectionReturn()
      ? PACKAGE_RETURN_PROMISED_DATE
      : PACKAGE_DELIVERY_PROMISED_DATE;
    if (data.redispatchPickupDate) {
      displayedDateComponent = PACKAGE_WAITING_REDISPATCH_PICKUP_DATE;
      displayedDate = data.redispatchPickupDate;
    }
  }

  const { value: allowedDaysWihoutMovement } = useRemoteConfig(
    'max_days_without_movement_per_company'
  );

  const maxDaysMap = JSON.parse(allowedDaysWihoutMovement);

  const { value: enableDelayedChipAndToast } = useRemoteConfig(
    'enable_delayed_chip_and_alert'
  );
  const daysWithoutMovement = differenceInDays(
    new Date(),
    parseISO(data.statusUpdatedTime)
  );

  const maxAllowedDays = maxDaysMap[companyId]
    ? maxDaysMap[companyId]
    : maxDaysMap.default;

  const showDelayedAlert =
    enableDelayedChipAndToast &&
    !data.isPackageDirectionReturn() &&
    !data.isFinalStatus() &&
    data.isPackageDelayed() &&
    daysWithoutMovement > maxAllowedDays;

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        flexWrap="wrap"
      >
        <Box flex="5" pr={2} alignItems="center">
          <Box display="flex">
            <Box display="flex" flexWrap="wrap">
              <Button
                className={styles.linkBackButton}
                variant="outlined"
                data-testid="back-btn"
                onClick={() => goBack()}
              >
                <ArrowBack style={{ fontSize: 22 }} />
              </Button>
              <Box ml={2} pb={{ xs: 1 }} flex="2">
                <Typography variant="h4">
                  <strong>{data?.status}</strong>
                </Typography>
                <Typography variant="body1">
                  {data?.statusDescription}
                </Typography>
              </Box>
              <Hidden smUp>
                <Box>
                  {shouldDisplayDeliveryInformation && data.receiverName && (
                    <Box pl={2}>
                      <Typography variant="h6">
                        {t('details.labels.received')}
                        <strong>{data.receiverName}</strong>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Hidden>
              {displayedDate && (
                <DetailsSummaryDateSection
                  date={getFormattedDate(displayedDate)}
                  returnDate={data.returnPromisedDate}
                  displayedDateComponent={displayedDateComponent}
                  isPackageDelayed={showDelayedAlert}
                />
              )}
            </Box>
          </Box>

          <Hidden xsDown>
            <Box>
              {shouldDisplayDeliveryInformation && data.receiverName && (
                <Box pl={6} ml={1}>
                  <Typography variant="h6">
                    {t('details.labels.received')}
                    <strong>{data.receiverName}</strong>
                  </Typography>
                </Box>
              )}
            </Box>
          </Hidden>
        </Box>
        <Box flex="1" pl={2} className={styles.packageOptionsButton}>
          <Button
            size="small"
            variant="outlined"
            onClick={handleClickOpen}
            data-testid="package-options-btn"
          >
            {t('details.actions.packageOptions')}
          </Button>
        </Box>
      </Box>
      <PackageDrawerBase onClose={handleClose} open={open}>
        <PackageOptionsDrawerHandler
          companyId={companyId}
          data={data}
          openCancelPackageDialog={handleClickOpenCancelPackageDialog}
          openPackageEditionModalError={handleClickOpenPackageEditionModalError}
          handleClose={handleClose}
        />
      </PackageDrawerBase>
      <CancelPackageDialog
        companyId={companyId}
        trackingKey={data.trackingKey}
        open={openCancelPackageDialog}
        handleClose={handleCloseCancelPackageDialog}
      />
      <PackageEditionModalError
        open={openPackageEditionModalError}
        handleClose={handleClosePackageEditionModalError}
        status={data.status}
      />
    </>
  );
};

DetailsSummary.propTypes = {
  data: packageProps.isRequired
};

export default DetailsSummary;
