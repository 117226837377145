import { translations as packagesTranslations } from '../packages';

const translations = {
  'pt-BR': {
    ...packagesTranslations['pt-BR']
  },
  en: {
    ...packagesTranslations.en
  }
};

export default translations;
