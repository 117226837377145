import { loggiGenericApi } from '@loggi/authentication-lib';

export const endpoints = {
  deliveryReceipt: (secretId, trackingKey) =>
    `/security/fraud/delivery-receipt-content/${secretId}/${trackingKey}`
};

const fetchLoggiGenericApi = {
  deliveryReceipt: (companyId, trackingKey) => {
    const url = endpoints.deliveryReceipt(companyId, trackingKey);

    return loggiGenericApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .get()
      .json();
  }
};

export default fetchLoggiGenericApi;
