import PropTypes from 'prop-types';

const recurringPickupFormShape = {
  nopickupAddressComplement: PropTypes.bool,
  pickupAddress: PropTypes.shape({
    description: PropTypes.string,
    place_id: PropTypes.string
  }),
  pickupAddressComplement: PropTypes.string,
  pickupDate: PropTypes.object,
  pickupEndTime: PropTypes.object,
  pickupOriginInstructions: PropTypes.string,
  pickupOriginName: PropTypes.string,
  pickupOriginPhone: PropTypes.string,
  pickupStartTime: PropTypes.object,
  pickupWeekdays: PropTypes.array
};

export default recurringPickupFormShape;
