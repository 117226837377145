import { useDownloadPackageLabelsCallback } from '@loggi/components/src/one/hooks';
import { Box, Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import fetchPickupPackages from './fetch-pickup-packages.request';

const DownloadPackagesLabelsButton = ({ handlePackagesVolumes }) => {
  const { t } = useTranslation('details');
  const { companyId, id: pickupId } = useParams();
  const [trackingKeys, setTrackingKeys] = useState([]);
  const [buttonWidth, setButtonWidth] = useState('auto');
  const { state } = useLocation();
  const ref = useRef();

  // Fetch the package tracking keys of a given pickup
  useEffect(() => {
    /**
     * This if is here because a Scheduling created from the pickups app,
     * doesn't really have real packages, only volume. So it would be useless
     * to try to fetch them. Now that we know when hte user comes from that page
     * we can avoid this in this moment
     */
    if (state?.from !== 'new-order-scheduling') {
      fetchPickupPackages(companyId, pickupId).json(({ packages, total }) => {
        handlePackagesVolumes(total);
        setTrackingKeys(packages.map(pkg => pkg.trackingCode));
      });
    }
  }, [companyId, pickupId, state, handlePackagesVolumes]);

  // Set the current button width as a state, so it doesn't shrink when loading
  useEffect(() => setButtonWidth(ref.current.clientWidth), []);

  const fileName = t('packagesLabelsFileName', { trackingKey: pickupId });
  const { triggerDownloadLabels, loading } = useDownloadPackageLabelsCallback(
    companyId,
    trackingKeys,
    fileName
  );
  const disabled = trackingKeys.length === 0 || loading;

  return (
    <Box flexGrow={{ xs: 1, sm: 0 }} clone>
      <Button
        color="primary"
        data-testid="button"
        disabled={disabled}
        onClick={triggerDownloadLabels}
        ref={ref}
        size="medium"
        style={{ width: buttonWidth }}
        variant="contained"
      >
        {loading ? (
          <CircularProgress color="inherit" data-testid="loading" size={20} />
        ) : (
          <span>{t('printAllLabels')}</span>
        )}
      </Button>
    </Box>
  );
};

DownloadPackagesLabelsButton.propTypes = {
  handlePackagesVolumes: PropTypes.func.isRequired
};

export default DownloadPackagesLabelsButton;
