import React from 'react';
import Toast from '@loggi/components/src/one/toast';

const showToast = ({
  message,
  buttonText = '',
  durationMilliseconds = 5000,
  onButtonClick = null,
  enqueueSnackbar,
  type = 'success'
}) => {
  enqueueSnackbar(message, {
    content: (key, msg) => (
      <Toast
        id={key}
        message={msg}
        type={type}
        onButtonClick={onButtonClick}
        buttonText={buttonText}
      />
    ),
    autoHideDuration: durationMilliseconds
  });
};

export default showToast;
