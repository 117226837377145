import { inboundApi, loggiWebApi } from '@loggi/authentication-lib';
import { getI18n } from 'react-i18next';
import * as Sentry from '@sentry/react';

export const endpoint = {
  integratorsList: companyId =>
    `/one/api/integrator/v1/companies/${companyId}/integrator_company/`,
  createScheduleConfig: integratorName =>
    `/api/v1/schedule/${integratorName}/config`,
  getScheduleConfig: (integratorName, externalId) =>
    `/api/v1/schedule/${integratorName}/config/${externalId}`,
  updateScheduleConfig: (integratorName, externalId) =>
    `/api/v1/schedule/${integratorName}/config/${externalId}`
};
export const requestErrors = {
  errors: {
    badRequest: {
      '3': 'integrations.errorsMessages.invalidArgument',
      '5': 'integrations.errorsMessages.notFoundError',
      '7': 'integrations.errorsMessages.permissionDanied'
    },
    generic: 'integrations.errorsMessages.genericError'
  }
};
export class IntegratorListApiError extends Error {
  constructor(...args) {
    super(...args);
    this.name = ' IntegratorListApiError';
  }
}

const integratorListApi = {
  integratorsList: companyId => {
    const t = getI18n().getFixedT(null, 'management');
    const url = endpoint.integratorsList(companyId);

    return loggiWebApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .get()
      .json()
      .catch(error => {
        const thrownError = new IntegratorListApiError(
          t(requestErrors.errors.generic)
        );
        Sentry.captureException(thrownError, {
          contexts: { 'original error': error }
        });

        throw thrownError;
      });
  },
  integratorCode: (companyId, payload) => {
    const t = getI18n().getFixedT(null, 'management');
    const url = endpoint.integratorsList(companyId);

    return loggiWebApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .post(payload)
      .badRequest(error => {
        const { badRequest } = requestErrors.errors;
        const scope = new Sentry.Scope();
        scope.setContext('payload', payload);
        Sentry.captureException(error);

        let snackbarMessage;

        try {
          const parsedError = JSON.parse(error.message);
          const code = parsedError?.code || parsedError?.message?.code;
          snackbarMessage = badRequest[code];
        } catch {
          snackbarMessage = requestErrors.errors.generic;
        }

        throw new IntegratorListApiError(t(snackbarMessage));
      })
      .json()
      .catch(error => {
        let thrownError;
        if (!(error instanceof IntegratorListApiError)) {
          thrownError = new IntegratorListApiError(
            t(requestErrors.errors.generic)
          );
          const scope = new Sentry.Scope();
          scope.setContext('payload', payload);
          Sentry.captureException(error);
        } else {
          thrownError = error;
        }

        throw thrownError;
      });
  },
  createScheduleConfig: (integratorName, payload) => {
    const t = getI18n().getFixedT(null, 'management');
    const url = endpoint.createScheduleConfig(integratorName);
    return inboundApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .post(payload)
      .json()
      .catch(error => {
        const thrownError = new IntegratorListApiError(
          t(requestErrors.errors.generic)
        );
        Sentry.captureException(thrownError, {
          contexts: { 'original error': error }
        });

        throw thrownError;
      });
  },
  getScheduleConfig: (integratorName, externalId) => {
    const t = getI18n().getFixedT(null, 'management');
    const url = endpoint.getScheduleConfig(integratorName, externalId);
    return inboundApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .get()
      .notFound(() => {
        return {};
      })
      .json()
      .catch(error => {
        const thrownError = new IntegratorListApiError(
          t(requestErrors.errors.generic)
        );
        Sentry.captureException(thrownError, {
          contexts: { 'original error': error }
        });

        throw thrownError;
      });
  },
  updateScheduleConfig: (integratorName, payload, externalId) => {
    const t = getI18n().getFixedT(null, 'management');
    const url = endpoint.updateScheduleConfig(integratorName, externalId);
    return inboundApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .put(payload)
      .json()
      .catch(error => {
        const thrownError = new IntegratorListApiError(
          t(requestErrors.errors.generic)
        );
        Sentry.captureException(thrownError, {
          contexts: { 'original error': error }
        });

        throw thrownError;
      });
  }
};

export default integratorListApi;
