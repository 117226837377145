import { useAmplifyAuth } from '@loggi/authentication-lib';
import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  fade,
  Hidden,
  makeStyles,
  Popover,
  SwipeableDrawer,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SignOutIcon } from '../../assets/sign-out.svg';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    email: {
      color: palette.text.secondary,
      lineHeight: '20px',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    menuItem: {
      color: palette.text.secondary,
      fontWeight: 600,
      display: 'flex',
      padding: spacing(3, 5),
      minHeight: typography.pxToRem(56),
      width: '100%',
      '&:hover': {
        backgroundColor: fade(palette.primary.light, 0.1)
      },
      '&.Mui-disabled': {
        color: palette.text.disabled
      },
      '&.Mui-disabled path': {
        fill: palette.text.disabled
      },
      [breakpoints.up('sm')]: {
        padding: spacing(2.5, 3)
      }
    },
    signOutButton: {
      gap: spacing(3),
      justifyContent: 'flex-start'
    },
    picture: {
      color: palette.grey[900],
      width: typography.pxToRem(56),
      height: typography.pxToRem(56),
      backgroundColor: palette.common.white,
      borderColor: palette.grey[500],
      borderStyle: 'solid',
      borderWidth: 'thin'
    },
    popover: {
      '& .MuiPopover-paper.MuiPaper-root': {
        marginTop: spacing(1),
        [breakpoints.only('xs')]: {
          width: 'calc(100% - 48px)'
        },
        [breakpoints.up('sm')]: {
          width: typography.pxToRem(320)
        }
      }
    },
    drawer: {
      '& .MuiDrawer-paper.MuiPaper-root': {
        borderRadius: spacing(2, 2, 0, 0)
      }
    },
    personContainer: {
      padding: spacing(3, 5),
      gap: spacing(3),
      [breakpoints.up('sm')]: {
        padding: spacing(3),
        gap: spacing(2)
      }
    },
    personInfoBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(1),
      wordBreak: 'break-word',
      '& .MuiTypography-root': {
        maxWidth: '100%',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  })
);

const UserProfileMenuContent = () => {
  const styles = useStyles();
  const {
    state: { authenticatedUser },
    signOut
  } = useAmplifyAuth();
  const { t } = useTranslation('components');

  const avatarLetter = authenticatedUser?.name
    ? authenticatedUser?.name[0]
    : undefined;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        className={styles.personContainer}
      >
        {avatarLetter && (
          <Avatar className={styles.picture} data-testid="profile-avatar-id">
            <Box fontWeight="fontWeightBold" component="strong">
              {avatarLetter}
            </Box>
          </Avatar>
        )}
        <Box className={styles.personInfoBox}>
          <Typography
            variant="h6"
            style={{ lineHeight: '20px', fontWeight: 'bold' }}
          >
            {authenticatedUser.name}
          </Typography>
          <Typography variant="body2" className={styles.email}>
            {authenticatedUser.email}
          </Typography>
        </Box>
      </Box>
      <Hidden smUp>
        <Divider variant="middle" style={{ width: '85%' }} />
      </Hidden>
      <Hidden xsDown>
        <Divider style={{ width: '100%' }} />
      </Hidden>
      <ButtonBase
        className={`${styles.menuItem} ${styles.signOutButton}`}
        data-testid="sign-out-btn"
        onClick={signOut}
        variant="button"
      >
        <SignOutIcon />
        {t('header.userMenu.logout')}
      </ButtonBase>
    </Box>
  );
};

const UserProfileMenu = ({ anchorElement, handleClose, handleOpen, open }) => {
  const styles = useStyles();

  return (
    <>
      <Hidden smUp>
        <SwipeableDrawer
          anchor="bottom"
          className={styles.drawer}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          <UserProfileMenuContent />
        </SwipeableDrawer>
      </Hidden>
      <Hidden xsDown>
        <Popover
          PaperProps={{
            style: { width: '380px' }
          }}
          anchorEl={anchorElement}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          className={styles.popover}
          elevation={24}
          marginThreshold={24}
          onClose={handleClose}
          open={open}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <UserProfileMenuContent />
        </Popover>
      </Hidden>
    </>
  );
};

UserProfileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  anchorElement: PropTypes.instanceOf(HTMLButtonElement)
};

UserProfileMenu.defaultProps = {
  anchorElement: null
};

export default UserProfileMenu;
