export const PICKUPS_COMPANIES_ENDPOINT = 'one/api/pickups/v1/companies';
export const PACKAGES_ENDPOINT = 'packages/';

export const invoiceLength = 44;

export const maxHeightInCm = 100;
export const maxLengthInCm = 100;
export const maxWeightInGrams = 30000;
export const maxWidthInCm = 100;

export const minHeightInCm = 1;
export const minLengthInCm = 1;
export const minWeightInGrams = 1;
export const minWidthInCm = 1;
