import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Button, Typography } from '@material-ui/core';
import { requestPermission } from '../../../../../firebase/notifications';

/**
 * FirebaseNotificationModalContent's responsibility is to show a model content to
 * handle the browser notification permissions.
 * @componentType: Presentational
 * @param open
 * @param isBlockedNotification
 * @returns {*}
 */
const FirebaseNotificationModalContent = ({
  onClose,
  isBlockedNotification
}) => {
  const { t } = useTranslation('packages');

  const title = isBlockedNotification
    ? t('customReport.permissions.denied.title')
    : t('customReport.permissions.default.title');
  const subtitle = isBlockedNotification
    ? t('customReport.permissions.denied.subtitle')
    : t('customReport.permissions.default.subtitle');
  const primaryButton = isBlockedNotification
    ? t('customReport.permissions.denied.primaryButton')
    : t('customReport.permissions.default.primaryButton');
  const secondaryButton = t('customReport.permissions.default.secondaryButton');

  const handlePrimaryButton = () => {
    if (!isBlockedNotification) {
      requestPermission();
    }
    onClose();
  };

  return (
    <Box p={{ xs: 3, sm: 5 }}>
      <Box pb={2}>
        <Typography variant="h6">
          <strong>{title}</strong>
        </Typography>
      </Box>
      <Box pb={4}>
        <Typography variant="subtitle2">{subtitle}</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        flexDirection={{ xs: 'column-reverse', sm: 'row' }}
      >
        <Button onClick={onClose} color="primary" size="large">
          {secondaryButton}
        </Button>
        <Button
          onClick={handlePrimaryButton}
          size="large"
          variant="contained"
          color="primary"
          autoFocus
        >
          {primaryButton}
        </Button>
      </Box>
    </Box>
  );
};

FirebaseNotificationModalContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  isBlockedNotification: PropTypes.bool.isRequired
};

export default FirebaseNotificationModalContent;
