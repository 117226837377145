import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@material-ui/core';

const GoBackButton = ({ onClick }) => {
  const { t } = useTranslation('signupCompanyForm');

  return (
    <Box pt={2} width="100%">
      <Button
        fullWidth
        color="primary"
        data-testid="goBackButton"
        onClick={onClick}
      >
        {t('goBackButton.text')}
      </Button>
    </Box>
  );
};

GoBackButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default GoBackButton;
