import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import EmptyPickupsIllustration from '../assets/empty-packages.svg';

const useStyles = makeStyles(({ typography, spacing }) => ({
  image: {
    width: '100%',
    maxWidth: typography.pxToRem(155),
    paddingTop: spacing(5)
  }
}));

const SchedulingDetailsEmptyPackagesList = () => {
  const classes = useStyles();
  const { t } = useTranslation('details');

  return (
    <Box
      alignItems="center"
      width={1}
      maxWidth={300}
      data-testid="empty-state-wrapper"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      m="auto"
      textAlign="center"
    >
      <img
        className={classes.image}
        src={EmptyPickupsIllustration}
        alt="Lista de pacotes vazia"
      />
      <Box mt={3.5} mb={2}>
        <Box mb={2}>
          <Typography className={classes.title} variant="subtitle1">
            <strong>{t('emptyPackagesList.title')}</strong>
          </Typography>
        </Box>
        <Typography variant="subtitle1" component="p">
          {t('emptyPackagesList.subtitle')}
        </Typography>
      </Box>
    </Box>
  );
};

export default SchedulingDetailsEmptyPackagesList;
