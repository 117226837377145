// eslint-disable-next-line max-classes-per-file
import moment from 'moment';

import * as Sentry from '@sentry/browser';

import currency from '@loggi/js/formatters/currency';
import { loggiWebApi } from '@loggi/authentication-lib';

import {
  GET_CLOSED_INVOICES_ENDPOINT,
  GET_PRICE_AGREEMENTS_ENDPOINT,
  GENERATE_SPREADSHEET_ENDPOINT,
  SPREADSHEET_GENERATION_STATUS_ENDPOINT
} from './payments-api-paths';

export class PriceAgreementsError extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'PriceAgreementsError';
  }
}

export class GenerateSpreadsheetError extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'GenerateSpreadsheetError';
  }
}

export class SpreadsheetGenerationStatusError extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'SpreadsheetGenerationStatusError';
  }
}

export const transformObject = ({ invoices }) =>
  invoices.map(invoice => {
    const { period_start: periodStart, period_end: periodEnd } = invoice;
    const momentStartDate = moment.utc(periodStart);
    const startDate = momentStartDate.format('DD/MM/YY');
    const endDate = moment.utc(periodEnd).format('DD/MM/YY');
    const interval = momentStartDate.format('MMMM / YY');
    const formattedAmount = currency(invoice.amount).abs;

    return Object.assign(invoice, {
      interval,
      startDate,
      endDate,
      amount: formattedAmount
    });
  });

export const transformPriceAgreements = priceAgreements =>
  priceAgreements.map(priceAgreement => {
    const {
      start_effective_on: start,
      end_effective_on: end,
      id: priceAgreementId
    } = priceAgreement;

    const startEffectiveOn = moment(start).format('LL');
    const startHourEffectiveOn = moment(start).format('HH:mm');
    const endEffectiveOn = end ? moment(end).format('LL') : end;

    return Object.assign(priceAgreement, {
      startEffectiveOn,
      startHourEffectiveOn,
      endEffectiveOn,
      priceAgreementId
    });
  });

export const generateSpreadsheetPayload = data => {
  const { priceTableName, spreadsheetTemplate } = data;

  return {
    price_table_name: priceTableName,
    spreadsheet_template: spreadsheetTemplate
  };
};

const paymentsApi = {
  getClosedInvoices: async companyId => {
    const response = await loggiWebApi
      .url(GET_CLOSED_INVOICES_ENDPOINT(companyId))
      .accept('application/json')
      .content('application/json')
      .get()
      .json();
    return transformObject(response);
  },
  getPriceAgreements: async companyId => {
    const response = await loggiWebApi
      .url(GET_PRICE_AGREEMENTS_ENDPOINT(companyId))
      .accept('application/json')
      .content('application/json')
      .get()
      .json()
      .catch(error => {
        Sentry.captureException(new PriceAgreementsError(error.message));
        throw new PriceAgreementsError(error.message);
      });

    return response ? transformPriceAgreements(response) : response;
  },
  generateSpreadsheet: async (companyId, priceAgreementId, data) => {
    const formattedPayload = generateSpreadsheetPayload(data);
    return loggiWebApi
      .url(GENERATE_SPREADSHEET_ENDPOINT(companyId, priceAgreementId))
      .accept('application/json')
      .content('application/json')
      .post(formattedPayload)
      .json()
      .catch(error => {
        Sentry.captureException(new GenerateSpreadsheetError(error.message));
      });
  },
  spreadsheetGenerationStatus: async (
    companyId,
    priceAgreementId,
    generationId
  ) => {
    return loggiWebApi
      .url(
        SPREADSHEET_GENERATION_STATUS_ENDPOINT(
          companyId,
          priceAgreementId,
          generationId
        )
      )
      .accept('application/json')
      .content('application/json')
      .get()
      .json()
      .catch(error => {
        Sentry.captureException(
          new SpreadsheetGenerationStatusError(error.message)
        );
      });
  }
};

export default paymentsApi;
