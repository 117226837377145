const { number, shape, string, arrayOf } = require('prop-types');

export const propTypes = shape({
  warehouseAddress: shape({
    postalAddress: shape({ addressLines: arrayOf(string) }),
    coordinates: shape({
      latitude: number,
      longitude: number
    }),
    coordinatesPostalCode: string,
    correiosAddress: shape({
      logradouro: string,
      numero: string,
      complemento: string,
      bairro: string,
      cep: string,
      cidade: string,
      uf: string
    }),
    coordinatesAdministrativeArea: string,
    coordinatesLocality: string
  }),
  tradingName: string,
  warehouseId: string,
  returnAddress: shape({
    companyWarehouseId: string,
    aliasName: string,
    instruction: string,
    responsibleName: string,
    responsiblePhone: string,
    address: shape({
      postalAddress: shape({
        regionCode: string,
        postalCode: string,
        administrativeArea: string,
        locality: string,
        sublocality: string,
        addressLines: arrayOf(string)
      })
    }),
    coordinates: shape({
      latitude: number,
      longitude: number
    }),
    coordinatesPostalCode: string,
    correiosAddress: shape({
      logradouro: string,
      numero: string,
      bairro: string,
      cep: string,
      cidade: string,
      uf: string
    }),
    coordinatesAdministrativeArea: string,
    coordinatesLocality: string
  })
});

export default propTypes;
