import { getUser } from '@loggi/authentication-lib';
import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFeature } from '@loggi/components/src/one/remote-config';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import { COMPANIES_ROUTE, INVITE_PATH } from '../users-management.constants';
import { INVITE_USER_BTN_ID } from './constants';
import { listUsers } from './service/index';
import UserListSearchBar from './user-list-search-bar';

import UsersListWrapper from './users-list-pagination/users-list-wrapper';

const useListUsersStyles = makeStyles(({ breakpoints }) => ({
  actionCol: {
    [breakpoints.up('sm')]: {
      alignItems: 'center',
      justifyContent: 'flex-end'
    }
  }
}));

// eslint-disable-next-line consistent-return
const reducer = (state, { type, payload }) => {
  if (type === 'fetchOk') {
    return {
      usersData: payload[1].usersAccesses,
      itemsCount: payload[1].totalSize,
      loggedUserEmail: payload[0].email,
      loading: false
    };
  }
};

const INITIAL_STATE = {
  usersData: [],
  itemsCount: 0,
  loading: true,
  LoggedUserEmail: ''
};

const UsersList = () => {
  const styles = useListUsersStyles();
  const { t } = useTranslation('listUsers');
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { itemsCount, loading, usersData, loggedUserEmail } = state;

  const { email = '', pageSize = 10, pageToken = 0 } = useQueryParams();
  const { companyId } = useParams();
  const { push, location } = useHistory();
  const { pathname } = useLocation();
  const enableFilterPaginationListUsers = useFeature(
    'enable_filter_pagination_list_user_management'
  );

  useEffect(() => {
    Promise.all([
      getUser(),
      listUsers(
        companyId,
        email,
        pageSize,
        pageToken,
        enableFilterPaginationListUsers
      )
    ]).then(responses => {
      dispatch({
        type: 'fetchOk',
        payload: responses
      });
    });
  }, [
    companyId,
    email,
    pathname,
    pageSize,
    pageToken,
    enableFilterPaginationListUsers
  ]);

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h4" component="h1" gutterBottom>
              <Box fontWeight="fontWeightMedium" component="strong">
                {t('header.message')}
              </Box>
            </Typography>
            <Typography variant="h6" component="h2">
              {t('subHeader.message')}
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={4} className={styles.actionCol}>
            <Button
              data-testid={INVITE_USER_BTN_ID}
              onClick={() =>
                push({
                  pathname: `${COMPANIES_ROUTE}/${companyId}/gerenciar-usuarios${INVITE_PATH}`,
                  search: location?.search
                })
              }
              variant="outlined"
            >
              {t('inviteButton.text')}
            </Button>
          </Grid>
        </Grid>
        {enableFilterPaginationListUsers && <UserListSearchBar />}
      </OneTemplateSummary>
      <OneTemplateContent>
        <UsersListWrapper
          count={itemsCount}
          isLoading={loading}
          data={usersData}
          loggedUserEmail={loggedUserEmail}
          enableFilterPaginationListUsers={enableFilterPaginationListUsers}
        />
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default UsersList;
