import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, Dialog, Button, Typography } from '@material-ui/core';

import Toast from '@loggi/components/src/one/toast';
import packagesApi from '../../packages-api';
import packagesRoutes from '../../packages-routes';
import {
  getToastParameters,
  getErrorToastMessage
} from './helpers/cancel-package.helpers';

const useCancelPackageDialogStyles = makeStyles(({ spacing, breakpoints }) => ({
  actionCol: {
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse'
    }
  },
  closeButton: {
    marginTop: spacing(1),
    [breakpoints.up('sm')]: {
      marginTop: spacing(0),
      marginRight: spacing(1.5)
    }
  }
}));

/**
 * CancelPackageDialog's responsibility is to show a dialog to confirm
 * a package cancelation intention, with the params it receives.
 * @componentType: Presentational
 * @param companyId
 * @param trackingKey
 * @param open
 * @param handleClose
 * @returns {*}
 */
const CancelPackageDialog = ({ companyId, trackingKey, open, handleClose }) => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const styles = useCancelPackageDialogStyles();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('packages');

  const redirectToSchedulesList = () =>
    history.push(packagesRoutes.index.url(companyId));

  const handleCancelPackageRequest = async () => {
    setLoading(true);

    packagesApi
      .cancel(companyId, trackingKey)
      .then(response => {
        setLoading(false);

        const { message: toastMessage, type } = getToastParameters(
          response.warning_message
        );

        enqueueSnackbar(t(toastMessage), {
          content: (key, message) => (
            <Toast id={key} message={message} type={type} />
          )
        });

        handleClose();
        redirectToSchedulesList();
      })
      .catch(error => {
        setLoading(false);

        const errorToastMessage = getErrorToastMessage(error);

        enqueueSnackbar(t(errorToastMessage), {
          content: (key, message) => (
            <Toast id={key} message={message} type="error" />
          )
        });
        handleClose();
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box p={{ xs: 3, sm: 5 }}>
        <Box pb={3}>
          <Typography variant="h5">
            <strong>{t('details.actions.cancel.title')}</strong>
          </Typography>
        </Box>
        <Box pb={3}>
          <Typography variant="body1">
            {t('details.actions.cancel.primarySubtitle')}
          </Typography>
        </Box>
        <Box pb={3}>
          <Typography variant="body1">
            <strong>
              {t('details.actions.cancel.secondarySubtitleStrong')}
            </strong>
            {t('details.actions.cancel.secondarySubtitle')}
          </Typography>
        </Box>
        <Box
          className={styles.actionCol}
          display="flex"
          justifyContent="center"
        >
          <Button
            data-testid="close-btn"
            className={styles.closeButton}
            onClick={handleClose}
            color="primary"
            size="medium"
          >
            {t('details.actions.cancel.closeButton')}
          </Button>
          <Button
            data-testid="cancel-btn"
            className="stop-sending-confirmation"
            onClick={handleCancelPackageRequest}
            disabled={loading}
            size="medium"
            variant="outlined"
            color="primary"
            autoFocus
          >
            {t('details.actions.cancel.title')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

CancelPackageDialog.propTypes = {
  companyId: PropTypes.string.isRequired,
  trackingKey: PropTypes.string.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

CancelPackageDialog.defaultProps = {
  open: false
};

export default CancelPackageDialog;
