import { colors } from '@loggi/mar';
import { ReactComponent as Buildings } from '@loggi/components/src/one/shared/images/buildings.svg';
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import packagesRoutes from '../../packages-routes';

import usePackageDownloadLabel from '../../use-package-download-label.hook';

const useAccessDeniedStyle = makeStyles(({ palette, typography }) => ({
  image: {
    fill: palette.primary.contrastText,
    width: '100%',
    maxWidth: typography.pxToRem(163)
  },
  root: {
    backgroundImage: colors.gradients.sanches
  }
}));

const PackageRecipientEditionSuccess = () => {
  const styles = useAccessDeniedStyle();
  const { t } = useTranslation('packages');
  const { companyId, trackingKey } = useParams();

  // As of today, we're assuming that Loggi One is using the tracking code as the barcode,
  // which means we'll send it twice on our label download request.
  const barcode = trackingKey;
  const downloadLabel = usePackageDownloadLabel(
    companyId,
    trackingKey,
    barcode
  );
  const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <Box
      className={styles.root}
      color="primary.contrastText"
      display="flex"
      height="100vh"
    >
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          height={1}
          justifyContent="center"
          pt={9.5}
        >
          <Buildings className={styles.image} />
          <Box pt={2}>
            <Typography variant="h6">
              <strong>{t('edition.success.title')}</strong>
            </Typography>
          </Box>
          <Box pt={1}>
            <Typography variant="body1">
              {t('edition.success.subtitle')}
            </Typography>
          </Box>
          <Box pt={17}>
            <Box>
              <Button
                size="large"
                fullWidth={!smUp}
                variant="outlined"
                onClick={downloadLabel}
              >
                {t('edition.success.printLabel')}
              </Button>
            </Box>
            <Box pt={1}>
              <Button
                component={Link}
                to={packagesRoutes.index.url(companyId)}
                size="large"
                fullWidth={!smUp}
                color="inherit"
              >
                {t('edition.success.linkText')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PackageRecipientEditionSuccess;
