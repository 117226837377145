import {
  AddressField,
  ComplementField,
  NoComplementField
} from '@loggi/components/src/one/formik-fields';
import { getOriginCoverageParams } from '@loggi/components/src/one/address';
import { useFeature } from '@loggi/components/src/one/remote-config';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PinIcon } from '../icons/pin.svg';
import SectionTitle from '../section-title/section-title';
import ShipperNameField from '../shipper/shipper-name-field';
import ShipperPhoneField from '../shipper/shipper-phone-field';
import { SM_FIELD_SPACING, XS_FIELD_SPACING } from './sections-constants';

const AddressSection = () => {
  const { t } = useTranslation('orderScheduling');
  const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const gridSpacing = smUp ? SM_FIELD_SPACING : XS_FIELD_SPACING;
  const complementFieldName = 'complement';

  const enableOriginCoverageValidation = useFeature(
    'enable_address_validation_in_order_scheduling_app'
  );

  return (
    <>
      <SectionTitle icon={<PinIcon title="Pin Icon" />}>
        {t('create.addressSection.title')}
      </SectionTitle>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={6}>
          <AddressField
            fieldName="address"
            validate={
              enableOriginCoverageValidation ? getOriginCoverageParams : null
            }
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <ComplementField fieldName={complementFieldName} />
        </Grid>
        <Grid item xs={6} md={3}>
          <Box mt={1}>
            <NoComplementField complementFieldName={complementFieldName} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <ShipperNameField />
        </Grid>
        <Grid item xs={12} md={3}>
          <ShipperPhoneField />
        </Grid>
      </Grid>
    </>
  );
};

export default AddressSection;
