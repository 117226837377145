import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Typography, makeStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Alert } from '@material-ui/lab';

import { SHIPPER_CODES_WITH_PROBLEM } from '@loggi/components/src/one/package-utils/status.helpers';

import packagesApi from '../../../packages-api';
import { FILTERS_NAMES } from '../../constants';

const useStyles = makeStyles(() => ({
  linkText: {
    textDecoration: 'underline',
    paddingLeft: 1,
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

/**
 * PackagesIssueAlert's only responsibility is to show the quantity of packages with issues.
 * @componentType: Presentational
 * @returns {*}
 */
const PackagesIssueAlert = ({ onChange }) => {
  const classes = useStyles();
  const { companyId } = useParams();
  const [totalIssues, setTotalIssues] = useState(0);

  const newFilter = Object();
  newFilter[FILTERS_NAMES.STATUS] = SHIPPER_CODES_WITH_PROBLEM();

  const { t } = useTranslation('packages');

  useEffect(() => {
    const params = {
      shipperStatus: SHIPPER_CODES_WITH_PROBLEM(),
      pageSize: 1
    };

    packagesApi
      .search(companyId, params)
      .then(response => setTotalIssues(response?.total));
  }, [companyId]);

  return (
    <>
      {totalIssues > 0 && (
        <Box pt={2}>
          <Alert icon={<ErrorIcon style={{ fontSize: 20 }} />} severity="error">
            <Box display="flex" flexWrap="wrap">
              <Typography component="span" variant="body2">
                {t('list.issuePackages.startTitle')}
                <strong>
                  {totalIssues === 1
                    ? t('list.issuePackages.middleTitle', {
                        count: totalIssues
                      })
                    : t('list.issuePackages.middleTitle_plural', {
                        count: totalIssues
                      })}
                </strong>
                {totalIssues === 1
                  ? t('list.issuePackages.endTitle')
                  : t('list.issuePackages.endTitle_plural')}
                <Typography
                  data-testid="issue-packages-link"
                  variant="body2"
                  component="span"
                  className={classes.linkText}
                  onClick={() => {
                    onChange(newFilter);
                  }}
                >
                  {totalIssues === 1
                    ? t('list.issuePackages.linkText', { count: totalIssues })
                    : t('list.issuePackages.linkText_plural', {
                        count: totalIssues
                      })}
                </Typography>
              </Typography>
            </Box>
          </Alert>
        </Box>
      )}
    </>
  );
};

PackagesIssueAlert.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default PackagesIssueAlert;
