const {
  func,
  instanceOf,
  arrayOf,
  number,
  shape,
  string,
  bool
} = require('prop-types');

const propTypes = shape({
  trackingKey: string.isRequired,
  deliveredDate: instanceOf(Date),
  status: string.isRequired,
  statusInfo: shape({
    label: string.isRequired,
    description: string.isRequired,
    code: number.isRequired
  }),
  destinationAddress: string,
  originPostalCode: string,
  receiverName: string,
  receiverDocument: string,
  deliveryLocationDescription: string,
  deliveryLinks: arrayOf(
    shape({
      rel: string,
      href: string
    })
  ),
  recipientName: string,
  recipientPhone: string,
  dimensions: string,
  weight: string,
  slo: number,
  chargeAmount: number,
  invoiceValue: string,
  companyCnpj: string,
  companyId: string,
  direction: shape({
    type: shape({
      code: number,
      description: string.isRequired
    }),
    reason: shape({
      code: number,
      description: string
    })
  })
});

export const chargeDetailPropTypes = {
  chargeAmount: number,
  slo: number,
  t: func.isRequired
};

export const packageDimensionsPropTypes = {
  volumetricInfo: shape({
    grossWeightG: number,
    integrationDimensions: shape({
      lengthCm: number,
      widthCm: number,
      heightCm: number
    }),
    measuredDimensions: shape({
      lengthCm: number,
      widthCm: number,
      heightCm: number
    })
  }),
  isMeasured: bool
};

export const packageChargePropTypes = shape({
  amount: number,
  billing_month: string,
  refund_reason: string
});

export const alertRefundPropTypes = {
  show: bool
};

export const fieldBlockPropTypes = shape({
  labelValue: string,
  value: string
});

export default propTypes;
