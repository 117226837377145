export const DEFAULT_PAGE_SIZE = 20;
export const FIRST_PAGE = 1;
export const FIRST_PAGE_TOKEN = 0;
export const DEFAULT_SEARCH_DAYS = 3;
export const PACKAGES_FETCH_ERRORS = {
  INVALID_PAGE: 'fetchPackages: page must be greater than 0',
  INVALID_PAGE_SIZE: 'fetchPackages: pageSize must be greater than 0'
};

export const PACKAGES_DATE_FILTER_VALUES = [3, 7, 15, 30];

export const DATE_PERIOD = 'period';

export const FILTERS_NAMES = {
  DIRECTION: 'direcao',
  DATE: 'periodo',
  STATUS: 'status',
  SEARCH: 'busca',
  PAGE: 'pagina',
  START_DATE: 'periodoInicial',
  FINAL_DATE: 'periodoFinal',
  INTEGRATOR: 'integrator',
  SEARCH_COMPANIES: 'searchCompanies'
};

export const FILTER_DATE_ERROR_NAME = {
  OUT_OF_RANGE_PERIOD: 'outOfRange',
  INVALID_PERIOD: 'invalidPeriod',
  SEARCH_ERROR: 'searchError',
  DEFAULT: 'searchError'
};

export const CLEAR_PAGINATION = true;

export const TRACKING_KEY_MAX_CHAR = 16;

export const EN_ROUTE_CANCELLATION_WARNING_MSG =
  'EN_ROUTE_CANCELLATION_WARNING_MESSAGE';
export const REDISPATCH_CANCELLATION_WARNING_MSG =
  'REDISPATCH_CANCELLATION_WARNING_MESSAGE';
export const REDISPATCH_ALREADY_CANCELLED_WARNING_MSG =
  'REDISPATCH_ALREADY_CANCELLED_WARNING_MESSAGE';

export const PARAM_URL_RETURN = 'devolucao';
export const BACKEND_URL_RETURN = 'return';
export const PACKAGE_DIRECTION_RETURN = 'PACKAGE_DIRECTION_RETURN';

export const HTTP_CODE_SUCCESS = '200';
export const MESSAGE_ORIGIN = 'LOGGI_ENVIOS';
export const FS_ENABLE_PACKAGE_SEARCH_V2 = 'enable_package_search_v2';
