import React from 'react';

import { useTheme, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingComponent = () => {
  const { spacing } = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={{ xs: spacing(40), sm: spacing(50) }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingComponent;
