import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../../index';
import getInvoiceLabel from './utils/get-invoice-label';

const initialState = {
  dataInvoiceDetails: {
    amount: 0.0,
    startDate: '',
    endDate: '',
    bankslipUrl: '',
    invoiceUrl: '',
    nfeUrl: '',
    cteUrl: '',
    status: ''
  },
  isDrawerOpen: false
};

const useInvoiceDetails = (invoices, companyId, invoiceLabel) => {
  const [{ dataInvoiceDetails, isDrawerOpen }, setDataDrawer] = useState(
    initialState
  );
  const { push } = useHistory();

  const resetInvoiceDetailsData = useCallback(() => {
    setDataDrawer(initialState);
    push(routes.index.url(companyId));
  }, [companyId, push]);

  const setData = data => {
    setDataDrawer(() => ({
      dataInvoiceDetails: {
        ...data
      },
      isDrawerOpen: true
    }));
  };

  const setInvoiceDetailsData = data => {
    setData(data);
    push(routes.invoiceDocuments.url(companyId, getInvoiceLabel(data)));
  };

  useEffect(() => {
    if (invoiceLabel && !isDrawerOpen) {
      const selectedInvoice = invoices
        .filter(invoice => getInvoiceLabel(invoice) === invoiceLabel)
        .shift();
      if (selectedInvoice) {
        setData(selectedInvoice);
      } else {
        resetInvoiceDetailsData();
      }
    }
  }, [
    invoices,
    companyId,
    invoiceLabel,
    isDrawerOpen,
    resetInvoiceDetailsData
  ]);

  return {
    dataInvoiceDetails,
    isDrawerOpen,
    resetInvoiceDetailsData,
    setInvoiceDetailsData
  };
};

export default useInvoiceDetails;
