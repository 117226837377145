import DateFnsUtils from '@date-io/date-fns';
import {
  addDays,
  isBefore,
  isAfter,
  isValid,
  isWeekend,
  isSameDay
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FormControl } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useFormikContext, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { calculateMinDate, basicHolidays } from './unified-pickup-helpers';
import { SCHEDULING_DAYS_FROM_NOW } from './constants';
import useNonWorkingDays from './use-non-working-days-list.hook';

const DateField = ({ fieldName }) => {
  const { t } = useTranslation('unifiedPickup');

  // Will use setFieldValue to reduce the number of rerenders on StateChange
  const {
    values: { pickupStartTime, pickupDate },
    setFieldValue
  } = useFormikContext();
  const [minDate, setMinDate] = useState();
  const maxDate = useMemo(() => addDays(minDate, SCHEDULING_DAYS_FROM_NOW), [
    minDate
  ]);
  const [nonWorkingDays] = useNonWorkingDays({ minDate, maxDate });
  const disabledDays = useCallback(
    date => {
      if (isWeekend(date)) {
        return true;
      }

      if (nonWorkingDays) {
        return nonWorkingDays.find(item => isSameDay(item, date)) !== undefined;
      }

      return basicHolidays(date);
    },
    [nonWorkingDays]
  );

  const [field, meta] = useField({
    name: fieldName,
    initialValue: pickupDate,
    validate: date => {
      // the widget always set the date to null when the user interacts
      // will use undefined to not show an error message
      if (date === undefined) {
        return null;
      }

      if (!date || !isValid(date)) {
        return String(t('dateField.errorMessages.requiredDate'));
      }

      if (pickupStartTime && isValid(pickupStartTime)) {
        const dateWithStartTime = new Date(date);
        dateWithStartTime.setHours(
          pickupStartTime.getHours(),
          pickupStartTime.getMinutes()
        );

        if (
          isBefore(dateWithStartTime, minDate) ||
          isAfter(dateWithStartTime, maxDate) ||
          disabledDays(dateWithStartTime)
        ) {
          return String(t('dateField.errorMessages.invalidDate'));
        }
      } else {
        return String(t('dateField.errorMessages.requiredDate'));
      }

      return null;
    }
  });

  useEffect(() => {
    if (pickupStartTime && isValid(pickupStartTime)) {
      const auxDate = calculateMinDate(pickupStartTime);
      setMinDate(auxDate);
    }
  }, [pickupStartTime, setMinDate]);

  const { onBlur, value = null } = field;
  const { error, touched } = meta;
  const hasError = Boolean(error) && touched;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <FormControl error={hasError} fullWidth variant="outlined">
        <KeyboardDatePicker
          autoOk
          data-testid="date-input"
          disableToolbar
          error={hasError}
          format="dd/MM/yyyy"
          helperText={hasError && error}
          id={fieldName}
          inputVariant="outlined"
          KeyboardButtonProps={{ 'aria-label': 'change date' }}
          label={t('dateField.label')}
          placeholder={t('dateField.placeholder')}
          maxDate={maxDate}
          minDate={minDate}
          name={fieldName}
          onBlur={onBlur}
          onAccept={v => setFieldValue(fieldName, v, true)}
          onChange={v => setFieldValue(fieldName, v, true)}
          required
          value={value}
          variant="inline"
          InputLabelProps={{ required: false }}
          inputProps={{ 'data-testid': `${fieldName}-input` }}
          shouldDisableDate={disabledDays}
          PopoverProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' }
          }}
        />
      </FormControl>
    </MuiPickersUtilsProvider>
  );
};

DateField.propTypes = {
  fieldName: PropTypes.string.isRequired
};

export default DateField;
