import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';

import PriceAgreementsListItem from './price-agreements-item.component';

const useStyles = makeStyles(() => ({
  list: {
    opacity: ({ isLoading }) => (isLoading ? 0.5 : 1)
  }
}));

const PriceAgreementsList = ({ priceAgreements, isLoading }) => {
  const classes = useStyles({ isLoading });

  return (
    <>
      <List className={classes.list}>
        {priceAgreements.map((priceAgreement, index) => {
          const generatedKey = `${priceAgreement.startEffectiveOn}${index}`;

          return (
            <PriceAgreementsListItem
              key={generatedKey}
              priceAgreement={priceAgreement}
            />
          );
        })}
      </List>
    </>
  );
};

PriceAgreementsList.propTypes = {
  priceAgreements: PropTypes.arrayOf(
    PropTypes.shape({
      priceAgreementId: PropTypes.string,
      status: PropTypes.string,
      startEffectiveOn: PropTypes.string,
      startHourEffectiveOn: PropTypes.string,
      endEffectiveOn: PropTypes.string,
      priceTables: PropTypes.arrayOf(
        PropTypes.shape({
          originRegion: PropTypes.string,
          priceTableName: PropTypes.string
        })
      )
    })
  ),
  isLoading: PropTypes.bool.isRequired
};
PriceAgreementsList.defaultProps = {
  priceAgreements: []
};

export default PriceAgreementsList;
