import { useQueryParams } from '@loggi/components/src/one/hooks';
import {
  Box,
  TablePagination,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@material-ui/core';
import { CheckCircle, Error } from '@material-ui/icons';
import theme from '@loggi/mar';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import Toast from '@loggi/components/src/one/toast';
import { ReactComponent as CheckMarkIcon } from '@loggi/components/src/one/shared/images/checkmark-icon.svg';
import { ReactComponent as BarcodeReaderIcon } from '@loggi/components/src/one/shared/images/barcode-reader.svg';
import { PICKUP_RECEIPT_ROUTES } from '../../constants';
import generateReceiptReportFile from '../../../../operations/generate-pickup-receipt-report';

/**
 * SchedulingDetailsPickupReceiptPackagesList renders a list that presents the data it receives with pagination
 * @componentType presentational
 * @param pickupReceipt
 * @returns {*}
 * @constructor
 */
const SchedulingDetailsPickupReceiptPackagesList = ({ pickupReceipt }) => {
  const { t } = useTranslation('details');
  const queryParams = useQueryParams();
  const history = useHistory();
  const DEFAULT_PAGE_SIZE = 20;

  const { pagina: page = 1 } = queryParams;

  const { companyId, id: schedulingId, pickupReceiptId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const onChangePage = useCallback(
    (_, nextPage) => {
      const params = {
        ...queryParams,
        pagina: nextPage + 1
      };
      history.replace(
        qs.stringify(params, { addQueryPrefix: true, encode: false })
      );
    },
    [history, queryParams]
  );

  const indexInitial = (page - 1) * DEFAULT_PAGE_SIZE;
  const indexFinal = page * DEFAULT_PAGE_SIZE;

  const packagesToList = pickupReceipt.packages.slice(indexInitial, indexFinal);

  const onReportClick = () => {
    history.push(
      PICKUP_RECEIPT_ROUTES.report.url(companyId, schedulingId, pickupReceiptId)
    );
  };

  const getToastMessage = success => {
    if (success) {
      return t('receiptDownload.toastMessageSuccess');
    }
    return t('receiptDownload.toastMessageError');
  };

  const sendToastMessage = ({ success }) => {
    const messageType = success ? 'success' : 'error';

    const toastMessage = getToastMessage(success);

    enqueueSnackbar(toastMessage, {
      content: (key, message) => (
        <Toast id={key} message={message} type={messageType} />
      )
    });
  };

  const generateReport = async () => {
    try {
      await generateReceiptReportFile(pickupReceipt, t);
      sendToastMessage({ success: true });
    } catch (error) {
      sendToastMessage({ success: false });
    }
  };

  const confirmationPropertiesMap = {
    true: {
      icon: (
        <CheckCircle
          color="disabled"
          style={{ width: '24px', height: '24px' }}
        />
      ),
      text: t('receiptConfirmation.confirmed.message'),
      color: theme.palette.text.disabled,
      action: null
    },
    false: {
      icon: (
        <Error color="disabled" style={{ width: '24px', height: '24px' }} />
      ),
      text: t('receiptConfirmation.notConfirmed.message'),
      color: theme.palette.text.disabled,
      action: null
    }
  };

  const defaultConfirmationProperties = {
    icon: <BarcodeReaderIcon />,
    text: t('receiptConfirmation.notAnswered.button.text'),
    color: theme.palette.primary.main
  };

  const confirmationProperties = pickupReceipt.alreadyAnswered
    ? confirmationPropertiesMap[!!pickupReceipt.shipperConfirmedTotalPackages]
    : defaultConfirmationProperties;

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={4}
        pb={2}
      >
        <Box display="flex" alignItems="center" pl={5.5}>
          <CheckMarkIcon />
          <Box pl={2}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: theme.typography.fontWeightMedium }}
            >
              {t('pickupReceiptDetails.title')}
            </Typography>
          </Box>
        </Box>
        <Box pr={4}>
          <Button
            startIcon={confirmationProperties.icon}
            onClick={onReportClick}
            data-testid="report-btn"
            style={{ color: confirmationProperties.color }}
          >
            <Typography variant="button">
              {confirmationProperties.text}
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            data-testid="download-btn"
            onClick={generateReport}
          >
            {t('receiptDownload.label')}
          </Button>
        </Box>
      </Box>
      <Table data-testid="packages-list">
        <TableHead>
          <TableRow>
            <TableCell>
              <Box pl={2.5}>
                <Typography variant="body2" color="textPrimary">
                  <strong>
                    {t('pickupReceiptDetails.receiptTable.columns.trackingKey')}
                  </strong>
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {packagesToList.map(pkg => {
            return (
              <TableRow key={pkg.trackingKey}>
                <TableCell>
                  <Box pl={2.5}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {pkg.trackingKey}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Box pt={3} pb={5}>
        <TablePagination
          component="div"
          onChangePage={onChangePage}
          page={Number(page) - 1} // Prop starts at 0
          count={pickupReceipt.totalPackages}
          labelDisplayedRows={({ from, to, count }) =>
            t('pickupReceiptDetails.pagination.row', { from, to, count })
          }
          rowsPerPage={DEFAULT_PAGE_SIZE}
          rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
          nextIconButtonProps={{
            disabled: page * DEFAULT_PAGE_SIZE >= pickupReceipt.totalPackages
          }}
          backIconButtonProps={{ disabled: Number(page) === 1 }}
        />
      </Box>
    </>
  );
};

SchedulingDetailsPickupReceiptPackagesList.propTypes = {
  pickupReceipt: PropTypes.shape({
    totalPackages: PropTypes.number.isRequired,
    driverName: PropTypes.string.isRequired,
    driverDocument: PropTypes.string,
    transportType: PropTypes.string,
    licensePlate: PropTypes.string,
    alreadyAnswered: PropTypes.bool,
    answeredAtTime: PropTypes.string,
    shipperConfirmedTotalPackages: PropTypes.bool,
    pickupEndTime: PropTypes.string,
    packages: PropTypes.arrayOf(
      PropTypes.shape({
        trackingKey: PropTypes.string
      })
    ).isRequired
  })
};

SchedulingDetailsPickupReceiptPackagesList.defaultProps = {
  pickupReceipt: {
    alreadyAnswered: false,
    shipperConfirmedTotalPackages: undefined
  }
};

export default SchedulingDetailsPickupReceiptPackagesList;
