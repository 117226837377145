import { loggiWebApi } from '@loggi/authentication-lib';

const billingApi = {
  getCharge: (companyId, trackingKey) => {
    return loggiWebApi
      .url(`/one/api/billing/v1/companies/${companyId}/charge/${trackingKey}`)
      .accept('application/json')
      .content('application/json')
      .get()
      .json();
  }
};

export default billingApi;
