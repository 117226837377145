import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(({ typography }) => ({
  skeleton: {
    borderRadius: typography.pxToRem(4)
  },
  title: {
    width: typography.pxToRem(205)
  }
}));

const AddressSectionSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Box
        className={classes.title}
        pb={4}
        data-testid="address-section-skeleton"
      >
        <Typography variant="h6">
          <Skeleton className={classes.skeleton} />
        </Typography>
      </Box>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
        <Box flex="1">
          <Box pr={{ sm: 5 }}>
            <Skeleton className={classes.skeleton} variant="rect" height={52} />
          </Box>
        </Box>
        <Box flex="1">
          <Box display="flex" pt={{ xs: 4, sm: 0 }}>
            <Box flex="1" pr={{ xs: 2, sm: 5 }}>
              <Skeleton
                className={classes.skeleton}
                variant="rect"
                height={52}
              />
            </Box>
            <Box flex="1" mt={2}>
              <Skeleton
                className={classes.skeleton}
                variant="rect"
                height={25}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddressSectionSkeleton;
