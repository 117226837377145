import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '@material-ui/core';

import FirebaseNotificationModalContent from './firebase-notification-modal-content';

/**
 * FirebaseNotificationModal's responsibility is to show a modal (dialog) to handle
 * browser notification permissions.
 * @componentType: Presentational
 * @param open
 * @param onClose
 * @param isBlockedNotification
 * @returns {*}
 */
const FirebaseNotificationModal = ({
  open,
  onClose,
  isBlockedNotification
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <FirebaseNotificationModalContent
        onClose={onClose}
        isBlockedNotification={isBlockedNotification}
      />
    </Dialog>
  );
};

FirebaseNotificationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isBlockedNotification: PropTypes.bool.isRequired
};

FirebaseNotificationModal.defaultProps = {
  open: false
};

export default FirebaseNotificationModal;
