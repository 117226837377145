import React from 'react';
import { Box, Typography, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import pickupReceiptHelpCenterUrl from './constants';

export default function PickupReceiptFooter({ position, pb, pt }) {
  const { t } = useTranslation('pickupReceiptFooter');

  return (
    <Box
      position={position}
      bottom="0px"
      width="100%"
      left="0px"
      pb={pb}
      pt={pt}
      textAlign="center"
    >
      <Typography variant="body2">{t('pickupQuestions.text')}</Typography>
      <Link href={pickupReceiptHelpCenterUrl} variant="body2" target="_blank">
        <strong>{t('faqLink.text')}</strong>
      </Link>
    </Box>
  );
}

PickupReceiptFooter.propTypes = {
  position: PropTypes.string,
  pb: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  pt: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
};

PickupReceiptFooter.defaultProps = {
  position: 'relative',
  pb: 0,
  pt: 0
};
