import marTheme, { colors } from '@loggi/mar';
import { oneConstants } from '@loggi/components/src/one';
import { ReactComponent as Buildings } from '@loggi/components/src/one/shared/images/buildings.svg';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  Drawer,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import React, { useCallback, useState } from 'react';

import { HIDE_RECURRING_PICKUP_DIALOG_LOCALSTORAGE_KEY } from './constants';

const StyledDrawer = withStyles(({ spacing }) => ({
  paperAnchorBottom: {
    borderRadius: spacing(2, 2, 0, 0)
  }
}))(Drawer);

const useAboutStyles = makeStyles(({ spacing, typography }) => ({
  listIcon: {
    alignItems: 'center',
    height: typography.pxToRem(32),
    minWidth: spacing(2)
  },
  listItem: {
    alignItems: 'start',
    paddingLeft: '0',
    paddingRight: '0'
  }
}));

const useSuccessStyles = makeStyles(() => ({
  root: {
    backgroundImage: colors.gradients.sanches
  }
}));

const TRACKING_INDEX_ROUTE = {
  url: companyId =>
    `/empresas/${companyId}${oneConstants.TRACKING_ROUTE_INITIAL_STATE}`
};

const AboutRecurringPickup = ({ onConfirm }) => {
  const styles = useAboutStyles();
  const { t } = useTranslation('unifiedPickup');
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleConfirm = useCallback(() => {
    if (dontShowAgain)
      localStorage.setItem(HIDE_RECURRING_PICKUP_DIALOG_LOCALSTORAGE_KEY, true);

    onConfirm();
  }, [dontShowAgain, onConfirm]);

  return (
    <Box p={{ xs: 3, sm: 5 }}>
      <Typography variant="h6">
        <strong>{t('about.title')}</strong>
      </Typography>
      <List>
        <ListItem className={styles.listItem}>
          <ListItemIcon className={styles.listIcon}>
            <Box
              width={8}
              height={8}
              borderRadius={50}
              bgcolor={marTheme.palette.primary.main}
            />
          </ListItemIcon>
          <ListItemText primary={t('about.bullet1')} />
        </ListItem>
        <ListItem className={styles.listItem}>
          <ListItemIcon className={styles.listIcon}>
            <Box
              width={8}
              height={8}
              borderRadius={50}
              bgcolor={marTheme.palette.primary.main}
            />
          </ListItemIcon>
          <ListItemText primary={t('about.bullet2')} />
        </ListItem>
        <ListItem className={styles.listItem}>
          <ListItemIcon className={styles.listIcon}>
            <Box
              width={8}
              height={8}
              borderRadius={50}
              bgcolor={marTheme.palette.primary.main}
            />
          </ListItemIcon>
          <ListItemText primary={t('about.bullet3')} />
        </ListItem>
        <ListItem className={styles.listItem}>
          <ListItemIcon className={styles.listIcon}>
            <Box
              width={8}
              height={8}
              borderRadius={50}
              bgcolor={marTheme.palette.primary.main}
            />
          </ListItemIcon>
          <ListItemText primary={t('about.bullet4')} />
        </ListItem>
      </List>
      <Box mt={3}>
        <Button
          color="primary"
          fullWidth
          onClick={handleConfirm}
          size="large"
          variant="contained"
        >
          {t('about.action')}
        </Button>
      </Box>
      <Box display="flex" justifyContent="center" mt={3}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              inputProps={{ 'data-testid': 'checkbox' }}
            />
          }
          label={t('about.checkbox')}
          onChange={() => setDontShowAgain(!dontShowAgain)}
          value={dontShowAgain}
        />
      </Box>
    </Box>
  );
};

AboutRecurringPickup.propTypes = {
  onConfirm: PropTypes.func.isRequired
};

const RecurringPickupSuccess = () => {
  const styles = useSuccessStyles();
  const { t } = useTranslation('unifiedPickup');
  const isMobile = useMediaQuery(theme => theme.breakpoints.only('xs'));
  const [openDialog, setOpenDialog] = useState(false);
  const { push } = useHistory();
  const { companyId } = useParams();

  const redirect = () => push(TRACKING_INDEX_ROUTE.url(companyId));
  const handleAction = () => {
    if (localStorage.getItem(HIDE_RECURRING_PICKUP_DIALOG_LOCALSTORAGE_KEY))
      return redirect();

    return setOpenDialog(true);
  };

  return (
    <Box
      className={styles.root}
      color="primary.contrastText"
      data-testid="successScreen"
      display="flex"
      height="100vh"
    >
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          height={1}
          justifyContent="center"
          pt={9.5}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Buildings />
            </Grid>
            <Grid item xs={12} sm={9} md={7}>
              <Typography
                color="inherit"
                component="h1"
                gutterBottom
                variant="h4"
              >
                <Box fontWeight="fontWeightMedium" component="strong">
                  {t('success.title')}
                </Box>
              </Typography>
              <Typography variant="h6" component="h2" color="inherit">
                {t('success.subTitle')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button size="large" variant="outlined" onClick={handleAction}>
                {t('success.action')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      {isMobile ? (
        <StyledDrawer
          anchor="bottom"
          data-testid="drawer"
          onClose={() => setOpenDialog(false)}
          open={openDialog}
        >
          <AboutRecurringPickup onConfirm={redirect} />
        </StyledDrawer>
      ) : (
        <Dialog
          data-testid="dialog"
          onClose={() => setOpenDialog(false)}
          open={openDialog}
        >
          <AboutRecurringPickup onConfirm={redirect} />
        </Dialog>
      )}
    </Box>
  );
};

export default RecurringPickupSuccess;
