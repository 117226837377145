import {
  OneTemplate,
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom';
import getPickupReceiptListApi from '../../operations/pickup-receipts/pickups-list-api';
import {
  PICKUP_RECEIPT_AUTH_ROUTE,
  PICKUP_RECEIPT_PACKAGES_ROUTE,
  PICKUP_RECEIPT_ROUTE
} from '../../routes/constants';
import PickupReceiptFooter from '../pickup-receipt-footer/pickup-receipt-footer.component';
import PackagesList from './packages-list/packages-list.component';
import PickupReceiptExpired from './pickup-receipt-expired.component';
import PickupReceiptsListItem from './pickup-receipts-list/pickup-receipts-list-item.component';
import ReceiptOrderSchedule from './receipt-order-schedule/receipt-order-schedule.component';
import useAsyncError from '../use-async-error';

const pickupListItems = (
  pickupOrderScheduleHash,
  pickupReceipts,
  setPickupReceipts
) => {
  return pickupReceipts.map(pickupReceipt => {
    return (
      <Box
        pl={{ xs: 3, sm: 0 }}
        pr={{ xs: 3, sm: 0 }}
        pb={3}
        key={pickupReceipt.pickupReceiptId}
      >
        <OneTemplateContent style={{ borderRadius: 16 }}>
          <PickupReceiptsListItem
            pickupOrderScheduleHash={pickupOrderScheduleHash}
            pickupReceipt={pickupReceipt}
            pickupReceipts={pickupReceipts}
            setPickupReceipts={setPickupReceipts}
          />
        </OneTemplateContent>
      </Box>
    );
  });
};

const PickupReceipts = () => {
  const location = useLocation();
  const { pickupOrderScheduleHash } = useParams();
  const [pickupReceipts, setPickupReceipts] = useState(
    location?.state?.pickupReceipts || []
  );
  const [expiredStatus, setExpiredStatus] = useState({
    isExpired: false,
    expiredDays: 0
  });
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const { t } = useTranslation(['pickupReceiptsList']);
  const showReceiptMainPage = matchPath(location.pathname, {
    path: PICKUP_RECEIPT_ROUTE.path,
    exact: true,
    strict: false
  });
  const throwError = useAsyncError();

  useEffect(() => {
    getPickupReceiptListApi
      .get(pickupOrderScheduleHash)
      .forbidden(() => {
        push(PICKUP_RECEIPT_AUTH_ROUTE.url(pickupOrderScheduleHash));
      })
      .error(410, error => {
        const errorParsed = JSON.parse(error.message);
        setExpiredStatus({
          isExpired: true,
          expiredDays: errorParsed.expiration_time
        });
      })
      .json(json => {
        setPickupReceipts(json.pickupReceipts);
      })
      .catch(error => {
        throwError(new Error(error));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupOrderScheduleHash, push, enqueueSnackbar, t]);

  return (
    <>
      {showReceiptMainPage && (
        <OneTemplate>
          <OneTemplateSummary>
            <ReceiptOrderSchedule
              pickupOrderScheduleHash={pickupOrderScheduleHash}
            />
          </OneTemplateSummary>
          <OneTemplateContent disablePaper>
            {expiredStatus.isExpired ? (
              <PickupReceiptExpired daysExpired={expiredStatus.expiredDays} />
            ) : (
              pickupListItems(
                pickupOrderScheduleHash,
                pickupReceipts,
                setPickupReceipts
              )
            )}
            <PickupReceiptFooter
              pt={2}
              pb={{ xs: 5, sm: 0 }}
              position="relative"
            />
          </OneTemplateContent>
        </OneTemplate>
      )}
      {pickupReceipts.length && (
        <Switch>
          <Route
            exact
            path={PICKUP_RECEIPT_PACKAGES_ROUTE.path}
            render={({ match }) => {
              const { params } = match;
              const { pickupReceiptId } = params;

              const consultedReceipt = pickupReceipts.find(
                receipt => receipt.pickupReceiptId === pickupReceiptId
              );

              return <PackagesList pickupReceipt={consultedReceipt} />;
            }}
          />
        </Switch>
      )}
    </>
  );
};

export default PickupReceipts;
