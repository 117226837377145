import { Drawer, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import InviteUserForm from './invite-user.container';
import {
  COMPANIES_ROUTE,
  USERS_MANAGEMENT_PATH
} from '../users-management.constants';

const CustomDrawer = withStyles(({ breakpoints, spacing }) => ({
  paper: {
    [breakpoints.up('sm')]: {
      maxWidth: spacing(58),
      minWidth: spacing(58)
    },
    width: '100%'
  }
}))(Drawer);

const InviteUserDrawer = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { push, location } = useHistory();
  const { companyId } = useParams();

  const closeDrawer = () => {
    setDrawerOpen(false);
    push({
      pathname: `${COMPANIES_ROUTE}/${companyId}${USERS_MANAGEMENT_PATH}`,
      search: location?.search
    });
  };

  useEffect(() => {
    setDrawerOpen(true);
  }, []);

  return (
    <CustomDrawer anchor="right" onClose={closeDrawer} open={isDrawerOpen}>
      <Box px={{ xs: 3, sm: 4 }} py={{ xs: 1.5, sm: 4 }}>
        <IconButton
          data-testid="close-drawer-btn"
          edge="start"
          onClick={closeDrawer}
        >
          <ArrowBack color="primary" />
        </IconButton>
      </Box>
      <InviteUserForm />
    </CustomDrawer>
  );
};

export default InviteUserDrawer;
