import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { colors } from '@loggi/mar';
import {
  Box,
  Button,
  Divider,
  ListItem,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  useLocation,
  useParams,
  Link as RouterLink
} from 'react-router-dom';

import packagesRoutes from '../../packages-routes';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    alertMessage: {
      color: palette.info.dark,
      fontSize: typography.body2.fontSize,
      [breakpoints.down('sm')]: {
        alignItems: 'start',
        '& .MuiAlert-icon': {
          paddingTop: spacing(1.15)
        }
      }
    },
    listItemBulletPoints: {
      color: palette.error.main,
      display: 'list-item',
      listStyle: 'disc',
      '&::marker': {
        fontSize: typography.subtitle2.fontSize
      }
    },
    subtitleIcon: {
      color: palette.error.main
    }
  })
);

const InvoiceErrors = () => {
  const { t } = useTranslation('packages');
  const styles = useStyles();
  const { push } = useHistory();
  const { companyId } = useParams();
  const { state } = useLocation();
  const uploadInvoiceUrl = packagesRoutes.importInvoice.url(companyId);
  const { errors } = state;

  if (!state?.errors?.length > 0) push(uploadInvoiceUrl);

  const groupFailuresByFilename = failures =>
    Array.from(new Set(failures.map(failure => failure.filename))).map(
      filename => {
        return {
          filename,
          details: failures
            .filter(failure => failure.filename === filename)
            .map(failure => failure.details)
        };
      }
    );

  return (
    <OneTemplate>
      <OneTemplateSummary color={colors.red[700]}>
        <Box display="flex" flexDirection="column">
          <Typography variant="h4" component="h1">
            <Box component="strong" fontWeight="fontWeightBold">
              {t('create.invoiceImport.errors.title')}
            </Box>
          </Typography>
          <Box mt={2}>
            <Alert
              className={styles.alertMessage}
              icon={<Error className={styles.subtitleIcon} fontSize="small" />}
              severity="error"
            >
              {t('create.invoiceImport.errors.subtitle')}
            </Alert>
          </Box>
        </Box>
      </OneTemplateSummary>
      <OneTemplateContent>
        <Box px={{ sm: 5 }} py={{ sm: 4 }}>
          {errors.map(({ packageResult: { failures } }) => {
            return groupFailuresByFilename(failures).map(
              ({ details, filename }) => (
                <Box key={filename}>
                  <Box ml={2}>
                    <ListItem
                      className={styles.listItemBulletPoints}
                      disableGutters
                    >
                      <Typography variant="body1" gutterBottom>
                        <Box
                          color="text.secondary"
                          component="strong"
                          fontWeight="fontWeightBold"
                        >
                          {t('create.invoiceImport.errors.list.title')}
                        </Box>
                      </Typography>
                    </ListItem>
                  </Box>
                  <Typography variant="subtitle1">
                    <Box component="strong" fontWeight="fontWeightBold">
                      {filename}
                    </Box>
                  </Typography>
                  <Box>
                    {details.map(detail => (
                      <Box pt={2}>
                        <Alert
                          className={styles.alertMessage}
                          icon={<Error fontSize="small" />}
                          severity="warning"
                        >
                          {detail}
                        </Alert>
                      </Box>
                    ))}
                  </Box>
                  <Box py={4}>
                    <Divider />
                  </Box>
                </Box>
              )
            );
          })}
          <Box display="flex" justifyContent="center" pt={3}>
            <Button
              color="primary"
              component={RouterLink}
              data-testid="add-new-invoice-btn"
              to={uploadInvoiceUrl}
              variant="outlined"
            >
              {t('create.invoiceImport.errors.list.buttonText')}
            </Button>
          </Box>
        </Box>
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default InvoiceErrors;
