import { Box, Button, Dialog, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

/**
 * CreatePackageModal's responsibility is to show a dialog to confirm
 * that will be redirect to the page to create a new package
 * @componentType: Presentational
 * @param handleClose
 * @param open
 * @param toUrl
 * @returns {*}
 */
const CreatePackageModal = ({ handleClose, open, toUrl }) => {
  const { spacing } = useTheme();
  const history = useHistory();
  const { t } = useTranslation('unifiedPickup');

  const goToCreatePackage = () => {
    history.push(toUrl);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box p={{ xs: 3, sm: 6 }}>
        <Box pb={2}>
          <Typography variant="h5">
            <strong>{t('packageSelection.addPackageModal.header')}</strong>
          </Typography>
        </Box>
        <Box pb={6}>
          <Typography variant="body1">
            {t('packageSelection.addPackageModal.description')}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            data-testid="continue-selecting-btn"
            onClick={handleClose}
            color="primary"
            variant="outlined"
            style={{ marginRight: spacing(3) }}
          >
            {t('packageSelection.addPackageModal.actions.continueSelecting')}
          </Button>
          <Button
            data-testid="go-to-create-package-btn"
            onClick={goToCreatePackage}
            color="secondary"
            autoFocus
          >
            {t('packageSelection.addPackageModal.actions.goToCreatePackage')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

CreatePackageModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  toUrl: PropTypes.string.isRequired
};

CreatePackageModal.defaultProps = {
  open: false
};

export default CreatePackageModal;
