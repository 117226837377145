export const RECIPIENT = 'RECIPIENT';
export const DATES = 'DATES';
export const STATUS = 'STATUS';
export const PACKAGE_DIRECTION = 'PACKAGE_DIRECTION';
export const PACKAGE_INFORMATION = 'PACKAGE_INFORMATION';
export const COMPANY_INFORMATION = 'COMPANY_INFORMATION';

export const SUB_FIELDS = {
  VOLUMETRY_INTEGRATION_DIMENSIONS: [
    'VOLUMETRY_INTEGRATION_HEIGHT',
    'VOLUMETRY_INTEGRATION_LENGTH',
    'VOLUMETRY_INTEGRATION_WIDTH'
  ],
  VOLUMETRY_MEASURED_DIMENSIONS: [
    'VOLUMETRY_MEASURED_HEIGHT',
    'VOLUMETRY_MEASURED_LENGTH',
    'VOLUMETRY_MEASURED_WIDTH'
  ]
};

const REPORT_FIELDS = {
  RECIPIENT: [
    {
      label: 'customReport.categories.recipient.fields.address',
      value: 'ADDRESS'
    },
    {
      label: 'customReport.categories.recipient.fields.city',
      value: 'CITY'
    },
    {
      label: 'customReport.categories.recipient.fields.state',
      value: 'STATE'
    },
    {
      label: 'customReport.categories.recipient.fields.recipientName',
      value: 'NAME'
    },
    {
      label: 'customReport.categories.recipient.fields.zipCode',
      value: 'ZIP'
    }
  ],
  DATES: [
    {
      label: 'customReport.categories.dates.fields.creationDate',
      value: 'CREATION_DATE'
    },
    {
      label: 'customReport.categories.dates.fields.deliveryDate',
      value: 'DELIVERY_DATE'
    },
    {
      label: 'customReport.categories.dates.fields.deliveryEstimate',
      value: 'DELIVERY_ESTIMATE'
    },
    {
      label: 'customReport.categories.dates.fields.pickUpDate',
      value: 'PICKUP_DATE'
    },
    {
      label: 'customReport.categories.dates.fields.returnDate',
      value: 'RETURN_DATE'
    },
    {
      label: 'customReport.categories.dates.fields.returnEstimate',
      value: 'RETURN_ESTIMATE'
    }
  ],
  STATUS: [
    {
      label: 'customReport.categories.status.fields.currentStatus',
      value: 'CURRENT_STATUS'
    },
    {
      label: 'customReport.categories.status.fields.lastStatusUpdate',
      value: 'LAST_STATUS_UPDATE'
    }
  ],
  PACKAGE_DIRECTION: [
    {
      label: 'customReport.categories.packageDirection.fields.directionType',
      value: 'DIRECTION'
    },
    {
      label: 'customReport.categories.packageDirection.fields.returnReason',
      value: 'REASON'
    }
  ],
  PACKAGE_INFORMATION: [
    {
      label: 'customReport.categories.packageInformation.fields.nfeNumber',
      value: 'NFE_NUMBER'
    },
    {
      label: 'customReport.categories.packageInformation.fields.nfeKey',
      value: 'NFE_KEY'
    },
    {
      label: 'customReport.categories.packageInformation.fields.nfeGoodValues',
      value: 'NFE_GOOD_VALUES'
    },
    {
      label:
        'customReport.categories.packageInformation.fields.volumetryIntegrationWeight',
      value: 'VOLUMETRY_INTEGRATION_WEIGHT'
    },
    {
      label:
        'customReport.categories.packageInformation.fields.volumetryIntegrationDimensions',
      value: 'VOLUMETRY_INTEGRATION_DIMENSIONS'
    },
    {
      label:
        'customReport.categories.packageInformation.fields.volumetryMeasuredWeight',
      value: 'VOLUMETRY_MEASURED_WEIGHT'
    },
    {
      label:
        'customReport.categories.packageInformation.fields.volumetryMeasuredDimensions',
      value: 'VOLUMETRY_MEASURED_DIMENSIONS'
    }
  ],
  COMPANY_INFORMATION: [
    {
      label: 'customReport.categories.companyInformation.fields.companyCnpj',
      value: 'COMPANY_CNPJ'
    }
  ]
};

export default REPORT_FIELDS;
