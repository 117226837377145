import { format, parseISO } from 'date-fns';
import { getI18n } from 'react-i18next';

const formatPackageCreationDate = dateIso => {
  const date = parseISO(dateIso);
  const i18n = getI18n();
  const t = i18n.getFixedT(null, 'unifiedPickup');

  return t('packageSelection.creationDate.format', {
    day: format(date, 'd'),
    month: format(date, 'MMMM')
  });
};

export default formatPackageCreationDate;
