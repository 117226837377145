import React from 'react';
import IntegrationTinyTutorial from '../components/integrator-tiny-tutorial.component';
import IntegratorBlingTutorial from '../components/integrator-bling-tutorial.component';
import WakeTutorial from '../components/wake/wake-tutorial.component';

const DefaultValue = () => {
  return <></>;
};

const tutorialsList = {
  tiny: IntegrationTinyTutorial,
  bling: IntegratorBlingTutorial,
  wake: WakeTutorial,
  default: DefaultValue
};

export default tutorialsList;
