import { InputAdornment, TextField } from '@material-ui/core';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const MonetarySymbolAdornment = () => {
  const { t } = useTranslation('packages');
  return (
    <InputAdornment position="start">
      {t('create.invoice.monetarySymbol')}
    </InputAdornment>
  );
};

const TotalValueField = () => {
  const { t } = useTranslation('packages');

  const validate = useCallback(
    newValue => {
      if (!newValue) return t('create.errorMessages.requiredTotalValue');

      return undefined;
    },
    [t]
  );

  const [field, meta] = useField({
    name: 'totalValue',
    validate
  });

  const { name, onBlur, onChange, value = '' } = field;
  const { error, touched } = meta;
  const hasError = Boolean(error) && touched;

  return (
    <TextField
      error={hasError}
      fullWidth
      helperText={hasError && error}
      id={name}
      inputProps={{
        'data-testid': `${name}-field`,
        inputMode: 'numeric',
        max: Number.MAX_SAFE_INTEGER,
        min: 0,
        step: 0.01
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        onBlur,
        startAdornment: <MonetarySymbolAdornment />
      }}
      label={t('create.invoice.totalValue')}
      name={name}
      onChange={onChange}
      required
      type="number"
      value={value}
      variant="outlined"
    />
  );
};

export default TotalValueField;
