import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { propTypes as warehousesProps } from '../../proptypes';
import WarehousesEmptyResult from '../warehouses-empty-result';
import WarehousesList from '../warehouses-list';
import GenericError from '../../../components/generic-error';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    minHeight: spacing(14),
    [breakpoints.up('sm')]: {
      minHeight: spacing(40)
    }
  }
}));

/**
 * WarehousesListWrapper is a wrapper component that adds a loading capability
 * before the warehouses list and pagination
 * @param {object} data
 * @param {boolean} isLoading
 * @returns {*}
 * @constructor
 */
const WarehousesListWrapper = ({ data, isLoading, error }) => {
  const styles = useStyles();
  if (isLoading)
    return (
      <Box
        pt={1}
        alignItems="center"
        className={styles.root}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <CircularProgress data-testid="loading" />
      </Box>
    );
  const showEmptyScreen = data?.length === 0 && !error;
  const wrap = component => (
    <Box
      pt={1}
      alignItems={showEmptyScreen ? 'flex-start' : 'center'}
      className={styles.root}
      display="flex-start"
      flexDirection="column"
      justifyContent={showEmptyScreen ? 'flex-start' : 'flex-start'}
    >
      {component}
    </Box>
  );
  if (showEmptyScreen) return wrap(<WarehousesEmptyResult />);
  if (error) {
    return wrap(<GenericError />);
  }
  return wrap(<WarehousesList data={data} />);
};

WarehousesListWrapper.propTypes = {
  data: PropTypes.arrayOf(warehousesProps),
  isLoading: PropTypes.bool,
  error: PropTypes.bool
};

WarehousesListWrapper.defaultProps = {
  data: [],
  isLoading: true,
  error: false
};

export default WarehousesListWrapper;
