import * as Sentry from '@sentry/browser';

const handler = (
  level = Sentry.Severity.Errorm,
  error,
  data = null,
  tags: {}
) => {
  Sentry.configureScope(scope => {
    scope.setLevel(level);
    Sentry.addBreadcrumb({ data });
    Sentry.captureException(error, tags);
  });
};

const exceptionHandler = {
  warning: (...args) => handler(Sentry.Severity.Warning, ...args),
  error: (...args) => handler(Sentry.Severity.Error, ...args)
};
export default exceptionHandler;
