import { useQueryParams } from '@loggi/components/src/one/hooks';
import { Box, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import SchedulingEmptyFilterResult from '../scheduling-empty-filter-result';
import SchedulingEmptyResult from '../scheduling-empty-result';
import SchedulingListPagination from '../scheduling-list-pagination';
import SchedulingModel from '../schedulings-list/scheduling-model';
import SchedulingsListTable from '../schedulings-list-table';

/**
 * SchedulingListWrapper is a wrapper component that adds a loading capability
 * before the scheduling list and pagination
 * @param {object} data
 * @param {number} count
 * @param {boolean} isLoading
 * @returns {*}
 * @constructor
 */
const SchedulingListWrapper = ({ data, count, isLoading }) => {
  const queryParams = useQueryParams();
  const hasFilterApplied = Object.keys(queryParams).length > 0;
  const dataIsEmpty = data.length === 0;

  return (
    <Box
      alignItems="center"
      minHeight={{ sm: 14, md: 40 }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {isLoading && dataIsEmpty ? (
        <CircularProgress data-testid="loading" />
      ) : (
        <>
          {dataIsEmpty && hasFilterApplied && <SchedulingEmptyFilterResult />}
          {dataIsEmpty && !hasFilterApplied && <SchedulingEmptyResult />}
          {!dataIsEmpty && (
            <>
              <SchedulingsListTable data={data} />
              <Box alignSelf="flex-end" my={2}>
                <SchedulingListPagination count={count} isLoading={isLoading} />
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

SchedulingListWrapper.propTypes = {
  count: PropTypes.number,
  data: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.arrayOf(SchedulingModel)
  ]),
  isLoading: PropTypes.bool
};

SchedulingListWrapper.defaultProps = {
  count: 0,
  data: [],
  isLoading: true
};

export default SchedulingListWrapper;
