import React from 'react';
import { ListItem, ListItemText, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

function PackagesListItem({ recipientName, trackingKey }) {
  return (
    <ListItem divider style={{ padding: 0 }}>
      <Box width="100%" p={3}>
        <ListItemText primary={recipientName} secondary={trackingKey} />
      </Box>
    </ListItem>
  );
}

PackagesListItem.propTypes = {
  trackingKey: PropTypes.string.isRequired,
  recipientName: PropTypes.string
};

PackagesListItem.defaultProps = {
  recipientName: ''
};

export default PackagesListItem;
