import { Box, Divider, Hidden } from '@material-ui/core';
import React from 'react';

import InstructionsSection from './upload/instructions-section';
import ModelSection from './upload/model-section';
import UploadDropzone from './upload/upload-dropzone.component';

const UploadSpreadsheet = () => (
  <Box data-testid="upload-spreadsheet">
    <Box mb={{ xs: 3, sm: 5 }}>
      <UploadDropzone />
    </Box>
    <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
      <Box flex={1} mr={{ xs: 0, sm: 3 }}>
        <ModelSection />
      </Box>
      <Hidden smUp>
        <Box my={3}>
          <Divider />
        </Box>
      </Hidden>
      <Box flex={1}>
        <InstructionsSection />
      </Box>
    </Box>
  </Box>
);

export default UploadSpreadsheet;
