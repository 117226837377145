import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(({ typography }) => ({
  skeleton: {
    borderRadius: typography.pxToRem(4)
  },
  title: {
    width: typography.pxToRem(205)
  }
}));

const NewRecipientSectionSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Box
        className={classes.title}
        pb={3}
        data-testid="new-recipient-section-skeleton"
      >
        <Typography variant="h6">
          <Skeleton className={classes.skeleton} />
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        pb={{ xs: 4, sm: 6 }}
      >
        <Box flex="1" pr={{ sm: 5 }}>
          <Skeleton className={classes.skeleton} variant="rect" height={52} />
        </Box>
        <Box flex="1" pt={{ xs: 4, sm: 0 }}>
          <Box width={{ xs: '100%', sm: '60%' }}>
            <Skeleton className={classes.skeleton} variant="rect" height={52} />
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
        <Box flex="1" pr={{ sm: 5 }}>
          <Skeleton className={classes.skeleton} variant="rect" height={52} />
        </Box>
        <Box flex="1" display="flex" pt={{ xs: 4, sm: 0 }}>
          <Box flex="1" pr={{ xs: 2, sm: 5 }}>
            <Skeleton className={classes.skeleton} variant="rect" height={52} />
          </Box>
          <Box flex="1" mt={1.5}>
            <Skeleton className={classes.skeleton} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewRecipientSectionSkeleton;
