import {
  OneTemplate,
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components';
import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Toast from '@loggi/components/src/one/toast';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import PageTitle from '../../page-title.component';
import CodeIntegrator from '../components/code-integrator.component';
import integratorListApi from '../services/integrator-api';
import tutorialsList from '../data/tutorials-list';
import customPageList from '../data/custom-page-list';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  codeIntegrator: {
    [breakpoints.only('xs')]: {
      marginBottom: spacing(5)
    }
  }
}));

export default function IntegratorPage({ integrator }) {
  const { companyId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const [enabled, setEnabled] = useState(false);
  const shouldRenderTutorial = integrator in tutorialsList;
  const shouldRenderCustomPage = integrator in customPageList;
  const TutorialComponent =
    integrator in tutorialsList
      ? tutorialsList[`${integrator}`]
      : tutorialsList.default;
  const CustomPageComponent =
    integrator in customPageList
      ? customPageList[`${integrator}`]
      : customPageList.default;

  useEffect(() => {
    integratorListApi
      .integratorsList(companyId)
      .then(response => {
        const verifyIntegrator = response.find(
          integration =>
            integration.integrator.name.toLowerCase() === integrator &&
            integration.active
        );
        setEnabled(Boolean(verifyIntegrator));
      })
      .catch(error => {
        enqueueSnackbar(error.message, {
          content: (key, message) => (
            <Toast id={key} message={message} type="error" />
          )
        });
      });
  }, [companyId, enqueueSnackbar, integrator]);

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Box
          alignItems="center"
          display="flex"
          flexWrap={{ xs: 'wrap', sm: 'initial' }}
        >
          <Box pr={3} flex={1}>
            <PageTitle title={`${integrator}.pageTitle`} />
          </Box>
        </Box>
      </OneTemplateSummary>
      <OneTemplateContent>
        <Box className={styles.codeIntegrator}>
          {!shouldRenderCustomPage && (
            <OneTemplateContent>
              <CodeIntegrator
                integrator={integrator}
                integratorEnabled={enabled}
              />
            </OneTemplateContent>
          )}
          {shouldRenderCustomPage && (
            <OneTemplateContent>
              <CustomPageComponent integrator={integrator} />
            </OneTemplateContent>
          )}
        </Box>
      </OneTemplateContent>
      {shouldRenderTutorial && (
        <OneTemplateContent>
          <TutorialComponent />
        </OneTemplateContent>
      )}
    </OneTemplate>
  );
}

IntegratorPage.propTypes = {
  integrator: PropTypes.string
};

IntegratorPage.defaultProps = {
  integrator: 'Default Integrator'
};
