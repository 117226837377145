import { makeStyles } from '@material-ui/core/styles';

const useSignupCompanyFormStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.up('sm')]: {
      paddingLeft: '25%',
      paddingRight: '25%'
    }
  }
}));

const useButtonLoadingStyle = makeStyles(({ palette }) => ({
  progress: {
    color: palette.common.white
  }
}));

export { useSignupCompanyFormStyles, useButtonLoadingStyle };
