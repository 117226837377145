import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import emptyPickupsIllustration from '@loggi/components/src/one/shared/images/packages-error.svg';

const useStyles = makeStyles(({ typography }) => ({
  image: {
    width: '100%',
    maxWidth: typography.pxToRem(155)
  }
}));

const GenericError = () => {
  const classes = useStyles();
  const { t } = useTranslation('management');

  return (
    <Box
      alignItems="center"
      data-testid="generic-error"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      m="auto"
      pt={{ xs: 5, sm: 0 }}
      textAlign="center"
      width={1}
      maxWidth={486}
    >
      <img
        className={classes.image}
        src={emptyPickupsIllustration}
        alt="Error"
      />
      <Box mt={3.5} mb={2}>
        <Box mb={2}>
          <Typography variant="subtitle1">
            <strong>{t('warehouses.error.title')}</strong>
          </Typography>
        </Box>
        <Typography variant="subtitle1">
          {t('warehouses.error.subtitle')}
        </Typography>
      </Box>
      <Button
        data-testid="reload"
        onClick={() => window.location.reload()}
        color="primary"
      >
        {t('warehouses.error.linkText')}
      </Button>
    </Box>
  );
};

export default GenericError;
