import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ShipperPhoneField = () => {
  const { t } = useTranslation('orderScheduling');
  const [field, meta] = useField({
    name: 'shipperPhone',
    validate: newValue =>
      !newValue ? t('create.errorMessages.requiredField') : undefined
  });

  const { name, onChange, onBlur, value = '' } = field;
  const { error, touched } = meta;
  const hasError = Boolean(error) && touched;

  return (
    <TextField
      error={hasError}
      fullWidth
      helperText={hasError && error}
      id={name}
      inputProps={{ 'data-testid': 'shipperPhone-field' }}
      label={t('create.shipper.phoneLabel')}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      type="tel"
      required
      value={value}
      variant="outlined"
    />
  );
};

export default ShipperPhoneField;
