import { Box, Divider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StatusIndicatorInvoicePayment from '@loggi/components/src/one/status-indicator/status-indicator-invoices-payment';

import DownloadButton from './download.button';

const InvoiceDetailsContent = ({
  invoice: {
    amount,
    bankSlipUrl,
    ctesZipUrl,
    endDate,
    interval,
    invoiceReportUrl,
    nfsesZipUrl,
    startDate,
    paymentStatus,
    debitNoteUrl
  }
}) => {
  const { t } = useTranslation(['invoiceDetails', 'payments']);

  const downloadMeta = [
    {
      id: 'bank_slip',
      title: t('buttonTitle.bankslip'),
      url: bankSlipUrl
    },
    {
      id: 'invoice_report',
      title: t('buttonTitle.invoice'),
      url: invoiceReportUrl
    },
    {
      id: 'nfe',
      title: t('buttonTitle.nfe'),
      url: nfsesZipUrl
    },
    {
      id: 'cte',
      title: t('buttonTitle.cte'),
      url: ctesZipUrl
    },
    {
      id: 'debit_note',
      title: t('buttonTitle.debit_note'),
      url: debitNoteUrl
    }
  ];

  const period = t('payments:invoices.invoicePeriod', {
    endDate,
    startDate
  });

  return (
    <>
      <Box pb={5}>
        <Box>
          <Typography variant="h4" data-testid="content-amount">
            <strong> {amount} </strong>
          </Typography>
        </Box>
        <Box
          py={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          data-testid="content-status"
        >
          <StatusIndicatorInvoicePayment
            endDate={endDate}
            paymentStatus={paymentStatus}
          />
        </Box>
        <Box pb={1}>
          <Typography variant="h6" data-testid="content-reference">
            {interval}
          </Typography>
        </Box>
        <Typography
          color="textSecondary"
          data-testid="content-period"
          variant="subtitle2"
        >
          {period}
        </Typography>
      </Box>
      <Divider />
      <Box pt={5}>
        <Box pb={1}>
          <Typography variant="subtitle1" data-testid="download-section-title">
            <strong> {t('downloadSection.title')} </strong>
          </Typography>
        </Box>
        <Typography
          color="textSecondary"
          data-testid="download-section-subtitle"
          variant="body1"
        >
          {t('downloadSection.subtitle')}
        </Typography>
        {downloadMeta.map(({ id, title, url }) => (
          <DownloadButton key={id} id={id} title={title} url={url} />
        ))}
      </Box>
    </>
  );
};

InvoiceDetailsContent.propTypes = {
  invoice: PropTypes.shape({
    invoiceId: PropTypes.number,
    amount: PropTypes.string,
    bankSlipUrl: PropTypes.string,
    ctesZipUrl: PropTypes.string,
    endDate: PropTypes.string,
    interval: PropTypes.string,
    invoiceReportUrl: PropTypes.string,
    nfsesZipUrl: PropTypes.string,
    startDate: PropTypes.string,
    paymentStatus: PropTypes.string,
    debitNoteUrl: PropTypes.string
  }).isRequired
};

export default InvoiceDetailsContent;
