import { AddressAliasField } from '@loggi/components/src/one/formik-fields';
import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useTranslation } from 'react-i18next';
import React from 'react';

const AddressAliasSection = () => {
  const { t } = useTranslation('management');
  const addressAliasField = 'returnAddressAlias';

  return (
    <>
      <Box pb={4}>
        <Typography variant="h6">
          <Box component="strong" fontWeight="fontWeightBold">
            {t('returnAddress.addressAliasSection.title')}
          </Box>
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ sm: 'center' }}
      >
        <Box flex="1" pr={{ sm: 5 }}>
          <AddressAliasField fieldName={addressAliasField} />
        </Box>
        <Box flex="2" pt={{ xs: 4, sm: 0 }}>
          <Alert severity="info" color="info">
            {t('returnAddress.addressAliasSection.alert')}
          </Alert>
        </Box>
      </Box>
    </>
  );
};

export default AddressAliasSection;
