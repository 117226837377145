import { useState } from 'react';
import { useSubscription } from '@loggi/components/src/one/useSubscription';
import notificationSubscriptionService from './notification-subscription.service';

const NotificationSubscription = ({ companyId, token }) => {
  const [pickupOrderScheduleId, setPickupOrderScheduleId] = useState(null);

  useSubscription(
    'order-schedule:created',
    id => {
      setPickupOrderScheduleId(id);
    },
    []
  );

  if (pickupOrderScheduleId !== null) {
    notificationSubscriptionService(
      companyId,
      String(pickupOrderScheduleId),
      token
    ).res(() => setPickupOrderScheduleId(null));
  }

  return null;
};

export default NotificationSubscription;
