import { useAmplifyAuth } from '@loggi/authentication-lib';
import { Box, Container, Divider, Hidden, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerBase from '@loggi/components/src/one/drawer-base/drawer-base';
import HelpDrawerContent from '@loggi/components/src/one/help-drawer-content/help-drawer-content';

import { useCurrentCompany } from '../auth/index';
import { ReactComponent as LogoLoggi } from '../assets/logo-loggi.svg';
import Menu from './menu.component';
import HelpCenterLink from './help-center-link.component';
import MobileMenu from './mobile-menu.component';
import AccountManagementLink from './account-management-link.component';
import AccountButtons from './account-buttons';

const DEFAULT_PADDING = 0;

const useHeaderStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    height: spacing(5),
    left: 0,
    position: 'absolute',
    right: 0,
    top: ({ isImpersonation, isDefaulter }) => {
      let spacingValue = DEFAULT_PADDING;

      if (isImpersonation && isDefaulter) {
        spacingValue = 15;
      } else if (isImpersonation || isDefaulter) {
        spacingValue = 7;
      }

      return spacing(spacingValue);
    },
    [breakpoints.up('md')]: {
      right: 'auto',
      width: '100vw'
    },
    [breakpoints.down('sm')]: {
      top: ({ isImpersonation, isDefaulter }) => {
        let spacingValue = DEFAULT_PADDING;

        if (isImpersonation && isDefaulter) {
          spacingValue = 20;
        } else if (isDefaulter) {
          spacingValue = 15;
        }

        return spacing(spacingValue);
      }
    },
    [breakpoints.down('xs')]: {
      top: ({ isImpersonation, isDefaulter }) => {
        let spacingValue = DEFAULT_PADDING;

        if (isImpersonation && isDefaulter) {
          spacingValue = 27;
        } else if (isDefaulter) {
          spacingValue = 20;
        }

        return spacing(spacingValue);
      }
    }
  },
  logoLink: {
    alignItems: 'center',
    color: palette.primary.contrastText,
    display: 'flex'
  },
  container: {
    display: 'flex'
  },
  signInContainer: {
    display: 'flex',
    paddingLeft: 120,
    paddingRight: 120,
    [breakpoints.down('md')]: {
      paddingLeft: 45,
      paddingRight: 45
    },
    [breakpoints.down('xs')]: {
      paddingLeft: 30,
      paddingRight: 30
    }
  },
  divider: {
    backgroundColor: ({ signInScreens }) =>
      signInScreens ? palette.primary.main : 'white',
    opacity: ({ signInScreens }) => (signInScreens ? 1 : 0.5)
  },
  signInColor: {
    color: palette.primary.main
  },
  accountButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px'
  }
}));

const HeaderContent = ({
  companyIdFromRoute,
  hasAuthenticatedUser,
  hasCompany,
  isMobile,
  signInScreens,
  isImpersonation,
  isDefaulter,
  isOutOfCoverage
}) => {
  const styles = useHeaderStyles({
    isImpersonation,
    signInScreens,
    isDefaulter
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Hidden smDown={hasAuthenticatedUser && !signInScreens}>
        <Box display="flex" mr={3}>
          <a className={styles.logoLink} data-testid="logo-loggi-id" href="/">
            <SvgIcon
              className={signInScreens && isMobile ? styles.signInColor : null}
              component={LogoLoggi}
            />
          </a>
        </Box>
      </Hidden>
      {hasAuthenticatedUser && hasCompany && !isOutOfCoverage && (
        <>
          <Hidden mdUp>
            <MobileMenu />
          </Hidden>
          <Hidden smDown>
            <Menu />
          </Hidden>
        </>
      )}
      <Box display="flex" ml="auto" alignItems="center">
        <Hidden smDown={hasAuthenticatedUser && !signInScreens}>
          <HelpCenterLink handleClickOpen={handleClickOpen} />
          <DrawerBase onClose={handleClose} open={open}>
            <HelpDrawerContent
              handleClose={handleClose}
              companyId={companyIdFromRoute}
            />
          </DrawerBase>
          {hasAuthenticatedUser && !isOutOfCoverage && (
            <>
              <Divider
                data-testid="profile-menu-divider-id"
                className={styles.divider}
                orientation="vertical"
                flexItem
              />
              {companyIdFromRoute ? <AccountManagementLink /> : <></>}
            </>
          )}
        </Hidden>
        {hasAuthenticatedUser && (
          <Box ml={2} className={styles.accountButtonsContainer}>
            <AccountButtons
              companyIdFromRoute={companyIdFromRoute}
              isOutOfCoverage={isOutOfCoverage}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

HeaderContent.propTypes = {
  companyIdFromRoute: PropTypes.number,
  hasAuthenticatedUser: PropTypes.bool.isRequired,
  hasCompany: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  signInScreens: PropTypes.bool.isRequired,
  isImpersonation: PropTypes.bool.isRequired,
  isDefaulter: PropTypes.bool.isRequired,
  isOutOfCoverage: PropTypes.bool
};

HeaderContent.defaultProps = {
  companyIdFromRoute: null,
  isOutOfCoverage: false
};

const Header = ({ isDefaulter, isOutOfCoverage }) => {
  const {
    state: { authenticatedUser, isImpersonation }
  } = useAmplifyAuth();
  const { pathname } = useLocation();
  const signInScreens =
    pathname.includes('cadastro') || pathname.includes('login');
  const accessDeniedScreen = pathname.includes('acesso-negado');
  const styles = useHeaderStyles({
    isImpersonation,
    signInScreens,
    isDefaulter
  });

  const match = useRouteMatch('/empresas/:companyId');
  const companyId = match?.params?.companyId;
  const currentCompany = useCurrentCompany();
  const currentCompanyId = currentCompany?.id;

  const hasCompany = accessDeniedScreen
    ? Boolean(currentCompanyId)
    : Boolean(companyId);
  const companyIdFromRoute = hasCompany ? parseInt(companyId, 10) : null;
  const hasAuthenticatedUser = Boolean(authenticatedUser);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box
      alignItems="center"
      className={styles.root}
      color="primary.contrastText"
      display="flex"
      mt={{ xs: 3, sm: 5 }}
    >
      {signInScreens ? (
        <Box width="100%" height="inherit" className={styles.signInContainer}>
          <HeaderContent
            companyIdFromRoute={companyIdFromRoute}
            hasAuthenticatedUser={hasAuthenticatedUser}
            hasCompany={hasCompany}
            isMobile={isMobile}
            signInScreens={signInScreens}
            isImpersonation={isImpersonation}
            isDefaulter={isDefaulter}
            isOutOfCoverage={isOutOfCoverage}
          />
        </Box>
      ) : (
        <Container
          component={Box}
          height="inherit"
          className={styles.container}
        >
          <HeaderContent
            companyIdFromRoute={companyIdFromRoute}
            hasAuthenticatedUser={hasAuthenticatedUser}
            hasCompany={hasCompany}
            isMobile={isMobile}
            signInScreens={signInScreens}
            isImpersonation={isImpersonation}
            isDefaulter={isDefaulter}
            isOutOfCoverage={isOutOfCoverage}
          />
        </Container>
      )}
    </Box>
  );
};

Header.propTypes = {
  isDefaulter: PropTypes.bool,
  isOutOfCoverage: PropTypes.bool
};

Header.defaultProps = {
  isDefaulter: false,
  isOutOfCoverage: false
};

export default Header;
