import { Box, Drawer, IconButton, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';

import PropTypes from 'prop-types';
import React from 'react';

const CustomDrawer = withStyles(({ breakpoints, spacing }) => ({
  paper: {
    width: '100%',
    [breakpoints.up('sm')]: {
      maxWidth: spacing(58),
      minWidth: spacing(58)
    }
  }
}))(Drawer);

const DrawerHeader = props => {
  const { onClose, secondaryAction, isSecondaryActionDisabled } = props;
  return (
    <Box pt={3} px={3} display="flex" justifyContent="space-between">
      <IconButton onClick={onClose} edge="start" data-testid="close-button">
        <CloseIcon color="primary" />
      </IconButton>
      {secondaryAction && (
        <IconButton
          disabled={isSecondaryActionDisabled}
          onClick={secondaryAction}
          edge="end"
          data-testid="Drawer-SecondaryAction"
          id="Drawer-SecondaryAction"
        >
          <GetAppIcon id="svg-download-button" color="inherit" />
        </IconButton>
      )}
    </Box>
  );
};

const PackageDrawerBase = props => {
  const {
    children,
    open,
    onClose,
    secondaryAction,
    isSecondaryActionDisabled
  } = props;

  return (
    <CustomDrawer
      data-testid="package-drawer-base"
      anchor="right"
      elevation={24}
      ModalProps={{
        hideBackdrop: false,
        disableScrollLock: false,
        BackdropProps: {
          invisible: false
        }
      }}
      SlideProps={{ onExited: onClose }}
      onClose={onClose}
      open={open}
    >
      <DrawerHeader
        onClose={onClose}
        secondaryAction={secondaryAction}
        isSecondaryActionDisabled={isSecondaryActionDisabled}
      />
      {children}
    </CustomDrawer>
  );
};

PackageDrawerBase.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  secondaryAction: PropTypes.func,
  isSecondaryActionDisabled: PropTypes.bool
};
PackageDrawerBase.defaultProps = {
  children: null,
  open: false,
  secondaryAction: null,
  isSecondaryActionDisabled: false
};

DrawerHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  secondaryAction: PropTypes.func,
  isSecondaryActionDisabled: PropTypes.bool
};
DrawerHeader.defaultProps = {
  secondaryAction: null,
  isSecondaryActionDisabled: false
};

export default PackageDrawerBase;
