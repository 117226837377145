import PropTypes from 'prop-types';

export const volumeShape = PropTypes.shape({
  alias: PropTypes.string,
  height_cm: PropTypes.string,
  length_cm: PropTypes.string,
  quantity: PropTypes.string,
  weight_g: PropTypes.string,
  width_cm: PropTypes.string
});

/**
 * This is a PropTypes shape that represents the PickupOrderScheduling entity
 */
const SchedulingModel = PropTypes.shape({
  amount: PropTypes.number,
  createdAt: PropTypes.string,
  endTime: PropTypes.string,
  id: PropTypes.number,
  modalRestrictions: PropTypes.arrayOf(PropTypes.string),
  origin: PropTypes.string,
  startTime: PropTypes.string,
  status: PropTypes.string,
  updatedAt: PropTypes.string,
  volumes: PropTypes.arrayOf(volumeShape)
});

export default SchedulingModel;
