import { TextField } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const COMPLEMENT_MAX_LENGTH = 80;

const ComplementField = ({ fieldName }) => {
  const { t } = useTranslation('one');
  const { values, setFieldValue } = useFormikContext();
  const noComplementFieldName = `no${fieldName}`;
  const noComplementValue = values[noComplementFieldName];

  const validate = useCallback(
    newValue => {
      if (noComplementValue) return undefined;
      if (!newValue) return t('complementField.errorMessages.requiredField');
      if (newValue && newValue.length > COMPLEMENT_MAX_LENGTH)
        return t('complementField.errorMessages.maxComplement', {
          maxLength: COMPLEMENT_MAX_LENGTH
        });

      return undefined;
    },
    [noComplementValue, t]
  );
  const [field, meta] = useField({ name: fieldName, validate });
  const { name, onBlur, onChange, value = '' } = field;
  const { error, touched } = meta;
  const hasError = Boolean(error) && touched;

  useEffect(() => {
    if (value !== '' && noComplementValue !== false)
      setFieldValue(noComplementFieldName, false, false);
  }, [noComplementFieldName, noComplementValue, setFieldValue, value]);

  return (
    <TextField
      disabled={noComplementValue}
      error={hasError}
      fullWidth
      helperText={hasError && error}
      id={name}
      inputProps={{ 'data-testid': `${name}-field`, 'data-hj-allow': true }}
      label={t('complementField.label')}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      variant="outlined"
    />
  );
};

ComplementField.propTypes = {
  fieldName: PropTypes.string.isRequired
};

export default ComplementField;
