import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserProfileButton from './user-profile/user-profile-button.component';
import CompaniesListButton from './companies-list/companies-list-button.component';
import getCompaniesList from './companies-list/service/get-companies-list';

const AccountButtons = ({ companyIdFromRoute, isOutOfCoverage }) => {
  const { pathname } = useLocation();
  const hasCompanyFromRoute = Boolean(companyIdFromRoute);
  const [indexOfSelectedCompany, setindexOfSelectedCompany] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [shouldHideCompanyDetails, setShouldHideCompanyDetails] = useState(
    false
  );
  const signUpFlow = pathname.includes('cadastro');
  const shouldRetrieveCompanies = hasCompanyFromRoute || signUpFlow;

  useEffect(() => {
    if (shouldRetrieveCompanies) {
      getCompaniesList()
        .then(companiesList => {
          setCompanies(companiesList);
        })
        .catch(() => {
          setCompanies([]);
        });
    }
  }, [shouldRetrieveCompanies]);

  useEffect(() => {
    if (hasCompanyFromRoute && companies) {
      const indexOfCompanyFromRoute = companies.findIndex(
        company => company.id === companyIdFromRoute
      );

      const isReadOnlyImpersonation = indexOfCompanyFromRoute < 0;

      if (isReadOnlyImpersonation) {
        setShouldHideCompanyDetails(true);
      } else {
        setShouldHideCompanyDetails(false);
        setindexOfSelectedCompany(indexOfCompanyFromRoute);
      }
    }
  }, [companyIdFromRoute, companies, hasCompanyFromRoute]);

  const selectedCompany = companies?.[indexOfSelectedCompany];

  const hasCompanyDetails =
    Boolean(selectedCompany) &&
    Boolean(selectedCompany.shared_name || selectedCompany.name) &&
    Boolean(selectedCompany.cnpj);

  const hidePersonIcon =
    hasCompanyDetails && !shouldHideCompanyDetails && !signUpFlow;

  return (
    <>
      {!signUpFlow && (
        <CompaniesListButton
          hasCompanyDetails={hasCompanyDetails}
          shouldHideCompanyDetails={shouldHideCompanyDetails}
          selectedCompany={selectedCompany}
          hasCompanyFromRoute={hasCompanyFromRoute}
          companies={companies}
          isOutOfCoverage={isOutOfCoverage}
        />
      )}
      <UserProfileButton
        signUpFlow={signUpFlow}
        hidePersonIcon={hidePersonIcon}
        isOutOfCoverage={isOutOfCoverage}
      />
    </>
  );
};

AccountButtons.propTypes = {
  companyIdFromRoute: PropTypes.number,
  isOutOfCoverage: PropTypes.bool
};

AccountButtons.defaultProps = {
  companyIdFromRoute: null,
  isOutOfCoverage: false
};

export default AccountButtons;
