import StatusIndicatorTag from '@loggi/components/src/one/status-indicator/status-indicator-tag';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { getI18n } from 'react-i18next';

const StatusIndicatorScheduling = props => {
  const i18n = getI18n();
  const t = i18n.getFixedT(null, 'scheduling');
  const { status } = props;
  const { palette } = useTheme();

  const statusMap = {
    cancelled: {
      color: palette.error.main,
      label: t('schedulingStatuses.cancelled')
    },
    dispatched: {
      color: palette.success.main,
      label: t('schedulingStatuses.dispatched')
    },
    partially_dispatched: {
      color: palette.success.dark,
      label: t('schedulingStatuses.partiallyDispatched')
    },
    waiting_pickup: {
      color: palette.primary.main,
      label: t('schedulingStatuses.waitingPickup')
    },
    waiting_volume: {
      color: palette.primary.main,
      label: t('schedulingStatuses.waitingPickup')
    },
    waiting_confirmation: {
      color: palette.primary.main,
      label: t('schedulingStatuses.waitingPickup')
    },
    failed_to_schedule: {
      color: palette.error.main,
      label: t('schedulingStatuses.cancelled')
    }
  };

  return <StatusIndicatorTag statusMap={statusMap} status={status} />;
};

StatusIndicatorScheduling.propTypes = {
  status: PropTypes.string.isRequired
};

export default StatusIndicatorScheduling;
