const INVITE_BTN_ID = 'invite-btn-id';
const UPDATE_BTN_ID = 'update-btn-id';
const NAME_INPUT_ID = 'name-input-id';
const EMAIL_INPUT_ID = 'email-input-id';
const ERROR_CODES = {
  ALREADY_INVITED: 6
};
const INVITE_USER_PROGRESS_BUTTON_TEST_ID = 'invite-user-progress-btn-id';
const UPDATE_USER_PROGRESS_BUTTON_TEST_ID = 'update-user-progress-btn-id';
const ROLES = {
  OWNER: { NAME: 'OWNER', VALUE: 1 },
  ADMIN: { NAME: 'ADMIN', VALUE: 2 },
  REGULAR: { NAME: 'REGULAR', VALUE: 3 },
  SUPPORT: { NAME: 'SUPPORT', VALUE: 6 },
  OPERATOR: { NAME: 'OPERATOR', VALUE: 7 },
  FINANCE: { NAME: 'FINANCE', VALUE: 8 },
  OWNER_LARGE: { NAME: 'OWNER_LARGE', VALUE: 9 },
  MANAGER_LARGE: { NAME: 'MANAGER_LARGE', VALUE: 10 },
  ADMIN_LARGE: { NAME: 'ADMIN_LARGE', VALUE: 11 },
  OPERATOR_LARGE: { NAME: 'OPERATOR_LARGE', VALUE: 12 }
};

export {
  INVITE_BTN_ID,
  UPDATE_BTN_ID,
  ROLES,
  INVITE_USER_PROGRESS_BUTTON_TEST_ID,
  UPDATE_USER_PROGRESS_BUTTON_TEST_ID,
  NAME_INPUT_ID,
  EMAIL_INPUT_ID,
  ERROR_CODES
};
