import { AppErrorBoundary } from '@loggi/components/src/one';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { setDefaultOptions } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useFeature } from '@loggi/components/src/one/remote-config';
import Create from './order-scheduling/create';
import UnifiedPickup from './unified-pickup/unified-pickup';
import PackagesSelection from './unified-pickup/package-selection/unified-pickup-packages-selection.component';
import { routes } from './unified-pickup/constants';

setDefaultOptions({ locale: ptBR });

const App = () => {
  const isPickupsUnifiedWithDynamic = useFeature('enable_unified_pickups');

  return (
    <AppErrorBoundary
      beforeCapture={scope => {
        scope.setTag('app.region', 'pickups');
      }}
    >
      <Switch>
        <Route
          exact
          path={routes.editRecurringPickup.path}
          component={UnifiedPickup}
        />
        <Route exact path={routes.unifiedPickup.path}>
          {isPickupsUnifiedWithDynamic ? <UnifiedPickup /> : <Create />}
        </Route>
        <Route
          exact
          path={routes.addPackage.path}
          component={PackagesSelection}
        />
      </Switch>
    </AppErrorBoundary>
  );
};

export default App;
