import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatDay, formatHour } from '@loggi/components/src/one/date-utils';
import PropTypes from 'prop-types';

const PickupOrderScheduleInfo = props => {
  const orderIdBoxStyles = makeStyles({
    fontColor: {
      mixBlendMode: 'screen',
      position: 'relative',
      color: 'initial',
      '&:before': {
        mixBlendMode: 'color-burn',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        content: '""'
      }
    }
  });
  const { t } = useTranslation('pickupOrderSchedulePackages');
  const {
    packagesCount,
    pickupOrderScheduleStartTime,
    pickupOrderScheduleEndTime,
    pickupOrderScheduleAddress,
    pickupOrderScheduleId
  } = props;

  const address = () => {
    let fullAddress = pickupOrderScheduleAddress.full_address;

    if (pickupOrderScheduleAddress.address_complement) {
      fullAddress = `${fullAddress}, ${
        pickupOrderScheduleAddress.address_complement
      }`;
    }

    return fullAddress;
  };

  return (
    <>
      <Box>
        <Typography>{t('header.title')}</Typography>
      </Box>
      <Box pt={1}>
        <Typography variant="h4">
          <strong>
            {t('header.packageCountTitle', { count: packagesCount })}
          </strong>
        </Typography>
      </Box>

      <Box pt={2.5}>
        <Typography variant="h6">
          <strong>
            {t('header.orderScheduleDate', {
              day: formatDay(pickupOrderScheduleStartTime),
              start: formatHour(pickupOrderScheduleStartTime),
              end: formatHour(pickupOrderScheduleEndTime)
            })}
          </strong>
        </Typography>
      </Box>

      <Box pt={1}>
        <Typography>{address()}</Typography>
      </Box>

      <Box display="flex" pt={2.5}>
        <Box
          bgcolor="common.white"
          borderRadius={50}
          px={1.5}
          py={1}
          className={orderIdBoxStyles().fontColor}
        >
          <Typography variant="caption">
            <strong>
              {t('header.pickupOrderScheduleId.label')}: {pickupOrderScheduleId}
            </strong>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

PickupOrderScheduleInfo.propTypes = {
  packagesCount: PropTypes.number.isRequired,
  pickupOrderScheduleStartTime: PropTypes.string.isRequired,
  pickupOrderScheduleEndTime: PropTypes.string.isRequired,
  pickupOrderScheduleAddress: PropTypes.objectOf(
    PropTypes.shape({
      full_address: PropTypes.string,
      address_complement: PropTypes.string
    })
  ).isRequired,
  pickupOrderScheduleId: PropTypes.number.isRequired
};

export default PickupOrderScheduleInfo;
