import * as Sentry from '@sentry/react';
import { loggiWebApi } from '@loggi/authentication-lib';

const mapErrorToSentry = (companyId, error) => {
  const scope = new Sentry.Scope();
  scope.setContext('companyId', companyId);
  Sentry.captureException(error);
};

const getCompanyData = companyId =>
  loggiWebApi
    .url(`/one/api/accounts/v1/companies/${companyId}/`)
    .get()
    .json()
    .catch(error => {
      mapErrorToSentry(companyId, error);
      throw error;
    });

const getHierarchyChildren = companyId =>
  loggiWebApi
    .url(`/one/api/players/v1/companies/${companyId}/hierarchy:children/`)
    .get()
    .json()
    .catch(error => {
      mapErrorToSentry(companyId, error);
      throw error;
    });

export { getCompanyData, getHierarchyChildren };
