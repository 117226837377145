import PICKUP_RECEIPT_API_ENDPOINTS from '../constants';
import pickupReceiptApi from '../pickup-receipt-api';

const receiptOrderScheduleApi = {
  get: pickupOrderScheduleHash => {
    return pickupReceiptApi
      .url(
        PICKUP_RECEIPT_API_ENDPOINTS.receiptOrderSchedule(
          pickupOrderScheduleHash
        )
      )
      .accept('application/json')
      .content('application/json')
      .get();
  }
};

export default receiptOrderScheduleApi;
