import React from 'react';
import { useTranslation } from 'react-i18next';

import { maxWeightInGrams, minWeightInGrams } from '../constants';
import BaseVolumeField from './base-volume-field';

const WeightField = () => {
  const { t } = useTranslation('packages');

  const validate = value => {
    if (value !== 0 && !value) return t('create.errorMessages.requiredWeight');

    if (value < minWeightInGrams)
      return t('create.errorMessages.invalidMeasurement');

    if (value > maxWeightInGrams)
      return t('create.errorMessages.maxWeight', { maxWeightInGrams });

    return null;
  };

  return (
    <BaseVolumeField
      adornmentText={t('create.volumetry.grams_unit')}
      fieldName="weight"
      label={t('create.volumetry.labelWeight')}
      validate={validate}
    />
  );
};

export default WeightField;
