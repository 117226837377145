import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import emptyPickupsIllustration from '@loggi/components/src/one/shared/images/packages-error.svg';

const useStyles = makeStyles(({ typography }) => ({
  title: {
    fontWeight: typography.fontWeightBold
  },
  image: {
    width: '100%',
    maxWidth: typography.pxToRem(155)
  }
}));

const PackagesSearchError = () => {
  const classes = useStyles();

  const { t } = useTranslation('packages');

  return (
    <Box
      alignItems="center"
      marginY={2}
      className={classes.container}
      data-testid="empty-state-wrapper"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      m="auto"
      textAlign="center"
      width={1}
      maxWidth={486}
      role="alert"
    >
      <img
        className={classes.image}
        src={emptyPickupsIllustration}
        alt="Truck"
      />
      <Box mt={3.5} mb={2} marginX={{ xs: 2 }}>
        <Box mb={2}>
          <Typography className={classes.title} variant="subtitle1">
            {t('list.ErrorSearchPackages.title')}
          </Typography>
        </Box>
        <Typography variant="subtitle1" component="p">
          {t('list.ErrorSearchPackages.subtitle')}
        </Typography>
      </Box>
      <Button
        onClick={() => {
          window.location.reload();
        }}
        color="primary"
      >
        {t('list.ErrorSearchPackages.linkText')}
      </Button>
    </Box>
  );
};

export default PackagesSearchError;
