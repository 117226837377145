import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';

import {
  ListItem,
  ListItemText,
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

import managementRoutes from '../../../../management-routes';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    padding: spacing(3),
    [breakpoints.up('sm')]: {
      padding: spacing(3, 5)
    },
    '&:hover': {
      backgroundColor: palette.secondary.light,
      cursor: 'pointer'
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: palette.primary.light
    },
    '& .MuiListItemText-root': {
      marginTop: 0,
      marginBottom: 0
    }
  }
}));

const WarehousesListItem = ({ warehouseId, tradingName, warehouseAddress }) => {
  const { companyId } = useParams();

  const classes = useStyles();

  const PrimaryListItem = (
    <Box
      mb={1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="subtitle1" color="textPrimary">
        <strong>{tradingName}</strong>
      </Typography>
      <ArrowForwardIos fontSize="small" color="primary" />
    </Box>
  );

  const SecondaryListItem = (
    <Box>
      <Typography variant="subtitle2" color="textSecondary" noWrap>
        {warehouseAddress}
      </Typography>
      <Box pt={1}>
        <Typography variant="subtitle2" color="textSecondary">
          <strong> {`#${warehouseId}`} </strong>
        </Typography>
      </Box>
    </Box>
  );

  return (
    <ListItem
      data-testid="warehouse-list-item"
      className={classes.root}
      button
      component={Link}
      to={`${managementRoutes.addressList.url(companyId, warehouseId)}`}
    >
      <Box width={1}>
        <ListItemText
          disableTypography
          primary={PrimaryListItem}
          secondary={SecondaryListItem}
        />
      </Box>
    </ListItem>
  );
};

WarehousesListItem.propTypes = {
  warehouseId: PropTypes.string.isRequired,
  tradingName: PropTypes.string.isRequired,
  warehouseAddress: PropTypes.string.isRequired
};

export default WarehousesListItem;
