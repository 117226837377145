import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { maxLengthInCm, minLengthInCm } from '../constants';
import BaseVolumeField from './base-volume-field';

const LengthField = ({ index }) => {
  const { t } = useTranslation('orderScheduling');

  const validate = value => {
    if (value !== 0 && !value) return t('create.errorMessages.requiredField');

    if (value < minLengthInCm)
      return t('create.errorMessages.invalidMeasurement');

    if (value > maxLengthInCm)
      return t('create.errorMessages.maxLength', { maxLengthInCm });

    return null;
  };

  return (
    <BaseVolumeField
      adornmentText={t('create.volumeSection.centimeters_unit')}
      fieldName={`volumes.${index}.length`}
      label={t('create.volumeSection.labelLength')}
      validate={validate}
    />
  );
};

LengthField.propTypes = {
  index: PropTypes.number.isRequired
};

export default LengthField;
