import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import IconWrapper from '../icon-wrapper';

const SectionTitle = ({ children, icon }) => {
  return (
    <Typography variant="h5" component="h2">
      <Box display="flex" alignItems="center" mb={{ xs: 2, sm: 3 }}>
        <Box mr={{ xs: 2, sm: 3 }}>
          <IconWrapper>{icon}</IconWrapper>
        </Box>
        {children}
      </Box>
    </Typography>
  );
};

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired
};

export default SectionTitle;
