const EMAIL_INPUT_ID = 'email-input';
const BUTTON_ID = 'impersonation-btn';
const PROGRESS_BUTTON_ID = 'impersonation-progress-btn';
const AWS_CUSTOM_AUTH_CHALLENGE_NAME = 'CUSTOM_CHALLENGE';

export {
  BUTTON_ID,
  PROGRESS_BUTTON_ID,
  EMAIL_INPUT_ID,
  AWS_CUSTOM_AUTH_CHALLENGE_NAME
};
