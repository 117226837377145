import currency from 'currency.js';
import { format, toDate } from 'date-fns-tz';
import pt from 'date-fns/locale/pt';

export const formatCurrency = value =>
  currency(parseFloat(value), {
    symbol: 'R$ ',
    separator: '.',
    decimal: ','
  }).format();

export const formatDeliveryProofHTML = htmlString => {
  return htmlString
    .replace('align-items: center;', 'align-items: none;')
    .replace('width: 312px', 'width: 100%');
};

export const getDeliveryLocationDescriptionMap = () => {
  const packagesTranslationPath =
    'details.sections.deliveryDetails.locationDescriptionMap';

  return {
    INVALID: `${packagesTranslationPath}.invalid`,
    RECIPIENT: `${packagesTranslationPath}.recipient`,
    OTHER_RECIPIENT: `${packagesTranslationPath}.otherRecipient`,
    CONCIERGE: `${packagesTranslationPath}.concierge`,
    MAILBOX: `${packagesTranslationPath}.mailbox`
  };
};

export const getFormattedDate = rawDate => {
  return format(toDate(rawDate), "d 'de' MMMM", {
    locale: pt
  }).toLowerCase();
};

export const getFormattedDateAndTime = rawDate => {
  return format(toDate(rawDate), "d 'de' MMMM 'às' HH:mm", {
    locale: pt
  }).toLowerCase();
};

export default formatCurrency;
