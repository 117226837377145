import qs from 'qs';
import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQueryParams } from '@loggi/components/src/one/hooks';

const UserFilter = filterEmail => {
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const queryParams = useQueryParams();

  const stringifiedQueryParams = JSON.stringify(queryParams);

  const setFilter = useCallback(
    (filter, clearPagination = false) => {
      const params = JSON.parse(stringifiedQueryParams);

      if (clearPagination) {
        delete params.pageToken;
        delete params.pageSize; // To reset pagination when a filter is applied
      }
      params[filterEmail] = filter;

      const stringifiedParams = qs.stringify(params, {
        addQueryPrefix: true,
        arrayFormat: 'comma',
        encode: false,
        skipNulls: true
      });

      push(`${url}${stringifiedParams}`);
    },
    [filterEmail, url, push, stringifiedQueryParams]
  );

  return [queryParams[filterEmail], setFilter];
};

export default UserFilter;
