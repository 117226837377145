import Box from '@material-ui/core/Box';
import React from 'react';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const WarehousesEmptyResult = () => {
  const { t } = useTranslation('management');

  return (
    <Box width={1} p={5}>
      <Alert severity="error" color="error">
        {t('warehouses.emptyList.title')}
      </Alert>
    </Box>
  );
};

export default WarehousesEmptyResult;
