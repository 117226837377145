import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { Box, Button, Divider } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';

import RecipientSection from './recipient-section.component';
import RecipientSectionSkeleton from './recipient-section-skeleton.component';
import NewRecipientSectionSkeleton from './new-recipient-section-skeleton.component';
import PackageRecipientEditionModal from './package-recipient-edition-modal';
import NewRecipientSection from './new-recipient-section.component';
import packagesRoutes from '../packages-routes';
import { useRemoteConfig } from '../../firebase';

const useStyles = makeStyles(({ breakpoints }) => ({
  actionCol: {
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse'
    }
  }
}));

const FormSkeleton = () => {
  const { t } = useTranslation('packages');
  const styles = useStyles();

  return (
    <>
      <Box>
        <Box pb={3}>
          <RecipientSectionSkeleton />
        </Box>
        <Divider />
        <Box py={3}>
          <NewRecipientSectionSkeleton />
        </Box>
      </Box>
      <Box
        className={styles.actionCol}
        display="flex"
        justifyContent="flex-end"
      >
        <Box
          display="flex"
          justifyContent="center"
          mt={{ xs: 3, sm: 0 }}
          mr={{ sm: 2 }}
        >
          <Button disabled color="primary" size="large">
            {t('edition.cancelButton')}
          </Button>
        </Box>
        <Box width={{ xs: '100%', sm: 200 }}>
          <Button
            color="primary"
            disabled
            data-testid="package-recipient-edition-btn"
            size="large"
            fullWidth
            variant="contained"
          >
            {t('edition.confirmationButton')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

const PackageRecipientEditionContent = ({ data, isLoading, initialValues }) => {
  const { t } = useTranslation('packages');
  const styles = useStyles();
  const { companyId, trackingKey } = useParams();
  const { width, height, length } = data?.rawDimensions || {};
  const { value: fsValue } = useRemoteConfig(
    'enable_package_recipient_edition'
  );

  const enablePackageRecipientEdition = fsValue === 'true';

  const formInitialValues = initialValues || {
    recipientName: data?.recipientName,
    recipientPhone: data?.recipientPhone,
    height,
    length,
    weight: data?.weightG,
    width,
    packageSlo: data?.slo,
    packageValue: data?.invoiceValue,
    pickupAddress: {
      description: data?.originPostalCode
    }
  };

  const [open, setOpen] = useState(false);
  const [newDestination, setNewDestination] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setNewDestination(null);
  };

  const handleSubmit = formValues => {
    const {
      packageAddress: { description, place_id: placeId },
      packageAddressComplement,
      recipientName,
      recipientPhone
    } = formValues;

    const destination = {
      address: description,
      complement: packageAddressComplement,
      placeId,
      recipientName,
      recipientPhone
    };
    setNewDestination(destination);
    setOpen(true);
  };

  return isLoading ? (
    <FormSkeleton />
  ) : (
    <>
      {open && (
        <PackageRecipientEditionModal
          open={open}
          handleClose={handleClose}
          oldPhone={formInitialValues?.recipientPhone}
          companyId={companyId}
          trackingKey={trackingKey}
          address={newDestination?.address}
          complement={newDestination?.complement}
          placeId={newDestination?.placeId}
          recipientName={newDestination?.recipientName}
          recipientPhone={newDestination?.recipientPhone}
          isPackageStatusAdded={data.isStatusAdded()}
          enablePackageRecipientEdition={enablePackageRecipientEdition}
        />
      )}
      <Formik
        initialValues={formInitialValues}
        // 'validateOnMount' is needed because we want the form
        // to be invalid on initial render, so the submit button
        // is disabled. The date fields also need this to work
        // properly.
        validateOnMount
        onSubmit={handleSubmit}
      >
        {({ isValid }) => {
          return (
            <Form data-testid="package-recipient-edition-form">
              <Box>
                <Box pb={3}>
                  <RecipientSection
                    recipientName={data.recipientName}
                    recipientPhone={data.recipientPhone}
                    destinationAddress={data.destinationAddress}
                  />
                </Box>
                <Divider />
                <Box pb={{ xs: 4, sm: 7 }}>
                  <NewRecipientSection
                    enablePackageRecipientEdition={
                      enablePackageRecipientEdition
                    }
                  />
                </Box>
              </Box>
              <Box
                className={styles.actionCol}
                display="flex"
                justifyContent="flex-end"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  mt={{ xs: 3, sm: 0 }}
                  mr={{ sm: 2 }}
                >
                  <Button
                    component={Link}
                    to={`${packagesRoutes.show.url(companyId, trackingKey)}`}
                    color="primary"
                    size="large"
                  >
                    {t('edition.cancelButton')}
                  </Button>
                </Box>
                <Box width={{ xs: '100%', sm: 200 }}>
                  <Button
                    color="primary"
                    disabled={!isValid}
                    data-testid="package-recipient-edition-btn"
                    size="large"
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    {t('edition.confirmationButton')}
                  </Button>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

PackageRecipientEditionContent.propTypes = {
  data: packageProps,
  isLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    width: PropTypes.number,
    weight: PropTypes.number,
    height: PropTypes.number,
    length: PropTypes.number,
    packageSlo: PropTypes.number,
    packageValue: PropTypes.number,
    nopackageAddressComplement: PropTypes.bool,
    packageAddress: PropTypes.shape({
      description: PropTypes.string,
      place_id: PropTypes.string
    }),
    packageAddressComplement: PropTypes.string,
    pickupAddress: PropTypes.shape({
      description: PropTypes.string
    }),
    recipientName: PropTypes.string,
    recipientPhone: PropTypes.string
  })
};

PackageRecipientEditionContent.defaultProps = {
  data: {},
  initialValues: null
};

export default PackageRecipientEditionContent;
