import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useTheme
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQueryParams, useCompany } from '@loggi/components/src/one/hooks';
import { formattedCNPJ } from '@loggi/components/src/one/utils';

import { FILTERS_NAMES } from '../../constants';

const MAIN_COMPANY_COUNT = 1;

const SelectAllOption = allSelected => {
  const { t } = useTranslation('packages');

  return (
    <MenuItem key="SelectAll" value="SelectAll">
      <Checkbox checked={allSelected} color="primary" />
      <ListItemText
        primary={
          <Typography noWrap>{t('filters.hierarchy.selectAll')}</Typography>
        }
      />
    </MenuItem>
  );
};

const MainCompanyOption = (mainCompanyData, hierarchyFilter) => {
  return (
    <MenuItem
      key={mainCompanyData.id.toString()}
      value={mainCompanyData.id.toString()}
    >
      <Checkbox
        checked={hierarchyFilter.some(
          childId => childId === mainCompanyData.id
        )}
        color="primary"
      />
      <ListItemText
        primary={
          <Typography noWrap>{formattedCNPJ(mainCompanyData.CNPJ)}</Typography>
        }
      />
    </MenuItem>
  );
};

const FilterHierarchy = ({ setChecked, onChange, hierarchyChildrenList }) => {
  const { t } = useTranslation('packages');
  const { spacing } = useTheme();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: spacing(42),
        marginTop: spacing(1),
        maxWidth: spacing(40),
        minWidth: spacing(30)
      }
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  };

  const { companyId } = useParams();
  const companyData = useCompany();
  const mainCompanyData = {
    id: companyId,
    CNPJ: companyData?.cnpj
  };

  const urlFilter = useQueryParams()[FILTERS_NAMES.SEARCH_COMPANIES];
  const parseFilter = filter => (filter?.length ? filter.split(',') : []);
  const [hierarchyFilter, setHierarchyFilter] = useState(
    parseFilter(urlFilter)
  );

  useEffect(() => {
    setHierarchyFilter(parseFilter(urlFilter));
    setChecked([]);
  }, [urlFilter, setChecked]);

  const selectAllCompanies = () => {
    const allCompaniesIdsList = hierarchyChildrenList.map(children =>
      children.child_id.toString()
    );
    allCompaniesIdsList.push(mainCompanyData.id.toString());
    setHierarchyFilter(allCompaniesIdsList);
  };
  const allSelected =
    hierarchyChildrenList.length + MAIN_COMPANY_COUNT ===
    hierarchyFilter.length;
  const handleBulkSelection = () => {
    const shouldUnselectAll = allSelected;
    const shouldSelectAll = !allSelected;

    if (shouldUnselectAll) {
      setHierarchyFilter([]);
      setChecked([]);
    } else if (shouldSelectAll) {
      selectAllCompanies();
    }
  };
  const handleToggle = checkboxValue => {
    if (checkboxValue.includes('SelectAll')) {
      handleBulkSelection();
    } else {
      setHierarchyFilter(checkboxValue);
    }
  };

  const getCNPJLabelFrom = id => {
    if (id.toString() === mainCompanyData.id.toString()) {
      return mainCompanyData.CNPJ ?? '';
    }
    const childObject = hierarchyChildrenList.find(
      child => child.child_id === parseInt(id, 10)
    );
    return childObject.child_cnpj;
  };

  const isNotSelectAllCheckbox = id => id !== 'SelectAll';
  const findCNPJById = id => {
    if (isNotSelectAllCheckbox(id)) {
      return getCNPJLabelFrom(id);
    }
    return '';
  };
  const renderSelectedLabel = companyChildrenId => {
    return companyChildrenId
      .map(childId => formattedCNPJ(findCNPJById(childId)))
      .join(', ');
  };

  const ChildrenCompanyOptions = () => {
    return hierarchyChildrenList.map(childObject => (
      <MenuItem
        key={childObject.child_id}
        value={childObject.child_id.toString()}
      >
        <Checkbox
          checked={hierarchyFilter.some(
            childId => parseInt(childId, 10) === childObject.child_id
          )}
          color="primary"
        />
        <ListItemText
          primary={
            <Typography noWrap>
              {formattedCNPJ(childObject.child_cnpj)}
            </Typography>
          }
        />
      </MenuItem>
    ));
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{t('filters.hierarchy.text')}</InputLabel>
      <Select
        id="filter-hierarchy-select-id"
        input={<OutlinedInput label={t('filters.hierarchy.text')} />}
        inputProps={{ 'data-testid': 'filter-hierarchy-select' }}
        MenuProps={MenuProps}
        labelId="filter-hierarchy-select-label"
        multiple
        onChange={event => handleToggle(event.target.value)}
        onClose={() => {
          const newFilter = Object();
          newFilter[FILTERS_NAMES.SEARCH_COMPANIES] = hierarchyFilter;
          onChange(newFilter);
        }}
        renderValue={renderSelectedLabel}
        value={hierarchyFilter}
      >
        {SelectAllOption(allSelected)}
        {mainCompanyData.CNPJ &&
          MainCompanyOption(mainCompanyData, hierarchyFilter)}
        {ChildrenCompanyOptions()}
      </Select>
    </FormControl>
  );
};

FilterHierarchy.propTypes = {
  setChecked: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  hierarchyChildrenList: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string })
  ).isRequired
};

export default FilterHierarchy;
