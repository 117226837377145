export const SCHEDULING_STATUSES = {
  CANCELLED: 'cancelled',
  DISPATCHED: 'dispatched',
  PARTIALLY_DISPATCHED: 'partially_dispatched',
  WAITING_PICKUP: 'waiting_pickup',
  WAITING_VOLUME: 'waiting_volume',
  WAITING_CONFIRMATION: 'waiting_confirmation',
  FAILED_TO_SCHEDULE: 'failed_to_schedule'
};
export const PICKUP_ORDER_DATE_FILTER_VALUES = {
  ALL_DAYS: 0,
  LAST_15_DAYS: 15,
  LAST_30_DAYS: 30,
  LAST_3_DAYS: 3,
  LAST_7_DAYS: 7
};
export const ORDER_BY_VALUES = {
  CREATED: 'created',
  STATUS: 'status'
};

export const DEFAULT_RECURRING_PICKUPS_LIST_PAGE_SIZE = 5;

export const CANCELLED_BY_VALUES = {
  SCHEDULE_CANCELLED_BY_SHIPPER: 'SCHEDULE_CANCELLED_BY_SHIPPER',
  SCHEDULE_CANCELLED_BY_ALLOCATION: 'SCHEDULE_CANCELLED_BY_ALLOCATION',
  SCHEDULE_CANCELLED_BY_TRACKING: 'SCHEDULE_CANCELLED_BY_TRACKING'
};

export const LIST_TYPE = {
  SCHEDULINGS: 'schedulings',
  RECURRING_PICKUPS: 'recurring'
};
