import React from 'react';
import { Box, SvgIcon, Container, useTheme } from '@material-ui/core';
import { ReactComponent as LogoLoggi } from '../assets/logo-loggi.svg';

const HeaderPickupOrderSchedulePackages = () => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      left={0}
      position="absolute"
      right={0}
      mt={7.75}
      id="header-aqui"
    >
      <Container component={Box} height="inherit">
        <Box display="flex" mr={3}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            role="button"
            tabIndex={0}
            style={{ color: theme.palette.primary.contrastText }}
            data-testid="logo-loggi-id"
          >
            <SvgIcon component={LogoLoggi} />
          </a>
        </Box>
      </Container>
    </Box>
  );
};

export default HeaderPickupOrderSchedulePackages;
