import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { propTypes as packageProps } from '@loggi/components/src/one/package-utils';
import PackageDetailsInvoiceSection from './package-details-invoice-section';
import PackageDetailsDimensionsSection from './package-details-dimensions-section';
import PackageDrawerBase from '../../package-drawer-base';
import FiscalDrawerContent from './fiscal-drawer-content';
import { formatCurrency } from '../utils';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  dimensionsSection: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: spacing(2),
    [breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    paddingBottom: spacing(3)
  }
}));

const PackageFiscalElementsSection = ({ data }) => {
  const [openFiscalDocumentsDrawer, setOpenFiscalDocumentsDrawer] = useState(
    false
  );
  const { t } = useTranslation('packages');

  const styles = useStyles();
  const isPackageMeasured = data.volumetricInfo?.measuredValues ?? null;

  let { invoiceKey, invoiceNumber, invoiceValue } = data;

  if (data.invoices) {
    if (data.invoices.length > 1) {
      return (
        <>
          <Divider />
          <Box display="flex" justifyContent="space-between" pt={3} pb={1}>
            <Typography variant="h6">
              <strong>{t('details.labels.contentInvoice')}</strong>
            </Typography>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                setOpenFiscalDocumentsDrawer(true);
              }}
            >
              {t('details.actions.seeFiscalDocuments')}
            </Button>
          </Box>
          <Box className={styles.dimensionsSection}>
            {isPackageMeasured && (
              <PackageDetailsDimensionsSection
                volumetricInfo={data.volumetricInfo}
                isMeasured
              />
            )}
            <Box pl={{ md: 8 }} pb={{ md: 2 }}>
              <PackageDetailsDimensionsSection
                isMeasured={false}
                volumetricInfo={data.volumetricInfo}
              />
            </Box>
            <Box pl={{ md: 8 }} pt={1}>
              <Typography variant="body2">
                <strong>{t('details.labels.numberOfFiscalDocuments')}</strong>
              </Typography>
              <Typography>{data.invoices.length}</Typography>
            </Box>
            <Box pl={{ md: 8 }} pt={1}>
              <Typography variant="body2">
                <strong>{t('details.labels.totalValue')}</strong>
              </Typography>
              <Typography>
                {formatCurrency(
                  data.invoices.reduce((accumulator, object) => {
                    return accumulator + parseFloat(object.totalValue);
                  }, 0)
                )}
              </Typography>
            </Box>
          </Box>
          <PackageDrawerBase
            open={openFiscalDocumentsDrawer}
            onClose={() => {
              setOpenFiscalDocumentsDrawer(false);
            }}
          >
            <FiscalDrawerContent invoices={data.invoices} />
          </PackageDrawerBase>
        </>
      );
    }

    if (data.invoices.length === 1) {
      invoiceKey = data.invoices[0].key;
      invoiceNumber = data.invoices[0].number;
      invoiceValue = data.invoices[0].totalValue;
    }
  }

  return (
    <>
      <Divider />
      <Box display={{ sm: 'flex' }} pt={3} pb={1}>
        <Typography variant="h6">
          <strong>{t('details.labels.contentInvoice')}</strong>
        </Typography>
      </Box>
      <PackageDetailsInvoiceSection
        invoiceKey={invoiceKey}
        invoiceNumber={invoiceNumber}
        invoiceValue={invoiceValue}
      />
      <Box className={styles.dimensionsSection}>
        {isPackageMeasured && (
          <PackageDetailsDimensionsSection
            volumetricInfo={data.volumetricInfo}
            isMeasured
          />
        )}
        <PackageDetailsDimensionsSection
          isMeasured={false}
          volumetricInfo={data.volumetricInfo}
        />
      </Box>
    </>
  );
};

PackageFiscalElementsSection.propTypes = {
  data: packageProps.isRequired
};

export default PackageFiscalElementsSection;
