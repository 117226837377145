import menuTranslationsPtBr from './menu/menu-translations-pt-br.json';
import returnAddressTranslationsPtBr from './addresses/return-address/return-address-translations-pt-br.json';
import warehousesListTranslationsPtBr from './addresses/warehouses/warehouses-list-translations-pt-br.json';
import addressListTranslationsPtBr from './addresses/address-list/address-list-translations-pt-br.json';
import integratorsGeneralTranslationsPtBr from './integrator/data/integrators-general-translations-pt-br.json';
import companyDataTranslationsPtBr from './company-data/company-data-translations-pt-br.json';
import integratorsTranslationsPtBr from './integrator/data/integrators-translations-pt-br.json';
import integratorsTutorialsTranslationsPtBr from './integrator/data/integrators-tutorials-translations-pt-br.json';
import urlFieldTranslationPtBr from './integrator/components/forms/url-field.translations.pt-br.json';

export default {
  'pt-BR': {
    management: {
      ...menuTranslationsPtBr,
      ...returnAddressTranslationsPtBr,
      ...warehousesListTranslationsPtBr,
      ...addressListTranslationsPtBr,
      ...integratorsGeneralTranslationsPtBr,
      ...companyDataTranslationsPtBr,
      ...integratorsTranslationsPtBr,
      ...integratorsTutorialsTranslationsPtBr,
      ...urlFieldTranslationPtBr
    }
  }
};
