import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function isUrlValid(url) {
  try {
    const newUrl = new URL(url);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
}

export const UrlField = ({ fieldName, disabled }) => {
  const { t } = useTranslation('management');
  const [field, meta] = useField({
    name: fieldName,
    validate: newValue => {
      let errorMessage = '';
      if (!newValue) {
        errorMessage = t('urlField.errorMessages.requiredField');
      } else if (!isUrlValid(newValue)) {
        errorMessage = t('urlField.errorMessages.invalid');
      }
      return errorMessage;
    }
  });

  const { name, onChange, onBlur, value = '' } = field;
  const { error, touched } = meta;
  const hasError = Boolean(error) && touched;

  return (
    <TextField
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      required
      value={value}
      variant="outlined"
      disabled={disabled}
      error={hasError}
      fullWidth
      helperText={hasError && error}
      id={name}
      inputProps={{ 'data-testid': `${name}-field` }}
      InputLabelProps={{ required: true }}
      label={t('urlField.label')}
    />
  );
};

UrlField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

UrlField.defaultProps = {
  disabled: false
};

export default UrlField;
