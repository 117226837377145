import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import marTheme from '@loggi/mar';
import {
  Box,
  Typography,
  makeStyles,
  Tooltip,
  IconButton,
  Divider
} from '@material-ui/core';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import { TRACKING_KEY_MAX_CHAR } from '../../constants';
import { truncateID } from '../packages-list-table/identifier-field';

const useStyles = makeStyles(({ palette, typography }) => ({
  copyIcon: {
    width: typography.pxToRem(16),
    height: typography.pxToRem(16)
  },
  copyIconButton: {
    boxShadow:
      '0px 11px 15px -7px rgba(190, 199, 214, 0.02), 0px 24px 38px 3px rgba(159, 171, 187, 0.03), 0px 9px 46px 8px rgba(72, 79, 95, 0.08)',
    background: palette.common.white,
    width: typography.pxToRem(32),
    height: typography.pxToRem(32)
  },
  trackingKey: {
    width: '100%',
    backgroundColor: palette.grey[50],
    borderColor: palette.grey[100]
  }
}));

/**
 * BulkCancellationListItem renders a static list item that only presents the data it receive
 * @componentType presentational
 * @param trackingKey
 * @param recipientName
 * @param recipientAddress
 * @param reason
 * @returns {*}
 */
const BulkCancellationListItem = ({
  trackingKey,
  recipientName,
  recipientAddress,
  reason
}) => {
  const { t } = useTranslation('packages');
  const classes = useStyles();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(trackingKey);
  };

  return (
    <>
      <Box display="flex" py={2.5} flexDirection={{ xs: 'column', sm: 'row' }}>
        <Box
          display="flex"
          flex={2}
          flexDirection="column"
          justifyContent="center"
          pb={{ xs: 3, sm: 0 }}
        >
          <Box display="flex">
            <Box
              px={2}
              py={1}
              bgcolor={marTheme.palette.grey[50]}
              borderRadius={5}
            >
              <Typography variant="body2">{reason}</Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flex={2} alignItems="center" px={{ sm: 2 }}>
          <Typography variant="body2">{recipientName}</Typography>
        </Box>
        <Box
          display="flex"
          flex={2}
          width={{ xs: 250, sm: 100 }}
          alignItems="center"
          pt={{ xs: 1, sm: 0 }}
          pb={{ xs: 3, sm: 0 }}
        >
          <Typography variant="caption" noWrap>
            {recipientAddress}
          </Typography>
        </Box>
        <Box flex={1.5} pl={{ sm: 4 }}>
          <Box display="flex">
            <Box
              display="flex"
              className={classes.trackingKey}
              borderRadius={100}
              border={1}
            >
              <Box
                pl={3}
                pr={1}
                py={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={1}
              >
                <Box pr={1}>
                  <Typography variant="caption" color="textSecondary" noWrap>
                    <strong>
                      {truncateID(trackingKey, TRACKING_KEY_MAX_CHAR)}
                    </strong>
                  </Typography>
                </Box>
                <Tooltip title={t('list.copyIdentifier')}>
                  <IconButton
                    data-testid="copyIconButton"
                    onClick={copyToClipboard}
                    border={1}
                    color="primary"
                    className={classes.copyIconButton}
                  >
                    <FileCopyRoundedIcon className={classes.copyIcon} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

BulkCancellationListItem.propTypes = {
  trackingKey: PropTypes.string.isRequired,
  recipientName: PropTypes.string.isRequired,
  recipientAddress: PropTypes.string.isRequired,
  reason: PropTypes.string
};

BulkCancellationListItem.defaultProps = {
  reason: undefined
};

export default BulkCancellationListItem;
