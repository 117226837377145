import { loggiWebApi } from '@loggi/authentication-lib';
import { ROLES } from '../constants';
import INVITE_USER_ENDPOINT from './constants';

const inviteUser = (invitedUser, companyId) => {
  const { email, name, role } = invitedUser;

  return loggiWebApi
    .url(`/${INVITE_USER_ENDPOINT}/${companyId}/users/`)
    .post({ full_name: name, email, role: ROLES[role].VALUE })
    .json();
};

export default inviteUser;
