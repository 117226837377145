import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import InviteUserDrawer from './invite-user/invite-user.drawer';
import ListUsers from './list-users/list-users.container';
import { INVITE_PATH } from './users-management.constants';

const UsersManagementRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path}>
        <ListUsers />
        <Route exact path={`${path}${INVITE_PATH}`}>
          <InviteUserDrawer />
        </Route>
      </Route>
      <Redirect to={path} />
    </Switch>
  );
};

export default UsersManagementRoutes;
