import { ErrorIcon } from '@loggi/components/src/one/shared/images';
import {
  Box,
  CircularProgress,
  Fade,
  IconButton,
  Typography
} from '@material-ui/core';
import { Replay } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

/**
 * This a wrapper that handles loading and error states of the order scheduling
 * page. If loading shows loading, if has a error shows a error layout, if not
 * simply renders whatever child was passed to it
 * @param orderSchedulingError
 * @param loading
 * @param reload - A method that enables the user to retry the request
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const SchedulingLoadingWrapper = ({
  orderSchedulingError,
  loading,
  reload,
  children
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    let timeout;
    if (!loading && !orderSchedulingError)
      timeout = setTimeout(() => setShow(true), 400);

    return () => clearTimeout(timeout);
  }, [loading, orderSchedulingError]);

  if (loading || orderSchedulingError) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        minHeight={233}
        p={{ xs: 3, sm: 5 }}
        textAlign="center"
      >
        {loading ? (
          <CircularProgress data-testid="loading" />
        ) : (
          <Fade in>
            <Box>
              <Box mb={2}>
                <ErrorIcon />
              </Box>
              <Box maxWidth={320}>
                <Typography>{orderSchedulingError.message}</Typography>
              </Box>
              <Box mt={1} mb={-1}>
                <IconButton
                  data-testid="retry"
                  color="primary"
                  onClick={reload}
                >
                  <Replay />
                </IconButton>
              </Box>
            </Box>
          </Fade>
        )}
      </Box>
    );
  }

  return (
    <Fade in={show}>
      <Box>{children}</Box>
    </Fade>
  );
};

SchedulingLoadingWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  loading: PropTypes.bool.isRequired,
  orderSchedulingError: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Error)
  ]),
  reload: PropTypes.func.isRequired
};
SchedulingLoadingWrapper.defaultProps = {
  orderSchedulingError: false
};

export default SchedulingLoadingWrapper;
