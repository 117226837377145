import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useTheme
} from '@material-ui/core';
import { showNotificationMessage } from '@loggi/authentication/src/service';
import {
  useRemoteConfig,
  useFeature
} from '@loggi/components/src/one/remote-config';
import SignupCompanyTemplate from '@loggi/components/src/one/sign-up-company';
import { HOME_ROUTE } from '../../routes/constants';
import SignupCompanyContext from '../sign-up-company-context';
import { validateCompanyData } from '../service/sign-up-company';
import { UPDATE_COMPANY_PICKUP_ROUTE } from '../routes/constants';
import {
  clearMultipleDataOnLocalStorage,
  SIGN_UP_COMPANY_KEYS
} from '../sign-up-company-storage';
import GoBackButton from '../shared/go-back-button.component';
import { createCompany, getSegmentSelectOptions } from './utils';
import {
  CONSENT_CHECKBOX_TEST_ID,
  CNPJ_REGEX,
  FORM,
  TERMS_URL,
  SIGN_UP_PROGRESS_BUTTON_TEST_ID
} from './constants';
import { useButtonLoadingStyle } from './styles';

const SignupCompanyForm = () => {
  const buttonLoadingStyle = useButtonLoadingStyle();
  const { spacing } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();
  const location = useLocation();
  const { t } = useTranslation('signupCompanyForm');
  const { value: enableCompanySignupEnrichment } = useRemoteConfig(
    'enable_company_signup_enrichment'
  );
  const enableL4BBypass = useFeature('enable_l4b_bypass');
  const companySignupEnrichmentEnabled =
    enableCompanySignupEnrichment === 'true';
  const {
    setCompanyId,
    setSignUpCompanyStepValues,
    signUpCompanyStepValues
  } = useContext(SignupCompanyContext);
  const segmentSelectOptions = getSegmentSelectOptions(t);
  const addNewCompany = Boolean(location?.state?.addNewCompany);
  const currentStep = addNewCompany ? 0 : 2;
  const initialValues = useMemo(
    () => signUpCompanyStepValues,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    INPUTS: { SHARED_NAME, CNPJ, CONSENT }
  } = FORM;

  const MENU_PROPS = {
    PaperProps: {
      style: {
        marginTop: spacing(1),
        maxWidth: spacing(10)
      }
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  };

  const signUpCompanyFormSchema = Yup.object().shape({
    cnpj: Yup.string()
      .required(t('validation.cnpjRequired'))
      .matches(CNPJ_REGEX, t('validation.cnpjInvalid')),
    sharedName: Yup.string().required(t('validation.sharedNameRequired')),
    consent: companySignupEnrichmentEnabled
      ? Yup.boolean().notRequired()
      : Yup.boolean().oneOf([true], t('validation.consentRequired')),
    segment: companySignupEnrichmentEnabled
      ? Yup.number().required(t('validation.segmentRequired'))
      : Yup.number().notRequired()
  });

  useEffect(() => {
    clearMultipleDataOnLocalStorage(Object.values(SIGN_UP_COMPANY_KEYS));
  }, []);

  const getErrorMessage = error => {
    let errorMessage = t('errorsMessages.default');
    const parsedError = error.message.toString().toLowerCase();

    if (error.message.includes('CompanyAlreadyExists')) {
      errorMessage = t(`errorsMessages.companyAlreadyExists`);
    } else if (
      (parsedError.includes('cnpj') && parsedError.includes('invalid')) ||
      parsedError.includes('bigdatacorp') ||
      parsedError.includes('nfe') ||
      error.message.includes('legalPersonModelValidationError')
    ) {
      errorMessage = t(`errorsMessages.invalidCnpj`);
    } else if (parsedError.includes('sharedname')) {
      errorMessage = t(`errorsMessages.invalidSharedName`);
    } else if (error.message.includes('accountableLoggiDoesNotExist')) {
      errorMessage = null;
    }

    return errorMessage;
  };

  const getIsNoL4BStatus = error => {
    return error.message.includes('accountableLoggiDoesNotExist');
  };

  const _onSubmit = async values => {
    setLoading(true);
    const { sharedName, cnpj } = values;
    const cnpjWithoutMask = cnpj.replace(/\W/g, '');
    setSignUpCompanyStepValues({ ...values });

    if (companySignupEnrichmentEnabled) {
      validateCompanyData(sharedName, cnpjWithoutMask)
        .then(() => {
          push(UPDATE_COMPANY_PICKUP_ROUTE, location?.state);
        })
        .catch(error => {
          const errorMessage = getErrorMessage(error);
          const isNoL4BStatus = getIsNoL4BStatus(error);

          if (errorMessage) {
            showNotificationMessage(errorMessage, 'error', enqueueSnackbar);
          }

          setLoading(false);

          if (enableL4BBypass && isNoL4BStatus) {
            setSignUpCompanyStepValues({ ...values, isNoL4BStatus });

            push(UPDATE_COMPANY_PICKUP_ROUTE, location?.state);
          }
        });
    } else {
      const signupCompanyPayload = {
        shared_name: sharedName,
        cnpj: cnpjWithoutMask
      };
      await createCompany(
        t,
        { payload: signupCompanyPayload, generatePricingTable: false },
        setCompanyId,
        enqueueSnackbar,
        push
      );
      setLoading(false);
    }
  };

  const redirectToHomeScreen = () => {
    push(HOME_ROUTE, { addNewCompany: false });
  };

  return (
    <SignupCompanyTemplate
      currentStep={currentStep}
      formTitles={t('signupCompanyForm.titles', { returnObjects: true })}
      formSubtitles={t('signupCompanyForm.subtitles', { returnObjects: true })}
    >
      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={signUpCompanyFormSchema}
        onSubmit={_onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          touched,
          isValid
        }) => (
          <form data-testid="sign-up-company-form" onSubmit={handleSubmit}>
            <TextField
              error={Boolean(errors.sharedName) && touched.sharedName}
              helperText={touched.sharedName ? errors.sharedName : ''}
              fullWidth
              id="EMAIL_INPUT_BUTTON_ID"
              label={t('sharedNameInput.label')}
              margin="normal"
              name={SHARED_NAME.NAME}
              onBlur={handleBlur}
              value={values.sharedName}
              onChange={handleChange}
              variant="outlined"
            />
            <Box mt={1} mb={3}>
              <InputMask
                mask={CNPJ.MASK}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cnpj}
              >
                {(
                  propsForTesting = {
                    onChange: null,
                    onBlur: null,
                    value: null
                  }
                ) => {
                  const { onChange, onBlur, value } = propsForTesting;
                  return (
                    <TextField
                      error={Boolean(errors.cnpj) && touched.cnpj}
                      fullWidth
                      helperText={touched.cnpj ? errors.cnpj : ''}
                      id={CNPJ.NAME}
                      label={t('cnpjInput.label')}
                      margin="normal"
                      name={CNPJ.NAME}
                      variant="outlined"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  );
                }}
              </InputMask>
              {companySignupEnrichmentEnabled && (
                <>
                  {segmentSelectOptions.map(({ id, inputLabel, options }) => (
                    <FormControl
                      fullWidth
                      key={id}
                      style={{ marginTop: spacing(3) }}
                      variant="outlined"
                    >
                      <InputLabel>{inputLabel}</InputLabel>
                      <Select
                        error={Boolean(touched[id] && errors[id])}
                        id={id}
                        name={id}
                        input={<OutlinedInput label={inputLabel} />}
                        MenuProps={MENU_PROPS}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values[id] || ''}
                      >
                        {options.map(({ label, value }) => (
                          <MenuItem key={label} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error>
                        {touched[id] && errors[id]}
                      </FormHelperText>
                    </FormControl>
                  ))}
                </>
              )}
              {!companySignupEnrichmentEnabled && (
                <Box>
                  <FormControlLabel
                    data-testid={CONSENT_CHECKBOX_TEST_ID}
                    control={
                      <Checkbox
                        checked={values.consent}
                        onChange={handleChange}
                        name={CONSENT.NAME}
                      />
                    }
                    label={
                      <span>
                        {t('consentInput.labelBefore')}
                        <Link href={TERMS_URL} target="_blank">
                          {t('consentInput.termsName')}
                        </Link>
                        {t('consentInput.labelAfter')}
                      </span>
                    }
                  />
                  <FormHelperText error>
                    {touched.consent && errors.consent}
                  </FormHelperText>
                </Box>
              )}
            </Box>
            <Box pt={2} width="100%">
              <Button
                data-testid="submitButton"
                disabled={loading || !isValid}
                color="primary"
                fullWidth
                variant="contained"
                type="submit"
              >
                {!loading && t('button.text')}
                {loading && (
                  <CircularProgress
                    data-testid={SIGN_UP_PROGRESS_BUTTON_TEST_ID}
                    size={26}
                    className={buttonLoadingStyle.progress}
                  />
                )}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      {addNewCompany && <GoBackButton onClick={redirectToHomeScreen} />}
    </SignupCompanyTemplate>
  );
};

export default SignupCompanyForm;
