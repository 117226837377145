import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import Toast from '@loggi/components/src/one/toast';
import { useFeature } from '@loggi/components/src/one/remote-config';
import { Box, Typography } from '@material-ui/core';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { oneConstants } from '@loggi/components/src/one';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RecurringPickupSuccess from './recurring-pickup-success.component';
import recurringPickupApi from './recurring-pickup-api';
import { UNIFIED_PICKUP_FORM_STORAGE_KEY } from './formik-storage-watcher.component';
import UnifiedPickupCreateForm from './unified-pickup-create-form';
import UnifiedPickupUpdateForm from './unified-pickup-update-form';
import {
  parseValues,
  setDefaultsForMissingValues
} from './unified-pickup-helpers';
import { IS_RECURRING } from './constants';

const UnifiedPickup = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const shouldUsePickupsApi = useFeature('should_use_pickups_api');

  const { companyId, recurringPickupId } = useParams();
  const isUpdate = !!recurringPickupId;

  const { push } = useHistory();

  const { t } = useTranslation('unifiedPickup');
  const _initialValues = useMemo(() => {
    const pickupFormValue = localStorage.getItem(
      UNIFIED_PICKUP_FORM_STORAGE_KEY
    );
    const parsedValues = parseValues(pickupFormValue);
    return setDefaultsForMissingValues(parsedValues);
  }, []);

  const getSelectedWeekdaysList = responseData => {
    const weekdaysList = [
      responseData.monday,
      responseData.tuesday,
      responseData.wednesday,
      responseData.thursday,
      responseData.friday
    ];
    const selectedWeekdaysList = [];
    let index = 0;
    weekdaysList.forEach(isWeekdaySelected => {
      if (isWeekdaySelected) {
        const weekdayNumber = index + 1;
        selectedWeekdaysList.push(weekdayNumber.toString());
      }
      index += 1;
    });
    return selectedWeekdaysList;
  };

  const buildToast = (key, type, message) => {
    return <Toast id={key} message={message} type={type} />;
  };

  const snackBarMessage = useCallback(
    (barMessage, type) =>
      enqueueSnackbar(barMessage, {
        content: (key, message) => buildToast(key, type, message)
      }),
    [enqueueSnackbar]
  );

  useEffect(() => {
    const mapRecurringPickup = responseData => {
      return {
        cnpjOrCpf: responseData.document,
        pickupAddress: {
          // eslint-disable-next-line camelcase
          description: responseData.origin_address.full_address
        },
        pickupAddressComplement:
          // eslint-disable-next-line camelcase
          responseData.origin_address?.address_complement,
        // eslint-disable-next-line camelcase
        pickupOriginInstructions: responseData?.instructions,
        // eslint-disable-next-line camelcase
        pickupOriginName: responseData?.shipper_name,
        // eslint-disable-next-line camelcase
        pickupOriginPhone: responseData?.shipper_phone,
        // eslint-disable-next-line camelcase
        pickupStartTime: responseData.start_time,
        // eslint-disable-next-line camelcase
        pickupEndTime: responseData.end_time,
        // eslint-disable-next-line camelcase
        pickupDate: responseData.starting_from_date,
        pickupWeekdays: getSelectedWeekdaysList(responseData),
        pickupIsRecurring: IS_RECURRING
      };
    };

    if (isUpdate) {
      recurringPickupApi
        .get({ companyId, recurringPickupId, shouldUsePickupsApi })
        .then(response => {
          setInitialValues(mapRecurringPickup(response));
        })
        .catch(error => {
          snackBarMessage(error.message, 'error');
          push(oneConstants.TRACKING_ROUTE_INITIAL_STATE);
        });

      return;
    }

    setInitialValues(_initialValues);
  }, [
    isUpdate,
    companyId,
    recurringPickupId,
    _initialValues,
    shouldUsePickupsApi,
    push,
    snackBarMessage
  ]);

  const unifiedPickupFormComponent = () => {
    return isUpdate ? (
      <UnifiedPickupUpdateForm
        initialValues={initialValues}
        onSubmit={setHasSubmitted}
      />
    ) : (
      <UnifiedPickupCreateForm
        initialValues={initialValues}
        onSubmit={setHasSubmitted}
      />
    );
  };

  return (
    <>
      {!hasSubmitted && initialValues && (
        <OneTemplate>
          <OneTemplateSummary>
            <Box display="flex" alignItems="center">
              <Typography
                variant="h4"
                component="h1"
                style={{ fontWeight: 'bold' }}
              >
                {t('newTitle')}
              </Typography>
            </Box>
          </OneTemplateSummary>
          <OneTemplateContent>
            {unifiedPickupFormComponent()}
          </OneTemplateContent>
        </OneTemplate>
      )}
      {hasSubmitted && <RecurringPickupSuccess />}
    </>
  );
};

export default UnifiedPickup;
