import {
  Menu,
  MenuItem,
  styled,
  Typography,
  useTheme
} from '@material-ui/core';
import { useRemoteConfig } from '@loggi/components/src/one/remote-config';
import { MoreVert } from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCheckCompanyStatus } from '@loggi/components/src/one/hooks';

import DeleteConfirmationModal from './delete-confirmation-modal.component';
import RecurringPickupItemPropTypes from './recurring-pickup-item.propTypes';

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main
  }
}));

/**
 * RecurringPickupListItem renders a static table that only presents the recurring it receives
 * @componentType presentational
 * @param recurring
 * @returns {*}
 * @constructor
 */

const RecurringSettingsOptions = ({
  recurring,
  onDelete,
  timeRange,
  daysList
}) => {
  const { t } = useTranslation('scheduling');
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const { spacing } = useTheme();
  const { isDefaulter } = useCheckCompanyStatus();
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const { value: editOptionEnabled } = useRemoteConfig(
    'enable_edit_recurring_pickup'
  );
  const isEditOptionEnabled = JSON.parse(editOptionEnabled);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const redirectToUnifiedPickup = () => {
    push(`${url}/adicionar`);
  };

  const redirectToEditRecurringPickup = () => {
    push(`${url}/rotina/${recurring.id}/editar`);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setDeletePopupOpen(false);
  };

  const openDeletePopup = () => {
    setDeletePopupOpen(true);
  };

  return (
    <>
      <ToggleButton
        data-testid="toggle-btn"
        selected={!!anchorEl}
        onClick={handleClickMenu}
        style={{
          border: 0,
          borderRadius: '50px',
          padding: '0'
        }}
      >
        <MoreVert />
      </ToggleButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: spacing(37.5)
          }
        }}
      >
        {!isEditOptionEnabled ? (
          <MenuItemStyled
            button
            data-testid="schedule-new"
            disabled={isDefaulter}
            onClick={redirectToUnifiedPickup}
          >
            <Typography variant="button">
              {t('settingsOptions.scheduleNew')}
            </Typography>
          </MenuItemStyled>
        ) : (
          <MenuItemStyled
            button
            data-testid="edit-recurring-pickup"
            disabled={isDefaulter}
            onClick={redirectToEditRecurringPickup}
          >
            <Typography variant="button">
              {t('settingsOptions.edit')}
            </Typography>
          </MenuItemStyled>
        )}
        <MenuItemStyled button onClick={openDeletePopup} data-testid="delete">
          <Typography variant="button">
            {t('settingsOptions.delete')}
          </Typography>
        </MenuItemStyled>
      </Menu>
      <DeleteConfirmationModal
        recurring={recurring}
        onDelete={onDelete}
        timeRange={timeRange}
        daysList={daysList}
        open={deletePopupOpen}
        handleClose={handleCloseMenu}
      />
    </>
  );
};

RecurringSettingsOptions.propTypes = {
  onDelete: PropTypes.func.isRequired,
  recurring: PropTypes.shape(RecurringPickupItemPropTypes).isRequired,
  timeRange: PropTypes.string.isRequired,
  daysList: PropTypes.string.isRequired
};

export default RecurringSettingsOptions;
