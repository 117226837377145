import firebase from 'firebase/app';
import 'firebase/remote-config';
import 'firebase/messaging';

const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const appId = process.env.REACT_APP_FIREBASE_APP_ID;
const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const publicVapidKey = process.env.REACT_APP_FIREBASE_PUBLIC_V_API_KEY;

const firebaseConfig = {
  apiKey,
  appId,
  authDomain,
  databaseURL,
  messagingSenderId,
  projectId,
  storageBucket: 'loggi-one.appspot.com'
};
firebase.initializeApp(firebaseConfig);

// Only load Firebase messaging when service workers are enabled
if (
  firebase.messaging.isSupported() &&
  process.env.REACT_APP_ENABLE_SERVICE_WORKER === 'true'
) {
  navigator.serviceWorker
    .register('/custom-service-worker.js')
    .then(registration => {
      firebase.messaging().useServiceWorker(registration);
    });

  const messaging = firebase.messaging();
  messaging.usePublicVapidKey(publicVapidKey);
}

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
  fetchTimeoutMillis: 60000
};
remoteConfig.defaultConfig = {
  enable_management_app: 'false',
  enable_shipping_package_selection: 'false'
};
