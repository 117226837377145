import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const SectionTitle = ({ children }) => (
  <Box mb={{ xs: 1, sm: 3 }}>
    <Typography variant="h6">
      <Box component="strong" fontWeight="600">
        {children}
      </Box>
    </Typography>
  </Box>
);

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired
};

export default SectionTitle;
