import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, makeStyles, Badge } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';

import { useTranslation } from 'react-i18next';

import { PendingOutlined } from '@loggi/components/src/one/shared/images';

const useStyles = makeStyles(({ palette, typography }) => ({
  badge: {
    '& .MuiBadge-badge': {
      width: typography.pxToRem(24),
      height: typography.pxToRem(24),
      borderRadius: '100%',
      right: 0,
      fontSize: typography.pxToRem(14)
    }
  },
  icon: {
    color: palette.grey[800],
    fontSize: typography.pxToRem(40)
  }
}));

const BulkCancellationListHeader = ({ type, badgeContent }) => {
  const styles = useStyles();
  const { t } = useTranslation('packages');

  let headerTitle = '';
  let headerSubtitle = '';
  let Icon = CheckCircleOutline;

  switch (type) {
    case 'error':
      headerTitle = t('list.bulkCancellation.results.error.title');
      headerSubtitle = t('list.bulkCancellation.results.error.subtitle');
      Icon = ErrorOutline;
      break;
    case 'warning':
      headerTitle = t('list.bulkCancellation.results.warning.title');
      headerSubtitle = t('list.bulkCancellation.results.warning.subtitle');
      Icon = PendingOutlined;
      break;
    default:
      headerTitle = t('list.bulkCancellation.results.success.title');
      headerSubtitle = t('list.bulkCancellation.results.success.subtitle');
      break;
  }

  return (
    <>
      <Box display="flex" pb={2} alignItems="center">
        <Box pr={4}>
          <Badge
            className={styles.badge}
            color="primary"
            overlap="circle"
            badgeContent={badgeContent}
          >
            <Icon className={styles.icon} />
          </Badge>
        </Box>
        <Box>
          <Typography variant="h6">
            <strong>{headerTitle}</strong>
          </Typography>
          <Typography variant="body1">{headerSubtitle}</Typography>
        </Box>
      </Box>
    </>
  );
};

BulkCancellationListHeader.propTypes = {
  type: PropTypes.string.isRequired,
  badgeContent: PropTypes.number.isRequired
};

export default BulkCancellationListHeader;
