export const BACK_BUTTON_ID = 'back-btn';
export const MIN_START_TIME_DELAY_IN_MINUTES = 60;
export const MIN_TIME_DIFF_IN_MINUTES = 60;
export const ORDER_SCHEDULES_ENDPOINT = 'order-schedules/';
export const PICKUPS_COMPANIES_ENDPOINT = 'one/api/pickups/v1/companies';
export const SHIFT_END = '18:00';
export const SHIFT_START = '08:00';
export const TRANSPORT_TYPES = {
  MOTORCYCLE: '1',
  BICYCLE: '2',
  VAN: '3',
  CAR: '4'
};

export const maxHeightInCm = 100;
export const maxLengthInCm = 100;
export const maxWeightInGrams = 30000;
export const maxWidthInCm = 100;

export const minHeightInCm = 1;
export const minLengthInCm = 1;
export const minWeightInGrams = 1;
export const minWidthInCm = 1;
