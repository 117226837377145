import firebase from 'firebase/app';
import { isLoggiOneIOSApp } from '../mobile';
import { NOTIFICATION_SCOPE, LOADING_STATUS } from './constants';

const { SUPPORTED, IOS_SUPPORTED, NOT_SUPPORTED } = NOTIFICATION_SCOPE;
const { LOADING, DONE } = LOADING_STATUS;

const getPermissionStatus = () => {
  let initialState = {};

  if (isLoggiOneIOSApp()) {
    // iOS
    initialState = {
      status: LOADING, // we wait for iOS wrapper to respond
      permission: null,
      token: null,
      scope: IOS_SUPPORTED
    };
  } else {
    // Web and Android
    // We should only load Firebase messaging if service workers are enabled
    const canUseNotification =
      firebase.messaging.isSupported() &&
      Notification &&
      process.env.REACT_APP_ENABLE_SERVICE_WORKER === 'true';

    if (canUseNotification) {
      const status = Notification.permission === 'granted' ? LOADING : DONE;
      initialState = {
        status,
        permission: Notification.permission,
        token: null,
        scope: SUPPORTED
      };
    } else {
      // Notification is not supported and isn't iOS
      initialState = {
        status: DONE,
        permission: null,
        token: null,
        scope: NOT_SUPPORTED
      };
    }
  }

  return initialState;
};

export default getPermissionStatus;
