import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { formatDeliveryProofHTML } from '../utils';
import DeliveryProofDrawerError from './delivery-proof-drawer-error';
import fetchLoggiGenericApi from '../../loggi-generic-api';
import { USER_ACCESS_COMPANIES_LOCAL_STORAGE_KEY } from '../constants';
import DeliveryProofDrawerSkeleton from './delivery-proof-drawer-skeleton';

const initialState = {
  isLoading: true,
  deliveryProofHtml: null,
  error: false
};

const DeliveryProofDrawerContent = ({ trackingKey, pdfLink }) => {
  const { companyId } = useParams();

  const [{ deliveryProofHtml, isLoading, error }, setState] = useState(
    initialState
  );

  const [countRefreshErrorDrawer, setCountRefreshErrorDrawer] = useState(0);

  const refreshErrorDrawer = () => {
    setCountRefreshErrorDrawer(countRefreshErrorDrawer + 1);
  };

  const getCompanySecretId = () => {
    const pdfURL = new URL(pdfLink);
    let secretID = pdfURL.searchParams.get('secret-id');

    const userCompaniesJson = localStorage.getItem(
      USER_ACCESS_COMPANIES_LOCAL_STORAGE_KEY
    );

    if (userCompaniesJson) {
      const userCompanies = JSON.parse(userCompaniesJson);

      const currentCompany = userCompanies?.find(
        company => company.id.toString() === companyId
      );
      const localStorageSecretID = currentCompany?.['secret_id'];

      if (localStorageSecretID) {
        secretID = localStorageSecretID;
      }
    }

    return secretID;
  };

  const secretId = getCompanySecretId();

  useEffect(() => {
    setState({ isLoading: true, deliveryProofHtml: null, error: false });

    fetchLoggiGenericApi
      .deliveryReceipt(secretId, trackingKey)
      .then(response => {
        setState({
          isLoading: false,
          deliveryProofHtml: response.html,
          error: false
        });
      })
      .catch(() =>
        setState({ isLoading: false, deliveryProofHtml: null, error: true })
      );
  }, [countRefreshErrorDrawer, trackingKey, secretId]);

  const htmlContent = () => {
    return (
      /* eslint-disable react/no-danger */
      <div
        dangerouslySetInnerHTML={{
          __html: formatDeliveryProofHTML(deliveryProofHtml)
        }}
      />
    );
  };

  return (
    <Box p={3}>
      {isLoading && <DeliveryProofDrawerSkeleton />}
      {!isLoading && !error && (
        <Box data-testid="html-content">{htmlContent()}</Box>
      )}
      {error && (
        <DeliveryProofDrawerError refreshErrorDrawer={refreshErrorDrawer} />
      )}
    </Box>
  );
};

DeliveryProofDrawerContent.propTypes = {
  trackingKey: PropTypes.string.isRequired,
  pdfLink: PropTypes.string.isRequired
};

export default DeliveryProofDrawerContent;
