import { useEffect, useState } from 'react';
import { parse } from 'date-fns';
import { useFormikContext } from 'formik';
import * as Sentry from '@sentry/browser';
import listNonWorkingDaysApi from '../operations/unified-pickup/list-non-working-days-api';

const useNonWorkingDays = ({ minDate, maxDate }) => {
  const {
    values: { pickupAddress, pickupAddressComplement }
  } = useFormikContext();
  const [nonWorkingDays, setNonWorkingDays] = useState(undefined);

  const normalizeNonWorkingDays = data =>
    data.map(item => parse(item.complete_date, 'yyyy-MM-dd', new Date()));

  useEffect(() => {
    if (pickupAddress?.description && minDate && maxDate) {
      const params = {
        startDate: minDate,
        endDate: maxDate,
        pickupAddress: pickupAddress?.description,
        pickupAddressComplement
      };
      listNonWorkingDaysApi
        .list(params)
        .then(data => {
          const dates = normalizeNonWorkingDays(data);
          setNonWorkingDays(dates);
        })
        .catch(error => {
          Sentry.configureScope(scope => {
            scope.setLevel(Sentry.Severity.Error);
            Sentry.addBreadcrumb({ params });
            Sentry.captureException(error);
          });

          setNonWorkingDays(undefined);
        });
    }
  }, [minDate, maxDate, pickupAddress, pickupAddressComplement]);

  return [nonWorkingDays];
};

export default useNonWorkingDays;
