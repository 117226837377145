import qs from 'qs';
import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQueryParams } from '../hooks';

const useFilter = () => {
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const queryParams = useQueryParams();

  const stringifiedQueryParams = JSON.stringify(queryParams);

  const setFilter = useCallback(
    (filters, clearPagination = false, pageQueryStringName = 'pagina') => {
      const parsedCurrentParams = JSON.parse(stringifiedQueryParams);

      if (clearPagination) {
        delete parsedCurrentParams[pageQueryStringName]; // To reset pagination when a filter is applied
      }

      const updatedParsedParams = parsedCurrentParams;
      const keys = Object.keys(filters);

      keys.forEach(key => {
        // This condition exists because of a bug that qs has, in which it doesn't
        // remove the param from the generated string when it's an empty array
        // with the 'arrayFormat' option as 'comma'
        // Issue: https://github.com/ljharb/qs/issues/310
        const value = filters[key];
        if (Array.isArray(value)) {
          if (value.length) {
            updatedParsedParams[key] = value;
          } else {
            delete updatedParsedParams[key];
          }
        } else if (value) {
          updatedParsedParams[key] = value;
        } else {
          delete updatedParsedParams[key];
        }
      });

      const stringifiedParams = qs.stringify(updatedParsedParams, {
        addQueryPrefix: true,
        arrayFormat: 'comma',
        encode: false,
        skipNulls: true
      });
      push(`${url}${stringifiedParams}`);
    },
    [url, push, stringifiedQueryParams]
  );

  return setFilter;
};

export default useFilter;
