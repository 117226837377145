/** This is purposely here so sentry is started as soon as possible
 * so we don't miss any errors on the app first render */
import './sentry/sentry-init';

import { AmplifyAuthProvider } from '@loggi/authentication-lib';
import { AppErrorBoundary } from '@loggi/components/src/one';
import marTheme from '@loggi/mar';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { appcuesPage } from './configuration/appcues-init';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './configuration/firebase-init';
import './configuration/i18n-init';
import './configuration/tag-manager-init';

ReactDOM.render(
  <ThemeProvider theme={marTheme}>
    <AppErrorBoundary
      beforeCapture={scope => {
        scope.setTag('app.region', 'one');
      }}
    >
      <SnackbarProvider
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        autoHideDuration={7000}
        maxSnack={3}
      >
        <AmplifyAuthProvider>
          <Router onUpdate={appcuesPage}>
            <App />
          </Router>
        </AmplifyAuthProvider>
      </SnackbarProvider>
    </AppErrorBoundary>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
