import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AddressAliasField = ({ fieldName }) => {
  const { t } = useTranslation('one');
  const [field, meta] = useField({
    name: fieldName,
    validate: newValue =>
      !newValue ? t('addressAliasField.errorMessages.requiredField') : null
  });

  const { name, onChange, onBlur, value = '' } = field;
  const { error, touched } = meta;
  const hasError = Boolean(error) && touched;

  return (
    <TextField
      error={hasError}
      fullWidth
      helperText={hasError && error}
      id={name}
      inputProps={{ 'data-testid': `${name}-field` }}
      label={t('addressAliasField.label')}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      required
      value={value}
      variant="outlined"
    />
  );
};

AddressAliasField.propTypes = {
  fieldName: PropTypes.string.isRequired
};

export default AddressAliasField;
