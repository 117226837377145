import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import WarehousesSummary from './components/warehouses-summary';
import WarehousesListWrapper from './components/warehouses-list-wrapper';
import addressesApi from '../../addresses-api';

const initialState = {
  isLoading: true,
  warehouses: [],
  showGenericError: false
};

/**
 * This is the warehouses screen root, it's responsible for getting the data
 * from API, and then passing to its children components
 * It receives no params, it renders itself without external dependencies
 * @componentType container
 * @returns {*}
 */
const WarehousesList = () => {
  const { companyId } = useParams();
  const [{ warehouses, isLoading, showGenericError }, setState] = useState(
    initialState
  );

  useEffect(() => {
    addressesApi
      .warehouses(companyId)
      .then(response => {
        setState({
          isLoading: false,
          warehouses: response.warehouses,
          showGenericError: false
        });
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          setState({
            isLoading: false,
            warehouses: [],
            showGenericError: false
          });
        } else {
          setState({
            isLoading: false,
            warehouses: [],
            showGenericError: true
          });
        }
      });
  }, [companyId]);

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <WarehousesSummary />
      </OneTemplateSummary>
      <OneTemplateContent disablePadding>
        <WarehousesListWrapper
          data={warehouses}
          isLoading={isLoading}
          error={showGenericError}
        />
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default WarehousesList;
