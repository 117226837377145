const masks = {
  cpf(val) {
    return val
      .replace(/(\d{11})\d/, '$1')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2');
  },
  cnpj(val) {
    return val
      .replace(/(\d{14})\d/, '$1')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  }
};

const formattedDocument = document => {
  if (document.length <= 11) return masks.cpf(document);

  return masks.cnpj(document);
};

export default formattedDocument;
