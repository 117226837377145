import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { Box } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import PageTitle from '../../page-title.component';
import addressesApi from '../../addresses-api';
import managementRoutes from '../../management-routes';
import ReturnAddresstWrapper from './return-address-wrapper.component';

const initialState = {
  isLoading: true,
  initialValues: {},
  showGenericError: false
};

const ReturnAddress = () => {
  const { push } = useHistory();
  const { t } = useTranslation('management');
  const { companyId, warehouseId } = useParams();
  const [
    { initialValues, isLoading, noWarehouseFound, showGenericError },
    setState
  ] = useState(initialState);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    addressesApi
      .warehouses(companyId)
      .then(response => {
        let values = {};

        const warehouse = response?.warehouses.find(
          warehouseAddress => warehouseAddress.warehouseId === warehouseId
        );

        // If there is no warehouses or no warehouse matching the url id, redirects to the warehouses screen
        if (warehouse === undefined) {
          setState({
            initialValues: {},
            isLoading: false,
            noWarehouseFound: true,
            showGenericError: false
          });
        }

        if (warehouse) {
          values = {
            responsibleName: warehouse.returnAddress?.responsibleName,
            responsiblePhone: warehouse.returnAddress?.responsiblePhone,
            returnAddressInstructions: warehouse.returnAddress?.instruction,
            returnAddressAlias: warehouse.returnAddress?.aliasName
          };
          setState({
            initialValues: values,
            isLoading: false,
            noWarehouseFound: false,
            showGenericError: false
          });
        }
      })
      .catch(() => {
        setState({
          initialValues: {},
          isLoading: false,
          noWarehouseFound: false,
          showGenericError: true
        });
      });
  }, [companyId, warehouseId, enqueueSnackbar, t]);

  if (!isLoading && noWarehouseFound) {
    push(managementRoutes.warehousesAddresses.url(companyId));
  }

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Box display="flex" alignItems="center">
          <PageTitle title="returnAddress.title" />
        </Box>
      </OneTemplateSummary>
      <OneTemplateContent>
        <ReturnAddresstWrapper
          data={initialValues}
          isLoading={isLoading}
          error={showGenericError}
        />
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default ReturnAddress;
