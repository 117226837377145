/* eslint-disable react/jsx-props-no-spreading */
import Toast from '@loggi/components/src/one/toast';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useCheckCompanyStatus } from '@loggi/components/src/one/hooks';

import { useFeature, useRemoteConfig } from '../../../../firebase';
import exceptionHandler from '../../../exception-handler';
import packagesApi from '../../../packages-api';
import packagesRoutes from '../../../packages-routes';
import { ReactComponent as FileIcon } from '../../file-icon.svg';
import LoadingDialog from '../../loading-dialog.component';
import ErrorAlert from './error-alert.component';
import NfeFilesList from './nfe-files-list.component';

const UploadDropzone = () => {
  const { t } = useTranslation('packages');
  const { companyId } = useParams();
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { value: enableZipUpload } = useRemoteConfig(
    'enable_package_zip_upload'
  );
  const enableZipValidation = useFeature('enable_zip_validation');
  const acceptedFiles =
    enableZipUpload === 'true' ? ['.xml', '.zip'] : ['.xml'];
  const formattedAcceptedFiles = acceptedFiles.join(' ');
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { isDefaulter } = useCheckCompanyStatus();

  const getFiles = useCallback(
    fileRejections => {
      const xmlFiles = uploadedFiles.filter(({ name }) =>
        enableZipUpload === 'true'
          ? name.endsWith('.xml') || name.endsWith('.zip')
          : name.endsWith('.xml')
      );
      const invalidTypeFiles = uploadedFiles.filter(
        files => !xmlFiles.includes(files)
      );
      const fileValidationError =
        fileRejections.length > 0 || invalidTypeFiles.length > 0;
      const uploadedInvalidTypeFiles =
        fileRejections.length > 0 || invalidTypeFiles.length > 0;
      const showFilesList = xmlFiles.length > 0 && !fileValidationError;

      return {
        fileValidationError,
        showFilesList,
        uploadedInvalidTypeFiles,
        xmlFiles
      };
    },
    [enableZipUpload, uploadedFiles]
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: acceptedFiles,
    multiple: true,
    disabled: isDefaulter,
    onDropAccepted: files => {
      /**
       * Restart the upload after a file validation error occurs,
       * otherwise increment the chosen files to the ones that
       * have been uploaded
       */
      return getFiles(fileRejections).fileValidationError
        ? setUploadedFiles([...files])
        : setUploadedFiles([...uploadedFiles, ...files]);
    }
  });

  const submit = useCallback(
    files => {
      setLoading(true);
      packagesApi
        .uploadInvoices(companyId, files)
        .badRequest(({ text }) => {
          const { isArray } = Array;
          const errors = JSON.parse(text);

          if (enableZipValidation && isArray(errors)) {
            const url = packagesRoutes.importInvoiceErrors.url(companyId);

            push(url, { errors });
          } else {
            enqueueSnackbar(t('create.upload.internalServerError'), {
              content: (key, message) => (
                <Toast id={key} message={message} type="error" />
              )
            });
            exceptionHandler.warning(text);
            setLoading(false);
          }
        })
        .error(413, () => {
          enqueueSnackbar(t('create.upload.requestEntityTooLarge'), {
            content: (key, message) => (
              <Toast id={key} message={message} type="error" />
            )
          });
          setLoading(false);
        })
        .json(response => {
          if (response?.length) {
            const url = packagesRoutes.createSuccess.url(companyId);
            push(url, { trackingKeys: response.map(pkg => pkg.trackingKey) });
          } else {
            enqueueSnackbar(t('create.upload.duplicatedPackages'), {
              content: (key, message) => (
                <Toast id={key} message={message} type="error" />
              )
            });
            setLoading(false);
          }
        })
        .catch(() => {
          enqueueSnackbar(t('create.upload.internalServerError'), {
            content: (key, message) => (
              <Toast id={key} message={message} type="error" />
            )
          });
          setLoading(false);
        });
    },
    [companyId, enableZipValidation, enqueueSnackbar, push, t]
  );

  const {
    fileValidationError,
    showFilesList,
    uploadedInvalidTypeFiles,
    xmlFiles
  } = getFiles(fileRejections);
  const useStyles = makeStyles(({ palette }) => ({
    dashedBox: {
      border: `dashed 1px ${
        fileValidationError ? palette.error.main : palette.primary.main
      }`,
      cursor: 'pointer'
    }
  }));
  const styles = useStyles();

  return (
    <>
      <Box
        {...getRootProps({ className: 'dropzone' })}
        borderRadius={16}
        className={classNames(styles.dashedBox)}
        p={{ xs: 2, sm: 7 }}
        textAlign="center"
      >
        <FileIcon />
        <Box>
          <Typography variant="h6">
            {t('create.supportedFiles', {
              formattedAcceptedFiles
            })}
          </Typography>
          {uploadedInvalidTypeFiles && (
            <ErrorAlert message={t('create.invoiceImport.fileFormatError')} />
          )}
          {showFilesList && (
            <Box
              {...getRootProps({
                /**
                 * Avoiding the file dialog to open at the same time that
                 * the user is clicking to delete a file inside the list
                 */
                onClick: e => e.stopPropagation()
              })}
              py={2}
            >
              <NfeFilesList
                files={uploadedFiles}
                onDeleteFile={setUploadedFiles}
              />
              <Button
                color="primary"
                data-testid="confirm-files"
                onClick={() => submit(xmlFiles)}
                size="large"
                variant="contained"
              >
                {t('create.invoiceImport.confirmFilesLink')}
              </Button>
            </Box>
          )}
          <Typography>
            <input {...getInputProps()} data-testid="upload-input" />
            <Box
              color="primary.main"
              component="strong"
              fontWeight="fontWeightBold"
            >
              {t('create.invoiceImport.chooseOrDragFiles')}
            </Box>
          </Typography>
        </Box>
      </Box>
      {loading && (
        <LoadingDialog message={t('create.spreadsheetFlow.loading')} />
      )}
    </>
  );
};

export default UploadDropzone;
