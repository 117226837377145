import theme from '@loggi/mar';
import { Box, Typography, useTheme } from '@material-ui/core/';
import PropTypes from 'prop-types';
import React from 'react';

const StatusIndicatorTag = props => {
  const { statusMap, status } = props;
  const { palette } = useTheme();

  if (!statusMap[status]) return null;

  return (
    <Box
      display="inline-flex"
      border={1}
      borderRadius={5}
      px={1}
      py={0.5}
      borderColor={theme.palette.grey[200]}
      bgcolor={palette.common.white}
      width="auto"
    >
      <Box
        display="flex"
        alignItems="center"
        alignSelf="center"
        mr={1}
        width={5}
        height={5}
        borderRadius={100}
        data-testid="status-indicator-tag"
        bgcolor={statusMap[status].color}
      />
      <Typography variant="caption" color="textPrimary" noWrap>
        <Box fontWeight={theme.typography.fontWeightBold} component="span">
          {statusMap[status].label}
        </Box>
      </Typography>
    </Box>
  );
};

StatusIndicatorTag.propTypes = {
  statusMap: PropTypes.objectOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default StatusIndicatorTag;
