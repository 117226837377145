import { useCallback, useEffect, useMemo, useState } from 'react';

import { SELECTED_COMPANY_LOCAL_STORAGE_KEY, SUPPORT_ROLE } from '../constants';
import useCurrentUser from './use-current-user';

const getCompanyFromLocalStorage = () => {
  const selectedCompanyFromLocalStorage = localStorage.getItem(
    SELECTED_COMPANY_LOCAL_STORAGE_KEY
  );
  const hasSelectedCompany = Boolean(selectedCompanyFromLocalStorage);

  return hasSelectedCompany
    ? JSON.parse(selectedCompanyFromLocalStorage)
    : null;
};

const useCurrentCompany = () => {
  const [company, setCompany] = useState(null);
  const user = useCurrentUser();
  const companies = useMemo(() => user?.companyAccess || [], [user]);

  const hasAccessToCompany = useCallback(
    selectedCompany => {
      const selectedCompanyAccess = companies.find(
        ({ id }) => id === selectedCompany.id
      );
      return Boolean(selectedCompanyAccess);
    },
    [companies]
  );

  const refreshCompany = useCallback(() => {
    if (!company) {
      const selectedCompany = getCompanyFromLocalStorage();
      const selectedRole = selectedCompany
        ? companies.find(({ id }) => id === selectedCompany.id)?.role
        : '';

      if (
        selectedCompany &&
        selectedRole !== SUPPORT_ROLE &&
        hasAccessToCompany(selectedCompany)
      ) {
        setCompany(selectedCompany);
      } else if (companies.length) {
        setCompany(
          companies.find(({ role }) => role !== SUPPORT_ROLE) ?? companies[0]
        );
      }
    }
  }, [companies, company, hasAccessToCompany]);

  useEffect(() => refreshCompany(), [refreshCompany]);

  return company;
};

export default useCurrentCompany;
