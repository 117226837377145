import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../utils';

const FiscalDrawerContent = ({ invoices }) => {
  const { t } = useTranslation('packages');

  return (
    <Box px={4}>
      <Box pb={3} pt={4}>
        <Typography variant="h4">
          <strong>
            {t('details.sections.fiscalDocuments.drawer.fiscalDocuments')}
          </strong>
        </Typography>
      </Box>
      {invoices.map(invoice => (
        <Box py={2}>
          <Typography variant="h6">
            <strong>
              {t('details.sections.fiscalDocuments.drawer.invoice')}{' '}
              {invoice.number}
            </strong>
          </Typography>
          <Box py={1}>
            <Typography color="textSecondary" variant="body2">
              {t('details.sections.fiscalDocuments.drawer.totalValue')}
            </Typography>
            <Typography variant="h6">
              {' '}
              {formatCurrency(invoice.totalValue)}{' '}
            </Typography>
          </Box>
          <Typography color="textSecondary" variant="body2">
            {t('details.sections.fiscalDocuments.drawer.invoiceKey')}
          </Typography>
          <Typography variant="body2"> {invoice.key} </Typography>
          <Box pt={4}>
            <Divider />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

FiscalDrawerContent.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number,
      totalValue: PropTypes.number,
      invoiceKey: PropTypes.number
    })
  ).isRequired
};

export default FiscalDrawerContent;
