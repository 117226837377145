import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDay, formatHour } from '@loggi/components/src/one/date-utils';

export default function ReceiptOrderScheduleDetails({ receiptOrderSchedule }) {
  const { t } = useTranslation('receiptOrderSchedule');

  const orderIdBoxStyles = makeStyles({
    fontColor: {
      mixBlendMode: 'screen',
      position: 'relative',
      color: 'initial',
      '&:before': {
        mixBlendMode: 'color-burn',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        content: '""'
      }
    }
  });

  return (
    <>
      <Typography variant="h4">
        <strong>{t('header.message')}</strong>
      </Typography>

      <Box display="flex" pb={1} pt={2}>
        <Box
          bgcolor="common.white"
          borderRadius={50}
          px={1.5}
          py={1}
          className={orderIdBoxStyles().fontColor}
        >
          <Typography variant="caption">
            <strong>
              {t('orderId.label')}: {receiptOrderSchedule.pickupOrderScheduleId}
            </strong>
          </Typography>
        </Box>
      </Box>
      <Box pb={1} pt={2}>
        <Typography variant="h6">
          <strong>
            {t('orderScheduleDate', {
              day: formatDay(receiptOrderSchedule.pickupOrderScheduleStartTime),
              start: formatHour(
                receiptOrderSchedule.pickupOrderScheduleStartTime
              ),
              end: formatHour(receiptOrderSchedule.pickupOrderScheduleEndTime)
            })}
          </strong>
        </Typography>
      </Box>
      <Box pb={1} pt={1}>
        <Typography>
          {`${receiptOrderSchedule.originAddress.fullAddress}, ${
            receiptOrderSchedule.originAddress.addressComplement
          }`}
        </Typography>
      </Box>
    </>
  );
}

ReceiptOrderScheduleDetails.propTypes = {
  receiptOrderSchedule: PropTypes.shape({
    pickupOrderScheduleId: PropTypes.string,
    pickupOrderScheduleStartTime: PropTypes.string,
    pickupOrderScheduleEndTime: PropTypes.string,
    originAddress: PropTypes.shape({
      fullAddress: PropTypes.string,
      addressComplement: PropTypes.string,
      placeId: PropTypes.string
    })
  }).isRequired
};
