import { ListItem, ListItemText, ButtonBase } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PickupReceiptInfo from './pickup-receipt-info.component';
import PickupReceiptConfirmation from './pickup-receipt-confirmation.component';
import { PICKUP_RECEIPT_PACKAGES_ROUTE } from '../../../routes/constants';

const getSecondaryListItemComponent = (
  pickupOrderScheduleHash,
  pickupReceipt,
  pickupReceipts,
  setPickupReceipts
) => {
  const { alreadyAnswered, limitForResponseExpired } = pickupReceipt;

  if (!alreadyAnswered && limitForResponseExpired === true) {
    return null;
  }

  return (
    <PickupReceiptConfirmation
      pickupOrderScheduleHash={pickupOrderScheduleHash}
      pickupReceipt={pickupReceipt}
      pickupReceipts={pickupReceipts}
      setPickupReceipts={setPickupReceipts}
    />
  );
};

const PickupReceiptsListItem = props => {
  const {
    pickupOrderScheduleHash,
    pickupReceipt,
    pickupReceipts,
    setPickupReceipts
  } = props;
  const {
    driverName,
    licensePlate,
    packages,
    pickupEndTime,
    transportType
  } = pickupReceipt;
  const secondaryComponent = getSecondaryListItemComponent(
    pickupOrderScheduleHash,
    pickupReceipt,
    pickupReceipts,
    setPickupReceipts
  );

  const history = useHistory();

  return (
    <ListItem disableGutters component="div">
      <ListItemText
        disableTypography
        primary={
          <ButtonBase
            onClick={() => {
              history.push({
                pathname: PICKUP_RECEIPT_PACKAGES_ROUTE.url(
                  pickupOrderScheduleHash,
                  pickupReceipt.pickupReceiptId
                ),
                state: { pickupReceipts }
              });
            }}
            style={{ width: '100%' }}
            data-testid="showPackagesButton"
          >
            <PickupReceiptInfo
              driverName={driverName}
              licensePlate={licensePlate}
              pickupEndTime={pickupEndTime}
              packages={packages}
              transportType={transportType}
              pickupReceiptId={pickupReceipt.pickupReceiptId}
              pickupReceipts={pickupReceipts}
              pickupOrderScheduleHash={pickupOrderScheduleHash}
            />
          </ButtonBase>
        }
        secondary={secondaryComponent}
      />
    </ListItem>
  );
};

PickupReceiptsListItem.propTypes = {
  pickupReceipt: PropTypes.shape({
    pickupReceiptId: PropTypes.string,
    driverName: PropTypes.string,
    transportType: PropTypes.string,
    licensePlate: PropTypes.string,
    pickupEndTime: PropTypes.string,
    packages: PropTypes.arrayOf(
      PropTypes.shape({
        trackingKey: PropTypes.string,
        recipientName: PropTypes.string
      })
    ),
    alreadyAnswered: PropTypes.bool,
    limitForResponseExpired: PropTypes.bool,
    shipperConfirmedTotalPackages: PropTypes.bool
  }).isRequired,
  pickupReceipts: PropTypes.arrayOf(PropTypes.shape).isRequired,
  setPickupReceipts: PropTypes.func.isRequired,
  pickupOrderScheduleHash: PropTypes.string.isRequired
};

export default PickupReceiptsListItem;
