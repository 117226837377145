import { ReactComponent as WarehouseDeliveryCheckmark } from '@loggi/components/src/one/shared/images/warehouse-delivery-checkmark.svg';
import { Box, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSchedulings from '../../useSchedulings';
import SchedulingFilters from '../scheduling-filters/scheduling-filters';
import SchedulingListWrapper from '../scheduling-list-wrapper';

const SchedulingsList = () => {
  const { t } = useTranslation('scheduling');
  const [schedulingsData, itemsCount, isLoading] = useSchedulings();
  const { typography } = useTheme();

  const SchedulingsListHeader = () => (
    <Box display="flex" flexDirection="row">
      <Box mr={2}>
        <WarehouseDeliveryCheckmark />
      </Box>
      <Typography
        variant="subtitle1"
        style={{ fontWeight: typography.fontWeightMedium }}
      >
        {t('schedulingsList.header')}
      </Typography>
    </Box>
  );

  return (
    <>
      <Box px={{ xs: 3, md: 4 }} pt={4}>
        <SchedulingsListHeader />
        <SchedulingFilters />
      </Box>
      <SchedulingListWrapper
        data={schedulingsData}
        count={itemsCount}
        isLoading={isLoading}
      />
    </>
  );
};

export default SchedulingsList;
