import { Box, Hidden, makeStyles } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(({ palette, typography }) => ({
  errorMessage: {
    color: palette.info.dark,
    fontSize: typography.body2.fontSize,
    maxHeight: typography.pxToRem(48)
  }
}));

const ErrorAlert = ({ message }) => {
  const styles = useStyles();

  return (
    <>
      <Hidden smDown>
        <Box display="flex" justifyContent="center" py={2}>
          <Alert
            className={styles.errorMessage}
            icon={<Error fontSize="small" />}
            severity="error"
          >
            {message}
          </Alert>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box color="error.main" fontWeight="fontWeightBold" py={1}>
          {message}
        </Box>
      </Hidden>
    </>
  );
};

ErrorAlert.propTypes = {
  message: PropTypes.string.isRequired
};

export default ErrorAlert;
