import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Box } from '@material-ui/core';

const ReportDownloadHeader = () => {
  const { t } = useTranslation('packages');

  return (
    <>
      <Typography variant="h5">
        <Box fontWeight="fontWeightMedium" component="strong">
          {t('customReport.title')}
        </Box>
      </Typography>
      <Typography variant="body2">{t('customReport.subtitle')}</Typography>
    </>
  );
};

export default ReportDownloadHeader;
