import React, { useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AccessDenied } from '@loggi/authentication/src/screens';
import { useRemoteConfig } from '@loggi/components/src/one/remote-config';
import {
  ACCESS_DENIED_ROUTE,
  SIGN_UP_COMPANY_ROUTE,
  UPDATE_COMPANY_PICKUP_ROUTE,
  UPDATE_COMPANY_CONTACT_ROUTE
} from './routes/constants';
import { hasDataOnContext } from './sign-up-company-storage';
import SignupCompanyContext, { defaultValue } from './sign-up-company-context';
import SignupCompanyForm from './steps/sign-up-company-form';
import CompanyContactForm from './steps/company-contact-form';
import CompanyPickupForm from './steps/company-pickup-form';

const SignupCompany = () => {
  const { value: enableCompanySignupEnrichment } = useRemoteConfig(
    'enable_company_signup_enrichment'
  );
  const location = useLocation();
  const companySignupEnrichmentEnabled =
    enableCompanySignupEnrichment === 'true';

  const [context, setContext] = useState({
    ...defaultValue,
    setSignUpCompanyStepValues: values => {
      setContext(state => ({ ...state, signUpCompanyStepValues: values }));
    },
    setCompanyId: id => {
      setContext(state => ({ ...state, companyId: id }));
    },
    setUpdateCompanyPickupStepValues: values => {
      setContext(state => ({
        ...state,
        updateCompanyPickupStepValues: values
      }));
    },
    setUpdateCompanyContactStepValues: values => {
      setContext(state => ({
        ...state,
        updateCompanyContactStepValues: values
      }));
    }
  });

  const {
    companyId,
    signUpCompanyStepValues,
    updateCompanyPickupStepValues,
    updateCompanyContactStepValues
  } = context;

  const hasCompany = hasDataOnContext(companyId);
  const addNewCompany = Boolean(location?.state?.addNewCompany);

  const renderSignupEnrichmentSteps =
    companySignupEnrichmentEnabled && !hasCompany;

  const renderPickupFormStep =
    renderSignupEnrichmentSteps &&
    hasDataOnContext(signUpCompanyStepValues) &&
    !hasDataOnContext(updateCompanyPickupStepValues);

  const renderContactFormStep =
    renderSignupEnrichmentSteps &&
    hasDataOnContext(updateCompanyPickupStepValues) &&
    !hasDataOnContext(updateCompanyContactStepValues);

  return (
    <SignupCompanyContext.Provider value={context}>
      <Switch>
        {(!hasCompany || addNewCompany) && (
          <Route
            exact
            path={SIGN_UP_COMPANY_ROUTE}
            component={SignupCompanyForm}
          />
        )}
        {(renderPickupFormStep || addNewCompany) && (
          <Route
            exact
            path={UPDATE_COMPANY_PICKUP_ROUTE}
            component={CompanyPickupForm}
          />
        )}
        {(renderContactFormStep || addNewCompany) && (
          <Route
            exact
            path={UPDATE_COMPANY_CONTACT_ROUTE}
            component={CompanyContactForm}
          />
        )}
        <Route exact path={ACCESS_DENIED_ROUTE} component={AccessDenied} />
        <Redirect to={SIGN_UP_COMPANY_ROUTE} />
      </Switch>
    </SignupCompanyContext.Provider>
  );
};

export default SignupCompany;
