// Default validation
export const validateMessageSchema = (schema, message) => {
  const schemaKeys = Object.keys(schema);
  const messageKeys = Object.keys(message);

  // Check if some type not match
  const messageTypesAreInvalid = schemaKeys.some(
    // eslint-disable-next-line valid-typeof
    schemaKey => typeof message[schemaKey] !== schema[schemaKey]
  );

  // Check if message keys and schema keys lengths are equal
  const schemaLength = schemaKeys.length;
  const messageLength = messageKeys.length;
  const keyLengthsAreDifferent = schemaLength !== messageLength;

  // Check if keys names are equal
  const keysAreDifferent = schemaKeys.some(
    schemaKey => !messageKeys.includes(schemaKey)
  );

  return !(
    messageTypesAreInvalid ||
    keyLengthsAreDifferent ||
    keysAreDifferent
  );
};

export default validateMessageSchema;
