import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import { DEFAULT_PAGE_SIZE, FIRST_PAGE } from '../service/constants';

/**
 * This component's responsibilities are to show the pagination info, and allow
 * the user to navigate to others pages with the HistoryApi provided by react-router-dom
 * @componentType container
 * @param count
 * @param isLoading
 * @returns {null | *}
 * @constructor
 */
const UsersListPagination = ({ count, isLoading }) => {
  const { t } = useTranslation('listUsers');
  const queryParams = useQueryParams();
  const { pageToken = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE } = queryParams;
  const [page, setPage] = useState(pageToken / pageSize);

  const history = useHistory();
  const onChangePage = useCallback(
    (_, nextPage) => {
      setPage(nextPage);
      const params = {
        ...queryParams,
        pageSize: DEFAULT_PAGE_SIZE,
        pageToken: nextPage * pageSize
      };
      history.push(
        // Encoding should be off because we want a "comma" separator in the URL.
        // We will encode later, when sending the request.
        qs.stringify(params, { addQueryPrefix: true, encode: false })
      );
    },
    [history, queryParams, pageSize]
  );

  // There's no pagination if there are no items.
  if (typeof count !== 'number' || count < 1) {
    return null;
  }
  const tablePage =
    (page > 0 && count <= DEFAULT_PAGE_SIZE) || pageToken === FIRST_PAGE
      ? FIRST_PAGE
      : page;
  return (
    <TablePagination
      component="div"
      data-testid="list-users-table-pagination"
      onChangePage={onChangePage}
      page={tablePage}
      count={count}
      labelDisplayedRows={({ from, to, count: total }) =>
        t('pagination.row', { from, to, count: total })
      }
      rowsPerPage={Number(pageSize)}
      rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
      nextIconButtonProps={{
        disabled: isLoading || (tablePage + 1) * pageSize >= count
      }}
      backIconButtonProps={{
        disabled: isLoading || tablePage === FIRST_PAGE
      }}
    />
  );
};

UsersListPagination.propTypes = {
  count: PropTypes.number.isRequired,
  isLoading: PropTypes.bool
};

UsersListPagination.defaultProps = {
  isLoading: false
};

export default UsersListPagination;
