import React from 'react';
import { NameField, PhoneField } from '@loggi/components/src/one/formik-fields';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SectionTitle from './section-title.component';
import InstructionsField from './instructions-field.component';

const OriginSection = () => {
  const { t } = useTranslation('unifiedPickup');

  return (
    <>
      <SectionTitle>{t('originSection.title')}</SectionTitle>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NameField
            fieldName="pickupOriginName"
            shouldRenderRequiredAsterisk={false}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneField
            fieldName="pickupOriginPhone"
            shouldRenderRequiredAsterisk={false}
          />
        </Grid>
        <Grid item xs={12}>
          <InstructionsField
            fieldName="pickupOriginInstructions"
            isRequired={false}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OriginSection;
