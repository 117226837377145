import { dispatchEvent } from '@loggi/components/src/one/useSubscription/useSubscription';

const allowedEvents = ['notification:status'];

const iosDispatchEventInterface = (event, data) => {
  if (allowedEvents.includes(event)) {
    dispatchEvent(event, data);
  }
};

export default iosDispatchEventInterface;
