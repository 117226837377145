import { loggiWebApi } from '@loggi/authentication-lib';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from '../toast';
import useDownloadCallback from './use-download-callback.hook';

const getLabels = (companyId, trackingKeys) =>
  loggiWebApi
    .content('application/json')
    .accept('application/pdf')
    .url(`/one/api/packages/v1/companies/${companyId}/packages/labels/`)
    .post({ tracking_keys: trackingKeys });

const useDownloadPackageLabelsCallback = (
  companyId,
  trackingKeys,
  fileName
) => {
  const [blobUrl, setBlobUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('details');
  const { enqueueSnackbar } = useSnackbar();
  const downloadCallback = useDownloadCallback();

  const showLabelDownloadErrorToast = useCallback(() => {
    enqueueSnackbar(t('packageDetails.packageSuccess.errorDownloadingLabel'), {
      content: (key, message) => (
        <Toast id={key} message={message} type="error" />
      )
    });
  }, [enqueueSnackbar, t]);

  const downloadPdf = useCallback(
    () =>
      getLabels(companyId, trackingKeys)
        .blob(blob => {
          const url = URL.createObjectURL(blob);
          setBlobUrl(url);
          return url;
        })
        .catch(showLabelDownloadErrorToast),
    [companyId, showLabelDownloadErrorToast, trackingKeys]
  );

  // with this whenever this component is unmount or a new objectURL is created
  // the current one is revoked
  const revokeURL = useCallback(() => {
    if (blobUrl) URL.revokeObjectURL(blobUrl);
  }, [blobUrl]);
  useEffect(() => revokeURL, [revokeURL]);

  const triggerDownloadLabels = useCallback(async () => {
    if (blobUrl) {
      downloadCallback(blobUrl, fileName);
    } else {
      setLoading(true);
      downloadCallback(await downloadPdf(), fileName);
      setLoading(false);
    }
  }, [blobUrl, downloadCallback, downloadPdf, fileName]);

  const triggerMultipleDownloadLabels = useCallback(async () => {
    setBlobUrl('');
    setLoading(true);
    downloadCallback(await downloadPdf(), fileName);
    setLoading(false);
  }, [downloadCallback, downloadPdf, fileName]);

  return { triggerDownloadLabels, loading, triggerMultipleDownloadLabels };
};

export default useDownloadPackageLabelsCallback;
