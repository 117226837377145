import { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '@loggi/components/src/one/hooks';

import exceptionHandler from '../exception-handler';
import EmptySearchException from '../exceptions/empty-search.exception';
import PackagesSearchException from '../exceptions/packages-search.exception';
import packagesApi from '../packages-api';
import {
  FIRST_PAGE,
  FIRST_PAGE_TOKEN,
  DEFAULT_PAGE_SIZE,
  PARAM_URL_RETURN,
  BACKEND_URL_RETURN
} from './constants';
import {
  normalizeSearchData,
  buildPackageHash
} from './packages-list.formatters';

const initialState = {
  data: undefined,
  totalItems: undefined,
  error: false,
  isLoading: true,
  pageTokens: {
    [FIRST_PAGE]: String(FIRST_PAGE_TOKEN)
  }
};

/**
 * This hook adds an abstraction layer between the component and the fetch for
 * the Package resource.
 * It gets the pagination params directly from the useQueryParams hook, and fetches
 * the data using the packagesApi, also adding a loading interface
 * @returns {[undefined, null, boolean, boolean]}
 */
const usePackages = ({
  page: forcedPage,
  pageSize: forcePageSize,
  status: forcedStatus
}) => {
  const { companyId } = useParams();
  const {
    busca,
    pageSize = forcePageSize,
    pagina = forcedPage,
    periodo,
    status = forcedStatus,
    direcao,
    periodoInicial,
    periodoFinal,
    integrator,
    searchCompanies
  } = useQueryParams();
  const [state, setState] = useReducer(
    (curState, newState) => ({ ...curState, ...newState }),
    initialState
  );
  const { data, totalItems, isLoading, pageTokens, error } = state;
  const MIN_SEARCH_SIZE = 3;

  const getPageToken = page => {
    if (pageTokens?.[pagina]) {
      return pageTokens?.[pagina];
    }

    /**
     * When the pagination is settled on URL directly, we don't have the page token information,
     * so it is necessary to calculate this page token through this function
     */
    const calculatedPageToken = (page - 1) * DEFAULT_PAGE_SIZE - 1;
    return calculatedPageToken > 0 ? calculatedPageToken : FIRST_PAGE_TOKEN;
  };

  const pageToken = getPageToken(pagina);

  useEffect(() => {
    // user already has search data, but the search term is still less than MIN_SEARCH_SIZE
    const isMinSearchSize = Boolean(busca && busca.length < MIN_SEARCH_SIZE);

    // user accessed a URL with a search term less than MIN_SEARCH_SIZE
    // ex. pacotes?busca=ae
    if (isMinSearchSize) {
      setState({ isLoading: false });
      return;
    }

    setState({ isLoading: true });

    const mappedDirection = () => {
      return direcao === PARAM_URL_RETURN ? BACKEND_URL_RETURN : direcao;
    };

    const fetchPackages = async (params = {}) => {
      return packagesApi
        .search(companyId, params)
        .then(response => {
          if (!response.packages?.length) {
            throw new EmptySearchException();
          }
          return normalizeSearchData(response);
        })
        .then(response => buildPackageHash(response))
        .then(searchResponse => {
          const nextPage = (pagina ? Number(pagina) : FIRST_PAGE) + 1;
          return {
            data: searchResponse.packagesHash,
            totalItems: searchResponse.total,
            pageTokens: {
              [nextPage]: searchResponse.nextPageToken
            }
          };
        });
    };

    const urlParams = {
      created: periodo,
      pageSize,
      pageToken,
      query: busca,
      shipperStatus: status,
      packageDirection: mappedDirection(),
      from: periodoInicial,
      to: periodoFinal,
      integratorId: integrator,
      searchCompanies
    };

    fetchPackages(urlParams)
      .then(response => {
        setState({
          ...response,
          error: false,
          isLoading: false
        });
      })
      .catch(exception => {
        if (exception instanceof EmptySearchException) {
          setState({
            ...initialState,
            error: false,
            isLoading: false
          });
        } else {
          const requestError = new PackagesSearchException(
            'Failed to search packages'
          );
          exceptionHandler.warning(requestError, {
            busca,
            pagina,
            companyId,
            direcao,
            httpStatus: exception.status
          });

          setState({
            ...initialState,
            error: true,
            isLoading: false
          });
        }
      });
  }, [
    companyId,
    busca,
    pagina,
    status,
    periodo,
    direcao,
    pageSize,
    periodoInicial,
    periodoFinal,
    pageToken,
    integrator,
    searchCompanies
  ]);

  const packages = Object.values(data?.byId || {});

  return [packages, totalItems, isLoading, error];
};

export default usePackages;
