import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import emptyPickupsIllustration from '@loggi/components/src/one/shared/images/map-pointer.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles(({ typography }) => ({
  image: {
    width: '100%',
    maxWidth: typography.pxToRem(88)
  }
}));

const DeliveryProofDrawerError = ({ refreshErrorDrawer }) => {
  const classes = useStyles();
  const { t } = useTranslation('packages');

  return (
    <Box>
      <Box pt={3} pb={6}>
        <Typography variant="h4">
          <strong>{t('details.sections.deliveryDetails.drawer.title')}</strong>
        </Typography>
      </Box>
      <Box
        data-testid="delivery-proof-drawer-error"
        display="flex"
        alignContent="center"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        mx={5}
        mt={2}
      >
        <img
          className={classes.image}
          src={emptyPickupsIllustration}
          alt="Error"
        />
        <Box mt={2} mb={3}>
          <Box mb={1}>
            <Typography variant="h6">
              <strong>
                {t('details.sections.deliveryDetails.drawer.error.title')}
              </strong>
            </Typography>
          </Box>
          <Box mx={3}>
            <Typography variant="body1" component="p" color="textSecondary">
              {t('details.sections.deliveryDetails.drawer.error.subtitle')}
            </Typography>
          </Box>
        </Box>
        <Button
          data-testid="refresh-btn"
          onClick={() => refreshErrorDrawer()}
          color="primary"
        >
          {t(`details.sections.deliveryDetails.drawer.error.linkText`)}
        </Button>
      </Box>
    </Box>
  );
};

DeliveryProofDrawerError.propTypes = {
  refreshErrorDrawer: PropTypes.func.isRequired
};

export default DeliveryProofDrawerError;
