import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import OneTemplate, {
  OneTemplateSummary,
  OneTemplateContent
} from '@loggi/components/src/one/template';
import { PackageFactory } from '@loggi/components/src/one/package-utils';

import fetchTrackingApi from '../tracking-api';
import DetailsPackagesError from './details-packages-error';
import DetailsSummary from './details-summary.component';
import DetailsContent from './details-content.component';

const initialState = {
  isLoading: true,
  data: null,
  error: false
};

const Details = () => {
  const [{ data, isLoading, error }, setState] = useState(initialState);
  const { companyId, trackingKey } = useParams();

  useEffect(() => {
    fetchTrackingApi
      .details(companyId, [trackingKey])
      .then(response => {
        return PackageFactory.trackingAPI.create(response.packages[0]);
      })
      .then(pkg => setState({ isLoading: false, data: pkg, error: false }))
      .catch(() => setState({ isLoading: false, data: null, error: true }));
  }, [companyId, trackingKey]);
  const showContent = !isLoading && !error;
  const showError = !isLoading && error;

  return (
    <>
      <OneTemplate>
        <OneTemplateSummary>
          {showContent && <DetailsSummary data={data} />}
        </OneTemplateSummary>
        <OneTemplateContent>
          {showContent && <DetailsContent data={data} />}
          {showError && <DetailsPackagesError />}
        </OneTemplateContent>
      </OneTemplate>
    </>
  );
};

export default Details;
