import Toast from '@loggi/components/src/one/toast';
import React from 'react';

const sendNotificationMessage = (
  notificationMessage,
  notificationType,
  snackbar
) => {
  snackbar(notificationMessage, {
    content: (key, message) => (
      <Toast id={key} message={message} type={notificationType} />
    )
  });
};

export default sendNotificationMessage;
