import { loggiWebApi } from '@loggi/authentication-lib';
import { PACKAGE_DIRECTION_DELIVERY } from '@loggi/components/src/one/constants';
import packageStatusFilter from './constants';
import { PACKAGE_LIST_PAGE_SIZE } from '../../unified-pickup/constants';

const PackagesApi = {
  list: ({
    companyId,
    query,
    pageToken = 0,
    packagesCreatedAtDays = 30,
    pageSize = PACKAGE_LIST_PAGE_SIZE
  }) => {
    const packageStatuses = Object.values(packageStatusFilter).join(',');

    const urlParams = new URLSearchParams({
      page_size: pageSize,
      page_token: pageToken,
      query: query || '',
      package_status: packageStatuses,
      created: packagesCreatedAtDays,
      package_direction: PACKAGE_DIRECTION_DELIVERY,
      search_companies: companyId
    });

    const url = `/one/api/packages/v1/companies/${companyId}/packages:search/?${urlParams}`;

    return loggiWebApi
      .url(url)
      .accept('application/json')
      .content('application/json')
      .get()
      .json();
  }
};

export default PackagesApi;
