const ROLES = {
  1: 'owner',
  2: 'admin',
  3: 'regular',
  6: 'support',
  7: 'operator',
  8: 'finance',
  9: 'owner_large',
  10: 'manager_large',
  11: 'admin_large',
  12: 'operator_large'
};

const USER_STATUS = {
  active: 2,
  pending: 1,
  revoked: 3,
  expired: 4
};

const KNOWN_ERROR_CODES = {
  5: 'notificationMessages.errors.notFound',
  6: 'notificationMessages.errors.alreadyExists',
  default: 'notificationMessages.errors.default'
};

const INVITE_USER_BTN_ID = 'invite-user-btn-id';
const RESEND_INVITE_USER_BTN_ID = 'resend-user-invite-btn-id';
const UPDATE_BTN_ID = 'update-btn-id';

export {
  KNOWN_ERROR_CODES,
  INVITE_USER_BTN_ID,
  RESEND_INVITE_USER_BTN_ID,
  ROLES,
  USER_STATUS,
  UPDATE_BTN_ID
};
