import { loggiWebApi } from '@loggi/authentication-lib';
import {
  ORDER_SCHEDULES_ENDPOINT,
  PICKUPS_COMPANIES_ENDPOINT
} from '../../constants';

/**
 * Implements a Promise interface that fetches SchedulingPackages API
 * and retrieves all pickups of a given scheduling order id.
 * @param {number} schedulingId
 * @param {number} companyId
 * @param {number} pageSize
 * @param {number} pageToken
 * @returns {Promise<>}
 */
const fetchSchedulingPackages = (
  schedulingId,
  companyId,
  pageSize,
  pageToken
) => {
  return loggiWebApi
    .url(
      `/${PICKUPS_COMPANIES_ENDPOINT}/${companyId}/${ORDER_SCHEDULES_ENDPOINT}/${schedulingId}/packages/?page_token=${pageToken}&page_size=${pageSize}`
    )
    .get()
    .json();
};

export default fetchSchedulingPackages;
