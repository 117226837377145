import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Dialog, SwipeableDrawer, Hidden } from '@material-ui/core';

import PackageRecipientEditionModalContent from './package-recipient-edition-modal-content';

const useStyles = makeStyles(({ spacing }) => ({
  drawer: {
    '& .MuiDrawer-paper.MuiPaper-root': {
      borderRadius: spacing(2, 2, 0, 0)
    }
  }
}));

/**
 * PackageRecipientEditionModal's responsibility is to show a modal (dialog or drawer) to confirm
 * a package recipient edition intention, with the params it receives.
 * @componentType: Presentational
 * @param companyId
 * @param trackingKey
 * @param open
 * @param handleClose
 * @param oldPhone
 * @param address
 * @param complement
 * @param placeId
 * @param recipientName
 * @param recipientPhone
 * @param isPackageStatusAdded
 * @returns {*}
 */
const PackageRecipientEditionModal = ({
  companyId,
  trackingKey,
  open,
  handleClose,
  oldPhone,
  address,
  complement,
  placeId,
  recipientName,
  recipientPhone,
  isPackageStatusAdded,
  enablePackageRecipientEdition
}) => {
  const styles = useStyles();

  const ModalContent = (
    <PackageRecipientEditionModalContent
      companyId={companyId}
      trackingKey={trackingKey}
      handleClose={handleClose}
      oldPhone={oldPhone}
      address={address}
      complement={complement}
      placeId={placeId}
      recipientName={recipientName}
      recipientPhone={recipientPhone}
      isPackageStatusAdded={isPackageStatusAdded}
      enablePackageRecipientEdition={enablePackageRecipientEdition}
    />
  );

  return (
    <>
      <Hidden smUp>
        <SwipeableDrawer
          className={styles.drawer}
          data-testid="drawer"
          anchor="bottom"
          open={open}
          onOpen={() => {}}
          onClose={handleClose}
        >
          {ModalContent}
        </SwipeableDrawer>
      </Hidden>
      <Hidden xsDown>
        <Dialog
          data-testid="dialog"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {ModalContent}
        </Dialog>
      </Hidden>
    </>
  );
};

PackageRecipientEditionModal.propTypes = {
  companyId: PropTypes.string.isRequired,
  trackingKey: PropTypes.string.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  oldPhone: PropTypes.string,
  address: PropTypes.string.isRequired,
  complement: PropTypes.string.isRequired,
  placeId: PropTypes.string.isRequired,
  recipientName: PropTypes.string.isRequired,
  recipientPhone: PropTypes.string.isRequired,
  isPackageStatusAdded: PropTypes.bool.isRequired,
  enablePackageRecipientEdition: PropTypes.bool.isRequired
};

PackageRecipientEditionModal.defaultProps = {
  open: false,
  oldPhone: ''
};

export default PackageRecipientEditionModal;
