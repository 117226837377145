import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useMediaQuery,
  Hidden,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  DeliveryService,
  IntegrationsConfiguration,
  Tracking,
  LogisticsGif,
  OrderCreation,
  PrintLabelGif,
  PrintLabel,
  ConfigGeneral
} from '../assets';
import { integratorDescriptionStyles, MENU_PROPS } from '../styles';

const useStyles = makeStyles(({ typography }) => ({
  list: {
    display: 'list-item',
    '&::marker': {
      fontSize: typography.subtitle2.fontSize
    },
    padding: 0
  },
  images: {
    height: '100%',
    width: '100%'
  },
  listDecimal: {
    listStyle: 'decimal'
  }
}));

const IntegratorBlingTutorial = () => {
  const blingStyles = useStyles();
  const descriptionStyles = integratorDescriptionStyles();
  const { t } = useTranslation('management');
  const smallerDevices = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const description = 'blingTutorial.description.';
  const trackingSteps = t(`${description}tracking.steps`, {
    returnObjects: true
  });
  const spreadsheetColumns = t(`${description}howTo.columns`, {
    returnObjects: true
  });
  const createOrderSteps = t(`${description}createOrder.steps`, {
    returnObjects: true
  });
  const listConfigurations = t(`${description}settings.columns`, {
    returnObjects: true
  });
  const listQuestionsLinks = t('integrations.questions.links', {
    returnObjects: true
  });
  const listPrintLabelSteps = t(`${description}printLabel.steps`, {
    returnObjects: true
  });
  let menuOptions = [];
  menuOptions = t('blingTutorial.menu.options', {
    returnObjects: true
  });

  return (
    <Box display="flex" flexDirection={smallerDevices ? 'column' : 'row'}>
      <Hidden smDown>
        <Box padding={1} flexGrow={1} flexBasis={0}>
          <MenuList className={descriptionStyles.stickyMenu}>
            <Typography
              variant="h6"
              className={descriptionStyles.titlesMenuLink}
            >
              {t(`${description}index`)}
            </Typography>
            {menuOptions.map(({ id, title }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={index} value={id} marginLeft={2}>
                <Link href={`#${id}`} className={descriptionStyles.titleMenu}>
                  {title}
                </Link>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box padding={1} flexGrow={1} flexBasis={0} marginBottom={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{t('blingTutorial.menu.title')}</InputLabel>
            <Select
              label={t('blingTutorial.menu.title')}
              MenuProps={MENU_PROPS}
            >
              {menuOptions.map(({ id, title }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={index} value={id} marginLeft={2}>
                  <Link href={`#${id}`} className={descriptionStyles.titleMenu}>
                    {title}
                  </Link>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Hidden>
      <Box width="inherit" padding={1} flexGrow={2} flexBasis={0}>
        <Box>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="pre-requisitos"
          >
            {t(`${description}requirements.title`)}
          </Typography>
          <Typography variant="subtitle2">
            <Box my={2}>
              {t(`${description}requirements.subtitle`)}
              <Link
                href={t(`${description}requirements.blingHelpCenterLink.url`)}
                target="_blank"
              >
                <styles className={descriptionStyles.link}>
                  {t(`${description}requirements.blingHelpCenterLink.title`)}
                </styles>
              </Link>
            </Box>
            <Box my={2}>
              {t(`${description}requirements.first`)}
              <strong>{t(`${description}requirements.textStrong`)}</strong>
              {t(`${description}requirements.second`)}
            </Box>
          </Typography>
        </Box>
        <Box my={4}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="configuracoes"
          >
            {t(`${description}settings.title`)}
          </Typography>
          <Typography variant="subtitle2">
            <Box my={2}>
              {t(`${description}settings.subtitle.first`)}
              <strong>
                {t(`${description}settings.subtitle.textStrongFirst`)}
              </strong>
              {t(`${description}settings.subtitle.second`)}
              <strong>
                {t(`${description}settings.subtitle.textStrongSecond`)}
              </strong>
            </Box>
            <Box my={2}>{t(`${description}settings.subtitle.third`)}</Box>
          </Typography>
          <Box my={2} className={descriptionStyles.boxGifs}>
            <img
              src={LogisticsGif}
              alt={t(`${description}settings.logisticsGif`)}
              className={blingStyles.images}
            />
          </Box>
          <List>
            <Box display="flex" flexDirection="column">
              {listConfigurations.map((item, index) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={`${blingStyles.list} ${blingStyles.listDecimal}`}
                >
                  <ListItemText
                    primary={
                      <Typography>
                        <strong>{item.title}</strong>
                        {item.content}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </Box>
          </List>
          <Box mt={2}>
            <img
              src={IntegrationsConfiguration}
              alt={t(`${description}settings.integrationsConfigurationPng`)}
              className={blingStyles.images}
            />
          </Box>
        </Box>
        <Box mt={4}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="configuracoes-gerais"
          >
            {t(`${description}configGeneral.title`)}
          </Typography>
          <List>
            <Box display="flex" flexDirection="column">
              <ListItem
                className={`${blingStyles.list} ${blingStyles.listDecimal}`}
              >
                <ListItemText
                  primary={
                    <Typography>
                      <strong>
                        {t(`${description}configGeneral.first.strong`)}
                      </strong>
                      {t(`${description}configGeneral.first.content`)}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                className={`${blingStyles.list} ${blingStyles.listDecimal}`}
              >
                <ListItemText
                  primary={
                    <Typography>
                      {t(`${description}configGeneral.second.content`)}
                      <strong>
                        {t(`${description}configGeneral.second.strongFirst`)}
                      </strong>
                      {t(`${description}configGeneral.second.or`)}
                      <strong>
                        {t(`${description}configGeneral.second.strongSecond`)}
                      </strong>
                    </Typography>
                  }
                />
              </ListItem>
            </Box>
          </List>
          <Box mt={2}>
            <img
              src={ConfigGeneral}
              alt={t(`${description}configGeneral.configGeneralPng`)}
              className={blingStyles.images}
            />
          </Box>
        </Box>
        <Box mt={4}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="rastreamento"
          >
            {t(`${description}tracking.title`)}
          </Typography>
          <List>
            <Box display="flex" flexDirection="column">
              {trackingSteps.map((step, index) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={`${blingStyles.list} ${blingStyles.listDecimal}`}
                >
                  <ListItemText
                    primary={
                      <Typography>
                        {step.first}
                        <strong>{step.strong}</strong>
                        {step.last}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </Box>
          </List>
          <Box mt={2}>
            <img
              src={Tracking}
              alt={t(`${description}tracking.trackingPng`)}
              className={blingStyles.images}
            />
          </Box>
        </Box>

        <Box mt={4}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="preenchimento-dos-servicos-de-entrega"
          >
            {t(`${description}deliveryServices.title`)}
          </Typography>
          <Typography variant="subtitle2">
            <Box my={2}>{t(`${description}deliveryServices.subtitle`)}</Box>
          </Typography>
          <Box mt={2}>
            <img
              src={DeliveryService}
              alt={t(`${description}deliveryServices.deliveryServicePng`)}
              className={blingStyles.images}
            />
          </Box>
          <Box my={2}>{t(`${description}deliveryServices.firstParagraph`)}</Box>
          <Box my={2}>
            <strong>
              {t(`${description}deliveryServices.secondParagraph.strongFirst`)}
            </strong>
            {t(`${description}deliveryServices.secondParagraph.content`)}
            <strong>
              {t(`${description}deliveryServices.secondParagraph.strongLast`)}
            </strong>
          </Box>
        </Box>

        <Box mt={4}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="como-utilizar-os-servicos"
          >
            {t(`${description}howTo.title`)}
          </Typography>
          <Typography variant="subtitle2">
            <Box mt={2}>{t(`${description}howTo.subtitle`)}</Box>
            <Box mt={2} fontWeight="bold" fontSize={18}>
              {t(`${description}howTo.reminder.title`)}
            </Box>
            <Box mt={2}>{t(`${description}howTo.reminder.description`)}</Box>
          </Typography>
          <List>
            <Box display="flex" flexDirection="column">
              {spreadsheetColumns.map((list, index) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={`${blingStyles.list} ${blingStyles.listDecimal}`}
                >
                  <ListItemText
                    primary={
                      <Typography>
                        <strong>{list.title}</strong>
                        {list.content}
                        {index === 2 ? (
                          <Link href={list.linkUrl} target="_blank">
                            <styles className={descriptionStyles.link}>
                              {list.linkText}
                            </styles>
                          </Link>
                        ) : (
                          <></>
                        )}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </Box>
          </List>
        </Box>
        <Box mt={4}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="criacao-de-pedidos-de-envios"
          >
            {t(`${description}createOrder.title`)}
          </Typography>
          <Typography variant="subtitle2">
            <Box mt={2}>
              {t(`${description}createOrder.subtitle.first`)}
              <strong>
                {t(`${description}createOrder.subtitle.textStrong`)}
              </strong>
              {t(`${description}createOrder.subtitle.second`)}
            </Box>
          </Typography>
          <Typography variant="subtitle2">
            <Box mt={2}>
              {t(`${description}createOrder.menu.first`)}
              <strong>{t(`${description}createOrder.menu.textStrong`)}</strong>
            </Box>
          </Typography>
          <List>
            <Box display="flex" flexDirection="column">
              {createOrderSteps.map((step, index) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={`${blingStyles.list} ${blingStyles.listDecimal}`}
                >
                  <ListItemText
                    primary={
                      <Typography>
                        {step.first}
                        <strong>{step.textStrong}</strong>
                        {step.last}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </Box>
          </List>
          <Box mt={2}>
            <img
              src={OrderCreation}
              alt={t(`${description}createOrder.createOrderPng`)}
              className={blingStyles.images}
            />
          </Box>
          <Typography variant="subtitle2">
            <Box mt={2} fontWeight="bold" fontSize={18}>
              {t(`${description}createOrder.reminder.title`)}
            </Box>
            <Typography variant="subtitle2">
              <Box mt={2}>
                {t(`${description}createOrder.verify.first`)}
                <strong>
                  {t(`${description}createOrder.verify.textStrong`)}
                </strong>
                {t(`${description}createOrder.verify.second`)}
              </Box>
            </Typography>
            <Box mt={2}>
              {t(`${description}createOrder.reminder.first`)}
              <strong>
                {t(`${description}createOrder.reminder.textStrong`)}
              </strong>
              {t(`${description}createOrder.reminder.second`)}
            </Box>
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="impressao-de-etiquetas"
          >
            {t(`${description}printLabel.title`)}
          </Typography>
          <Typography variant="subtitle2">
            <Box mt={2}>{t(`${description}printLabel.subtitle`)}</Box>
            <Box mt={2}>
              {t(`${description}printLabel.recommendation.first`)}
              <strong>
                {t(`${description}printLabel.recommendation.strongTexts.first`)}
              </strong>
              {t(`${description}printLabel.recommendation.second`)}
              <strong>
                {t(
                  `${description}printLabel.recommendation.strongTexts.second`
                )}
              </strong>
            </Box>
          </Typography>
          <List>
            <Box display="flex" flexDirection="column">
              {listPrintLabelSteps.map((item, index) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={`${blingStyles.list} ${blingStyles.listDecimal}`}
                >
                  <ListItemText
                    primary={
                      <Typography>
                        {item.text}
                        <strong>{item.strong}</strong>
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </Box>
          </List>
          <Box mt={2}>
            <img
              src={PrintLabel}
              alt={t(`${description}printLabel.printLabelPng`)}
              className={blingStyles.images}
            />
          </Box>
          <Box mt={2}>{t(`${description}printLabel.information`)}</Box>
          <Box mt={2} className={descriptionStyles.boxGifs}>
            <img
              src={PrintLabelGif}
              alt={t(`${description}printLabel.printLabelGif`)}
              className={blingStyles.images}
            />
          </Box>
          <Typography variant="subtitle2">
            <Box mt={2} fontWeight="bold" fontSize={18}>
              {t(`${description}printLabel.reminder.title`)}
            </Box>
            <Box mt={2}>
              {t(`${description}printLabel.reminder.first`)}
              <strong>
                {t(`${description}printLabel.reminder.strongTexts.first`)}
              </strong>
              {t(`${description}printLabel.reminder.second`)}
              <strong>
                {t(`${description}printLabel.reminder.strongTexts.second`)}
              </strong>
              {t(`${description}printLabel.reminder.third`)}
              <strong>
                {t(`${description}printLabel.reminder.strongTexts.third`)}
              </strong>
              {t(`${description}printLabel.reminder.fourth`)}
              <strong>
                {t(`${description}printLabel.reminder.strongTexts.fourth`)}
              </strong>
            </Box>
            <Box mt={2}>
              {t(`${description}printLabel.reminder.bottom.first`)}
              <strong>
                {t(`${description}printLabel.reminder.bottom.textStrong`)}
              </strong>
              {t(`${description}printLabel.reminder.bottom.second`)}
            </Box>
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography
            variant="h5"
            className={descriptionStyles.titlesMenuLink}
            id="duvidas-sobre-integracao"
          >
            {t('integrations.questions.title')}
          </Typography>
          <Box mt={2}>
            <List>
              <Box display="flex" flexDirection="column">
                {listQuestionsLinks.map((el, index) => (
                  <ListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={`${blingStyles.list} ${
                      descriptionStyles.listDisc
                    }`}
                  >
                    <ListItemText
                      primary={
                        <Typography>
                          <Link href={el.link} target="_blank">
                            <styles className={descriptionStyles.link}>
                              {el.text}
                            </styles>
                          </Link>
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </Box>
            </List>
          </Box>
        </Box>
        <Box mt={2}>
          <Typography variant="h6" className={descriptionStyles.titlesMenuLink}>
            {t('integrations.helpLoggi.title')}
          </Typography>
          <Box>
            <Link href="https://ajuda.loggi.com/hc/pt-br/requests/new">
              <styles className={descriptionStyles.link}>
                {t('integrations.helpLoggi.link')}
              </styles>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default IntegratorBlingTutorial;
