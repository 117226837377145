import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, Typography } from '@material-ui/core';
import { redirectToHelpCenter } from '@loggi/components/src/one';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { useFeature } from '@loggi/components/src/one/remote-config';

const MovementActionHelpCenter = () => {
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const [loading, setLoading] = useState(false);
  const { companyId } = useParams();
  const useZendeskUrl = useFeature('enable_redirect_to_zendesk_help_center');
  const { t } = useTranslation('packages');

  /* eslint no-void: ["error", { "allowAsStatement": true }] */
  return (
    <Button
      variant="contained"
      color="primary"
      data-testid="help-center-btn"
      disabled={loading}
      fullWidth
      onClick={() => {
        void (async () => {
          setLoading(true);
          await redirectToHelpCenter(
            companyId,
            authenticatedUser,
            useZendeskUrl
          );
          setLoading(false);
        })();
      }}
    >
      <Typography variant="body1" color="initial">
        {t('movementDrawer.actionsButtons.helpCenter')}
      </Typography>
    </Button>
  );
};

MovementActionHelpCenter.propTypes = {};

export default MovementActionHelpCenter;
