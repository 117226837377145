import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { Box, IconButton, Typography, withStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BACK_BUTTON_ID } from './constants';
import { PICKUP_FORM_STORAGE_KEY } from './formik-storage-watcher.component';
import OrderSchedulingForm from './order-scheduling-form';

const CustomIconButton = withStyles(({ spacing }) => ({
  root: {
    border: '1px solid',
    height: spacing(5),
    padding: spacing(1),
    width: spacing(5)
  }
}))(IconButton);

const Create = () => {
  const { t } = useTranslation('orderScheduling');
  const history = useHistory();

  const initialValues = useMemo(() => {
    const pickupFormValue = localStorage.getItem(PICKUP_FORM_STORAGE_KEY);
    try {
      return JSON.parse(pickupFormValue) ?? {};
    } catch {
      return {};
    }
  }, []);

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Box display="flex" alignItems="center">
          <Box mr={3}>
            <CustomIconButton
              color="inherit"
              data-testid={BACK_BUTTON_ID}
              onClick={() => history.goBack()}
            >
              <ArrowBack />
            </CustomIconButton>
          </Box>
          <Typography variant="h4" component="h1">
            {t('create.title')}
          </Typography>
        </Box>
      </OneTemplateSummary>
      <OneTemplateContent>
        <OrderSchedulingForm initialValues={initialValues} />
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default Create;
