import { SELECTED_PACKAGES_KEY } from './constants';

const selectedPackagesIsExpired = selectedPackages => {
  if (selectedPackages?.expirationHour) {
    const expirationHour = new Date(selectedPackages?.expirationHour);
    if (expirationHour >= new Date()) {
      return false;
    }
  }
  return true;
};

const getSelectedPackages = () => {
  const selectedPackages = JSON.parse(
    localStorage.getItem(SELECTED_PACKAGES_KEY)
  );
  if (selectedPackagesIsExpired(selectedPackages)) {
    localStorage.removeItem(SELECTED_PACKAGES_KEY);
    return new Map();
  }

  return new Map(Object.entries(selectedPackages?.packages));
};

export default getSelectedPackages;
