import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import SummaryDateItem from './summary-date-item.component';

/**
 * PackageDeliveredDate only responsibility is to show the delivery date info.
 * @componentType: Presentational
 * @param date
 * @returns {*}
 */
const PackageDeliveredDate = ({ date }) => {
  const { t } = useTranslation('packages');

  return (
    <SummaryDateItem
      date={date}
      label={t('details.labels.deliveredAt')}
      dataTestId="delivered-date-info"
    />
  );
};

PackageDeliveredDate.propTypes = {
  date: PropTypes.string.isRequired
};

export default PackageDeliveredDate;
