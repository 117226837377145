import React, { useEffect } from 'react';
import { number } from 'prop-types';
import useNotificationStatus, { constants } from './use-notification-status';
import NotificationDialog from './notification-dialog';
import NotificationSubscription from './notification-subscription';
import { notificationListenner } from '../firebase/notifications';
import iosDispatchEventInterface from '../iosDispatchEventInterface';

const { NOTIFICATION_SCOPE, LOADING_STATUS } = constants;

const Notification = ({ companyId }) => {
  const { status, scope, permission, token } = useNotificationStatus();

  if (scope === NOTIFICATION_SCOPE.SUPPORTED) {
    // Firebase Android/Web listenner
    notificationListenner();
  } else if (scope === NOTIFICATION_SCOPE.IOS_SUPPORTED) {
    // Exposing dispatch interface for iOS native App
    window.globalDispatchEvent = iosDispatchEventInterface;
  }

  useEffect(() => () => delete window.globalDispatchEvent, []);

  // Notifications only renders if we can get it and is ready
  if (
    scope === NOTIFICATION_SCOPE.NOT_SUPPORTED ||
    status === LOADING_STATUS.LOADING
  )
    return null;

  return (
    <>
      {/* Dialog should render only if permission is `default` */
      permission === 'default' ? <NotificationDialog scope={scope} /> : null}
      {/* We only subscribe when we have permission and token */
      permission === 'granted' && token !== null ? (
        <NotificationSubscription companyId={companyId} token={token} />
      ) : null}
    </>
  );
};

Notification.propTypes = {
  companyId: number
};

Notification.defaultProps = {
  companyId: null
};

export default Notification;
