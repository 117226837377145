import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoLoggi } from '../../assets/logo-loggi.svg';

const UpperDialog = withStyles({
  paper: {
    alignSelf: 'flex-start'
  }
})(Dialog);

const useStyles = makeStyles(({ palette }) => ({
  logo: {
    fill: palette.primary.main,
    color: palette.primary.main,
    width: 48,
    height: 'auto'
  }
}));

const Transition = React.forwardRef((props, ref) =>
  React.cloneElement(<Slide />, { direction: 'down', ref, ...props })
);

const NotificationDialogUI = props => {
  const styles = useStyles();
  const { t } = useTranslation('notificationDialog');

  const { onSuccess, onFail, onClose } = props;

  return (
    <UpperDialog
      open
      maxWidth="sm"
      TransitionComponent={Transition}
      PaperProps={{ elevation: 24 }}
      aria-labelledby={t('dialog.title')}
      aria-describedby={t('dialog.description')}
      onClose={onClose}
      fullWidth
    >
      <DialogContent>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item>
            <LogoLoggi className={styles.logo} />
          </Grid>
          <Grid item xs={9} sm={10}>
            <Typography variant="body2">{t('dialog.description')}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onFail} color="primary">
          {t('dialog.denyLabel')}
        </Button>
        <Button onClick={onSuccess} color="primary" variant="contained">
          {t('dialog.acceptLabel')}
        </Button>
      </DialogActions>
    </UpperDialog>
  );
};

NotificationDialogUI.propTypes = {
  onSuccess: func.isRequired,
  onFail: func.isRequired,
  onClose: func.isRequired
};

export default NotificationDialogUI;
