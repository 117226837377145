import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import {
  useLocation,
  useParams,
  useRouteMatch,
  Redirect
} from 'react-router-dom';

import SchedulingDetailsSummary from './components/scheduling-details-summary';
import DetailsContext from './details-context';
import schedulingApi from './scheduling-api';
import SchedulingContent from './scheduling-content.component';
import SchedulingLoadingWrapper from './scheduling-loading-wrapper.component';
import useSchedulingStatusColor from './use-scheduling-status-color';
import { SCHEDULING_STATUSES } from '../scheduling/constants';

const initialState = {
  loading: true,
  orderScheduling: null,
  orderSchedulingError: false,
  pickups: [],
  wasCancelledByShipper: false
};
const initReducer = locationState => {
  const state = { ...initialState };

  if (locationState?.pickup) {
    state.orderScheduling = locationState.pickup;
    state.loading = false;
  }

  return state;
};

// eslint-disable-next-line consistent-return
const reducer = (state, { type, payload }) => {
  // eslint-disable-next-line default-case
  switch (type) {
    case 'startOrderSchedulingFetch': {
      return { ...state, loading: true, orderSchedulingError: false };
    }
    case 'finishOrderSchedulingFetch': {
      const { orderScheduling } = payload;
      return { ...state, orderScheduling, loading: false };
    }
    case 'failedOrderSchedulingFetch': {
      const { error } = payload;
      return {
        ...state,
        loading: false,
        orderScheduling: initialState.orderScheduling,
        orderSchedulingError: error
      };
    }
    case 'finishPickupsFetch': {
      const {
        data: { pickups, wasCancelledByShipper }
      } = payload;
      return { ...state, pickups, wasCancelledByShipper };
    }
  }
};

/**
 * This is the scheduling details screen root, it's responsible for getting the data
 * from API using the fetchSchedulingDetails and fetchSchedulingPickups, and then
 * passing to it's children components.
 * It receives no params, it renders itself without external dependencies.
 * @componentType container
 * @returns {*}
 */
const SchedulingDetails = () => {
  const { id, companyId } = useParams();
  const { state: locationState } = useLocation();
  const [state, dispatch] = useReducer(reducer, locationState, initReducer);
  const { orderScheduling, orderSchedulingError, loading } = state;
  const { url } = useRouteMatch();

  const isScheduleDispatchedOrPartiallyDispatched = useMemo(() => {
    return [
      SCHEDULING_STATUSES.DISPATCHED,
      SCHEDULING_STATUSES.PARTIALLY_DISPATCHED
    ].includes(orderScheduling?.status);
  }, [orderScheduling]);

  const fetchOrderScheduling = useCallback(() => {
    dispatch({ type: 'startOrderSchedulingFetch' });
    schedulingApi
      .get(id, companyId)
      .then(response => {
        dispatch({
          type: 'finishOrderSchedulingFetch',
          payload: { orderScheduling: response }
        });
      })
      .catch(error => {
        dispatch({ type: 'failedOrderSchedulingFetch', payload: { error } });
      });
  }, [companyId, id]);

  useEffect(() => {
    if (!orderScheduling) fetchOrderScheduling();
  }, [fetchOrderScheduling, orderScheduling]);

  useEffect(() => {
    schedulingApi
      .getPickups(id, companyId)
      .then(data => dispatch({ type: 'finishPickupsFetch', payload: { data } }))
      .catch(error => {
        /** This data seems to be used internally and I don't see the point of
         * notifying the user that this request failed, this exception is
         * already being reported to Sentry */
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [companyId, id]);

  const context = useMemo(() => {
    const { pickups, wasCancelledByShipper } = state;
    return { orderScheduling, pickups, wasCancelledByShipper };
  }, [orderScheduling, state]);
  const bgColor = useSchedulingStatusColor(orderScheduling);

  return (
    <>
      {isScheduleDispatchedOrPartiallyDispatched && (
        <Redirect to={`${url}/veiculos`} />
      )}
      {!isScheduleDispatchedOrPartiallyDispatched && (
        <DetailsContext.Provider value={context}>
          <OneTemplate>
            <OneTemplateSummary color={bgColor}>
              <SchedulingDetailsSummary />
            </OneTemplateSummary>
            <OneTemplateContent disablePadding>
              <SchedulingLoadingWrapper
                loading={loading}
                orderSchedulingError={orderSchedulingError}
                reload={fetchOrderScheduling}
              >
                <SchedulingContent />
              </SchedulingLoadingWrapper>
            </OneTemplateContent>
          </OneTemplate>
        </DetailsContext.Provider>
      )}
    </>
  );
};

export default SchedulingDetails;
