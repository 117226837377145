import App from './App';
import {
  ACCESS_DENIED,
  COMPANIES_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  SIGN_UP_ROUTE,
  VALIDATE_PASSWORD_RECOVERY
} from './routes/constants';
import {
  ForgotPassword,
  Signin,
  GoogleSignIn,
  AppleSignIn,
  Signup,
  ValidateRecoveryPassword
} from './screens';

export { default as authenticationLocales } from './locales';
export {
  ACCESS_DENIED,
  COMPANIES_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  SIGN_UP_ROUTE,
  VALIDATE_PASSWORD_RECOVERY
};

export default App;
export {
  ForgotPassword,
  Signin,
  GoogleSignIn,
  AppleSignIn,
  Signup,
  ValidateRecoveryPassword
};
