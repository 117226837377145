import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';

import PackageDrawerBase from '../../package-drawer-base';
import fetchTrackingApi from '../../tracking-api';
import PackageHistoryDrawerContent from '../package-history-drawer/package-history-drawer-content';
import PackageHistoryDrawerSkeleton from '../package-history-drawer/package-history-drawer-skeleton';
import PackageHistoryDrawerError from '../package-history-drawer/package-history-drawer-error';

const initialState = {
  isLoading: true,
  history: null,
  error: false
};

const PackageHistoryWrapper = ({ trackingKey, open, onClose }) => {
  const { companyId } = useParams();

  const [countRefreshErrorDrawer, setCountRefreshErrorDrawer] = useState(0);
  const [{ history, isLoading, error }, setState] = useState(initialState);

  const refreshErrorDrawer = () => {
    setCountRefreshErrorDrawer(countRefreshErrorDrawer + 1);
  };

  useEffect(() => {
    if (open) {
      setState(initialState);

      fetchTrackingApi
        .tracking(companyId, trackingKey)
        .then(response => {
          setState({
            isLoading: false,
            history: response.packages[0].trackingHistory,
            error: false
          });
        })
        .catch(() => {
          setState({ isLoading: false, history: null, error: true });
        });
    }
  }, [companyId, trackingKey, open, countRefreshErrorDrawer]);

  return (
    <Box>
      <PackageDrawerBase onClose={onClose} open={open}>
        {isLoading && <PackageHistoryDrawerSkeleton />}
        {!isLoading && !error && (
          <PackageHistoryDrawerContent history={history} />
        )}
        {error && (
          <PackageHistoryDrawerError refreshErrorDrawer={refreshErrorDrawer} />
        )}
      </PackageDrawerBase>
    </Box>
  );
};

PackageHistoryWrapper.propTypes = {
  trackingKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool
};
PackageHistoryWrapper.defaultProps = {
  open: false
};

export default PackageHistoryWrapper;
