import { Box, Menu, MenuItem, styled, useMediaQuery } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { colors } from '@loggi/mar';
import { pxToRem } from '@loggi/mar/src/utils';
import CreatePackageModal from './filters/package-selection-create-package-modal.component';

const ToggleButtonStyle = styled(ToggleButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  borderRadius: '50px',
  width: '100%',
  paddingLeft: theme.typography.pxToRem(16),
  paddingRight: theme.typography.pxToRem(16),
  marginBottom: theme.spacing(2),
  '&.Mui-selected, &.Mui-selected:hover': {
    color: theme.palette.primary.main,
    transition: 'all 0.2s ease-in-out'
  },
  '&:disabled': {
    color: colors.smoke[500]
  },
  // Media query for mobile devices
  '@media (max-width: 960px)': {
    width: '100%' // Set full width on mobile devices
  }
}));

const MenuItemStyle = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main,
    transition: 'all 0.2s ease-in-out'
  },
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightBold,
  padding: `${theme.typography.pxToRem(18)} ${theme.typography.pxToRem(36)}`,
  borderRadius: '8px'
}));

const packagesRoutes = {
  singlePackage: companyId => `/empresas/${companyId}/pacotes/adicionar`,
  spreadsheet: companyId => `/empresas/${companyId}/pacotes/adicionar/planilha`,
  importInvoice: companyId => `/empresas/${companyId}/pacotes/adicionar/nfe`
};

const CreatePackageNavigation = ({ buttonText }) => {
  const { t } = useTranslation('unifiedPickup');
  const [createPackagePopupOpen, setCreatePackagePopupOpen] = useState(false);
  const [selectedItemUrl, setSelectedItemUrl] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const menuRef = useRef(null);
  const { companyId } = useParams();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const navigationItems = [
    {
      key: 'invoice',
      label: t('packageSelection.addPackageButton.navigation.invoice'),
      url: packagesRoutes.importInvoice(companyId)
    },
    {
      key: 'spreadsheet',
      label: t('packageSelection.addPackageButton.navigation.spreadsheet'),
      url: packagesRoutes.spreadsheet(companyId)
    },
    {
      key: 'single-package',
      label: t('packageSelection.addPackageButton.navigation.singlePackage'),
      url: packagesRoutes.singlePackage(companyId)
    }
  ];
  const handleToggleButtonClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCreatePackagePopupOpen(false);
  };

  const openCreatePopup = url => {
    setSelectedItemUrl(url);
    handleCloseMenu();
    setCreatePackagePopupOpen(true);
  };

  return (
    <>
      <ToggleButtonStyle
        data-testid="toggle-btn"
        onClick={handleToggleButtonClick}
        ref={menuRef}
        variant="outlined"
        disabled={false}
        aria-controls="menu"
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl)}
      >
        <Box mr={1}>{buttonText}</Box>
        {anchorEl ? (
          <KeyboardArrowUp fontSize="small" />
        ) : (
          <KeyboardArrowDown fontSize="small" />
        )}
      </ToggleButtonStyle>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        getContentAnchorEl={null} // Add this line to prevent menu placement issues
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        id="menu"
        PaperProps={{
          style: {
            width: isMobile ? '100%' : pxToRem(264)
          }
        }}
      >
        {navigationItems.map(item => (
          <MenuItemStyle
            key={item.key}
            onClick={() => {
              openCreatePopup(item.url);
            }}
          >
            {item.label}
          </MenuItemStyle>
        ))}
      </Menu>
      <CreatePackageModal
        open={createPackagePopupOpen}
        handleClose={handleCloseMenu}
        toUrl={selectedItemUrl}
      />
    </>
  );
};

CreatePackageNavigation.propTypes = {
  buttonText: PropTypes.string.isRequired
};

export default CreatePackageNavigation;
