import { loggiGenericApi } from '@loggi/authentication-lib';
import * as Sentry from '@sentry/react';
import { getI18n } from 'react-i18next';
import { NonWorkingDayType } from '../../unified-pickup/constants';

const endpoint = ({ companyId }) => {
  return `/promiseland/api/v1/${companyId}/non_working_day/`;
};

export class NonWorkingDayGetError extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'NonWorkingDayGetError';
  }
}

const nonWorkingDayApi = {
  get: ({ companyId, date, address, addressComplement }) => {
    const t = getI18n().getFixedT(null, 'unifiedPickup');
    const url = endpoint({
      companyId
    });

    return loggiGenericApi
      .url(url)
      .accept('application/json')
      .post({
        date,
        address,
        address_complement: addressComplement,
        non_working_day_type: NonWorkingDayType.PICKUP
      })
      .json()
      .catch(error => {
        const throwError = new NonWorkingDayGetError(
          t('errorMessages.generic')
        );
        Sentry.captureException(throwError, {
          contexts: { 'original error': error }
        });

        throw throwError;
      });
  }
};

export default nonWorkingDayApi;
