import React from 'react';
import { useTranslation } from 'react-i18next';

import { maxHeightInCm, minHeightInCm } from '../constants';
import BaseVolumeField from './base-volume-field';

const HeightField = () => {
  const { t } = useTranslation('packages');

  const validate = value => {
    if (value !== 0 && !value) return t('create.errorMessages.requiredHeight');

    if (value < minHeightInCm)
      return t('create.errorMessages.invalidMeasurement');

    if (value > maxHeightInCm)
      return t('create.errorMessages.maxHeight', { maxHeightInCm });

    return null;
  };

  return (
    <BaseVolumeField
      adornmentText={t('create.volumetry.centimeters_unit')}
      fieldName="height"
      label={t('create.volumetry.labelHeight')}
      validate={validate}
    />
  );
};

export default HeightField;
