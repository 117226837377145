import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Box, Typography, Tooltip, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFeature } from '@loggi/components/src/one/remote-config';
import { useParams, useHistory } from 'react-router-dom';
import { endpoints } from '../constants';
import { ReactComponent as PackageReturnIllustration } from './package-return.svg';
import UpdateReturnAddressAlert from '../package-update-return-address-modal';

const useStyles = makeStyles(({ typography, palette }) => ({
  helpIcon: {
    width: typography.pxToRem(20),
    height: typography.pxToRem(20)
  },
  returnPackageCard: {
    borderColor: palette.grey[200]
  }
}));

const PackageDetailsReturnDirectionCard = ({
  show,
  type,
  reason,
  returnAddress,
  warehouseId,
  canUpdateReturnAddress
}) => {
  const { t } = useTranslation('packages');
  const styles = useStyles();
  const { push } = useHistory();
  const { companyId } = useParams();
  const [
    updateReturnAddressDialogState,
    setUpdateReturnAddressDialogState
  ] = useState(false);

  const enableChangeReturnAddressAlert = useFeature(
    'enable_return_address_alert'
  );

  const fullAddress = returnAddress?.addressLines?.join(' ');
  const displayAddress = enableChangeReturnAddressAlert && fullAddress;

  const handleUpdateReturnAddressClick = () => {
    if (canUpdateReturnAddress) {
      push(endpoints.updateWarehouse(companyId, warehouseId));
    } else {
      setUpdateReturnAddressDialogState(true);
    }
  };

  return (
    <>
      {show && (
        <Box
          className={styles.returnPackageCard}
          data-testid="return-package-card"
          display="flex"
          borderRadius={10}
          border={1}
          justifyContent="space-between"
          alignItems="center"
          mb={4}
        >
          <Box display="flex" m={2} alignItems="center">
            <Box mr={3}>
              <PackageReturnIllustration />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="subtitle2">
                <strong>{type}</strong>
              </Typography>
              {reason && <Typography variant="body1">{reason}</Typography>}
              {displayAddress && (
                <Typography variant="body1">{fullAddress}</Typography>
              )}
            </Box>
          </Box>

          {enableChangeReturnAddressAlert ? (
            <Box mr={3} display="flex" alignItems="center">
              <Button
                size="small"
                data-testid="update-return-address-button"
                color="primary"
                onClick={() => handleUpdateReturnAddressClick()}
              >
                {t('details.labels.direction.changeReturnAddressButton')}
              </Button>
            </Box>
          ) : (
            <Box mr={3} display="flex" alignItems="center">
              <Tooltip
                arrow
                placement="top-end"
                title={
                  <Typography variant="caption">
                    {t('details.labels.direction.title')}
                  </Typography>
                }
              >
                <HelpOutlineIcon color="primary" className={styles.helpIcon} />
              </Tooltip>
            </Box>
          )}
        </Box>
      )}
      <UpdateReturnAddressAlert
        dialogState={updateReturnAddressDialogState}
        setDialogState={setUpdateReturnAddressDialogState}
      />
    </>
  );
};

PackageDetailsReturnDirectionCard.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string.isRequired,
  reason: PropTypes.string,
  returnAddress: PropTypes.shape(PropTypes.string),
  warehouseId: PropTypes.number,
  canUpdateReturnAddress: PropTypes.bool
};

PackageDetailsReturnDirectionCard.defaultProps = {
  show: false,
  reason: null,
  returnAddress: null,
  warehouseId: null,
  canUpdateReturnAddress: false
};

export default PackageDetailsReturnDirectionCard;
