import { useFormikContext } from 'formik';

export const UNIFIED_PICKUP_FORM_STORAGE_KEY = 'loggione_unified_pickup_form';

const FormikStorageWatcher = () => {
  const {
    values: {
      pickupAddress,
      pickupAddressComplement,
      pickupOriginName,
      pickupOriginPhone,
      pickupOriginInstructions,
      pickupStartTime,
      pickupEndTime,
      pickupIsRecurring,
      endTimeFieldName,
      startTimeFieldName,
      pickupDate
    }
  } = useFormikContext();

  localStorage.setItem(
    UNIFIED_PICKUP_FORM_STORAGE_KEY,
    JSON.stringify({
      pickupAddress,
      pickupAddressComplement,
      pickupOriginName,
      pickupOriginPhone,
      pickupOriginInstructions,
      pickupStartTime,
      pickupEndTime,
      pickupIsRecurring,
      endTimeFieldName,
      startTimeFieldName,
      pickupDate
    })
  );

  return null;
};

export default FormikStorageWatcher;
