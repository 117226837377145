import { Box, Grid, useMediaQuery, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRemoteConfig } from '@loggi/components/src/one/remote-config';
import { Error } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { format, isValid } from 'date-fns';
import { useFormikContext } from 'formik';
import SectionTitle from './section-title.component';
import WeekdaysField from './weekdays-field.component';
import IsRecurring from './is-recurring.component';
import PeriodTimeSection from './period-time-section.component';
import RecurringPickupDrawerExplain from './recurring-pickup-drawer.component';
import DateField from './date-field.component';
import { getDateFromChosenWeekday } from './unified-pickup-helpers';
import { IS_RECURRING } from './constants';

const DateSection = () => {
  const { t } = useTranslation(['orderScheduling', 'unifiedPickup']);
  const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const gridSpacing = smUp ? 5 : 2;
  const weekdaysFieldName = 'pickupWeekdays';
  const pickupDateFieldName = 'pickupDate';
  const { values, setFieldValue } = useFormikContext();
  const { recurringPickupId, companyId } = useParams();
  const isUpdate = !!recurringPickupId;
  const pickupAddress = values.pickupAddress?.description;
  const [
    currentPickupDateCalculationParams,
    setCurrentPickupDateCalculationParams
  ] = useState({});

  const { value: fsValidateWorkingDay } = useRemoteConfig(
    'enable_validate_working_day_on_unified_pickup'
  );
  const validateWorkingDay = JSON.parse(fsValidateWorkingDay);
  const { value: fsEnableCalendar } = useRemoteConfig(
    'enable_calendar_on_unified_pickup'
  );
  const enableCalendar = JSON.parse(fsEnableCalendar);

  const hasValue = fieldValue => {
    return !!fieldValue;
  };

  const hasStartTime =
    hasValue(values.pickupStartTime) && isValid(values.pickupStartTime);
  const hasEndTime =
    hasValue(values.pickupEndTime) && isValid(values.pickupEndTime);
  const isPickupWeekdaysSelected =
    hasValue(values.pickupWeekdays) && values.pickupWeekdays.length > 0;
  const isRecurringPickup = values.pickupIsRecurring === IS_RECURRING;

  const hasAddress = hasValue(pickupAddress);

  const allFieldsFilled = [
    hasStartTime,
    hasEndTime,
    isPickupWeekdaysSelected,
    hasAddress
  ].every(isFieldFilled => isFieldFilled === true);

  const shouldCalculatePickupDate =
    !enableCalendar || (enableCalendar && isUpdate);

  const calculatePickupDate = useCallback(
    ({
      pickupWeekdays,
      pickupStartTime,
      address,
      addressComplement,
      isIndividualPickup
    }) => {
      getDateFromChosenWeekday({
        pickupWeekdays,
        pickupStartTime,
        address,
        addressComplement,
        isIndividualPickup,
        validateWorkingDay,
        companyId
      })
        .then(date => {
          if (!isValid(date)) {
            setFieldValue(pickupDateFieldName, undefined, true);
            return;
          }
          setFieldValue(pickupDateFieldName, date, true);
        })
        .catch(() => setFieldValue(pickupDateFieldName, undefined, true));
    },
    [setFieldValue, companyId, pickupDateFieldName, validateWorkingDay]
  );

  useEffect(() => {
    if (shouldCalculatePickupDate) {
      const pickupDateCalculationParamsHasChanged = () => {
        return (
          currentPickupDateCalculationParams.pickupWeekdays !==
            values.pickupWeekdays ||
          currentPickupDateCalculationParams.pickupStartTime !==
            values.pickupStartTime ||
          currentPickupDateCalculationParams.address !== pickupAddress ||
          currentPickupDateCalculationParams.addressComplement !==
            values.pickupAddressComplement ||
          currentPickupDateCalculationParams.isRecurringPickup !==
            isRecurringPickup
        );
      };

      if (pickupDateCalculationParamsHasChanged()) {
        setCurrentPickupDateCalculationParams({
          pickupWeekdays: values.pickupWeekdays,
          pickupStartTime: values.pickupStartTime,
          address: pickupAddress,
          addressComplement: values.pickupAddressComplement,
          isRecurringPickup
        });

        if (allFieldsFilled) {
          calculatePickupDate({
            pickupWeekdays: values.pickupWeekdays,
            pickupStartTime: values.pickupStartTime,
            address: pickupAddress,
            addressComplement: values.pickupAddressComplement,
            isIndividualPickup: !isRecurringPickup
          });
          return;
        }
        setFieldValue(pickupDateFieldName, undefined, true);
      }
    }
  }, [
    allFieldsFilled,
    shouldCalculatePickupDate,
    pickupDateFieldName,
    pickupAddress,
    values.pickupAddressComplement,
    values.pickupStartTime,
    values.pickupWeekdays,
    isRecurringPickup,
    currentPickupDateCalculationParams,
    weekdaysFieldName,
    setCurrentPickupDateCalculationParams,
    calculatePickupDate,
    setFieldValue
  ]);

  const pickupDateInfoFirst = () => {
    if (isRecurringPickup && isUpdate) {
      return t(
        'orderScheduling:create.pickupDateInfo.updateRecurringPickup.first'
      );
    }

    if (isRecurringPickup && !isUpdate) {
      return t('orderScheduling:create.pickupDateInfo.recurringPickup.first');
    }

    return t('orderScheduling:create.pickupDateInfo.individualPickup.first');
  };

  const showCalendar = enableCalendar && !isUpdate;
  const showWeekday = !enableCalendar || (isRecurringPickup && enableCalendar);
  const showPickupDateInfo =
    !showCalendar && allFieldsFilled && isValid(values.pickupDate);
  const showDateWarning =
    !showCalendar && allFieldsFilled && !values.pickupDate;

  return (
    <>
      <Box>
        <PeriodTimeSection />
      </Box>
      {showCalendar && (
        <Box mt={3} data-testid="date-field">
          <SectionTitle>{t('unifiedPickup:dateField.title')}</SectionTitle>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <DateField fieldName={pickupDateFieldName} />
            </Grid>
          </Grid>
        </Box>
      )}
      {!isUpdate && (
        <>
          <Box mt={2} data-testid="is-recurring-section">
            <SectionTitle>
              {t('unifiedPickup:dateSection.isRecurringPickup.title')}
            </SectionTitle>
            <IsRecurring fieldName="pickupIsRecurring" />
          </Box>
          <Box mt={3}>
            <RecurringPickupDrawerExplain />
          </Box>
        </>
      )}
      {showWeekday && (
        <Box mt={3}>
          <SectionTitle>
            {t('unifiedPickup:dateSection.weekdays.title')}
          </SectionTitle>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <WeekdaysField fieldName={weekdaysFieldName} />
            </Grid>
          </Grid>
        </Box>
      )}
      {showDateWarning && (
        <Box my={4}>
          <Alert icon={<Error />} severity="warning">
            <AlertTitle>
              {t('unifiedPickup:dateSection.nonWorkingDayAlert.warning')}
            </AlertTitle>
            {t('unifiedPickup:dateSection.nonWorkingDayAlert.selectOtherDay')}
          </Alert>
        </Box>
      )}
      {showPickupDateInfo && (
        <Box my={4}>
          <Alert
            icon={<Error />}
            severity="info"
            data-testid="pickup-date-info"
          >
            <Typography variant="body1">
              {pickupDateInfoFirst()}
              <strong data-testid="pickup-date-info-with-period">
                {t(
                  'orderScheduling:create.pickupDateInfo.textDateEnablePeriodSection',
                  {
                    weekday: format(values.pickupDate, 'EEEE').toLowerCase(),
                    day: format(values.pickupDate, 'd'),
                    month: format(values.pickupDate, 'MMMM').toLowerCase(),
                    year: format(values.pickupDate, 'yyyy'),
                    startTime: format(values.pickupStartTime, 'HH:mm'),
                    endTime: format(values.pickupEndTime, 'HH:mm')
                  }
                )}
              </strong>
            </Typography>
          </Alert>
        </Box>
      )}
    </>
  );
};

export default DateSection;
