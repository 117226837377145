import React from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsOutlined } from '@material-ui/icons';
import { useRouteMatch, Link as RouterLink } from 'react-router-dom';
import { Button, makeStyles, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import CompanyHierarchy from '@loggi/components/src/one/entity/CompanyHierarchy';
import { MANAGEMENT } from '../routes/constants';

const useStyles = makeStyles(({ typography, spacing, palette }) => ({
  mobileManagementLink: {
    ...typography.h6,
    padding: spacing(2, 4),
    width: '100%',
    borderRadius: '0',
    display: 'flex',
    justifyContent: 'start',
    '&:hover': {
      backgroundColor: palette.secondary.light
    }
  }
}));

const AccountManagementLink = ({ isMobile }) => {
  const { t } = useTranslation('components');
  const styles = useStyles();
  const match = useRouteMatch('/empresas/:companyId');
  const companyId =
    CompanyHierarchy.getInstance().getLoggedCompanyId() ??
    match?.params?.companyId;

  return (
    <Tooltip title="Configurações da empresa" placement="bottom">
      <Button
        className={isMobile ? styles.mobileManagementLink : ''}
        component={RouterLink}
        data-testid="management-btn"
        to={MANAGEMENT.url(companyId)}
        textDecoration="none"
      >
        {isMobile ? (
          t('header.management')
        ) : (
          <SettingsOutlined style={{ color: 'white' }} />
        )}
      </Button>
    </Tooltip>
  );
};

AccountManagementLink.propTypes = {
  isMobile: PropTypes.bool
};

AccountManagementLink.defaultProps = {
  isMobile: false
};

export default AccountManagementLink;
