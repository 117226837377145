import { loggiWebApi } from '@loggi/authentication-lib';
import {
  ORDER_SCHEDULES_ENDPOINT,
  PICKUPS_COMPANIES_ENDPOINT
} from '../../constants';
import { ORDER_SCHEDULES_FETCH_ERRORS } from './constants';
/**
 * This method is just mock for now, but later it'll be responsible for reaching
 * the cancelSchedule API and try to cancel a schedule.
 * @param {number} schedulingId - must be given
 * @returns {*}
 */
const cancelSchedule = (companyId, schedulingId) => {
  if (!schedulingId)
    throw new Error(ORDER_SCHEDULES_FETCH_ERRORS.MISSING_SCHEDULING_ID);
  return loggiWebApi
    .url(
      `/${PICKUPS_COMPANIES_ENDPOINT}/${companyId}/${ORDER_SCHEDULES_ENDPOINT}/${schedulingId}/cancel/`
    )
    .headers({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    })
    .patch()
    .json();
};

export default cancelSchedule;
