import PICKUP_RECEIPT_API_ENDPOINTS from '../constants';
import pickupReceiptApi from '../pickup-receipt-api';

const sendShipperFeedbackApi = {
  send: (
    pickupOrderScheduleHash,
    pickupReceiptId,
    shipperConfirmedTotalPackages,
    shipperFeedbackMessage,
    undoShipperConfirmation = false
  ) => {
    return pickupReceiptApi
      .url(PICKUP_RECEIPT_API_ENDPOINTS.update(pickupOrderScheduleHash))
      .accept('application/json')
      .content('application/json')
      .post({
        pickupReceiptId,
        shipperConfirmedTotalPackages,
        shipperFeedbackMessage,
        undoShipperConfirmation
      });
  }
};

export default sendShipperFeedbackApi;
