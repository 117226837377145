/* eslint-disable react/jsx-props-no-spreading */
import Toast from '@loggi/components/src/one/toast';
import { colors } from '@loggi/mar';
import { Box, Hidden, makeStyles, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useCheckCompanyStatus } from '@loggi/components/src/one/hooks';

import packagesApi from '../../../packages-api';
import packagesRoutes from '../../../packages-routes';
import SectionTitle from '../../create-section-title';
import { ReactComponent as FileIcon } from '../../file-icon.svg';
import LoadingDialog from '../../loading-dialog.component';

const acceptedFiles = ['.xls', '.xlsx', '.csv'];
const acceptedFilesRegex = /.xls|.xlsx|.csv/g;
export const formattedAcceptedFiles = acceptedFiles.join(' ');

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    alertMessage: {
      backgroundColor: colors.smoke[50],
      border: `1px solid ${colors.smoke[300]}`,
      color: palette.info.dark,
      fontSize: typography.body2.fontSize,
      [breakpoints.down('sm')]: {
        alignItems: 'start',
        '& .MuiAlert-icon': {
          paddingTop: spacing(1.15)
        }
      }
    },
    dashedBox: {
      border: `dashed 1px ${palette.primary.main}`,
      cursor: 'pointer'
    },
    error: {
      border: `dashed 1px ${palette.error.main}`
    },
    errorMessage: {
      color: palette.info.dark,
      fontSize: typography.body2.fontSize,
      maxHeight: typography.pxToRem(48)
    },
    subtitleIcon: {
      color: palette.info.dark
    }
  })
);

const UploadDropzone = () => {
  const { t } = useTranslation('packages');
  const styles = useStyles();
  const { push } = useHistory();
  const { companyId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [hasInvalidType, setHasInvalidType] = useState(false);
  const showInvalidFileError = hasInvalidType && !loading;
  const { isDefaulter } = useCheckCompanyStatus();

  const hasValidTypeFile = files => {
    return (
      files.filter(({ name }) => name.match(acceptedFilesRegex)).length > 0
    );
  };

  const submitSpreadsheet = useCallback(
    files => {
      setHasInvalidType(false);
      setLoading(true);

      packagesApi
        .upload(companyId, files[0])
        .badRequest(({ text }) => {
          const url = packagesRoutes.create.spreadsheet.errors.url(companyId);
          push(url, { errors: JSON.parse(text) });
        })
        .error(413, () => {
          enqueueSnackbar(t('create.upload.requestEntityTooLarge'), {
            content: (key, message) => (
              <Toast id={key} message={message} type="error" />
            )
          });
          setLoading(false);
        })
        .json(response => {
          if (response?.length) {
            const url = packagesRoutes.createSuccess.url(companyId);
            push(url, { trackingKeys: response.map(pkg => pkg.trackingKey) });
          } else {
            enqueueSnackbar(t('create.upload.duplicatedPackages'), {
              content: (key, message) => (
                <Toast id={key} message={message} type="error" />
              )
            });
            setLoading(false);
          }
        })
        .catch(() => {
          enqueueSnackbar(t('create.upload.internalServerError'), {
            content: (key, message) => (
              <Toast id={key} message={message} type="error" />
            )
          });
          setLoading(false);
        });
    },
    [companyId, enqueueSnackbar, push, t]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFiles,
    multiple: false,
    disabled: isDefaulter,
    onDropAccepted: files =>
      hasValidTypeFile(files)
        ? submitSpreadsheet(files)
        : setHasInvalidType(true),
    onDropRejected: () => setHasInvalidType(true)
  });

  return (
    <Box data-testid="spreadsheet-upload-section">
      <SectionTitle>{t('create.spreadsheetFlow.upload.title')}</SectionTitle>
      <Box pb={3} pt={1}>
        <Alert
          className={styles.alertMessage}
          icon={<Error className={styles.subtitleIcon} fontSize="small" />}
          severity="error"
        >
          <AlertTitle>
            {t('create.spreadsheetFlow.upload.alertTitle')}
          </AlertTitle>
          {t('create.spreadsheetFlow.upload.alertDescription')}
        </Alert>
      </Box>
      <Box
        {...getRootProps({ className: 'dropzone' })}
        borderRadius={16}
        className={classNames(
          styles.dashedBox,
          showInvalidFileError && styles.error
        )}
        p={{ xs: 2, sm: 7 }}
        textAlign="center"
      >
        <FileIcon />
        <Box>
          <Typography variant="h6">
            {t('create.supportedFiles', {
              formattedAcceptedFiles
            })}
          </Typography>
          {showInvalidFileError && (
            <>
              <Hidden smDown>
                <Box display="flex" justifyContent="center" py={2}>
                  <Alert
                    className={styles.errorMessage}
                    icon={<Error fontSize="small" />}
                    severity="error"
                  >
                    {t('create.upload.fileFormatError')}
                  </Alert>
                </Box>
              </Hidden>
              <Hidden smUp>
                <Box color="error.main" fontWeight="fontWeightBold" py={1}>
                  {t('create.upload.fileFormatError')}
                </Box>
              </Hidden>
            </>
          )}
          <Typography>
            <input
              {...getInputProps()}
              data-testid="upload-spreadsheet-input"
              onChange={e => {
                const files = Array.from(e.target.files);
                return hasValidTypeFile(files)
                  ? submitSpreadsheet(files)
                  : setHasInvalidType(true);
              }}
            />
            <Box
              color="primary.main"
              component="strong"
              fontWeight="fontWeightBold"
            >
              {t('create.spreadsheetFlow.upload.linkText')}
            </Box>
          </Typography>
        </Box>
      </Box>
      {loading && (
        <LoadingDialog message={t('create.spreadsheetFlow.loading')} />
      )}
    </Box>
  );
};

export default UploadDropzone;
