import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const DeliveryProofDrawerSkeleton = () => {
  return (
    <Box data-testid="loading">
      <Box pt={2} pb={1}>
        <Typography variant="h6">
          <Skeleton variant="rect" width={250} />
        </Typography>
      </Box>
      <Box pb={3}>
        <Typography variant="body2">
          <Skeleton variant="rect" width={100} />
        </Typography>
      </Box>
      <Box pb={3}>
        <Typography variant="overline">
          <Skeleton variant="rect" width={50} />
        </Typography>
      </Box>
      <Box pb={2}>
        <Skeleton variant="rect" height={70} />
      </Box>
      <Box pb={2}>
        <Skeleton variant="rect" height={150} />
      </Box>
      <Box pb={2}>
        <Skeleton variant="rect" height={70} />
      </Box>
      <Box pb={2}>
        <Skeleton variant="rect" height={70} />
      </Box>
    </Box>
  );
};

export default DeliveryProofDrawerSkeleton;
