import { getI18n } from 'react-i18next';
import {
  format,
  formatRelative,
  isValid,
  parseISO,
  setDefaultOptions
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

setDefaultOptions({ locale: ptBR });

function firstToUpper(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const formatDayMonth = (timeStamp, monthComplete = false) => {
  const date = new Date(timeStamp);
  const monthFormatter = monthComplete ? 'LLLL' : 'LLL';

  return isValid(date)
    ? `${format(date, 'd')} de ${firstToUpper(format(date, monthFormatter))}`
    : '';
};

export const formatDayMonthYear = timeStamp => {
  const date = new Date(timeStamp);

  return isValid(date) ? format(date, 'P') : '';
};

export const formatHour = timeStamp => {
  const date = parseISO(timeStamp);
  return isValid(date) ? format(date, 'HH:mm') : '';
};

export const formatDay = timeStamp => {
  const date = parseISO(timeStamp);
  const i18n = getI18n();

  const t = i18n.getFixedT(null, 'one');
  const formatRelativeLocale = {
    lastWeek: t('daySummary.sameElse'),
    yesterday: t('daySummary.yesterday'),
    today: t('daySummary.today'),
    tomorrow: t('daySummary.tomorrow'),
    nextWeek: t('daySummary.sameElse'),
    other: t('daySummary.sameElse')
  };
  const locale = {
    ...ptBR,
    formatRelative: token => formatRelativeLocale[token]
  };

  return isValid(date)
    ? formatRelative(date, new Date(), { locale }).toString()
    : '';
};
