import { useState } from 'react';
import {
  useSubscription,
  dispatchEvent
} from '@loggi/components/src/one/useSubscription';
import { createNotificationBridge } from '../mobile/bridge';
import getPermissionStatus from './get-permission-status';
import { NOTIFICATION_SCOPE, LOADING_STATUS } from './constants';
import { getToken } from '../../firebase/notifications';

const GET_NOTIFICATION_TOKEN_BRIGDE = 'getNotificationToken';
const GET_NOTIFICATION_TOKEN = 'get-notification-token';

export const dispatchSupportedToken = async () => {
  const token = await getToken();
  dispatchEvent('notification:status', { token });
};

const useNotificationStatus = () => {
  // Default data
  const [permissionStatus, setPermissionStatus] = useState(getPermissionStatus);

  if (
    permissionStatus.scope === NOTIFICATION_SCOPE.IOS_SUPPORTED &&
    !permissionStatus.token
  ) {
    // Request Notification status/token from iOS wrapper
    const notificationBridge = createNotificationBridge(
      GET_NOTIFICATION_TOKEN_BRIGDE
    );
    notificationBridge[GET_NOTIFICATION_TOKEN_BRIGDE]({
      message: GET_NOTIFICATION_TOKEN
    });
  }

  if (
    permissionStatus.status === LOADING_STATUS.LOADING &&
    permissionStatus.scope === NOTIFICATION_SCOPE.SUPPORTED &&
    permissionStatus.permission === 'granted' &&
    !permissionStatus.token
  ) {
    // Request Notification status/token from iOS wrapper
    // this finally here is because all promises must be treated
    dispatchSupportedToken().finally(() => {
      // eslint-disable-next-line no-useless-return
      return;
    });
  }

  // Subscribe for notification status change
  useSubscription(
    'notification:status',
    data =>
      setPermissionStatus({
        ...permissionStatus,
        ...data,
        status: LOADING_STATUS.DONE
      }),
    []
  );

  // Render child component with extra permission status props
  return { ...permissionStatus };
};

export default useNotificationStatus;
