import { TRANSPORT_TYPE_MAPPER } from '@loggi/components/src/one/constants';
import { Box, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CheckCircle, Error } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import msk from 'msk';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatDayMonthYear,
  formatHour
} from '@loggi/components/src/one/date-utils';
import { CPF_MASK } from '../../utils/masks';

/**
 * SchedulingDetailsPickupReceiptSummary's only responsibility is to show the summary info of the
 * pickup receipt scheduling details screen, with the params it receives.
 * @componentType: Presentational
 * @param pickupReceipt
 * @returns {*}
 */
const SchedulingDetailsPickupReceiptSummary = ({ pickupReceipt }) => {
  const { t } = useTranslation(['details']);
  const { palette } = useTheme();
  const {
    totalPackages,
    driverName,
    driverDocument,
    transportType,
    licensePlate
  } = pickupReceipt;

  const documentMasked = msk(driverDocument, CPF_MASK);

  const documentText = t('pickupDriver.document', { document: documentMasked });
  const transportTypeText = t(TRANSPORT_TYPE_MAPPER(transportType));

  const vehicleInfoText = [transportTypeText, licensePlate]
    .filter(value => !!value)
    .join(' - ');
  const driverDetailsText = [documentText, vehicleInfoText]
    .filter(value => !!value)
    .join(' / ');

  const showAlert = pickupReceipt.alreadyAnswered;
  const alertProperties = pickupReceipt.shipperConfirmedTotalPackages
    ? {
        message: 'pickupReceiptDetails.confirmedMessage',
        icon: (
          <CheckCircle
            style={{ color: palette.success.dark }}
            data-testid="success-icon"
          />
        )
      }
    : {
        message: 'pickupReceiptDetails.reportedMessage',
        icon: <Error color="error" data-testid="error-icon" />
      };

  return (
    <>
      <Box display="flex" alignItems="flex-start">
        <Box
          pl={5}
          display="flex"
          width="100%"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="flex-start"
        >
          <Box pb={{ xs: 4, sm: 0 }}>
            <Typography variant="h4" component="h1">
              <strong>
                {t('numberOfBeepedPackages.label', { count: totalPackages })}
              </strong>
            </Typography>
            <Box py={1}>
              <Typography variant="subtitle1">
                {`${t('pickupReceiptDetails.collectedBy')} `}
                <strong>{driverName}</strong>
              </Typography>
            </Box>
            <Typography variant="body2">{driverDetailsText}</Typography>
          </Box>
        </Box>
      </Box>
      {showAlert && (
        <Box pt={3}>
          <Alert
            icon={alertProperties.icon}
            severity="info"
            data-testid="answered-alert"
          >
            {t(alertProperties.message, {
              date: formatDayMonthYear(pickupReceipt.answeredAtTime),
              time: formatHour(pickupReceipt.answeredAtTime)
            })}
          </Alert>
        </Box>
      )}
    </>
  );
};

SchedulingDetailsPickupReceiptSummary.propTypes = {
  pickupReceipt: PropTypes.shape({
    totalPackages: PropTypes.number.isRequired,
    driverName: PropTypes.string.isRequired,
    driverDocument: PropTypes.string,
    transportType: PropTypes.string,
    licensePlate: PropTypes.string,
    alreadyAnswered: PropTypes.bool,
    answeredAtTime: PropTypes.string,
    shipperConfirmedTotalPackages: PropTypes.bool
  }).isRequired
};

export default SchedulingDetailsPickupReceiptSummary;
