import {
  Box,
  Collapse,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDay, formatHour } from '@loggi/components/src/one/date-utils';

import { SCHEDULING_STATUSES } from '../../../scheduling/constants';
import DetailsContext from '../../details-context';
import CancelScheduleDisclaimer from '../cancel-schedule-disclaimer';

const useSchedulingDetailsSummaryStyles = makeStyles(
  ({ palette, spacing }) => ({
    linkBackButton: {
      borderRadius: '100%',
      height: spacing(5),
      minWidth: 0,
      width: spacing(5),
      color: palette.common.white
    },
    fontColor: {
      mixBlendMode: 'screen',
      position: 'relative',
      color: 'initial',
      '&:before': {
        mixBlendMode: 'color-burn',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        content: '""'
      }
    }
  })
);

const STATUS_MAP = {
  [SCHEDULING_STATUSES.CANCELLED]: 'scheduling:schedulingStatuses.cancelled',
  [SCHEDULING_STATUSES.DISPATCHED]: 'scheduling:schedulingStatuses.dispatched',
  [SCHEDULING_STATUSES.PARTIALLY_DISPATCHED]:
    'scheduling:schedulingStatuses.partiallyDispatched',
  [SCHEDULING_STATUSES.WAITING_PICKUP]:
    'scheduling:schedulingStatuses.waitingPickup',
  [SCHEDULING_STATUSES.WAITING_VOLUME]:
    'scheduling:schedulingStatuses.waitingPickup',
  [SCHEDULING_STATUSES.WAITING_CONFIRMATION]:
    'scheduling:schedulingStatuses.waitingPickup',
  [SCHEDULING_STATUSES.FAILED_TO_SCHEDULE]:
    'scheduling:schedulingStatuses.cancelled'
};

/**
 * SchedulingDetailsSummary's only responsibility is to show the summary info of the
 * scheduling details screen, with the params it receives.
 * @componentType: Presentational
 * @returns {*}
 */
const SchedulingDetailsSummary = () => {
  const styles = useSchedulingDetailsSummaryStyles();
  const theme = useTheme();
  const { t } = useTranslation(['details', 'scheduling']);

  const { wasCancelledByShipper, orderScheduling } = useContext(DetailsContext);

  // This component need to be rendered before orderScheduling is ready to be used
  const {
    endTime,
    fullAddress,
    id,
    startTime,
    status,
    cancelledBy
  } = useMemo(() => {
    if (!orderScheduling) return {};

    return {
      ...orderScheduling,
      fullAddress: orderScheduling.originAddress.fullAddress
    };
  }, [orderScheduling]);

  const showDisclaimer =
    status === SCHEDULING_STATUSES.CANCELLED ||
    status === SCHEDULING_STATUSES.PARTIALLY_DISPATCHED ||
    status === SCHEDULING_STATUSES.FAILED_TO_SCHEDULE;

  return (
    <Box display="flex" flexDirection="column">
      <Box
        alignSelf="flex-start"
        mb={1.5}
        bgcolor={theme.palette.common.white}
        borderRadius={50}
        px={1}
        py={0.5}
        className={styles.fontColor}
      >
        <Typography variant="caption">
          <strong>{t('details:summary.scheduling', { id })}</strong>
        </Typography>
      </Box>
      <Collapse
        in={Boolean(orderScheduling)}
        timeout={500}
        easing={{ enter: 'ease-out' }}
      >
        <Box>
          <Box pb={1.5}>
            <Typography variant="h4">
              <strong>{t(STATUS_MAP[(orderScheduling?.status)])}</strong>
            </Typography>
          </Box>
          <Box pb={0.5}>
            <Typography variant="subtitle1">
              <strong>
                {t('details:summary.primaryText', {
                  day: formatDay(startTime),
                  start: formatHour(startTime),
                  end: formatHour(endTime)
                })}
              </strong>
            </Typography>
          </Box>
          <Typography variant="body2">{fullAddress}</Typography>
        </Box>
        {showDisclaimer && (
          <Box pt={2}>
            <CancelScheduleDisclaimer
              orderStatus={status}
              cancelledBy={cancelledBy}
              wasCancelledByShipper={wasCancelledByShipper}
            />
          </Box>
        )}
      </Collapse>
    </Box>
  );
};

export default SchedulingDetailsSummary;
