import {
  Box,
  ListItem,
  Typography,
  Checkbox,
  Paper,
  CircularProgress,
  List
} from '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import React from 'react';
import {
  ActiveIcon,
  DisabledIcon
} from '@loggi/components/src/one/shared/images';

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  listItem: {
    display: 'unset'
  },
  menuContent: {
    '& > :last-child': {
      '& .MuiDivider-root': {
        display: 'none'
      }
    },
    [breakpoints.only('xs')]: {
      margin: spacing(3, 2, 2, 2)
    }
  },
  horizontalMenu: {
    borderRadius: '16px',
    maxWidth: '360px',
    margin: spacing(1, 2, 2, 2)
  },
  descriptionIntegrator: {
    color: palette.grey[600]
  },
  circularProgress: {
    margin: spacing(2, 1, 1, 1)
  }
}));

const CheckboxIntegratorStatus = ({ isActive }) => (
  <Checkbox
    checked={isActive}
    icon={<DisabledIcon />}
    checkedIcon={<ActiveIcon />}
  />
);

CheckboxIntegratorStatus.propTypes = {
  isActive: PropTypes.bool.isRequired
};

const CardIntegrator = ({ subtitle, url, icon, isActive, loading }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const { t } = useTranslation('management');
  const integratorStatus = isActive
    ? t('integrations.cards.status.enabled')
    : t('integrations.cards.status.disabled');

  return (
    <Paper className={classes.horizontalMenu} elevation={6}>
      <List className={classes.menuContent} disablePadding>
        <ListItem
          button
          className={classes.listItem}
          sx={{ width: 200 }}
          data-testid="management-card-integrator"
          disableGutters
          onClick={() => push(url)}
        >
          <Box display="flex" flexDirection="column" p={{ sm: 3 }}>
            <Box display="flex" justifyContent="space-between">
              {icon}
              <ArrowForwardIos style={{ fontSize: 20 }} color="primary" />
            </Box>
            <Box display="flex" mt={3} mr={8}>
              <Typography
                className={classes.descriptionIntegrator}
                style={{ fontSize: 15 }}
                variant="body1"
              >
                {subtitle}
              </Typography>
            </Box>
            <Box display="flex" ml={-2}>
              <Typography variant="body1">
                {loading ? (
                  <CircularProgress
                    className={classes.circularProgress}
                    size={25}
                  />
                ) : (
                  <Box fontWeight="fontWeightBold" fontSize={14}>
                    <CheckboxIntegratorStatus isActive={isActive} />
                    {integratorStatus}
                  </Box>
                )}
              </Typography>
            </Box>
          </Box>
        </ListItem>
      </List>
    </Paper>
  );
};

CardIntegrator.propTypes = {
  subtitle: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  isActive: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
};

export default CardIntegrator;
