import { Box, Divider, Typography } from '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const SchedulingDetailsVolumePackages = ({ count }) => {
  const { t } = useTranslation('details');

  return (
    <>
      <Box
        p={{ xs: 3, sm: 5 }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body1">
          <strong>{String(count).padStart(2, '0')}</strong>
          {t('volumePackagesList.title', { count })}
        </Typography>
        <ArrowForwardIos style={{ fontSize: 20 }} color="primary" />
      </Box>
      <Divider />
    </>
  );
};

SchedulingDetailsVolumePackages.propTypes = {
  count: PropTypes.number.isRequired
};

export default SchedulingDetailsVolumePackages;
