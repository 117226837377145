import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Chip, Box } from '@material-ui/core';

const ReportDownloadSection = ({ header, data, onChange }) => {
  const { t } = useTranslation('packages');

  const isAllSelected = data.every(field => field.selected);

  const changeValue = (index, selected) => {
    const dataAux = data;
    dataAux[index].selected = selected;
    onChange(dataAux);
  };

  const renderItems = data.map((field, index) => {
    return (
      <Box key={field.label} pr={1} pb={2}>
        <Chip
          label={field.label}
          variant="outlined"
          color={field.selected ? 'primary' : 'default'}
          onClick={() => changeValue(index, !field.selected)}
          disableRipple
        />
      </Box>
    );
  });

  const changeSelection = () => {
    for (let index = 0; index < data.length; index += 1) {
      const field = data[index];
      field.selected = !isAllSelected;
    }

    onChange(data);
  };

  return (
    <Box pt={4} pb={2}>
      <Box
        pb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle1">
          <Box fontWeight="fontWeightMedium" component="strong">
            {header}
          </Box>
        </Typography>

        <Typography variant="caption" color="primary" gutterBottom>
          <Box
            style={{ cursor: 'pointer' }}
            onClick={() => changeSelection()}
            fontWeight="fontWeightMedium"
            component="strong"
          >
            {isAllSelected
              ? t('customReport.removeAll')
              : t('customReport.selectAll')}
          </Box>
        </Typography>
      </Box>
      <Box display="flex" flexWrap="wrap">
        {renderItems}
      </Box>
    </Box>
  );
};

ReportDownloadSection.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
};

export default ReportDownloadSection;
