import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import {
  ListItem,
  ListItemText,
  Box,
  Typography,
  ListItemSecondaryAction,
  ListItemIcon,
  Checkbox,
  useTheme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StatusIndicatorPackages } from '@loggi/components/src/one/status-indicator';
import packagesRoutes from '../../../packages-routes';
import IdentifierField from '../packages-list-table/identifier-field';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    padding: spacing(3),
    '&:hover': {
      backgroundColor: palette.secondary.light,
      cursor: 'pointer'
    },
    '&.Mui-selected': {
      backgroundColor: palette.secondary.light,
      '&:hover': {
        backgroundColor: palette.secondary.light
      }
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: palette.primary.light
    },
    '& .MuiListItemText-root': {
      marginTop: 0,
      marginBottom: 0
    }
  },
  checkbox: {
    minWidth: spacing(3),
    paddingRight: spacing(1)
  },
  secondaryAction: {
    maxWidth: spacing(10),
    left: spacing(8),
    top: spacing(18)
  }
}));

/**
 * PackagesListItem's accountability is to create a list item with the package information
 * @param {string} status the package status
 * @param {string} recipientName the recipient name
 * @param {string} recipientAddress the recipient address
 * @param {string} trackingKey the package trackingKey
 * @param {string} companyCnpj the package's company CNPJ
 * @param {function} handleToggle the function that handles a checkbox click
 * @param {arrayOf} checked the list of currente selected trackingKeys
 * @returns packageListItem
 */
const PackagesListItem = props => {
  const { companyId } = useParams();
  const { push } = useHistory();

  const {
    status,
    recipientName,
    recipientAddress,
    trackingKey,
    companyCnpj,
    handleToggle,
    checked,
    useHierarchySpecifcs
  } = props;

  const classes = useStyles();
  const { typography } = useTheme();

  const handleCheckbox = value => {
    if (value === 'checkbox') {
      handleToggle(trackingKey);
    } else {
      push(packagesRoutes.show.url(companyId, trackingKey));
    }
  };

  const ItemContent = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      mb={6}
      width="77%"
    >
      <Box display="flex" pb={2}>
        <StatusIndicatorPackages status={status} isTag />
      </Box>
      <Box display="flex" alignItems="start" mr={8} pb={0}>
        <Box flex={2} mx={0}>
          <Typography
            variant="body2"
            color="textPrimary"
            style={{ fontWeight: typography.fontWeightMedium }}
            noWrap
          >
            {recipientName}
          </Typography>
        </Box>
      </Box>
      {useHierarchySpecifcs && (
        <Box display="flex" alignItems="start" mr={8} pb={0}>
          <Box flex={2} mx={0}>
            <Typography
              variant="body2"
              color="textPrimary"
              style={{ fontWeight: typography.fontWeightMedium }}
              noWrap
            >
              {companyCnpj}
            </Typography>
          </Box>
        </Box>
      )}
      <Box display="flex" pb={0}>
        <Box flex={4} mx={0} width={300}>
          <Typography variant="body2" color="textSecondary" noWrap>
            {recipientAddress}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <ListItem
      display="flex"
      data-testid="package-list-item"
      className={classes.root}
      selected={checked.indexOf(trackingKey) !== -1}
      onClick={event => handleCheckbox(event.target.type)}
    >
      <ListItemIcon className={classes.checkbox}>
        <Checkbox
          edge="start"
          data-testid="checkBox-item"
          checked={checked.indexOf(trackingKey) !== -1}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': 'labelId' }}
          onClick={event => handleCheckbox(event.target.type)}
        />
      </ListItemIcon>
      <ListItemText primary={ItemContent} />
      <ListItemSecondaryAction className={classes.secondaryAction}>
        <IdentifierField identifier={trackingKey} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

PackagesListItem.propTypes = {
  status: PropTypes.string.isRequired,
  recipientName: PropTypes.string.isRequired,
  recipientAddress: PropTypes.string.isRequired,
  trackingKey: PropTypes.string.isRequired,
  companyCnpj: PropTypes.string.isRequired,
  handleToggle: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  useHierarchySpecifcs: PropTypes.bool
};

PackagesListItem.defaultProps = {
  useHierarchySpecifcs: false
};

export default PackagesListItem;
