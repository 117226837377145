import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAmplifyAuth } from '@loggi/authentication-lib';

import { getHierarchyChildren } from './service';
import CompanyHierarchy from '../entity/CompanyHierarchy';

const useHierarchyChildren = () => {
  const [childrenListAPIResponse, setChildrenListAPIResponse] = useState(
    undefined
  );
  const [
    shouldFetchHierarchyChildren,
    setShouldFetchHierarchyChildren
  ] = useState(false);
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const { companyId } = useParams();

  useEffect(() => {
    setShouldFetchHierarchyChildren(Boolean(authenticatedUser));
  }, [authenticatedUser]);

  useEffect(() => {
    const companyHierarchy = CompanyHierarchy.getInstance();
    const hasCompanyChanged =
      companyHierarchy.getLoggedCompanyId() !== companyId;

    const updateHierarchySingleton = (hierarchyData, loggedCompanyId) => {
      companyHierarchy.setChildren(hierarchyData);
      companyHierarchy.setLoggedCompanyId(loggedCompanyId);
    };

    if (shouldFetchHierarchyChildren && companyId) {
      if (companyHierarchy.hasNoLoggedCompany() || hasCompanyChanged) {
        getHierarchyChildren(companyId)
          .then(hierarchyData => {
            setChildrenListAPIResponse(hierarchyData);
            updateHierarchySingleton(hierarchyData, companyId);
          })
          .catch(() => setChildrenListAPIResponse([]));
      }
    } else {
      setChildrenListAPIResponse(companyHierarchy.getChildren());
    }
  }, [shouldFetchHierarchyChildren, companyId]);

  return childrenListAPIResponse ?? null;
};

export default useHierarchyChildren;
