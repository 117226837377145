const pickupReceiptBaseEndpoint = pickupOrderScheduleHash =>
  `/one/api/pickup_receipt/v1/order-schedules/${pickupOrderScheduleHash}`;
const PICKUP_RECEIPT_API_ENDPOINTS = {
  auth: pickupOrderScheduleHash =>
    `${pickupReceiptBaseEndpoint(
      pickupOrderScheduleHash
    )}/auth-pickup-receipt/`,

  update: pickupOrderScheduleHash =>
    `${pickupReceiptBaseEndpoint(
      pickupOrderScheduleHash
    )}/update-pickup-receipt/`,

  getPickupReceipts: pickupOrderScheduleHash =>
    `${pickupReceiptBaseEndpoint(
      pickupOrderScheduleHash
    )}/get-pickup-receipts/`,

  receiptOrderSchedule: pickupOrderScheduleHash =>
    `${pickupReceiptBaseEndpoint(
      pickupOrderScheduleHash
    )}/get-receipt-order-schedule/`
};

export default PICKUP_RECEIPT_API_ENDPOINTS;
