import {
  Box,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
  SvgIcon,
  withStyles
} from '@material-ui/core';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import {
  accessItems,
  getUserRole
} from '@loggi/components/src/one/permissions';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import CompanyHierarchy from '@loggi/components/src/one/entity/CompanyHierarchy';
import { Menu as MenuIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { useCurrentCompany } from '../auth/index';
import { ReactComponent as LogoLoggi } from '../assets/logo-loggi.svg';
import { useRemoteConfig } from '../firebase';
import { MENU_ITEMS } from './header.constants';
import HelpCenterLink from './help-center-link.component';
import AccountManagementLink from './account-management-link.component';

const useStyles = makeStyles({
  drawerPaper: {
    width: '80%'
  }
});

const StyledMenuItem = withStyles(({ spacing, palette, typography }) => ({
  gutters: {
    padding: spacing(2, 4)
  },
  root: {
    ...typography.h5,
    fontWeight: typography.fontWeightBold,
    '&.Mui-selected, &.Mui-selected:hover': {
      background: 'transparent'
    },
    '&.Mui-selected': {
      color: palette.primary.main
    }
  }
}))(MenuItem);

const MobileMenu = () => {
  const styles = useStyles();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { value: isPaymentsEnabled } = useRemoteConfig('enable_payments_app');
  const { t } = useTranslation('components');
  const { pathname } = useLocation();
  const companyMatch = useRouteMatch('/empresas/:companyId');
  const companyId = companyMatch?.params?.companyId;
  const accessDeniedScreen = pathname.includes('acesso-negado');
  const { value: enableHierarchyRoute } = useRemoteConfig(
    'enable_hierarchy_company_in_menu_route'
  );

  const currentCompany = useCurrentCompany();
  const currentCompanyId = currentCompany?.id;

  let routeCompanyId = companyId;
  if (enableHierarchyRoute === 'true') {
    routeCompanyId =
      CompanyHierarchy.getInstance().getLoggedCompanyId() ?? companyId;
  }
  const userCompanyId = accessDeniedScreen ? currentCompanyId : routeCompanyId;

  const {
    state: { authenticatedUser, isImpersonation }
  } = useAmplifyAuth();
  const userRole = getUserRole(authenticatedUser, userCompanyId);

  const mobileMenuItem = [
    {
      id: 'tracking',
      label: t(MENU_ITEMS.TRACKING.label),
      route: MENU_ITEMS.TRACKING.route(userCompanyId),
      match: Boolean(useRouteMatch(MENU_ITEMS.TRACKING.path)),
      visible: accessItems.tracking.includes(userRole)
    },
    {
      id: 'packages',
      label: t(MENU_ITEMS.PACKAGES.label),
      route: MENU_ITEMS.PACKAGES.route(userCompanyId),
      match: Boolean(useRouteMatch(MENU_ITEMS.PACKAGES.path)),
      visible: accessItems.packages.includes(userRole)
    },
    {
      id: 'payments',
      label: t(MENU_ITEMS.PAYMENTS.label),
      route: MENU_ITEMS.PAYMENTS.route(userCompanyId),
      match: Boolean(useRouteMatch(MENU_ITEMS.PAYMENTS.path)),
      visible:
        isPaymentsEnabled === 'true' && accessItems.finance.includes(userRole)
    }
  ];

  return (
    <>
      <Box height="inherit" clone>
        <IconButton
          color="inherit"
          data-testid="menu-button"
          edge="start"
          onClick={() => setMenuOpen(true)}
        >
          <MenuIcon data-testid="mobile-menu-icon" style={{ fontSize: 32 }} />
        </IconButton>
      </Box>
      <Drawer
        anchor="left"
        classes={{ paper: styles.drawerPaper }}
        onClose={() => setMenuOpen(false)}
        open={isMenuOpen}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box p={4}>
            <SvgIcon
              color="primary"
              style={{ fontSize: 30 }}
              component={LogoLoggi}
            />
          </Box>
          <MenuList onClick={() => setMenuOpen(false)}>
            {mobileMenuItem.map(({ id, route, label, visible, match }) => {
              return (
                (visible || isImpersonation) && (
                  <StyledMenuItem
                    key={id}
                    component={Link}
                    selected={match}
                    to={route}
                  >
                    {t(label)}
                  </StyledMenuItem>
                )
              );
            })}
          </MenuList>

          <Box
            mt="auto"
            display="flex"
            flexDirection="column"
            alignItems="start"
          >
            <Divider style={{ width: '100%' }} />
            <Box width="100%">
              <HelpCenterLink isMobile />
            </Box>
            <Box onClick={() => setMenuOpen(false)} width="100%">
              <AccountManagementLink isMobile />
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default MobileMenu;
