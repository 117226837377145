import {
  Box,
  Radio,
  FormControlLabel,
  RadioGroup,
  Tooltip,
  Grid,
  Typography,
  useTheme
} from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import CachedIconOutlined from '@material-ui/icons/CachedOutlined';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { NOT_RECURRING, IS_RECURRING } from './constants';
import { ReactComponent as WrongIcon } from './icons/wrong.svg';

const useStyledCheckboxStyles = makeStyles(({ palette, typography, shape }) => {
  return {
    icon: {
      borderRadius: shape.borderRadius,
      fontSize: typography.body2.fontSize,
      fontWeight: typography.fontWeightBold,
      minHeight: typography.pxToRem(42),
      lineHeight: '150%',
      width: '100%',
      margin: typography.pxToRem(10),
      display: 'flex',
      verticalAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center'
    },
    checkedIcon: {
      color: palette.text.primary,
      background: palette.background.paper,
      boxShadow:
        '0px 8px 10px -5px rgba(190, 199, 214, 0.02), 0px 16px 24px 2px rgba(159, 171, 187, 0.03), 0px 6px 30px 5px rgba(72, 79, 95, 0.08)'
    },
    checkbox: {
      padding: 0,
      width: '100%',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  };
});

const isRecurringFieldStyles = makeStyles(({ palette, typography, shape }) => ({
  formLabel: {
    margin: 0,
    width: '100%'
  },
  formGroup: {
    borderRadius: shape.borderRadius,
    justifyContent: 'space-between',
    width: '100%',
    background: palette.grey[50],
    minHeight: typography.pxToRem(62)
  }
}));

const StyledRadio = props => {
  const classes = useStyledCheckboxStyles();
  const { description, label, dataTestId, icon, value, disabled } = props;
  const { spacing, typography } = useTheme();

  const radioItem = checked => {
    const fontProperties = checked
      ? {
          weight: typography.fontWeightMedium,
          color: 'textPrimary'
        }
      : {
          color: 'textSecondary'
        };

    return (
      <>
        <Box display={{ lg: 'flex' }} py={1}>
          {icon}
          <Box
            pl={{ lg: 1.37 }}
            display="flex"
            alignItems="center"
            width={{ xs: spacing(12.5), lg: '100%' }}
          >
            <Typography
              variant="body2"
              color={fontProperties.color}
              style={{ fontWeight: fontProperties.weight }}
            >
              {label}
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Tooltip title={description}>
      <Radio
        className={classes.checkbox}
        checkedIcon={
          <>
            <span className={`${classes.icon} ${classes.checkedIcon}`}>
              {radioItem(true)}
            </span>
          </>
        }
        icon={<span className={`${classes.icon} `}>{radioItem(false)}</span>}
        inputProps={{
          'data-testid': dataTestId,
          'aria-label': description
        }}
        value={value}
        disabled={disabled}
      />
    </Tooltip>
  );
};

StyledRadio.propTypes = {
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

const IsRecurring = ({ fieldName }) => {
  const { t } = useTranslation('unifiedPickup');

  const pickupFrequencyOptions = [
    {
      icon: <CachedIconOutlined />,
      description: 'Sim',
      label: t('dateSection.isRecurringPickup.buttonYes'),
      fieldValue: IS_RECURRING
    },
    {
      icon: <WrongIcon />,
      description: 'Não',
      label: t('dateSection.isRecurringPickup.buttonNo'),
      fieldValue: NOT_RECURRING
    }
  ];

  const classes = isRecurringFieldStyles();
  const { validateForm } = useFormikContext();
  const [field, { touched }, helpers] = useField({
    name: fieldName,
    validate: () => null
  });

  const { setValue, setTouched } = helpers;
  const { value = IS_RECURRING } = field;

  useEffect(() => {
    if (value !== undefined && !touched) {
      setTouched(true);
    }
  }, [touched, value, setTouched]);

  const renderItems = () => {
    let i = 0;
    return pickupFrequencyOptions.map(
      ({ description, fieldValue, label, icon }) => {
        i += 1;
        return (
          <Grid item xs={6} key={i}>
            <FormControlLabel
              className={classes.formLabel}
              control={
                <StyledRadio
                  description={description}
                  label={label}
                  icon={icon}
                  dataTestId={fieldValue}
                  value={fieldValue}
                  disabled={false}
                />
              }
              key={description}
              value={fieldValue}
            />
          </Grid>
        );
      }
    );
  };

  const handleChange = useCallback(
    e => {
      setValue(e.target.defaultValue);
      setTimeout(() => {
        validateForm().finally();
      }, 0);
    },
    [setValue, validateForm]
  );

  return (
    <Box display="flex">
      <RadioGroup
        name={fieldName}
        className={classes.formGroup}
        value={value}
        onChange={handleChange}
        row
      >
        <Grid container spacing={2}>
          {renderItems()}
        </Grid>
      </RadioGroup>
    </Box>
  );
};

IsRecurring.propTypes = {
  fieldName: PropTypes.string.isRequired
};

export default IsRecurring;
