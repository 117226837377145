import {
  List,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SchedulingModel from '../schedulings-list/scheduling-model';
import TableCellHead from '../table-cell-head';
import {
  SchedulingsListItem,
  SchedulingsTableItem
} from './schedulings-list-item';

/**
 * SchedulingsListTable renders a static table that only presents the data it receives
 * @componentType presentational
 * @param data
 * @returns {*}
 * @constructor
 */
const SchedulingsListTable = ({ data }) => {
  const { t } = useTranslation('scheduling');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const renderItems = () => {
    let i = 0;
    return data.map((pickup, index) => {
      i += 1;
      return <SchedulingsTableItem pickup={pickup} index={index} key={i} />;
    });
  };

  const schedulesTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHead ml={2}>
                {t('schedulingsList.tableDataHeaders.status')}
              </TableCellHead>
              <TableCellHead>
                {t('schedulingsList.tableDataHeaders.date')}
              </TableCellHead>
              <TableCellHead>
                {t('schedulingsList.tableDataHeaders.address')}
              </TableCellHead>
              <TableCellHead mr={2}>
                {t('schedulingsList.tableDataHeaders.id')}
              </TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>{renderItems()}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  const schedulesList = () => {
    return (
      <List style={{ width: '100%' }}>
        {data.map((pickup, index) => (
          <SchedulingsListItem pickup={pickup} index={index} />
        ))}
      </List>
    );
  };

  return <>{isDesktop ? schedulesTable() : schedulesList()}</>;
};

SchedulingsListTable.propTypes = {
  data: PropTypes.arrayOf(SchedulingModel)
};
SchedulingsListTable.defaultProps = {
  data: []
};

export default SchedulingsListTable;
