import React from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

/**
 * DatePickerErrorDialog's accountability is to create the dynamic modal to show
 * the error message according to the date range picker rules.
 * @param {string} errorName Error name according to 'packages-translation-pt-br.json'
 * @param {boolean} open Set the dialog open
 * @param {function} handleCloseErrorDialog function to handle close dialog
 * @returns dialog
 */
const DatePickerErrorDialog = ({ errorName, open, handleCloseErrorDialog }) => {
  const { t } = useTranslation('packages');

  return (
    <Dialog
      open={open}
      onClose={handleCloseErrorDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box p={{ xs: 3, sm: 5 }}>
        <Box pb={2}>
          <Typography variant="h6">
            <strong>{t(`filters.date.errors.${errorName}.title`)}</strong>
          </Typography>
        </Box>
        <Box pb={4}>
          <Typography variant="subtitle2">
            {t(`filters.date.errors.${errorName}.subtitle`)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            onClick={handleCloseErrorDialog}
            size="large"
            variant="contained"
            color="primary"
            autoFocus
          >
            {t('filters.date.errors.closeButton')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

DatePickerErrorDialog.propTypes = {
  errorName: PropTypes.string.isRequired,
  open: PropTypes.bool,
  handleCloseErrorDialog: PropTypes.func.isRequired
};

DatePickerErrorDialog.defaultProps = {
  open: false
};

export default DatePickerErrorDialog;
