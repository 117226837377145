const LOADING_ID = 'loading-id';
const PAGE_NOT_FOUND = 'Página não encontrada';
const PICKUPS_COMPANIES_ENDPOINT = 'one/api/pickups/v1/companies';
const SUBSCRIBE_NOTIFICATIONS_ENDPOINT = 'subscribe-notifications';
const SIGN_UP_USER_ALREADY_EXISTS_ERROR =
  'PreSignUp+failed+with+error+There+is+already+a+registered+user+with+email';
const SIGN_UP_GOOGLE_USER_LINK_REDIRECT =
  'PreSignUp+failed+with+error+User+account+has+been+linked+to+corresponding+Google+federated+account';
const ACCOUNTS_COMPANIES_ENDPOINT = 'one/api/accounts/v1/companies/';
const SELECTED_COMPANY_LOCAL_STORAGE_KEY = 'loggione_selected_company';
const SUPPORT_ROLE = 'SUPPORT';

export {
  LOADING_ID,
  PAGE_NOT_FOUND,
  PICKUPS_COMPANIES_ENDPOINT,
  SUBSCRIBE_NOTIFICATIONS_ENDPOINT,
  SIGN_UP_USER_ALREADY_EXISTS_ERROR,
  SIGN_UP_GOOGLE_USER_LINK_REDIRECT,
  ACCOUNTS_COMPANIES_ENDPOINT,
  SELECTED_COMPANY_LOCAL_STORAGE_KEY,
  SUPPORT_ROLE
};
