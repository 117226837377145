const validAddress = (value, { prefix = '', sufix = '' }) =>
  value ? prefix + value + sufix : '';

const getAddress = (address = {}) =>
  `${address?.addressSt || ''}\
${validAddress(address.addressNumber, { prefix: ', ' })}\
${validAddress(address.vicinity, { prefix: ' - ' })}\
${validAddress(address.city, { prefix: ', ' })}\
${validAddress(address.state, { prefix: ' - ' })}\
${validAddress(address.addressComplement, { prefix: ' - ' })}`;

const getPostalAddress = (address = {}) =>
  `${address?.addressLines?.[0] || ''}\
${validAddress(address?.addressLines?.[1], { prefix: ', ' }) || ''}\
${validAddress(address.sublocality, { prefix: ' - ' })}\
${validAddress(address.locality, { prefix: ', ' })}\
${validAddress(address.administrativeArea, { prefix: ' - ' })}\
${validAddress(address.postalCode, { prefix: ', ' })}\
${validAddress(address.regionCode, { prefix: ', ' })}`;

const getCorreioAddress = (address = {}) =>
  `${validAddress(address.correiosAddress?.logradouro, { prefix: '' })}\
${validAddress(address.correiosAddress?.numero, {
  prefix: ', ',
  sufix: ' - '
})}\
${validAddress(address.correiosAddress?.complemento, {
  prefix: ' - ',
  sufix: ' - '
})}\
${validAddress(address.correiosAddress?.bairro, { prefix: '' })}\
${validAddress(address.correiosAddress?.cidade, { prefix: ' - ' })}\
${validAddress(address.correiosAddress?.uf, { prefix: ' - ' })}\
${validAddress(address.correiosAddress?.cep, { prefix: ' - ' })}`;

const shouldUseCorreiosAddress = pickupAddress => {
  const addressLines = pickupAddress.postalAddress?.addressLines?.[0];
  const items = addressLines.split('-');
  const agencyAddress = items[1].trim();

  return Boolean(agencyAddress);
};

const getAddressLines = pickupAddress => {
  const addressLines = pickupAddress?.postalAddress?.addressLines?.[0];

  return addressLines.split(',')[0].trim();
};

const getRedispatchAddress = pickupAddress => {
  return shouldUseCorreiosAddress(pickupAddress)
    ? getCorreioAddress(pickupAddress)
    : getAddressLines(pickupAddress);
};

export default { getAddress };
export {
  getAddress,
  getPostalAddress,
  getCorreioAddress,
  getRedispatchAddress
};
