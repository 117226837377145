import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import emptyPickupsIllustration from './packages-error.svg';

const useStyles = makeStyles(({ typography, spacing }) => ({
  title: {
    fontWeight: typography.fontWeightBold
  },
  image: {
    width: '100%',
    maxWidth: typography.pxToRem(155),
    paddingTop: spacing(5)
  }
}));

const SchedulingDetailsPackagesListError = () => {
  const { push } = useHistory();
  const classes = useStyles();
  const { url } = useRouteMatch();
  const { t } = useTranslation('scheduling');

  return (
    <Box
      alignItems="center"
      className={classes.container}
      data-testid="empty-state-wrapper"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      m="auto"
      textAlign="center"
      width={1}
      maxWidth={486}
    >
      <img
        className={classes.image}
        src={emptyPickupsIllustration}
        alt="Truck"
      />
      <Box mt={3.5} mb={2}>
        <Box mb={2}>
          <Typography className={classes.title} variant="subtitle1">
            {t('errorListPackages.title')}
          </Typography>
        </Box>
        <Typography variant="subtitle1" component="p">
          {t('errorListPackages.subtitle')}
        </Typography>
      </Box>
      <Button onClick={() => push(`${url}`)} color="primary">
        {t('errorListPackages.linkText')}
      </Button>
    </Box>
  );
};

export default SchedulingDetailsPackagesListError;
