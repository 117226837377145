import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot
} from '@material-ui/lab';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const useStyles = makeStyles(({ typography }) => ({
  deprecatedPromisedDate: {
    textDecoration: 'line-through'
  },
  timelineConnector: {
    background: 'none',
    borderRight: '2px dashed',
    minHeight: typography.pxToRem(15)
  },
  timelineContent: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0
  },
  timelineDot: {
    paddingRight: typography.pxToRem(8),
    paddingLeft: typography.pxToRem(8),
    background: 'none'
  },
  timelineIcon: {
    width: typography.pxToRem(20),
    height: typography.pxToRem(20)
  },
  timelineItem: {
    '&:before': {
      display: 'none'
    },
    minHeight: 0
  },
  timelineSecondaryItem: {
    '&:before': {
      display: 'none'
    },
    minHeight: 0,
    opacity: 0.5
  },
  timelineStyles: {
    margin: 0,
    padding: 0
  }
}));

/**
 * PackageReturnPromisedDate only responsibility is to show the promised date info.
 * @componentType: Presentational
 * @param date
 * @param returnDate
 * @returns {*}
 */
const PackageReturnPromisedDate = ({ date, returnDate }) => {
  const { t } = useTranslation('packages');
  const styles = useStyles();

  return (
    <Box
      display="flex"
      pt={{ xs: 2, sm: 0 }}
      data-testid="return-promised-date-info"
    >
      <Timeline className={styles.timelineStyles}>
        <TimelineItem className={styles.timelineItem}>
          <TimelineSeparator>
            <TimelineDot className={styles.timelineDot}>
              <KeyboardReturnIcon className={styles.timelineIcon} />
            </TimelineDot>
            <TimelineConnector className={styles.timelineConnector} />
          </TimelineSeparator>
          <TimelineContent className={styles.timelineContent}>
            <Box>
              <Box display="flex">
                <Typography variant="caption">
                  <strong>{t('details.labels.returnPromisedDate')}</strong>
                </Typography>
              </Box>
              <Box display="flex">
                <Typography variant="body1">
                  <strong>{returnDate}</strong>
                </Typography>
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem className={styles.timelineSecondaryItem}>
          <TimelineSeparator>
            <TimelineDot className={styles.timelineDot}>
              <RadioButtonUncheckedIcon className={styles.timelineIcon} />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent className={styles.timelineContent}>
            <Typography
              variant="caption"
              className={styles.deprecatedPromisedDate}
              data-testid="deprecate-promised-date"
            >
              <strong>{t('details.labels.promisedDate')}</strong>
            </Typography>
            <Typography
              variant="body1"
              className={styles.deprecatedPromisedDate}
            >
              <strong>{date}</strong>
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  );
};

PackageReturnPromisedDate.propTypes = {
  date: PropTypes.string.isRequired,
  returnDate: PropTypes.string.isRequired
};

export default PackageReturnPromisedDate;
