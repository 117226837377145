import { Box, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SCHEDULING_STATUSES } from '../../../scheduling/constants';

const useSchedulingDetailsDriversSummaryStyles = makeStyles(
  ({ palette, spacing }) => ({
    linkBackButton: {
      borderRadius: '100%',
      height: spacing(5),
      minWidth: 0,
      width: spacing(5),
      color: palette.common.white
    },
    fontColor: {
      mixBlendMode: 'screen',
      position: 'relative',
      color: 'initial',
      '&:before': {
        mixBlendMode: 'color-burn',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        content: '""'
      }
    }
  })
);

/**
 * SchedulingDetailsDriversSummary's only responsibility is to show the summary info of the
 * drivers scheduling details screen, with the params it receives.
 * @componentType: Presentational
 * @param id
 * @param vehiclesAmount
 * @param orderStatus
 * @returns {*}
 */
const SchedulingDetailsDriversSummary = ({
  id,
  vehiclesAmount,
  orderStatus
}) => {
  const styles = useSchedulingDetailsDriversSummaryStyles();
  const theme = useTheme();
  const { t } = useTranslation(['details']);

  const vehicleAmountLabel =
    orderStatus === SCHEDULING_STATUSES.WAITING_PICKUP
      ? 'vehiclesAmountOverview.toCollect'
      : 'vehiclesAmountOverview.collected';

  const showPackagesAmountInfo = vehiclesAmount !== null;

  return (
    <Box display="flex" flexDirection="column">
      <Box
        alignSelf="flex-start"
        mb={1.5}
        bgcolor={theme.palette.common.white}
        borderRadius={50}
        px={1}
        py={0.5}
        className={styles.fontColor}
      >
        <Typography variant="caption">
          <strong>{t('summary.scheduling', { id })}</strong>
        </Typography>
      </Box>
      {showPackagesAmountInfo && (
        <Box>
          <Typography variant="h4">
            <strong>
              {orderStatus === SCHEDULING_STATUSES.CANCELLED || !vehiclesAmount
                ? t('transportTypes.none')
                : `${vehiclesAmount}${t(vehicleAmountLabel, {
                    count: vehiclesAmount
                  })}`}
            </strong>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

SchedulingDetailsDriversSummary.propTypes = {
  id: PropTypes.string.isRequired,
  vehiclesAmount: PropTypes.number,
  orderStatus: PropTypes.string
};

SchedulingDetailsDriversSummary.defaultProps = {
  vehiclesAmount: null,
  orderStatus: null
};

export default SchedulingDetailsDriversSummary;
