import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PackageFactory } from '@loggi/components/src/one/package-utils';
import { useQueryParams } from '@loggi/components/src/one/hooks';
import fetchSchedulingPackages from './fetch-scheduling-packages';
import { DEFAULT_PAGE_SIZE, FIRST_PAGE_TOKEN, FIRST_PAGE } from './constants';

const initialState = {
  isLoading: true,
  data: undefined,
  totalItems: null, // Mock value
  pageTokens: {
    [FIRST_PAGE]: FIRST_PAGE_TOKEN
  },
  pageSize: DEFAULT_PAGE_SIZE
};

/**
 * This hook adds an abstraction layer between the component and the fetch.
 * It gets the pagination params directly from the useQueryParams hook, and fetches
 * the data using the fetchSchedulingPackages method, also adding a loading interface
 * @returns {[undefined, null, boolean]}
 */
const usePackages = (schedulingId, companyId) => {
  const { pagina } = useQueryParams();
  const { push } = useHistory();
  const { url } = useRouteMatch();

  const [
    { data, totalItems, isLoading, pageTokens, pageSize, error },
    setData
  ] = useState(initialState);

  useEffect(() => {
    const updateStateWithAPIResponse = ({ total, packages, nextPageToken }) => {
      setData(state => ({
        ...state,
        data: packages,
        isLoading: false,
        totalItems: total,
        pageTokens: {
          ...state.pageTokens,
          [pagina ? Number(pagina) + 1 : 2]: nextPageToken
        }
      }));
    };

    const getPageToken = pageNumber => {
      if (!pageNumber) return pageTokens[FIRST_PAGE];
      // In case nextPageToken is undefined and user accesses the url directly
      if (pageTokens[pageNumber]) return pageTokens[pageNumber];

      const nextPageToken = (Number(pageNumber) - 1) * DEFAULT_PAGE_SIZE;

      setData(state => ({
        ...state,
        pageTokens: {
          ...state.pageTokens,
          [Number(pageNumber)]: nextPageToken
        }
      }));

      return nextPageToken;
    };

    setData(state => ({ ...state, isLoading: true }));

    const redirectOnEmptyResponse = response => {
      const isPackageHashEmpty = Boolean(
        response?.total > 0 && response?.packages?.length === 0
      );

      if (isPackageHashEmpty) {
        push(`${url}?pagina=1`);
      }

      return response;
    };

    const handleError = response => {
      const hasFailedPackages = response.failed_packages?.length > 0;

      if (hasFailedPackages) {
        throw new Error(`Error on fetching packages from company ${companyId}`);
      }

      return response;
    };

    const normalizePackages = response => {
      const models = PackageFactory.trackingAPI.bulkCreate(response.packages);

      return Promise.all([response, models]);
    };

    fetchSchedulingPackages(
      schedulingId,
      companyId,
      pageSize,
      getPageToken(pagina)
    )
      .then(response => redirectOnEmptyResponse(response))
      .then(response => handleError(response))
      .then(response => normalizePackages(response))
      .then(([{ total, next_page_token: nextPageToken }, packages]) =>
        updateStateWithAPIResponse({
          total,
          packages,
          nextPageToken
        })
      )
      .catch(() => {
        setData(state => ({ ...state, error: true, isLoading: false }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulingId, companyId, pagina]);
  return [data, totalItems, isLoading, error];
};

export default usePackages;
