import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import UsersListPagination from './users-list-pagination';
import ListUsersContainer from '../list-users.component';
import UsersFilterEmptyFilterResult from '../users-empty-result/users-filter-empty-result';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    minHeight: spacing(14),
    [breakpoints.up('sm')]: {
      minHeight: spacing(40)
    }
  },
  paginationBox: {
    marginTop: spacing(2),
    [breakpoints.up('sm')]: {
      marginTop: spacing(3)
    }
  }
}));

/**
 * UsersListWrapper is a wrapper component that adds a loading capability
 * before the packages table and pagination
 * @param {object} data
 * @param {number} count
 * @param {boolean} isLoading
 * @returns {*}
 * @constructor
 */
const UsersListWrapper = ({
  data,
  count,
  isLoading,
  loggedUserEmail,
  enableFilterPaginationListUsers
}) => {
  const [shouldUpdateUsersList, setshouldUpdateUsersList] = useState(false);
  const styles = useStyles();
  const dataIsEmpty = !data || data.length === 0;

  const updateUsersListCallback = () =>
    setshouldUpdateUsersList(!shouldUpdateUsersList);

  return (
    <Box
      className={styles.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {isLoading && dataIsEmpty ? (
        <CircularProgress data-testid="loading" />
      ) : (
        <>
          {dataIsEmpty && <UsersFilterEmptyFilterResult />}
          {!dataIsEmpty && (
            <>
              <ListUsersContainer
                loggedUserEmail={loggedUserEmail}
                users={data}
                updateUsersListCallback={updateUsersListCallback}
              />
              <Box
                data-testid="users-list-pagination-wrapper"
                alignSelf="flex-end"
                className={styles.paginationBox}
              >
                {enableFilterPaginationListUsers && (
                  <UsersListPagination count={count} isLoading={isLoading} />
                )}
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

UsersListWrapper.propTypes = {
  count: PropTypes.number,
  data: PropTypes.oneOfType([PropTypes.any]),
  isLoading: PropTypes.bool,
  loggedUserEmail: PropTypes.string,
  enableFilterPaginationListUsers: PropTypes.bool
};

UsersListWrapper.defaultProps = {
  count: 0,
  data: [],
  isLoading: true,
  enableFilterPaginationListUsers: false,
  loggedUserEmail: ''
};

export default UsersListWrapper;
