import {
  Avatar,
  Box,
  Button,
  Hidden,
  makeStyles,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { ArrowForwardIos } from '@material-ui/icons';
import {
  useRemoteConfig,
  useFeature
} from '@loggi/components/src/one/remote-config';
import { useHistory } from 'react-router-dom';

import {
  UPDATE_BTN_ID,
  KNOWN_ERROR_CODES,
  RESEND_INVITE_USER_BTN_ID,
  ROLES,
  USER_STATUS
} from '../constants';
import { resendUserInvite } from '../service';
import inviteUser from '../../invite-user/service';
import UpdateUserDrawer from '../../invite-user/update-user.drawer';
import sendNotificationMessage from '../../components/send-notification-message';
import errorHandler from '../../components/error-handler';
import {
  COMPANIES_ROUTE,
  USERS_MANAGEMENT_PATH
} from '../../users-management.constants';

const useStyles = makeStyles(() => ({
  button: {
    padding: '5px 0'
  }
}));

const useInfosStyles = makeStyles(({ breakpoints, palette }) => ({
  button: {
    padding: '0 15px',
    marginTop: '5px',
    height: '30px',
    [breakpoints.down('sm')]: {
      marginLeft: '-15px'
    }
  },
  picture: {
    color: palette.grey[900],
    backgroundColor: palette.common.white,
    borderColor: palette.grey[500],
    borderStyle: 'solid',
    borderWidth: 'thin'
  },
  pending: {
    display: 'flex',
    [breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    },
    [breakpoints.up('sm')]: {
      justifyContent: 'start'
    }
  }
}));

const UserListItem = ({
  accountId,
  accountRole,
  email,
  loggedUserEmail,
  name,
  updateUsersListCallback,
  userId,
  userStatus
}) => {
  const userInfosStyles = useInfosStyles();
  const { t } = useTranslation('listUsers');
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { push } = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const role = ROLES[accountRole];
  const ROLE_OWNER = ROLES[1];
  const ROLE_OWNER_LARGE = ROLES[9];
  const avatarLetter = name ? name[0].toUpperCase() : undefined;

  const isExpiredEnabled = useFeature('enable_expired_status');
  const statusList = [
    {
      label: 'status.pending',
      value: USER_STATUS.pending,
      bgColor: 'grey.100',
      color: '#000000'
    },
    {
      label: 'status.expired',
      value: USER_STATUS.expired,
      bgColor: '#FFEFEF',
      color: '#662827'
    }
  ];
  const statusToCheck = isExpiredEnabled ? statusList[1] : statusList[0];

  const getKnownError = error => {
    let parsedError;
    try {
      parsedError = JSON.parse(error.message);
    } catch (e) {
      return null;
    }

    if (KNOWN_ERROR_CODES[(parsedError?.code)]) return parsedError.code;
    return null;
  };

  const sendSuccessMessage = () =>
    sendNotificationMessage(
      t('notificationMessages.success'),
      'success',
      enqueueSnackbar
    );

  const sendErrorMessage = code => {
    const message = KNOWN_ERROR_CODES[code] || KNOWN_ERROR_CODES.default;
    sendNotificationMessage(t(message), 'error', enqueueSnackbar);
  };

  const handleResendUserInviteRequest = async () => {
    if (isExpiredEnabled) {
      inviteUser({ email, name, role: role.toUpperCase() }, accountId)
        .then(() => {
          sendSuccessMessage();
          updateUsersListCallback();
          push(`${COMPANIES_ROUTE}/${accountId}${USERS_MANAGEMENT_PATH}`);
        })
        .catch(error => {
          const knownErrorCode = getKnownError(error);
          if (knownErrorCode === 6) {
            sendSuccessMessage();
            updateUsersListCallback();
            push(`${COMPANIES_ROUTE}/${accountId}${USERS_MANAGEMENT_PATH}`);
            return;
          }
          sendErrorMessage(knownErrorCode);
          if (!knownErrorCode) {
            errorHandler(error);
          }
        });
    } else {
      resendUserInvite(userId, accountId, email, accountRole)
        .then(() => sendSuccessMessage())
        .catch(error => {
          const knownErrorCode = getKnownError(error);
          sendErrorMessage(knownErrorCode);
          if (!knownErrorCode) {
            errorHandler(error);
          }
        });
    }
  };
  const { value: isUpdateUserEnabled } = useRemoteConfig(
    'enable_user_update_and_remove'
  );
  const showUpdateUserButton = isUpdateUserEnabled === 'true';

  return (
    <Box display="flex" p={{ xs: 2, sm: 4 }}>
      <Box fontWeight="fontWeightBold" mt={0.5}>
        {avatarLetter && (
          <Avatar
            className={userInfosStyles.picture}
            data-testid="profile-avatar-id"
          >
            {avatarLetter}
          </Avatar>
        )}
      </Box>
      <Box ml={avatarLetter ? 2 : 0} flex={1}>
        <Box className={userInfosStyles.pending}>
          <Typography variant="h6" component="h2">
            {name}
          </Typography>
          {statusList.map(
            status =>
              userStatus === status.value && (
                <Box ml={1.4} display="flex" key={status.value}>
                  <Box
                    bgcolor={status.bgColor}
                    color={status.color}
                    borderRadius={5}
                    height={25}
                    mt={0.4}
                    px={1}
                  >
                    <Typography variant="caption" color="inherit">
                      {t(status.label)}
                    </Typography>
                  </Box>
                </Box>
              )
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" mt={0.5}>
          <Typography variant="caption">{email}</Typography>
          <Box display="flex" justifyContent="space-between">
            {userStatus === statusToCheck.value && (
              <Hidden only="xs">
                <Button
                  className={userInfosStyles.button}
                  color="primary"
                  data-testid={RESEND_INVITE_USER_BTN_ID}
                  type="submit"
                  onClick={handleResendUserInviteRequest}
                >
                  {t('resendInviteButton.text')}
                </Button>
              </Hidden>
            )}
            {showUpdateUserButton &&
              role !== ROLE_OWNER &&
              role !== ROLE_OWNER_LARGE &&
              loggedUserEmail !== email && (
                <>
                  <Box display="flex" justifyContent="space-between">
                    <Button
                      className={classes.button}
                      color="primary"
                      data-testid={UPDATE_BTN_ID}
                      type="submit"
                      onClick={handleClickOpen}
                    >
                      <ArrowForwardIos color="primary" />
                    </Button>
                  </Box>
                  <UpdateUserDrawer
                    accountId={accountId}
                    handleClose={handleClose}
                    name={name}
                    email={email}
                    role={role}
                    open={open}
                    updateUsersListCallback={updateUsersListCallback}
                    userId={userId}
                    userStatus={userStatus}
                  />
                </>
              )}
          </Box>
        </Box>
        <Typography component="h5" variant="body2">
          {t(`roles.${role}`)}
        </Typography>
        {userStatus === statusToCheck.value && (
          <Hidden smUp>
            <Button
              className={userInfosStyles.button}
              color="primary"
              data-testid={RESEND_INVITE_USER_BTN_ID}
              type="submit"
              onClick={handleResendUserInviteRequest}
            >
              {t('resendInviteButton.text')}
            </Button>
          </Hidden>
        )}
      </Box>
    </Box>
  );
};

UserListItem.propTypes = {
  accountId: PropTypes.string.isRequired,
  accountRole: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  loggedUserEmail: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  updateUsersListCallback: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userStatus: PropTypes.number.isRequired
};

export default UserListItem;
